import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { AuthScreen } from './AuthScreen'

const useStyles = makeStylesFast((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  logOut: {
    marginTop: theme.spacing(4),
  },
}))

const i18nBase = `auth.no_company`

/**
 * Screen shown when a user is logged in but does not belong to any company (or that they are disabled
 * on all their companies).
 */
export function NoCompany() {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleSignout = useCallback(() => {
    navigate({ to: '/signout' })
  }, [navigate])

  return (
    <AuthScreen>
      <Helmet>
        <title>{t('titles.sign_in')}</title>
      </Helmet>
      <SitelineText variant="h1" bold className={classes.title}>
        {t(`${i18nBase}.title`)}
      </SitelineText>
      <SitelineText variant="body1">{t(`${i18nBase}.details`)}</SitelineText>
      <Button fullWidth className={classes.logOut} variant="contained" onClick={handleSignout}>
        {t(`${i18nBase}.log_out`)}
      </Button>
    </AuthScreen>
  )
}
