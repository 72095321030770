import { TFunction } from 'i18next'
import _ from 'lodash'
import {
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  safeDivide,
  TAX_RATE_PERCENT_PRECISION,
} from 'siteline-common-all'
import { IntegrationSovLineItem, SitelineText } from 'siteline-common-web'
import {
  makeContentCell,
  makeDataCell,
  SpreadsheetCell,
  SpreadsheetColumn,
  SpreadsheetDataType,
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { TaxGroupProperties } from '../../../common/graphql/apollo-operations'
import { NUM_FIXED_UNIT_DECIMALS } from '../../../common/util/ManageUnitPriceSovColumn'

const i18nBase = 'integrations.pay_app_line_items_sync_dialog'

export enum PayAppLineItemColumn {
  CODE = 'code',
  DESCRIPTION = 'description',
  SCHEDULED_VALUE = 'scheduled_value',
  PREVIOUS_BILLED = 'previous_billed',
  AMOUNT = 'amount',
  RETAINAGE = 'retainage',
  RETAINAGE_RELEASED = 'retainage_released',
  UNITS = 'units',
  TAX_GROUP = 'tax_group',
}

export type ColumnOptions = {
  includeProgressBillingColumns: boolean
  includeRetentionColumn: boolean
  includeRetentionReleasedColumn: boolean
  includeUnitsColumn: boolean
  includeTaxGroupColumn: boolean
}

export function getSovColumns(
  t: TFunction,
  isEditable: boolean,
  options: ColumnOptions,
  taxGroups: TaxGroupProperties[]
): SpreadsheetColumn[] {
  const baseRows: SpreadsheetColumn[] = [
    {
      id: PayAppLineItemColumn.CODE,
      heading: t(`${i18nBase}.code`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
    },
    {
      id: PayAppLineItemColumn.DESCRIPTION,
      heading: t(`${i18nBase}.description`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      minWidth: 200,
    },
    {
      id: PayAppLineItemColumn.SCHEDULED_VALUE,
      heading: t(`${i18nBase}.scheduled_value`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
    {
      id: PayAppLineItemColumn.PREVIOUS_BILLED,
      heading: t(`${i18nBase}.previous_billed`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
  ]
  if (options.includeUnitsColumn) {
    baseRows.push({
      id: PayAppLineItemColumn.UNITS,
      heading: t(`${i18nBase}.units`),
      isEditable: false,
      dataType: SpreadsheetDataType.NUMBER,
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
    })
  }
  if (options.includeProgressBillingColumns) {
    baseRows.push({
      id: PayAppLineItemColumn.AMOUNT,
      heading: t(`${i18nBase}.amount`),
      isEditable,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 125,
    })
  }
  if (options.includeRetentionColumn) {
    baseRows.push({
      id: PayAppLineItemColumn.RETAINAGE,
      heading: t(`${i18nBase}.retainage`),
      isEditable,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 125,
    })
  }
  if (options.includeRetentionReleasedColumn) {
    baseRows.push({
      id: PayAppLineItemColumn.RETAINAGE_RELEASED,
      heading: t(`${i18nBase}.retainage_released`),
      isEditable,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 125,
    })
  }
  if (options.includeTaxGroupColumn) {
    const taxGroupOptions = _.chain(taxGroups)
      .map((taxGroup) => ({
        id: taxGroup.id,
        label: `${taxGroup.name} (${decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION)}%)`,
      }))
      .orderBy((taxGroup) => taxGroup.label)
      .value()
    baseRows.push({
      id: PayAppLineItemColumn.TAX_GROUP,
      heading: t(`${i18nBase}.tax_group`),
      isEditable,
      dataType: SpreadsheetDataType.SELECT,
      align: 'left',
      minWidth: 125,
      options: taxGroupOptions,
      searchPlaceholder: t(`${i18nBase}.search_tax_groups`),
      allowEmpty: true,
    })
  }
  return baseRows
}

type GetSovLineItemRowParams = {
  sovLineItem: IntegrationSovLineItem
  billedAmount: number
  retentionAmount: number
  releasedRetentionAmount: number
  sitelineTaxGroupId: string | null
  options: ColumnOptions
}

/** Returns a SpreadsheetRow component to be displayed in the Pay App Line Item invoice export dialog */
export function getSovLineItemRow({
  sovLineItem,
  billedAmount,
  retentionAmount,
  releasedRetentionAmount,
  sitelineTaxGroupId,
  options,
}: GetSovLineItemRowParams): SpreadsheetRow | null {
  // The interface allows this value to be null, but we expect it to exist for all ERP
  // line items, so this is to help the compiler
  if (!sovLineItem.integrationLineItemId) {
    return null
  }

  const bold = billedAmount !== 0 || retentionAmount !== 0 || releasedRetentionAmount !== 0
  const cells: SpreadsheetCell[] = [
    makeDataCell(sovLineItem.code),
    makeDataCell(sovLineItem.description),
    makeDataCell(centsToDollars(sovLineItem.scheduledValue)),
    makeDataCell(centsToDollars(sovLineItem.billedToDate)),
  ]
  if (options.includeUnitsColumn) {
    const billedCents = dollarsToCents(billedAmount)
    const unitPrice = safeDivide(billedCents, sovLineItem.unitPrice, 1)
    cells.push(makeDataCell(unitPrice))
  }
  if (options.includeProgressBillingColumns) {
    cells.push(makeDataCell(billedAmount, { bold }))
  }
  if (options.includeRetentionColumn) {
    cells.push(makeDataCell(retentionAmount, { bold }))
  }
  if (options.includeRetentionReleasedColumn) {
    cells.push(makeDataCell(releasedRetentionAmount, { bold }))
  }
  if (options.includeTaxGroupColumn) {
    cells.push(makeDataCell(sitelineTaxGroupId ?? ''))
  }

  return {
    type: SpreadsheetRowType.DEFAULT,
    id: sovLineItem.integrationLineItemId,
    cells,
  }
}

/**
 * Returns the totals row for the SOV in the ERP, with total amounts for each column to be displayed
 * as a footer row in the table.
 */
export function getSovLineItemTotalsRow(
  sovLineItems: readonly IntegrationSovLineItem[],
  totalAmount: number,
  totalRetention: number,
  totalRetentionReleased: number,
  t: TFunction,
  options: ColumnOptions
): SpreadsheetFooterRow {
  const scheduledValue = _.sumBy(sovLineItems, (sovLineItem) =>
    centsToDollars(sovLineItem.scheduledValue)
  )
  const billedToDate = _.sumBy(sovLineItems, (sovLineItem) =>
    centsToDollars(sovLineItem.billedToDate)
  )

  const cells = [
    makeContentCell(
      <SitelineText variant="secondary" bold>
        {t(`${i18nBase}.total`)}
      </SitelineText>,
      [],
      { colSpan: 2 }
    ),
    makeDataCell(scheduledValue),
    makeDataCell(billedToDate),
  ]
  if (options.includeUnitsColumn) {
    cells.push(makeContentCell(null, []))
  }

  if (options.includeProgressBillingColumns) {
    cells.push(makeDataCell(totalAmount, { bold: true }))
  }

  if (options.includeRetentionColumn) {
    cells.push(makeDataCell(totalRetention, { bold: true }))
  }
  if (options.includeRetentionReleasedColumn) {
    cells.push(makeDataCell(totalRetentionReleased, { bold: true }))
  }
  if (options.includeTaxGroupColumn) {
    cells.push(makeContentCell(null, []))
  }

  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'totals',
    cells,
    isNonEditableRow: true,
    isFixed: false,
  }
}
