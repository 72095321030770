import { Grid2 } from '@mui/material'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line no-restricted-imports
import { Document, DocumentProps, Page, Thumbnail, pdfjs } from 'react-pdf'
import LinkService from 'react-pdf/dist/esm/LinkService'
import { ScrollPageIntoViewArgs } from 'react-pdf/dist/esm/shared/types'
import DocumentErrorIcon from './assets/images/documentError.svg'
import { EmptyResultsView } from './common/components/EmptyResultsView'

// Import CSS required for react-pdf
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

// Configure worker
// Note that we don't use a CDN anymore because we need to inject a polyfill
// Note that we use the legacy build because the modern build uses Promise.withResolvers without polyfill.
// See https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

type PdfFallbackProps = {
  onReload: () => void
}

/**
 * Component that appears when a PDF fails to load.
 * This is usually due to a network error or a bug in react-pdf/pdfjs-dist.
 * The component shows a retry button that calls `onReload` when clicked.
 */
function PdfFallback({ onReload }: PdfFallbackProps) {
  const { t } = useTranslation()
  return (
    <Grid2 sx={{ width: '100%' }}>
      <Grid2>
        <EmptyResultsView
          subtitle={t('pdf_error.title')}
          buttonProps={{ label: t('pdf_error.try_again'), onClick: onReload }}
          image={DocumentErrorIcon}
        ></EmptyResultsView>
      </Grid2>
    </Grid2>
  )
}

type DocumentFromUrlOrBlobProps = Omit<DocumentProps, 'file'> & {
  file?: undefined | null | string | Blob
}

type DocumentRefParams = {
  linkService: React.RefObject<LinkService>
  pages: React.RefObject<HTMLDivElement[]>
  viewer: React.RefObject<{
    scrollPageIntoView: (args: ScrollPageIntoViewArgs) => void
  }>
}

/**
 * Wrapper around react-pdf's Document component that adds support for reloading the document, as
 * well as standard fonts (deprecated in PDF 1.5).
 */
const PdfDocument = forwardRef<DocumentRefParams, DocumentFromUrlOrBlobProps>(function PdfDocument(
  { file: originalFile, options, ...rest },
  ref
) {
  // Wrapper around the `file` prop of react-pdf's Document component.
  // If the file fails loading, we update it with a clone of the original file so
  // that the Document component re-renders.
  const [file, setFile] = useState<DocumentFromUrlOrBlobProps['file']>(originalFile)

  // Whenever the original file changes, reset the wrapped file to the original file.
  useEffect(() => setFile(originalFile), [originalFile])

  // Add support for standard fonts (deprecated in PDF 1.5).
  const optionsWithStandardFonts = useMemo((): DocumentProps['options'] => {
    return {
      ...options,
      // https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#setting-up-react-pdf-1
      standardFontDataUrl: `${import.meta.env.BASE_URL}standardFonts/`,
      // https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#setting-up-react-pdf
      cMapUrl: `${import.meta.env.BASE_URL}cmaps/`,
    }
  }, [options])

  // Called when clicking "Try again" in the error boundary fallback.
  const handleReload = useCallback(
    (resetErrorBoundary: () => void) => {
      // If there is no file yet, do nothing
      if (!file) {
        return
      }

      // If file is a blob, clone it to force a reload
      if (file instanceof Blob) {
        const newBlob = new Blob([file], { type: file.type })
        setFile(newBlob)

        // If file is a string (url), add a query parameter to force a reload
      } else {
        const newUrl = new URL(file)
        newUrl.searchParams.set('cacheBuster', Date.now().toString())
        setFile(newUrl.toString())
      }

      resetErrorBoundary()
    },
    [file]
  )

  // Fallback component (alert with "try again" button)
  const FallbackComponent = useCallback(
    ({ resetErrorBoundary }: FallbackProps) => (
      <PdfFallback onReload={() => handleReload(resetErrorBoundary)} />
    ),
    [handleReload]
  )

  return (
    <>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Document ref={ref} file={file} options={optionsWithStandardFonts} {...rest} />
      </ErrorBoundary>
    </>
  )
})

export { PdfDocument as Document, Page, Thumbnail }
