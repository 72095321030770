import { Divider, TextField, Theme } from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TAX_RATE_PERCENT_PRECISION,
  decimalToPercent,
  integrationTypes,
  supportsLinkingTaxGroup,
} from 'siteline-common-all'
import {
  SitelineText,
  colors,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { NumericFormatWithForwardRef } from '../../../../common/components/NumberFormat'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../../common/contexts/CompanyContext'
import * as fragments from '../../../../common/graphql/Fragments'
import {
  Company,
  TaxGroupProperties,
  useCreateTaxGroupMutation,
  useUpdateTaxGroupMutation,
} from '../../../../common/graphql/apollo-operations'
import { trackCreateTaxGroup, trackEditTaxGroup } from '../../../../common/util/MetricsTracking'
import { taxGroupPercentToDecimal } from '../../../../common/util/TaxGroup'
import { SelectTaxGroupIntegration } from './SelectTaxGroupIntegration'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      '& .label': {
        width: 150,
      },
    },
    '& .percentInput': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      height: 39,
      ...theme.typography.body1,
      '&:active, &:focus': {
        outline: 'none',
      },
    },
  },
}))

export type AddTaxRateLocation =
  | 'settings'
  | 'sov'
  | 'quickBill'
  | 'changeOrderRequest'
  | 'createProject'

interface AddOrEditTaxGroupDialogProps {
  open: boolean
  onClose: () => void
  taxGroup: TaxGroupProperties | null
  onAddTaxGroup: (taxGroup: TaxGroupProperties) => void
  location: AddTaxRateLocation
}

const i18nBase = 'projects.subcontractors.taxes'

/** Dialog for creating or editing a tax group */
export function AddOrEditTaxGroupDialog({
  open,
  onClose,
  taxGroup,
  onAddTaxGroup,
  location,
}: AddOrEditTaxGroupDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId, company } = useCompanyContext()
  const [createTaxGroup, { loading: creating }] = useCreateTaxGroupMutation()
  const [updateTaxGroup, { loading: updating }] = useUpdateTaxGroupMutation()
  const initialTaxName = useMemo(() => taxGroup?.name ?? '', [taxGroup])
  const [taxName, setTaxName] = useState(initialTaxName)
  const initialTaxPercent = useMemo(() => taxGroup?.taxPercent ?? null, [taxGroup])
  const [taxPercent, setTaxPercent] = useState<number | null>(initialTaxPercent)

  const companyIntegrations = useMemo(
    () => [...(company?.companyIntegrations ?? [])],
    [company?.companyIntegrations]
  )
  const taxGroupSupportedIntegrations = useMemo(
    () => companyIntegrations.filter((integration) => supportsLinkingTaxGroup(integration.type)),
    [companyIntegrations]
  )
  const initialIntegrationTaxGroupIds = useMemo(() => {
    if (!taxGroup) {
      return {}
    }
    const mappings = taxGroup.integrationMappings as integrationTypes.TaxGroupIntegrationMappings
    return _.fromPairs(
      mappings.integrations.map((mapping) => [
        mapping.companyIntegrationId,
        mapping.integrationTaxGroupId,
      ])
    )
  }, [taxGroup])
  // Map of company integration IDs to the selected integration tax group IDs
  const [integrationTaxGroupIds, setIntegrationTaxGroupIds] = useState<Record<string, string>>(
    initialIntegrationTaxGroupIds
  )

  const handleResetDialog = useCallback(() => {
    setTaxName(initialTaxName)
    setTaxPercent(initialTaxPercent)
    setIntegrationTaxGroupIds(initialIntegrationTaxGroupIds)
  }, [initialTaxName, initialTaxPercent, initialIntegrationTaxGroupIds])

  // Update the tax name and percent if the provided data changes
  useEffect(() => {
    handleResetDialog()
  }, [handleResetDialog])

  const isEditingTaxGroup = taxGroup !== null

  const handleSubmit = useCallback(async () => {
    if (!taxName || taxPercent === null) {
      return
    }

    const integrationMappingsInput = Object.entries(integrationTaxGroupIds).map(
      ([companyIntegrationId, integrationTaxGroupId]) => ({
        companyIntegrationId,
        integrationTaxGroupId,
      })
    )

    if (isEditingTaxGroup) {
      try {
        await updateTaxGroup({
          variables: {
            input: {
              taxGroupId: taxGroup.id,
              name: taxName,
              taxPercent,
              integrationMappings: integrationMappingsInput,
            },
          },
        })
        trackEditTaxGroup({
          taxGroupId: taxGroup.id,
          newTaxGroupName: taxName,
          newTaxRatePercent: taxPercent,
          newIntegrations: companyIntegrations
            .filter((integration) => Object.keys(integrationTaxGroupIds).includes(integration.id))
            .map((integration) => integration.shortName),
        })
        onClose()
      } catch (err) {
        snackbar.showError(err.message)
      }
    } else {
      try {
        const { data } = await createTaxGroup({
          variables: {
            input: {
              companyId,
              name: taxName,
              taxPercent,
              integrationMappings: integrationMappingsInput,
            },
          },
          update(cache, { data }) {
            if (!data || !company) {
              return
            }

            cache.modify<WritableDeep<Company>>({
              id: cache.identify(company),
              fields: {
                taxGroups(existingRequests, { toReference }) {
                  const newTaxGroupRef = cache.writeFragment({
                    data: data.createTaxGroup,
                    fragment: fragments.taxGroup,
                    fragmentName: 'TaxGroupProperties',
                  })
                  const refs = toReferences(existingRequests, toReference)
                  return _.compact([...refs, newTaxGroupRef])
                },
              },
            })
          },
        })
        trackCreateTaxGroup({ taxGroupName: taxName, taxRatePercent: taxPercent, location })
        if (data) {
          onAddTaxGroup(data.createTaxGroup)
        }
      } catch (err) {
        snackbar.showError(err.message)
      }
    }
  }, [
    company,
    companyId,
    companyIntegrations,
    createTaxGroup,
    integrationTaxGroupIds,
    isEditingTaxGroup,
    location,
    onAddTaxGroup,
    onClose,
    snackbar,
    taxGroup?.id,
    taxName,
    taxPercent,
    updateTaxGroup,
  ])

  const handleChangePercent = useCallback((value: number | undefined) => {
    const decimalPercent = value !== undefined ? taxGroupPercentToDecimal(value) : null
    setTaxPercent(decimalPercent)
  }, [])

  const handleSelectIntegrationTaxGroupId = useCallback(
    (integrationTaxGroupId: string | null, companyIntegrationId: string) =>
      setIntegrationTaxGroupIds((taxGroupIds) =>
        integrationTaxGroupId
          ? {
              ...taxGroupIds,
              [companyIntegrationId]: integrationTaxGroupId,
            }
          : _.omit(taxGroupIds, companyIntegrationId)
      ),
    []
  )

  const shouldDisableSubmit = !taxName.trim() || taxPercent === null

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      disableSubmit={shouldDisableSubmit}
      submitting={creating || updating}
      title={isEditingTaxGroup ? t(`${i18nBase}.edit_tax_group`) : t(`${i18nBase}.add_tax_group`)}
      subtitle={isEditingTaxGroup ? undefined : t(`${i18nBase}.create_description`)}
      subtitleVariant="body1"
      submitLabel={isEditingTaxGroup ? t('common.actions.save') : t('common.actions.add')}
      onResetDialog={handleResetDialog}
    >
      <div className={classes.root}>
        <div className="row">
          <SitelineText variant="body1" color="grey50" className="label">
            {t(`${i18nBase}.name`)}
          </SitelineText>
          <TextField
            variant="outlined"
            value={taxName}
            onChange={(evt) => setTaxName(evt.currentTarget.value)}
            autoFocus={!taxGroup}
          />
        </div>
        <div className="row">
          <SitelineText variant="body1" color="grey50" className="label">
            {t(`${i18nBase}.tax_rate_percent`)}
          </SitelineText>
          <NumericFormatWithForwardRef
            value={
              taxPercent !== null ? decimalToPercent(taxPercent, TAX_RATE_PERCENT_PRECISION) : ''
            }
            onValueChange={({ floatValue }) => handleChangePercent(floatValue)}
            decimalScale={TAX_RATE_PERCENT_PRECISION}
            displayType="input"
            thousandSeparator={true}
            suffix="%"
            allowNegative={false}
            className="percentInput"
          />
        </div>
        {taxGroupSupportedIntegrations.length > 0 && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <SitelineText variant="h4" color="grey50">
              {t(`${i18nBase}.link_tax_group`)}
            </SitelineText>
            {taxGroupSupportedIntegrations.map((integration) => (
              <SelectTaxGroupIntegration
                key={integration.id}
                companyIntegration={integration}
                selectedIntegrationTaxGroupId={_.get(integrationTaxGroupIds, integration.id, null)}
                onSelectIntegrationTaxGroupId={handleSelectIntegrationTaxGroupId}
              />
            ))}
          </>
        )}
      </div>
    </SitelineDialog>
  )
}
