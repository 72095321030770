import { DialogContentText, DialogProps } from '@mui/material'
import { clsx } from 'clsx'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast } from 'siteline-common-web'
import { SitelineDialog } from './SitelineDialog'

const useStyles = makeStylesFast(() => ({
  root: {
    whiteSpace: 'pre-wrap',
  },
}))

export type ConfirmationType = 'confirm' | 'delete'

type ConfirmationDialogProps = {
  open: boolean
  title: string
  details?: string | ReactNode
  onCancel: () => void
  onConfirm: () => void
  confirmationType: ConfirmationType
  disableConfirm?: boolean
  submitting?: boolean

  /** Optionally override the default maxWidth of 'xs' */
  maxWidth?: DialogProps['maxWidth']
  confirmLabel?: string
  cancelLabel?: string

  /** Optional button in the bottom left corner */
  subscript?: ReactNode
}

/** Dialog that asks the user to confirm an action or selection */
export function SitelineConfirmationDialog({
  open,
  title,
  details,
  onCancel,
  onConfirm,
  maxWidth,
  confirmationType,
  confirmLabel,
  disableConfirm = false,
  cancelLabel,
  subscript,
  submitting,
}: ConfirmationDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const submitLabel = useMemo(() => {
    if (confirmLabel) {
      return confirmLabel
    } else {
      return confirmationType === 'delete'
        ? t('common.actions.delete')
        : t('common.actions.confirm')
    }
  }, [confirmLabel, confirmationType, t])

  return (
    <SitelineDialog
      disableBackdrop
      disableEscapeKey
      maxWidth={maxWidth ?? 'xs'}
      open={open}
      title={title}
      className={clsx(classes.root, { delete: confirmationType === 'delete' })}
      onClose={onCancel}
      onSubmit={onConfirm}
      submitLabel={submitLabel}
      disableSubmit={disableConfirm}
      cancelLabel={cancelLabel}
      submitButtonColor={confirmationType === 'delete' ? 'destructive' : undefined}
      subscript={subscript}
      submitting={submitting}
    >
      <DialogContentText>{details}</DialogContentText>
    </SitelineDialog>
  )
}
