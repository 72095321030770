import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, Grow } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { SitelineText, colorStyles, colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2.5, 4),
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    fontFeatureSettings: 'normal',
    transition: theme.transitions.create(['background-color', 'border-color']),
    '& h3, & h4, & span': {
      transition: theme.transitions.create('color'),
    },
    '& .primaryButton': {
      alignSelf: 'center',
    },
    '&.green': {
      borderColor: colors.green30,
      backgroundColor: colors.green10,
      '&.isClickable:hover': {
        '& h3, & h4, & span': {
          color: colors.green70,
        },
        borderColor: colors.green40,
        backgroundColor: colors.green20,
      },
    },
    '&.purple': {
      borderColor: colors.purple30,
      backgroundColor: colors.purple10,
      '&.isClickable:hover': {
        '& h3, & h4, & span': {
          color: colors.purple70,
        },
        borderColor: colors.purple40,
        backgroundColor: colors.purple20,
      },
    },
    '&.blue': {
      borderColor: colors.blue30,
      backgroundColor: colors.blue10,
      '&.isClickable:hover': {
        '& h3, & h4, & span': {
          color: colors.blue70,
        },
        borderColor: colors.blue40,
        backgroundColor: colors.blue20,
      },
    },
    '&.red': {
      borderColor: colors.red30,
      backgroundColor: colors.red10,
      '&.isClickable:hover': {
        '& h3, & h4, & span': {
          color: colors.red70,
        },
        borderColor: colors.red40,
        backgroundColor: colors.red20,
      },
    },
    '&.yellow': {
      borderColor: colors.yellow30,
      backgroundColor: colors.yellow10,
      '&.isClickable:hover': {
        '& h3, & h4, & span': {
          color: colors.yellow70,
        },
        borderColor: colors.yellow40,
        backgroundColor: colors.yellow20,
      },
    },
    '&.isClickable': {
      cursor: 'pointer',
    },
    '& .link': {
      display: 'flex',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      flexGrow: 1,
      justifyContent: 'flex-end',
      padding: 0,
      marginLeft: theme.spacing(2),
      '&.buttonLink': {
        // Counteract the padding of the button so the link is aligned with the title and overview card buttons
        marginTop: theme.spacing(-0.75),
        marginRight: theme.spacing(-1),
      },
      '&.centered': {
        alignSelf: 'center',
        alignItems: 'center',
        marginTop: 0,
      },
    },
    '& .imageContainer': {
      display: 'flex',
      alignItems: 'center',
      minHeight: 48,
      '& > img': {
        width: 48,
        marginRight: theme.spacing(2.5),
      },
    },
    '& .textContent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '& .bottomMargin': {
        marginBottom: theme.spacing(0.5),
      },
    },
  },
  textAction: {
    '& .editButton': {
      height: 'unset',
      margin: theme.spacing(-1),
    },
  },
}))

interface Action {
  endIcon: ReactNode
  onClick: () => void
}

interface StatusBannerProps {
  title: string
  subtitle?: ReactNode
  textAction?: Action
  image?: string
  linkLabel?: string
  onClick?: () => void
  color: 'green' | 'purple' | 'blue' | 'red' | 'yellow'
  className?: string
  /**
   * - If the action type is 'banner', the whole banner will be clickable and the link text
   *   will be colored based on the banner theme
   * - If the action type is 'link', a text button will be clickable and its color will match
   *   that of the banner
   * - If the action type is 'button', a contained primary button will be clickable
   * @default 'banner'
   */
  actionType?: 'banner' | 'link' | 'button'
}

/** A banner with an image, title, subtitle, and link */
export function StatusBanner({
  title,
  subtitle,
  textAction,
  image,
  linkLabel,
  onClick,
  color,
  actionType = 'banner',
  className,
}: StatusBannerProps) {
  const classes = useStyles()

  const isBannerClickable = actionType === 'banner'

  let textColor: keyof typeof colorStyles = 'grey50'
  switch (color) {
    case 'green':
      textColor = 'green50'
      break
    case 'purple':
      textColor = 'purple50'
      break
    case 'blue':
      textColor = 'blue50'
      break
    case 'red':
      textColor = 'red50'
      break
    case 'yellow':
      textColor = 'yellow50'
      break
  }

  const textContainer = (
    <div className="textContent">
      <SitelineText
        variant="h3"
        bold
        color={textColor}
        className={clsx({ bottomMargin: subtitle !== undefined })}
        endIcon={textAction?.endIcon}
      >
        {title}
      </SitelineText>
      {subtitle && (
        <SitelineText variant="smallText" color={textColor}>
          {subtitle}
        </SitelineText>
      )}
    </div>
  )

  return (
    <TransitionGroup component={null} exit={false}>
      {title && (
        <Grow appear={false}>
          <div
            className={clsx(classes.root, className, color, { isClickable: isBannerClickable })}
            onClick={isBannerClickable ? onClick : undefined}
          >
            {image && (
              <div className="imageContainer">
                <img src={image} alt={title} />
              </div>
            )}
            {textAction ? (
              <div className={classes.textAction}>
                <Button
                  onClick={(evt) => {
                    evt.stopPropagation()
                    textAction.onClick()
                  }}
                  className="editButton"
                >
                  {textContainer}
                </Button>
              </div>
            ) : (
              textContainer
            )}
            {linkLabel && (
              <div
                className={clsx('link', { centered: !subtitle, buttonLink: !isBannerClickable })}
              >
                {actionType === 'banner' && (
                  <SitelineText variant="h4" endIcon={<KeyboardArrowRightIcon />} color={textColor}>
                    {linkLabel}
                  </SitelineText>
                )}
                {actionType === 'link' && (
                  <Button variant="text" onClick={onClick}>
                    <SitelineText variant="h4" color={textColor}>
                      {linkLabel}
                    </SitelineText>
                  </Button>
                )}
                {actionType === 'button' && (
                  <Button variant="contained" onClick={onClick} className="primaryButton">
                    {linkLabel}
                  </Button>
                )}
              </div>
            )}
          </div>
        </Grow>
      )}
    </TransitionGroup>
  )
}
