import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FEE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import {
  ContractRateTableFee,
  SitelineText,
  colors,
  makeStylesFast,
  useToggle,
} from 'siteline-common-web'
import {
  BillingType,
  RateTableFeeProperties,
  RateTableGroupProperties,
  RateTableProperties,
} from '../../../common/graphql/apollo-operations'
import { getMappedRateTableFees, getSortedRateTableGroups } from '../../../common/util/Pricing'
import { ContractForProjectHome } from '../home/ProjectHome'
import { AddOrEditFee, AddOrEditFeeDialog, createEmptyFee } from './AddOrEditFeeDialog'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& button': {
      whiteSpace: 'nowrap',
    },
    '& .addNewButton': {
      // This smidge of margin prevents a wonky transition on collapse/expand of the section
      // where the top part of the border disappears before everything else
      marginTop: theme.spacing(0.5),
    },
    '& .section': {
      marginBottom: theme.spacing(2),
    },
    '& .advancedSection': {
      marginBottom: theme.spacing(2),
    },
    '& .sectionTitle': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .categoryFeesSubtitle': {
      // This subtitle gets pushed down because of button padding
      marginTop: theme.spacing(-0.5),
    },
    '& .advancedButton': {
      marginLeft: theme.spacing(-1),
    },
    '& .expandIcon': {
      // Animate the arrow icon flipping around when the menu opens
      transition: theme.transitions.create('transform'),
      '&.expanded': {
        // Flip the dropdown arrow upside down when the menu opens
        transform: 'rotate(-180deg)',
      },
    },
    '& .category': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(3),
    },
    '& .feeTable': {
      marginTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
    },
    '& .feeTableRow': {
      borderBottom: `1px solid ${colors.grey20}`,
      borderRadius: 0,
      padding: 0,
      '&:first-of-type': {
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${colors.grey20}`,
      },
      '& .editIcon': {
        opacity: 0,
        color: colors.grey50,
        transition: theme.transitions.create('opacity'),
      },
      '&:hover': {
        backgroundColor: colors.grey10,
        '& .editIcon': {
          opacity: 1,
        },
      },
      '&:disabled': {
        color: colors.grey70,
      },
    },
    '& .feeTableRowDescription': {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1.5),
    },
    '& .feeTableRowPercent': {
      width: 100,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(1.5),
      borderLeft: `1px solid ${colors.grey20}`,
    },
    '& .noFees': {
      fontStyle: 'italic',
      color: colors.grey50,
      marginTop: theme.spacing(1),
    },
  },
}))

const i18nBase = 'projects.onboarding.checklist'

interface FeeTablesProps {
  rateTable: RateTableProperties | null
  contract: ContractForProjectOnboarding | ContractForProjectHome
  isEditing?: boolean
  // Used for metrics
  location: 'projectOnboarding' | 'projectSettings'
}

/** Displays breakdown on fees on the rate table task card */
export function FeeTables({ contract, rateTable, isEditing = true, location }: FeeTablesProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isAdvancedFeesOpen, handleOpenAdvanced, handleCloseAdvanced] = useToggle()
  const [addOrEditFee, setAddOrEditFee] = useState<AddOrEditFee | null>(null)

  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS

  const rateTableGroups = useMemo(
    () => (rateTable ? getSortedRateTableGroups({ rateTable }) : []),
    [rateTable]
  )

  const rateTableFees = useMemo(() => [...contract.rateTableFees], [contract.rateTableFees])

  const { globalFees, globalFeeIds, feeIdsByGroupId, sortedRateTableFees } = useMemo(
    () => getMappedRateTableFees({ rateTableGroups, rateTableFees }),
    [rateTableFees, rateTableGroups]
  )

  const handleToggleAdvanced = useCallback(() => {
    if (isAdvancedFeesOpen) {
      handleCloseAdvanced()
    } else {
      handleOpenAdvanced()
    }
  }, [handleCloseAdvanced, handleOpenAdvanced, isAdvancedFeesOpen])

  const handleOpenNewFeeDialog = useCallback(() => {
    const emptyFee = createEmptyFee(null)
    setAddOrEditFee(emptyFee)
  }, [])

  const handleOpenNewCategoryFeeDialog = useCallback(() => {
    if (!rateTable) {
      console.error('Cannot add a category fee if a rate table has not been selected')
      return
    }
    if (rateTable.groups.length === 0) {
      console.error('Cannot add category fee to rate table with no groups')
      return
    }
    const defaultGroup = rateTable.groups[0]
    const emptyFee = createEmptyFee(defaultGroup)
    setAddOrEditFee(emptyFee)
  }, [rateTable])

  const handleOpenEditFeeDialog = useCallback(
    (fee: AddOrEditFee, group?: RateTableGroupProperties) => {
      if (fee.id === null) {
        console.error('Fee id should not be null')
        return
      }
      const isCategoryFee = group !== undefined
      const derivesFromGlobalFee = globalFeeIds.has(fee.id)
      const isOverride = !!fee.overridesFee
      const shouldOverride = isCategoryFee && derivesFromGlobalFee
      const isFeeDeleted = isOverride && fee.percent === 0
      setAddOrEditFee({
        ...fee,
        group: group ?? null,
        shouldOverride,
        isFeeDeleted,
      })
    },
    [globalFeeIds]
  )

  const handleFeeDialogClose = useCallback(() => {
    setAddOrEditFee(null)
  }, [])

  const shouldShowAdvanced = rateTable && rateTable.groups.length > 0

  return (
    <div className={classes.root}>
      <div className="section">
        <div className="sectionTitle">
          <SitelineText variant="h4" bold>
            {t(`${i18nBase}.general_fees`)}
          </SitelineText>
          {isEditing && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleOpenNewFeeDialog}
              className="addNewButton"
            >
              {t(`${i18nBase}.add_fee`)}
            </Button>
          )}
        </div>
        <SitelineText variant="body2" color="grey50">
          {isTimeAndMaterials
            ? t(`${i18nBase}.general_fees_subtitle`)
            : t(`${i18nBase}.general_fees_subtitle_cor`)}
        </SitelineText>
        <div className="groupFeeTable feeTable">
          {globalFees.map((fee) => {
            return (
              <Button
                key={`global - ${fee.id}`}
                variant="text"
                color="secondary"
                onClick={() => handleOpenEditFeeDialog(fee as RateTableFeeProperties)}
                className="feeTableRow"
                disabled={!isEditing}
              >
                <SitelineText
                  variant="body2"
                  className="feeTableRowDescription"
                  endIcon={<EditIcon className="editIcon" fontSize="small" />}
                >
                  {fee.description}
                </SitelineText>
                <SitelineText variant="body2" className="feeTableRowPercent">
                  {`${decimalToPercent(fee.percent, FEE_PERCENT_PRECISION)}%`}
                </SitelineText>
              </Button>
            )
          })}
        </div>
      </div>
      {shouldShowAdvanced && (
        <div className={'advancedSection'}>
          <Button variant="text" className="advancedButton" onClick={handleToggleAdvanced}>
            <SitelineText
              variant="h4"
              color="grey90"
              endIcon={
                <ExpandMoreIcon
                  fontSize="small"
                  className={clsx('expandIcon', { expanded: isAdvancedFeesOpen })}
                />
              }
            >
              {t(`${i18nBase}.category_fees`)}
            </SitelineText>
          </Button>
          <Collapse in={isAdvancedFeesOpen}>
            <div className="section">
              <div className="sectionTitle categoryFeesSubtitle">
                {isEditing && (
                  <>
                    <SitelineText variant="body2" color="grey50">
                      {rateTableFees.length
                        ? t(`${i18nBase}.category_fees_subtitle_click`)
                        : t(`${i18nBase}.category_fees_subtitle`)}
                    </SitelineText>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={handleOpenNewCategoryFeeDialog}
                      className="addNewButton"
                    >
                      {t(`${i18nBase}.add_group_fee`)}
                    </Button>
                  </>
                )}
              </div>
              <div className="feeTable">
                {rateTableGroups.map((group) => {
                  const groupFees = feeIdsByGroupId[group.id]
                  return (
                    <div key={group.id} className="category">
                      <SitelineText variant="body2" bold>
                        {group.name}
                      </SitelineText>
                      {groupFees.size === 0 && (
                        <SitelineText variant="body2" className="noFees">
                          {t(`${i18nBase}.no_fees_yet`)}
                        </SitelineText>
                      )}
                      {sortedRateTableFees.map((fee) => {
                        if (groupFees.has(fee.id)) {
                          return (
                            <Button
                              key={`${group.id} - ${fee.id}`}
                              variant="text"
                              color="secondary"
                              onClick={() =>
                                handleOpenEditFeeDialog(fee as ContractRateTableFee, group)
                              }
                              className="feeTableRow"
                              disabled={!isEditing}
                            >
                              <SitelineText
                                variant="body2"
                                className="feeTableRowDescription"
                                endIcon={<EditIcon className="editIcon" fontSize="small" />}
                              >
                                {fee.description}
                              </SitelineText>
                              <SitelineText variant="body2" className="feeTableRowPercent">
                                {`${decimalToPercent(fee.percent, FEE_PERCENT_PRECISION)}%`}
                              </SitelineText>
                            </Button>
                          )
                        }
                        return null
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          </Collapse>
        </div>
      )}
      <AddOrEditFeeDialog
        addOrEditFee={addOrEditFee}
        onClose={handleFeeDialogClose}
        groups={rateTableGroups}
        contract={contract}
        location={location}
      />
    </div>
  )
}
