import { Radio, RadioProps } from '@mui/material'
import { clsx } from 'clsx'
import { makeStylesFast } from 'siteline-common-web'
import RadioCheckedIcon from '../../assets/icons/radio-checked.svg'
import RadioIcon from '../../assets/icons/radio.svg'

const useStyles = makeStylesFast(() => ({
  radio: {
    width: 24,
    height: 24,
  },
  disabled: {
    opacity: 0.5,
  },
}))

/**
 * Uses custom icon and colors for Radio button. Custom icon has a white background, inside of the
 * radio button itself, which is not available via normal color options.
 */
export function SitelineRadio(props: RadioProps) {
  const classes = useStyles()
  return (
    <Radio
      icon={
        <img
          src={RadioIcon}
          alt="Radio"
          className={clsx(classes.radio, { [classes.disabled]: props.disabled })}
        />
      }
      checkedIcon={
        <img
          src={RadioCheckedIcon}
          alt="RadioChecked"
          className={clsx(classes.radio, { [classes.disabled]: props.disabled })}
        />
      }
      color="primary"
      {...props}
    />
  )
}
