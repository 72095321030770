import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { Theme, styled } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
// @ts-expect-error Could not get typings to work
import path from 'path-browserify'
import { PropsWithChildren, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ACCEPTED_FILE_TYPES, MAX_FILENAME_LENGTH } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { StoredFileType } from '../../../../common/graphql/apollo-operations'
import { useSitelineDropzone } from '../../../../common/util/ReactDropzone'
import { FileDragUploadInput } from './FileDragUploadInput'

const THUMBNAIL_SIZE = 32
const i18nBase = 'projects.subcontractors.pay_app.attachments.add'

type StyleProps = {
  size: 'small' | 'medium'
}

const StyledAddFileContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'size',
})<StyleProps>(({ theme, size }) => ({
  border: '1px dashed',
  borderColor: colors.grey50,
  backgroundColor: colors.white,
  borderRadius: 4,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(size === 'medium' ? 10 : 5),
  '&.fileDrag': {
    borderColor: colors.green40,
    backgroundColor: colors.green10,
  },
  '& .textContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .uploadIcon': {
    color: colors.grey40,
    fontSize: 70,
    paddingBottom: theme.spacing(2),
  },
  '& .uploadText': {
    paddingBottom: theme.spacing(1),
  },
  '&.hasFiles': {
    padding: theme.spacing(5),
  },
  '&.rowLayout': {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: ({ size }: StyleProps) => theme.spacing(size === 'medium' ? 6 : 3),
    '& .uploadIcon': { fontSize: 56, paddingBottom: 0 },
    '& .uploadText': {
      paddingBottom: ({ size }: StyleProps) => theme.spacing(size === 'medium' ? 1 : 0),
    },
    '& .textContainer': {
      marginLeft: theme.spacing(3),
      alignItems: 'flex-start',
    },
  },
}))

const useStyles = makeStylesFast((theme: Theme) => ({
  files: {
    padding: theme.spacing(1, 0),
    '&.noDropBox': {
      marginTop: theme.spacing(-2),
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      borderBottom: '1px solid',
      borderBottomColor: colors.grey30,
      '& .MuiInputBase-root': {
        border: '1px solid',
        borderColor: colors.grey30,
        padding: theme.spacing(0.5, 1),
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.subtitle1.fontWeight,
        backgroundColor: colors.white,
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(0),
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      '& span': {
        lineHeight: '27px', // Overriding SitelineText because of icons
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
      },
      '& .delete': {
        margin: theme.spacing(0, 0.5, 0, 1),
      },
    },
  },
  thumbnail: {
    width: THUMBNAIL_SIZE,
    minWidth: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    objectFit: 'cover',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    borderRadius: theme.spacing(0.5),
  },
}))

// File types that can be rendered as a thumbnail
export const THUMBNAIL_FILE_TYPES = [StoredFileType.JPEG, StoredFileType.PNG]

export type PendingFile = {
  file?: File
  name: string
  type: StoredFileType
}

// Generates a human-readable file name given a string that could contain special characters
// like dashes and underscore.
//
// Example input: "somethingGreat-like_that"
// Example output: "Something Great Like That"
//
// This contains a safe version of https://github.com/sindresorhus/humanize-string which doesn't
// use `decamelize` because it has regex lookbacks which are not supported by Safari.
// See https://github.com/siteline/siteline/issues/7759
//
export function getHumanFilename(str: string): string {
  return _.chain(str)
    .snakeCase() // Change camelCase to snake_case
    .lowerCase() // Lowercase
    .replace(/[_-]+/g, ' ') // Replace - and _ with spaces
    .replace(/\s{2,}/g, ' ') // Remove extra spaces within the string
    .trim() // Remove extra whitespace on both ends
    .replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase()) // Titleize the string
    .value()
}

/** Returns a callback function for handling uploaded backup files */
export function useBackupFilesDropHandler() {
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()

  return (files: File[]) => {
    const allowed: PendingFile[] = []
    for (const file of files) {
      // .dot & .doc files have the same file type, but .dot is not supported
      const isDotType = file.name.endsWith('.dot')
      const type = isDotType ? undefined : ACCEPTED_FILE_TYPES[file.type]
      const fileName = path.parse(file.name)
      let name = getHumanFilename(fileName.name)
      if (name.length > MAX_FILENAME_LENGTH) {
        snackbar.showInfo(t(`${i18nBase}.truncated`))
        name = name.slice(0, MAX_FILENAME_LENGTH)
      }

      if (!type) {
        snackbar.showError(
          t(`${i18nBase}.invalid`, {
            name,
            types: t(`${i18nBase}.types`),
          })
        )
        continue
      }

      allowed.push({ file, name, type })
    }

    return allowed
  }
}

interface FileDragUploadProps {
  pendingFiles: PendingFile[]
  setPendingFiles: (pendingFiles: PendingFile[]) => void
  allowMultiple: boolean
  autoFocusFilename?: boolean
  disableEditFilename?: boolean
  useRawFilename?: boolean
  size?: 'small' | 'medium'
  layout?: 'column' | 'row'
  disabled?: boolean

  /**
   * If onRemovePendingFile is set to null, files will not be removable from the drag and drop component. This is for cases where an attachment has already been uploaded,
   * the dialog is has been re-opened for editing, and we want to prevent the user from deleting the file that they're editing (the thought is that they can, however,
   * delete the file when the dialog is closed)
   */
  onRemovePendingFile: ((index: number) => void) | null
}

export function FileDragUpload({
  pendingFiles,
  setPendingFiles,
  onRemovePendingFile,
  allowMultiple,
  autoFocusFilename = true,
  size = 'medium',
  layout = 'column',
  disableEditFilename = false,
  useRawFilename = false,
  disabled = false,
  children,
}: PropsWithChildren<FileDragUploadProps>) {
  const classes = useStyles()
  const { t } = useTranslation()

  const snackbar = useSitelineSnackbar()

  const handlePendingFileChange = useCallback(
    (pendingFile: PendingFile, index: number) => {
      const files = [...pendingFiles]
      files[index] = pendingFile
      setPendingFiles(files)
    },
    [pendingFiles, setPendingFiles]
  )

  const handleFileDrop = useBackupFilesDropHandler()
  const onDrop = (acceptedFiles: File[]) => {
    const allowedFiles = handleFileDrop(acceptedFiles)
    if (allowMultiple) {
      setPendingFiles([...pendingFiles, ...allowedFiles])
    } else {
      setPendingFiles(allowedFiles)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useSitelineDropzone(t, snackbar, {
    onDrop,
    multiple: allowMultiple,
    disabled,
  })

  const hasFiles = pendingFiles.length > 0
  const showDropBox = allowMultiple || pendingFiles.length === 0
  const instructionText = allowMultiple ? 'drag_drop_files' : 'drag_drop_file'

  return (
    <>
      {showDropBox && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <StyledAddFileContainer
            size={size}
            className={clsx({
              fileDrag: isDragActive,
              hasFiles,
              rowLayout: layout === 'row',
            })}
          >
            <CloudUploadOutlinedIcon className="uploadIcon" />
            <div className="textContainer">
              <SitelineText
                variant={size === 'small' ? 'h4' : 'h3'}
                color="grey50"
                bold
                className="uploadText"
              >
                <Trans
                  i18nKey={`${i18nBase}.${instructionText}`}
                  components={{
                    bold: <span style={{ fontWeight: 600, color: colors.grey70 }} />,
                  }}
                />
              </SitelineText>
              <SitelineText variant="secondary" color="grey50">
                {t(`${i18nBase}.types`)}
              </SitelineText>
            </div>
          </StyledAddFileContainer>
        </div>
      )}
      {children ?? (
        <div className={clsx(classes.files, { noDropBox: !showDropBox })}>
          {pendingFiles.map((file, index) => (
            <div key={`${file.name}-${index}`}>
              <FileDragUploadInput
                pendingFile={file}
                onChange={(file) => handlePendingFileChange(file, index)}
                disabled={disableEditFilename}
                autoFocus={autoFocusFilename}
                useRawFilename={useRawFilename}
                onRemovePendingFile={onRemovePendingFile && (() => onRemovePendingFile(index))}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}
