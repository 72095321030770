import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { isDesktop } from 'react-device-detect'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { Z_INDEX } from '../themes/Main'
import {
  END_COLUMN_WIDTH,
  TableColumnWidth,
  TableColumnWidthType,
  TableContent,
  TableHeader,
  alignToJustifyContent,
  calculateColumnWidth,
} from './SitelineTable.lib'
import { SortType, SortableColumnHeader } from './SortableColumnHeader'

const transitionDuration = 200
/** May be used by table content that's visible only when its row is hovered */
export const VISIBLE_ON_ROW_HOVER_CLASS = 'visibleOnRowHover'
/** May be used by table content that's visible only when its row is NOT hovered */
export const HIDDEN_ON_ROW_HOVER_CLASS = 'hiddenOnRowHover'
/** May be used by table content that's visible only when its cell is hovered */
export const VISIBLE_ON_CELL_HOVER_CLASS = 'visibleOnCellHover'

const useStyles = makeStylesFast((theme: Theme) => ({
  table: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    minWidth: 'fit-content',
    '&.nonStickyHeader': {
      overflowX: 'scroll',
    },
    '& .row': {
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      position: 'relative',
      '& .cell': {
        boxSizing: 'border-box',
        flexGrow: 1,
        overflow: 'hidden',
        listStyle: 'none',
        display: 'flex',
        backgroundColor: colors.white,
        transition: theme.transitions.create('background-color', {
          duration: transitionDuration,
        }),
        minHeight: 56,
        [`& .${HIDDEN_ON_ROW_HOVER_CLASS}`]: {
          display: 'block',
          whiteSpace: 'nowrap',
        },
        [`& .${VISIBLE_ON_ROW_HOVER_CLASS}`]: {
          display: isDesktop ? 'none' : 'block',
        },
        [`& .${VISIBLE_ON_CELL_HOVER_CLASS}`]: {
          display: isDesktop ? 'none' : 'block',
        },
        [`&:hover .${VISIBLE_ON_CELL_HOVER_CLASS}`]: {
          display: 'block',
        },
      },
      '&.headerRow, &:not(:last-child):not(.noBottomBorder)': {
        borderBottom: `1px solid ${colors.grey20}`,
      },
      '& .cellInner': {
        padding: theme.spacing(1, 2),
        display: 'flex',
        width: '100%',
        textAlign: 'left',
        '& span': {
          textAlign: 'left',
        },
      },
      '& .headerCell': {
        display: 'block',
        minHeight: 'initial',
        lineHeight: '1em',
        '& .cellInner': {
          display: 'flex',
          alignItems: 'center',
        },
      },
      '& .arrowCell': {
        alignItems: 'center',
        '& > *': {
          opacity: 0,
          transition: theme.transitions.create('opacity', { duration: transitionDuration }),
        },
      },
      '&:hover .cell:not(.headerCell)': {
        backgroundColor: colors.grey10,
      },
      '&.clickable': {
        cursor: 'pointer',
        '&:hover .arrowCell, & .arrowCell:focus': {
          outline: 'none',
          '& > *': {
            opacity: 1,
          },
        },
      },
      '&:hover .cell': {
        [`& .${HIDDEN_ON_ROW_HOVER_CLASS}`]: {
          display: 'none',
        },
        [`& .${VISIBLE_ON_ROW_HOVER_CLASS}`]: {
          display: 'block',
          whiteSpace: 'nowrap',
        },
      },
      '& .rowOverlay': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: 'none',
      },
      '&.hiddenOnPrint': {
        [`@media print`]: {
          display: 'none',
        },
      },
    },
    '& .footer': {
      [`@media print`]: {
        // Override sticky/fixed position to force the footer to the bottom of the
        // table on print preview
        position: 'relative !important',
      },
    },
    '& .headerRow.includeTableBorders': {
      borderTop: `1px solid ${colors.grey20}`,
    },
    '&.grey': {
      '& .cellInner': {
        backgroundColor: colors.grey10,
      },
      '& .cell': {
        // Override white background applied to cell class
        backgroundColor: `${colors.grey10} !important`,
      },
      '& .headerRow.includeTableBorders': {
        borderTop: `1px solid ${colors.grey30}`,
      },
      '& .row.headerRow, & .row:not(:last-child):not(.noBottomBorder)': {
        borderBottom: `1px solid ${colors.grey30}`,
      },
      '& .row:hover .cell:not(.headerCell)': {
        backgroundColor: `${colors.grey20} !important`,
        '& .cellInner': {
          backgroundColor: colors.grey20,
        },
      },
    },
    '& .stickyLeft': {
      // This allows us to display a box shadow on the right edge of the sticky column
      overflow: 'visible !important',
    },
    // Creates a box shadow on the right edge of the sticky column
    '& .stickyLeft::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      boxShadow: '10px 0px 5px -2px rgba(0, 0, 0, 0.02)',
      pointerEvents: 'none',
    },
  },
}))

function alignToAlignItems(align: 'top' | 'center' | 'bottom' | undefined) {
  switch (align) {
    case 'top':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'bottom':
      return 'flex-end'
    case undefined:
      // Default to center align
      return 'center'
  }
}

const NUM_SKELETON_ROWS = 3

type BaseSitelineTableProps<T> = {
  content: TableContent<T>

  /**
   * If provided, will show a sticky header with this `top` value. If undefined,
   * the sticky header is disabled.
   */
  stickyHeaderTopOffset?: number

  /**
   * If provided, sticky position will be applied to the last row of table cells
   * with this `bottom` value. If undefined, the last row will not have a sticky
   * position applied.
   */
  stickyFooterBottomOffset?: number

  /**
   * If provided, fixed position will be applied to the last row of table cells with the given
   * margin removed from the width (relative to the browser width). Since a fixed element is
   * positioned relative to the window, the footer most likely needs side margins to match the
   * margins on the table. If undefined, the last row will not have fixed position.
   */
  fixedFooterSideMargins?: number

  /**
   * If provided, sticky position will be applied to the first (leftmost) column of
   * table cells with this `left` value. If undefined, the first column will not have
   * a sticky position applied.
   */
  stickyLeftColumnOffset?: number

  /** Include a border above the header row and below the last row. True by default. */
  includeTableBorders?: boolean

  /** Number of skeleton rows to show while loading; defaults to 3 */
  numLoadingRows?: number

  /** Default color theme is white. Grey color theme has grey10 background. */
  colorTheme?: 'white' | 'grey'

  id?: string
}

type SortableSitelineTableProps<T> = {
  sortBy: SortType<T>
  onSortByChange: (sortBy: SortType<T>) => void
}

export type SitelineTableProps<T> =
  | BaseSitelineTableProps<T>
  | (BaseSitelineTableProps<T> & SortableSitelineTableProps<T>)

function getColumnWidthStyle({
  columnIndex,
  numHeaders,
  includeArrowColumn,
  columnWidths,
}: {
  columnIndex: number
  numHeaders: number
  includeArrowColumn: boolean
  columnWidths: TableColumnWidth[] | undefined
}) {
  const width = calculateColumnWidth(columnIndex, numHeaders, includeArrowColumn, columnWidths)
  let minWidth: string | number | undefined = width
  if (columnWidths) {
    const column = columnWidths[columnIndex]
    minWidth =
      column.type === TableColumnWidthType.GROW && column.minWidth ? column.minWidth : width
  }
  const widthStyle = {
    width,
    minWidth,
    maxWidth: width,
  }
  return widthStyle
}

function getCellPaddingStyle<T>(columnCellPadding?: TableHeader<T>['columnCellPadding']) {
  return {
    ...(columnCellPadding === 'vertical-only' && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
    ...(columnCellPadding === 'none' && {
      // We write out each padding property explicitly so this overrides individual defaults
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }
}

/**
 * A responsive, column-oriented table with custom styling. Loosely based on
 * https://css-tricks.com/accessible-simple-responsive-tables/
 */
export function SitelineTable<T>({
  content: { headers, rows, columnWidths, loading, showLoadingMoreRow },
  stickyHeaderTopOffset,
  stickyFooterBottomOffset,
  fixedFooterSideMargins,
  stickyLeftColumnOffset,
  colorTheme = 'white',
  includeTableBorders = true,
  numLoadingRows = NUM_SKELETON_ROWS,
  id,
  ...props
}: SitelineTableProps<T>) {
  const classes = useStyles()

  const emptyCell = useCallback(
    (rowIndex?: number) => {
      const row = rowIndex !== undefined ? rows[rowIndex] : null
      let showArrow = row && row.onClick !== undefined
      if (row?.showArrowOnHover !== undefined) {
        showArrow = row.showArrowOnHover
      }
      return (
        <div
          tabIndex={showArrow ? 0 : undefined}
          className={clsx('cell', {
            arrowCell: showArrow,
            headerCell: rowIndex === undefined,
          })}
          style={{
            width: END_COLUMN_WIDTH,
            padding: 0,
            backgroundColor: row?.backgroundColor ?? undefined,
          }}
          onKeyDown={(evt) => {
            // Trigger onClick on enter for accessibility
            if (evt.key === 'Enter' && showArrow && row?.onClick) {
              row.onClick()
            }
          }}
        >
          {showArrow && <ChevronRightIcon style={{ color: colors.grey50 }} />}
        </div>
      )
    },
    [rows]
  )

  const skeletonRow = (rowIndex: number) => {
    return (
      <div className="row" key={rowIndex.toString()}>
        {_.times(headers.length, (columnIndex) => {
          const widthStyle = columnWidthStyles[columnIndex]
          const header = headers[columnIndex]
          return (
            <div
              key={`${rowIndex.toString()}-${columnIndex.toString()}`}
              className="cell"
              style={widthStyle}
            >
              <div className="cellInner" style={getCellPaddingStyle(header.columnCellPadding)}>
                <Skeleton variant="text" width="100%" />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const includeArrowColumn = useMemo(
    () => rows.some((row) => row.showArrowOnHover !== false && row.onClick !== undefined),
    [rows]
  )

  const columnWidthStyles = useMemo(
    () =>
      _.times(headers.length, (index) =>
        getColumnWidthStyle({
          columnIndex: index,
          numHeaders: headers.length,
          includeArrowColumn,
          columnWidths,
        })
      ),
    [columnWidths, headers.length, includeArrowColumn]
  )

  if (loading) {
    return (
      <div
        className={clsx(classes.table, colorTheme, {
          nonStickyHeader: stickyHeaderTopOffset === undefined,
        })}
      >
        <div className={clsx('row', 'headerRow', { includeTableBorders })}>
          {headers.map((header, columnIndex) => {
            const widthStyle = columnWidthStyles[columnIndex]
            return (
              <div
                key={header.key}
                className={clsx('cell', 'headerCell', header.className)}
                style={{
                  textAlign: header.align,
                  ...widthStyle,
                }}
              >
                <div
                  style={{
                    justifyContent: alignToJustifyContent(header.align),
                    ...getCellPaddingStyle(header.columnCellPadding),
                  }}
                  className="cellInner"
                >
                  {typeof header.label === 'string' ? (
                    <SitelineText variant="label" color="grey50">
                      {header.label}
                    </SitelineText>
                  ) : (
                    header.label
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {_.times(numLoadingRows, (rowIndex) => (
          <div className="row" key={rowIndex.toString()}>
            {skeletonRow(rowIndex)}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      id={id}
      className={clsx(classes.table, colorTheme, {
        nonStickyHeader: stickyHeaderTopOffset === undefined,
      })}
    >
      <div
        className={clsx('row', 'headerRow', { includeTableBorders })}
        style={
          stickyHeaderTopOffset !== undefined
            ? { position: 'sticky', top: stickyHeaderTopOffset, zIndex: Z_INDEX.stickyHeader }
            : undefined
        }
      >
        {headers.map((header, columnIndex) => {
          const isStickyLeft = columnIndex === 0 && stickyLeftColumnOffset !== undefined
          const widthStyle = columnWidthStyles[columnIndex]
          const stickyLeftStyle = isStickyLeft
            ? {
                position: 'sticky' as const,
                left: stickyLeftColumnOffset,
                zIndex: Z_INDEX.stickyColumn,
              }
            : {}
          return (
            <div
              style={{
                textAlign: header.align,
                ...stickyLeftStyle,
                ...widthStyle,
              }}
              className={clsx('cell', 'headerCell', header.className, {
                stickyLeft: isStickyLeft,
              })}
              key={header.key}
            >
              {'sortBy' in props && header.sortKey !== undefined && (
                <SortableColumnHeader
                  title={header.label}
                  tooltipLabel={header.sortLabels ? header.sortLabels[props.sortBy.order] : ''}
                  sortColumn={header.sortKey}
                  sortBy={props.sortBy}
                  onSortByChange={props.onSortByChange}
                  className="cellInner"
                  hasHorizontalPadding={
                    header.columnCellPadding !== 'none' &&
                    header.columnCellPadding !== 'vertical-only'
                  }
                  align={header.align}
                />
              )}
              {(!('sortBy' in props) || header.sortKey === undefined) && (
                <div
                  className="cellInner"
                  style={{
                    justifyContent: alignToJustifyContent(header.align),
                    ...getCellPaddingStyle(header.columnCellPadding),
                  }}
                >
                  {typeof header.label === 'string' ? (
                    <SitelineText variant="label" color="grey50">
                      {header.label}
                    </SitelineText>
                  ) : (
                    header.label
                  )}
                </div>
              )}
            </div>
          )
        })}
        {includeArrowColumn && emptyCell()}
      </div>
      {_.times(rows.length, (rowIndex) => {
        const row = rows[rowIndex]
        const isStickyFooter =
          stickyFooterBottomOffset !== undefined && rowIndex === rows.length - 1
        const isRowAboveStickyFooter =
          stickyFooterBottomOffset !== undefined && rowIndex === rows.length - 2
        const isFixedFooter = fixedFooterSideMargins !== undefined && rowIndex === rows.length - 1
        const isRowAboveFixedFooter =
          fixedFooterSideMargins !== undefined && rowIndex === rows.length - 2
        let style = {}
        if (isStickyFooter) {
          style = {
            position: 'sticky',
            zIndex: Z_INDEX.stickyFooter,
            bottom: stickyFooterBottomOffset,
            borderTop: `1px solid ${colors.grey20}`,
            borderBottom: `1px solid ${colors.grey20}`,
          }
        }
        if (isFixedFooter) {
          style = {
            position: 'fixed',
            zIndex: Z_INDEX.stickyFooter,
            bottom: 0,
            width: `calc(100% - ${fixedFooterSideMargins * 2}px)`,
            borderTop: `1px solid ${colors.grey20}`,
            borderBottom: `1px solid ${colors.grey20}`,
            overflow: 'auto',
          }
        }

        // The row may have a custom render function that returns the inner row element. Otherwise,
        // the cells are simply rendered as is.
        const renderInnerRow = row.onRenderRowCells ?? _.identity

        return (
          <div
            ref={row.ref}
            onScroll={row.onScroll}
            className={clsx('row', 'footer', {
              clickable: !!row.onClick,
              // The sticky footer has a top border, so the row above it should not have a
              // bottom border
              noBottomBorder:
                row.includeBottomBorder === false ||
                isRowAboveStickyFooter ||
                isRowAboveFixedFooter,
              includeTableBorders,
            })}
            key={rowIndex.toString()}
            onClick={() => {
              const row = rows[rowIndex]
              if (row.onClick) {
                row.onClick()
              }
            }}
            style={style}
          >
            {renderInnerRow(
              _.times(headers.length, (columnIndex) => {
                const isStickyLeft = columnIndex === 0 && stickyLeftColumnOffset !== undefined
                const widthStyle = columnWidthStyles[columnIndex]
                const header = headers[columnIndex]
                const cell = row.cells[columnIndex]
                const colorStyle =
                  cell.backgroundColor || row.backgroundColor
                    ? { backgroundColor: cell.backgroundColor ?? row.backgroundColor }
                    : {}
                const stickyLeftStyle = isStickyLeft
                  ? {
                      position: 'sticky' as const,
                      left: stickyLeftColumnOffset,
                      zIndex: Z_INDEX.stickyColumn,
                    }
                  : {}
                return (
                  <div
                    style={{
                      ...colorStyle,
                      ...widthStyle,
                      ...stickyLeftStyle,
                    }}
                    key={`${row.key}-${header.key}`}
                    className={clsx('cell', {
                      stickyLeft: isStickyLeft,
                    })}
                  >
                    <div
                      className="cellInner"
                      style={{
                        alignItems: alignToAlignItems(row.align),
                        justifyContent: alignToJustifyContent(header.align),
                        ...getCellPaddingStyle(header.columnCellPadding),
                      }}
                    >
                      {cell.content}
                    </div>
                  </div>
                )
              })
            )}
            {includeArrowColumn && emptyCell(rowIndex)}
            {row.overlayColor && (
              <div className="rowOverlay" style={{ backgroundColor: row.overlayColor }} />
            )}
          </div>
        )
      })}
      {showLoadingMoreRow && <div className={clsx('row', 'hiddenOnPrint')}>{skeletonRow(0)}</div>}
    </div>
  )
}
