import { NavigateOptions } from '@tanstack/react-router'
import moment from 'moment-timezone'
import { useLocalStorage } from '../../common/util/SafeLocalStorage'

export const FORM_PERIOD_END_FORMAT = 'YYYYMMDD'

/** Returns a complete URL for the path to a compliance page */
export function getCompliancePath({
  projectId,
  legalRequirementId,
  periodEnd,
}: {
  /** If only project id is passed, links to compliance project home */
  projectId?: string
  /** If only project id + requirement ID is passed, links to the detail page */
  legalRequirementId?: string
  /**
   * If provided, will link to the form view. Should be a valid moment
   * if recurring, or null for standard requirements with a single form.
   */
  periodEnd?: moment.Moment | null
} = {}): NavigateOptions {
  if (!projectId) {
    return { to: '/compliance' }
  }

  if (!legalRequirementId) {
    return { to: `/compliance/$projectId`, params: { projectId } }
  }

  if (periodEnd === undefined) {
    return {
      to: `/compliance/$projectId/$requirementId`,
      params: { projectId, requirementId: legalRequirementId },
    }
  }

  if (periodEnd === null) {
    return {
      to: '/compliance/$projectId/$requirementId/form',
      params: {
        projectId,
        requirementId: legalRequirementId,
      },
    }
  }

  return {
    to: '/compliance/$projectId/$requirementId/form/$periodEnd',
    params: {
      projectId,
      requirementId: legalRequirementId,
      periodEnd: periodEnd.format(FORM_PERIOD_END_FORMAT),
    },
  }
}

/** Utility hook for managing queued document IDs in local storage */
export function useQueuedLegalDocumentIds(projectId: string): [string[], (ids: string[]) => void] {
  const storageId = `compliance-${projectId}`
  const [storedDocumentIds, setStoredDocumentIds] = useLocalStorage(storageId, JSON.stringify([]))
  const queuedDocumentIds = JSON.parse(storedDocumentIds) as string[]
  const handleQueuedDocumentIdsChange = (ids: string[]) => {
    setStoredDocumentIds(JSON.stringify(ids))
  }
  return [queuedDocumentIds, handleQueuedDocumentIdsChange]
}
