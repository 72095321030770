import { createContext, useContext } from 'react'
import { Loader } from '../components/Loader'
import { SignatureProperties, useGetCurrentUserQuery } from '../graphql/apollo-operations'

export type UserProps = {
  id: string
  email: string
  firstName: string
  lastName: string
  defaultSignature: SignatureProperties | null
}

const UserContext = createContext<UserProps>({
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  defaultSignature: null,
})

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { data } = useGetCurrentUserQuery()
  const user = data?.currentUser
  if (!user) {
    return <Loader />
  }
  const contextValue = {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    defaultSignature: user.defaultSignature,
  }
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export function useUserContext() {
  return useContext(UserContext)
}
