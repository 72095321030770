import { useTranslation } from 'react-i18next'
import { ContractForVendorsCopyDialog } from '../../components/vendors/add-vendors/CopyProjectVendorsDialog'
import { ContractWithGc, contractsHaveSameGc } from '../util/Contact'
import { ComplianceContract } from '../util/LegalRequirement'
import { ContractForForms } from '../util/ProjectOnboarding'
import { BlueChip } from './SitelineChip'

interface SameGcChipProps {
  currentContract: ContractWithGc | undefined
  otherContract: ComplianceContract | ContractForForms | ContractForVendorsCopyDialog | undefined
}

/**
 * Status chip displayed on dropdown menus of projects to copy from. Indicates
 * that the current project has the same GC as the current project. Primarily
 * displayed on the CopyProjectFormsDialog component. Handles comparing GCs
 * and rendering conditionally.
 **/
export function SameGcChip({ currentContract, otherContract }: SameGcChipProps) {
  const { t } = useTranslation()
  if (!contractsHaveSameGc(currentContract, otherContract)) {
    return null
  }

  return <BlueChip size="small" label={t('projects.subcontractors.settings.forms.same_gc')} />
}
