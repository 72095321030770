import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { colors } from 'siteline-common-web'
import { TOP_HEADER_HEIGHT, Z_INDEX } from '../../common/themes/Main'

const SIDESHEET_WIDTH_PERCENT = 90
const SIDESHEET_WIDTH_PERCENT_MD = 70
const SIDESHEET_WIDTH_PERCENT_LG = 50
const StyledSidesheet = styled('div')(({ theme }) => ({
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: TOP_HEADER_HEIGHT,
  left: `${100 - SIDESHEET_WIDTH_PERCENT}%`,
  zIndex: Z_INDEX.sidesheet,
  backgroundColor: colors.white,
  borderLeft: `1px solid ${colors.grey20}`,
  borderRadius: '8px 0 0 8px',
  padding: theme.spacing(3, 4),
  overflow: 'auto',
  // It's important that we use a transform for animating in/out the sidebar rather than changing
  // the positioning or width, because the spreadsheet component is sized based on its container
  // and this way the container remains the same size and only its location is shifted.
  transform: 'translateX(100%)',
  transition: theme.transitions.create('transform', { duration: 150, easing: 'ease-out' }),
  '&.isOpen': {
    // It's important that there be no transform on the sidebar component when it's open, because
    // drag-and-drop will not work correctly on the spreadsheet if a parent of the Draggable
    // component has a transform applied
    transform: 'none',
  },
  [theme.breakpoints.up('md')]: {
    left: `${100 - SIDESHEET_WIDTH_PERCENT_MD}%`,
  },
  [theme.breakpoints.up('lg')]: {
    left: `${100 - SIDESHEET_WIDTH_PERCENT_LG}%`,
  },
}))
const StyledBackdrop = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  transition: theme.transitions.create('background-color', { duration: 150, easing: 'ease-out' }),
  pointerEvents: 'none',
  zIndex: Z_INDEX.sidesheetBackdrop,
  '&.isOpen': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    pointerEvents: 'auto',
  },
}))

interface SidesheetProps {
  isOpen: boolean
  onClose: () => void
  className?: string
}

export function Sidesheet({
  isOpen,
  onClose,
  className,
  children,
}: PropsWithChildren<SidesheetProps>) {
  return (
    <>
      <StyledSidesheet className={clsx(className, { isOpen })}>{children}</StyledSidesheet>
      <StyledBackdrop className={clsx({ isOpen })} onClick={onClose} />
    </>
  )
}
