import { DialogProps } from '@mui/material'
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { ConfirmationType, SitelineConfirmationDialog } from './SitelineConfirmationDialog'

export type ConfirmParams = {
  title: string
  details?: ReactNode
  callback: (confirmed: boolean) => void
  maxWidth?: DialogProps['maxWidth']

  /** Default is confirm. Delete button has a red background. */
  confirmationType?: ConfirmationType
  confirmLabel?: string
  cancelLabel?: string

  /** Optional button in the bottom left corner */
  subscript?: ReactNode
}

type ConfirmationContextType = {
  confirm: (params: ConfirmParams) => void
}

const ConfirmationContext = createContext<ConfirmationContextType>({
  confirm: (): void => undefined,
})

export function useSitelineConfirmation() {
  return useContext(ConfirmationContext)
}

export function SitelineConfirmationProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState<ReactNode | undefined>()
  const [open, setOpen] = useState(false)
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth'] | undefined>()
  const [confirmationType, setConfirmationType] = useState<ConfirmationType>('confirm')
  const [confirmLabel, setConfirmLabel] = useState<string | undefined>()
  const [cancelLabel, setCancelLabel] = useState<string | undefined>()
  const [callback, setCallback] = useState<(confirmed: boolean) => void>(() => () => undefined)
  const [subscript, setSubscript] = useState<ReactNode | undefined>()
  const [confirming, setConfirming] = useState<boolean>(false)

  const onCancel = useCallback(() => {
    callback(false)
    setOpen(false)
  }, [callback])

  const onConfirm = useCallback(async () => {
    setConfirming(true)
    await Promise.resolve(callback(true))
    setConfirming(false)
    setOpen(false)
  }, [callback])

  const contextValue = useMemo(
    () => ({
      confirm: (params: ConfirmParams) => {
        setTitle(params.title)
        setDetails(params.details)
        setOpen(true)
        setCallback(() => params.callback)
        setMaxWidth(params.maxWidth)
        setConfirmationType(params.confirmationType ?? 'confirm')
        setConfirmLabel(params.confirmLabel || undefined)
        setCancelLabel(params.cancelLabel || undefined)
        setSubscript(params.subscript || undefined)
      },
    }),
    []
  )

  return (
    <>
      <ConfirmationContext.Provider value={contextValue}>{children}</ConfirmationContext.Provider>
      <SitelineConfirmationDialog
        open={open}
        title={title}
        details={details}
        onCancel={onCancel}
        onConfirm={onConfirm}
        maxWidth={maxWidth}
        confirmationType={confirmationType}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
        subscript={subscript}
        submitting={confirming}
      />
    </>
  )
}
