import { createContext, useCallback, useContext, useMemo, useState } from 'react'

export type SpreadsheetContextProps = {
  focusedSpreadsheetId: string | null
  isFocusPaused: boolean
  focusSpreadsheet: (spreadsheetId: string) => void
  removeSpreadsheet: (spreadsheetId: string) => void

  /**
   * Disable all spreadsheets from being in focus, without removing them from the stack.
   * Useful for pausing focus while a modal is in the foreground, and focus will return
   * to the spreadsheet when it closes.
   */
  pauseFocus: () => void
  resumeFocus: () => void
}

const SpreadsheetContext = createContext<SpreadsheetContextProps>({
  focusedSpreadsheetId: null,
  isFocusPaused: false,
  focusSpreadsheet: () => {
    // Do nothing
  },
  removeSpreadsheet: () => {
    // Do nothing
  },
  pauseFocus: () => {
    // Do nothing
  },
  resumeFocus: () => {
    // Do nothing
  },
})

/** Provider for managing focus state among multiple spreadsheets, if simultaneously mounted */
export function SpreadsheetProvider({ children }: { children: React.ReactNode }) {
  // This is a stack in which the first spreadsheet in the list has focus
  const [activeSpreadsheetsIds, setActiveSpreadsheetIds] = useState<string[]>([])
  // If true, override the spreadsheet list and return null for the focused spreadsheet
  const [isFocusPaused, setIsFocusPaused] = useState<boolean>(false)

  const focusSpreadsheet = useCallback((spreadsheetId: string) => {
    setActiveSpreadsheetIds((spreadsheetIds) => [spreadsheetId, ...spreadsheetIds])
  }, [])

  const removeSpreadsheet = useCallback((spreadsheetId: string) => {
    setActiveSpreadsheetIds((spreadsheetIds) => spreadsheetIds.filter((id) => id !== spreadsheetId))
  }, [])

  const pauseFocus = useCallback(() => {
    setIsFocusPaused(true)
  }, [])

  const resumeFocus = useCallback(() => {
    setIsFocusPaused(false)
  }, [])

  const contextValue = useMemo(() => {
    const focusedSpreadsheetId = activeSpreadsheetsIds.length > 0 ? activeSpreadsheetsIds[0] : null
    return {
      focusedSpreadsheetId,
      isFocusPaused,
      focusSpreadsheet,
      removeSpreadsheet,
      pauseFocus,
      resumeFocus,
    }
  }, [
    focusSpreadsheet,
    removeSpreadsheet,
    activeSpreadsheetsIds,
    pauseFocus,
    resumeFocus,
    isFocusPaused,
  ])

  return <SpreadsheetContext.Provider value={contextValue}>{children}</SpreadsheetContext.Provider>
}

export function useSpreadsheetContext() {
  return useContext(SpreadsheetContext)
}
