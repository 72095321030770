import { Chip } from '@mui/material'
import { colors, withStylesFast } from 'siteline-common-web'

// Chips also have text and size information overriden in MainTheme (Main.ts)

export const GreyChip = withStylesFast({
  root: {
    backgroundColor: colors.grey10,
    color: colors.grey50,
  },
})(Chip)

export const GreyItalicsChip = withStylesFast({
  root: {
    backgroundColor: colors.grey10,
    color: colors.grey50,
  },
})(Chip)

export const MediumGreyChip = withStylesFast({
  root: {
    backgroundColor: colors.grey20,
    color: colors.grey60,
  },
})(Chip)

export const BlueChip = withStylesFast({
  root: {
    backgroundColor: colors.blue10,
    color: colors.blue50,
    '&.MuiButtonBase-root:hover': {
      backgroundColor: colors.blue20,
      color: colors.blue90,
    },
  },
})(Chip)

export const GreenChip = withStylesFast({
  root: {
    backgroundColor: colors.green10,
    color: colors.green50,
  },
})(Chip)

export const YellowChip = withStylesFast({
  root: {
    backgroundColor: colors.yellow20,
    color: colors.yellow50,
  },
})(Chip)

export const PurpleChip = withStylesFast({
  root: {
    backgroundColor: colors.purple10,
    color: colors.purple50,
  },
})(Chip)

export const RedChip = withStylesFast({
  root: {
    backgroundColor: colors.red10,
    color: colors.red50,
  },
})(Chip)
