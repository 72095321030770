import { TFunction } from 'i18next'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  decimalToPercent,
  doesChangeOrderRequestUsePricingTool,
  FEE_PERCENT_PRECISION,
  formatCentsToDollars,
} from 'siteline-common-all'
import { saveAs } from 'siteline-common-web'
import { CellObject, WorkSheet } from 'xlsx-js-style'
import {
  ChangeOrderRequestProperties,
  ChangeOrderRequestQuery,
  ChangeOrderRequestRateTableFeeProperties,
  ChangeOrderRequestRateTableGroupProperties,
  ChangeOrderRequestStatus,
  ContractStatus,
  PayAppRateTableFeeProperties,
  RateTableFeeProperties,
  RateTableProperties,
} from '../graphql/apollo-operations'
import { bold, getColumnSizesFromRows, leftAlign, number, string } from './Export'

/**
 * This set of utils is used for dealing with and making calculations based on rate table data.
 * This happens on both our T&M pay app invoice and our change order request pricing tool.
 */

type RateTableGroupWithIdOnly = {
  id: string
}

type RateTableFee =
  | RateTableFeeProperties
  | PayAppRateTableFeeProperties
  | ChangeOrderRequestRateTableFeeProperties

export type FeesIdSetByGroupId = Record<string, Set<string>>
export type FeeById = Record<string, RateTableFee>
export type FeesById = Record<string, RateTableFee[]>

/** Iterates through & creates maps of rate table fees, organized by group vs global */
export function getMappedRateTableFees({
  rateTableGroups,
  rateTableFees,
}: {
  rateTableGroups: RateTableGroupWithIdOnly[]
  rateTableFees: RateTableFee[]
}) {
  const sortedRateTableFees = _.orderBy(rateTableFees, (fee) => fee.description, 'asc')
  const globalFees = _.filter(sortedRateTableFees, (fee) => fee.group === null)
  const groupFees = _.filter(sortedRateTableFees, (fee) => fee.group !== null)

  const globalFeeIds = new Set(globalFees.map(({ id }) => id))
  const feeIdsByGroupId: FeesIdSetByGroupId = {}
  const feesByFeeId: FeeById = _.keyBy(rateTableFees, ({ id }) => id)
  const feesByGroupId: FeesById = {}

  // Create map by group id, add global fees to each group
  rateTableGroups.forEach((group) => {
    feeIdsByGroupId[group.id] = new Set([...globalFeeIds])
  })

  // Update map with group fee ids
  groupFees.forEach((fee) => {
    if (!fee.group) {
      return
    }
    if (fee.overridesFee) {
      // Remove overidden fee from group
      feeIdsByGroupId[fee.group.id].delete(fee.overridesFee.id)
    }
    // Add group fee to map
    feeIdsByGroupId[fee.group.id].add(fee.id)
  })

  // Build map of feesByGroupId based on `feeIdsByGroupId`
  Object.entries(feeIdsByGroupId).map(([groupId, groupFeeIdsSet]) => {
    feesByGroupId[groupId] = []
    Array.from(groupFeeIdsSet).forEach((feeId) => {
      feesByGroupId[groupId].push(feesByFeeId[feeId])
    })
  })

  return {
    globalFees,
    globalFeeIds,
    feeIdsByGroupId,
    sortedRateTableFees,
    feesByGroupId,
  }
}

/**
 * Takes a rate table and returns the rate tables groups sorted by corresponding line
 * item sort orders
 */
export function getSortedRateTableGroups({ rateTable }: { rateTable: RateTableProperties }) {
  const sortedLineItems = _.orderBy(rateTable.items, (item) => item.sortOrder, 'desc')
  const groupIdToSortOrder: Record<string, number> = {}

  sortedLineItems.forEach((lineItem) => {
    groupIdToSortOrder[lineItem.group.id] = lineItem.sortOrder
  })

  return _.orderBy(rateTable.groups, (group) => groupIdToSortOrder[group.id], 'asc')
}

export type ChangeOrderRequestForPricingTool = ChangeOrderRequestQuery['changeOrderRequest']

/**
 * When a project rate table is swapped, we don't touch any existing change order requests.
 * The user might want to manually update the rate table for change order requests that are
 * still in draft. This function is referenced when deciding if we should display a banner
 * prompting the user to update the rate table that the COR uses. Checks include:
 * - The COR must be using the pricing tool
 * - The COR must be in draft
 * - The change order request rate table is different from the project rate table
 * - The contract must be active
 **/
export function doesChangeOrderRequestUseOutdatedRateTable({
  changeOrderRequest,
}: {
  changeOrderRequest: Pick<
    ChangeOrderRequestForPricingTool,
    'rateTableItems' | 'status' | 'rateTable' | 'contract'
  >
}) {
  const { rateTableItems, status, rateTable, contract } = changeOrderRequest
  const { status: contractStatus } = contract

  if (status !== ChangeOrderRequestStatus.DRAFT) {
    return false
  }

  if (contractStatus !== ContractStatus.ACTIVE) {
    return false
  }

  if (!doesChangeOrderRequestUsePricingTool(rateTableItems)) {
    return false
  }

  return rateTable?.id !== contract.rateTable?.id
}

/**
 * When a rate table is updated, we don't touch any existing change order request rate table values.
 * For example, a rate table might have an item called "paint" that is priced at $4.00. The change order
 * request might bill against this value, and then the rate table may be updated to a new rate of $5.00.
 * By default, we do not update the COR pricing tool. We should detect if a change was made, and if so,
 * display a banner prompting the user to update the COR rates.
 * Checks include:
 * - The COR must be using the pricing tool
 * - The COR must be in draft
 * - The contract must be active
 * - Finally, we check a field that is calculated on the BE `hasContactRateTableChanged`
 **/
export function doesChangeOrderRequestUseOutdatedRateTableValues({
  changeOrderRequest,
}: {
  changeOrderRequest: Pick<
    ChangeOrderRequestForPricingTool,
    'rateTableItems' | 'status' | 'rateTable' | 'contract' | 'hasContractRateTableChanged'
  >
}) {
  const { rateTableItems, status, rateTable, contract, hasContractRateTableChanged } =
    changeOrderRequest
  const { status: contractStatus } = contract

  if (status !== ChangeOrderRequestStatus.DRAFT) {
    return false
  }

  if (contractStatus !== ContractStatus.ACTIVE) {
    return false
  }

  if (!doesChangeOrderRequestUsePricingTool(rateTableItems)) {
    return false
  }

  if (rateTable === null) {
    return false
  }

  return hasContractRateTableChanged
}

/**
 * When a project's fees are updated via settings, we don't touch any existing change order request
 * rate table fees, but we do surface this change to the user, prompting them to update via banner
 * CTA. This function determines whether project fees have change
 * Checks include:
 * - The COR must be using the pricing tool
 * - The COR must be in draft
 * - The contract must be active
 * - Finally, we check a field that is calculated on the BE `haveContractFeesChanged`
 **/
export function doesChangeOrderRequestUseOutdatedFees({
  changeOrderRequest,
}: {
  changeOrderRequest: Pick<
    ChangeOrderRequestForPricingTool,
    'rateTableItems' | 'status' | 'rateTable' | 'contract' | 'haveContractFeesChanged'
  >
}) {
  const { rateTableItems, status, rateTable, contract, haveContractFeesChanged } =
    changeOrderRequest
  const { status: contractStatus } = contract

  if (status !== ChangeOrderRequestStatus.DRAFT) {
    return false
  }

  if (contractStatus !== ContractStatus.ACTIVE) {
    return false
  }

  if (!doesChangeOrderRequestUsePricingTool(rateTableItems)) {
    return false
  }

  if (rateTable === null) {
    return false
  }

  return haveContractFeesChanged
}

function formatDateForExcelExport({
  date,
  shouldAddTimeStamp,
  timeZone,
}: {
  date?: string
  shouldAddTimeStamp: boolean
  timeZone: string
}) {
  const dateMoment = date ? moment.tz(date, timeZone) : moment.tz(timeZone)
  const format = shouldAddTimeStamp ? 'MM/DD/YY [at] h:mma z' : 'MM/DD/YY'
  return dateMoment.format(format)
}

function formatPricingToolTotal(value: number): string {
  return formatCentsToDollars(value, true)
}

enum PricingTableHeader {
  DESCRIPTION = 'Description',
  CODE = 'Code',
  UNIT_NAME = 'Unit name',
  UNIT_RATE = 'Unit rate',
  UNIT_QUANTITY = 'Unit quantity',
  TOTAL = 'Total',
}

interface PricingToolToXlsxArgs {
  changeOrderRequest: ChangeOrderRequestProperties
  timeZone: string
  t: TFunction
  projectName: string
  projectNumber: string
}

/**
 * Exports COR pricing tool data to an excel worksheet that can be rendered to XLSX or CSV.
 */
async function corPricingToolDataToWorksheet({
  changeOrderRequest,
  timeZone,
  t,
  projectName,
  projectNumber,
}: PricingToolToXlsxArgs): Promise<WorkSheet> {
  const i18nBase = 'projects.subcontractors.change_order_requests.pricing_tool.excel_export'

  // Sort, group, & format data for worksheet
  const { rateTableFees, rateTable, rateTableGroups, rateTableItems } = changeOrderRequest
  const globalFees = rateTableFees.filter((fee) => fee.group === null)
  const groupFees = rateTableFees.filter((fee) => fee.group !== null)
  const globalFeeIds = new Set(globalFees.map(({ id }) => id))
  const feesByGroupId: Record<string, Set<string>> = {}
  const groupsById: Record<string, ChangeOrderRequestRateTableGroupProperties | undefined> =
    _.keyBy(rateTableGroups, (group) => group.id)

  // Add global fees to each group in map
  rateTableGroups.forEach((group) => {
    feesByGroupId[group.id] = new Set([...globalFeeIds])
  })

  // Add group fees to their respective map group
  groupFees.forEach((fee) => {
    if (!fee.group) {
      return
    }
    if (fee.overridesFee) {
      // Remove overidden fee from group
      feesByGroupId[fee.group.id].delete(fee.overridesFee.id)
    }
    // Add group fee to map
    feesByGroupId[fee.group.id].add(fee.id)
  })

  const pricedLineItems = rateTableItems.filter((item) => item.currentUnitsPriced > 0)
  const sortedLineItems = _.orderBy(pricedLineItems, (item) => item.sortOrder)
  const lastLineItemIndex = sortedLineItems.length - 1

  const rows: CellObject[][] = []

  // Header
  const formattedGenerationDate = formatDateForExcelExport({ timeZone, shouldAddTimeStamp: true })
  rows.push([bold(string(t(`${i18nBase}.title`)))])
  rows.push([string(t(`${i18nBase}.generated`, { date: formattedGenerationDate }))])
  rows.push([])

  // Project info
  rows.push([bold(string(t(`${i18nBase}.project_name`))), leftAlign(string(projectName))])
  rows.push([bold(string(t(`${i18nBase}.project_number`))), leftAlign(string(projectNumber))])
  if (rateTable?.name) {
    rows.push([bold(string(t(`${i18nBase}.rate_table`))), leftAlign(string(rateTable.name))])
  }
  rows.push([])

  // COR info
  rows.push([bold(string(t(`${i18nBase}.cor_name`))), leftAlign(string(changeOrderRequest.name))])
  if (changeOrderRequest.internalNumber) {
    rows.push([
      bold(string(t(`${i18nBase}.cor_internal_number`))),
      leftAlign(string(changeOrderRequest.internalNumber)),
    ])
  }
  if (changeOrderRequest.generalContractorNumber) {
    rows.push([
      bold(string(t(`${i18nBase}.gc_number`))),
      leftAlign(string(changeOrderRequest.generalContractorNumber)),
    ])
  }
  if (changeOrderRequest.reason) {
    rows.push([bold(string(t(`${i18nBase}.reason`))), leftAlign(string(changeOrderRequest.reason))])
  }

  rows.push([])

  // Pricing table headers
  const tableHeaders = Object.values(PricingTableHeader).map((headerName) =>
    bold(string(headerName))
  )

  // Pricing table
  const tableRows: CellObject[][] = []
  sortedLineItems.forEach((item, index) => {
    const group = groupsById[item.group.id]
    if (!group) {
      return
    }

    const groupFees = feesByGroupId[group.id]
    const previousLineItem = index > 0 ? sortedLineItems[index - 1] : null
    const previousGroup = previousLineItem ? previousLineItem.group : null
    const nextLineItem = index < lastLineItemIndex ? sortedLineItems[index + 1] : null
    const nextGroup = nextLineItem ? nextLineItem.group : null
    const isNewGrouping = index === 0 || group.id !== previousGroup?.id
    const isEndOfGrouping = index === lastLineItemIndex || group.id !== nextGroup?.id

    if (isNewGrouping) {
      if (index !== 0) {
        // Empty row
        tableRows.push([])
      }

      // Group name
      tableRows.push([bold(string(group.name))])
    }

    const row: CellObject[] = []
    for (const header of Object.values(PricingTableHeader)) {
      switch (header) {
        case PricingTableHeader.DESCRIPTION:
          row.push(string(item.description))
          break
        case PricingTableHeader.CODE:
          row.push(string(item.code ?? ''))
          break
        case PricingTableHeader.UNIT_NAME:
          row.push(string(item.unitName ?? ''))
          break
        case PricingTableHeader.UNIT_RATE:
          row.push(string(formatCentsToDollars(item.unitRate)))
          break
        case PricingTableHeader.UNIT_QUANTITY:
          row.push(number(item.currentUnitsPriced))
          break
        case PricingTableHeader.TOTAL:
          row.push(string(formatPricingToolTotal(item.currentPriced)))
          break
      }
    }
    tableRows.push(row)

    if (isEndOfGrouping) {
      // Group subtotal
      tableRows.push([
        bold(string(t(`${i18nBase}.group_subtotal`))),
        string(''),
        string(''),
        string(''),
        string(''),
        bold(string(formatPricingToolTotal(group.subtotalAmount))),
      ])

      // Group fees
      rateTableFees.forEach((fee) => {
        if (groupFees.has(fee.id)) {
          const feePercent = decimalToPercent(fee.percent, FEE_PERCENT_PRECISION)
          const feeAmount = formatPricingToolTotal(fee.percent * group.subtotalAmount)
          tableRows.push([
            bold(string(`${fee.description} (${feePercent}%)`)),
            string(''),
            string(''),
            string(''),
            string(''),
            bold(string(feeAmount)),
          ])
        }
      })

      // Group total
      tableRows.push([
        bold(string(t(`${i18nBase}.group_total`))),
        string(''),
        string(''),
        string(''),
        string(''),
        bold(string(formatPricingToolTotal(group.totalAmount))),
      ])
    }
  })

  tableRows.push([])

  // COR total
  tableRows.push([
    bold(string(t(`${i18nBase}.total_amount`))),
    string(''),
    string(''),
    string(''),
    string(''),
    bold(string(formatPricingToolTotal(changeOrderRequest.amount ?? 0))),
  ])

  rows.push(tableHeaders)
  rows.push(...tableRows)

  const { utils } = await import('xlsx-js-style')
  const sheet = utils.json_to_sheet(rows, { skipHeader: true })
  const autoFitRows = [tableHeaders, ...tableRows]
  sheet['!cols'] = getColumnSizesFromRows(autoFitRows)
  return sheet
}

export async function exportPricingToolDataToXlsx(args: PricingToolToXlsxArgs): Promise<void> {
  const i18nBase = 'projects.subcontractors.change_order_requests.pricing_tool.excel_export'
  const { utils, write } = await import('xlsx-js-style')
  const sheet = await corPricingToolDataToWorksheet(args)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, sheet, 'Change Order Pricing')
  const out = write(workbook, { type: 'array' })
  const blob = new Blob([out], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  })
  saveAs(
    blob,
    args.t(`${i18nBase}.excel_filename`, { changeOrderRequestName: args.changeOrderRequest.name })
  )
}
