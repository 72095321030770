import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Wrapper around `window.localStorage` which swallows errors so that components can render even
 * if a preference cannot be loaded from the browser.
 * See https://sentry.io/organizations/siteline/issues/3528996152/?project=5374497&referrer=slack
 */
export const safeLocalStorage: Storage = {
  clear: () => {
    try {
      return window.localStorage.clear()
    } catch {
      // Ignore
    }
  },
  getItem: (key) => {
    try {
      return window.localStorage.getItem(key)
    } catch {
      return null
    }
  },
  key: (index) => {
    try {
      return window.localStorage.key(index)
    } catch {
      return null
    }
  },
  removeItem: (key) => {
    try {
      return window.localStorage.removeItem(key)
    } catch {
      // Ignore
    }
  },
  setItem: (key, value) => {
    try {
      return window.localStorage.setItem(key, value)
    } catch {
      // Ignore
    }
  },
  get length() {
    try {
      return window.localStorage.length
    } catch {
      return 0
    }
  },
}

/**
 * Hook for creating a state variable that is persisted in local storage.
 * Based on `react-use-localstorage`, but using our SafeLocalStorage.
 */
export function useLocalStorage<T extends string>(
  key: string,
  initialValue: T
): [T, (update: T) => void] {
  const defaultValue = useMemo(() => {
    const storedValue = safeLocalStorage.getItem(key)
    if (storedValue) {
      return storedValue as T
    }
    return initialValue
  }, [key, initialValue])
  const [value, setValue] = useState<T>(defaultValue)

  const setItem = useCallback(
    (newValue: T) => {
      setValue(newValue)
      safeLocalStorage.setItem(key, newValue)
    },
    [key]
  )

  // Update the state if the key changes, or initial value changes and there's
  // no value stored yet
  useEffect(() => {
    const newValue = safeLocalStorage.getItem(key) as T
    setValue(newValue || initialValue)
  }, [key, initialValue])

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === key && event.newValue !== value) {
        setValue((event.newValue as T) || initialValue)
      }
    },
    [value, key, initialValue]
  )

  // Update the state if a storage event triggers for this key
  useEffect(() => {
    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [handleStorage])

  return useMemo(() => [value, setItem], [setItem, value])
}

const DEFAULT_FILTERS_VALUE: string[] = []
const DEFAULT_FILTER_VALUE: string | null = null

/**
 * Hook encapsulates interaction with local storage and exposes the same getters
 * and setters as useState. This allows us to easily store selected filters on the
 * Reporting Page, which is applicable to users who only deal with/ care about
 * subsets of data within the report.
 *
 * Use this for filters that accept multiple values.
 */
export function useFiltersStore<T>(storageId: string): [T[] | null, (update: T[] | null) => void] {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    storageId,
    JSON.stringify(DEFAULT_FILTERS_VALUE)
  )

  return useMemo(() => {
    const value = JSON.parse(localStorageValue) as T[]
    const setValue = (update: T[] | null) => {
      setLocalStorageValue(JSON.stringify(update ?? DEFAULT_FILTERS_VALUE))
    }
    return [value.length ? value : null, setValue]
  }, [localStorageValue, setLocalStorageValue])
}

/**
 * Use this for filters that accept a single value (eg: aging amount type).
 */
export function useFilterStore<T>(storageId: string): [T | null, (update: T | null) => void] {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    storageId,
    JSON.stringify(DEFAULT_FILTER_VALUE)
  )

  return useMemo(() => {
    const value = JSON.parse(localStorageValue) as T
    const setValue = (update: T | null) => {
      setLocalStorageValue(JSON.stringify(update ?? DEFAULT_FILTER_VALUE))
    }
    return [value, setValue]
  }, [localStorageValue, setLocalStorageValue])
}
