import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardContent, CardMedia } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { getIcon, isImage } from '../../../../common/util/PayAppAttachment'
import { AttachmentForViewer } from './AttachmentViewerDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    border: 'none',
    borderRadius: '6px', // 2 pixels less than spacing to prevent clipping bug
  },
  media: {
    height: 94,
    padding: theme.spacing(3),
    textAlign: 'center',
    backgroundColor: colors.grey10,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  content: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
}))

type AttachmentThumbnailProps = {
  attachment: AttachmentForViewer
}

export function AttachmentThumbnail({ attachment }: AttachmentThumbnailProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card variant="outlined" className={classes.root}>
      {isImage(attachment.file.type) ? (
        <CardMedia className={classes.media} image={attachment.file.url} />
      ) : (
        <CardMedia className={classes.media}>
          <FontAwesomeIcon icon={getIcon(attachment.file.type)} size="3x" color={colors.grey50} />
        </CardMedia>
      )}
      <CardContent className={classes.content}>
        <SitelineText variant="smallText" className={classes.name}>
          {attachment.name ||
            (isImage(attachment.file.type)
              ? t('projects.subcontractors.pay_app.attachments.image')
              : t('projects.subcontractors.pay_app.attachments.attachment'))}
        </SitelineText>
      </CardContent>
    </Card>
  )
}
