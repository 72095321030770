import CloseIcon from '@mui/icons-material/Close'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Autocomplete, Grow, IconButton, TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { clampToLines } from 'siteline-common-web/src/utils/CSS'
import { Avatar } from '../../../common/components/Avatar'
import { BlueChip } from '../../../common/components/SitelineChip'
import { useUserContext } from '../../../common/contexts/UserContext'
import {
  CompanyUserRole,
  ContractUserRole,
  UserStatus,
} from '../../../common/graphql/apollo-operations'
import { filterOutSitelineEmails } from '../../../common/util/User'
import { CompanyUserForManagingUsers } from './ManageUsersDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  autocompleteDropdown: {
    '& .MuiSvgIcon-root': { color: colors.grey50 },
  },
  userList: {
    height: 144,
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.white,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
    border: `1px solid ${colors.grey30}`,
    marginTop: theme.spacing(1.5),
    '&.lg': {
      height: 228,
    },
    '& .userRow': {
      display: 'flex',
      alignItems: 'center',
      '& .userName': {
        margin: theme.spacing(0, 2, 0, 1),
        width: '30%',
        ...clampToLines(1),
      },
      '& .flexGrow': {
        flexGrow: 1,
      },
      '& .adminIcon': {
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': { fontSize: 20, color: colors.grey40 },
      },
      '& .leadChip': {
        marginRight: theme.spacing(0.5),
        '& > *': {
          fontSize: theme.typography.caption.fontSize,
        },
      },
      '& .leadPMIcon': { color: colors.blue50 },
      '& .leadPMIconUnfilled': { color: colors.grey50 },
      '& .removeButton': {
        pointerEvents: 'auto',
        '& .MuiSvgIcon-root': { fontSize: 20 },
      },
    },
  },
}))

export type UserWithContractRole = {
  userId: string
  contractRole: ContractUserRole
}

interface ManageProjectTeamProps {
  companyUsers: CompanyUserForManagingUsers[]
  teamMembers: UserWithContractRole[]
  onToggleTeamMemberAdded: (userId: string, selected: boolean) => void
  onChangeContractUserRole: (userId: string, contractRole: ContractUserRole) => void
  size?: 'md' | 'lg'
}

const i18nBase = 'projects.onboarding.checklist'

/** Interface for adding and removing users for a project, and designating the lead PMs */
export function ManageProjectTeam({
  companyUsers,
  teamMembers,
  onToggleTeamMemberAdded,
  onChangeContractUserRole,
  size,
}: ManageProjectTeamProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentUser = useUserContext()

  const allCompanyUsers = useMemo(() => {
    const filteredCompanyUsers = filterOutSitelineEmails(currentUser, companyUsers)
    return filteredCompanyUsers.filter((companyUser) => companyUser.status === UserStatus.ACTIVE)
  }, [companyUsers, currentUser])

  const projectCompanyUsers = useMemo(() => {
    const allTeamMembers = allCompanyUsers.filter(({ user }) =>
      teamMembers.some((teamMember) => teamMember.userId === user.id)
    )
    return _.orderBy(
      allTeamMembers,
      [
        // The current user should always be first in the list
        ({ user }) => user.id === currentUser.id,
        // Other users are sorted by name
        ({ user }) => `${user.firstName} ${user.lastName}`,
      ],
      ['desc', 'asc']
    )
  }, [allCompanyUsers, teamMembers, currentUser])

  const autocompleteOptions = useMemo(
    () =>
      _.chain(allCompanyUsers)
        .filter(({ user }) => !teamMembers.some((teamMember) => teamMember.userId === user.id))
        .map(({ user }) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        }))
        .orderBy((user) => user.name)
        .value(),
    [allCompanyUsers, teamMembers]
  )

  return (
    <div>
      <Autocomplete
        // Resets the autocomplete when a project is selected so the input clears
        key={teamMembers.length}
        freeSolo={false}
        options={autocompleteOptions}
        autoHighlight
        autoComplete
        openOnFocus
        noOptionsText={t(`${i18nBase}.no_users`)}
        onChange={(ev, option) => {
          if (!option) {
            return
          }
          onToggleTeamMemberAdded(option.id, true)
        }}
        className={classes.autocompleteDropdown}
        getOptionLabel={(project) => project.name}
        renderInput={(params) => (
          <TextField variant="outlined" placeholder={t(`${i18nBase}.teammate_name`)} {...params} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <SitelineText variant="body1">{option.name}</SitelineText>
            </li>
          )
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <div className={clsx(classes.userList, size)}>
        {projectCompanyUsers.map((companyUser) => {
          const teamMember = teamMembers.find(({ userId }) => userId === companyUser.user.id)
          const isLeadPM = teamMember?.contractRole === ContractUserRole.LEAD_PM
          const isCurrentUser = companyUser.user.id === currentUser.id
          return (
            <div key={companyUser.id} className="userRow">
              <Avatar
                user={companyUser.user}
                textColor={isLeadPM ? 'grey20' : 'grey70'}
                color={isLeadPM ? 'grey70' : 'grey20'}
                size="lg"
              />
              <SitelineText variant="body1" color="grey90" className="userName">
                {companyUser.user.firstName} {companyUser.user.lastName}
              </SitelineText>
              <SitelineText variant="body1" color="grey50">
                {companyUser.user.jobTitle}
              </SitelineText>
              <div className="flexGrow" />
              <Grow in={isLeadPM}>
                <BlueChip label={t(`${i18nBase}.lead`)} size="small" className="leadChip" />
              </Grow>
              <Tooltip
                title={isLeadPM ? t(`${i18nBase}.click_to_undo`) : t(`${i18nBase}.make_lead_pm`)}
                disableInteractive
                placement="right"
              >
                <IconButton
                  key={String(isLeadPM)}
                  onClick={() =>
                    onChangeContractUserRole(
                      companyUser.user.id,
                      isLeadPM ? ContractUserRole.MEMBER : ContractUserRole.LEAD_PM
                    )
                  }
                >
                  {isLeadPM ? (
                    <StarIcon className="leadPMIcon" />
                  ) : (
                    <StarBorderIcon className="leadPMIconUnfilled" />
                  )}
                </IconButton>
              </Tooltip>
              {companyUser.role === CompanyUserRole.ADMINISTRATOR ? (
                <div className="adminIcon">
                  <Tooltip
                    title={t(`${i18nBase}.admin_access`)}
                    disableInteractive
                    placement="right"
                    enterDelay={250}
                    enterNextDelay={250}
                  >
                    <PrivacyTipIcon />
                  </Tooltip>
                </div>
              ) : (
                <Tooltip
                  title={
                    isCurrentUser
                      ? t(`${i18nBase}.cannot_remove_self`)
                      : t(`${i18nBase}.remove_from_project`)
                  }
                  disableInteractive
                  placement="right"
                  enterDelay={250}
                  enterNextDelay={250}
                >
                  <div className="removeButton">
                    <IconButton
                      onClick={() => onToggleTeamMemberAdded(companyUser.user.id, false)}
                      disabled={isCurrentUser}
                    >
                      <CloseIcon style={{ color: isCurrentUser ? colors.grey30 : colors.grey50 }} />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
