import { OutlinedTextFieldProps, TextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode, memo } from 'react'
import {
  SitelineText,
  SitelineTextProps,
  SitelineVariant,
  makeStylesFast,
} from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > .label': {
        display: 'flex',
      },
      '& .endAdornment': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
      },
    },
    '& > .subtitle': {
      marginTop: theme.spacing(0.5),
    },
  },
}))

export interface TextFieldWithLabelProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  label: string
  labelVariant?: SitelineVariant
  subtitle?: string
  endAdornment?: ReactNode
  inputLabel?: TextFieldProps['label']
  LabelProps?: Omit<SitelineTextProps, 'variant'> & { variant?: SitelineTextProps['variant'] }
  labelRowClassName?: string
}

/** A text field with a label above it */
export const TextFieldWithLabel = memo(function TextFieldWithLabel(
  props: PropsWithChildren<TextFieldWithLabelProps>
) {
  const {
    label,
    labelVariant,
    subtitle,
    inputLabel,
    LabelProps,
    children,
    className,
    labelRowClassName,
    endAdornment,
    slotProps,
    ...restProps
  } = props
  const classes = useStyles()

  return (
    <div className={className}>
      <div className={classes.root}>
        <div className={clsx('title', labelRowClassName)}>
          <SitelineText variant={labelVariant ?? 'label'} className="label" {...LabelProps}>
            {label}
          </SitelineText>
          {endAdornment && <div className="endAdornment">{endAdornment}</div>}
        </div>
        {subtitle && (
          <SitelineText variant="smallText" color="grey50" className="subtitle">
            {subtitle}
          </SitelineText>
        )}
      </div>
      {children === undefined && (
        <TextField
          fullWidth
          variant="outlined"
          label={inputLabel}
          slotProps={slotProps}
          {...restProps}
        />
      )}
      {children}
    </div>
  )
})
