import { NavigateOptions } from '@tanstack/react-router'
import { BillingType } from 'siteline-common-all'

export enum FieldGuestBillingPathType {
  Home,
  ProjectHome,
  PayApp,
}

type GetFieldGuestBillingPathParams =
  | { pathType: FieldGuestBillingPathType.Home }
  | { pathType: FieldGuestBillingPathType.ProjectHome; projectId: string }
  | { pathType: FieldGuestBillingPathType.PayApp; projectId: string; payAppId: string }

export function getFieldGuestBillingPath(params: GetFieldGuestBillingPathParams): NavigateOptions {
  switch (params.pathType) {
    case FieldGuestBillingPathType.Home:
      return { to: '/field' }
    case FieldGuestBillingPathType.ProjectHome:
      return { to: '/field/billing/$projectId/payApps', params: { projectId: params.projectId } }
    case FieldGuestBillingPathType.PayApp:
      return {
        to: '/field/billing/$projectId/payApps/$payAppId',
        params: { projectId: params.projectId, payAppId: params.payAppId },
      }
  }
}

// The px width breakpoint that defines various styles on the project list,
// (e.g. hiding columns on smaller screen sizes)
export const FIELD_GUEST_SMALL_SCREEN_WIDTH = 800

export const FIELD_GUEST_BILLING_TYPES = [BillingType.LUMP_SUM, BillingType.UNIT_PRICE]
