import { MenuItem } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { BillingPathType, getBillingPath } from '../Billing.lib'
import { ContractForProjectHome } from '../home/ProjectHome'

interface CreateOrViewUnconditionalMenuItemProps {
  contract: ContractForProjectHome
  month: moment.Moment
  hasUnconditional: boolean
}

/**
 * Allow you to create an unconditional lien waiver for a month when you've opted out of billing, or
 * to view the unconditional if it already exists.
 */
export function CreateOrViewUnconditionalMenuItem({
  contract,
  month,
  hasUnconditional,
}: CreateOrViewUnconditionalMenuItemProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <MenuItem
      onClick={() =>
        navigate(
          getBillingPath({
            pathType: BillingPathType.OptedOutMonthUnconditional,
            projectId: contract.project.id,
            month,
          })
        )
      }
    >
      {hasUnconditional
        ? t('projects.subcontractors.pay_app.create_or_opt_out.view_unconditional')
        : t('projects.subcontractors.pay_app.create_or_opt_out.create_unconditional')}
    </MenuItem>
  )
}
