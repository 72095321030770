import { ApolloProvider } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import _ from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AGAVE_CONNECTOR_OFFLINE_ERROR_SUBSTRING } from 'siteline-common-all'
import { makeApolloClient } from '../../client'
import { AgaveConnectorOfflinePopup } from './AgaveConnectorOfflinePopup'

// Keep the popup visible for 30 seconds. It would be very difficult to hide it only once the
// Connector is responsive again, because we'd need to identify every resolver that eventually
// pings the Connector, so we just keep it visible for a fixed amount of time and then show it again
// if the user attempts another action that returns the offline error.
const POPUP_TIMEOUT = 30_000

/**
 * A wrapper around `ApolloProvider` that injects an error link for intercepting Agave Connector
 * offline errors and displaying a popup to the user.
 */
export function SitelineApolloProvider({ children }: { children: ReactNode }) {
  // We intentionally use `useState` here instead of `useToggle` because it allows us to avoid
  // passing `isAgaveConnectorPopupOpen` as a dependency to the `apolloClient` memoization
  const [isAgaveConnectorPopupOpen, setAgaveConnectorPopupOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)

  const apolloClient = useMemo(() => {
    const agaveConnectorOfflineLink = onError(({ graphQLErrors }) => {
      if (!graphQLErrors) {
        return
      }
      const errorMessage = _.first(graphQLErrors)?.message
      if (!errorMessage?.includes(AGAVE_CONNECTOR_OFFLINE_ERROR_SUBSTRING)) {
        return
      }
      setErrorMessage(errorMessage)
      setAgaveConnectorPopupOpen((isOpen) => {
        if (!isOpen) {
          return true
        }
        // If the popup is already open, reset the timeout
        if (timer.current) {
          clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => setAgaveConnectorPopupOpen(false), POPUP_TIMEOUT)
        return true
      })
    })

    return makeApolloClient([agaveConnectorOfflineLink])
  }, [])

  const handleCloseAgaveConnectorPopup = useCallback(() => setAgaveConnectorPopupOpen(false), [])

  // The popup automatically closes after several seconds if not manually dismissed
  useEffect(() => {
    if (isAgaveConnectorPopupOpen) {
      timer.current = setTimeout(handleCloseAgaveConnectorPopup, POPUP_TIMEOUT)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [handleCloseAgaveConnectorPopup, isAgaveConnectorPopupOpen])

  return (
    <>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      <AgaveConnectorOfflinePopup
        isOpen={isAgaveConnectorPopupOpen}
        onClose={handleCloseAgaveConnectorPopup}
        error={errorMessage}
      />
    </>
  )
}
