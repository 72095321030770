import { Link } from '@mui/material'
import { Trans } from 'react-i18next'
import { SitelineText, SitelineVariant } from 'siteline-common-web'

const HELP_CENTER_LINK =
  'https://support.siteline.com/hc/en-us/articles/13027254317588-Add-new-forms-to-Siteline'

interface FormUploadBestPracticesHelperTextProps {
  helpCenterLinkScrollTo?: string
  variant?: SitelineVariant
  prefix?: string
}

/** Subtitle on form uploads dialog, shares a link to form uploads best practices article */
export function FormUploadBestPracticesHelperText({
  helpCenterLinkScrollTo,
  variant = 'body1',
  prefix = '',
}: FormUploadBestPracticesHelperTextProps) {
  const helpCenterLinkWithAnchor = helpCenterLinkScrollTo
    ? HELP_CENTER_LINK + `#${helpCenterLinkScrollTo}`
    : HELP_CENTER_LINK

  const hasPrefix = prefix.length > 0

  return (
    <SitelineText variant={variant} color="grey50">
      {prefix}
      <Trans
        i18nKey={
          hasPrefix
            ? 'projects.onboarding.checklist.form_upload_dialog.prefixed_subtitle'
            : 'projects.onboarding.checklist.form_upload_dialog.subtitle'
        }
        components={{
          hcLink: (
            <Link
              href={helpCenterLinkWithAnchor}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              underline="hover"
            />
          ),
        }}
      />
    </SitelineText>
  )
}
