import EditIcon from '@mui/icons-material/Edit'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grow,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import {
  Permission,
  centsToDollars,
  dollarsToCents,
  formatCentsToDollars,
} from 'siteline-common-all'
import {
  SitelineText,
  SitelineTooltip,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import AdjustRetentionIcon from '../../../../assets/icons/adjust-retention.svg'
import BillRetentionIcon from '../../../../assets/icons/bill-retention.svg'
import { DollarNumberFormat } from '../../../../common/components/NumberFormat'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../../common/contexts/CompanyContext'
import { RetentionBillingResult } from '../../../../common/graphql/apollo-operations'
import { OnboardingButton } from '../../onboarding/OnboardingButton'
import { RetentionPercentInput } from './RetentionPercentInput'

const useStyles = makeStylesFast((theme: Theme) => ({
  buttons: {
    display: 'flex',
    margin: theme.spacing(1, -1, 0),
    flexGrow: 1,
    '& > *': {
      flex: 1,
      justifyContent: 'center',
      margin: theme.spacing(0, 1),
      flexBasis: '50%',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(-0.5),
    '& .title': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  inputs: {
    '& .row': {
      display: 'flex',
      margin: theme.spacing(3, 0),
      height: 40,
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        width: 248,
        '& .MuiSvgIcon-root': {
          marginLeft: theme.spacing(1),
          fontSize: 14,
          color: colors.grey50,
        },
      },
      '& .valueInput': {
        width: 120,
        alignSelf: 'center',
        '& .MuiTypography-root': {
          textAlign: 'right',
          paddingRight: theme.spacing(1),
        },
        '& input': {
          textAlign: 'right',
          width: 120,
          height: 40,
          padding: theme.spacing(0, 1),
          ...theme.typography.body1,
        },
      },
      '& .rightButton': {
        marginLeft: 'auto',
        display: 'flex',
        alignSelf: 'center',
      },
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(1),
    '& .MuiCircularProgress-svg': {
      color: colors.grey50,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
}))

interface AdjustRetentionDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (
    retentionHeldPercent: number | null,
    retentionHeldAmount: number | null,
    retentionReleasedAmount: number | null,
    isReleasingRetention: boolean
  ) => Promise<void> | void

  /** If adjusting retention for an individual line item, provide the line item's code */
  lineItemCode?: string

  canOverrideHeldAmount: boolean
  canOverrideReleasedAmount: boolean
  originalHeldToDateAmount: number
  originalReleasedAmount: number
  originalHeldPercent: number
  originalHeldAmount: number
  canBillRetention: boolean
  onCalculateRetention: (
    newRetentionHeldPercent: number,
    isReleasingRetention: boolean
  ) => Promise<RetentionBillingResult | null>
  onResetRetentionReleased?: () => Promise<void>
  onResetRetentionHeldOverride?: () => Promise<void>
  /**
   * If the dialog should only support billing retention, it won't show an option for adjusting
   * current retention
   */
  isBillingOnly: boolean
  initialView?: AdjustRetentionView
  isRetentionByLineItem: boolean
}

type RetentionAction = {
  title: string
  tooltip: string
  bold: boolean
  valueInput: ReactNode
  rightButton: ReactNode
  hidden: boolean
}

const i18nBase = 'projects.subcontractors.pay_app.invoice.adjust_retention'
const RETENTION_HELP_CENTER_ARTICLE =
  'https://support.siteline.com/hc/en-us/articles/9981052773268-Make-retention-adjustments'

export enum AdjustRetentionView {
  SELECT_TYPE = 'SELECT_TYPE',
  RELEASE_PAST = 'RELEASE_PAST',
  ADJUST_FUTURE = 'ADJUST_FUTURE',
}

/** Dialog for updating a pay app or line item's retention held %, held $, and released $ */
export function AdjustRetentionDialog({
  open,
  onClose,
  onSubmit,
  lineItemCode,
  canOverrideHeldAmount,
  canOverrideReleasedAmount,
  originalHeldToDateAmount,
  originalReleasedAmount,
  originalHeldPercent,
  originalHeldAmount,
  canBillRetention,
  onCalculateRetention,
  onResetRetentionReleased,
  onResetRetentionHeldOverride,
  isBillingOnly,
  initialView: initialRetentionView,
  isRetentionByLineItem,
}: AdjustRetentionDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { permissions } = useCompanyContext()

  const canEdit = permissions.includes(Permission.EDIT_INVOICE)

  const initialView = useMemo(() => {
    if (isBillingOnly) {
      return AdjustRetentionView.RELEASE_PAST
    }
    return initialRetentionView ?? AdjustRetentionView.SELECT_TYPE
  }, [initialRetentionView, isBillingOnly])

  const [view, setView] = useState<AdjustRetentionView>(initialView)
  const [isLoadingValues, setIsLoadingValues] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const isReleasingRetention = view === AdjustRetentionView.RELEASE_PAST

  const initialRetentionHeldPercent = useMemo(() => {
    // For billing past retention, we don't show a default value for the retention %. Because
    // past retention can be an aggregate of retention held and billed across multiple pay apps,
    // a single percent value may not clearly reflect what the user expects to see.
    if (isReleasingRetention) {
      return null
    }
    // If editing in aggregate for a pay app, we don't show a default value for the retention %
    // because the percent is an aggregate across all line items the average may be confusing.
    if (!lineItemCode) {
      return null
    }
    return originalHeldPercent
  }, [isReleasingRetention, lineItemCode, originalHeldPercent])

  const [retentionHeldPercent, setRetentionHeldPercent] = useState<number | null>(
    initialRetentionHeldPercent
  )
  const [retentionHeldCurrentAmount, setRetentionHeldCurrentAmount] =
    useState<number>(originalHeldAmount)
  const [retentionHeldToDateAmount, setRetentionHeldToDateAmount] = useState<number | null>(
    originalHeldToDateAmount
  )
  const [retentionReleasedAmount, setRetentionReleasedAmount] = useState<number | null>(
    originalReleasedAmount
  )
  const [editingReleasedAmount, setEditingReleasedAmount] = useState<number | null>(null)
  const [editingHeldToDateAmount, setEditingHeldToDateAmount] = useState<number | null>(null)

  const [isEditingHeldAmount, setIsEditingHeldAmount] = useState<boolean>(false)
  const [isEditingReleasedAmount, setIsEditingReleasedAmount] = useState<boolean>(false)

  // We need to track which values were manually edited so we can undo the right fields if the user
  // clears one of their changes
  const [hasEditedHeldPercent, setHasEditedHeldPercent] = useState<boolean>(false)
  const [hasEditedHeldAmount, setHasEditedHeldAmount] = useState<boolean>(false)
  const [hasEditedReleasedAmount, setHasEditedReleasedAmount] = useState<boolean>(false)

  const handleResetValues = useCallback(() => {
    setRetentionHeldPercent(initialRetentionHeldPercent)
    setRetentionHeldCurrentAmount(originalHeldAmount)
    setRetentionHeldToDateAmount(originalHeldToDateAmount)
    setRetentionReleasedAmount(originalReleasedAmount)
    setIsEditingHeldAmount(false)
    setIsEditingReleasedAmount(false)
    setHasEditedHeldPercent(false)
    setHasEditedHeldAmount(false)
    setHasEditedReleasedAmount(false)
    setEditingReleasedAmount(null)
    setEditingHeldToDateAmount(null)
    setIsLoadingValues(false)
  }, [
    initialRetentionHeldPercent,
    originalHeldAmount,
    originalHeldToDateAmount,
    originalReleasedAmount,
  ])

  const handleResetDialog = useCallback(() => {
    setView(initialView)
    handleResetValues()
  }, [handleResetValues, initialView])

  // Reset values whenever the view changes. Note that `view` should remain a dependency of this
  // effect so we reset values when the view state changes.
  useEffect(handleResetValues, [handleResetValues, view])

  const recalculateAmounts = useCallback(
    async (
      value: number,
      valueType: 'heldPercent' | 'heldAmount' | 'releasedAmount',
      // Even though this is a state variable, it's important that it's passed into the function
      // as a param so that it doesn't become a dependency of the memoized callback. Otherwise, this
      // function would get re-created when the value changes, causing the debounce function to be
      // re-created and no longer work as intended.
      retentionHeldPercentArg: number | null
    ) => {
      switch (valueType) {
        case 'heldPercent': {
          const retentionBilling = await onCalculateRetention(value, isReleasingRetention)
          setIsLoadingValues(false)
          if (!retentionBilling) {
            return
          }
          setRetentionHeldToDateAmount(retentionBilling.retentionHeldToDate)
          setRetentionReleasedAmount(retentionBilling.retentionReleased)
          setRetentionHeldCurrentAmount(retentionBilling.retentionHeldCurrent)
          break
        }
        case 'heldAmount':
          return
        case 'releasedAmount': {
          if (retentionHeldPercentArg !== null) {
            const retentionBilling = await onCalculateRetention(
              retentionHeldPercentArg,
              isReleasingRetention
            )
            if (!retentionBilling) {
              return
            }
            const retentionReleased = retentionBilling.retentionReleased
            if (value === retentionReleased) {
              setHasEditedReleasedAmount(false)
            } else {
              setHasEditedReleasedAmount(true)
            }
          }
          break
        }
      }
    },
    [isReleasingRetention, onCalculateRetention]
  )

  const debouncedRecalculateAmounts = useMemo(
    () => _.debounce(recalculateAmounts, 500),
    [recalculateAmounts]
  )

  // Updates all retention amounts as needed depending on the value that changed
  const updateAmounts = useCallback(
    async (
      value: number | null,
      valueType: 'heldPercent' | 'heldAmount' | 'releasedAmount',
      withDebounce: boolean
    ) => {
      switch (valueType) {
        case 'heldPercent': {
          if (value === retentionHeldPercent) {
            return
          } else if (value === null) {
            debouncedRecalculateAmounts.cancel()
            setRetentionHeldPercent(null)
            setRetentionHeldToDateAmount(null)
            setRetentionReleasedAmount(null)
            setIsLoadingValues(false)
            return
          }
          setRetentionHeldPercent(value)

          if (value !== retentionHeldPercent) {
            // If the retention % changed, the held and released amounts have been reset
            setHasEditedHeldAmount(false)
            setHasEditedReleasedAmount(false)
          }

          // If setting the current retention on a line item and the value equals the existing
          // percent, don't consider the value edited
          if (!isReleasingRetention && lineItemCode && value === originalHeldPercent) {
            setHasEditedHeldPercent(false)
          } else {
            setHasEditedHeldPercent(true)
          }

          setIsLoadingValues(true)
          if (withDebounce) {
            debouncedRecalculateAmounts(value, valueType, retentionHeldPercent)
          } else {
            recalculateAmounts(value, valueType, retentionHeldPercent)
          }
          break
        }
        case 'heldAmount': {
          // Update the current retention held
          const heldDelta = (retentionHeldToDateAmount ?? 0) - (value ?? 0)
          const newHeldCurrentAmount = retentionHeldCurrentAmount - heldDelta

          setRetentionHeldCurrentAmount(newHeldCurrentAmount)
          setRetentionHeldToDateAmount(value)
          setEditingHeldToDateAmount(null)

          if (value === originalHeldToDateAmount) {
            setHasEditedHeldAmount(false)
          } else {
            setHasEditedHeldAmount(true)
          }
          break
        }
        case 'releasedAmount': {
          // Update the retention held and released amounts when the released amount changes
          const releasedDelta = (value ?? 0) - (retentionReleasedAmount ?? 0)
          const newHeldToDateAmount = (retentionHeldToDateAmount ?? 0) - releasedDelta

          setRetentionHeldToDateAmount(newHeldToDateAmount)
          setRetentionReleasedAmount(value)
          setEditingReleasedAmount(null)

          if (value !== retentionReleasedAmount) {
            // If the released amount changed, the held amount has been reset
            setHasEditedHeldAmount(false)

            // Clear the retention held percent input, since it no longer corresponds to the
            // dollar amount of retention to be billed
            setRetentionHeldPercent(null)
          }

          setHasEditedReleasedAmount(true)
          if (retentionHeldPercent === null) {
            setHasEditedReleasedAmount(value !== 0)
          } else {
            recalculateAmounts(newHeldToDateAmount, valueType, retentionHeldPercent)
          }

          break
        }
      }
    },
    [
      retentionHeldPercent,
      isReleasingRetention,
      lineItemCode,
      originalHeldPercent,
      debouncedRecalculateAmounts,
      recalculateAmounts,
      retentionHeldToDateAmount,
      retentionHeldCurrentAmount,
      originalHeldToDateAmount,
      retentionReleasedAmount,
    ]
  )

  const handleCancelUpdate = useCallback(
    async (valueType: 'heldAmount' | 'releasedAmount') => {
      switch (valueType) {
        case 'heldAmount': {
          if (!hasEditedHeldAmount) {
            // If the amount hasn't been updated, nothing needs to happen
            return
          }
          if (hasEditedHeldPercent || hasEditedReleasedAmount) {
            // If either the held % or released $ changed (both impact held $), then reset the value to
            // whatever the current held % says it should be
            updateAmounts(retentionHeldPercent, 'heldPercent', false)
          } else {
            // If only the held amount was updated, reset it to the original value
            setRetentionHeldToDateAmount(originalHeldToDateAmount)
            setRetentionHeldCurrentAmount(originalHeldAmount)
          }
          setHasEditedHeldAmount(false)
          setEditingHeldToDateAmount(null)
          break
        }
        case 'releasedAmount': {
          if (!hasEditedReleasedAmount) {
            // If the amount hasn't been updated, nothing needs to happen
            return
          }
          if (hasEditedHeldPercent) {
            let newRetentionHeldCurrent = originalHeldAmount
            let newRetentionHeldToDate = originalHeldToDateAmount
            let retentionReleased = originalReleasedAmount
            if (retentionHeldPercent !== null) {
              // If the held % changed (impacts released $), then reset the value to whatever the values
              // should have been after the retention % was changed
              setIsLoadingValues(true)
              const retentionBilling = await onCalculateRetention(
                retentionHeldPercent,
                isReleasingRetention
              )
              setIsLoadingValues(false)
              if (!retentionBilling) {
                return
              }
              newRetentionHeldToDate = retentionBilling.retentionHeldToDate
              retentionReleased = retentionBilling.retentionReleased
              newRetentionHeldCurrent = retentionBilling.retentionHeldCurrent
            }
            setRetentionHeldCurrentAmount(newRetentionHeldCurrent)
            setRetentionHeldToDateAmount(newRetentionHeldToDate)
            setRetentionReleasedAmount(retentionReleased)
          } else {
            // If only the released amount was updated, reset it to the original value
            setRetentionReleasedAmount(originalReleasedAmount)
          }
          setHasEditedReleasedAmount(false)
          setEditingReleasedAmount(null)
          break
        }
      }
    },
    [
      hasEditedHeldAmount,
      hasEditedHeldPercent,
      hasEditedReleasedAmount,
      isReleasingRetention,
      onCalculateRetention,
      originalHeldAmount,
      originalHeldToDateAmount,
      originalReleasedAmount,
      retentionHeldPercent,
      updateAmounts,
    ]
  )

  // Button to release all retention. Only show if showing released amounts.
  const billAllButton = useMemo(() => {
    if (retentionHeldPercent === 0) {
      return undefined
    }
    return (
      <Grow in={isReleasingRetention}>
        <Button
          variant="text"
          color="primary"
          onClick={() => updateAmounts(0, 'heldPercent', false)}
          disabled={isEditingHeldAmount || isEditingReleasedAmount}
        >
          {t(`${i18nBase}.held_percent.bill_all`)}
        </Button>
      </Grow>
    )
  }, [
    isEditingHeldAmount,
    isEditingReleasedAmount,
    isReleasingRetention,
    retentionHeldPercent,
    t,
    updateAmounts,
  ])

  // When the percent held field is blurred while empty, reset fields to their original values if
  // they're empty
  const handlePercentHeldBlur = useCallback(() => {
    if (isReleasingRetention) {
      if (
        retentionHeldPercent === null &&
        retentionHeldToDateAmount === null &&
        retentionReleasedAmount === null
      ) {
        setRetentionHeldPercent(initialRetentionHeldPercent)
        setRetentionHeldToDateAmount(originalHeldToDateAmount)
        setRetentionReleasedAmount(originalReleasedAmount)
      }
    } else if (retentionHeldPercent === null && retentionHeldToDateAmount === null) {
      setRetentionHeldPercent(initialRetentionHeldPercent)
      setRetentionHeldToDateAmount(originalHeldToDateAmount)
      setRetentionHeldCurrentAmount(originalHeldAmount)
    }
  }, [
    initialRetentionHeldPercent,
    isReleasingRetention,
    originalHeldAmount,
    originalHeldToDateAmount,
    originalReleasedAmount,
    retentionHeldPercent,
    retentionHeldToDateAmount,
    retentionReleasedAmount,
  ])

  // Input a retention %. This is disabled if the held or released amounts are actively being
  // edited. If it isn't being changed, a "bill all" button exists to bring it down to 0%. Else, it
  // shows the original retention % before any modifications.
  const heldPercentAction = useMemo(
    () => ({
      title: t(`${i18nBase}.new_retention_percent`),
      bold: !isEditingHeldAmount && !isEditingReleasedAmount,
      tooltip: t(`${i18nBase}.new_retention_tooltip`),
      valueInput: (
        <RetentionPercentInput
          percent={retentionHeldPercent ?? undefined}
          onInputChange={(newValue) => updateAmounts(newValue ?? null, 'heldPercent', true)}
          onPercentChange={(newValue) => updateAmounts(newValue, 'heldPercent', true)}
          maxAllowedPercent={1}
          disableInput={isEditingHeldAmount || isEditingReleasedAmount}
          onBlur={handlePercentHeldBlur}
        />
      ),
      rightButton: retentionHeldPercent === null ? billAllButton : undefined,
      hidden: false,
    }),
    [
      billAllButton,
      handlePercentHeldBlur,
      isEditingHeldAmount,
      isEditingReleasedAmount,
      retentionHeldPercent,
      t,
      updateAmounts,
    ]
  )

  const heldCurrentAmountAction = useMemo(() => {
    let valueInput = (
      <SitelineText variant="body1" color={isEditingHeldAmount ? 'grey30' : 'grey50'}>
        {_.isNumber(retentionHeldToDateAmount) ? (
          <DollarNumberFormat value={retentionHeldCurrentAmount} />
        ) : (
          '–'
        )}
      </SitelineText>
    )
    if (isLoadingValues) {
      valueInput = (
        <div className={classes.loading}>
          <CircularProgress size={20} />
        </div>
      )
    }
    return {
      title: t(`${i18nBase}.held_current_amount.title`),
      bold: false,
      tooltip: '',
      rightButton: <></>,
      valueInput,
      hidden: false,
    }
  }, [
    isEditingHeldAmount,
    retentionHeldToDateAmount,
    retentionHeldCurrentAmount,
    isLoadingValues,
    t,
    classes.loading,
  ])

  // Input a retention held to date $. This is disabled if the released amounts is actively being
  // edited. If it isn't being changed, an override button exists to set the value. Else, it shows a
  // save or cancel button. In the retention billing view, this is read-only.
  const heldToDateAmountAction = useMemo(() => {
    let valueInput = (
      <SitelineText variant="body1" color={isEditingReleasedAmount ? 'grey30' : 'grey50'}>
        {_.isNumber(retentionHeldToDateAmount) ? (
          <DollarNumberFormat value={retentionHeldToDateAmount} />
        ) : (
          '–'
        )}
      </SitelineText>
    )
    if (isEditingHeldAmount) {
      valueInput = (
        <NumericFormat
          value={
            _.isNumber(retentionHeldToDateAmount) ? centsToDollars(retentionHeldToDateAmount) : null
          }
          onValueChange={({ floatValue }) =>
            setEditingHeldToDateAmount(_.isNumber(floatValue) ? dollarsToCents(floatValue) : null)
          }
          decimalScale={2}
          fixedDecimalScale
          displayType="input"
          thousandSeparator
          prefix="$"
          allowNegative
        />
      )
    }
    if (isLoadingValues) {
      valueInput = (
        <div className={classes.loading}>
          <CircularProgress size={20} />
        </div>
      )
    }

    let rightButton = (
      <Tooltip title={t(`${i18nBase}.override`)}>
        <IconButton
          color="secondary"
          onClick={() => setIsEditingHeldAmount(true)}
          size="small"
          disabled={isEditingReleasedAmount}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )
    if (isEditingHeldAmount) {
      rightButton = (
        <>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              handleCancelUpdate('heldAmount')
              setIsEditingHeldAmount(false)
            }}
          >
            {t('common.actions.cancel')}
          </Button>
          <Tooltip title={t(`${i18nBase}.held_to_date_amount.save_tooltip`)}>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                updateAmounts(editingHeldToDateAmount, 'heldAmount', false)
                setIsEditingHeldAmount(false)
              }}
            >
              {t('common.actions.save')}
            </Button>
          </Tooltip>
        </>
      )
    }
    if (isReleasingRetention || !canOverrideHeldAmount) {
      rightButton = <></>
    }

    return {
      title: t(`${i18nBase}.held_to_date_amount.title`),
      bold: isEditingHeldAmount,
      tooltip:
        originalHeldAmount && isReleasingRetention
          ? t(`${i18nBase}.held_to_date_amount.releasing_tooltip`, {
              amount: formatCentsToDollars(originalHeldAmount, true),
            })
          : t(`${i18nBase}.held_to_date_amount.tooltip`),
      valueInput,
      rightButton,
      hidden: false,
    }
  }, [
    isEditingReleasedAmount,
    retentionHeldToDateAmount,
    isEditingHeldAmount,
    isLoadingValues,
    t,
    isReleasingRetention,
    canOverrideHeldAmount,
    originalHeldAmount,
    classes.loading,
    handleCancelUpdate,
    updateAmounts,
    editingHeldToDateAmount,
  ])

  // The release $ button shows an override button if it isn't actively being changed. Else, it
  // shows a save or cancel button.
  const releasedRightButton = useMemo(
    () =>
      isEditingReleasedAmount ? (
        <>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              handleCancelUpdate('releasedAmount')
              setIsEditingReleasedAmount(false)
            }}
          >
            {t('common.actions.cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              updateAmounts(editingReleasedAmount, 'releasedAmount', false)
              setIsEditingReleasedAmount(false)
            }}
          >
            {t('common.actions.save')}
          </Button>
        </>
      ) : (
        <Tooltip title={t(`${i18nBase}.override`)}>
          <IconButton
            color="secondary"
            onClick={() => {
              setEditingReleasedAmount(retentionReleasedAmount)
              setIsEditingReleasedAmount(true)
            }}
            size="small"
            disabled={isEditingHeldAmount}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    [
      isEditingReleasedAmount,
      t,
      isEditingHeldAmount,
      handleCancelUpdate,
      updateAmounts,
      editingReleasedAmount,
      retentionReleasedAmount,
    ]
  )

  // Input a retention released $. This is only allowed if this is for a progress on STANDARD or
  // LINE_ITEM tracking. This is disabled if the released amounts is actively being
  // edited.
  const releasedAmountAction = useMemo(() => {
    let valueInput = (
      <SitelineText variant="body1" color="grey50">
        {_.isNumber(retentionReleasedAmount) ? (
          <DollarNumberFormat value={retentionReleasedAmount} />
        ) : (
          '–'
        )}
      </SitelineText>
    )
    if (isEditingReleasedAmount) {
      valueInput = (
        <NumericFormat
          value={_.isNumber(editingReleasedAmount) ? centsToDollars(editingReleasedAmount) : null}
          onValueChange={({ floatValue }) => {
            setEditingReleasedAmount(_.isNumber(floatValue) ? dollarsToCents(floatValue) : null)
          }}
          decimalScale={2}
          fixedDecimalScale
          displayType="input"
          thousandSeparator
          prefix="$"
          allowNegative
        />
      )
    }
    if (isLoadingValues) {
      valueInput = (
        <div className={classes.loading}>
          <CircularProgress size={20} />
        </div>
      )
    }
    return {
      title: t(`${i18nBase}.released_amount.title`),
      bold: isEditingReleasedAmount,
      tooltip: t(`${i18nBase}.released_amount.tooltip`),
      valueInput,
      rightButton: canOverrideReleasedAmount ? releasedRightButton : <></>,
      hidden: false,
    }
  }, [
    canOverrideReleasedAmount,
    classes.loading,
    editingReleasedAmount,
    isEditingReleasedAmount,
    isLoadingValues,
    releasedRightButton,
    retentionReleasedAmount,
    t,
  ])

  const actions: RetentionAction[] = isReleasingRetention
    ? [heldPercentAction, releasedAmountAction, heldToDateAmountAction]
    : [heldPercentAction, heldCurrentAmountAction, heldToDateAmountAction]

  const handleClose = (fromButton: boolean) => {
    if (fromButton && !isBillingOnly) {
      setView(AdjustRetentionView.SELECT_TYPE)
      return
    }

    onClose()
  }

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    await onSubmit(
      retentionHeldPercent,
      hasEditedHeldAmount ? retentionHeldToDateAmount : null,
      hasEditedReleasedAmount ? retentionReleasedAmount : null,
      isReleasingRetention
    )
    setIsSubmitting(false)
    onClose()
  }, [
    hasEditedHeldAmount,
    hasEditedReleasedAmount,
    isReleasingRetention,
    onClose,
    onSubmit,
    retentionHeldToDateAmount,
    retentionHeldPercent,
    retentionReleasedAmount,
  ])

  const handleResetRetentionReleased = useCallback(async () => {
    if (!onResetRetentionReleased) {
      return
    }
    setIsSubmitting(true)
    await onResetRetentionReleased()
    setIsSubmitting(false)
    snackbar.showSuccess(t(`${i18nBase}.reset_billed_success`))
    onClose()
  }, [onClose, onResetRetentionReleased, snackbar, t])

  const handleResetRetentionOverride = useCallback(async () => {
    if (!onResetRetentionHeldOverride) {
      return
    }
    setIsSubmitting(true)
    await onResetRetentionHeldOverride()
    setIsSubmitting(false)
    snackbar.showSuccess(t(`${i18nBase}.reset_override_success`))
    onClose()
  }, [onClose, onResetRetentionHeldOverride, snackbar, t])

  const hasNullValue = retentionHeldToDateAmount === null || retentionReleasedAmount === null
  const noValueUpdated = !hasEditedHeldAmount && !hasEditedHeldPercent && !hasEditedReleasedAmount

  const isButtonOptionsView = view === AdjustRetentionView.SELECT_TYPE

  const [title, subtitle] = useMemo(() => {
    const isAdjustingPayApp = lineItemCode === undefined
    switch (view) {
      case AdjustRetentionView.SELECT_TYPE: {
        if (isAdjustingPayApp) {
          return [
            t(`${i18nBase}.title`),
            isRetentionByLineItem ? t(`${i18nBase}.subtitle_pay_app`) : '',
          ]
        } else {
          return [t(`${i18nBase}.title`), t(`${i18nBase}.subtitle_progress`)]
        }
      }
      case AdjustRetentionView.ADJUST_FUTURE: {
        return [
          t(`${i18nBase}.adjust_current_retention`),
          isAdjustingPayApp
            ? t(`${i18nBase}.adjust_subtitle_pay_app`)
            : t(`${i18nBase}.adjust_subtitle_progress`),
        ]
      }
      case AdjustRetentionView.RELEASE_PAST: {
        return [
          t(`${i18nBase}.bill_for_retention`),
          <Trans
            key="billForRetention"
            i18nKey={`${i18nBase}.bill_for_retention_subtitle`}
            components={{ italic: <span className={classes.italic} /> }}
          />,
        ]
      }
    }
  }, [lineItemCode, view, t, isRetentionByLineItem, classes.italic])

  const subscript = useMemo(() => {
    if (isReleasingRetention && onResetRetentionReleased) {
      return (
        <SitelineTooltip title={t(`${i18nBase}.reset_billed_tooltip`)} placement="top-start">
          <Button variant="outlined" color="secondary" onClick={handleResetRetentionReleased}>
            {t(`${i18nBase}.reset_retention_billed`)}
          </Button>
        </SitelineTooltip>
      )
    }

    if (!isReleasingRetention && onResetRetentionHeldOverride) {
      return (
        <SitelineTooltip title={t(`${i18nBase}.reset_override_tooltip`)} placement="top-start">
          <Button variant="outlined" color="secondary" onClick={handleResetRetentionOverride}>
            {t(`${i18nBase}.reset_retention_override`)}
          </Button>
        </SitelineTooltip>
      )
    }

    return undefined
  }, [
    handleResetRetentionOverride,
    handleResetRetentionReleased,
    isReleasingRetention,
    onResetRetentionHeldOverride,
    onResetRetentionReleased,
    t,
  ])

  return (
    <SitelineDialog
      open={open}
      actionsLayout={isButtonOptionsView ? 'closeIcon' : 'actionsRow'}
      onClose={handleClose}
      cancelLabel={isBillingOnly ? undefined : t('common.actions.back')}
      title={
        <div className={classes.title}>
          <SitelineText variant="h1" bold className="title">
            {title}
          </SitelineText>
          <Tooltip
            title={
              <SitelineText variant="smallText" endIcon={<LaunchIcon style={{ fontSize: 14 }} />}>
                {t(`${i18nBase}.help_center`)}
              </SitelineText>
            }
            placement="top-start"
          >
            <IconButton
              color="secondary"
              onClick={() => {
                window.open(RETENTION_HELP_CENTER_ARTICLE, '_blank')
              }}
            >
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      subtitle={
        <>
          {lineItemCode && (
            <SitelineText variant="body1" color="grey50" className="subtitle" bold>
              {t(`${i18nBase}.line_item_code`, { code: lineItemCode })}
            </SitelineText>
          )}
          <SitelineText variant="body1" color="grey50" className="subtitle">
            {subtitle}
          </SitelineText>
        </>
      }
      subtitleVariant="body1"
      submitLabel={t('common.actions.confirm')}
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      disableSubmit={
        isEditingReleasedAmount || isEditingHeldAmount || hasNullValue || noValueUpdated || !canEdit
      }
      maxWidth="sm"
      onResetDialog={handleResetDialog}
      subscript={subscript}
    >
      {view === AdjustRetentionView.SELECT_TYPE && (
        <div className={classes.buttons}>
          <OnboardingButton
            title={t(`${i18nBase}.adjust_current_retention`)}
            imageSrc={AdjustRetentionIcon}
            subtitle={t(`${i18nBase}.adjust_current_retention_subtitle`)}
            onClick={() => setView(AdjustRetentionView.ADJUST_FUTURE)}
          />
          <SitelineTooltip
            title={canBillRetention ? '' : t(`${i18nBase}.bill_for_retention_disabled`)}
            placement="top-start"
          >
            <div>
              <OnboardingButton
                title={t(`${i18nBase}.bill_for_past_retention`)}
                imageSrc={BillRetentionIcon}
                subtitle={t(`${i18nBase}.bill_for_past_retention_subtitle`)}
                onClick={() => setView(AdjustRetentionView.RELEASE_PAST)}
                // If there is no retention held prior to this pay app (either because none was ever
                // held or because it was all billed out before this pay app), we'll disable the option
                // to bill for retention
                disabled={!canBillRetention}
              />
            </div>
          </SitelineTooltip>
        </div>
      )}
      {!isButtonOptionsView && (
        <div className={classes.inputs}>
          {actions.map((action) => (
            <div key={action.title}>
              <Collapse in={!action.hidden}>
                <div className="row">
                  <div className="title">
                    <SitelineText variant="body1" bold={action.bold} color="grey70">
                      {action.title}
                    </SitelineText>
                    {action.tooltip && (
                      <Tooltip title={action.tooltip}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    )}
                  </div>
                  <div className="valueInput">{action.valueInput}</div>
                  <div className="rightButton">{action.rightButton}</div>
                </div>
                <Divider />
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </SitelineDialog>
  )
}
