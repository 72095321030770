import { Button, ButtonOwnProps, Tooltip } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LienWaiverCategory, LienWaiverProgressType, LienWaiverType } from 'siteline-common-all'
import { useToggle } from 'siteline-common-web'
import { ButtonVariant } from '../../../common/components/DropdownButton'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  BillingType,
  PayAppForOverviewProperties,
  PayAppStatus,
  Permission,
} from '../../../common/graphql/apollo-operations'
import { getLienWaiverCategoryForType } from '../../../common/util/LienWaiver'
import { isPayAppSubmittedOrSynced } from '../../../common/util/PayApp'
import {
  doesContractHaveUnconditionalFinal,
  doesContractHaveUnconditionalProgress,
  requiresUnconditionalLienWaiver,
} from '../../../common/util/Project'
import { BillingPathType, getBillingPath } from '../Billing.lib'
import { LienWaiverTemplatesForPayApps } from '../PayAppDetails'
import { ContractForProjectHome, PayAppForProjectHome } from '../home/ProjectHome'
import { MarkAsPaidDialog } from './MarkAsPaidDialog'
import { SelectLienWaiverProgressTypeDialog } from './SelectLienWaiverProgressTypeDialog'
import { LienWaiverNavigationState } from './UnconditionalDetails'

const i18nBase = 'projects.subcontractors.pay_app_list'

interface MarkAsPaidResult {
  label: string
  tooltip: string
  disabled: boolean
  isButtonVisible: boolean
  onClick: () => void
  onNavigateToLienWaiver: (lienWaiverType?: LienWaiverProgressType) => void
  hasUnconditionalFinalTemplate: boolean
  hasUnconditionalProgressTemplate: boolean
  hasRequiredTemplate: boolean
  isProcessingUnconditionalFinalTemplate: boolean
  isProcessingUnconditionalProgressTemplate: boolean
  isProcessingRequiredTemplate: boolean
}

type PayAppForMarkAsPaid = Pick<
  PayAppForOverviewProperties,
  | 'id'
  | 'status'
  | 'balanceToFinish'
  | 'totalRetention'
  | 'statusChangedAt'
  | 'lastSubmitted'
  | 'amountDuePostTax'
  | 'amountPaid'
  | 'isBalanceManuallyClosed'
> &
  Pick<PayAppForProjectHome, 'lienWaivers'>

export type ContractForMarkAsPaid = {
  sov: {
    id: string
    totalBilled: number
    totalValue: number
  } | null
  lienWaiverTemplates?: LienWaiverTemplatesForPayApps
  project: {
    id: string
  }
  billingType: BillingType
}

interface UseMarkAsPaidProps {
  contract: ContractForMarkAsPaid
  payApp?: PayAppForMarkAsPaid
  isPaidInIntegration: boolean
  onOpenLienWaiverDialog: () => void
  onOpenMarkAsPaidDialog: () => void
  location: 'collections' | 'payApps'
}

/** Returns the info used to show a mark as paid button or dropdown action */
export function useMarkAsPaid({
  contract,
  payApp,
  isPaidInIntegration,
  onOpenLienWaiverDialog,
  onOpenMarkAsPaidDialog,
  location,
}: UseMarkAsPaidProps): MarkAsPaidResult {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isContractActive } = useProjectContext()
  const { permissions } = useCompanyContext()

  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS
  const canEdit = permissions.includes(Permission.EDIT_INVOICE)
  const canSign = permissions.includes(Permission.SIGNER)

  const {
    hasUnconditionalTemplate: hasUnconditionalProgressTemplate,
    isProcessingFormTemplate: isProcessingUnconditionalProgressTemplate,
  } = useMemo(() => doesContractHaveUnconditionalProgress(contract), [contract])

  const {
    hasUnconditionalTemplate: hasUnconditionalFinalTemplate,
    isProcessingFormTemplate: isProcessingUnconditionalFinalTemplate,
  } = useMemo(() => doesContractHaveUnconditionalFinal(contract), [contract])

  const {
    hasUnconditionalTemplate: hasRequiredTemplate,
    isProcessingFormTemplate: isProcessingRequiredTemplate,
  } = useMemo(() => {
    if (!payApp) {
      return { hasUnconditionalTemplate: false, isProcessingFormTemplate: false }
    }
    return requiresUnconditionalLienWaiver(payApp, contract)
  }, [contract, payApp])

  const buttonLabel = useMemo(() => {
    if (!payApp) {
      return ''
    }
    if (payApp.status === PayAppStatus.NOTARIZING_UNCONDITIONAL) {
      return t(`${i18nBase}.upload_notarized`)
    }
    if (isPaidInIntegration) {
      return t(`${i18nBase}.generate_unconditional`)
    }
    return t(`${i18nBase}.mark_as_paid`)
  }, [isPaidInIntegration, payApp, t])

  const disabledTooltip = useMemo(() => {
    if (isTimeAndMaterials) {
      if (isProcessingUnconditionalProgressTemplate && isProcessingUnconditionalFinalTemplate) {
        return t(`${i18nBase}.lien_waivers.processing_unconditionals`)
      }
      if (isProcessingUnconditionalProgressTemplate && !hasUnconditionalFinalTemplate) {
        return t(`${i18nBase}.lien_waivers.processing_progress`)
      }
      if (isProcessingUnconditionalFinalTemplate && !hasUnconditionalProgressTemplate) {
        return t(`${i18nBase}.lien_waivers.processing_final`)
      }
    } else {
      if (isProcessingRequiredTemplate) {
        return t(`${i18nBase}.processing_unconditional`)
      }
    }
    return ''
  }, [
    hasUnconditionalFinalTemplate,
    hasUnconditionalProgressTemplate,
    isProcessingRequiredTemplate,
    isProcessingUnconditionalFinalTemplate,
    isProcessingUnconditionalProgressTemplate,
    isTimeAndMaterials,
    t,
  ])

  const handleNavigateToLienWaiver = useCallback(
    (lienWaiverType?: LienWaiverProgressType) => {
      if (!payApp) {
        return
      }
      const lienWaiverNavigationState: LienWaiverNavigationState = {
        backTo: location,
      }
      navigate({
        ...getBillingPath({
          pathType: BillingPathType.PayAppUnconditional,
          projectId: contract.project.id,
          payAppId: payApp.id,
          payAppTab: 'unconditional',
          progressType: lienWaiverType,
        }),
        state: lienWaiverNavigationState,
      })
    },
    [contract.project.id, location, navigate, payApp]
  )

  const handleClick = useCallback(() => {
    if (!payApp) {
      return
    }

    if (isTimeAndMaterials) {
      // If the pay app status is "Notarizing Unconditional", the signed/unnotarized lien waiver
      // will already exist on the pay app and we can derive the type from that
      if (payApp.status === PayAppStatus.NOTARIZING_UNCONDITIONAL) {
        const lienWaiver = payApp.lienWaivers.find(
          ({ type }) => getLienWaiverCategoryForType(type) === LienWaiverCategory.UNCONDITIONAL
        )
        if (lienWaiver && lienWaiver.type === LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT) {
          handleNavigateToLienWaiver(LienWaiverProgressType.PROGRESS)
        }
        if (lienWaiver && lienWaiver.type === LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT) {
          handleNavigateToLienWaiver(LienWaiverProgressType.FINAL)
        }
        return
      }
      // If the project has both progress & final lien waiver templates, open the dialog where the
      // user can select which form to generate
      if (hasUnconditionalProgressTemplate && hasUnconditionalFinalTemplate) {
        onOpenLienWaiverDialog()
        return
      }
      // If the project only has progress, there is only one form that can be generated. Navigate
      // directly to progress.
      if (hasUnconditionalProgressTemplate && !hasUnconditionalFinalTemplate) {
        handleNavigateToLienWaiver(LienWaiverProgressType.PROGRESS)
        return
      }
      // If the project only has final, there is only one form that can be generated. Navigate
      // directly to final.
      if (hasUnconditionalFinalTemplate && !hasUnconditionalProgressTemplate) {
        handleNavigateToLienWaiver(LienWaiverProgressType.FINAL)
        return
      }
    }
    if (!isTimeAndMaterials && hasRequiredTemplate) {
      handleNavigateToLienWaiver()
      return
    }

    onOpenMarkAsPaidDialog()
  }, [
    handleNavigateToLienWaiver,
    hasRequiredTemplate,
    hasUnconditionalFinalTemplate,
    hasUnconditionalProgressTemplate,
    isTimeAndMaterials,
    onOpenLienWaiverDialog,
    onOpenMarkAsPaidDialog,
    payApp,
  ])

  const isButtonVisible = useMemo(() => {
    const isReadyForUnconditional =
      payApp &&
      (isPayAppSubmittedOrSynced(payApp.status) ||
        payApp.status === PayAppStatus.NOTARIZING_UNCONDITIONAL ||
        isPaidInIntegration)

    const lumpSumHasLienWaiverTemplate = !isTimeAndMaterials && hasRequiredTemplate
    const timeAndMaterialsHasLienWaiverTemplate =
      isTimeAndMaterials && (hasUnconditionalProgressTemplate || hasUnconditionalFinalTemplate)
    const hasLienWaiverTemplate =
      timeAndMaterialsHasLienWaiverTemplate || lumpSumHasLienWaiverTemplate

    // Basic cases for hiding the button
    if (!payApp || !isContractActive || !canEdit || !isReadyForUnconditional) {
      return false
    }
    // Hide if the pay app was marked as paid via an integration but the project doesn't have unconditionals
    if (isPaidInIntegration && !hasLienWaiverTemplate) {
      return false
    }
    // Hide if the pay app does have unconditionals but the user doesn't have permission to sign
    if (hasLienWaiverTemplate && !canSign) {
      return false
    }
    return true
  }, [
    canEdit,
    canSign,
    hasRequiredTemplate,
    hasUnconditionalFinalTemplate,
    hasUnconditionalProgressTemplate,
    isContractActive,
    isPaidInIntegration,
    isTimeAndMaterials,
    payApp,
  ])

  return useMemo(
    () => ({
      disabled: disabledTooltip.length > 0,
      tooltip: disabledTooltip,
      isButtonVisible,
      onNavigateToLienWaiver: handleNavigateToLienWaiver,
      onClick: handleClick,
      label: buttonLabel,
      hasUnconditionalFinalTemplate,
      hasUnconditionalProgressTemplate,
      hasRequiredTemplate,
      isProcessingUnconditionalFinalTemplate,
      isProcessingUnconditionalProgressTemplate,
      isProcessingRequiredTemplate,
    }),
    [
      buttonLabel,
      disabledTooltip,
      handleClick,
      handleNavigateToLienWaiver,
      hasRequiredTemplate,
      hasUnconditionalFinalTemplate,
      hasUnconditionalProgressTemplate,
      isButtonVisible,
      isProcessingRequiredTemplate,
      isProcessingUnconditionalFinalTemplate,
      isProcessingUnconditionalProgressTemplate,
    ]
  )
}

interface MarkAsPaidButtonProps {
  payApp?: PayAppForMarkAsPaid
  contract: ContractForProjectHome
  className?: string
  variant?: 'text' | ButtonVariant
  isPaidInIntegration: boolean
  location: 'collections' | 'payApps'
}

export function MarkAsPaidButton({
  payApp,
  contract,
  className,
  variant: buttonVariant,
  isPaidInIntegration,
  location,
}: MarkAsPaidButtonProps) {
  const [isMarkAsPaidDialogOpen, handleOpenMarkAsPaid, handleCloseMarkAsPaid] = useToggle()
  const [isSelectLienWaiverDialogOpen, handleOpenLienWaiverDialog, handleCloseLienWaiverDialog] =
    useToggle()

  const { variant, color } = useMemo(() => {
    switch (buttonVariant) {
      case 'text':
        return { variant: 'text', color: 'primary' }
      case ButtonVariant.PRIMARY:
        return { variant: 'contained', color: 'primary' }
      case ButtonVariant.SECONDARY:
        return { variant: 'outlined', color: 'secondary' }
      case undefined:
        return { variant: 'contained', color: 'secondary' }
    }
  }, [buttonVariant])

  const {
    label,
    disabled,
    tooltip,
    isButtonVisible,
    onClick,
    onNavigateToLienWaiver,
    isProcessingUnconditionalFinalTemplate,
    isProcessingUnconditionalProgressTemplate,
  } = useMarkAsPaid({
    contract,
    payApp,
    isPaidInIntegration,
    onOpenLienWaiverDialog: handleOpenLienWaiverDialog,
    onOpenMarkAsPaidDialog: handleOpenMarkAsPaid,
    location,
  })

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onClick()
    },
    [onClick]
  )

  if (!isButtonVisible || !payApp) {
    return null
  }

  return (
    // Prevent clicks from propagating to the parent
    <div onClick={(evt) => evt.stopPropagation()}>
      <Tooltip title={tooltip} disableInteractive>
        <div>
          <Button
            className={className}
            variant={variant as ButtonOwnProps['variant']}
            color={color as ButtonOwnProps['color']}
            disabled={disabled}
            onClick={handleClick}
          >
            {label}
          </Button>
        </div>
      </Tooltip>
      <MarkAsPaidDialog
        open={isMarkAsPaidDialogOpen}
        onClose={handleCloseMarkAsPaid}
        payApp={payApp}
        location={location}
      />
      <SelectLienWaiverProgressTypeDialog
        open={isSelectLienWaiverDialogOpen}
        onClose={handleCloseLienWaiverDialog}
        onSubmit={onNavigateToLienWaiver}
        isProcessingProgressTemplate={isProcessingUnconditionalProgressTemplate}
        isProcessingFinalTemplate={isProcessingUnconditionalFinalTemplate}
      />
    </div>
  )
}
