import _, { DebouncedFunc } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import Scroll from 'react-scroll'
import { TOP_HEADER_HEIGHT } from '../themes/Main'

const SCROLL_DURATION = 300

export function useWaypoint<T>({
  setSelectedSection,
  sectionToString,
}: {
  setSelectedSection: (section: T) => void
  sectionToString: (section: T) => string
}): [(section: T) => void, DebouncedFunc<(section: T) => void>, boolean, () => void] {
  // Disable the waypoint auto selecting months while scrolling if the
  // scrolling is caused by clicking a month
  const isWaypointDisabled = useRef<boolean>(true)

  // Enable the waypoint once the user has scrolled (so it doesn't immediately
  // select a month further down the list when the page loads)
  useEffect(() => {
    function enableWaypoint() {
      isWaypointDisabled.current = false
      window.removeEventListener('scroll', enableWaypoint)
    }
    window.addEventListener('scroll', enableWaypoint)
    return () => window.removeEventListener('scroll', enableWaypoint)
  }, [])

  const handleScrollToSection = useCallback(
    (section: T) => {
      isWaypointDisabled.current = true
      Scroll.scroller.scrollTo(sectionToString(section), {
        duration: SCROLL_DURATION,
        smooth: 'easeInOutCubic',
        // The 200px is to make sure that we're somewhat in view on the screen (below the headers)
        offset: -(TOP_HEADER_HEIGHT + 200),
      })
      setTimeout(() => {
        isWaypointDisabled.current = false
      }, 2 * SCROLL_DURATION)
    },
    [sectionToString]
  )

  const handleScrollSectionIntoView = useMemo(
    () =>
      _.debounce(
        (section) => {
          if (!isWaypointDisabled.current) {
            setSelectedSection(section)
          }
        },
        100,
        { maxWait: SCROLL_DURATION }
      ),
    [setSelectedSection]
  )

  const disableWaypoint = useCallback(() => {
    isWaypointDisabled.current = true
    setTimeout(() => {
      isWaypointDisabled.current = false
    }, SCROLL_DURATION)
  }, [])

  return [
    handleScrollToSection,
    handleScrollSectionIntoView,
    isWaypointDisabled.current,
    disableWaypoint,
  ]
}
