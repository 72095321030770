import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PhoneIcon from '@mui/icons-material/Phone'
import { Theme } from '@mui/material/styles'
import { useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { RemovableChip } from '../../../common/components/RemovableChip'
import { formatPhoneNumber } from '../../../common/util/PhoneNumber'
import { EmailContact } from './SendEmailDialogForm'

const useStyles = makeStylesFast((theme: Theme) => ({
  tooltipRow: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 12,
      marginRight: theme.spacing(1),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(0.5),
    },
  },
}))

interface ContactChipProps {
  contact: EmailContact
  onRemoveContact?: () => void
  onEditContact?: () => void
  color?: 'white' | 'grey'
  emailOnly?: boolean
  disabled?: boolean
  className?: string
}

/** A chip displaying the name of a contact, with their email showing in a tooltip on hover  */
export function ContactChip({
  contact,
  onRemoveContact,
  onEditContact,
  color = 'white',
  emailOnly = false,
  disabled,
  className,
}: ContactChipProps) {
  const classes = useStyles()
  const label = emailOnly ? contact.email : contact.fullName

  const tooltip = useMemo(() => {
    if (emailOnly) {
      return undefined
    }
    return (
      <div>
        <div className={classes.tooltipRow}>
          <EmailOutlinedIcon />
          <div>{contact.email}</div>
        </div>
        {'phoneNumber' in contact && contact.phoneNumber && (
          <div className={classes.tooltipRow}>
            <PhoneIcon />
            <div>{formatPhoneNumber(contact.phoneNumber)}</div>
          </div>
        )}
        {'jobTitle' in contact && contact.jobTitle && (
          <div className={classes.tooltipRow}>
            <BadgeOutlinedIcon />
            <div>{contact.jobTitle}</div>
          </div>
        )}
      </div>
    )
  }, [classes.tooltipRow, contact, emailOnly])

  return (
    <RemovableChip
      label={label}
      onClick={onEditContact}
      onRemove={onRemoveContact}
      color={color}
      tooltipTitle={tooltip}
      disabled={disabled}
      className={className}
    />
  )
}
