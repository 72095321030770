import { Alert } from '@mui/material'
import { colors, withStylesFast } from 'siteline-common-web'

export const SitelineAlert = withStylesFast({
  root: {
    alignItems: 'center',
    // Redeclaring the original padding as !important to override any other padding rules
    padding: '6px 16px !important',
  },
  message: {
    color: colors.grey90,
    fontSize: '.8125rem',
  },
  standardWarning: {
    backgroundColor: colors.yellow20,
    '& .MuiAlert-icon': {
      color: colors.yellow50,
    },
    '& .MuiAlert-message': {
      color: colors.yellow70,
    },
  },
  standardSuccess: {
    backgroundColor: colors.green10,
    '& .MuiAlert-icon': {
      color: colors.green40,
    },
  },
  standardInfo: {
    backgroundColor: colors.blue10,
    '& .MuiAlert-icon': {
      color: colors.blue50,
    },
  },
  standardError: {
    backgroundColor: colors.red10,
    '& .MuiAlert-icon': {
      color: colors.red70,
    },
  },
})(Alert)
