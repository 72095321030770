import { InfoOutlined } from '@mui/icons-material'
import { Switch } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, SitelineTooltip, useSitelineSnackbar } from 'siteline-common-web'
import { Row } from '../../../common/components/Row'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import {
  MinimalIntegrationProperties,
  useUpdateIntegrationMutation,
} from '../../../common/graphql/apollo-operations'

const i18nBase = 'projects.subcontractors.settings.integrations'

interface IntegrationSettingsProps {
  integration: MinimalIntegrationProperties
  open: boolean
  onClose: () => void
}

/** Simple dialog for advanced GC portal integration settings */
export function IntegrationSettings({ integration, open, onClose }: IntegrationSettingsProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [updateIntegration, { loading: submitting }] = useUpdateIntegrationMutation()
  const [allowGcPortalManualSovEditing, setAllowGcPortalManualSovEditing] = useState<boolean>(
    integration.allowGcPortalManualSovEditing
  )

  // Update the state if the integration value changes
  useEffect(() => {
    setAllowGcPortalManualSovEditing(integration.allowGcPortalManualSovEditing)
  }, [integration])

  const handleSubmit = useCallback(async () => {
    try {
      await updateIntegration({
        variables: {
          input: {
            id: integration.id,
            allowGcPortalManualSovEditing,
          },
        },
      })
      snackbar.showSuccess(
        t(`${i18nBase}.updated_settings`, { integration: integration.shortName })
      )
      onClose()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [
    updateIntegration,
    integration.id,
    integration.shortName,
    allowGcPortalManualSovEditing,
    snackbar,
    t,
    onClose,
  ])

  return (
    <SitelineDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title={t(`${i18nBase}.advanced_settings`, { integration: integration.shortName })}
      onSubmit={handleSubmit}
      submitLabel={t('common.actions.save')}
      submitting={submitting}
    >
      <Row alignItems="center" gap={24}>
        <SitelineText
          variant="body1"
          color="grey50"
          endIcon={
            <SitelineTooltip
              title={t(`${i18nBase}.manual_sov_editing_tooltip`, {
                integration: integration.shortName,
              })}
            >
              <InfoOutlined fontSize="small" />
            </SitelineTooltip>
          }
        >
          {t(`${i18nBase}.enable_manual_sov_editing`)}
        </SitelineText>
        <Switch
          checked={allowGcPortalManualSovEditing}
          onChange={(event) => {
            setAllowGcPortalManualSovEditing(event.target.checked)
          }}
        />
      </Row>
    </SitelineDialog>
  )
}
