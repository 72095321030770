import { ReactNode, createContext, useContext, useMemo } from 'react'
import { CompanyUserRole } from 'siteline-common-all'
import { useMultiCompanyContext } from './CompanyContext'

interface UserRoleContextData {
  userRole: CompanyUserRole | null
}

const UserRoleContext = createContext<UserRoleContextData>({
  userRole: null,
})

/**
 * UserRoleProvider is a wrapper around the app which is used for conditional rendering logic surrounding
 * two versions of the app - the standard siteline app and the field worker tool. If the user role
 * is field guest, we will display a more limited version of the app. This can also be used for admin
 * role checking.
 *
 * NOTE: This provider must always be wrapped in the `CompanyProvider` and the `UserProvider` because
 * the data from these providers is what we'll use to determine the user role
 */
export function UserRoleProvider({ children }: { children: ReactNode }) {
  const { companyUsers, companyId } = useMultiCompanyContext()

  const contextValue: UserRoleContextData = useMemo(() => {
    const currentCompanyUser = companyUsers.find(
      (companyUser) => companyUser.company.id === (companyId ?? '')
    )

    // User roles are company-specific. `companyId` will be null in the context of viewing "all companies".
    // In this case, a user role won't exist
    if (companyId === null || currentCompanyUser === undefined) {
      return { userRole: null }
    }

    return { userRole: currentCompanyUser.role }
  }, [companyId, companyUsers])

  return <UserRoleContext.Provider value={contextValue}>{children}</UserRoleContext.Provider>
}

export function useUserRoleContext() {
  return useContext(UserRoleContext)
}
