import { TFunction } from 'i18next'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
  SpreadsheetRow,
  SpreadsheetValue,
  ValidationFunctionResult,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  SovLineItemProperties,
  UpdateWorksheetLineItemsInput,
  WorksheetLineItemProgressProperties,
} from '../graphql/apollo-operations'
import { FieldGuestLumpSumInvoiceColumn } from './FieldGuestLumpSumInvoice'
import { FieldGuestUnitPriceInvoiceColumn } from './FieldGuestUnitPriceInvoice'
import { EditingSovLineItem } from './ManageSov'
import { currentWorksheetProgressPercentComplete } from './PayApp'

export enum BaseFieldGuestInvoiceColumn {
  NUMBER = 'number',
  NAME = 'name',
  COST_CODE = 'costCode',
  PERCENT_COMPLETE = 'percentComplete',
  PRE_SITELINE_BILLING = 'preSitelineBilling',
}

export type FieldGuestInvoiceColumn =
  | BaseFieldGuestInvoiceColumn
  | FieldGuestLumpSumInvoiceColumn
  | FieldGuestUnitPriceInvoiceColumn

export type EditingWorksheetLineItem = {
  id: string
  code: string
  name: string
  costCode?: string | null
  totalValue: number
  unitPrice?: number
  unitName?: string
  preSitelineBilled?: number
  billedToDate: number
}

export type EditingWorksheetSovLineItem = {
  id: string
  code: string
  name: string
  costCode?: string | null
  latestTotalValue: number
  unitPrice?: number
  unitName?: string

  worksheetLineItems: EditingWorksheetLineItem[]

  // Not editable on the worksheet, but needed for determining whether worksheet items can be
  // edited and deleted
  hasPreSitelineBilling: boolean
  isChangeOrder: boolean
}

export type EditingWorksheet = EditingWorksheetSovLineItem[]

export function sovLineItemToEditingWorksheetSovLineItem(
  sovLineItem: SovLineItemProperties
): EditingWorksheetSovLineItem {
  const worksheetLineItems = _.chain(sovLineItem.worksheetLineItems)
    .orderBy((worksheetLineItem) => worksheetLineItem.sortOrder)
    .map((worksheetLineItem) => ({
      id: worksheetLineItem.id,
      code: worksheetLineItem.code,
      name: worksheetLineItem.name,
      costCode: worksheetLineItem.costCode,
      totalValue: worksheetLineItem.totalValue,
      unitPrice: sovLineItem.unitPrice ?? undefined,
      unitName: sovLineItem.unitName ?? undefined,
      preSitelineBilled: worksheetLineItem.previousBilled,
      billedToDate: worksheetLineItem.billedToDate,
    }))
    .value()
  return {
    id: sovLineItem.id,
    code: sovLineItem.code,
    name: sovLineItem.name,
    costCode: sovLineItem.costCode,
    latestTotalValue: sovLineItem.latestTotalValue,
    unitPrice: sovLineItem.unitPrice ?? undefined,
    unitName: sovLineItem.unitName ?? undefined,
    hasPreSitelineBilling: sovLineItem.previousBilled !== 0,
    isChangeOrder: sovLineItem.isChangeOrder,
    worksheetLineItems,
  }
}

export function makeEmptyEditingWorksheetLineItem({
  includePreSitelineBilling,
  sovLineItem,
}: {
  includePreSitelineBilling: boolean
  sovLineItem: EditingWorksheetSovLineItem
}): EditingWorksheetLineItem {
  return {
    id: uuidv4(),
    code: '',
    name: '',
    costCode: '',
    totalValue: 0,
    unitName: sovLineItem.unitName,
    unitPrice: sovLineItem.unitPrice,
    preSitelineBilled: includePreSitelineBilling ? 0 : undefined,
    billedToDate: 0,
  }
}

export function worksheetLineItemsFromWorksheet(
  worksheet: EditingWorksheet
): EditingWorksheetLineItem[] {
  return worksheet.flatMap((lineItem) => lineItem.worksheetLineItems)
}

export function invoiceColumnToEditingWorksheetLineItemField(
  column: FieldGuestInvoiceColumn
): keyof EditingWorksheetLineItem {
  switch (column) {
    case BaseFieldGuestInvoiceColumn.NUMBER:
      return 'code'
    case BaseFieldGuestInvoiceColumn.NAME:
      return 'name'
    case BaseFieldGuestInvoiceColumn.COST_CODE:
      return 'costCode'
    case BaseFieldGuestInvoiceColumn.PRE_SITELINE_BILLING:
      return 'preSitelineBilled'
    case FieldGuestLumpSumInvoiceColumn.SCHEDULED_VALUE:
    case FieldGuestUnitPriceInvoiceColumn.TOTAL_UNITS:
      return 'totalValue'
    case BaseFieldGuestInvoiceColumn.PERCENT_COMPLETE:
    case FieldGuestUnitPriceInvoiceColumn.UNIT_NAME:
    case FieldGuestUnitPriceInvoiceColumn.CURRENT_UNITS_BILLED:
      throw new Error('Worksheet column is not editable')
  }
}

/**
 * Checks that a cell value is valid and if not, returns a message to display to the user
 * that describes the problem
 */
export function validatePercentComplete(
  newValue: SpreadsheetValue,
  progress: WorksheetLineItemProgressProperties,
  t: TFunction
): ValidationFunctionResult {
  const value = Number(newValue)
  if (isNaN(value)) {
    return { type: 'error', message: t('common.spreadsheet.not_a_number') }
  }
  if (progress.totalValue === 0 && value !== 0) {
    return {
      type: 'error',
      message: t('projects.subcontractors.pay_app.invoice.zero_scheduled_value'),
    }
  }
  if (value > 100) {
    return {
      type: 'error',
      message: t('projects.subcontractors.pay_app.invoice.percent_complete_maximum', {
        num: `100%`,
      }),
    }
  } else {
    const progressWithoutProgressBilled = { ...progress, progressBilled: 0 }
    const minPercentComplete = currentWorksheetProgressPercentComplete(
      progressWithoutProgressBilled
    )
    if (value < minPercentComplete) {
      return {
        type: 'error',
        message: t('projects.subcontractors.pay_app.invoice.percent_complete_minimum', {
          num: `${minPercentComplete}%`,
        }),
      }
    }
  }
  return null
}

type ReorderableWorksheetLineItem = Pick<EditingWorksheetLineItem, 'id'>
function isHeaderRow(row: SpreadsheetRow): boolean {
  return !!row.isGroupHeaderRow
}
function isDividerRow(row: SpreadsheetRow): boolean {
  return row.cells.length === 0
}

/** Reorders the rate table line items, triggered by user drag and dropping a line item */
export function reorderWorksheetLineItem<T extends ReorderableWorksheetLineItem>({
  lineItems,
  lineItem,
  lineItemIndex,
  fromRowIndex,
  toRowIndex,
  contentRows,
}: {
  lineItems: T[]
  lineItem: T
  lineItemIndex: number
  fromRowIndex: number
  toRowIndex: number
  contentRows: SpreadsheetRow[]
}) {
  // Remove line item from rows
  const newRows = [...contentRows]
  newRows.splice(fromRowIndex, 1)
  // Remove line item from spreadsheet data
  const newLineItems = [...lineItems]
  newLineItems.splice(lineItemIndex, 1)
  // Find previous *line item* at target index (skip groups/dividers)
  let previousLineItemRowIndex = toRowIndex - 1
  while (
    previousLineItemRowIndex >= 0 &&
    (isHeaderRow(newRows[previousLineItemRowIndex]) ||
      isDividerRow(newRows[previousLineItemRowIndex]))
  ) {
    previousLineItemRowIndex--
  }
  const previousLineItemId =
    previousLineItemRowIndex >= 0 ? newRows[previousLineItemRowIndex].id : undefined
  const previousLineItemIndex = newLineItems.findIndex(
    (lineItem) => lineItem.id === previousLineItemId
  )
  newLineItems.splice(previousLineItemIndex + 1, 0, lineItem)

  return newLineItems
}

/**
 * Creates the input type used in the `UpdateWorksheetLineItems` mutation based on an
 * `EditingWorksheet`. Uses the initial worksheet line items to determine which line items are
 * new, edited, and deleted.
 */
export function editingWorksheetToUpdateWorksheetInput({
  worksheet,
  initialWorksheetLineItems,
  sovId,
}: {
  worksheet: EditingWorksheet
  initialWorksheetLineItems: EditingWorksheetLineItem[]
  sovId: string
}): UpdateWorksheetLineItemsInput {
  const initialWorksheetLineItemIds = new Set(initialWorksheetLineItems.map((item) => item.id))

  const worksheetLineItemInput = worksheet.flatMap((lineItem) =>
    lineItem.worksheetLineItems.map((worksheetLineItem, index) => {
      const isExistingLineItem = initialWorksheetLineItemIds.has(worksheetLineItem.id)
      return {
        ...(isExistingLineItem && { id: worksheetLineItem.id }),
        sortOrder: index + 1,
        code: worksheetLineItem.code,
        name: worksheetLineItem.name,
        costCode: worksheetLineItem.costCode ?? undefined,
        totalValue: worksheetLineItem.totalValue,
        previousBilled: worksheetLineItem.preSitelineBilled ?? 0,
        sovLineItemId: lineItem.id,
      }
    })
  )
  const worksheetLineItems = worksheetLineItemsFromWorksheet(worksheet)
  const nonEmptyWorksheetLineItems = worksheetLineItems.filter(
    (lineItem) =>
      !!lineItem.billedToDate ||
      !!lineItem.code ||
      !!lineItem.costCode ||
      !!lineItem.name ||
      !!lineItem.preSitelineBilled
  )
  const worksheetLineItemIds = new Set(nonEmptyWorksheetLineItems.map((lineItem) => lineItem.id))

  // This will remove 1) stored line items that don't exist in our updated worksheet, and 2)
  // worksheet line items that are empty (defined by nonEmptyWorksheetLineItems above)
  const deleteLineItems = initialWorksheetLineItems
    .filter((worksheetLineItem) => !worksheetLineItemIds.has(worksheetLineItem.id))
    .map((lineItem) => lineItem.id)

  return {
    sovId,
    lineItems: worksheetLineItemInput,
    deleteLineItems,
  }
}

/**
 * Compares total values of each worksheet line item to the sov line item total value
 * to determine if the sum of worksheet line item totals exceed the sov line item total. This
 * is referenced when validating changes to the sov line item scheduled value as well as a
 * worksheet line item total value.
 */
export function doesWorksheetExceedTotalValue({
  sovLineItem,
  currentWorksheetLineItem,
  toWorksheetLineItemValue,
  toSovLineItemValue,
}: {
  sovLineItem: EditingWorksheetSovLineItem | EditingSovLineItem
  /** Provided when making changes to a worksheet line item */
  currentWorksheetLineItem: EditingWorksheetLineItem | null
  /** Provided when making changes to a worksheet line item, should be in cents */
  toWorksheetLineItemValue: number | null
  /** Provided when making changes to the sov line item, should be in cents */
  toSovLineItemValue: number | null
}) {
  const isEditingWorksheetLineItem =
    currentWorksheetLineItem !== null && toWorksheetLineItemValue !== null

  // The max value is equivalent to the sov line item total value. The sum of all worksheet
  // line item total values cannot be greater than this.
  const maxValue = toSovLineItemValue ?? sovLineItem.latestTotalValue

  // Return early if there is no worksheet to compare the SOV line item to
  if (sovLineItem.worksheetLineItems.length === 0) {
    return { exceedsTotalValue: false, sumOfWorksheetLineItems: 0, sovLineItemTotalValue: maxValue }
  }

  // Filter out the current worksheet line item from the rest of the worksheet if
  // `toWorksheetLineItemValue` has been provided. If that value is being updated, we don't want
  // to include its old value in this sum.
  const otherWorksheetLineItems = isEditingWorksheetLineItem
    ? sovLineItem.worksheetLineItems.filter(
        (lineItem) => lineItem.id !== currentWorksheetLineItem.id
      )
    : sovLineItem.worksheetLineItems

  // Sum all of the worksheet line item total values together
  const sumOfWorksheetLineItems =
    _.sumBy(otherWorksheetLineItems, (lineItem) => lineItem.totalValue) +
    (toWorksheetLineItemValue ?? 0)

  return {
    exceedsTotalValue: sumOfWorksheetLineItems > maxValue,
    sumOfWorksheetLineItems,
    sovLineItemTotalValue: maxValue,
  }
}
