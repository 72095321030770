import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { SitelineCard } from '../../../common/components/SitelineCard'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    textAlign: 'center',
    border: `1px solid ${colors.grey30}`,
    '&.rowLayout': {
      gap: theme.spacing(2),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      '& .cardImage': {
        marginBottom: 0,
        borderRadius: theme.spacing(0.5),
        width: 48,
        height: 48,
        '&.smallImage': {
          width: 32,
          height: 32,
        },
        '&.xsImage': {
          width: 24,
          height: 24,
        },
      },
      '& .textContainer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      '& .subtitle': {
        whiteSpace: 'inherit',
      },
    },
    '&.rectangleLayout': {
      height: 150,
    },
    '&.squareLayout': {
      height: 200,
    },
    '&.isDisabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&.selected': {
      border: `2px solid ${colors.grey50}`,
    },
    '& .cardImage': {
      marginBottom: theme.spacing(2),
      width: 56,
      height: 56,
      '& .MuiSvgIcon-root': {
        color: colors.grey70,
        fontSize: 56,
      },
      '&.xsImage': {
        width: 24,
        height: 24,
        '& .MuiSvgIcon-root': {
          fontSize: 24,
        },
      },
      '&.smallImage': {
        width: 32,
        height: 32,
        '& .MuiSvgIcon-root': {
          fontSize: 32,
        },
      },
      '&.lgImage': {
        width: 155,
        '& .MuiSvgIcon-root': {
          fontSize: 48,
        },
      },
    },
    '& .hoverImage': {
      display: 'none',
    },
    '&:hover': {
      '& .hoverImage': {
        display: 'block',
      },
      '& .nonHoverImage': {
        display: 'none',
      },
    },
    '& .cardTitle': {
      display: 'block',
      '&.withSubtitle': {
        marginBottom: theme.spacing(0.5),
      },
    },
    '& .subtitle': {
      whiteSpace: 'pre-wrap',
    },
  },
}))

interface OnboardingButtonProps {
  imageSrc?: string | ReactNode
  hoverImageSrc?: string
  imageSize?: 'xs' | 'sm' | 'md' | 'lg'
  title: string
  subtitle?: string
  onClick: (() => void) | undefined
  layout?: 'rectangle' | 'row' | 'square'
  disabled?: boolean
  className?: string
  selected?: boolean
}

/** A large button, laid out vertically, used in the onboarding flow */
export function OnboardingButton({
  imageSrc,
  hoverImageSrc,
  imageSize = 'md',
  title,
  subtitle,
  onClick,
  layout,
  disabled,
  className,
  selected,
}: OnboardingButtonProps) {
  const classes = useStyles()

  return (
    <SitelineCard
      className={clsx(
        classes.root,
        {
          rowLayout: layout === 'row',
          squareLayout: layout === 'square',
          rectangleLayout: layout === 'rectangle',
          isDisabled: disabled,
          selected,
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {!!imageSrc && (
        <>
          {typeof imageSrc === 'string' ? (
            <>
              <img
                src={imageSrc}
                alt={title}
                className={clsx('cardImage', {
                  xsImage: imageSize === 'xs',
                  smallImage: imageSize === 'sm',
                  lgImage: imageSize === 'lg',
                  nonHoverImage: hoverImageSrc !== undefined,
                })}
              />
              {hoverImageSrc && (
                <img
                  src={hoverImageSrc}
                  alt={title}
                  className={clsx('cardImage', 'hoverImage', {
                    xsImage: imageSize === 'xs',
                    smallImage: imageSize === 'sm',
                    lgImage: imageSize === 'lg',
                  })}
                />
              )}
            </>
          ) : (
            <div
              className={clsx('cardImage', {
                xsImage: imageSize === 'xs',
                smallImage: imageSize === 'sm',
                lgImage: imageSize === 'lg',
              })}
            >
              {imageSrc}
            </div>
          )}
        </>
      )}
      <div className="textContainer">
        <SitelineText
          variant={layout === 'row' && !subtitle ? 'h4' : 'secondary'}
          bold
          color={onClick ? 'grey70' : 'grey50'}
          className={clsx('cardTitle', { withSubtitle: !!subtitle })}
        >
          {title}
        </SitelineText>
        {subtitle && (
          <SitelineText variant="smallText" color="grey50" className="subtitle">
            {subtitle}
          </SitelineText>
        )}
      </div>
    </SitelineCard>
  )
}
