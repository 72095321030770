import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CHANGE_ORDER_REQUEST_NAME_MAX_CHARS, replaceAllWhitespaces } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { Column } from '../../../common/components/Column'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { TextFieldWithLabel } from '../../../common/components/TextFieldWithLabel'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  ChangeOrderRequestsDocument,
  ChangeOrderRequestsQuery,
  MinimalChangeOrderRequestProperties,
  useCreateChangeOrderRequestMutation,
} from '../../../common/graphql/apollo-operations'
import { getNextCode } from '../../../common/util/Sov'
import { getChangeOrdersPath } from '../Billing.lib'
import { MinimalChangeOrderRequestWithRateTableItems } from './MarkChangeOrderRequestSubmittedDialog'

const i18nBase = 'projects.subcontractors.change_order_requests.details'

interface CreateChangeOrderRequestDialogProps {
  open: boolean
  onClose: () => void
  changeOrderRequests: (
    | MinimalChangeOrderRequestWithRateTableItems
    | MinimalChangeOrderRequestProperties
  )[]
}

/** Dialog that handles creating a change order request with a name only, then navigating to the details page */
export function CreateChangeOrderRequestDialog({
  open,
  onClose,
  changeOrderRequests,
}: CreateChangeOrderRequestDialogProps) {
  const { t } = useTranslation()
  const { contract, id: projectId } = useProjectContext()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()

  // When we create this COR, we'll guess what the internal number should be based on existing
  // COR internal numbers.
  const initialNumber = useMemo(() => {
    const existingInternalNumbers = _.chain(changeOrderRequests)
      .map((request) => request.internalNumber)
      .compact()
      .value()
    return existingInternalNumbers.length ? getNextCode(existingInternalNumbers) : undefined
  }, [changeOrderRequests])

  const [name, setName] = useState<string>('')
  const [number, setNumber] = useState<string | undefined>(undefined)

  const [createChangeOrderRequest, { loading }] = useCreateChangeOrderRequestMutation()

  // When number is initially set, `changeOrderRequests` may not have loaded yet. Update
  // the number once `initialNumber` is set due to `changeOrderRequests` loading.
  useEffect(() => {
    if (number === undefined && initialNumber !== undefined) {
      setNumber(initialNumber)
    }
  }, [initialNumber, number])

  const nameInputProps = useMemo(
    () => ({
      htmlInput: {
        maxLength: CHANGE_ORDER_REQUEST_NAME_MAX_CHARS,
      },
    }),
    []
  )

  const handleNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value)
  }, [])

  const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const update = replaceAllWhitespaces(event.target.value).trim()
    setName(update)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!contract || !name.length) {
      return
    }

    try {
      const result = await createChangeOrderRequest({
        variables: {
          input: {
            contractId: contract.id,
            name,
            attachments: [],
            internalNumber: number,
          },
        },
        update(cache, { data }) {
          if (!data) {
            return
          }
          const queryData: ChangeOrderRequestsQuery | null = cache.readQuery({
            query: ChangeOrderRequestsDocument,
            variables: { contractId: contract.id },
          })
          if (queryData) {
            cache.writeQuery({
              query: ChangeOrderRequestsDocument,
              variables: { contractId: contract.id },
              data: {
                changeOrderRequests: [
                  ...queryData.changeOrderRequests,
                  data.createChangeOrderRequest,
                ],
              },
            })
          }
        },
      })
      const newChangeOrderRequest = result.data?.createChangeOrderRequest
      if (newChangeOrderRequest) {
        navigate(getChangeOrdersPath({ projectId, changeOrderRequestId: newChangeOrderRequest.id }))
        snackbar.showSuccess(t(`${i18nBase}.created_change_order_request`))
      }
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [contract, createChangeOrderRequest, name, navigate, number, projectId, snackbar, t])

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      title={t(`${i18nBase}.new_change_order_request`)}
      submitLabel={t(`${i18nBase}.create_change_order_request`)}
      disableSubmit={name.length === 0 || loading}
      submitting={loading}
      onSubmit={handleSubmit}
      maxWidth="sm"
    >
      <Column gap={16}>
        <TextFieldWithLabel
          label={t(`${i18nBase}.number`)}
          value={number}
          onChange={handleNumberChange}
          autoFocus={initialNumber === undefined}
        />
        <TextFieldWithLabel
          label={t(`${i18nBase}.name`)}
          onChange={handleNameChange}
          autoFocus={initialNumber !== undefined}
          slotProps={nameInputProps}
        />
      </Column>
    </SitelineDialog>
  )
}
