import { TFunction } from 'i18next'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  DropEvent,
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone,
} from 'react-dropzone'

// Cloud Run has a max payload size of 32mb, so we limit file uploads to 30mb.
// See https://github.com/siteline/siteline/issues/5233
export const MAX_FILE_SIZE = 30 * 1024 * 1024

type SnackbarLike = {
  showErrors: (errors: string[]) => void
}

/**
 * Handles rejected files from a react-dropzone instance.
 */
function handleRejectedFiles(files: FileRejection[], t: TFunction, snackbar: SnackbarLike): void {
  const errors = _.flatten(
    files.map((rejection) => {
      return rejection.errors.map((error) => {
        switch (error.code) {
          case 'file-too-large':
            return t('upload.too_large')
          case 'file-too-small':
            return t('upload.too_small')
          case 'too-many-files':
            return t('upload.too_many')
          case 'file-invalid-type':
            return t('upload.invalid_type')

          // Error is a generic string
          // eslint-disable-next-line no-restricted-syntax
          default:
            return error.message
        }
      })
    })
  )
  if (errors.length > 0) {
    snackbar.showErrors(errors)
  }
}

/**
 * Wraps `useDropzone` and adds the following features:
 *  - Ensure max size is always 30mb
 *  - Automatically show snackbar errors for rejected files
 */
export function useSitelineDropzone(
  t: TFunction,
  snackbar: SnackbarLike,
  options?: DropzoneOptions
): DropzoneState {
  const onDropWrapper = useCallback(
    (accepted: File[], rejected: FileRejection[], event: DropEvent): void => {
      handleRejectedFiles(rejected, t, snackbar)
      if (options?.onDrop) {
        options.onDrop(accepted, rejected, event)
      }
    },
    [options, snackbar, t]
  )
  return useDropzone({
    ...options,
    onDrop: onDropWrapper,
    maxSize: MAX_FILE_SIZE,
  })
}
