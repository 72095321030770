import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSitelineSnackbar } from 'siteline-common-web'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useAddContractFilesMutation } from '../../../common/graphql/apollo-operations'
import { FileDragUpload, PendingFile } from '../backup/attachments/FileDragUpload'
import { ContractForProjectHome } from '../home/ProjectHome'

const i18nBase = 'projects.subcontractors.settings.contract'

interface AddContractFilesDialogProps {
  open: boolean
  onClose: () => void
  contract: ContractForProjectHome
}

/** Dialog for uploading contract documents via project settings */
export function AddContractFilesDialog({ open, onClose, contract }: AddContractFilesDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const [contractFiles, setContractFiles] = useState<PendingFile[] | null>(null)

  const [addContractFiles, { loading: uploading }] = useAddContractFilesMutation()

  const handleResetDialog = useCallback(() => {
    setContractFiles(null)
  }, [])

  const contractFilesForUpload = useMemo(() => {
    if (contractFiles === null) {
      return []
    }
    return _.chain(contractFiles)
      .map((contractFile) => contractFile.file)
      .compact()
      .value()
  }, [contractFiles])

  const pendingFiles = useMemo(() => contractFiles ?? [], [contractFiles])

  const handleRemovePendingFile = useCallback((index: number) => {
    setContractFiles((current) => {
      if (current === null) {
        return null
      }
      const update = [...current]
      update.splice(index, 1)
      return update
    })
  }, [])

  const handleSubmit = useCallback(async () => {
    if (contractFilesForUpload.length === 0) {
      return
    }

    try {
      await addContractFiles({
        variables: {
          input: {
            contractId: contract.id,
            files: contractFilesForUpload,
          },
        },
      })
      onClose()
      snackbar.showSuccess(
        t(`${i18nBase}.uploaded_contract_file`, { count: contractFilesForUpload.length })
      )
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [contractFilesForUpload, addContractFiles, contract.id, onClose, snackbar, t])

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      size="email"
      title={t(`${i18nBase}.upload_files`)}
      submitLabel={t('common.actions.upload')}
      onSubmit={handleSubmit}
      actionsLayout={contractFilesForUpload.length ? 'actionsRow' : 'closeIcon'}
      submitting={uploading}
      onResetDialog={handleResetDialog}
    >
      <FileDragUpload
        pendingFiles={pendingFiles}
        setPendingFiles={setContractFiles}
        onRemovePendingFile={handleRemovePendingFile}
        allowMultiple
        disableEditFilename
      />
    </SitelineDialog>
  )
}
