import { Switch } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, SitelineTooltip, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  toggle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

interface IncludeChangeOrderLogInPayAppToggleProps {
  shouldInclude: boolean
  onChange: (update: boolean) => void
  disabled: boolean
  shouldShowDisabledTooltip: boolean
}

const i18nBase = 'projects.subcontractors.settings.forms'

/** Small toggle component that handles the setting for including the change order log in pay app packages */
export function IncludeChangeOrderLogInPayAppToggle({
  shouldInclude,
  onChange,
  disabled,
  shouldShowDisabledTooltip,
}: IncludeChangeOrderLogInPayAppToggleProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked)
    },
    [onChange]
  )

  // The tooltip message is only relevant if `shouldInclude` is false
  const isAddFormsTooltip = disabled && shouldInclude === false && shouldShowDisabledTooltip

  return (
    <div className={classes.toggle}>
      <SitelineText variant="body1" color="grey50">
        {t(`${i18nBase}.include_cor_log_pay_apps`)}
      </SitelineText>
      <SitelineTooltip
        placement="top"
        title={isAddFormsTooltip ? t(`${i18nBase}.include_cor_toggle_disabled`) : ''}
      >
        <div>
          <Switch checked={shouldInclude} onChange={handleChange} disabled={disabled} />
        </div>
      </SitelineTooltip>
    </div>
  )
}
