import { Autocomplete, Collapse, FormControlLabel, RadioGroup, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { centsToDollars, integrationTypes } from 'siteline-common-all'
import {
  IntegrationGeneralLedgerAccount,
  SitelineText,
  colors,
  makeStylesFast,
} from 'siteline-common-web'
import { SitelineRadio } from '../../../common/components/SitelineRadio'
import { Spreadsheet } from '../../../common/components/Spreadsheet/Spreadsheet'
import {
  SpreadsheetCell,
  SpreadsheetColumn,
  SpreadsheetContent,
  SpreadsheetDataType,
  SpreadsheetRow,
  SpreadsheetRowType,
  SpreadsheetValue,
  makeDataCell,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { PayApp } from '../../../common/graphql/Fragments'
import { useGetContractForPayAppQuery } from '../../../common/graphql/apollo-operations'
import { LedgerAllocationType, getLedgerAccountLabel } from './SyncFoundationDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .bold': {
      fontWeight: 600,
    },
  },
  radioOption: {
    marginBottom: theme.spacing(0),
  },
  spreadsheet: {
    marginTop: theme.spacing(3),
  },
  autocomplete: {
    margin: theme.spacing(0, 0, 2, 4),
    width: 468,
    backgroundColor: colors.white,
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
}))

const i18nBase = 'integrations.foundation_sync_dialog'

enum LedgerSelectorColumns {
  LINE_ITEM_ID = 'LINE_ITEM_ID',
  LINE_ITEM_NAME = 'LINE_ITEM_NAME',
  LINE_ITEM_SCHEDULED_VALUE = 'LINE_ITEM_SCHEDULED_VALUE',
  LINE_ITEM_CURRENT_BILLED = 'LINE_ITEM_CURRENT_BILLED',
  LEDGER_ACCOUNT = 'LEDGER_ACCOUNT',
}
export interface LedgerAccountOptionsProps {
  ledgerAccount: IntegrationGeneralLedgerAccount | null
  ledgerAccounts: IntegrationGeneralLedgerAccount[]
  ledgerType: LedgerAllocationType
  ledgerAccountMapping: integrationTypes.FoundationLineItemMapping[]
  onLedgerTypeChange: (ledgerType: LedgerAllocationType) => void
  onLedgerAccountIdChange: (ledgerAccountId: string | null) => void
  onLedgerAccountMappingChange: (
    ledgerAccountMapping: integrationTypes.FoundationLineItemMapping[]
  ) => void
  payApp: PayApp
}

/** Shows radio buttons for selecting a default general ledger or assign by line item */
export function LedgerAccountOptions({
  ledgerAccount,
  ledgerAccounts,
  ledgerType,
  onLedgerTypeChange,
  onLedgerAccountIdChange,
  ledgerAccountMapping,
  onLedgerAccountMappingChange,
  payApp,
}: LedgerAccountOptionsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id: projectId } = useProjectContext()
  const { companyId } = useCompanyContext()
  const { data: contractData, loading } = useGetContractForPayAppQuery({
    variables: {
      input: { projectId, companyId },
    },
  })

  const contract = contractData?.contractByProjectId
  const selectableLedgerAccounts = ledgerAccounts.map((ledgerAccount) => {
    return {
      id: ledgerAccount.integrationAccountId,
      label: getLedgerAccountLabel(ledgerAccount),
    }
  })

  const handleUpdateLedgerType = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const newLedgerType = evt.target.value as LedgerAllocationType
      onLedgerTypeChange(newLedgerType)
    },
    [onLedgerTypeChange]
  )

  const sovLineItemIdsWithProgress = useMemo(
    () =>
      payApp.progress
        .filter((progressLineItem) => progressLineItem.currentBilled !== 0)
        .map((progressLineItem) => progressLineItem.sovLineItem.id),
    [payApp.progress]
  )

  const columns: SpreadsheetColumn[] = [
    {
      id: LedgerSelectorColumns.LINE_ITEM_ID,
      heading: t(`${i18nBase}.spreadsheet.line_item_id_column_header`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      minWidth: 125,
    },
    {
      id: LedgerSelectorColumns.LINE_ITEM_NAME,
      heading: t(`${i18nBase}.spreadsheet.line_item_name_column_header`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
    },

    {
      id: LedgerSelectorColumns.LINE_ITEM_SCHEDULED_VALUE,
      heading: t(`${i18nBase}.spreadsheet.scheduled_value_column_header`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 125,
    },
    {
      id: LedgerSelectorColumns.LINE_ITEM_CURRENT_BILLED,
      heading: t(`${i18nBase}.spreadsheet.current_billed_column_header`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 125,
    },
    {
      id: LedgerSelectorColumns.LEDGER_ACCOUNT,
      heading: t(`${i18nBase}.spreadsheet.ledger_account_column_header`),
      isEditable: true,
      dataType: SpreadsheetDataType.SELECT,
      align: 'center',
      options: selectableLedgerAccounts,
      extraWidth: 8,
    },
  ]

  const content: SpreadsheetContent = useMemo(() => {
    // Filter only to line items that have current billed on this pay app
    if (!contract || !contract.sov) {
      return { rows: [], enableReorderRows: false }
    }

    const sovLineItems = _.chain(contract.sov.lineItems)
      .filter((lineItem) => sovLineItemIdsWithProgress.includes(lineItem.id))
      .orderBy((lineItem) => lineItem.sortOrder)
      .value()

    const lineItemRows: (SpreadsheetRow | null)[] = sovLineItems.map((sovLineItem) => {
      const progress = payApp.progress.find(
        (progressLineItem) => progressLineItem.sovLineItem.id === sovLineItem.id
      )
      const previousLedgerAccountMapping = ledgerAccountMapping.find(
        (mappedItem) => mappedItem.sitelineLineItemId === sovLineItem.id
      )
      const previousLedgerAccountId = !_.isNil(previousLedgerAccountMapping)
        ? previousLedgerAccountMapping.agaveLedgerAccountId
        : ''

      if (!progress) {
        return null
      }

      const cells: SpreadsheetCell[] = [
        makeDataCell(sovLineItem.code),
        makeDataCell(sovLineItem.name),
        makeDataCell(centsToDollars(progress.totalValue)),
        makeDataCell(centsToDollars(progress.currentBilled)),
        makeDataCell(previousLedgerAccountId),
      ]
      return {
        type: SpreadsheetRowType.DEFAULT,
        id: sovLineItem.id,
        cells,
      }
    })

    return {
      rows: _.compact(lineItemRows),
      enableReorderRows: false,
    }
  }, [contract, ledgerAccountMapping, payApp.progress, sovLineItemIdsWithProgress])

  // NOTE that we don't check column ID here because there is only one editable column
  const handleChange = useCallback(
    (rowId: string, columnId: string, toValue: SpreadsheetValue) => {
      const ledgerAccountId = toValue as string
      const filteredUpdate = ledgerAccountMapping.filter(
        (lineItem) => lineItem.sitelineLineItemId !== rowId
      )
      const update = [
        ...filteredUpdate,
        {
          sitelineLineItemId: rowId,
          agaveLedgerAccountId: ledgerAccountId,
        },
      ]
      onLedgerAccountMappingChange(update)
    },
    [ledgerAccountMapping, onLedgerAccountMappingChange]
  )

  return (
    <div className={classes.root}>
      <RadioGroup
        name="ledger-account-setting"
        onChange={handleUpdateLedgerType}
        value={ledgerType}
      >
        <FormControlLabel
          label={t(`${i18nBase}.use_default_ledger_account`)}
          value={LedgerAllocationType.SINGLE_LEDGER_ACCOUNT}
          control={<SitelineRadio color="primary" size="small" />}
          className={classes.radioOption}
        />
        <Collapse in={ledgerType === LedgerAllocationType.SINGLE_LEDGER_ACCOUNT}>
          <div>
            <Autocomplete
              value={ledgerAccount ?? undefined}
              disableClearable={true}
              freeSolo={false}
              options={ledgerAccounts}
              autoHighlight
              openOnFocus
              onChange={(ev, option) => {
                onLedgerAccountIdChange(option.integrationAccountId)
              }}
              className={classes.autocomplete}
              renderInput={(params) => {
                return (
                  <TextField
                    variant="outlined"
                    placeholder={t(`${i18nBase}.search_accounts`)}
                    {...params}
                  />
                )
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.integrationAccountId}>
                  <SitelineText variant="secondary">{getLedgerAccountLabel(option)}</SitelineText>
                </li>
              )}
              getOptionLabel={getLedgerAccountLabel}
            />
          </div>
        </Collapse>
        <FormControlLabel
          label={t(`${i18nBase}.use_multiple_ledger_accounts`)}
          value={LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM}
          control={<SitelineRadio color="primary" size="small" />}
          className={classes.radioOption}
        />
        <Collapse in={ledgerType === LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM}>
          <div className={classes.spreadsheet}>
            <Spreadsheet
              columns={columns}
              blurOnClickAway={false}
              content={content}
              onChange={handleChange}
              loading={loading}
            />
          </div>
        </Collapse>
      </RadioGroup>
    </div>
  )
}
