import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import LaunchIcon from '@mui/icons-material/Launch'
import {
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Switch,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { supportsRetentionTrackingLevel } from 'siteline-common-all'
import {
  BillingType,
  RetentionTrackingLevel,
  SitelineText,
  colors,
  makeStylesFast,
} from 'siteline-common-web'
import { SitelineRadio } from '../../../common/components/SitelineRadio'
import { ImportProjectOnboardingMetadataProperties } from '../../../common/graphql/apollo-operations'
import { ProjectOnboardingRetention } from '../../../common/util/ProjectOnboarding'
import { RetentionPercentInput } from '../invoice/retention/RetentionPercentInput'
import { INPUT_CLASS, OnboardingFormRow } from './OnboardingFormRow'
import { ProjectOnboardingFormCard } from './ProjectOnboardingFormCard'

const useStyles = makeStylesFast((theme: Theme) => ({
  title: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  radioGroup: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    '& .retentionDescription': {
      display: 'block',
      marginTop: theme.spacing(-0.5),
      marginBottom: theme.spacing(1),
      // Align vertically with radio button label
      marginLeft: theme.spacing(4),
    },
    [`& .${INPUT_CLASS}`]: {
      overflow: 'visible',
    },
  },
  roundingRow: {
    paddingTop: theme.spacing(1),
    [`& .${INPUT_CLASS}`]: {
      overflow: 'visible',
    },
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: colors.grey30,
    },
  },
  contractRetention: {
    '& input': {
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
    },
  },
  divider: {
    backgroundColor: colors.grey20,
    marginTop: theme.spacing(4),
  },
  advancedSettings: {
    marginLeft: theme.spacing(-1),
    // Override the default margin, since this will look like the last child
    marginBottom: `${theme.spacing(0)} !important`,
  },
  expandIcon: {
    // Animate the arrow icon flipping around when the menu opens
    transition: theme.transitions.create('transform'),
    '&.expanded': {
      // Flip the dropdown arrow upside down when the menu opens
      transform: 'rotate(-180deg)',
    },
  },
}))

const i18nBase = 'projects.onboarding.retention'

export const RETENTION_HELP_CENTER_ARTICLE =
  'https://support.siteline.com/hc/en-us/articles/10214577574292-Retention-Type-Options'

// Retention types for lump sum & unit price
const LUMP_SUM_RETENTION_TYPES = [
  RetentionTrackingLevel.STANDARD,
  RetentionTrackingLevel.LINE_ITEM,
  RetentionTrackingLevel.PAY_APP,
  RetentionTrackingLevel.PROJECT,
] as const

const TIME_AND_MATERIALS_RETENTION_TYPES = [
  RetentionTrackingLevel.PAY_APP,
  RetentionTrackingLevel.PROJECT,
] as const

interface RetentionFormProps {
  retentionInfo: ProjectOnboardingRetention
  onRetentionInfoChange: (retentionInfo: ProjectOnboardingRetention) => void
  billingType: BillingType
  gcProject: ImportProjectOnboardingMetadataProperties | null
}

/** Form for onboarding retention info for a new lump sum project */
export function RetentionForm({
  retentionInfo,
  onRetentionInfoChange,
  billingType,
  gcProject,
}: RetentionFormProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)

  const retentionTypes = useMemo(() => {
    const supportedRetentionTypes =
      billingType === BillingType.TIME_AND_MATERIALS
        ? TIME_AND_MATERIALS_RETENTION_TYPES
        : LUMP_SUM_RETENTION_TYPES
    if (gcProject === null) {
      return supportedRetentionTypes
    }
    // Procore & GC pay do not support all retention types. All other integrations do.
    return supportedRetentionTypes.filter((retentionType) =>
      supportsRetentionTrackingLevel(gcProject.type, retentionType)
    )
  }, [billingType, gcProject])

  // If only 1 retention type is supported, we should ensure that that retention
  // type is selected and not show the advanced section (since they can't change it)
  useEffect(() => {
    if (retentionTypes.length === 1 && retentionInfo.retentionTrackingLevel !== retentionTypes[0]) {
      onRetentionInfoChange({
        ...retentionInfo,
        retentionTrackingLevel: retentionTypes[0],
      })
    }
  }, [onRetentionInfoChange, retentionInfo, retentionTypes])

  const shouldShowAdvanced = retentionTypes.length > 1

  return (
    <ProjectOnboardingFormCard
      title={
        <div className={classes.title}>
          <SitelineText variant="h3" bold>
            {t('projects.onboarding.sections.RETENTION')}
          </SitelineText>
          <Tooltip
            title={
              <SitelineText variant="smallText" endIcon={<LaunchIcon style={{ fontSize: 14 }} />}>
                {t(`${i18nBase}.help_center`)}
              </SitelineText>
            }
            placement="top-start"
          >
            <IconButton
              color="secondary"
              onClick={() => {
                window.open(RETENTION_HELP_CENTER_ARTICLE, '_blank')
              }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
    >
      <OnboardingFormRow
        label={t(`${i18nBase}.contract_retention`)}
        info={t(`${i18nBase}.contract_retention_info`)}
        className={classes.contractRetention}
      >
        <RetentionPercentInput
          percent={
            retentionInfo.defaultRetentionPercent !== null
              ? retentionInfo.defaultRetentionPercent
              : undefined
          }
          onPercentChange={(percent) =>
            onRetentionInfoChange({
              ...retentionInfo,
              defaultRetentionPercent: percent,
            })
          }
          maxAllowedPercent={1}
        />
      </OnboardingFormRow>
      <OnboardingFormRow label={t(`${i18nBase}.round_retention`)} className={classes.roundingRow}>
        <Switch
          checked={retentionInfo.roundRetention}
          onChange={(event) =>
            onRetentionInfoChange({ ...retentionInfo, roundRetention: event.target.checked })
          }
          className={classes.switch}
        />
      </OnboardingFormRow>
      {shouldShowAdvanced && (
        <>
          <Divider className={classes.divider} />
          <Button
            variant="text"
            className={classes.advancedSettings}
            onClick={() => setShowAdvanced((showingAdvanced) => !showingAdvanced)}
          >
            <SitelineText
              variant="button"
              color="grey50"
              endIcon={
                <ExpandMoreIcon
                  fontSize="small"
                  className={clsx(classes.expandIcon, { expanded: showAdvanced })}
                />
              }
            >
              {t(`${i18nBase}.advanced_settings`)}
            </SitelineText>
          </Button>
          <Collapse in={showAdvanced}>
            <OnboardingFormRow
              label={t(`${i18nBase}.retention_level`)}
              className={classes.radioGroup}
            >
              <RadioGroup
                name="retention-types"
                value={retentionInfo.retentionTrackingLevel}
                onChange={(evt) =>
                  onRetentionInfoChange({
                    ...retentionInfo,
                    retentionTrackingLevel: evt.target.value as RetentionTrackingLevel,
                  })
                }
              >
                {retentionTypes.map((retentionType) => (
                  <div key={retentionType}>
                    <FormControlLabel
                      value={retentionType}
                      control={<SitelineRadio color="primary" size="small" />}
                      label={t(`${i18nBase}.retention_names.${retentionType}`)}
                    />
                    <SitelineText
                      variant="secondary"
                      color="grey50"
                      className="retentionDescription"
                    >
                      {t(`${i18nBase}.retention_descriptions.${retentionType}`)}
                    </SitelineText>
                  </div>
                ))}
              </RadioGroup>
            </OnboardingFormRow>
          </Collapse>
        </>
      )}
    </ProjectOnboardingFormCard>
  )
}
