import { NavigateOptions } from '@tanstack/react-router'
import { Permission } from 'siteline-common-web'
import { CompanySettingsSection } from './CompanySettings'

export enum CompanySettingsTab {
  COMPANY = 'company',
  USERS = 'users',
  OFFICES = 'offices',
  TAX_GROUPS = 'taxGroups',
}

export const settingsNavTabs = [
  CompanySettingsTab.COMPANY,
  CompanySettingsTab.USERS,
  CompanySettingsTab.OFFICES,
  CompanySettingsTab.TAX_GROUPS,
]

type SettingsPathParams =
  | {
      tab: Exclude<CompanySettingsTab, CompanySettingsTab.COMPANY | CompanySettingsTab.USERS>
    }
  | { tab: CompanySettingsTab.COMPANY; section?: CompanySettingsSection }
  | { tab: CompanySettingsTab.USERS; permissions: Permission[] }

/** Returns a path to the corresponding route within company settings */
export function getCompanySettingsPath(props: SettingsPathParams): NavigateOptions {
  switch (props.tab) {
    case CompanySettingsTab.OFFICES:
      return { to: '/settings/company/offices' }
    case CompanySettingsTab.COMPANY:
      return { to: '/settings/company', search: { section: props.section } }
    case CompanySettingsTab.USERS:
      if (props.permissions.includes(Permission.EDIT_COMPANY_USERS)) {
        return { to: '/settings/company/users' }
      }
      return { to: '/settings/company' }
    case CompanySettingsTab.TAX_GROUPS:
      return { to: '/settings/company/taxGroups' }
  }
}
