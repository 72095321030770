import { TFunction } from 'i18next'
import { SpreadsheetColumn, SpreadsheetDataType } from '../components/Spreadsheet/Spreadsheet.lib'
import { BaseManageSovColumn, GetSovColumnsForViewOrEditSovProps } from './ManageSov'
import {
  makeChangeOrderApprovalColumn,
  makeChangeOrderColumn,
  makeDefaultRetentionPercentColumn,
  makePreSitelineBillingColumn,
  makePreSitelineRetentionAmountColumn,
  makeStoredMaterialsColumn,
  makeTaxGroupColumn,
  makeTotalRetainageColumn,
} from './ManageSovColumn'

export enum ManageLumpSumSovColumn {
  SCHEDULED_VALUE = 'scheduledValue',
  PRE_SITELINE_BILLING = 'preSitelineBilling',
  BILLED_TO_DATE = 'billedToDate',
  BALANCE_TO_FINISH = 'balanceToFinish',
}

/** Columns that may be shown when viewing or editing an SOV */
export function getLumpSumSovColumns(
  t: TFunction,
  {
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeTotalRetainageColumn,
    includeTotalBillingColumns,
    includeChangeOrderColumn,
    includeChangeOrderApprovalColumn,
    taxGroups,
    onAddTaxGroupForLineItemId,
    isEditable,
  }: GetSovColumnsForViewOrEditSovProps
): SpreadsheetColumn[] {
  const i18nBase = 'projects.subcontractors.sov'
  const columns: SpreadsheetColumn[] = [
    {
      id: BaseManageSovColumn.CODE,
      heading: t(`${i18nBase}.headers.number`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      maxWidth: 200,
    },
    {
      id: BaseManageSovColumn.NAME,
      heading: t(`${i18nBase}.headers.name`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 200,
      minWidth: 250,
    },
    {
      id: BaseManageSovColumn.COST_CODE,
      heading: t(`${i18nBase}.headers.cost_code`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      skeletonWidth: 100,
      minWidth: 100,
    },
    {
      id: ManageLumpSumSovColumn.SCHEDULED_VALUE,
      heading: t(`${i18nBase}.headers.scheduled_value`),
      isEditable,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
  ]
  if (includePreSitelineBillingColumn) {
    columns.push(makePreSitelineBillingColumn({ t, isEditable }))
  }
  if (includeMaterialsInStorageColumn) {
    columns.push(makeStoredMaterialsColumn({ isEditable, t }))
  }
  if (includeRetentionPercentColumn) {
    columns.push(makeDefaultRetentionPercentColumn({ t, isEditable }))
  }
  if (includePreSitelineRetentionColumns) {
    columns.push(makePreSitelineRetentionAmountColumn({ t, isEditable }))
  }
  if (taxGroups !== null) {
    columns.push(makeTaxGroupColumn({ t, isEditable, taxGroups, onAddTaxGroupForLineItemId }))
  }
  if (includeChangeOrderColumn) {
    columns.push(makeChangeOrderColumn({ t, isEditable }))
  }
  if (includeChangeOrderApprovalColumn) {
    columns.push(makeChangeOrderApprovalColumn({ t, isEditable }))
  }
  if (includeTotalBillingColumns) {
    columns.push(
      {
        id: ManageLumpSumSovColumn.BILLED_TO_DATE,
        heading: t(`${i18nBase}.headers.billed_to_date`),
        isEditable: false,
        dataType: SpreadsheetDataType.DOLLAR as const,
        align: 'right' as const,
      },
      {
        id: ManageLumpSumSovColumn.BALANCE_TO_FINISH,
        heading: t(`${i18nBase}.headers.balance_to_finish`),
        isEditable: false,
        dataType: SpreadsheetDataType.DOLLAR as const,
        align: 'right' as const,
      }
    )
  }
  if (includeTotalRetainageColumn) {
    columns.push(makeTotalRetainageColumn({ t }))
  }

  return columns
}
