import { flushSync } from 'react-dom'

/**
 * Executes a callback function immediately and synchronously to ensure
 * that the state changes take effect and appear in the DOM right away.
 * This is useful in scenarios where immediate updates are crucial, such as
 * preventing rendering delays or handling user inputs on components with complex
 * state. However, it is important to use this sparingly to avoid potential
 * performance issues or conflicts during React's rendering cycle.
 */
export function launchImmediateStateUpdate(callback: () => void) {
  // Use a microtask to ensure the callback is executed after the current render cycle.
  // This avoids restrictions and warnings from React RE calling flushSync from within
  // lifecycle methods.
  queueMicrotask(() => {
    flushSync(callback)
  })
}
