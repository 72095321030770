import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Theme } from '@mui/material/styles'
import { Trans, useTranslation } from 'react-i18next'
import { SitelineText, SitelineTooltip, colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  noLeadPM: {
    marginTop: theme.spacing(2),
    '& .helpIcon': {
      color: colors.grey50,
      fontSize: 16,
    },
    '& .chooseLeadPM': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: theme.spacing(0.5),
        fontSize: 20,
      },
    },
  },
  tooltip: {
    whiteSpace: 'pre-wrap',
  },
}))

const i18nBase = 'projects.onboarding.checklist'

/** Collapsible message explaining that a contract has no lead PM, and what a lead PM is for */
export function NoLeadPmInfo() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.noLeadPM}>
      <SitelineText
        variant="h4"
        endIcon={
          <SitelineTooltip
            title={t(`${i18nBase}.lead_pm_tooltip`)}
            classes={{ tooltip: classes.tooltip }}
            placement="right"
          >
            <HelpOutlineIcon className="helpIcon" />
          </SitelineTooltip>
        }
      >
        {t(`${i18nBase}.no_lead_pm`)}
      </SitelineText>
      <SitelineText variant="body1" color="grey50" className="chooseLeadPM">
        <Trans
          i18nKey={`${i18nBase}.to_choose_lead_pm`}
          components={{ starIcon: <StarBorderIcon /> }}
        />
      </SitelineText>
    </div>
  )
}
