import { TextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { useTranslation } from 'react-i18next'
import { colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      ...theme.typography.h6,
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0.5),
      color: colors.red70,
    },
  },
}))

const i18nBase = 'projects.onboarding'

/** A text field in the project onboarding form */
export function OnboardingFormTextField(
  props: Pick<TextFieldProps, 'value' | 'onChange' | 'error' | 'autoFocus' | 'inputProps'>
) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TextField
      variant="outlined"
      className={clsx(classes.root, { missingField: props.error })}
      helperText={props.error ? t(`${i18nBase}.required_field`) : undefined}
      {...props}
    />
  )
}
