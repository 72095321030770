import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import addrs from 'email-addresses'
import _ from 'lodash'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, makeStylesFast, SitelineText } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { AutocompleteOrAdd } from '../../../common/components/AutocompleteOrAddNew'
import { SuggestEmailBanner } from '../../../common/components/SuggestEmailBanner'
import { useBrowserAutocompleteDisabled } from '../../../common/util/Forms'
import { formatPhoneNumber } from '../../../common/util/PhoneNumber'
import { SendEmailDialogRow } from './SendEmailDialogRow'
import { SendEmailDialogTextFieldRow } from './SendEmailDialogTextFieldRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(-2),
    '& .emailContainer': {
      '& .label': {
        paddingTop: theme.spacing(2),
      },
      '& .emailInput': {
        // Leave room for the invalid email message
        marginTop: theme.spacing(2),
      },
    },
    '& .fullNameRow': {
      '&:not(.shouldPadTop)': {
        marginTop: 0,
      },
    },
    '& .autocomplete': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    '& .messageContainer': {
      display: 'flex',
      justifyContent: 'flex-end',
      height: 0,
      '& .invalidEmailMessage': {
        position: 'absolute',
        display: 'none',
        '&.isShown': {
          display: 'block',
        },
      },
      '& .invalidPhoneNumberMessage': {
        position: 'absolute',
        display: 'none',
        marginTop: theme.spacing(-2),
        '&.isShown': {
          display: 'block',
        },
      },
    },
    '& .invalidInput': {
      '& fieldset': {
        // Override default border style to show red when there's an error
        borderColor: `${colors.red30} !important`,
      },
    },
    '& .companyName': {
      marginLeft: theme.spacing(1),
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

export type BaseContact = {
  id: string
  email: string
  fullName: string
  companyName: string
  phoneNumber: string | null
  jobTitle: string | null
}

// Add union with generic object type so typecheck works with the autocomplete component
export type Contact = BaseContact & { [key: string]: string | null }

interface EmailSuggestionProps {
  /**
   * If a string is provided, a banner will be displayed underneath the email field, suggesting
   * that the user may have a typo in the email field & suggesting an update.
   * If null, the banner will not be displayed.
   */
  suggestedEmail: string | null
  /**
   * Callback triggered when the user clicks out of the email field. This callback should handle
   * searching for a matching email. If there's a match, suggestedEmail will be updated and
   * this component will open a banner with the email suggestion.
   **/
  onSuggestEmail: () => void
  /** Callback updates `contact` with the suggested email */
  onAcceptEmailSuggestion: () => void
  /** Callback should set `suggestedEmail` to null, which will cause the banner to collapse */
  onClearEmailSuggestion: () => void
}

interface NewContactFormProps<T extends Contact> {
  contact: T
  onContactChange: (contact: T) => void
  /* All contacts that exist for the current user's company */
  companyContacts: T[]
  /* A list of company names to be used. If not provided, will pull from company contacts above */
  companyNames?: string[]
  allowNewCompanies: boolean

  /** If true, include a field for optional fields (phone number and job title)*/
  showContactOptionalFields?: boolean

  showInvalidEmailWarning?: boolean
  showInvalidPhoneNumberWarning?: boolean

  /** Whether to allow editing the vendor. True by default. */
  enableEditingVendor?: boolean

  /** Props that handle displaying a banner underneath the email field, used to detect typos */
  emailSuggestionProps?: EmailSuggestionProps
}

const i18nBase = 'projects.subcontractors.pay_app.submit.new_contact'

/** A form in the send email dialog for adding a new contact */
export function NewContactForm<T extends Contact>({
  contact,
  onContactChange,
  companyContacts,
  companyNames,
  allowNewCompanies,
  showContactOptionalFields,
  showInvalidEmailWarning,
  showInvalidPhoneNumberWarning,
  enableEditingVendor = true,
  emailSuggestionProps,
}: NewContactFormProps<T>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const formRef = useRef<HTMLDivElement>(null)
  useBrowserAutocompleteDisabled(formRef)

  const contactsByEmail = _.zipObject(
    companyContacts.map((contact) => contact.email),
    companyContacts
  )
  const vendorNames = companyNames ?? _.uniq(companyContacts.map((contact) => contact.companyName))

  const handleValueChange = (type: string, value: string | null) => {
    if (type === 'email' && emailSuggestionProps !== undefined) {
      emailSuggestionProps.onClearEmailSuggestion()
    }
    if (!value) {
      onContactChange({ ...contact, [type]: '' })
      // If the autocomplete is cleared, name becomes null
      return
    }
    // Attempt to parse the input value in RFC 5322 format, in case it was copied in
    const emailParts = addrs.parseOneAddress(value)
    if (type === 'email' && value in contactsByEmail) {
      const existingContact = contactsByEmail[value]
      onContactChange({
        ...existingContact,
        phoneNumber: existingContact.phoneNumber
          ? formatPhoneNumber(existingContact.phoneNumber)
          : null,
      })
      return
    }
    // If the email doesn't match an existing contact but the contact ID in state still matches
    // a previous contact, clear it and use a new ID so we don't accidentally use the other contact
    let contactId = contact.id
    const isNewContact = !(contact.email in contactsByEmail)
    const idMatchesExistingContact = companyContacts.some((contact) => contact.id === contactId)
    if (isNewContact && idMatchesExistingContact) {
      contactId = uuidv4()
    }
    if (type === 'email' && emailParts && emailParts.type === 'mailbox') {
      // Use the name from the parser if one isn't already inputted
      const name = contact.fullName || emailParts.name || ''
      const email = emailParts.address
      onContactChange({ ...contact, id: contactId, fullName: name, email })
    } else {
      onContactChange({ ...contact, id: contactId, [type]: value })
    }
  }

  return (
    <div ref={formRef} className={classes.root}>
      <div className="messageContainer">
        <SitelineText
          variant="smallText"
          color="red70"
          className={clsx('invalidEmailMessage', { isShown: showInvalidEmailWarning })}
        >
          {t(`${i18nBase}.invalid_email`)}
        </SitelineText>
      </div>
      <div>
        <SendEmailDialogRow
          label={t(`${i18nBase}.new_contact_headers.email`)}
          alignLabel="center"
          width="medium"
          className="emailContainer"
        >
          <div
            className={clsx('autocomplete', 'emailInput', {
              invalidInput: showInvalidEmailWarning,
            })}
            onBlur={emailSuggestionProps ? emailSuggestionProps.onSuggestEmail : undefined}
          >
            <AutocompleteOrAdd
              options={Object.keys(contactsByEmail)}
              type="email"
              values={contact}
              freeSolo
              handleInputChange={handleValueChange}
              autoFocus
            />
          </div>
        </SendEmailDialogRow>
        {emailSuggestionProps && (
          <SuggestEmailBanner
            suggestedEmail={emailSuggestionProps.suggestedEmail}
            onYes={emailSuggestionProps.onAcceptEmailSuggestion}
            onNo={emailSuggestionProps.onClearEmailSuggestion}
          />
        )}
        <SendEmailDialogTextFieldRow
          label={t(`${i18nBase}.new_contact_headers.full_name`)}
          alignLabel="center"
          type="fullName"
          value={contact.fullName}
          onChange={handleValueChange}
          width="medium"
          className={clsx('fullNameRow', {
            shouldPadTop: !emailSuggestionProps || emailSuggestionProps.suggestedEmail !== null,
          })}
        />
        <SendEmailDialogRow
          label={t(`${i18nBase}.new_contact_headers.company`)}
          alignLabel="center"
          width="medium"
        >
          {enableEditingVendor && (
            <div className="autocomplete">
              <AutocompleteOrAdd
                options={vendorNames}
                type="companyName"
                values={contact}
                freeSolo={allowNewCompanies}
                handleInputChange={handleValueChange}
              />
            </div>
          )}
          {!enableEditingVendor && (
            <SitelineText variant="body1" className="companyName">
              {contact.companyName}
            </SitelineText>
          )}
        </SendEmailDialogRow>
        {showContactOptionalFields && (
          <>
            <SendEmailDialogTextFieldRow
              label={t(`${i18nBase}.new_contact_headers.job_title`)}
              alignLabel="center"
              type="jobTitle"
              value={contact.jobTitle ?? ''}
              onChange={handleValueChange}
              width="medium"
            />
            <SendEmailDialogTextFieldRow
              label={t(`${i18nBase}.new_contact_headers.phone_number`)}
              alignLabel="center"
              type="phoneNumber"
              value={contact.phoneNumber ?? ''}
              onChange={handleValueChange}
              width="medium"
              className={clsx({ invalidInput: showInvalidPhoneNumberWarning })}
            />
            <div className="messageContainer">
              <SitelineText
                variant="smallText"
                color="red70"
                className={clsx('invalidPhoneNumberMessage', {
                  isShown: showInvalidPhoneNumberWarning,
                })}
              >
                {t(`${i18nBase}.invalid_phone_number`)}
              </SitelineText>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
