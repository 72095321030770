import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { SitelineText, SitelineTooltip, makeStylesFast } from 'siteline-common-web'
import { alignToJustifyContent } from './SitelineTable.lib'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    '& .startIconAndButton': {
      '& .startIcon': {
        marginRight: theme.spacing(1),
      },
      display: 'flex',
      alignItems: 'center',
    },
    '& .headerButton': {
      // Use opposite of button padding to keep header label aligned with column
      margin: '-4px -5px',
      minWidth: 0,
      height: 24,
      '& .arrowIcon': {
        marginLeft: theme.spacing(0.5),
        fontSize: theme.typography.body1.fontSize,
        transition: theme.transitions.create(['transform', 'opacity']),
        opacity: 0,
        '&.flipped': {
          transform: 'scaleY(-1)',
        },
        '&.sorting': {
          opacity: 1,
        },
      },
      '&:hover .arrowIcon': {
        opacity: 1,
      },
      '&.alignRight': {
        '& .arrowIcon': {
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(0.5),
        },
      },
    },
  },
}))

export interface SortType<T> {
  column: T
  order: 'asc' | 'desc'
}

export interface SortableColumnHeaderProps<T> {
  title: ReactNode
  tooltipLabel: string
  sortColumn: T
  sortBy: SortType<T>
  onSortByChange: (sortBy: SortType<T>) => void
  hasHorizontalPadding?: boolean
  endIcon?: ReactNode
  startIcon?: ReactNode
  className?: string
  align?: 'left' | 'center' | 'right'
}

export function flipSortOrder(order: 'asc' | 'desc') {
  return order === 'asc' ? 'desc' : 'asc'
}

/** A column header that changes sort order when clicked */
export function SortableColumnHeader<T>({
  title,
  tooltipLabel,
  hasHorizontalPadding,
  sortColumn,
  sortBy,
  onSortByChange,
  endIcon,
  startIcon,
  className,
  align,
}: SortableColumnHeaderProps<T>) {
  const classes = useStyles()
  const isSortColumn = sortBy.column === sortColumn

  const button = (
    <Button
      variant="text"
      size="small"
      onClick={() =>
        onSortByChange({
          column: sortColumn,
          order: sortBy.column === sortColumn ? flipSortOrder(sortBy.order) : 'asc',
        })
      }
      className={clsx('headerButton', { alignRight: align === 'right' })}
    >
      <SitelineText
        variant="label"
        color={sortBy.column === sortColumn ? 'grey90' : 'grey50'}
        {...{
          [align === 'right' ? 'startIcon' : 'endIcon']: (
            <ArrowDownwardIcon
              className={clsx('arrowIcon', {
                sorting: isSortColumn,
                flipped: sortBy.order === 'desc',
              })}
            />
          ),
        }}
      >
        {title}
      </SitelineText>
    </Button>
  )
  const wrappedButton = isSortColumn ? (
    <SitelineTooltip title={tooltipLabel} placement="top-start" disableInteractive>
      {button}
    </SitelineTooltip>
  ) : (
    button
  )

  return (
    <div
      className={className}
      style={{
        justifyContent: alignToJustifyContent(align),
        ...(!hasHorizontalPadding && {
          paddingLeft: 0,
          paddingRight: 0,
        }),
      }}
    >
      <div className={classes.root}>
        <div className="startIconAndButton">
          {startIcon && <div className="startIcon">{startIcon}</div>}
          {wrappedButton}
        </div>
        {endIcon}
      </div>
    </div>
  )
}
