export const firebaseConfig = {
  apiKey: 'AIzaSyCBZdCeJ2dBz96gcR8LZ-0CQkRLXXHVQw0',
  authDomain: 'siteline-prod.firebaseapp.com',
  databaseURL: 'https://siteline-prod.firebaseio.com',
  projectId: 'siteline-prod',
  storageBucket: 'siteline-prod.appspot.com',
  messagingSenderId: '946320597438',
  appId: '1:946320597438:web:54b5c7e565b123917699ef',
  measurementId: 'G-7MPRR0Q4KR',
}

export const googleMapsApiKey = 'AIzaSyCBZdCeJ2dBz96gcR8LZ-0CQkRLXXHVQw0'
export const apiBaseUrl = 'api.siteline.com'
export const attachmentsBaseUrl = 'https://attachments.siteline.com'
export const pdfBaseUrl = 'https://pdf.siteline.com'
export const reportingBaseUrl = 'https://reporting.siteline.com'
export const subscriptionsBaseUrl = 'subscriptions.siteline.com'
export const segmentKey = 'K0yIRFFghSDtE3rDoaisunKgfI5DTv4H'
export const segmentCdnUrl = 'https://7ae8df4.siteline.com'
export const segmentApiHost = '93f86ed.siteline.com/v1'
export const logRocketApiUrl = 'https://4c05439.siteline.com/i'
export const sentryTunnel = 'https://759ed53.siteline.com/api/5374497/envelope/'
