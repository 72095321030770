import { createRootRouteWithContext, Outlet, useLocation } from '@tanstack/react-router'
import { useEffect } from 'react'
import 'reset-css'
import { TrackPageViews } from '../Analytics'

export type RouterContext = {
  isAuthenticated: boolean
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Root,
})

function Root() {
  // Always scroll to position 0,0 when a transition occurs. We use `location.pathname` instead of
  // simply `location` because we only want to scroll to the top when the actual base URL changes.
  // On certain screens the query parameters may change as you interact with the page, and we don't
  // want to scroll away in those cases.
  const pathname = useLocation({ select: (location) => location.pathname })
  useEffect(() => window.scrollTo(0, 0), [pathname])

  return (
    <>
      <TrackPageViews />
      <Outlet />
    </>
  )
}
