import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { BillingWorksheetSidesheet } from '../../components/billing/BillingWorksheetSidesheet'
import { useCompanyContext } from './CompanyContext'

interface BillingWorksheetContextData {
  onCloseSidebar: () => void
  onViewSovLineItemWorksheet: (sovLineItemId: string) => void
  viewingSovLineItemId: string | null
}

const BillingWorksheetContext = createContext<BillingWorksheetContextData>({
  onCloseSidebar: () => {
    // No-op
  },
  onViewSovLineItemWorksheet: () => {
    // No-op
  },
  viewingSovLineItemId: null,
})

export function BillingWorksheetProvider({ children }: { children: ReactNode }) {
  const [sovLineItemId, setSovLineItemId] = useState<string | null>(null)
  const { enableBillingWorksheets } = useCompanyContext()

  const handleCloseSidebar = useCallback(() => {
    setSovLineItemId(null)
  }, [])

  const contextValue: BillingWorksheetContextData = useMemo(
    () => ({
      onCloseSidebar: handleCloseSidebar,
      onViewSovLineItemWorksheet: (sovLineItemId: string) => {
        // Don't allow an sov line item id to be set if billing worksheets is not enabled on this company
        if (!enableBillingWorksheets) {
          console.error('Billing worksheets are not enabled for this company')
        } else {
          setSovLineItemId(sovLineItemId)
        }
      },
      viewingSovLineItemId: sovLineItemId,
    }),
    [enableBillingWorksheets, handleCloseSidebar, sovLineItemId]
  )

  return (
    <BillingWorksheetContext.Provider value={contextValue}>
      {children}
      {enableBillingWorksheets && <BillingWorksheetSidesheet />}
    </BillingWorksheetContext.Provider>
  )
}

export function useBillingWorksheetContext() {
  const context = useContext(BillingWorksheetContext)
  const handleCloseSidebar = useMemo(() => context.onCloseSidebar, [context.onCloseSidebar])

  // Close the sidebar when the component unmounts that uses this context
  useEffect(() => handleCloseSidebar, [handleCloseSidebar])

  return context
}
