import { useTranslation } from 'react-i18next'
import { ContractForVendorsCopyDialog } from '../../components/vendors/add-vendors/CopyProjectVendorsDialog'
import { ContractStatus } from '../graphql/apollo-operations'
import { ComplianceContract } from '../util/LegalRequirement'
import { ContractForForms } from '../util/ProjectOnboarding'
import { GreyChip } from './SitelineChip'

interface ArchivedChipProps {
  contract: ComplianceContract | ContractForForms | ContractForVendorsCopyDialog | undefined
}

/**
 * Status chip displayed on dropdown menus of projects to copy from. Indicates that the given
 * project is archived. Primarily displayed on the CopyProjectFormsDialog component. Handles
 * checking the contract status and rendering conditionally.
 **/
export function ArchivedChip({ contract }: ArchivedChipProps) {
  const { t } = useTranslation()
  if (contract?.status !== ContractStatus.ARCHIVED) {
    return null
  }

  return <GreyChip size="small" label={t('projects.subcontractors.settings.forms.archived')} />
}
