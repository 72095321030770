import _ from 'lodash'
import { fuseSearch } from 'siteline-common-web'

/**
 * Used to detect typos in email domain, based on the provided domain and a list of existing
 * domains (use case is existing company email domains that we have stored). If the email domain
 * is an exact match within the list of domains or a match cannot be found, will return null.
 */
export function findCloselyMatchingEmail({
  email,
  validDomains,
}: {
  email: string
  validDomains: string[]
}): string | null {
  const splitEmail = email.split('@')
  const doesEmailHaveDomain = splitEmail.length === 2

  // Email validation should be handled separately
  if (!doesEmailHaveDomain) {
    return null
  }

  const [prefix, domain] = splitEmail
  const matchingDomains = fuseSearch(validDomains, domain, undefined, {
    distance: 10,
    minMatchCharLength: 4,
  })
  const isDomainValid = validDomains.includes(domain)
  const hasMatchingDomain = matchingDomains.length > 0

  // If the domain is valid, no need to find a match.
  // If there is no match, no point in continuing.
  if (isDomainValid || !hasMatchingDomain || !domain) {
    return null
  }

  // Sort matching results by frequency
  const frequencyMap: Record<string, number> = {}
  validDomains.forEach((domain) => {
    frequencyMap[domain] = frequencyMap[domain] ? frequencyMap[domain] + 1 : 1
  })
  const sortedMatches = matchingDomains.sort((a, b) => frequencyMap[b] - frequencyMap[a])
  const matchingDomain = sortedMatches[0]
  return [prefix, matchingDomain].join('@')
}

/**
 * Pulls domains from a list of email addresses
 * @param emails an array of email addresses, ex: ['maureen@siteline.com', 'oliver@siteline.com']
 * @returns an array of domains, ex: ['siteline.com', 'siteline.com']
 */
export function getEmailDomainsFromContacts(emails: string[]) {
  return _.chain(emails)
    .map((email) => email.split('@'))
    .filter((splitEmail) => splitEmail.length === 2 && splitEmail[1] !== '')
    .map((splitEmail) => splitEmail[1])
    .value()
}
