import { Card, CardContent } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import backgroundDefault from '../../assets/images/backgroundDefault.jpeg'
import linePattern from '../../assets/images/line-pattern.svg'
import inviteSitelineLogo from '../../assets/images/logo/white-text.svg'

const useStyles = makeStylesFast((theme: Theme) => ({
  layoutContainer: {
    minHeight: '100vh',
    display: 'flex',
    fontFamily: 'InterVariable',
  },
  image: {
    backgroundImage: `url(${backgroundDefault})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center right',
    width: '40%',
    [theme.breakpoints.down('lg')]: {
      width: '20%',
    },
    [theme.breakpoints.down('md')]: {
      width: 0,
    },
  },
  logo: {
    backgroundImage: `url(${inviteSitelineLogo})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: 310,
    height: 64,
    marginBottom: theme.spacing(4),
  },
  layoutContent: {
    flexGrow: 1,
    overflowX: 'hidden',
    paddingTop: theme.spacing(8),
    backgroundColor: colors.brandNavy,
    backgroundImage: `url(${linePattern})`,
    backgroundPosition: 'top left',
    backgroundSize: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90vw',
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
    '&:last-child': {
      padding: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },
    },
  },
  signinContainer: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    width: '100%',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    marginTop: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 0),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  passwordText: {
    margin: theme.spacing(-1),
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
  },
}))

/** Base screen for auth screens, including sign-in and sign-up */
export function AuthScreen({ children }: { children: ReactNode }) {
  const classes = useStyles()

  return (
    <div className={classes.layoutContainer}>
      <div className={classes.image} />
      <div className={classes.layoutContent}>
        <div className={classes.root}>
          <div className={classes.logo} />
          <Card className={classes.card}>
            <CardContent className={classes.content}>{children}</CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
