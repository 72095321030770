import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import { HEADER_HEIGHT, TOP_HEADER_HEIGHT, Z_INDEX } from '../themes/Main'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: colors.grey30,
    height: HEADER_HEIGHT,
    padding: `${theme.spacing(0, 4)} !important`,
    position: 'fixed',
    top: TOP_HEADER_HEIGHT,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: Z_INDEX.sitelineHeader,
    '& .grow': {
      flexGrow: 1,
    },
    '& .flexEnd': {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  hiddenOnPrint: {
    [`@media print`]: {
      // Override the default value to hide the header entirely when printing
      // (otherwise it may be layered over the content that's being printed)
      display: 'none !important',
    },
  },
}))

interface SitelineHeaderProps extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string
  borderBottomColor?: string
  spacerHeight?: number
}

/** A subheader under the main navigation that is always sticky. */
export function SitelineHeader({
  backgroundColor = colors.grey10,
  borderBottomColor = colors.grey30,
  spacerHeight = TOP_HEADER_HEIGHT + HEADER_HEIGHT,
  ...props
}: PropsWithChildren<SitelineHeaderProps>) {
  const classes = useStyles()
  return (
    <>
      <div
        {...props}
        className={clsx(classes.root, classes.hiddenOnPrint, props.className)}
        style={{ backgroundColor, borderBottomColor }}
      >
        {props.children}
      </div>
      <div className={classes.hiddenOnPrint} style={{ height: spacerHeight }} />
    </>
  )
}
