import { clsx } from 'clsx'
import _ from 'lodash'
import { memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { SpreadsheetRow as Row } from './Spreadsheet.lib'
import SpreadsheetRow, { SpreadsheetRowProps } from './SpreadsheetRow'

interface DraggableSpreadsheetRowProps extends SpreadsheetRowProps {
  draggingRowId: string | null
  draggingGroupRows: Row[]
  draggingAboveIndex: number
}

/**
 * Custom comparison for whether to re-render the component. Uses standard comparison
 * for all props except the row, which uses a deeper comparison to ensure we only
 * re-render the row if the value or content of a cell has changed.
 */
function arePropsEqual(
  prevProps: DraggableSpreadsheetRowProps,
  props: DraggableSpreadsheetRowProps
) {
  // Intentionally ignore `onBeforeSave` in the comparison, as it will cause a re-render for every
  // row every time the pay app changes. We can safely exclude it because it will only change if
  // the progress for the row also changes, which will trigger a re-render for the individual row.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { row: prevRow, onBeforeSave: prevOnBeforeSave, ...prevRest } = prevProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { row, onBeforeSave, ...rest } = props
  if (!_.isEqual(prevRest, rest)) {
    return false
  }

  if (
    prevRow.id !== row.id ||
    prevRow.isGroupHeaderRow !== row.isGroupHeaderRow ||
    prevRow.isNonEditableRow !== row.isNonEditableRow ||
    prevRow.isFirstInUngroupedBlock !== row.isFirstInUngroupedBlock ||
    prevRow.allowDragging !== row.allowDragging ||
    prevRow.isDragDisabled !== row.isDragDisabled ||
    prevRow.backgroundColor !== row.backgroundColor ||
    prevRow.cells.length !== row.cells.length
  ) {
    return false
  }

  return prevRow.cells.every((prevCell, index) => {
    const cell = row.cells[index]
    if (
      prevCell.type !== cell.type ||
      prevCell.colOffset !== cell.colOffset ||
      prevCell.colSpan !== cell.colSpan ||
      prevCell.borderColor !== cell.borderColor ||
      prevCell.backgroundColor !== cell.backgroundColor ||
      prevCell.tooltipTitle !== cell.tooltipTitle
    ) {
      return false
    }
    if (prevCell.type === 'CONTENT' && cell.type === 'CONTENT') {
      return prevCell.dependencies.every((dependency, index) =>
        _.isEqual(dependency, cell.dependencies[index])
      )
    }
    if (prevCell.type === 'DATA' && cell.type === 'DATA') {
      if (
        prevCell.value !== cell.value ||
        prevCell.bold !== cell.bold ||
        prevCell.strikethrough !== cell.strikethrough ||
        prevCell.italic !== cell.italic
      ) {
        return false
      }
      if (prevCell.leftContent && cell.leftContent) {
        const areDependenciesEqual = prevCell.leftContent.dependencies.every((dependency, index) =>
          _.isEqual(dependency, cell.leftContent?.dependencies[index])
        )
        const areAlignmentsEqual = prevCell.leftContent.align === cell.leftContent.align
        return areDependenciesEqual && areAlignmentsEqual
      } else if (prevCell.leftContent || cell.leftContent) {
        return false
      }
      if (prevCell.rightContent && cell.rightContent) {
        return prevCell.rightContent.dependencies.every((dependency, index) =>
          _.isEqual(dependency, cell.rightContent?.dependencies[index])
        )
      } else if (prevCell.rightContent || cell.rightContent) {
        return false
      }
    }
    return true
  })
}

/** Wrapper around SpreadsheetRow for making it draggable within a drag/drop context */
export const DraggableSpreadsheetRow = memo(function DraggableSpreadsheetRow({
  draggingRowId,
  draggingGroupRows,
  draggingAboveIndex,
  row,
  rowIndex,
  includeDragHandles,
  disableReordering,
  columns,
  dispatch,
  columnSizing,
  onCellWidthChange,
  ...props
}: DraggableSpreadsheetRowProps) {
  const isInDraggingGroup = draggingGroupRows.some((draggingRow) => draggingRow.id === row.id)
  const isDragDisabled =
    !includeDragHandles ||
    isInDraggingGroup ||
    disableReordering ||
    row.isDragDisabled !== undefined

  return (
    <Draggable key={row.id} draggableId={row.id} index={rowIndex} isDragDisabled={isDragDisabled}>
      {(draggableProvided, snapshot) => {
        // A row with no cells should just be rendered as a divider, but is included within
        // Draggable to enable dropping rows before or after dividers
        if (row.cells.length === 0) {
          return (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              className={clsx('row', 'groupDivider')}
            >
              <div {...draggableProvided.dragHandleProps} />
            </div>
          )
        }

        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            className={clsx({
              isDragging: snapshot.isDragging,
              draggingToAbove: draggingAboveIndex === rowIndex,
              isDraggingGroup: isInDraggingGroup,
              isFirstInUngroupedBlock: rowIndex > 0 && row.isFirstInUngroupedBlock,
            })}
          >
            <SpreadsheetRow
              row={row}
              rowIndex={rowIndex}
              dragHandleProps={draggableProvided.dragHandleProps}
              includeDragHandles={includeDragHandles}
              disableReordering={disableReordering}
              columns={columns}
              dispatch={dispatch}
              columnSizing={columnSizing}
              onCellWidthChange={onCellWidthChange}
              {...props}
            />
            {
              // When dragging a group header, show all the grouped rows
              // that will also be moved
              draggingRowId === row.id &&
                row.isGroupHeaderRow &&
                draggingGroupRows.map((draggingRow) => (
                  <SpreadsheetRow
                    key={`dragging-${draggingRow.id}`}
                    row={draggingRow}
                    rowIndex={rowIndex}
                    columns={columns}
                    dispatch={dispatch}
                    columnSizing={columnSizing}
                    onCellWidthChange={onCellWidthChange}
                    includeDragHandles={includeDragHandles}
                    disableReordering={disableReordering}
                  />
                ))
            }
          </div>
        )
      }}
    </Draggable>
  )
}, arePropsEqual)
