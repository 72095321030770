import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import WarningIcon from '@mui/icons-material/Warning'
import { Button, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RetentionTrackingLevel, decimalToPercent, roundCents } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { DollarNumberFormat, PercentNumberFormat } from '../../../common/components/NumberFormat'
import { YellowChip } from '../../../common/components/SitelineChip'
import { EditingSov, getContractPreSitelineRetentionAmount } from '../../../common/util/ManageSov'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import {
  usesPayAppOrProjectTracking,
  usesStandardOrLineItemTracking,
} from '../../../common/util/Retention'
import { RetentionPercentInput } from '../invoice/retention/RetentionPercentInput'
import { ContractForEditingSov } from '../onboarding/SovOnboarding'

const useStyles = makeStylesFast((theme: Theme) => ({
  retention: {
    paddingBottom: theme.spacing(2),
  },
  defaultRetentionPercent: {
    display: 'flex',
    alignItems: 'center',
  },
  contractRetention: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
  },
  retentionAmount: {
    padding: theme.spacing(0, 1),
  },
  editRetention: {
    marginLeft: theme.spacing(4),
  },
  overrideChip: {
    marginLeft: theme.spacing(1),
  },
  unlinkedContainer: {
    display: 'flex',
  },
}))

type ManageSovContractRetentionProps = {
  defaultRetentionPercent: number
  onDefaultRetentionPercentChange: (percent: number) => void
  contract: ContractForEditingSov
  sov: EditingSov
  isEditing: boolean
  onEditPreSitelineRetention: () => void
}

const i18nBase = 'projects.subcontractors.sov'

/**
 * Retention section of the SOV tab.
 * Includes the default retention percent field and the pre-siteline retention amount (with an edit button)
 */
export function ManageSovContractRetention({
  defaultRetentionPercent,
  onDefaultRetentionPercentChange,
  contract,
  sov,
  isEditing,
  onEditPreSitelineRetention,
}: ManageSovContractRetentionProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  const hasAnyPayApp = contract.payApps.length > 0
  const hasSignedPayApp = contract.payApps.some(
    (payApp) => !isPayAppDraftOrSyncFailed(payApp.status)
  )
  const wouldOverride = usesStandardOrLineItemTracking(contract.retentionTrackingLevel)
  const hasOverrideOnContract = wouldOverride && _.isNumber(sov.preSitelineRetentionHeldOverride)
  const preSitelineRetentionAmount = useMemo(
    () => getContractPreSitelineRetentionAmount(sov),
    [sov]
  )
  const preSitelineBillingAmount = useMemo(
    () => _.sumBy(sov.lineItems, (lineItem) => lineItem.preSitelineBilling ?? 0),
    [sov.lineItems]
  )

  // Show a warning if the contract-level pre-siteline retention amount does not match the
  // default retention percent. This is the equivalent of the unlinked warning that we show
  // at the line-item level, except this only applies to PROJECT/PAY_APP contracts.
  const showUnlinkedPreSitelineRetentionWarning =
    usesPayAppOrProjectTracking(contract.retentionTrackingLevel) &&
    roundCents(
      preSitelineBillingAmount * (sov.defaultRetentionPercent ?? 0),
      contract.roundRetention
    ) !== sov.preSitelineRetentionHeldOverride

  const showContractDefaultRetentionPercent =
    usesPayAppOrProjectTracking(contract.retentionTrackingLevel) && !hasAnyPayApp
  const showPreSitelineRetention =
    contract.pastPayAppCount > 0 && contract.retentionTrackingLevel !== RetentionTrackingLevel.NONE

  if (!showContractDefaultRetentionPercent && !showPreSitelineRetention) {
    return null
  }

  return (
    <div className={classes.retention}>
      {showContractDefaultRetentionPercent && (
        <div className={classes.defaultRetentionPercent}>
          <SitelineText variant="body1" color="grey50">
            {t(`${i18nBase}.adjust_pre_siteline_retention.default_retention_percent`)}
          </SitelineText>
          <SitelineText
            variant="h4"
            className={classes.retentionAmount}
            endIcon={
              <Tooltip
                title={t(
                  `${i18nBase}.adjust_pre_siteline_retention.default_retention_percent_tooltip`
                )}
                placement="top"
              >
                <InfoOutlinedIcon fontSize="small" htmlColor={colors.grey50} />
              </Tooltip>
            }
          >
            {isEditing && (
              <RetentionPercentInput
                percent={defaultRetentionPercent}
                onPercentChange={onDefaultRetentionPercentChange}
                maxAllowedPercent={1}
              />
            )}
            {!isEditing && (
              <PercentNumberFormat
                value={decimalToPercent(contract.defaultRetentionPercent ?? 0, 1)}
              />
            )}
          </SitelineText>
        </div>
      )}
      {showPreSitelineRetention && (
        <div className={classes.contractRetention}>
          <SitelineText variant="body1" color="grey50">
            {t(`${i18nBase}.adjust_pre_siteline_retention.retention_held`)}
          </SitelineText>
          <SitelineText variant="h4" className={classes.retentionAmount}>
            <DollarNumberFormat value={preSitelineRetentionAmount} />
          </SitelineText>
          {showUnlinkedPreSitelineRetentionWarning && (
            <Tooltip
              placement="top"
              title={t(`${i18nBase}.adjust_pre_siteline_retention.pre_siteline_retention_unlinked`)}
              disableInteractive
            >
              <div className={classes.unlinkedContainer}>
                <LinkOffIcon htmlColor={colors.grey50} />
              </div>
            </Tooltip>
          )}
          {hasOverrideOnContract && (
            <Tooltip
              title={t(`${i18nBase}.pre_siteline_retention_override_warning`)}
              color="warning"
            >
              <YellowChip
                icon={<WarningIcon />}
                label={t(`${i18nBase}.pre_siteline_retention_override_chip`)}
                size="small"
                className={classes.overrideChip}
              />
            </Tooltip>
          )}
          {isEditing && !hasSignedPayApp && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.editRetention}
              onClick={onEditPreSitelineRetention}
            >
              {usesStandardOrLineItemTracking(contract.retentionTrackingLevel)
                ? t(`${i18nBase}.adjust_pre_siteline_retention.override_retention`)
                : t(`${i18nBase}.adjust_pre_siteline_retention.edit_retention`)}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
