import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { integrationTypes } from 'siteline-common-all'
import { colors } from 'siteline-common-web'
import syncPendingImage from '../../../assets/images/syncPending.png'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { WriteSyncResultScreen, WriteSyncResultScreenText } from './WriteSyncResultScreen'

interface WriteSyncStillRunningScreenProps {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload
  onClose: () => void
}

const i18nBase = 'integrations.still_running'

/**
 * Screen shown when a sync was still running after the 1min client timeout.
 */
export function WriteSyncStillRunningScreen({
  integration,
  projectId,
  payload,
  onClose,
}: WriteSyncStillRunningScreenProps) {
  const { t } = useTranslation()

  const text = useMemo((): WriteSyncResultScreenText => {
    return {
      actions: [],
      title: t(`${i18nBase}.title`),
      description: t(`${i18nBase}.description`),
      icon: (
        <img
          src={syncPendingImage}
          className="image"
          style={{ color: colors.red50, fontSize: 60 }}
          alt={t(`${i18nBase}.title`)}
        />
      ),
      closeLabel: t('common.actions.ok'),
    }
  }, [t])

  return (
    <WriteSyncResultScreen
      text={text}
      integration={integration}
      payload={payload}
      projectId={projectId}
      onClose={onClose}
    />
  )
}
