import _ from 'lodash'
import { integrationTypes } from 'siteline-common-all'
import { IntegrationVendorInvoice } from 'siteline-common-web'
import {
  VendorContractForLienWaiversProperties,
  VendorsForIntegrationLinkingQuery,
} from '../../../../common/graphql/apollo-operations'

type VendorContractForSelectingVendors = Pick<
  VendorContractForLienWaiversProperties,
  'id' | 'vendor'
>

/**
 * Given a list of vendor contracts and an integration vendor, find the vendor contract for a vendor
 * linked to that integration vendor. If none exists, returns undefined.
 */
export function findVendorContractWithIntegrationVendorId<
  T extends VendorContractForSelectingVendors,
>(vendorContracts: T[], integrationVendorId: string, companyIntegrationId: string): T | undefined {
  return vendorContracts.find((vendorContract) => {
    const mappings = vendorContract.vendor
      .integrationMappings as integrationTypes.VendorIntegrationMappings
    return mappings.integrations?.find(
      (integration) =>
        integration.companyIntegrationId === companyIntegrationId &&
        integration.integrationVendorId === integrationVendorId
    )
  })
}

type VendorIntegrationMappingsIntegration = NonNullable<
  integrationTypes.VendorIntegrationMappings['integrations']
>[number]

/**
 * Given a vendor, returns the vendor's integration details for the given company integration. If
 * no mapping for that integration, returns undefined.
 */
export function getVendorIntegrationForCompanyIntegration(
  vendor: Pick<VendorForIntegrationLinking, 'integrationMappings'>,
  companyIntegrationId: string
): VendorIntegrationMappingsIntegration | undefined {
  const mappings = vendor.integrationMappings as integrationTypes.VendorIntegrationMappings
  return mappings.integrations?.find(
    (integration) => integration.companyIntegrationId === companyIntegrationId
  )
}

/**
 * Given a list of integration invoices and vendor contracts on a project, creates a map from
 * each invoice's ID to the Siteline ID of the vendor linked to that invoice's integration vendor.
 * This may be used when the sitelineVendorId has not yet been filled on all the invoices.
 */
export function getVendorIdByInvoiceId<T extends VendorContractForSelectingVendors>(
  invoices: IntegrationVendorInvoice[],
  vendorContracts: T[],
  companyIntegrationId: string
): Record<string, string> {
  return _.chain(invoices)
    .map((invoice) => {
      const invoiceVendorContract = findVendorContractWithIntegrationVendorId(
        vendorContracts,
        invoice.integrationVendorId,
        companyIntegrationId
      )
      return invoiceVendorContract
        ? [invoice.integrationInvoiceId, invoiceVendorContract.vendor.id]
        : null
    })
    .compact()
    .fromPairs()
    .value()
}

/**
 * Given a list of integration vendor IDs and vendor contracts on a project, creates a map from
 * integration vendor ID to the vendor contract ID for the Siteline vendor linked to that
 * integration vendor. If no vendor contract is found that links to the integration vendor, the
 * integration vendor will not be included in the map.
 */
export function getVendorContractIdByIntegrationVendorId<
  T extends VendorContractForSelectingVendors,
>(
  integrationVendorIds: string[],
  vendorContracts: T[],
  companyIntegrationId: string
): Record<string, string> {
  return _.chain(integrationVendorIds)
    .map((integrationVendorId) => {
      const vendorContract = findVendorContractWithIntegrationVendorId(
        vendorContracts,
        integrationVendorId,
        companyIntegrationId
      )
      return vendorContract ? [integrationVendorId, vendorContract.id] : null
    })
    .compact()
    .fromPairs()
    .value()
}

export type VendorForIntegrationLinking = VendorsForIntegrationLinkingQuery['vendors'][number]

/**
 * Given a list of vendors, creates a map from a vendor's linked integration vendor ID to the
 * vendor. Vendors with no linked integration vendor (for the given integration) are not included
 * in the map.
 */
export function getVendorByIntegrationVendorId(
  vendors: VendorForIntegrationLinking[],
  companyIntegrationId: string
): Record<string, VendorForIntegrationLinking> {
  return _.chain(vendors)
    .map((vendor) => {
      const vendorIntegration = getVendorIntegrationForCompanyIntegration(
        vendor,
        companyIntegrationId
      )
      return vendorIntegration?.integrationVendorId
        ? [vendorIntegration.integrationVendorId, vendor]
        : null
    })
    .compact()
    .fromPairs()
    .value()
}
