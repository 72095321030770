import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardMedia, Skeleton, Theme } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { colors, makeStylesFast, useToggle } from 'siteline-common-web'
import { PdfPageMemoized } from '../../../common/components/Pdf/PdfPageMemoized'
import { StoredFileProperties, StoredFileType } from '../../../common/graphql/apollo-operations'
import { Z_INDEX } from '../../../common/themes/Main'
import { getIcon, isImage } from '../../../common/util/PayAppAttachment'
import { Document } from '../../../pdf'

const DEFAULT_IMAGE_WIDTH = 200
export const MEDIA_CARD_HEIGHT = 160
const MEDIA_HEIGHT = 120

const useStyles = makeStylesFast((theme: Theme) => ({
  media: {
    width: '100%',
    height: MEDIA_HEIGHT,
    borderBottom: `1px solid ${colors.grey30}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  clickable: {
    cursor: 'pointer',
  },
  content: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: 0,
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  loadingThumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: Z_INDEX.mask,
    height: MEDIA_HEIGHT,
    backgroundColor: colors.grey10,
  },
}))

interface ContractFileThumbnailProps {
  file: StoredFileProperties
  onClick: () => void
}

/**
 * A clickable thumnail that displays a preview of an uploaded contract file.
 * Note that this is similar to `SitelineCardMedia`, except it does not display a UI for
 * uploading attachments and it handles the primary file type for contract files (PDFs).
 */
export function ContractFileThumbnail({ file, onClick }: ContractFileThumbnailProps) {
  const classes = useStyles()
  const cardMediaRef = useRef<HTMLDivElement | null>(null)

  const [pdfThumbnailWidth, setPdfThumbnailWidth] = useState<number>(DEFAULT_IMAGE_WIDTH)
  const [isLoadingPdf, , hideLoadingPdf] = useToggle(true)

  // Determine the size of the thumbmail on mount
  useEffect(() => {
    if (cardMediaRef.current) {
      const size = cardMediaRef.current.getBoundingClientRect()
      setPdfThumbnailWidth(size.width)
    }
  }, [])

  if (isImage(file.type)) {
    return (
      <CardMedia
        onClick={onClick}
        image={file.url}
        className={clsx(classes.media, classes.clickable)}
        component="img"
      />
    )
  }

  if (file.type === StoredFileType.PDF) {
    return (
      <CardMedia
        ref={cardMediaRef}
        onClick={onClick}
        className={clsx(classes.media, classes.clickable)}
      >
        {isLoadingPdf && (
          <Skeleton
            variant="rectangular"
            width={pdfThumbnailWidth}
            className={classes.loadingThumbnail}
          />
        )}
        <Document file={file.url}>
          <PdfPageMemoized
            thumbnailWidth={pdfThumbnailWidth}
            pageNumber={1}
            onRenderSuccess={hideLoadingPdf}
          />
        </Document>
      </CardMedia>
    )
  }

  return (
    <CardMedia onClick={onClick} className={clsx(classes.media, classes.clickable)}>
      <div className={classes.content}>
        <FontAwesomeIcon icon={getIcon(file.type)} size="3x" color={colors.grey40} />
      </div>
    </CardMedia>
  )
}
