import { FormControlLabel, RadioGroup } from '@mui/material'
import { Theme } from '@mui/material/styles'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pdfTypes } from 'siteline-common-all'
import {
  SitelineText,
  generatePdf,
  makeStylesFast,
  savePdf,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { SitelineRadio } from '../../../common/components/SitelineRadio'
import { Spacer } from '../../../common/components/Spacer'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { MinimalChangeOrderRequestProperties } from '../../../common/graphql/apollo-operations'
import {
  exportChangeOrderLogDataToXlsx,
  totalApprovedAmount,
  totalAuthorizedToProceed,
  totalProceedingWithWork,
  totalRequestedAmount,
} from '../../../common/util/ChangeOrderRequest'
import { getChangeOrderLogFilename } from '../../../common/util/Filename'
import { trackChangeOrderLogExported } from '../../../common/util/MetricsTracking'
import { contractSumToDate, originalContractValue } from '../../../common/util/Sov'
import { ContractForProjectHome } from '../home/ProjectHome'

const useStyles = makeStylesFast((theme: Theme) => ({
  exportDialog: {
    '& .radioButtons': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .radioOptionDescription': {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(-0.5),
    },
  },
}))

export enum ExportType {
  PDF,
  EXCEL,
}

const i18nBase = 'projects.subcontractors.change_order_requests.co_log_forms'

interface ExportChangeOrderLogDialogProps {
  /** Null indicates that the dialog is not open */
  exportType: ExportType | null
  onClose: () => void
  contract: ContractForProjectHome
  changeOrderRequests: MinimalChangeOrderRequestProperties[]
}

/** Dialog flow for exporting the change order log. Users select whether they want to export internal or external.  */
export function ExportChangeOrderLogDialog({
  exportType,
  onClose,
  contract,
  changeOrderRequests,
}: ExportChangeOrderLogDialogProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { timeZone, name: projectName, projectNumber, id: projectId } = useProjectContext()
  const { companyId } = useCompanyContext()

  const [loadingExport, beginLoadingExport, finishLoadingExport] = useToggle()
  const [isLogVisibleToGc, setIsLogVisibleToGc] = useState<boolean>(true)

  const sovLineItems = useMemo(() => {
    if (!contract.sov) {
      return []
    }
    return [...contract.sov.lineItems]
  }, [contract.sov])

  const handleToggleVisibleToGc = useCallback(() => {
    setIsLogVisibleToGc((current) => !current)
  }, [])

  const handleClose = useCallback(() => {
    setIsLogVisibleToGc(true)
    onClose()
  }, [onClose])

  const handleExportToExcel = useCallback(async () => {
    beginLoadingExport()
    snackbar.showInfo(t(`${i18nBase}.preparing_export`))

    await exportChangeOrderLogDataToXlsx({
      isLogVisibleToGc,
      t,
      timeZone,
      changeOrderRequests,
      projectName,
      projectNumber,
      leadPMs: [...contract.leadPMs],
      gcName: contract.project.generalContractor?.company.name ?? '',
      totalAmountRequested: totalRequestedAmount(changeOrderRequests),
      totalAmountApproved: totalApprovedAmount(changeOrderRequests),
      totalAmountProceedingWithWork: totalProceedingWithWork(changeOrderRequests),
      totalAmountAuthorizedToProceed: totalAuthorizedToProceed(changeOrderRequests),
      originalContractAmount: originalContractValue(sovLineItems),
      contractSumAmount: contractSumToDate(sovLineItems),
      billingType: contract.billingType,
    })

    trackChangeOrderLogExported({
      exportType: 'excel',
      includeInternalOnly: !isLogVisibleToGc,
      companyId,
      projectId,
      projectName,
    })

    snackbar.closeAll()
    finishLoadingExport()
    handleClose()
  }, [
    beginLoadingExport,
    changeOrderRequests,
    companyId,
    contract.billingType,
    contract.leadPMs,
    contract.project.generalContractor?.company.name,
    finishLoadingExport,
    handleClose,
    isLogVisibleToGc,
    projectId,
    projectName,
    projectNumber,
    snackbar,
    sovLineItems,
    t,
    timeZone,
  ])

  const handleDownloadPdf = useCallback(async () => {
    if (changeOrderRequests.length === 0) {
      return
    }

    beginLoadingExport()
    snackbar.showLoading(t(`${i18nBase}.downloading`))

    try {
      const fileName = getChangeOrderLogFilename(projectName, moment.tz(timeZone))
      const printAndDownloadPdfPackage: pdfTypes.PayloadPackage = {
        type: 'changeOrderLog',
        contractId: contract.id,
        includeVisibleToGcOnly: isLogVisibleToGc,
      }
      const pdf = await generatePdf(printAndDownloadPdfPackage)
      savePdf(pdf, fileName)
      trackChangeOrderLogExported({
        exportType: 'pdf',
        includeInternalOnly: !isLogVisibleToGc,
        companyId,
        projectId,
        projectName,
      })
    } catch (error) {
      snackbar.showError(error.message)
    }

    finishLoadingExport()
    snackbar.closeAll()
    handleClose()
  }, [
    beginLoadingExport,
    changeOrderRequests.length,
    companyId,
    contract.id,
    finishLoadingExport,
    handleClose,
    isLogVisibleToGc,
    projectId,
    projectName,
    snackbar,
    t,
    timeZone,
  ])

  const { title, submitLabel, handleSubmit } = useMemo(() => {
    switch (exportType) {
      case null:
        return { title: '', submitLabel: undefined, handleSubmit: undefined }
      case ExportType.EXCEL:
        return {
          title: t(`${i18nBase}.export_excel`),
          submitLabel: t(`${i18nBase}.export`),
          handleSubmit: handleExportToExcel,
        }
      case ExportType.PDF:
        return {
          title: t(`${i18nBase}.download_pdf`),
          submitLabel: t(`${i18nBase}.download`),
          handleSubmit: handleDownloadPdf,
        }
    }
  }, [exportType, handleDownloadPdf, handleExportToExcel, t])

  return (
    <SitelineDialog
      title={title}
      open={exportType !== null}
      onClose={handleClose}
      submitting={loadingExport}
      onSubmit={handleSubmit}
      maxWidth="sm"
      submitLabel={submitLabel}
      className={classes.exportDialog}
    >
      <RadioGroup
        value={isLogVisibleToGc}
        onChange={handleToggleVisibleToGc}
        className="radioButtons"
      >
        <div>
          <FormControlLabel
            value={true}
            label={t(`${i18nBase}.gc_log_option`)}
            control={<SitelineRadio color="primary" size="small" />}
          />
          <SitelineText variant="body1" color="grey50" className="radioOptionDescription">
            {t(`${i18nBase}.gc_log_option_description`)}
          </SitelineText>
        </div>
        <Spacer maxWidth={8} minHeight={8} />
        <div>
          <FormControlLabel
            value={false}
            label={t(`${i18nBase}.internal_option`)}
            control={<SitelineRadio color="primary" size="small" />}
          />
          <SitelineText variant="body1" color="grey50" className="radioOptionDescription">
            {t(`${i18nBase}.internal_option_description`)}
          </SitelineText>
        </div>
      </RadioGroup>
    </SitelineDialog>
  )
}
