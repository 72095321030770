import { gql } from '@apollo/client'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Collapse,
  Fade,
  FormControlLabel,
  Link,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  ContractPaymentTermsType,
  DAY_FORMAT,
  IntegrationType,
  MAX_SAGE_INVOICE_CODE_LENGTH,
  Sage100InvoiceTaxType,
  TaxCalculationType,
  getDefaultInvoiceCode,
  getDefaultInvoiceDates,
  integrationTypes,
} from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { Column } from '../../../common/components/Column'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { MultiFilterDropdownMenu } from '../../../common/components/MultiFilterDropdownMenu'
import { DollarNumberFormat } from '../../../common/components/NumberFormat'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { SitelineDialog, SitelineDialogProps } from '../../../common/components/SitelineDialog'
import { config } from '../../../common/config/constants'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  GetIntegrationGeneralLedgerAccountsQuery,
  MinimalIntegrationProperties,
  useGenerateIntegrationInvoiceCodeLazyQuery,
  useGetIntegrationGeneralLedgerAccountsQuery,
  usePayAppForSage100SyncQuery,
} from '../../../common/graphql/apollo-operations'
import { isWriteSyncInProgress, useWriteSync } from '../../../common/util/Integration'
import { trackIntegrationSyncDialogReset } from '../../../common/util/MetricsTracking'
import { sortSovLineItemProgress } from '../../../common/util/PayApp'
import { WriteSyncDialogContent } from './WriteSyncDialogContent'

const SECTION_WIDTH = 450
const DROPDOWN_WIDTH = 190

gql`
  query payAppForSage100Sync($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      billingEnd
      payAppNumber
      currentBilled
      amountDuePostTax
      amountDueTaxAmount
      lastSubmitted {
        id
        statusUpdatedAt
      }
      contract {
        id
        taxCalculationType
        appliesTaxToRetention
      }
      progress {
        id
        currentBilled
        sovLineItem {
          id
          code
          name
          sortOrder
        }
      }
      rateTableItems {
        id
        currentUnitsBilled
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  help: {
    marginTop: theme.spacing(1),
  },
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 720,
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      '& .row': {
        display: 'flex',
        gap: theme.spacing(5),
        '& .leftSection': {
          width: SECTION_WIDTH,
          display: 'flex',
          justifyContent: 'space-between',
        },
        '& .tooltip': {
          width: 16,
          height: 16,
          color: colors.grey50,
        },
      },
      '& .noDefaultCode': {
        marginTop: theme.spacing(-1),
      },
      '& .setting': {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        margin: theme.spacing(1.5, 0),
        '&.doubleGap': {
          gap: theme.spacing(2),
        },
      },
    },
    '& .dateInput': {
      width: DROPDOWN_WIDTH,
    },
    '& .invoiceCode': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .generateCode': {
        marginLeft: theme.spacing(1),
      },
    },
    '& .loadingAccounts': {
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    '& .divider': {
      margin: theme.spacing(1.5, 0),
    },
    '& .numberInput': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      // Match height of the other inputs
      height: 39,
      width: '100%',
      ...theme.typography.body1,
      '&:hover': {
        borderColor: colors.grey90,
      },
    },
  },
  syncSuccess: {
    '& .MuiDialog-paper': {
      maxWidth: 720,
    },
    '& .MuiDialogContent-root': {
      margin: theme.spacing(-2.5),
    },
  },
  autocomplete: {
    backgroundColor: colors.white,
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  taxAmount: {
    marginTop: theme.spacing(1),
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      maxWidth: 'unset !important',
    },
  },
  readOnlyAccount: {
    marginTop: theme.spacing(1),
  },
}))

gql`
  query getIntegrationGeneralLedgerAccounts($companyIntegrationId: ID!) {
    integrationGeneralLedgerAccounts(companyIntegrationId: $companyIntegrationId) {
      integrationAccountId
      parentName
      parentCode
      parentId
      code
      name
    }
  }
`

const HELP_CENTER_URL =
  'https://support.siteline.com/hc/en-us/articles/12460801343380-Sage-100-Contractor-Integration-Details'
const i18nBase = 'integrations.sage_100_sync_dialog'
export type GeneralLedgerAccount =
  GetIntegrationGeneralLedgerAccountsQuery['integrationGeneralLedgerAccounts'][number]

function getOptionLabel(option: GeneralLedgerAccount): string {
  if (option.parentName && option.parentCode) {
    return `${option.parentCode} ${option.parentName} (${option.code} ${option.name})`
  }
  return `${option.code} ${option.name}`
}

type IncomeAccount = { accountId: string; parentAccountId?: string }

interface SyncSage100CDialogProps {
  open: boolean
  onClose: () => void
  integration: MinimalIntegrationProperties
  payAppId: string
  /** Pass this separately from `integration` so we don't have to handle all types */
  integrationType: IntegrationType.SAGE_100_CONTRACTOR | IntegrationType.SAGE_100_CONTRACTOR_AGAVE
}

/**
 * Dialog that gives options to select from when syncing their invoice to Sage 100 Contractor.
 */
export function SyncSage100CDialog({
  open,
  onClose,
  integration,
  payAppId,
  integrationType,
}: SyncSage100CDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { id: projectId, projectNumber, contract, timeZone } = useProjectContext()
  const { companyAgingIntervalType } = useCompanyContext()
  // This number will be incremented to trigger a re-try if the user clicks "Sync again"
  const [invoiceCode, setInvoiceCode] = useState<string>('')
  const [invoiceDate, setInvoiceDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const [dueDate, setDueDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const integrationLongName = integration.longName
  const [generateInvoiceCode, { loading: generatingInvoiceCode }] =
    useGenerateIntegrationInvoiceCodeLazyQuery({
      variables: { input: { integrationId: integration.id } },
      // Always go to the network, in case a new invoice code has been posted since the last fetch
      fetchPolicy: 'network-only',
    })

  const mappings = integration.mappings as
    | integrationTypes.IntegrationMappingsSage100Contractor
    | integrationTypes.IntegrationMappingsSage100ContractorAgave

  // Income account
  const defaultAccount = useMemo(() => {
    switch (integrationType) {
      case IntegrationType.SAGE_100_CONTRACTOR: {
        const sageMappings = mappings as integrationTypes.IntegrationMappingsSage100Contractor
        return sageMappings.incomeAccount
          ? { accountId: sageMappings.incomeAccount.hh2AccountId }
          : null
      }
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE: {
        const agaveMappings = mappings as integrationTypes.IntegrationMappingsSage100ContractorAgave
        if (!agaveMappings.incomeAccount) {
          return null
        }
        if (agaveMappings.incomeAccount.subsidiaryAccountRef) {
          return {
            accountId: agaveMappings.incomeAccount.subsidiaryAccountRef,
            parentAccountId: agaveMappings.incomeAccount.agaveAccountId,
          }
        }
        return { accountId: agaveMappings.incomeAccount.agaveAccountId }
      }
    }
  }, [integrationType, mappings])
  const [incomeAccount, setIncomeAccount] = useState<IncomeAccount | null>(defaultAccount)

  // Tax account
  const defaultTaxAccount = useMemo(() => {
    switch (integrationType) {
      case IntegrationType.SAGE_100_CONTRACTOR: {
        const sageMappings = mappings as integrationTypes.IntegrationMappingsSage100Contractor
        return sageMappings.defaultIntegrationTaxAccountId
          ? { accountId: sageMappings.defaultIntegrationTaxAccountId }
          : defaultAccount
      }
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE: {
        const agaveMappings = mappings as integrationTypes.IntegrationMappingsSage100ContractorAgave
        if (!agaveMappings.defaultIntegrationTaxAccountId) {
          return defaultAccount
        }
        if (agaveMappings.defaultTaxSubsidiaryAccountRef) {
          return {
            accountId: agaveMappings.defaultTaxSubsidiaryAccountRef,
            parentAccountId: agaveMappings.defaultIntegrationTaxAccountId,
          }
        }
        return { accountId: agaveMappings.defaultIntegrationTaxAccountId }
      }
    }
  }, [defaultAccount, integrationType, mappings])
  const [taxAccount, setTaxAccount] = useState<IncomeAccount | null>(defaultTaxAccount)

  const { data, loading, error } = usePayAppForSage100SyncQuery({
    variables: { payAppId },
    skip: !open,
  })

  const hasAnyBilling = useMemo(() => {
    if (!contract || !data) {
      return false
    }

    const { billingType } = contract
    if (billingType === BillingType.LUMP_SUM || billingType === BillingType.UNIT_PRICE) {
      const linesBilled = data.payApp.progress.filter(
        (progressLine) => progressLine.currentBilled !== 0
      )
      return linesBilled.length > 0
    }

    if (billingType === BillingType.TIME_AND_MATERIALS) {
      const rateTableItems = data.payApp.rateTableItems.map((item) => item.currentUnitsBilled !== 0)
      return rateTableItems.length > 0
    }

    return false
  }, [contract, data])

  const {
    data: accountsData,
    loading: loadingAccounts,
    error: accountsError,
    refetch: refetchAccounts,
  } = useGetIntegrationGeneralLedgerAccountsQuery({
    variables: { companyIntegrationId: integration.companyIntegration.id },
    // Sets loading to true when calling refetch on error
    notifyOnNetworkStatusChange: true,
    skip: !open,
  })
  const handleRetryAccounts = useCallback(() => refetchAccounts(), [refetchAccounts])

  // Tax line items
  const defaultTaxLineItemIds = useMemo(() => {
    const availableLineItemIds =
      data?.payApp.progress.map((progress) => progress.sovLineItem.id) ?? []
    const lineItemIdsInMappings = mappings.defaultSitelineTaxLineItemIds ?? []
    return lineItemIdsInMappings.filter((lineItemId) => availableLineItemIds.includes(lineItemId))
  }, [data?.payApp.progress, mappings.defaultSitelineTaxLineItemIds])
  const [taxLineItemIds, setTaxLineItemIds] = useState<string[]>(defaultTaxLineItemIds)

  // Determine whether the contract calculates taxes
  const calculatesTaxes = useMemo(() => {
    if (!data?.payApp.contract.taxCalculationType) {
      return false
    }
    switch (data.payApp.contract.taxCalculationType) {
      case TaxCalculationType.NONE:
        return false
      case TaxCalculationType.SINGLE_TAX_GROUP:
      case TaxCalculationType.MULTIPLE_TAX_GROUPS:
        return true
    }
  }, [data?.payApp.contract.taxCalculationType])

  // Determine how to insert taxes on the invoice, by default
  const defaultInvoiceTaxType = useMemo((): Sage100InvoiceTaxType | null => {
    const fallback = calculatesTaxes ? Sage100InvoiceTaxType.TAX_DISTRICT : null
    if (mappings.defaultInvoiceTaxType === Sage100InvoiceTaxType.TAX_DISTRICT && !calculatesTaxes) {
      return fallback
    }
    if (
      mappings.defaultInvoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX &&
      calculatesTaxes
    ) {
      return fallback
    }
    return fallback
  }, [calculatesTaxes, mappings.defaultInvoiceTaxType])
  const [invoiceTaxType, setInvoiceTaxType] = useState<Sage100InvoiceTaxType | null>(
    defaultInvoiceTaxType
  )

  const accounts = useMemo(() => {
    // If an account has subsidiary accounts, Sage will require a subaccount to be specified. We
    // filter parent accounts out of this list if they have subaccounts to prevent this error. I.e.
    // if a primary account also appears in this list as a parent account, remove it as a primary
    // account so one of its subaccounts will be selected instead.
    const allAccounts = accountsData?.integrationGeneralLedgerAccounts ?? []
    const parentAccounts = new Set(
      allAccounts.map((account) => `${account.parentCode} ${account.parentName}`)
    )
    return _.chain(allAccounts)
      .filter((account) => !parentAccounts.has(`${account.code} ${account.name}`))
      .orderBy(getOptionLabel)
      .value()
  }, [accountsData])
  const selectedTaxAccount = useMemo(
    () =>
      accounts.find(
        (account) =>
          account.integrationAccountId === taxAccount?.accountId &&
          account.parentId === (taxAccount.parentAccountId ?? null)
      ) ?? null,
    [accounts, taxAccount]
  )
  const selectedIncomeAccount = useMemo(
    () =>
      accounts.find(
        (account) =>
          account.integrationAccountId === incomeAccount?.accountId &&
          account.parentId === (incomeAccount.parentAccountId ?? null)
      ) ?? null,
    [accounts, incomeAccount]
  )

  const payAppTotal = useMemo(
    () => data?.payApp.amountDuePostTax ?? 0,
    [data?.payApp.amountDuePostTax]
  )

  const taxLineItems = useMemo(() => {
    const progress = data?.payApp.progress ?? []
    return progress
      .map((progress) => ({
        value: progress.sovLineItem.id,
        label: `${progress.sovLineItem.code} · ${progress.sovLineItem.name}`,
        amount: progress.currentBilled,
        sovLineItem: {
          sortOrder: progress.sovLineItem.sortOrder,
          code: progress.sovLineItem.code,
        },
      }))
      .filter((progress) => progress.amount !== 0)
      .sort(sortSovLineItemProgress)
  }, [data])

  const taxAmount = useMemo(() => {
    switch (invoiceTaxType) {
      case null:
        return 0
      case Sage100InvoiceTaxType.TAX_DISTRICT:
        return data?.payApp.amountDueTaxAmount ?? 0
      case Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX:
        return _.chain(taxLineItems)
          .filter((lineItem) => taxLineItemIds.includes(lineItem.value))
          .sumBy((lineItem) => lineItem.amount)
          .value()
    }
  }, [data?.payApp.amountDueTaxAmount, invoiceTaxType, taxLineItemIds, taxLineItems])

  const incomeAmount = useMemo(() => {
    switch (invoiceTaxType) {
      case null:
      case Sage100InvoiceTaxType.TAX_DISTRICT:
        return data?.payApp.currentBilled ?? 0
      case Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX:
        return (data?.payApp.currentBilled ?? 0) - taxAmount
    }
  }, [data?.payApp.currentBilled, invoiceTaxType, taxAmount])

  const handleGenerateInvoiceCode = useCallback(async () => {
    try {
      const { data } = await generateInvoiceCode()
      const generatedInvoiceCode = data?.generateIntegrationInvoiceCode
      if (!generatedInvoiceCode) {
        snackbar.showError(t(`${i18nBase}.no_generated_code`))
        return
      }
      setInvoiceCode(generatedInvoiceCode)
    } catch (err) {
      console.error(err.message)
      snackbar.showError(t(`${i18nBase}.no_generated_code`))
    }
  }, [generateInvoiceCode, snackbar, t])

  const payload = useMemo((): integrationTypes.WriteSyncPayloadPayAppSage100 | null => {
    if (!invoiceDate.date || !dueDate.date || !incomeAccount || error || accountsError) {
      return null
    }

    let integrationAccountId: string | null
    let subsidiaryAccountRef: string | undefined
    let integrationTaxAccountId: string | undefined
    let taxSubsidiaryAccountRef: string | undefined
    switch (integrationType) {
      case IntegrationType.SAGE_100_CONTRACTOR: {
        integrationAccountId = incomeAccount.accountId
        integrationTaxAccountId = taxAccount?.accountId
        break
      }
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE: {
        integrationAccountId = incomeAccount.parentAccountId ?? incomeAccount.accountId
        subsidiaryAccountRef = incomeAccount.parentAccountId ? incomeAccount.accountId : undefined
        integrationTaxAccountId = taxAccount?.parentAccountId ?? taxAccount?.accountId
        taxSubsidiaryAccountRef = taxAccount?.parentAccountId ? taxAccount.accountId : undefined
        break
      }
    }

    if (!integrationAccountId) {
      return null
    }

    return {
      type: 'payAppSage100',
      payAppId,
      invoiceDate: invoiceDate.date.format(DAY_FORMAT),
      invoiceCode,
      dueDate: dueDate.date.format(DAY_FORMAT),
      integrationAccountId,
      subsidiaryAccountRef,
      invoiceTaxType: invoiceTaxType ?? undefined,
      // We only need to include the tax account information if tax type is defined
      ...(invoiceTaxType && { integrationTaxAccountId, taxSubsidiaryAccountRef }),
      sitelineTaxLineItemIds: taxLineItemIds,
    }
  }, [
    invoiceDate.date,
    dueDate.date,
    incomeAccount,
    error,
    accountsError,
    integrationType,
    payAppId,
    invoiceCode,
    invoiceTaxType,
    taxLineItemIds,
    taxAccount?.accountId,
    taxAccount?.parentAccountId,
  ])

  const { sync, status } = useWriteSync({ integration })

  const resetDates = useCallback(() => {
    if (!data) {
      return
    }
    const paymentTerms = _.isNumber(contract?.paymentTerms) ? contract.paymentTerms : null
    const { invoiceDate, dueDate } = getDefaultInvoiceDates({
      agingIntervalType: companyAgingIntervalType,
      timeZone,
      paymentTerms,
      billingEnd: moment.tz(data.payApp.billingEnd, timeZone),
      submittedAt: data.payApp.lastSubmitted
        ? moment.tz(data.payApp.lastSubmitted.statusUpdatedAt, timeZone)
        : null,
    })
    setInvoiceDate(makeDatePickerValue(invoiceDate))
    setDueDate(makeDatePickerValue(dueDate))
  }, [companyAgingIntervalType, contract?.paymentTerms, data, timeZone])

  const resetDialog = useCallback(
    (trackClick: boolean) => {
      if (!data) {
        return
      }

      // Reset all dates
      resetDates()

      if (trackClick) {
        trackIntegrationSyncDialogReset({ projectId, payAppId, integrationLongName })
      }
    },
    [payAppId, data, projectId, resetDates, integrationLongName]
  )

  // When we have all the data, try to auto-populate as best as we can. Always reset the dialog
  // when you change between pay apps.
  useEffect(() => {
    resetDialog(false)
  }, [resetDialog, payAppId])

  // Update the invoice date if the pay app dates or company aging settings change
  useEffect(() => {
    resetDates()
  }, [resetDates])

  const defaultInvoiceCode = useMemo(
    () =>
      getDefaultInvoiceCode({
        internalProjectNumber: contract?.internalProjectNumber,
        projectNumber,
        payAppNumber: data?.payApp.payAppNumber,
        maxInvoiceCodeLength: MAX_SAGE_INVOICE_CODE_LENGTH,
        billingType: contract?.billingType,
      }),
    [
      contract?.billingType,
      contract?.internalProjectNumber,
      data?.payApp.payAppNumber,
      projectNumber,
    ]
  )

  // Assign the invoice code based on project number and pay app number. This essentially only gets
  // called once when the dialog is first rendered.
  useEffect(() => {
    setInvoiceCode(defaultInvoiceCode)
  }, [defaultInvoiceCode])

  let dialogTitle = t(`${i18nBase}.header`)
  let cancelLabel: string | undefined
  let handleClose: (() => void) | undefined
  let handleSubmit: (() => void) | undefined
  let className: string | undefined
  let actionsLayout: SitelineDialogProps['actionsLayout'] = 'actionsRow'
  let simpleErrorMessage: string | undefined

  if (status.type !== 'notCreated') {
    dialogTitle = ''
    actionsLayout = 'closeIcon'
    className = isWriteSyncInProgress(status) ? undefined : classes.syncSuccess
  } else if (!hasAnyBilling) {
    handleClose = onClose
    simpleErrorMessage = t(`${i18nBase}.errors.no_billing`)
  } else {
    className = classes.root
    handleClose = onClose
    handleSubmit = () => {
      if (!payload) {
        return
      }
      sync(payload)
    }
  }

  const invoiceCodeTooLong = invoiceCode.length > MAX_SAGE_INVOICE_CODE_LENGTH
  let invoiceCodeHelperText: string | undefined
  if (invoiceCodeTooLong) {
    invoiceCodeHelperText = t(`${i18nBase}.invoice_code_length_long`)
  } else if (!defaultInvoiceCode) {
    invoiceCodeHelperText = t(`${i18nBase}.no_default_invoice_code`)
  }
  const invoiceCodeError = invoiceCodeTooLong || !invoiceCode

  const hasTaxOnRetentionError = useMemo(
    () =>
      invoiceTaxType === Sage100InvoiceTaxType.TAX_DISTRICT &&
      data?.payApp.contract.appliesTaxToRetention,
    [data?.payApp.contract.appliesTaxToRetention, invoiceTaxType]
  )

  const disableSubmit = useMemo(() => {
    if (loading || generatingInvoiceCode || error || accountsError) {
      return true
    }
    if (invoiceCodeError) {
      return true
    } else if (isMissingDate(invoiceDate)) {
      return true
    } else if (isMissingDate(dueDate)) {
      return true
    } else if (!incomeAccount) {
      return true
    } else if (invoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX && !taxAccount) {
      return true
    } else if (hasTaxOnRetentionError) {
      return true
    }
    return false
  }, [
    accountsError,
    dueDate,
    error,
    generatingInvoiceCode,
    hasTaxOnRetentionError,
    incomeAccount,
    invoiceCodeError,
    invoiceDate,
    invoiceTaxType,
    loading,
    taxAccount,
  ])
  const showLoading = loading || (!simpleErrorMessage && !incomeAccount && loadingAccounts)
  const showMainContent =
    !showLoading && !error && !accountsError && status.type === 'notCreated' && !simpleErrorMessage

  return (
    <SitelineDialog
      title={dialogTitle}
      maxWidth={isWriteSyncInProgress(status) ? 'sm' : 'md'}
      open={open}
      onClose={handleClose}
      cancelLabel={cancelLabel}
      onSubmit={handleSubmit}
      submitLabel={t('integrations.button.sync')}
      disableSubmit={disableSubmit}
      className={className}
      actionsLayout={actionsLayout}
      disableEscapeKeyDown
      subtitle={
        showMainContent && (
          <Link target="_blank" href={HELP_CENTER_URL} underline="none">
            <SitelineText
              variant="h4"
              color="blue50"
              endIcon={<LaunchIcon fontSize="small" />}
              className={classes.help}
            >
              {t(`${i18nBase}.help_center`)}
            </SitelineText>
          </Link>
        )
      }
    >
      {payload && (
        <WriteSyncDialogContent
          integration={integration}
          projectId={projectId}
          onClose={handleClose ?? onClose}
          onSyncAgain={() => sync(payload)}
          payload={payload}
          status={status}
        />
      )}

      {!loading && simpleErrorMessage && (
        <SitelineText variant="secondary">{simpleErrorMessage}</SitelineText>
      )}

      {accountsError && !simpleErrorMessage && (
        <Column alignItems="flex-start" gap={16}>
          <SitelineText variant="secondary">
            {t('projects.onboarding.project_info.integration_error', {
              integration: integration.shortName,
              email: config.support.email,
            })}
          </SitelineText>
          <Button variant="outlined" color="secondary" onClick={handleRetryAccounts}>
            {t('common.actions.retry')}
          </Button>
        </Column>
      )}

      {showLoading && (
        <div className="container">
          <div className="row">
            <div className="leftSection">
              <div className="setting doubleGap">
                <Skeleton variant="rectangular" width={150} />
              </div>
              <div className="setting">
                <Skeleton variant="rectangular" width={150} />
              </div>
            </div>
            <div className="setting">
              <Skeleton variant="rectangular" width={150} />
            </div>
          </div>
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <Skeleton variant="rectangular" width={150} />
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <Skeleton variant="rectangular" width={SECTION_WIDTH} />
              </div>
            </div>
          </div>
        </div>
      )}

      {showMainContent && (
        <div className="container">
          <div className="row">
            <div className="leftSection">
              <div className="setting doubleGap">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount`)}
                </SitelineText>
                <div>
                  <SitelineText variant="h3" bold>
                    <DollarNumberFormat value={payAppTotal} />
                  </SitelineText>
                </div>
              </div>
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_date`)}
                </SitelineText>
                <DatePickerInput
                  value={invoiceDate}
                  onChange={(value) =>
                    setInvoiceDate({
                      ...value,
                      date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                    })
                  }
                  timeZone={timeZone}
                  className="dateInput"
                />
              </div>
            </div>
            <div className="setting">
              <SitelineText
                variant="secondary"
                bold
                color="grey70"
                endIcon={
                  <Tooltip
                    title={
                      contract?.paymentTermsType === ContractPaymentTermsType.NET_PAYMENT &&
                      _.isNumber(contract.paymentTerms)
                        ? t(`${i18nBase}.due_date_tooltip_terms`)
                        : t(`${i18nBase}.due_date_tooltip`)
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon className="tooltip" />
                  </Tooltip>
                }
              >
                {t(`${i18nBase}.due_date`)}
              </SitelineText>
              <DatePickerInput
                value={dueDate}
                onChange={(value) =>
                  setDueDate({
                    ...value,
                    date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                  })
                }
                timeZone={timeZone}
                className="dateInput"
              />
            </div>
          </div>
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_number`)}
                </SitelineText>
                <div className="invoiceCode">
                  <TextField
                    error={invoiceCodeTooLong}
                    variant="outlined"
                    value={invoiceCode}
                    onChange={(ev) => setInvoiceCode(ev.target.value)}
                    disabled={generatingInvoiceCode}
                  />
                  <LoadingButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleGenerateInvoiceCode}
                    startIcon={<AutoAwesomeIcon />}
                    className="generateCode"
                    loading={generatingInvoiceCode}
                  >
                    {t(`${i18nBase}.generate_code`)}
                  </LoadingButton>
                </div>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          {(!defaultInvoiceCode || invoiceCodeHelperText) && (
            <div className="noDefaultCode">
              <SitelineText variant="smallText" color={invoiceCodeTooLong ? 'red50' : 'grey50'}>
                {invoiceCodeHelperText}
              </SitelineText>
            </div>
          )}

          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText
                  variant="secondary"
                  bold
                  color="grey70"
                  endIcon={
                    <Tooltip title={t(`${i18nBase}.income_account_tooltip`)} placement="top-start">
                      <InfoOutlinedIcon className="tooltip" />
                    </Tooltip>
                  }
                >
                  {t(`${i18nBase}.income_account`)}
                </SitelineText>
                {!loadingAccounts && (
                  <Autocomplete
                    value={selectedIncomeAccount}
                    fullWidth
                    style={{ width: SECTION_WIDTH }}
                    freeSolo={false}
                    options={accounts}
                    autoHighlight
                    openOnFocus
                    onChange={(ev, option) => {
                      if (!option) {
                        setIncomeAccount(null)
                        return
                      }
                      setIncomeAccount({
                        accountId: option.integrationAccountId,
                        parentAccountId: option.parentId ?? undefined,
                      })
                    }}
                    className={classes.autocomplete}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="outlined"
                          placeholder={t(`${i18nBase}.search_accounts`)}
                          {...params}
                        />
                      )
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={`${option.integrationAccountId}-${option.parentId}`}>
                        <SitelineText variant="secondary">{getOptionLabel(option)}</SitelineText>
                      </li>
                    )}
                    getOptionLabel={(option) => getOptionLabel(option)}
                  />
                )}
                {loadingAccounts && (
                  <Skeleton
                    variant="rectangular"
                    width={SECTION_WIDTH}
                    className="loadingAccounts"
                  />
                )}
              </div>
            </div>
            <div>
              <Fade in={invoiceTaxType !== null}>
                <div className="setting doubleGap">
                  <SitelineText variant="secondary" bold color="grey70">
                    {t(`${i18nBase}.income_amount`)}
                  </SitelineText>
                  <div>
                    <SitelineText
                      variant="body1"
                      endIcon={
                        <Tooltip title={t(`${i18nBase}.amount_info`)}>
                          <InfoOutlinedIcon className="tooltip" />
                        </Tooltip>
                      }
                    >
                      <DollarNumberFormat value={incomeAmount} />
                    </SitelineText>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          {!calculatesTaxes && (
            <div className="row">
              <FormControlLabel
                control={
                  <SitelineCheckbox
                    name={t(`${i18nBase}.record_taxes`)}
                    value={invoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX}
                    checked={invoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX}
                    onChange={(ev, checked) =>
                      setInvoiceTaxType(
                        checked ? Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX : null
                      )
                    }
                  />
                }
                label={t(`${i18nBase}.record_taxes`)}
              />
            </div>
          )}
          <Collapse in={invoiceTaxType !== null}>
            <div className="row">
              <div className="leftSection">
                <div className="setting">
                  <SitelineText variant="secondary" bold color="grey70">
                    {t(`${i18nBase}.tax_account`)}
                  </SitelineText>
                  {invoiceTaxType === Sage100InvoiceTaxType.TAX_DISTRICT && (
                    <SitelineText variant="body1" className={classes.readOnlyAccount}>
                      {selectedIncomeAccount ? getOptionLabel(selectedIncomeAccount) : '—'}
                    </SitelineText>
                  )}
                  {invoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX && (
                    <Autocomplete
                      value={selectedTaxAccount}
                      fullWidth
                      style={{ width: SECTION_WIDTH }}
                      freeSolo={false}
                      loading={loadingAccounts}
                      options={accounts}
                      autoHighlight
                      openOnFocus
                      onChange={(ev, option) => {
                        if (!option) {
                          setTaxAccount(null)
                          return
                        }
                        setTaxAccount({
                          accountId: option.integrationAccountId,
                          parentAccountId: option.parentId ?? undefined,
                        })
                      }}
                      className={classes.autocomplete}
                      renderInput={(params) => {
                        return (
                          <TextField
                            variant="outlined"
                            placeholder={t(`${i18nBase}.search_accounts`)}
                            {...params}
                          />
                        )
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.integrationAccountId}>
                          <SitelineText variant="secondary">{getOptionLabel(option)}</SitelineText>
                        </li>
                      )}
                      getOptionLabel={getOptionLabel}
                    />
                  )}
                </div>
              </div>
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.tax_amount`)}
                </SitelineText>
                <div className={classes.taxAmount}>
                  <SitelineText variant="body1">
                    <DollarNumberFormat value={taxAmount} />
                  </SitelineText>
                </div>
              </div>
            </div>
          </Collapse>
          <Collapse in={invoiceTaxType === Sage100InvoiceTaxType.SITELINE_SOV_POST_TAX}>
            <div className="row">
              <div className="leftSection">
                <div className="setting">
                  <SitelineText variant="secondary" bold color="grey70">
                    {t(`${i18nBase}.tax_line_items`)}
                  </SitelineText>
                  <MultiFilterDropdownMenu
                    options={taxLineItems}
                    selectedOptions={taxLineItemIds}
                    onChange={(newIds) => setTaxLineItemIds(newIds ?? [])}
                    title={t(`${i18nBase}.num_line_items`, { count: taxLineItemIds.length })}
                    useSingleButton
                    includeAllMenuItem={false}
                    maxWidth={SECTION_WIDTH}
                    searchProps={{
                      placeholder: t(`${i18nBase}.search_sov`),
                      emptySearchText: t(`${i18nBase}.no_line_items`),
                    }}
                  />
                </div>
              </div>
            </div>
          </Collapse>
          {hasTaxOnRetentionError && (
            <SitelineAlert severity="warning">
              {t(`${i18nBase}.errors.tax_on_retention`)}
            </SitelineAlert>
          )}
        </div>
      )}
    </SitelineDialog>
  )
}
