import _ from 'lodash'
import { toReferences } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyProperties,
  GetContractForFormsQuery,
  Query,
  useCreateCompanyContactMutation,
} from '../graphql/apollo-operations'

/** Hook returns a callback for creating a new company contact and updating the cache */
export function useCreateCompanyContact(companyId: string) {
  return useCreateCompanyContactMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          companyContacts(existingRefs, { toReference, storeFieldName }) {
            // Don't modify the cached query for another company's contacts. Note this is slightly
            // hacky because Apollo doesn't have a cleaner way to identify root query cache fields
            // by their variables, per:
            // https://github.com/apollographql/apollo-client/issues/7129#issuecomment-1018869198
            if (!storeFieldName.includes(companyId)) {
              return existingRefs
            }
            const newRef = cache.writeFragment({
              data: data.createCompanyContact,
              fragment: fragments.companyContact,
              fragmentName: 'CompanyContactProperties',
            })
            const refs = toReferences(existingRefs, toReference)
            return _.compact([...refs, newRef])
          },
        },
      })
    },
  })
}

/** Creates an empty contract, used to setting initial state on contact forms */
export function makeEmptyContact(company?: Pick<CompanyProperties, 'id' | 'name'>) {
  return {
    id: uuidv4(),
    email: '',
    fullName: '',
    companyName: company?.name ?? '',
    companyId: company?.id ?? '',
    phoneNumber: null,
    jobTitle: null,
  }
}

export type ContractWithGc = Pick<GetContractForFormsQuery['contractByProjectId'], 'project'>

/** Returns a boolean indicating whether or not two contracts have the same gc */
export function contractsHaveSameGc(
  firstContract: ContractWithGc | undefined,
  secondContract: ContractWithGc | undefined
) {
  return !!(
    firstContract?.project.generalContractor &&
    secondContract?.project.generalContractor &&
    firstContract.project.generalContractor.company.id ===
      secondContract.project.generalContractor.company.id
  )
}
