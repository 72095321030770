import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { Dispatch, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dollarsToCents, formatCentsToDollars } from 'siteline-common-all'
import { SitelineTooltip, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { launchImmediateStateUpdate } from '../../util/State'
import { useSitelineConfirmation } from '../SitelineConfirmation'
import { cellTextColor } from '../SitelineTable.lib'
import {
  SpreadsheetCellLeftContent,
  SpreadsheetDataType,
  SpreadsheetElement,
  SpreadsheetInputDataType,
  SpreadsheetValue,
  ValidationFunction,
  numericDataTypes,
} from './Spreadsheet.lib'
import { SpreadsheetCellInput } from './SpreadsheetCellInput'
import { useSpreadsheetContext } from './SpreadsheetContext'
import { SpreadsheetAction } from './SpreadsheetReducer'
import { CellWidthUpdate } from './SpreadsheetSizingReducer'

const useStyles = makeStylesFast((theme: Theme) => ({
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
  },
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
  },
  cellText: {
    // Match secondary style on SitelineText
    ...theme.typography.body2,
    color: colors.grey90,
    '&.grey50': {
      color: colors.grey50,
    },
    '&.isBold': {
      fontWeight: 600,
    },
    '&.isStrikethrough': {
      textDecoration: 'line-through',
    },
    '&.isItalic': {
      fontStyle: 'italic',
    },
  },
}))

interface BaseSpreadsheetCellProps {
  rowId: string
  value: SpreadsheetValue
  columnId: string
  dataType: SpreadsheetInputDataType
  onCellWidthChange: (cellWidth: CellWidthUpdate) => void
  dispatch: Dispatch<SpreadsheetAction>
  isEditing: boolean
  validate?: ValidationFunction
  isGroupHeaderRow?: boolean
  initialValue?: string
  bold?: boolean
  strikethrough?: boolean
  italic?: boolean
  className?: string
  fixedDecimals?: number
  color?: 'grey90' | 'grey50' | 'grey70' | 'red50'
  textAlign?: 'left' | 'center' | 'right'
  leftContent?: SpreadsheetCellLeftContent
  rightContent?: SpreadsheetElement
  tooltipTitle?: string
  onBeforeSave?: (params: {
    onSave: () => void
    onCancel: () => void
    rowId: string
    columnId: string
    fromValue: SpreadsheetValue
    toValue: SpreadsheetValue
  }) => void
  isGrowColumn: boolean
  characterLimit?: number
  maxDecimals?: number
}

interface SpreadsheetInputCellProps extends BaseSpreadsheetCellProps {
  dataType: Exclude<SpreadsheetInputDataType, SpreadsheetDataType.DATE>
}

interface SpreadsheetDateCellProps extends BaseSpreadsheetCellProps {
  dataType: SpreadsheetDataType.DATE
  timeZone: string
}

type SpreadsheetCellProps = SpreadsheetInputCellProps | SpreadsheetDateCellProps

function formatValue(
  value: string,
  dataType: SpreadsheetDataType,
  {
    timeZone,
    fixedDecimals,
    maxDecimals = 2,
  }: { timeZone?: string; fixedDecimals?: number; maxDecimals?: number }
) {
  if (numericDataTypes.includes(dataType)) {
    const numValue = Number(value)
    if (isNaN(numValue)) {
      // If something goes wrong, just fall through and render the value as a string
    } else {
      if (dataType === SpreadsheetDataType.DOLLAR) {
        const centsValue = dollarsToCents(numValue, maxDecimals - 2)
        return formatCentsToDollars(centsValue, true)
      } else if (dataType === SpreadsheetDataType.NUMBER) {
        const valueWithFixedDecimals = _.isNumber(fixedDecimals)
          ? numValue.toFixed(fixedDecimals)
          : numValue
        return valueWithFixedDecimals.toLocaleString()
      } else if (dataType === SpreadsheetDataType.PERCENT) {
        return `${numValue}%`
      }
    }
  } else if (dataType === SpreadsheetDataType.DATE) {
    const date = moment.tz(value, timeZone ?? '')
    if (!date.isValid()) {
      // If unable to parse, just fall through and render the value as a string
    } else {
      // Display the date in MM/DD/YYYY format
      return date.format('MM/DD/YYYY')
    }
  }
  return value.toString()
}

function spreadsheetValueToString(value: SpreadsheetValue, dataType: SpreadsheetDataType) {
  if (dataType === SpreadsheetDataType.DATE && value) {
    const momentValue = value as Moment
    try {
      return momentValue.toISOString()
    } catch {
      // If something goes wrong, it's likely the spreadsheet is transitioning to new content and
      // columns; fallthrough to simply rendering the value as a string until the data is in sync
    }
  }
  if (numericDataTypes.includes(dataType) && Number(value) === 0) {
    // When a cell has a zero in it, initialize the input with the empty string so the user doesn't
    // have to remove the zero before typing in a value
    return ''
  }
  return value.toString()
}

/** A single cell in a spreadsheet, with its value and optionally an editable input */
export const SpreadsheetCell = memo(function SpreadsheetCell(props: SpreadsheetCellProps) {
  const {
    rowId,
    value,
    columnId,
    dataType,
    onCellWidthChange,
    dispatch,
    isEditing,
    validate,
    isGroupHeaderRow,
    initialValue,
    bold,
    strikethrough,
    italic,
    className,
    color,
    fixedDecimals,
    textAlign,
    leftContent,
    rightContent,
    tooltipTitle,
    onBeforeSave,
    isGrowColumn,
    characterLimit,
    maxDecimals,
  } = props
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()
  const { t } = useTranslation()
  const { pauseFocus, resumeFocus } = useSpreadsheetContext()
  const initialCurrentValue = useMemo(
    () => spreadsheetValueToString(value, dataType),
    [value, dataType]
  )
  const [currentValue, setCurrentValue] = useState<string>(initialCurrentValue)

  // Update the current value if a new value is passed in
  useEffect(() => {
    setCurrentValue(initialCurrentValue)
  }, [initialCurrentValue])

  // Update the current value if a new initial value is provided
  useEffect(() => {
    if (initialValue) {
      if (numericDataTypes.includes(dataType) && !['-', '.'].includes(initialValue)) {
        const numValue = Number(initialValue)
        if (isNaN(numValue)) {
          snackbar.showError(t('common.spreadsheet.not_a_number'))
          return
        }
      }
      // Don't pass values from the outer input to the inner input if we're in a date cell
      // This prevents us from converting text entry values to dates based on single characters
      // (e.g., "1" => "12/31/2001").
      if (dataType !== SpreadsheetDataType.DATE) {
        launchImmediateStateUpdate(() => setCurrentValue(initialValue))
      }
    }
  }, [initialValue, dataType, snackbar, t])

  const handleReset = useCallback(
    () => setCurrentValue(spreadsheetValueToString(value, dataType)),
    [value, dataType]
  )

  const handleEditValue = useCallback(
    (toValue: SpreadsheetValue, onAfterSave?: () => void) => {
      if (typeof toValue === 'string') {
        toValue = toValue.trim()
      }
      setCurrentValue(spreadsheetValueToString(toValue, dataType))
      if (onBeforeSave) {
        onBeforeSave({
          onSave: () => {
            dispatch({
              type: 'EDITED_CELL',
              toValue,
            })
            if (onAfterSave) {
              onAfterSave()
            }
          },
          onCancel: () => {
            handleReset()
            dispatch({ type: 'STOP_EDITING' })
          },
          rowId,
          fromValue: value,
          toValue,
          columnId,
        })
        return
      }
      dispatch({
        type: 'EDITED_CELL',
        toValue,
      })
      if (onAfterSave) {
        onAfterSave()
      }
    },
    [value, dataType, dispatch, handleReset, onBeforeSave, rowId, columnId]
  )

  const timeZone = props.dataType === SpreadsheetDataType.DATE ? props.timeZone : ''
  const handleSave = useCallback(
    (saveValue?: string, onAfterSave?: () => void) => {
      let toValue: SpreadsheetValue = saveValue ?? currentValue
      if (numericDataTypes.includes(dataType)) {
        toValue = Number(toValue)
        if (isNaN(toValue)) {
          snackbar.showError(t('common.spreadsheet.not_a_number'))
          handleReset()
          dispatch({ type: 'STOP_EDITING' })
          return
        }
      } else if (dataType === SpreadsheetDataType.DATE) {
        toValue = moment.tz(toValue, timeZone)
        if (!toValue.isValid()) {
          snackbar.showError(t('common.spreadsheet.not_a_date'))
          handleReset()
          dispatch({ type: 'STOP_EDITING' })
          return
        }
      }
      if (validate) {
        const validated = validate(toValue)
        if (validated !== null) {
          switch (validated.type) {
            case 'error': {
              const { message } = validated
              // Wrap the error snackbar in a timeout so it shows up even when the save is triggered
              // by a click event. Otherwise, the blur event triggers before the click event, and the
              // click is caught by the snackbar as a clickaway, closing the snackbar immediately.
              setTimeout(() => {
                snackbar.showError(message)
              }, 500)
              handleReset()
              dispatch({ type: 'STOP_EDITING' })
              return
            }
            case 'confirm': {
              const { title, details, confirmationType, confirmLabel } = validated
              pauseFocus()
              confirm({
                title,
                details,
                confirmationType,
                confirmLabel,
                callback: (confirmed) => {
                  resumeFocus()
                  if (confirmed) {
                    handleEditValue(toValue, onAfterSave)
                  } else {
                    handleReset()
                    dispatch({ type: 'STOP_EDITING' })
                  }
                },
              })
              return
            }
            case 'override': {
              toValue = validated.value
              break
            }
          }
        }
      }
      handleEditValue(toValue, onAfterSave)
    },
    [
      currentValue,
      dataType,
      snackbar,
      dispatch,
      handleReset,
      validate,
      t,
      timeZone,
      handleEditValue,
      confirm,
      pauseFocus,
      resumeFocus,
    ]
  )

  const lastWidth = useRef<number>()
  const cellSizing = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        const size = element.getBoundingClientRect()
        if (size.width !== lastWidth.current) {
          onCellWidthChange({ rowId, columnId, width: size.width })
          lastWidth.current = size.width
        }
      }
    },
    [columnId, onCellWidthChange, rowId]
  )

  const handleValueChange = useCallback((value: string) => {
    launchImmediateStateUpdate(() => setCurrentValue(value))
  }, [])

  const content = (
    // Avoid using SitelineText here as a performance optimization
    <span
      className={clsx(classes.cellText, className, {
        isBold: isGroupHeaderRow || bold,
        isStrikethrough: strikethrough,
        isItalic: italic,
      })}
      style={{ color: cellTextColor(color) }}
    >
      {formatValue(currentValue, dataType, {
        ...(props.dataType === SpreadsheetDataType.DATE && { timeZone: props.timeZone }),
        fixedDecimals,
        maxDecimals,
      })}
    </span>
  )

  return (
    <>
      <div
        className={clsx({
          [classes.hidden]: isEditing,
          hasRightContent: rightContent !== undefined,
          hasLeftContent: leftContent !== undefined,
          isLeftContentRightAligned: leftContent !== undefined && leftContent.align === 'right',
        })}
      >
        {leftContent && <div className={classes.leftContent}>{leftContent.content}</div>}
        <SitelineTooltip title={tooltipTitle} placement="top">
          {content}
        </SitelineTooltip>
        {rightContent && <div>{rightContent.content}</div>}
      </div>
      {/* Hidden content for sizing the cell */}
      {!isGrowColumn && (
        <div
          // Make sure we cell sizing function is called any time the option changes so the
          // spreadsheet sizing can adjust
          key={currentValue}
          ref={cellSizing}
          className={classes.hidden}
        >
          {content}
        </div>
      )}
      {isEditing && (
        <SpreadsheetCellInput
          value={currentValue}
          onValueChange={handleValueChange}
          onSave={handleSave}
          onReset={handleReset}
          dispatch={dispatch}
          bold={isGroupHeaderRow || bold}
          color={color}
          textAlign={textAlign}
          characterLimit={characterLimit}
          maxDecimals={maxDecimals}
          {...(props.dataType === SpreadsheetDataType.DATE
            ? { dataType: props.dataType, timeZone: props.timeZone }
            : { dataType: props.dataType })}
        />
      )}
    </>
  )
})
