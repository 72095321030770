import { Button, Menu, MenuItem, PopoverOrigin, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, fuseSearch, makeStylesFast } from 'siteline-common-web'
import { clampToLines } from 'siteline-common-web/src/utils/CSS'
import { AnimatedArrowDropdownIcon } from './AnimatedArrowDropdownIcon'
import { FILTER_DROPDOWN_BUTTON_CLASS } from './FilterDropdownMenu'
import { SitelineCheckbox } from './SitelineCheckbox'

const useStyles = makeStylesFast((theme: Theme) => ({
  button: {
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .heading': {
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
    '& .clampWidth': {
      textAlign: 'left',
      maxWidth: 200,
      ...clampToLines(1),
    },
  },
  singleButton: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: colors.white,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
      color: colors.grey50,
    },
  },
  menu: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&.hasSearch:': {
      // Set a max height so the menu doesn't jump around as its size changes
      // while searching, and instead always stays anchored to the button
      maxHeight: '70%',
    },
    '& .MuiMenuItem-root': {
      maxWidth: 'none',
      // Override MUI's menu item padding of 16px !important
      padding: `${theme.spacing(1, 1.5, 1, 1)} !important`,
    },
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      '& .checkbox': {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
  search: {
    '&.MuiMenuItem-root.MuiButtonBase-root': {
      padding: `${theme.spacing(1, 1.5)} !important`,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      '& input': {
        fontSize: theme.typography.body2.fontSize,
        backgroundColor: colors.grey20,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
  noResults: {
    '&.MuiMenuItem-root.MuiButtonBase-root': {
      padding: `${theme.spacing(1, 2, 2, 2)} !important`,
      fontSize: theme.typography.body2.fontSize,
      color: colors.grey50,
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

const ANCHOR_ORIGIN: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }
const TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
const DEFAULT_MAX_WIDTH = 200

type SearchProps = {
  placeholder: string
  emptySearchText: string
}

type FilterOption<T> = {
  label: string
  value: T
}

type FilterProps<T> = {
  options: FilterOption<T>[]
  selectedOptions: T[] | null
  onChange: (update: T[] | null) => void
  title: string
  label?: string
  maxWidth?: number
  searchProps?: SearchProps
  className?: string
  startIcon?: ReactNode
  // If true, adds an "All" option that sets the value to null, when selected. By default true.
  includeAllMenuItem?: boolean
  // If true, shows a single button (rendered similarly to a Select dropdown) instead of two
  // buttons with a title. By default false.
  useSingleButton?: boolean
}

/** Same design-wise as the FilterDropdownMenu component, but allows for multiple selections */
export function MultiFilterDropdownMenu<T>({
  options,
  selectedOptions,
  onChange,
  title,
  label,
  searchProps,
  className,
  startIcon,
  maxWidth = DEFAULT_MAX_WIDTH,
  includeAllMenuItem = true,
  useSingleButton = false,
}: FilterProps<T>) {
  const { t } = useTranslation()
  const classes = useStyles()

  const searchInputRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const selectedOptionsSet = useMemo(() => new Set(selectedOptions), [selectedOptions])

  const dropdownLabel = useMemo(() => {
    if (selectedOptions === null) {
      return t('common.all')
    }
    if (label !== undefined) {
      return label
    }
    const selectedOptionLabels: string[] = []
    options.forEach((option) => {
      if (selectedOptionsSet.has(option.value)) {
        selectedOptionLabels.push(option.label)
      }
    })
    return selectedOptionLabels.join(', ')
  }, [label, options, selectedOptions, selectedOptionsSet, t])

  const searchResults = useMemo(
    () => fuseSearch(options, searchQuery, ['label'], { ignoreLocation: true }),
    [options, searchQuery]
  )

  const isEmptySearch = searchProps && searchResults.length === 0

  const menuItemStyle = useMemo(() => ({ maxWidth }), [maxWidth])

  const handleOptionToggle = useCallback(
    (value: T) => {
      const currentOptions = [...(selectedOptions || [])]
      const indexOfSelected = currentOptions.indexOf(value)
      if (indexOfSelected === -1) {
        onChange([...currentOptions, value])
      } else {
        currentOptions.splice(indexOfSelected, 1)
        onChange(currentOptions.length ? currentOptions : null)
      }
      searchInputRef.current?.focus()
    },
    [onChange, selectedOptions]
  )

  const handleAllClick = useCallback(() => {
    setAnchorEl(null)
    onChange(null)
  }, [onChange])

  const handleSearchOptionClick = useCallback(() => {
    searchInputRef.current?.focus()
  }, [])

  const handleSearch = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }, [])

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setAnchorEl(null)
    }
    event.stopPropagation()
  }, [])

  return (
    <>
      {!useSingleButton && (
        <Button
          variant="text"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={clsx(classes.button, className)}
        >
          <SitelineText variant="smallText" color="grey50" className="heading">
            {title}
          </SitelineText>
          <SitelineText
            variant="secondary"
            bold
            color={selectedOptions !== null ? 'grey70' : 'grey50'}
            endIcon={<AnimatedArrowDropdownIcon isMenuOpen={Boolean(anchorEl)} />}
            startIcon={startIcon}
          >
            <div className={clsx('clampWidth', FILTER_DROPDOWN_BUTTON_CLASS)} style={menuItemStyle}>
              {dropdownLabel}
            </div>
          </SitelineText>
        </Button>
      )}
      {useSingleButton && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={classes.singleButton}
          style={{ width: maxWidth }}
          endIcon={<AnimatedArrowDropdownIcon isMenuOpen={Boolean(anchorEl)} />}
        >
          <SitelineText variant="body1" color={selectedOptions?.length ? 'grey90' : 'grey50'}>
            {title}
          </SitelineText>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className={clsx(classes.menu, { hasSearch: searchProps !== undefined })}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        keepMounted={false}
        autoFocus={false}
      >
        {searchProps !== undefined && (
          <MenuItem className={classes.search} tabIndex={-1} onClick={handleSearchOptionClick}>
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              placeholder={searchProps.placeholder}
              value={searchQuery}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              inputRef={searchInputRef}
              tabIndex={0}
            />
          </MenuItem>
        )}
        {searchQuery === '' && includeAllMenuItem && (
          <MenuItem onClick={handleAllClick} tabIndex={0}>
            <SitelineCheckbox checked={selectedOptions === null} className="checkbox" />
            <SitelineText variant="body1" bold={selectedOptions === null}>
              {t('common.all')}
            </SitelineText>
          </MenuItem>
        )}
        {searchResults.map((option, index) => {
          const isSelected = selectedOptionsSet.has(option.value)
          const key = typeof option.value === 'string' ? option.value : index
          return (
            <MenuItem
              key={key}
              onClick={() => handleOptionToggle(option.value)}
              className="menuItem"
              tabIndex={0}
            >
              <SitelineCheckbox checked={isSelected} className="checkbox" />
              <SitelineText
                variant="body1"
                noWrap
                style={menuItemStyle}
                bold={selectedOptionsSet.has(option.value)}
              >
                {option.label}
              </SitelineText>
            </MenuItem>
          )
        })}
        {isEmptySearch && (
          <MenuItem className={classes.noResults} tabIndex={-1}>
            {searchProps.emptySearchText}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
