import ClearIcon from '@mui/icons-material/Clear'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { IconButton, InputBase, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, makeStylesFast } from 'siteline-common-web'
import { StoredFileType } from '../../../../common/graphql/apollo-operations'
import { PendingFile } from './FileDragUpload'

const THUMBNAIL_SIZE = 32

const useStyles = makeStylesFast((theme: Theme) => ({
  input: {
    borderRadius: theme.spacing(0.5),
    border: '1px solid',
    borderColor: colors.grey30,
    padding: theme.spacing(0.5, 1),
    fontSize: theme.typography.subtitle2.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.subtitle1.fontWeight,
    backgroundColor: colors.white,
    '& .MuiInputBase-input': {
      padding: theme.spacing(0),
    },
    '& .delete': {
      margin: theme.spacing(0, 0.5, 0, 1),
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
    '& span': {
      lineHeight: '27px', // Overriding SitelineText because of icons
    },
  },
  thumbnail: {
    width: THUMBNAIL_SIZE,
    minWidth: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    objectFit: 'cover',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

// File types that can be rendered as a thumbnail
export const THUMBNAIL_FILE_TYPES = [StoredFileType.JPEG, StoredFileType.PNG]

// Filenames should be truncated if they are longer than the max length, which should match
// the max name length enforced in `resolvers/pay-app-attachment.ts`.
export const MAX_FILENAME_LENGTH = 100
const INPUT_PROPS = { maxLength: MAX_FILENAME_LENGTH }

interface FileDragUploadInputProps {
  pendingFile: PendingFile
  onChange: ((pendingFile: PendingFile) => void) | null
  disabled?: boolean
  autoFocus?: boolean
  useRawFilename?: boolean
  onRemovePendingFile: (() => void) | null
}

/** An input field for file names accompanying the file drag/upload dropzone component */
export function FileDragUploadInput({
  pendingFile,
  onChange,
  disabled,
  autoFocus,
  useRawFilename,
  onRemovePendingFile,
}: FileDragUploadInputProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleFileNameChanged = useCallback(
    (newName: string) => onChange && onChange({ ...pendingFile, name: newName }),
    [onChange, pendingFile]
  )

  return (
    <InputBase
      autoFocus={autoFocus}
      fullWidth
      startAdornment={
        pendingFile.file && THUMBNAIL_FILE_TYPES.includes(pendingFile.type) ? (
          <img
            src={URL.createObjectURL(pendingFile.file)}
            alt={pendingFile.name}
            className={classes.thumbnail}
          />
        ) : (
          <div className={classes.thumbnail}>
            <DescriptionOutlinedIcon fontSize="small" />
          </div>
        )
      }
      endAdornment={
        onRemovePendingFile !== null && (
          <Tooltip title={t('common.actions.delete')} placement="top">
            <IconButton
              color="secondary"
              onClick={onRemovePendingFile}
              className="delete"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )
      }
      onBlur={(e) => handleFileNameChanged(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' && handleFileNameChanged(e.currentTarget.value)}
      defaultValue={useRawFilename ? (pendingFile.file?.name ?? '') : pendingFile.name}
      inputProps={INPUT_PROPS}
      className={classes.input}
      disabled={!onChange || disabled}
    />
  )
}
