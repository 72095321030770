import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, Menu, MenuItem } from '@mui/material'
import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BillingType, SitelineText, makeStylesFast } from 'siteline-common-web'
import { themeSpacing } from '../../../../common/themes/Main'
import { TaxesView } from '../InvoiceReducer'

const useStyles = makeStylesFast(() => ({
  button: {
    height: 24,
    '& .endIcon': {
      // Use a smaller margin to save space
      marginLeft: `${themeSpacing(0.5)}px !important`,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  menu: {
    '& .MuiSvgIcon-root': {
      opacity: 0,
      '&.active': {
        opacity: 1,
      },
    },
  },
}))

export const TAXES_VIEW_STORAGE_KEY = 'invoiceTaxesView'

interface TaxesViewToggleProps {
  taxesView: TaxesView
  onViewChange: (view: TaxesView) => void
  billingType: BillingType
}

/** Dropdown toggle for switching the taxes view on a pay app invoice */
export function TaxesViewToggle({ taxesView, onViewChange, billingType }: TaxesViewToggleProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLSpanElement>(null)

  const label = t(`projects.subcontractors.pay_app.invoice.headers.${taxesView}`)

  const openMenu = useCallback((evt: MouseEvent<HTMLSpanElement>) => {
    setMenuAnchorEl(evt.currentTarget)
  }, [])

  const handleClick = useCallback(
    (taxesView: TaxesView) => {
      onViewChange(taxesView)
      setMenuAnchorEl(null)
    },
    [onViewChange]
  )

  return (
    <>
      <Button variant="text" onClick={openMenu} className={classes.button}>
        <SitelineText
          variant="smallText"
          color="grey50"
          endIcon={<ArrowDropDownIcon fontSize="small" />}
        >
          {label}
        </SitelineText>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        className={classes.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => handleClick(TaxesView.TOTAL_POST_TAX)}
          selected={taxesView === TaxesView.TOTAL_POST_TAX}
        >
          {t('projects.subcontractors.pay_app.invoice.headers.TOTAL_POST_TAX')}
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(TaxesView.TAX_AMOUNT)}
          selected={taxesView === TaxesView.TAX_AMOUNT}
        >
          {t('projects.subcontractors.pay_app.invoice.headers.TAX_AMOUNT')}
        </MenuItem>
        {billingType !== BillingType.QUICK && (
          <MenuItem
            onClick={() => handleClick(TaxesView.TAX_RATE)}
            selected={taxesView === TaxesView.TAX_RATE}
          >
            {t('projects.subcontractors.pay_app.invoice.headers.TAX_RATE')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
