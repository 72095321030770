import { Menu, MenuItem } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const i18nBase = 'common.spreadsheet.context_menu'

export type ContextMenuAnchorPosition = { top: number; left: number }

export type ContextMenuLabels = {
  insertAbove?: string
  insertBelow?: string
}

interface SpreadsheetRowContextMenuProps {
  anchorPosition: ContextMenuAnchorPosition | null
  isOpen: boolean
  onClose: () => void
  onInsertRow: (direction: 'up' | 'down') => void
  menuLabels: ContextMenuLabels | undefined
}

/**
 * Context menu that is meant to be used by the `SpreadsheetRow` component, attached to right click events.
 * Currently contains options for "insert row above" and "insert row below".
 */
export function SpreadsheetRowContextMenu({
  anchorPosition,
  isOpen,
  onClose,
  onInsertRow,
  menuLabels,
}: SpreadsheetRowContextMenuProps) {
  const { t } = useTranslation()

  const handleAddRowAbove = useCallback(() => {
    onInsertRow('up')
    onClose()
  }, [onClose, onInsertRow])

  const handleAddRowBelow = useCallback(() => {
    onInsertRow('down')
    onClose()
  }, [onInsertRow, onClose])

  if (anchorPosition === null) {
    return null
  }

  return (
    <Menu
      open={isOpen}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      <MenuItem onClick={handleAddRowAbove}>
        {menuLabels?.insertAbove ?? t(`${i18nBase}.add_row_above`)}
      </MenuItem>
      <MenuItem onClick={handleAddRowBelow}>
        {menuLabels?.insertBelow ?? t(`${i18nBase}.add_row_below`)}
      </MenuItem>
    </Menu>
  )
}
