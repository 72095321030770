import { colors } from 'siteline-common-web'

interface MailtoProps {
  email: string
  subject?: string
  body?: string
}

export function Mailto({ email, subject, body, ...props }: React.PropsWithChildren<MailtoProps>) {
  return (
    <a
      style={{ textDecoration: 'none', color: colors.blue50 }}
      href={`mailto:${email}?subject=${subject ? encodeURIComponent(subject) : ''}&body=${
        body ? encodeURIComponent(body) : ''
      }`}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  )
}
