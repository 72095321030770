import { Button, DialogContentText } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { colors, makeStylesFast } from 'siteline-common-web'
import { EditingSovLineItemGroup } from '../util/ManageSov'
import { LineItemGroup } from '../util/RateTable'
import { SitelineDialog } from './SitelineDialog'

const useStyles = makeStylesFast(() => ({
  root: {
    whiteSpace: 'pre-wrap',
    '& .buttons > .MuiButton-root:last-child': {
      backgroundColor: colors.red50,
    },
  },
  bold: { fontWeight: 'bold' },
}))

interface DeleteGroupConfirmationProps {
  open: boolean
  onCancel: () => void
  onConfirm: (withLineItems: boolean) => void
  group: EditingSovLineItemGroup | LineItemGroup
}

const i18nBase = 'projects.subcontractors.sov.delete_dialog'

/** Confirmation for deleting a group and selecting whether or not to delete line items */
export function DeleteGroupConfirmation({
  open,
  onCancel,
  onConfirm,
  group,
}: DeleteGroupConfirmationProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const groupName = group.name

  let description: ReactNode = t(
    'projects.subcontractors.sov.delete_dialog.group_description_untitled'
  )
  if (groupName) {
    description = (
      <Trans
        i18nKey={'projects.subcontractors.sov.delete_dialog.group_description'}
        components={{ strong: <span className={classes.bold} /> }}
        values={{
          name: groupName,
        }}
      />
    )
  }

  return (
    <SitelineDialog
      disableBackdrop
      disableEscapeKey
      maxWidth="sm"
      open={open}
      title={t('projects.subcontractors.sov.delete_dialog.group_title')}
      className={clsx(classes.root, 'delete')}
      onClose={onCancel}
      onSubmit={() => onConfirm(true)}
      submitLabel={t(`${i18nBase}.delete_line_items`)}
      additionalButton={
        <Button variant="outlined" color="secondary" onClick={() => onConfirm(false)}>
          {t(`${i18nBase}.delete_header_only`)}
        </Button>
      }
    >
      <DialogContentText>{description}</DialogContentText>
    </SitelineDialog>
  )
}
