import { Card, CardProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { PropsWithChildren, forwardRef } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    padding: theme.spacing(3, 4),
    backgroundColor: colors.white,
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${colors.grey20}`,
    transition: theme.transitions.create('border-color', { duration: 100 }),
    '&.isClickable': {
      cursor: 'pointer',
      '&.blueOnHover:hover': {
        borderColor: colors.blue40,
        // Components can use these classes to add a hover effect
        '& .cardTitle, & .cardImage': {
          color: colors.blue50,
          '& .MuiSvgIcon-root': {
            color: colors.blue50,
          },
        },
      },
    },
    '&.disabled': {
      cursor: 'default',
    },
  },
}))

type SitelineCardProps = CardProps & {
  disabled?: boolean
  blueBorderOnHover?: boolean
}

export const SitelineCard = forwardRef<HTMLDivElement, PropsWithChildren<SitelineCardProps>>(
  function SitelineCard(props, ref) {
    const classes = useStyles()
    const { children, blueBorderOnHover, className, onClick, ...rest } = props

    return (
      <Card
        ref={ref}
        elevation={0}
        className={clsx(classes.card, className, {
          isClickable: onClick !== undefined && !props.disabled,
          disabled: props.disabled,
          blueOnHover: blueBorderOnHover !== false,
        })}
        onClick={!props.disabled ? onClick : undefined}
        {...rest}
      >
        {children}
      </Card>
    )
  }
)
