import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

/** Number format component that forwards a ref if needed, e.g. to wrap in a Tooltip */
export const NumericFormatWithForwardRef = forwardRef<
  typeof NumericFormat<number>,
  NumericFormatProps
>(function NumericFormatWithForwardRef(props, ref) {
  return <NumericFormat getInputRef={ref} {...props} />
})

export const FormNumberFormat = ({ value }: { value: number }) => {
  // Divide value by 100 to convert from integer cents to float dollars
  return (
    <NumericFormat
      value={value / 100}
      decimalScale={2}
      fixedDecimalScale
      displayType={'text'}
      thousandSeparator={true}
      renderText={(input) => {
        const isNegative = value < 0
        return isNegative ? '(' + input.substring(1) + ')' : input
      }}
    />
  )
}

export const DollarNumberFormat = ({ value }: { value: number }) => {
  // Divide value by 100 to convert from integer cents to float dollars, prefix with $ sign
  return (
    <NumericFormat
      value={value / 100}
      decimalScale={2}
      fixedDecimalScale
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      renderText={(input) => {
        const isNegative = value < 0
        return isNegative ? '(' + input.substring(1) + ')' : input
      }}
    />
  )
}

export const DollarNumberNoCentsFormat = ({ value }: { value: number }) => {
  // Figure out if this number is negative or not. If it is, make sure we multiple the end result
  // by a -1 so that we have a negative value. This is because the Math.floor() function does not
  // work the way we expect it to for negative values.
  //
  // Example: Math.floor(-4.5) is -5. We need the value to be -4, so we take the floor of the
  // absolute value and then apply the negative multiplier afterwards.
  const negativeMultiplier = value < 0 ? -1 : 1
  // Divide value by 100 to convert from integer cents to float dollars
  // Then take the floor so it doesn't round up. Also account for negative numbers.
  const valueInDollars = Math.floor(Math.abs(value) / 100) * negativeMultiplier
  return (
    <NumericFormat
      value={valueInDollars}
      decimalScale={0}
      fixedDecimalScale
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      renderText={(input) => {
        const isNegative = value < 0
        return isNegative ? '(' + input.substring(1) + ')' : input
      }}
    />
  )
}

export const PercentNumberFormat = ({
  value,
  decimalScale = 2,
}: {
  value: number
  decimalScale?: number
}) => {
  return (
    <NumericFormat
      value={value}
      decimalScale={decimalScale}
      fixedDecimalScale
      displayType={'text'}
      suffix={'%'}
    />
  )
}
