import { integrationTypes } from 'siteline-common-all'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { UseWriteSyncStatus, isWriteSyncInProgress } from '../../../common/util/Integration'
import { WriteSyncDeferredScreen } from './WriteSyncDeferredScreen'
import { WriteSyncFailureScreen } from './WriteSyncFailureScreen'
import { WriteSyncInProgress } from './WriteSyncInProgressScreen'
import { WriteSyncQueuedInHh2 } from './WriteSyncQueuedInHh2'
import { WriteSyncStillRunningScreen } from './WriteSyncStillRunningScreen'
import { WriteSyncSuccessScreen } from './WriteSyncSuccessScreen'

type WriteSyncDialogContentProps = {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload
  status: UseWriteSyncStatus
  onClose: () => void
  onBack?: () => void
  onSyncAgain: () => void
}

/**
 * Content to display while a sync is running and after it is complete.
 * It displays its content based on the status of the sync.
 * This is used by the regular sync dialog, as well as the sage100/sage300 modals which encapsulate
 * content differently.
 */
export function WriteSyncDialogContent({
  integration,
  projectId,
  status,
  payload,
  onClose,
  onBack,
  onSyncAgain,
}: WriteSyncDialogContentProps) {
  return (
    <>
      {/* Sync in progress */}
      {isWriteSyncInProgress(status) && (
        <WriteSyncInProgress integration={integration} payload={payload} />
      )}

      {/* Sync failed in the integrations service */}
      {status.type === 'syncError' && (
        <WriteSyncFailureScreen
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          onBack={onBack}
          payload={payload}
          operation={status.operation}
          error={null}
          onSyncAgain={onSyncAgain}
        />
      )}

      {/* Sync failed because of API or network */}
      {status.type === 'internalError' && (
        <WriteSyncFailureScreen
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          payload={payload}
          operation={null}
          error={status.error}
          onSyncAgain={onSyncAgain}
        />
      )}

      {/* Sync has been deferred for processing at a later date */}
      {status.type === 'deferred' && (
        <WriteSyncDeferredScreen
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          payload={payload}
        />
      )}

      {/* Sync was successful */}
      {status.type === 'success' && (
        <WriteSyncSuccessScreen
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          payload={payload}
          result={status.operation.result}
        />
      )}

      {/* Sync was still running after 1min */}
      {status.type === 'stillRunningAfterClientTimeout' && (
        <WriteSyncStillRunningScreen
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          payload={payload}
        />
      )}

      {/* Invoice is queued for export in hh2 but not processed yet */}
      {status.type === 'queuedInHh2' && (
        <WriteSyncQueuedInHh2
          integration={integration}
          projectId={projectId}
          onClose={onClose}
          payload={payload}
          result={status.operation.result}
        />
      )}
    </>
  )
}
