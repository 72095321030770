import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { colors, SitelineText } from 'siteline-common-web'
import NailIcon from '../../assets/icons/nail.svg'
import SitelineLogo from '../../assets/images/logo/white-text.svg'

const Styled404 = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: colors.blue90,
  '& .nails': {
    backgroundImage: `url(${NailIcon})`,
    backgroundRepeat: 'repeat-y',
    position: 'fixed',
    left: 0,
    height: '100vh',
    width: 52,
    margin: theme.spacing(4),
    '&.second': {
      marginLeft: theme.spacing(12),
      top: theme.spacing(2),
    },
  },
  '& .logo': {
    marginBottom: theme.spacing(7),
    width: '155px',
  },
  '& .heading': {
    color: colors.white,
    fontSize: 36,
    lineHeight: '32px',
    marginBottom: theme.spacing(5),
  },
}))

export function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onCancel = () => navigate({ to: '/' })

  return (
    <Styled404>
      <div className="nails first" />
      <div className="nails second" />
      <div>
        <img src={SitelineLogo} alt="Siteline" className="logo" />
        <SitelineText variant="h1" bold className="heading">
          {t('404.not_found')}
        </SitelineText>
        <Button variant="contained" onClick={onCancel}>
          {t('404.return_home')}
        </Button>
      </div>
    </Styled404>
  )
}
