import moment from 'moment-timezone'
import { PayAppForSorting } from 'siteline-common-all'

/**
 * Common util function to get the "month" of a payApp. For consistency, we will always use the
 * billingEnd date of a pay app.
 */
export function getPayAppMonth(
  payApp: Pick<PayAppForSorting, 'billingEnd'>,
  timeZone: string
): moment.Moment {
  return moment.tz(payApp.billingEnd, timeZone)
}
