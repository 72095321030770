import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { makeStylesFast } from 'siteline-common-web'
import { ContractForProjectHome } from '../home/ProjectHome'
import { Forms } from './Forms'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

const noop = () => undefined

interface FormsWithLoaderProps {
  contract?: ContractForProjectHome
}

/**
 * If contract is undefined, returns a loader state with the settings section title.
 * Otherwise return the Forms component
 */
export function FormsWithLoader({ contract }: FormsWithLoaderProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  if (contract === undefined) {
    return (
      <div className={classes.root}>
        <SettingsHeader
          title={t(`${i18nBase}.title`)}
          canEdit={false}
          isEditing={false}
          setIsEditing={noop}
          bulkSaveProps={null}
        />
        <SettingsRow label="" isLoading value="" editingValue="" isEditing={false} />
      </div>
    )
  }

  return <Forms contract={contract} />
}
