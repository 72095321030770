import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Menu, MenuItem, MenuProps } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import { useCallback } from 'react'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast(() => ({
  nestedMenuItem: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        padding: 0,
      },
    },
  },
}))

const topLevelMenuProps: Partial<MenuProps> = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
}
const leftNestedItemMenuProps: Partial<MenuProps> = {
  anchorOrigin: { vertical: 'top', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
}
const rightNestedItemMenuProps: Partial<MenuProps> = {
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
}

type BaseDropdownMenuItemType = {
  label: string
}

type DropdownLeafMenuItemType = {
  onClick: () => void
} & BaseDropdownMenuItemType

type DropdownNestedMenuItemType = {
  items: NestedDropdownMenuItemType[]
  direction: 'left' | 'right'
} & BaseDropdownMenuItemType

export type NestedDropdownMenuItemType = DropdownLeafMenuItemType | DropdownNestedMenuItemType

function DropdownMenuItem({
  item,
  onCloseMenu,
}: {
  item: DropdownLeafMenuItemType
  onCloseMenu: () => void
}) {
  const handleClick = useCallback(() => {
    onCloseMenu()
    item.onClick()
  }, [item, onCloseMenu])

  return <MenuItem onClick={handleClick}>{item.label}</MenuItem>
}

function DropdownNestedMenuItem({
  item,
  open,
  onCloseMenu,
}: {
  item: DropdownNestedMenuItemType
  open: boolean
  onCloseMenu: () => void
}) {
  const classes = useStyles()

  return (
    <NestedMenuItem
      label={item.label}
      rightIcon={<ArrowRightIcon />}
      parentMenuOpen={open}
      className={classes.nestedMenuItem}
      MenuProps={item.direction === 'left' ? leftNestedItemMenuProps : rightNestedItemMenuProps}
    >
      {item.items.map((item, index) => (
        <DropdownItem key={index} item={item} open={open} onCloseMenu={onCloseMenu} />
      ))}
    </NestedMenuItem>
  )
}

function DropdownItem({
  item,
  open,
  onCloseMenu,
}: {
  item: NestedDropdownMenuItemType
  open: boolean
  onCloseMenu: () => void
}) {
  return 'items' in item ? (
    <DropdownNestedMenuItem item={item} open={open} onCloseMenu={onCloseMenu} />
  ) : (
    <DropdownMenuItem item={item} onCloseMenu={onCloseMenu} />
  )
}

interface NestedDropdownMenuProps {
  items: NestedDropdownMenuItemType[]
  onClose: () => void
  anchorEl: HTMLElement | null
}

/**
 * Shows multiple items in a dropdown. For nesting: each "item" in the `items` props optionally takes an array of `items`.
 * Note that just like the MUI `Menu` component, the consumer must add a button that handles opening & closing the menu,
 * and is responsible for controlling the open/closed state of the menu.
 **/
export function NestedDropdownMenu({ items, onClose, anchorEl }: NestedDropdownMenuProps) {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={topLevelMenuProps.anchorOrigin}
        transformOrigin={topLevelMenuProps.transformOrigin}
      >
        {items.map((item, index) => (
          <DropdownItem key={index} item={item} open={Boolean(anchorEl)} onCloseMenu={onClose} />
        ))}
      </Menu>
    </>
  )
}
