import { Dialog, DialogContent } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect } from 'react'
import { integrationTypes } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { isWriteSyncInProgress, useWriteSync } from '../../../common/util/Integration'
import { WriteSyncDialogContent } from './WriteSyncDialogContent'

const useStyles = makeStylesFast((theme: Theme) => ({
  syncCompleteContainer: {
    marginTop: theme.spacing(2),
  },
  loadingDialogContainer: {
    '&.MuiDialogContent-root': {
      padding: theme.spacing(6, 4, 4),
    },
  },
}))

type WriteSyncDialogProps = {
  open: boolean
  onClose: (syncSuccess: boolean) => void
  integration: MinimalIntegrationProperties
  payload: integrationTypes.WriteSyncPayload
  projectId: string
  closeOnError: boolean
}

/**
 * Dialog shown when trying to sync to an integration. Sage300CRE (and any others) may have their
 * own specific syncing dialogs, though.
 */
export function WriteSyncDialog({
  open,
  onClose,
  integration,
  projectId,
  payload,
  closeOnError,
}: WriteSyncDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const { sync, status } = useWriteSync({ integration })

  const handleClose = useCallback(() => {
    let isSuccessful: boolean
    switch (status.type) {
      case 'notCreated':
      case 'creating':
      case 'polling':
      case 'internalError':
      case 'syncError':
        isSuccessful = false
        break
      case 'success':
      case 'deferred':
      case 'queuedInHh2':
      case 'stillRunningAfterClientTimeout':
        isSuccessful = true
    }
    onClose(isSuccessful)
  }, [status, onClose])

  useEffect(() => {
    if (status.type === 'internalError') {
      if (closeOnError) {
        onClose(false)
      }
      snackbar.showError(status.error.message)
    }
  }, [closeOnError, onClose, snackbar, status])

  useEffect(() => {
    if (open) {
      sync(payload)
    }
  }, [open, payload, sync])

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && isWriteSyncInProgress(status)) {
          return
        }
        handleClose()
      }}
    >
      <DialogContent
        className={
          isWriteSyncInProgress(status)
            ? classes.loadingDialogContainer
            : classes.syncCompleteContainer
        }
      >
        <WriteSyncDialogContent
          integration={integration}
          projectId={projectId}
          onClose={handleClose}
          onSyncAgain={() => sync(payload)}
          payload={payload}
          status={status}
        />
      </DialogContent>
    </Dialog>
  )
}
