import _ from 'lodash'
import {
  DependencyList,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  MAX_EMAIL_MESSAGE_CHARACTERS,
  MAX_EMAIL_SUBJECT_CHARACTERS,
  isValidEmail,
  replaceAllWhitespaces,
} from 'siteline-common-all'
import { SitelineVariant, toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { SitelineDialog, SitelineDialogProps } from '../../../common/components/SitelineDialog'
import * as fragments from '../../../common/graphql/Fragments'
import {
  Query,
  useCreateCompanyContactMutation,
  useGetCompanyContactsQuery,
} from '../../../common/graphql/apollo-operations'
import { makeEmptyContact } from '../../../common/util/Contact'
import { findCloselyMatchingEmail, getEmailDomainsFromContacts } from '../../../common/util/Email'
import { trackGeneralContractorContactAdded } from '../../../common/util/MetricsTracking'
import { Contact, NewContactForm } from './NewContactForm'
import { SelectContactsForm } from './SelectContactsForm'
import { EmailContact, SendEmailDialogForm } from './SendEmailDialogForm'
import { SendEmailDialogRowProps } from './SendEmailDialogRow'

interface SendEmailDialogProps {
  open: boolean
  onClose: (fromButton: boolean) => void
  onSubmit?: (contents: { subject: string; message?: string }) => void

  title: ReactNode
  subtitle?: ReactNode
  subtitleVariant?: SitelineVariant
  defaultSubject: string
  contacts: EmailContact[]
  onRemoveContact?: (id: string) => void
  onCreateContact?: (contact: Contact) => void

  /** If true, will show an "Add recipient" button with this callback as its click handler */
  onAddRecipient?: () => void

  attachmentName?: string
  onAttachmentNameChange?: (update: string) => void
  /** Used as placeholder text on the attachment name input when `onAttachmentNameChange` is provided */
  defaultAttachmentName?: string

  attachmentDescription?: string
  submitLabel?: string
  cancelLabel?: string
  subscript?: string | ReactElement

  /** If provided, will only allow selecting recipients from the provided list */
  availableContacts?: Contact[]
  onContactsChange?: (contacts: EmailContact[]) => void

  initialNote?: string
  /** If true, show the note text field */
  isNoteExpanded?: boolean

  additionalStartRows?: ReactNode
  additionalEndRows?: ReactNode
  additionalButton?: ReactNode

  /** If any maxWidth is provided, it will override the default `email` size */
  maxWidth?: SitelineDialogProps['maxWidth']
  labelSize?: SendEmailDialogRowProps['width']

  disableSubmit?: boolean
  submitting?: boolean
  onEnter?: () => void
  onExited?: () => void

  /** The current user's company ID */
  companyId: string
  allowNewCompanies?: boolean

  /** If true, will reset the subject and message each time the dialog is closed */
  resetOnClose?: boolean

  /** If any of these values change, reset the subject and message when the dialog is opened */
  resetDependencies?: DependencyList

  className?: string

  /** Determines whether the subject is editable. If not editable, defaultSubject will be used. */
  canEditSubject?: boolean
}

const i18nBase = 'projects.subcontractors.pay_app.submit.email_dialog'

/** A dialog with options to customize the email sent for submitting a document */
export function SendEmailDialog({
  open,
  onClose,
  onSubmit,
  title,
  subtitle,
  subtitleVariant,
  defaultSubject,
  contacts,
  onRemoveContact,
  onCreateContact,
  onAddRecipient,
  attachmentName,
  onAttachmentNameChange,
  defaultAttachmentName,
  attachmentDescription,
  submitLabel,
  cancelLabel,
  subscript,
  initialNote = '',
  isNoteExpanded,
  additionalStartRows,
  additionalEndRows,
  additionalButton,
  submitting,
  disableSubmit,
  companyId,
  availableContacts,
  onContactsChange,
  allowNewCompanies = true,
  onEnter,
  onExited,
  resetOnClose,
  labelSize,
  maxWidth,
  resetDependencies,
  className,
  children,
  canEditSubject = true,
}: React.PropsWithChildren<SendEmailDialogProps>) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const [subject, setSubject] = useState<string>(defaultSubject)
  const [message, setMessage] = useState<string>(initialNote)
  const [showSubjectWarning, setShowSubjectWarning] = useState<boolean>(false)
  const [showInvalidEmailWarning, setShowInvalidEmailWarning] = useState<boolean>(false)
  const [editingContact, setEditingContact] = useState<Contact | null>(null)
  const [suggestedEmail, setSuggestedEmail] = useState<string | null>(null)
  const [selectingContactIds, setSelectingContactIds] = useState<string[] | null>(null)
  const hasEditedSubject = useRef<boolean>(false)
  const { data: companyContactData } = useGetCompanyContactsQuery({
    variables: {
      input: {
        companyId,
      },
    },
    skip: !companyId,
  })
  const [createNewCompanyContact] = useCreateCompanyContactMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          companyContacts(existingRefs, { toReference, storeFieldName }) {
            // Don't modify the cached query for another company's contacts
            if (!storeFieldName.includes(companyId)) {
              return existingRefs
            }
            const newRef = cache.writeFragment({
              data: data.createCompanyContact,
              fragment: fragments.companyContact,
              fragmentName: 'CompanyContactProperties',
            })
            const refs = toReferences(existingRefs, toReference)
            return _.compact([...refs, newRef])
          },
        },
      })
    },
  })

  // If the default subject changes while the dialog is closed, or if the user
  // hasn't changed the subject, update to reflect the new default
  const lastSubject = useRef<string>(defaultSubject)
  useEffect(() => {
    if (lastSubject.current !== defaultSubject && !hasEditedSubject.current) {
      setSubject(defaultSubject)
    }
    lastSubject.current = defaultSubject
  }, [defaultSubject])

  // If the note that's passed in changes, update the note in state
  useEffect(() => {
    const rightSizeInitialNote = _.truncate(initialNote, {
      length: MAX_EMAIL_MESSAGE_CHARACTERS,
      // Break too long messages on newline
      // to avoid chopping in the middle of a line
      separator: '\n',
      // Put omission ellipse on next line to look cleaner
      omission: '\n...',
    })
    setMessage(rightSizeInitialNote)
  }, [initialNote])

  // Reset the subject to the default if the input is empty when the dialog is reopened,
  // or the subject hasn't changed (in case the default changed)
  const handleDialogClosed = () => {
    if (resetOnClose) {
      setSubject(defaultSubject)
      setMessage(initialNote)
      setShowSubjectWarning(false)
      setShowInvalidEmailWarning(false)
      setEditingContact(null)
      setSelectingContactIds(null)
      if (onAttachmentNameChange && defaultAttachmentName) {
        onAttachmentNameChange(defaultAttachmentName)
      }
    } else if (!subject || !hasEditedSubject.current) {
      setSubject(defaultSubject)
      setShowSubjectWarning(false)
    }
    if (onExited) {
      onExited()
    }
    hasEditedSubject.current = false
  }

  const handleDialogEnter = useCallback(() => {
    setEditingContact(null)
    setSuggestedEmail(null)
    setShowInvalidEmailWarning(false)
    setSelectingContactIds(null)
    hasShownSubjectMaxCharToast.current = false
    hasShownMessageMaxCharToast.current = false
    if (onEnter) {
      onEnter()
    }
  }, [onEnter])

  // If a list of dependencies is given for resetting the dialog, reset
  // the subject and message whenever the dependencies change
  useEffect(() => {
    if (resetDependencies) {
      setSubject(defaultSubject)
      setMessage(initialNote)
      setShowSubjectWarning(false)
    }
  }, [resetDependencies, defaultSubject, initialNote])

  const handleClose = (fromButton: boolean) => {
    const goBackFromEditingContact = fromButton && editingContact
    setSuggestedEmail(null)
    if (goBackFromEditingContact) {
      setEditingContact(null)
      setShowInvalidEmailWarning(false)
      return
    }
    if (fromButton && selectingContactIds !== null) {
      setSelectingContactIds(null)
      return
    }
    onClose(fromButton)
  }

  const companyContacts = useMemo(
    () => [...(companyContactData?.companyContacts ?? [])],
    [companyContactData?.companyContacts]
  )

  const existingContactDomains = useMemo(
    () => getEmailDomainsFromContacts(companyContacts.map(({ email }) => email)),
    [companyContacts]
  )

  const isEditingContact = !children && editingContact !== null

  const handleContactChange = (contact: Contact) => {
    if (!editingContact || contact.email !== editingContact.email) {
      setShowInvalidEmailWarning(false)
    }
    setEditingContact(contact)
  }

  const handleEmailSuggestion = useCallback(() => {
    if (!editingContact) {
      return
    }
    const emailMatch = findCloselyMatchingEmail({
      email: editingContact.email,
      validDomains: existingContactDomains,
    })
    if (emailMatch !== null) {
      setSuggestedEmail(emailMatch)
    }
  }, [editingContact, existingContactDomains])

  const emailSuggestionProps = useMemo(
    () => ({
      suggestedEmail,
      onSuggestEmail: handleEmailSuggestion,
      onClearEmailSuggestion: () => setSuggestedEmail(null),
      onAcceptEmailSuggestion: () => {
        if (suggestedEmail === null || !editingContact) {
          return
        }
        setEditingContact({ ...editingContact, email: suggestedEmail })
        setSuggestedEmail(null)
      },
    }),
    [editingContact, handleEmailSuggestion, suggestedEmail]
  )

  const handleSubmit = async () => {
    if (!onSubmit) {
      return
    }
    if (isEditingContact) {
      const { companyName, fullName, email } = editingContact

      if (!isValidEmail(email)) {
        setShowInvalidEmailWarning(true)
        return
      }

      // Check to see if a contact already exists with this exact info
      let contact = companyContacts.find((contact) => {
        return (
          contact.companyName === companyName &&
          contact.fullName === fullName &&
          contact.email === email
        )
      })

      if (!contact) {
        try {
          const contactResponse = await createNewCompanyContact({
            variables: {
              input: {
                companyId,
                fullName: replaceAllWhitespaces(fullName).trim(),
                email,
                companyName: replaceAllWhitespaces(companyName).trim(),
              },
            },
          })
          contact = contactResponse.data?.createCompanyContact
        } catch {
          snackbar.showError(t(`${i18nBase}.create_contact_error`))
        }
      }

      if (contact) {
        if (onCreateContact) {
          onCreateContact(contact)
        }
        trackGeneralContractorContactAdded({
          companyName: contact.companyName,
          fullName: contact.fullName,
          email: contact.email,
        })
        setEditingContact(null)
      }

      return
    }
    if (selectingContactIds && availableContacts && onContactsChange) {
      const newContacts = selectingContactIds
        .map((id) => availableContacts.find((contact) => contact.id === id))
        .filter((contact): contact is Contact => !!contact)
      onContactsChange(newContacts)
      setSelectingContactIds(null)
      return
    }
    if (!subject) {
      setShowSubjectWarning(true)
      return
    }
    onSubmit({ subject, message })
  }

  // Disable the "Next" button if creating a contact and not enough
  // information has been entered
  const disableCreateContact =
    isEditingContact &&
    (!editingContact.fullName || !editingContact.email || !editingContact.companyName)
  // Disable the "Send" button if on the send view with no contacts
  const disableSend =
    !isEditingContact && selectingContactIds === null && !children && contacts.length === 0
  const disableSubmitButton = disableSubmit || disableSend || disableCreateContact

  let subscriptShown: ReactElement | string | undefined = undefined
  if (!isEditingContact && selectingContactIds === null) {
    if (subscript !== undefined) {
      subscriptShown = subscript
    } else {
      subscriptShown = t(`${i18nBase}.you_will_receive`)
    }
  }

  const hasShownSubjectMaxCharToast = useRef<boolean>(false)
  const hasShownMessageMaxCharToast = useRef<boolean>(false)
  let submitLabelShown = submitLabel ?? t('common.actions.submit')
  if (isEditingContact) {
    submitLabelShown = t('common.actions.add')
  } else if (selectingContactIds !== null) {
    submitLabelShown = t('common.actions.continue')
  }

  let cancelLabelShown = cancelLabel
  if (isEditingContact || selectingContactIds !== null) {
    cancelLabelShown = t('common.actions.back')
  }

  let onFormCreateContact = onAddRecipient
  if (onCreateContact) {
    onFormCreateContact = () => setEditingContact(makeEmptyContact())
  }

  // If the modal content isn't being passed in via `children`, render either
  // the main email form or the view for entering or selecting contacts
  let emailOrContactsContent = (
    <SendEmailDialogForm
      defaultSubject={defaultSubject}
      subject={subject}
      onSubjectChange={(toSubject) => {
        if (toSubject && showSubjectWarning) {
          setShowSubjectWarning(false)
        }
        if (toSubject.length <= MAX_EMAIL_SUBJECT_CHARACTERS) {
          setSubject(toSubject)
          hasEditedSubject.current = true
        } else if (!hasShownSubjectMaxCharToast.current) {
          snackbar.showInfo(
            t(`${i18nBase}.too_many_characters_subject`, {
              maxChars: MAX_EMAIL_SUBJECT_CHARACTERS,
            })
          )
          hasShownSubjectMaxCharToast.current = true
        }
      }}
      showSubjectWarning={showSubjectWarning}
      message={message}
      onMessageChange={(toMessage) => {
        if (toMessage.length <= MAX_EMAIL_MESSAGE_CHARACTERS) {
          setMessage(toMessage)
        } else if (!hasShownMessageMaxCharToast.current) {
          snackbar.showInfo(
            t(`${i18nBase}.too_many_characters_note`, {
              maxChars: MAX_EMAIL_MESSAGE_CHARACTERS,
            })
          )
          hasShownMessageMaxCharToast.current = true
        }
      }}
      contacts={contacts}
      onRemoveContact={onRemoveContact}
      onCreateContact={onFormCreateContact}
      onSelectContacts={
        availableContacts && availableContacts.length > 0
          ? () => setSelectingContactIds(contacts.map((contact) => contact.id))
          : undefined
      }
      attachmentName={attachmentName}
      onAttachmentNameChange={onAttachmentNameChange}
      defaultAttachmentName={defaultAttachmentName}
      attachmentDescription={attachmentDescription}
      isNoteExpanded={isNoteExpanded}
      additionalStartRows={additionalStartRows}
      additionalEndRows={additionalEndRows}
      labelSize={labelSize}
      submitting={submitting}
      canEditSubject={canEditSubject}
    />
  )
  if (editingContact) {
    emailOrContactsContent = (
      <NewContactForm
        contact={editingContact}
        onContactChange={handleContactChange}
        companyContacts={companyContacts}
        allowNewCompanies={allowNewCompanies}
        showInvalidEmailWarning={showInvalidEmailWarning}
        emailSuggestionProps={emailSuggestionProps}
      />
    )
  } else if (selectingContactIds !== null && availableContacts !== undefined) {
    emailOrContactsContent = (
      <SelectContactsForm
        onAddContact={(id) => setSelectingContactIds(_.uniq([...selectingContactIds, id]))}
        onRemoveContact={(id) =>
          setSelectingContactIds(selectingContactIds.filter((contactId) => contactId !== id))
        }
        availableContacts={availableContacts}
        contactIds={selectingContactIds}
      />
    )
  }

  return (
    <SitelineDialog
      open={open}
      TransitionProps={{
        onEnter: handleDialogEnter,
        onExited: handleDialogClosed,
      }}
      onClose={handleClose}
      onSubmit={onSubmit ? handleSubmit : undefined}
      title={title}
      subtitle={subtitle}
      subtitleVariant={subtitleVariant}
      subscript={subscriptShown}
      submitLabel={submitLabelShown}
      cancelLabel={cancelLabelShown}
      disableSubmit={disableSubmitButton}
      additionalButton={additionalButton}
      submitting={submitting}
      maxWidth={maxWidth}
      size={maxWidth === undefined ? 'email' : undefined}
      className={className}
    >
      {/* Use || instead of ?? so we show the default content when any falsy value is passed */}
      {children || emailOrContactsContent}
    </SitelineDialog>
  )
}
