import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import * as fragments from '../../../common/graphql/Fragments'
import { useClearChangeOrderRequestFormsMutation } from '../../../common/graphql/apollo-operations'
import {
  CompanyWithForms,
  deriveChangeOrderTemplateFromContract,
  deriveDefaultChangeOrderTemplateFromContract,
} from '../../../common/util/Forms'
import {
  deriveFormSelectionStatusFromContract,
  getClearOnboardingFormsRefetchQuery,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import { OnboardingFormsSection } from './OnboardingFormsSection'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

gql`
  mutation clearChangeOrderRequestForms($contractId: ID!) {
    clearChangeOrderRequestForms(contractId: $contractId) {
      id
      changeOrderRequestTemplate {
        id
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${fragments.onboardedProjectContractStatus}
`

const i18nBase = 'projects.subcontractors.settings.forms'

const FORM_TYPE = ProjectOnboardingFormType.CHANGE_ORDER_REQUEST

interface ChangeOrderRequestFormsTaskProps {
  contract: ContractForProjectOnboarding
  isEditing?: boolean
  company: CompanyWithForms
  shouldConfirmFormDeletion?: boolean
}

/** Under the onboarding checklist Forms task, section for selecting change order request forms */
export function ChangeOrderRequestFormsSection({
  contract,
  isEditing = true,
  company,
  shouldConfirmFormDeletion,
}: ChangeOrderRequestFormsTaskProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const refetchQuery = useMemo(() => getClearOnboardingFormsRefetchQuery(companyId), [companyId])
  const [clearForms] = useClearChangeOrderRequestFormsMutation({
    ...refetchQuery,
    update: (cache) => {
      // OnboardedStatus.selectedChangeOrderRequestForms might change
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const formSelectionStatus = useMemo(() => {
    return deriveFormSelectionStatusFromContract(FORM_TYPE, contract)
  }, [contract])

  const formTemplates = useMemo(() => {
    const template = deriveChangeOrderTemplateFromContract(contract)
    return template ? [template] : []
  }, [contract])

  const defaultFormTemplates = useMemo(() => {
    const template = deriveDefaultChangeOrderTemplateFromContract(company)
    return template ? [template] : []
  }, [company])

  const handleClearForms = useCallback(async () => {
    try {
      await clearForms({ variables: { contractId: contract.id } })
      snackbar.showSuccess(t(`${i18nBase}.cleared_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [clearForms, contract.id, snackbar, t])

  return (
    <OnboardingFormsSection
      onboardingFormType={FORM_TYPE}
      formTemplates={formTemplates}
      defaultFormTemplates={defaultFormTemplates}
      onClearForms={handleClearForms}
      isProcessingForms={formSelectionStatus.isProcessingForms}
      contract={contract}
      isEditing={isEditing}
      shouldConfirmFormDeletion={shouldConfirmFormDeletion}
    />
  )
}
