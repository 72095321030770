import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LaunchIcon from '@mui/icons-material/Launch'
import LockIcon from '@mui/icons-material/Lock'
import { Grow, IconButton, Switch, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Permission, SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { config } from '../../../common/config/constants'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  RetentionTrackingLevel,
  useUpdateRoundRetentionMutation,
} from '../../../common/graphql/apollo-operations'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import { ContractForProjectHome } from '../home/ProjectHome'
import { RETENTION_HELP_CENTER_ARTICLE } from '../onboarding/RetentionForm'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
}))

const i18nBase = 'projects.subcontractors.settings.retention'

interface RetentionProps {
  contract?: ContractForProjectHome
}

/** Shows a project's retention and allows you to edit some parts of it. */
export function Retention({ contract }: RetentionProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isContractActive } = useProjectContext()
  const { permissions } = useCompanyContext()
  const snackbar = useSitelineSnackbar()

  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [roundRetention, setRoundRetention] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const [updateRoundRetention] = useUpdateRoundRetentionMutation()
  const canUpdateRoundRetention =
    contract &&
    contract.payApps.every((projectPayApp) => isPayAppDraftOrSyncFailed(projectPayApp.status))

  const resetState = useCallback(() => {
    setRoundRetention(contract?.roundRetention ?? false)
    setHasEdited(false)
  }, [contract])

  const handleSave = useCallback(async () => {
    if (!contract) {
      return
    }
    try {
      updateRoundRetention({
        variables: {
          input: {
            contractId: contract.id,
            roundRetention,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateRoundRetention: {
            ...contract,
            roundRetention,
          },
        },
      })
      snackbar.showSuccess(t(`${i18nBase}.updated`))
      setHasEdited(false)
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [contract, roundRetention, snackbar, t, updateRoundRetention])

  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
    }),
    [handleSave, hasEdited, resetState]
  )

  // Update the state when the contract loads in
  useEffect(() => {
    if (!contract) {
      return
    }

    resetState()
  }, [contract, resetState])

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.title`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={bulkSaveProps}
        endIcon={
          <Grow in={isEditing}>
            <Tooltip
              title={
                <SitelineText variant="smallText" endIcon={<LaunchIcon style={{ fontSize: 14 }} />}>
                  {t(`${i18nBase}.help_center`)}
                </SitelineText>
              }
              placement="top-start"
            >
              <IconButton
                color="secondary"
                onClick={() => {
                  window.open(RETENTION_HELP_CENTER_ARTICLE, '_blank')
                }}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grow>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.tracking_level`)}
        isLoading={!contract}
        value={contract ? t(`${i18nBase}.tracking_levels.${contract.retentionTrackingLevel}`) : ''}
        editingValue={
          <SitelineText
            variant="body1"
            color="grey50"
            endIcon={
              <Tooltip
                title={t(`${i18nBase}.email_support`, { email: config.support.email })}
                placement="top"
              >
                <LockIcon fontSize="small" />
              </Tooltip>
            }
          >
            {contract && t(`${i18nBase}.tracking_levels.${contract.retentionTrackingLevel}`)}
          </SitelineText>
        }
        isEditing={isEditing}
      />
      {contract?.retentionTrackingLevel !== RetentionTrackingLevel.NONE && (
        <>
          <SettingsRow
            label={t(`${i18nBase}.round_to_nearest_dollar`)}
            isLoading={!contract}
            value={roundRetention ? t(`${i18nBase}.on`) : t(`${i18nBase}.off`)}
            editingValue={
              <>
                {canUpdateRoundRetention && (
                  <div className={classes.switch}>
                    <Switch
                      checked={roundRetention}
                      onChange={(event) => {
                        setRoundRetention(event.target.checked)
                        setHasEdited(true)
                      }}
                    />
                  </div>
                )}
                {!canUpdateRoundRetention && (
                  <SitelineText
                    variant="body1"
                    color="grey50"
                    endIcon={
                      <Tooltip
                        title={t(`${i18nBase}.email_support`, { email: config.support.email })}
                        placement="top"
                      >
                        <LockIcon fontSize="small" />
                      </Tooltip>
                    }
                  >
                    {roundRetention ? t(`${i18nBase}.on`) : t(`${i18nBase}.off`)}
                  </SitelineText>
                )}
              </>
            }
            isEditing={isEditing}
          />
        </>
      )}
    </div>
  )
}
