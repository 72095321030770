import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { dollarsToCents, replaceAllWhitespaces } from 'siteline-common-all'
import { localizeDate } from '../components/DatePickerInput'
import { ExcelJson } from './ProjectOnboarding'

export enum ImportTemplateDataType {
  STRING,
  NUMBER,
  DATE,
  DOLLAR,
  QUANTITY,
}

export type BaseImportTemplateColumnConfig<ImportMetadata> = {
  metadataKey: keyof ImportMetadata
  isRequired: boolean
  dataType: ImportTemplateDataType

  /** If the value is longer, it will be truncated when converted to JSON */
  maxLength?: number
}

type ColumnConfigRequiredParams<T, ImportTemplateHeader> =
  | {
      isRequired: true
    }
  // If a column is not required, it may have a default value to use when left empty, or a column
  // to fill the default value from
  | {
      isRequired: false
      defaultValue?: T
      defaultColumnValue?: ImportTemplateHeader
    }

type ColumnConfigValidationParams<T> = {
  /** If provided, returns a string to show as an error if value is invalid, or null if valid */
  validate?: (value: T) => string | null
}

type ColumnConfigString<ImportTemplateHeader> = {
  dataType: ImportTemplateDataType.STRING
} & ColumnConfigRequiredParams<string, ImportTemplateHeader> &
  ColumnConfigValidationParams<string>

type ColumnConfigNumber<ImportTemplateHeader> = {
  dataType:
    | ImportTemplateDataType.NUMBER
    | ImportTemplateDataType.DOLLAR
    | ImportTemplateDataType.QUANTITY
} & ColumnConfigRequiredParams<number, ImportTemplateHeader> &
  ColumnConfigValidationParams<number>

type ColumnConfigDate<ImportTemplateHeader> = {
  dataType: ImportTemplateDataType.DATE
} & ColumnConfigRequiredParams<Moment, ImportTemplateHeader> &
  ColumnConfigValidationParams<Moment>

export type ImportTemplateColumnConfig<ImportMetadata, ImportTemplateHeader extends string> = (
  | ColumnConfigString<ImportTemplateHeader>
  | ColumnConfigNumber<ImportTemplateHeader>
  | ColumnConfigDate<ImportTemplateHeader>
) &
  BaseImportTemplateColumnConfig<ImportMetadata>

export type ImportTemplateHeaderToColumnConfig<
  ImportTemplateHeader extends string,
  ImportMetadata,
> = Record<ImportTemplateHeader, ImportTemplateColumnConfig<ImportMetadata, ImportTemplateHeader>>

/** If the cell contains only some form of -, $-, spaces, or an empty string, treat it as an empty cell */
export function isEmptyDollarAmount(value: string): boolean {
  return value.match(/^[$\-–—]*$/g) !== null
}

const i18nBase = 'bulk_import.import_error'

/**
 * Given an Excel string value, validates that it is in a valid format to be parsed as a dollar
 * amount and returns the value as an amount in cents. If invalid, throws a user-friendly error.
 */
export function parseDollarAmount(value: string, columnName: string, t: TFunction) {
  // If the cell contains only some form of -, $-, spaces, or an empty string, consider it $0
  if (isEmptyDollarAmount(value)) {
    return 0
  }
  // Check that this column doesn't include any characters that shouldn't be in a dollar amount,
  // i.e. $, comma, decimal, or numerals
  // Example: $1,000.00 would match, 100USD would not
  if (!value.match(/[$\d,.-]+/g)) {
    throw new Error(t(`${i18nBase}.dollar_format`, { columnName, value }))
  }
  // Remove all characters from the string other than numerals and decimals, and convert to number.
  // This should return the right number in cents, since the template should enforce the expected format.
  // Example: $1,500.25 -> 1500.25
  let amount = Number(value.replace(/[^\d.-]/g, ''))
  if (isNaN(amount)) {
    throw new Error(t(`${i18nBase}.dollar_format`, { columnName, value }))
  }

  // It's possible for a negative value to have parentheses around it rather than a negative symbol.
  // If so, detect that original value is negative and turn amount into a negative number. We do
  // this iff it's not already negative.
  if (
    value.length &&
    (value[0] === '(' || (value[0] === '$' && value[1] === '(')) &&
    value[value.length - 1] === ')' &&
    amount >= 0
  ) {
    amount *= -1
  }

  return dollarsToCents(amount)
}

/**
 * Given an Excel string value, validates that it is in a valid format to be parsed as a quantity
 * and returns the value as a numeric quantity. If invalid, throws a user-friendly error.
 */
export function parseQuantityAmount(value: string, columnName: string, t: TFunction) {
  // If the cell contains a $, throw an error explaining that this isn't an amount column
  if (value.includes('$')) {
    throw new Error(t(`${i18nBase}.quantity_amount`, { columnName, value }))
  }
  if (value.match(/^[-–—]*$/g)) {
    // If the cell contains only some form of -, or an empty string, consider it 0
    return 0
  }
  // Remove all characters from the string other than numerals and decimals, and convert to number.
  // Example: 1,500.25 -> 1500.25
  let quantity = Number(value.replace(/[^\d.-]/g, ''))
  if (isNaN(quantity)) {
    throw new Error(t(`${i18nBase}.quantity_format`, { columnName, value }))
  }

  // Catch quantities that use parentheses and make them negative
  if (value.length && value[0] === '(' && value[value.length - 1] === ')' && quantity >= 0) {
    quantity *= -1
  }

  return quantity
}

/**
 * This works to convert dates to the project timezone; note that simpler conversions didn't
 * work across different time zones, so don't attempt to simplify this to a simple moment constructor
 * without being sure it works from all time zones
 */
function dateStringToTimeZone(dateStr: string, timeZone: string) {
  // Parse dates without timezone so we don't offset them to get the incorrect date
  // eslint-disable-next-line momentjs/no-moment-constructor
  const initialMoment = moment(dateStr)
  return localizeDate(initialMoment, timeZone)
}

function validateValue<T>(value: T, validateFn?: (value: T) => string | null) {
  if (!validateFn) {
    return
  }
  const error = validateFn(value)
  if (error) {
    throw new Error(error)
  }
}

/** Converts a JSON object to the expected import metadata type */
export function jsonToImportMetadata<ImportTemplateHeader extends string, ImportMetadata>({
  json,
  headersToColumnConfig,
  t,
  timeZone,
}: {
  json: ExcelJson
  headersToColumnConfig: ImportTemplateHeaderToColumnConfig<ImportTemplateHeader, ImportMetadata>
  t: TFunction
  timeZone: string
}): ImportMetadata[] {
  // Skip all lines until we find the header row (based on the first cell in the row matching one of
  // the expected column headers)
  let index = 0
  while (index < json.length && !(_.get(json, [index, 0], '') in headersToColumnConfig)) {
    index++
  }
  // If a header row is never found, throw an error
  if (index === json.length) {
    throw new Error(t(`${i18nBase}.match_template`))
  }
  const headers = json[index]
  const rows = json.slice(index + 1)
  const metadata: ImportMetadata[] = []
  rows.forEach((row) => {
    // Skip empty lines
    if (row.every((element) => String(element).trim() === '')) {
      return
    }
    const rowMetadata: Partial<ImportMetadata> = {}
    for (let i = 0; i < row.length; i++) {
      const header = headers[i] as ImportTemplateHeader
      if (header in headersToColumnConfig) {
        const unsanitized = String(row[i]).trim()
        const { metadataKey, dataType, validate, maxLength } = headersToColumnConfig[header]
        let value: string | number = replaceAllWhitespaces(unsanitized)
        switch (dataType) {
          case ImportTemplateDataType.NUMBER: {
            value = _.toNumber(value.replaceAll(/[^\d]/g, ''))
            if (value && isNaN(value)) {
              throw new Error(t(`${i18nBase}.number_format`, { columnName: header, value }))
            }
            validateValue(value, validate)
            break
          }
          case ImportTemplateDataType.DATE: {
            const date = dateStringToTimeZone(value, timeZone)
            if (value && !date.isValid()) {
              throw new Error(t(`${i18nBase}.date_format`, { columnName: header, value }))
            }
            validateValue(date, validate)
            value = date.toISOString()
            break
          }
          case ImportTemplateDataType.DOLLAR: {
            const dollarValue = parseDollarAmount(value, header, t)
            value = dollarValue
            validateValue(value, validate)
            break
          }
          case ImportTemplateDataType.QUANTITY: {
            const quantityValue = parseQuantityAmount(value, header, t)
            value = quantityValue
            validateValue(value, validate)
            break
          }
          case ImportTemplateDataType.STRING:
            validateValue(value, validate)
            break
        }
        let truncatedValue = value || null
        if (_.isString(truncatedValue) && maxLength) {
          truncatedValue = _.truncate(truncatedValue, { length: maxLength })
        }
        _.set(rowMetadata, metadataKey, truncatedValue)
      }
    }
    // Once all values are read, go back over the row, fill in any default values, and check for
    // missing values
    for (const header in headersToColumnConfig) {
      const columnConfig = headersToColumnConfig[header]
      if (!rowMetadata[columnConfig.metadataKey]) {
        if (columnConfig.isRequired) {
          throw new Error(t(`${i18nBase}.missing_value`, { columnName: header }))
        }
        if (columnConfig.defaultValue) {
          _.set(rowMetadata, columnConfig.metadataKey, columnConfig.defaultValue)
        }
        // This is intentionally not an else block; if both a defaultValue and defaultColumnValue
        // are defined, the column value should take precedence if one exists. But if that column
        // also has no value, the default value should be used.
        if (columnConfig.defaultColumnValue) {
          const defaultColumnConfig = headersToColumnConfig[columnConfig.defaultColumnValue]
          const defaultColumnValue = rowMetadata[defaultColumnConfig.metadataKey]
          if (defaultColumnValue) {
            const truncatedValue =
              columnConfig.maxLength && _.isString(defaultColumnValue)
                ? _.truncate(defaultColumnValue, { length: columnConfig.maxLength })
                : defaultColumnValue
            _.set(rowMetadata, columnConfig.metadataKey, truncatedValue)
          }
        }
      }
    }
    metadata.push(rowMetadata as ImportMetadata)
  })

  if (metadata.length === 0) {
    throw new Error(t(`${i18nBase}.no_results`))
  }

  return metadata
}
