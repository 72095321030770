import type { PhoneNumber } from 'libphonenumber-js'
import parse from 'libphonenumber-js'

/**
 * Converts a phone number into the format expected by the database
 */
export function serializePhoneNumber(phoneNumber: PhoneNumber): string {
  const { number, ext } = phoneNumber
  return ext ? `${number}x${ext}` : number
}

/**
 * Parses a phone number in any format into a PhoneNumber object
 */
export function parsePhoneNumber(phoneNumber: string): PhoneNumber | null {
  const parsed = parse(phoneNumber, { defaultCountry: 'US', extract: false })
  return parsed ?? null
}
