import { gql } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ERP_SYNC_FIELD_INTRODUCED_AT,
  getPayAppLastErpSyncStatus,
  IntegrationTypeFamily,
} from 'siteline-common-all'
import { evictWithGc, useSitelineSnackbar } from 'siteline-common-web'
import { ConfirmResetToDraftDialog } from '../../../common/components/ConfirmResetToDraftDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  BillingType,
  GetCollectionsTasksForUserDocument,
  GetPayAppForCollectionsDocument,
  LienWaiverForMonthDocument,
  PayAppActivityDocument,
  PayAppProperties,
  PayAppStatus,
  ResetPayAppToDraftMutation,
  useResetPayAppToDraftMutation,
} from '../../../common/graphql/apollo-operations'
import { getIntegrationOfFamily } from '../../../common/util/Integration'
import { unconditionalLienWaiverTypes } from '../../../common/util/LienWaiver'
import { trackPayAppResetToDraft } from '../../../common/util/MetricsTracking'
import { getMetricsForPayApp } from '../../../common/util/PayApp'
import { getTimeNoTimeZone } from '../../../common/util/Time'
import { typedPayAppForErpSyncStatus } from '../pay-app-list/AdditionalOptionsMenu'

gql`
  mutation resetPayAppToDraft($input: ResetPayAppToDraftInput!) {
    resetPayAppToDraft(input: $input) {
      id
      status
      statusChangedAt
      revertToDraftEvents {
        id
      }
      uploadedFile {
        id
      }
      lienWaivers {
        id
        type
        vendorContract {
          id
          vendor {
            id
          }
        }
        lienWaiverRequests {
          id
          lienWaiver {
            id
          }
        }
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
    }
  }
  ${fragments.formTemplateAnnotationValue}
`

export type PayAppForReset = ResetPayAppToDraftMutation['resetPayAppToDraft']

const i18nBase = 'projects.subcontractors.pay_app.invoice.reset_to_draft'

interface ResetPayAppToDraftDialogProps {
  open: boolean
  onClose: (success: boolean) => void
  onResetComplete?: () => void
  successMessage?: string
  payApp: Pick<
    PayAppProperties,
    | 'id'
    | 'createdAt'
    | 'retentionOnly'
    | 'status'
    | 'payAppNumber'
    | 'billingType'
    | 'billingEnd'
    | 'lastErpSync'
  >
}

export function ResetPayAppToDraftDialog({
  open,
  onClose,
  payApp,
  onResetComplete,
  successMessage,
}: ResetPayAppToDraftDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { id: projectId, name: projectName, contract, timeZone } = useProjectContext()

  const [gcPortal, erp] = useMemo(() => {
    if (!contract) {
      return [null, null]
    }
    return [
      getIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL),
      getIntegrationOfFamily(contract, IntegrationTypeFamily.ERP),
    ]
  }, [contract])

  const hasGcPortal = gcPortal !== null

  const [retainAnnotations, setRetainAnnotations] = useState(!hasGcPortal)
  const [revertReason, setRevertReason] = useState<string>('')

  const [resetPayApp, { loading }] = useResetPayAppToDraftMutation({
    refetchQueries: [
      {
        query: PayAppActivityDocument,
        variables: {
          payAppId: payApp.id,
        },
      },
      { query: GetPayAppForCollectionsDocument, variables: { payAppId: payApp.id } },
      { query: GetCollectionsTasksForUserDocument },
      // If the unconditional lien waiver has already been loaded for the pay app,
      // refresh the request since the lien waiver should have been deleted on the backend
      {
        query: LienWaiverForMonthDocument,
        variables: {
          input: {
            contractId: contract?.id ?? '',
            payAppId: payApp.id,
            month: moment.tz(payApp.billingEnd, timeZone).toISOString(),
            types: unconditionalLienWaiverTypes,
          },
        },
      },
    ],
    update(cache, { data }) {
      if (!data || !contract) {
        return
      }
      evictWithGc(cache, (evict) => {
        evict({ id: cache.identify(contract), fieldName: 'invoiceAmountOutstanding' })
        evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
        evict({ id: cache.identify(payApp), fieldName: 'lastErpSync' })
      })
    },
  })

  const handleResetToDraft = useCallback(async () => {
    try {
      await resetPayApp({
        variables: {
          input: {
            id: payApp.id,
            retainAnnotations,
            reason: revertReason,
          },
        },
      })
      if (onResetComplete) {
        onResetComplete()
      }
      trackPayAppResetToDraft({
        ...getMetricsForPayApp(payApp, projectId, projectName),
        resetDraftDate: getTimeNoTimeZone().toISOString(),
      })
      snackbar.showSuccess(successMessage ?? t(`${i18nBase}.success`))
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [
    onResetComplete,
    payApp,
    projectId,
    projectName,
    resetPayApp,
    retainAnnotations,
    revertReason,
    snackbar,
    successMessage,
    t,
  ])

  const description = useMemo(() => {
    if (payApp.billingType === BillingType.QUICK) {
      return t(`${i18nBase}.body_quick`)
    }

    const isPaid = payApp.status === PayAppStatus.PAID
    const isPendingGcPortalSync = hasGcPortal && payApp.status === PayAppStatus.SYNC_PENDING
    const typedPayApp = typedPayAppForErpSyncStatus(payApp)
    const payAppErpSyncStatus = getPayAppLastErpSyncStatus(typedPayApp)
    const shouldHideErpSyncWarning = moment
      .utc(payApp.createdAt)
      .isBefore(moment.utc(ERP_SYNC_FIELD_INTRODUCED_AT))
    const isSyncedToErp = payAppErpSyncStatus === 'synced' && !shouldHideErpSyncWarning
    const isPendingErpSync = payAppErpSyncStatus === 'pending' && !shouldHideErpSyncWarning
    const hasErpSync = !!erp && (isSyncedToErp || isPendingErpSync)
    const notSyncedToErp = payAppErpSyncStatus === 'notSynced' || shouldHideErpSyncWarning

    // Has GC portal sync and ERP sync
    if (hasGcPortal && hasErpSync) {
      // Both pending (not paid synce pending sync to gc portal)
      if (isPendingGcPortalSync && isPendingErpSync) {
        return t(`${i18nBase}.body_pending_erp_gc`, {
          integration1: gcPortal.shortName,
          integration2: erp.shortName,
        })
      }
      // Has pending sync to GC portal, synced to ERP (not paid since pending sync to gc portal)
      if (isPendingGcPortalSync) {
        return t(`${i18nBase}.body_pending_success`, {
          pendingIntegration: gcPortal.shortName,
          syncedIntegration: erp.shortName,
        })
      }
      // Paid
      if (isPaid) {
        // Synced to GC portal, pending sync to ERP
        if (isPendingErpSync) {
          return t(`${i18nBase}.body_paid_erp_pending`, {
            integration: erp.shortName,
          })
        }
        // Synced to both GC portal and ERP
        return t(`${i18nBase}.body_paid_sync_erp_gc`, {
          integration1: gcPortal.shortName,
          integration2: erp.shortName,
        })
      }

      // Not paid
      // Pending sync to ERP, synced to GC portal
      if (isPendingErpSync) {
        return t(`${i18nBase}.body_pending_success`, {
          pendingIntegration: erp.shortName,
          syncedIntegration: gcPortal.shortName,
        })
      }
      // Synced to both GC portal and ERP
      return t(`${i18nBase}.body_sync_erp_gc`, {
        integration1: gcPortal.shortName,
        integration2: erp.shortName,
      })
    }

    // Synced to GC portal, not ERP
    if (hasGcPortal && notSyncedToErp) {
      if (isPendingGcPortalSync) {
        return t(`${i18nBase}.body_pending`, { integration: gcPortal.shortName })
      }
      if (isPaid) {
        return t(`${i18nBase}.body_paid_sync`, { integration: gcPortal.shortName })
      }
      return t(`${i18nBase}.body_sync`, { integration: gcPortal.shortName })
    }

    // Has ERP sync, not GC portal
    if (hasErpSync && !hasGcPortal) {
      // Paid
      if (isPaid) {
        return isPendingErpSync
          ? // Pending sync to ERP
            t(`${i18nBase}.body_paid_erp_pending`, { integration: erp.shortName })
          : // Synced to ERP
            t(`${i18nBase}.body_paid_synced_erp`, { integration: erp.shortName })
      }
      // Not paid
      return isPendingErpSync
        ? // Pending sync to ERP
          t(`${i18nBase}.body_pending`, { integration: erp.shortName })
        : // Synced to ERP
          t(`${i18nBase}.body_signed_synced_erp`, { integration: erp.shortName })
    }

    // Does NOT have GC portal, does NOT have ERP syncs
    return isPaid ? t(`${i18nBase}.body_paid`) : t(`${i18nBase}.body`)
  }, [erp, gcPortal?.shortName, hasGcPortal, payApp, t])

  return (
    <ConfirmResetToDraftDialog
      open={open}
      onClose={onClose}
      onResetToDraft={handleResetToDraft}
      retainAnnotations={retainAnnotations}
      onRetainAnnotationsChange={setRetainAnnotations}
      showRetainAnnotations={!hasGcPortal}
      description={description}
      submitting={loading}
      revertReason={revertReason}
      onRevertReasonChange={setRevertReason}
    />
  )
}
