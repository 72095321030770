import _ from 'lodash'
import { safeDivide } from 'siteline-common-all'
import {
  SPREADSHEET_BORDER_PADDING,
  SPREADSHEET_CELL_SIDE_PADDING,
  SPREADSHEET_EDGE_CELLS_SIDE_PADDING,
} from './Spreadsheet'
import {
  SpreadsheetColumn,
  SpreadsheetContent,
  SpreadsheetRow,
  includeDragHandleColumn,
} from './Spreadsheet.lib'

type CellWidth = number
interface CellSizing {
  rowId: string
  width: CellWidth
}
interface ColumnSizing {
  header: CellWidth
  cells: CellSizing[]
}
export interface SpreadsheetSizing {
  width: number
  columns: ColumnSizing[]
}
export interface CellWidthUpdate {
  rowId: string
  columnId: string
  width: number
}
/**
 * The widths of each column in a spreadsheet, calculated from a full sizing matrix.
 * Each width corresponds to the rendered width of the respective column in the spreadsheet.
 */
export type SpreadsheetColumnSizing = CellWidth[]

/** The width of the column containing drag handles if reordering is enabled */
export const DRAG_HANDLE_COLUMN_WIDTH = 24 + 2 * 16

/** Creates an empty sizing object */
export function emptySizing(
  numColumns: number,
  rows: SpreadsheetRow[],
  initialSpreadsheetWidth?: number
): SpreadsheetSizing {
  const emptyColumn = { header: 0, cells: rows.map((row) => ({ rowId: row.id, width: 0 })) }
  return {
    width: initialSpreadsheetWidth ?? 0,
    columns: _.times(numColumns, () => emptyColumn),
  }
}

/** Calculate the width of a cell and its content offset based on spreadsheet sizing */
export function calculateCellLayout(
  columnIndex: number,
  columnSizing: SpreadsheetColumnSizing,
  { colSpan, colOffset }: { colSpan?: number; colOffset?: number } = {}
) {
  // The total cell width is the sum of all columns in the span of the cell
  const columnWidths = _.range(columnIndex, columnIndex + (colSpan ?? 1)).map(
    // Take the largest measured size of every cell in the column
    (index) => columnSizing[index]
  )
  const width = _.sum(columnWidths)
  const offset = _.sum(columnWidths.slice(0, colOffset ?? 0))
  return { width, offset }
}

/** Calculate the column widths for a spreadsheet based on its full sizing matrix */
export function getSpreadsheetColumnSizing(
  sizing: SpreadsheetSizing,
  columns: SpreadsheetColumn[]
): SpreadsheetColumnSizing {
  return sizing.columns.map((column, columnIndex) => {
    const cellWidths = column.cells.map((cell) => cell.width)
    const maxWidth = Math.max(column.header, ...cellWidths)
    return constrainColumnWidth(maxWidth, columns[columnIndex])
  })
}

/** Returns a width adjusted to meet optional column width constraints */
function constrainColumnWidth(width: number, column: SpreadsheetColumn) {
  let toWidth = width
  if (column.minWidth !== undefined) {
    toWidth = Math.max(toWidth, column.minWidth)
  }
  if (column.maxWidth !== undefined) {
    toWidth = Math.min(toWidth, column.maxWidth)
  }
  return toWidth
}

/**
 * Returns a table with sizes corresponding to each cell in a spreadsheet. If a table width is
 * provided, it recalculates cell sizes to fill the additional space to shrink to fit the table.
 * If a cell's content has been updated and a new width is provided, the width is inserted into the
 * sizing table and other column widths may be adjusted to accommodate the updated cell.
 */
function calculateSpreadsheetSizing(
  sizing: SpreadsheetSizing,
  columns: SpreadsheetColumn[],
  content: SpreadsheetContent,
  updates: {
    tableWidth?: number
    cells?: CellWidthUpdate[]
    headerWidth?: {
      columnIndex: number
      width: number
    }
  } = {}
): SpreadsheetSizing {
  const tableWidth = updates.tableWidth ?? sizing.width
  const dragHandleColumnWidth = includeDragHandleColumn(content) ? DRAG_HANDLE_COLUMN_WIDTH : 0
  const allRows = [...content.rows, ...(content.footerRows ?? [])]
  const mapDelimiter = ','
  const cellUpdatesMap = updates.cells
    ? _.keyBy(updates.cells, (cell) => [cell.rowId, cell.columnId].join(mapDelimiter))
    : undefined

  // Start by sizing all fitted columns based on their content
  let sizedColumns = columns.map((column, columnIndex) => {
    const isEdgeColumn = columnIndex === 0 || columnIndex === columns.length - 1
    return allRows.map((row) => {
      // If updating the cell width, calculate the new width and create a sizing cell
      if (!column.grow && cellUpdatesMap) {
        const updatedCell = cellUpdatesMap[[row.id, column.id].join(mapDelimiter)]
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (updatedCell) {
          const totalCellSidePadding = isEdgeColumn
            ? SPREADSHEET_CELL_SIDE_PADDING + SPREADSHEET_EDGE_CELLS_SIDE_PADDING
            : 2 * SPREADSHEET_CELL_SIDE_PADDING
          const totalWidth =
            updatedCell.width +
            totalCellSidePadding +
            2 * SPREADSHEET_BORDER_PADDING +
            (column.extraWidth ?? 0)
          return { rowId: row.id, width: constrainColumnWidth(totalWidth, column) }
        }
      }
      // Otherwise, find the corresponding entry in the sizing table and return it
      const sizingColumn = sizing.columns[columnIndex]
      const sizingCell = sizingColumn.cells.find((cell) => cell.rowId === row.id)
      return sizingCell ?? { rowId: row.id, width: column.minWidth ?? 0 }
    })
  })

  // Update header widths
  const headers = columns.map((column, columnIndex) => {
    const isEdgeColumn = columnIndex === 0 || columnIndex === columns.length - 1
    if (updates.headerWidth?.columnIndex === columnIndex) {
      const cellSidePadding = isEdgeColumn
        ? SPREADSHEET_CELL_SIDE_PADDING + SPREADSHEET_EDGE_CELLS_SIDE_PADDING
        : 2 * SPREADSHEET_CELL_SIDE_PADDING
      // If we allow the header to wrap, ignore the calculated width of the
      // header label since we don't want to factor it into the column's width
      return column.allowHeaderWrap === true
        ? 0
        : updates.headerWidth.width + cellSidePadding + 2 * SPREADSHEET_BORDER_PADDING
    }
    return sizing.columns[columnIndex].header
  })

  // Calculate total width of fit-to-content columns
  let fittedColumnsWidth = 0
  columns.forEach((column, columnIndex) => {
    if (!column.grow) {
      const columnWidths = sizedColumns[columnIndex].map((cell) => cell.width)
      columnWidths.push(headers[columnIndex])
      // If the column has a min-width, include that too so the column is allocated at least
      // that minimum width
      columnWidths.push(column.minWidth ?? 0)
      fittedColumnsWidth += _.max(columnWidths) ?? 0
    }
  })

  // Distributing remaining width evenly
  const growColumnsWidth = tableWidth - dragHandleColumnWidth - fittedColumnsWidth
  const growColumns = columns.filter((column) => column.grow)
  const defaultGrowColumnsWidth = safeDivide(growColumnsWidth, growColumns.length, growColumnsWidth)

  let remainingWidth = 0
  sizedColumns = columns.map((column, columnIndex) => {
    if (column.grow) {
      const columnWidth = constrainColumnWidth(defaultGrowColumnsWidth, column)
      if (columnWidth < defaultGrowColumnsWidth) {
        remainingWidth += defaultGrowColumnsWidth - columnWidth
      }
      if (allRows.length === 0) {
        // If there are no rows in the table, still show the correct column sizes
        return [{ rowId: columnIndex.toString(), width: columnWidth }]
      }
      return allRows.map((row) => ({ rowId: row.id, width: columnWidth }))
    }
    return sizedColumns[columnIndex]
  })

  // Allocate any remaining width in order of columns
  const finalColumns = []
  for (let index = 0; index < columns.length; index++) {
    const column = columns[index]
    const sizedColumn = sizedColumns[index]
    if (column.grow) {
      if (remainingWidth > 0) {
        const currentWidth = Math.max(...sizedColumn.map((cell) => cell.width))
        const additionalWidth = column.maxWidth
          ? Math.min(remainingWidth, column.maxWidth - currentWidth)
          : remainingWidth
        finalColumns.push(
          sizedColumn.map((cell) => ({ ...cell, width: cell.width + additionalWidth }))
        )
        remainingWidth -= additionalWidth
      } else {
        finalColumns.push(sizedColumn)
      }
    } else {
      finalColumns.push(sizedColumn)
    }
  }

  return {
    width: tableWidth,
    columns: finalColumns.map((column, columnIndex) => ({
      header: headers[columnIndex],
      cells: column,
    })),
  }
}

export type SpreadsheetSizingReducerState = {
  columns: SpreadsheetColumn[]
  content: SpreadsheetContent
  sizing: SpreadsheetSizing
  columnSizing: SpreadsheetColumnSizing
}

export type SpreadsheetSizingAction =
  // Update sizing of the spreadsheet based on table width
  | { type: 'UPDATE_SPREADSHEET_WIDTH'; width: number }
  // Update sizing based on the width of a header cell
  | { type: 'UPDATE_HEADER_WIDTH'; columnIndex: number; width: number }
  // Update sizing based on a resized cell
  | { type: 'UPDATE_CELL_WIDTHS'; cells: CellWidthUpdate[] }
  // Update the spreadsheet and optionally recalculate sizing
  | { type: 'UPDATE_SPREADSHEET_CONTENT'; content: SpreadsheetContent; shouldResize: boolean }
  // Update the spreadsheet columns and recalculate sizing
  | { type: 'UPDATE_SPREADSHEET_COLUMNS'; columns: SpreadsheetColumn[] }

export function spreadsheetSizingReducer(
  state: SpreadsheetSizingReducerState,
  action: SpreadsheetSizingAction
): SpreadsheetSizingReducerState {
  let sizing = state.sizing
  let newState = { ...state }
  switch (action.type) {
    case 'UPDATE_SPREADSHEET_WIDTH': {
      sizing = calculateSpreadsheetSizing(state.sizing, state.columns, state.content, {
        tableWidth: action.width,
      })
      break
    }
    case 'UPDATE_HEADER_WIDTH': {
      const { columnIndex, width } = action
      sizing = calculateSpreadsheetSizing(state.sizing, state.columns, state.content, {
        headerWidth: { columnIndex, width },
      })
      break
    }
    case 'UPDATE_CELL_WIDTHS': {
      if (!action.cells.length) {
        return state
      }
      sizing = calculateSpreadsheetSizing(state.sizing, state.columns, state.content, {
        cells: action.cells,
      })
      break
    }
    case 'UPDATE_SPREADSHEET_CONTENT': {
      newState = { ...newState, content: action.content }
      if (action.shouldResize) {
        sizing = calculateSpreadsheetSizing(state.sizing, state.columns, action.content)
      }
      break
    }
    case 'UPDATE_SPREADSHEET_COLUMNS': {
      newState = { ...newState, columns: action.columns }
      const shouldResetSizing = !_.isEqual(
        state.columns.map((column) => column.id),
        action.columns.map((column) => column.id)
      )
      sizing = calculateSpreadsheetSizing(
        // Use empty sizing to start if the columns have changed, since the previous sizing matrix
        // will no longer have the right columns per row
        shouldResetSizing ? emptySizing(action.columns.length, state.content.rows) : state.sizing,
        action.columns,
        state.content
      )
      break
    }
  }
  const toColumnSizing = getSpreadsheetColumnSizing(sizing, newState.columns)
  return {
    ...newState,
    sizing: _.isEqual(state.sizing, sizing) ? state.sizing : sizing,
    columnSizing: _.isEqual(state.columnSizing, toColumnSizing)
      ? state.columnSizing
      : toColumnSizing,
  }
}
