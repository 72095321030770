import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { colors } from 'siteline-common-web'

export const TOP_HEADER_HEIGHT = 56
export const SITELINE_FOOTER_HEIGHT = 66
export const HEADER_HEIGHT = 72

// Below 800px our ui begins to break. Rather than displaying the ui in a broken state
// we can remove some of our nice-to-have features on content-heavy pages
export const MINIMUM_SUPPORTED_SCREEN_WIDTH = 800

export const Z_INDEX: Record<string, number> = {
  hidden: -1,
  defaultPriority: 1,
  card: 1,
  sidebar: 1,
  stickyColumn: 1,
  stickyRow: 1,
  stickyHeader: 2,
  stickyFooter: 2,
  pdfHelf: 2,
  navigationBreadcrumbTabs: 2,
  forecastSearchBar: 2,
  mask: 2,
  appSwitcherButton: 3,
  sitelineFooter: 10,
  sitelineHeader: 10,

  // React-pdf's text/annotation layers have a z-index of 2, so we need to be above that.
  pdfAnnotation: 10,

  sidesheet: 1300,
  sidesheetBackdrop: 1200,

  // This is the default from MUI, we just set it explicitly so it's here for comparison
  topNavHeader: 1100,

  // Use an extra high z-index to ensure the header is stacked above all annotation
  // flags on the pdf viewer, which have z-indexes ranked by order and priority
  pdfHeader: 1000,
  // Snackbar is 1400, so this needs to be higher
  popover: 1401,
}

const DEFAULT_THEME_SPACING = 8

// Numeric equivalent of `theme.spacing(1)`, to be used when doing arithmetic with spacing values
// in CSS, since `theme.spacing(1)` resolves to `8px`.
export function themeSpacing(multiplier: number) {
  return DEFAULT_THEME_SPACING * multiplier
}

// All pages that show the top bar and a header should take up enough space to push the footer to
// the very bottom of the screen
export function defaultMinHeight(additionalOffset = 0) {
  const offset = TOP_HEADER_HEIGHT + HEADER_HEIGHT + SITELINE_FOOTER_HEIGHT + additionalOffset
  return `calc(100vh - ${offset}px)`
}

const theme = createTheme({
  spacing: DEFAULT_THEME_SPACING,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          input: {
            '&::-ms-clear, &::-ms-reveal': {
              display: 'none',
              height: 0,
              width: 0,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 6,
        },
        input: {
          // Override vertical padding to match size of normal input
          paddingTop: '4.5px !important',
          paddingBottom: '4.5px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        contained: {
          boxShadow: 'none',
        },
        textPrimary: {
          '&:focus': {
            color: colors.blue70,
          },
        },
        textSecondary: {
          '&:focus': {
            color: colors.grey90,
          },
        },

        // TextInfo isn't recognized by TS, but is a valid variant and class
        // on the button, which is used here for removing the hover background
        ...{
          textInfo: {
            color: colors.grey90,
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.grey70,
            },
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colors.blue70,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: colors.grey70,
          },
        },
        outlinedSecondary: {
          backgroundColor: colors.white,
          borderColor: colors.grey30,
          color: colors.grey70,
          '&.Mui-disabled': {
            borderColor: colors.grey20,
            color: colors.grey40,
          },
          '&:hover': {
            backgroundColor: colors.grey20,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          '&:hover': {
            // Match the MUI background color of the default text button
            backgroundColor: 'rgba(0, 107, 173, 0.04)',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.grey30,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: colors.white,
          borderBottom: '1px solid',
          borderBottomColor: colors.grey30,
          '& .MuiTableCell-root': {
            borderBottom: 0,
            lineHeight: '36px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: `${themeSpacing(2)}px ${themeSpacing(1.5)}px`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '.75rem',
          fontWeight: 600,
          letterSpacing: '0.07em',
          textTransform: 'uppercase',
          paddingLeft: themeSpacing(0.5),
          paddingRight: themeSpacing(0.5),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.grey10,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: themeSpacing(2),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          // Override default padding for multiline inputs
          padding: '10px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px',
          height: 19,
          '&::placeholder': {
            color: colors.grey50,
            opacity: 1,
          },
        },
        notchedOutline: {
          borderColor: colors.grey30,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // Same as "small text"
          fontSize: '.75rem',
          backgroundColor: '#000000',
          padding: `${themeSpacing(1)}px ${themeSpacing(1.5)}px`,
          // Override larger bottom margin on the popper component
          marginBottom: `${themeSpacing(1)}px !important`,
        },
        arrow: {
          color: '#000000',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
        elevation1: {
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
        },
        elevation8: {
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        colorSecondary: {
          backgroundColor: colors.grey20,
        },
        barColorSecondary: {
          backgroundColor: colors.grey30,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey20,
          borderRadius: themeSpacing(0.5),
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 'inherit',
            marginRight: themeSpacing(1),
          },
          '& .MuiButtonBase-root': {
            paddingTop: themeSpacing(1),
            paddingBottom: themeSpacing(1),
            '&:not(:last-child)': {
              borderBottom: '1px solid',
              borderBottomColor: colors.grey30,
            },
          },
          '& .MuiButtonBase-root.Mui-selected:hover': {
            backgroundColor: colors.blue50,
          },
          '& .MuiButtonBase-root.Mui-selected.Mui-focusVisible': {
            backgroundColor: colors.blue50,
          },
        },
        padding: {
          padding: 0,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: '19px',
          lineHeight: '19px',
          '&:focus': {
            backgroundColor: colors.white,
          },
        },
        icon: {
          color: colors.grey50,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '& .MuiMenuItem-root': {
            backgroundColor: colors.white,
            color: colors.grey90,
            display: 'block',
            // Override default padding for more spacing
            padding: `${themeSpacing(2)}px !important`,
            minWidth: '250px',
            maxWidth: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: colors.grey20,
            },
            '&.Mui-selected': {
              backgroundColor: colors.blue50,
              color: colors.white,
            },
            '&.MuiButtonBase-root:not(:last-child)': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.grey70,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          display: 'block',
          fontSize: 13,
          fontFamily: 'InterVariable',
          fontWeight: 500,
          letterSpacing: 0,
          marginLeft: themeSpacing(1),
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
          borderRadius: themeSpacing(1),
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56, // TOP_HEADER_HEIGHT from Chrome.tsx
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
  palette: {
    background: {
      default: colors.grey10,
    },
    primary: {
      main: colors.blue50,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.grey70,
      contrastText: colors.white,
    },
    error: {
      main: colors.red50,
      contrastText: colors.white,
    },
    text: {
      primary: colors.grey90,
      secondary: colors.grey50,
      disabled: colors.grey50,
    },
  },
  typography: {
    fontFamily: 'InterVariable',
    htmlFontSize: 16,
    h1: {
      fontWeight: 400,
      fontSize: '1.75rem',
      lineHeight: 1.14,
      letterSpacing: '-0.02em',
      fontFeatureSettings: 'initial',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.175,
      letterSpacing: '-0.019em',
      fontFeatureSettings: 'initial',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.2,
      letterSpacing: '-0.017em',
      fontFeatureSettings: 'initial',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.01em',
      fontFeatureSettings: 'initial',
    },
    h5: {
      fontSize: '.875rem',
      lineHeight: 1.25,
      letterSpacing: '0.07em',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    h6: {
      // Small text
      fontSize: '.75rem',
      fontWeight: 400,
      lineHeight: 1.33,
      display: 'block',
    },
    body1: {
      // Body regular
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.01em',
    },
    body2: {
      // Secondary
      fontSize: '.875rem',
      lineHeight: 1.43,
      letterSpacing: '-0.005em',
    },
    button: {
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.01em',
      textTransform: 'none',
    },
    caption: {
      fontSize: '.625rem',
      fontWeight: 600,
      letterSpacing: '1px',
      lineHeight: 1.6,
      textTransform: 'uppercase',
      '& .MuiSvgIcon-root': {
        width: 14,
        height: 14,
        marginRight: themeSpacing(0.5),
      },
    },
    subtitle1: {
      // Label
      fontSize: '.75rem',
      fontWeight: 600,
      lineHeight: 1.33,
    },
  },
})

export const MainTheme = responsiveFontSizes(theme)
