import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Button, IconButton, MenuItem, Select } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  LocationAddressForm,
  calculateLocationCoordinates,
} from '../../../common/components/LocationAddressForm'
import { locationInputFromLocation } from '../../../common/components/LocationAutocomplete'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { LocationInput, LocationProperties } from '../../../common/graphql/apollo-operations'
import {
  formatLocationOneLine,
  isIncompleteLocation,
  isValidStateCode,
  makeEmptyLocationInput,
} from '../../../common/util/Location'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    '& .formField': {
      display: 'flex',
      flexDirection: 'column',
      '& .invalidFieldText': {
        marginTop: theme.spacing(0.5),
      },
    },
  },
  locations: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    padding: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  editLocationsButton: {
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      maxWidth: 'initial',
    },
  },
}))

interface OnboardingAddressInputProps {
  /** If any existing locations to choose from, will show them in a dropdown */
  locationOptions: LocationProperties[]
  /** Currently selected location, from among existing options, or null if not using existing location */
  locationId: string | null
  /** The current location details to show; may be an existing location or a new one */
  location: LocationInput | null
  /** A newly selected or entered location, and a location ID if selecting an existing location */
  onLocationChange: (location: LocationInput | null, locationId: string | null) => void
  dialogTitle: string
  addButtonLabel?: string
  submitDialogLabel?: string
  isFieldMissing?: boolean
  isFieldInvalid?: boolean
}

const i18nBase = 'projects.onboarding'
const NEW_LOCATION = 'new-location'

/** Field for selecting or adding/editing an address, with a dialog containing address fields */
export function OnboardingAddressInput({
  locationOptions,
  locationId,
  location,
  onLocationChange,
  dialogTitle,
  addButtonLabel,
  isFieldMissing,
  isFieldInvalid,
  submitDialogLabel,
}: OnboardingAddressInputProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const emptyLocationInput = useMemo(makeEmptyLocationInput, [])
  const hasLocation = location && !isIncompleteLocation(location)
  const shouldDisplayDropdown = locationOptions.length > 0
  const initialLocation = hasLocation && !shouldDisplayDropdown ? location : emptyLocationInput
  const [newLocation, setNewLocation] = useState<LocationInput>(initialLocation)
  const [originalLocationId, setOriginalLocationId] = useState<string | null>(locationId)

  // Update the original locationId iff we actually receive a real location (ie. not null)
  useEffect(() => {
    if (!locationId) {
      return
    }
    setOriginalLocationId(locationId)
  }, [locationId])

  const handleSubmit = useCallback(async () => {
    if (!isValidStateCode(newLocation.state)) {
      snackbar.showError(t('projects.onboarding.invalid_state_field'))
      return
    }
    try {
      const coordinates = await calculateLocationCoordinates(newLocation, t)
      onLocationChange({ ...newLocation, coordinates }, null)
      setDialogOpen(false)
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [newLocation, onLocationChange, snackbar, t])

  return (
    <div className={classes.root}>
      {!hasLocation && (
        <div className="formField">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon fontSize="small" />}
            onClick={() => setDialogOpen(true)}
          >
            {addButtonLabel ?? t(`${i18nBase}.add_address`)}
          </Button>
          {isFieldMissing && (
            <SitelineText variant="smallText" color="red70" className="invalidFieldText">
              {t('projects.onboarding.required_field')}
            </SitelineText>
          )}
        </div>
      )}
      {hasLocation && (
        <div className="formField">
          {/* If no existing locations, just show the new location as plain text */}
          {locationOptions.length === 0 && (
            <SitelineText
              variant="body1"
              color="grey90"
              endIcon={
                <IconButton
                  color="secondary"
                  className={classes.editButton}
                  onClick={() => setDialogOpen(true)}
                  size="large"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            >
              {formatLocationOneLine(location)}
            </SitelineText>
          )}
          {/* If existing locations to select from, show a dropdown with those options (and a newly entered location) */}
          {shouldDisplayDropdown && (
            <div className={classes.locations}>
              <Select
                variant="outlined"
                value={locationId ?? NEW_LOCATION}
                onChange={(event) => {
                  const existingLocation = locationOptions.find(
                    (someLocation) => someLocation.id === event.target.value
                  )
                  if (existingLocation) {
                    onLocationChange(
                      locationInputFromLocation(existingLocation),
                      existingLocation.id
                    )
                  } else {
                    onLocationChange(newLocation, null)
                    if (isIncompleteLocation(newLocation)) {
                      setDialogOpen(true)
                    }
                  }
                }}
              >
                <MenuItem value={NEW_LOCATION} className={classes.menuItem}>
                  {isIncompleteLocation(newLocation)
                    ? t(`${i18nBase}.new_address`)
                    : formatLocationOneLine(newLocation)}
                </MenuItem>
                {locationOptions.map((location) => (
                  <MenuItem key={location.id} value={location.id} className={classes.menuItem}>
                    {formatLocationOneLine(location)}
                  </MenuItem>
                ))}
              </Select>
              {locationId === null && (
                <IconButton
                  color="secondary"
                  className={clsx(classes.editButton, classes.editLocationsButton)}
                  onClick={() => setDialogOpen(true)}
                  size="large"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          )}
          {isFieldInvalid && (
            <SitelineText variant="smallText" color="red70" className="invalidFieldText">
              {t('projects.onboarding.invalid_field')}
            </SitelineText>
          )}
        </div>
      )}
      <SitelineDialog
        disableBackdrop
        disableEscapeKey
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          // If we have an original location, switch back to that
          if (originalLocationId !== null) {
            const switchToLocation = locationOptions.find(
              (option) => option.id === originalLocationId
            )
            if (switchToLocation) {
              onLocationChange(locationInputFromLocation(switchToLocation), switchToLocation.id)
              setNewLocation(emptyLocationInput)
            }
          }
        }}
        title={dialogTitle}
        disableSubmit={isIncompleteLocation(newLocation)}
        onSubmit={handleSubmit}
        additionalButton={
          locationId === null &&
          locationOptions.length === 0 &&
          location && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                onLocationChange(null, null)
                setNewLocation(emptyLocationInput)
                setDialogOpen(false)
              }}
            >
              {t('common.actions.clear')}
            </Button>
          )
        }
        submitLabel={submitDialogLabel ?? t(`${i18nBase}.add_address`)}
        maxWidth="sm"
      >
        <LocationAddressForm
          location={newLocation}
          onLocationChange={setNewLocation}
          includeNickname={false}
          labelColor="grey50"
        />
      </SitelineDialog>
    </div>
  )
}
