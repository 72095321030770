import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { integrationTypes } from 'siteline-common-all'
import { colors, makeStylesFast } from 'siteline-common-web'
import syncPendingImage from '../../../assets/images/syncPending.png'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { WriteSyncResultScreen, WriteSyncResultScreenText } from './WriteSyncResultScreen'

const useStyles = makeStylesFast(() => ({
  icon: {
    color: colors.red50,
    fontSize: 60,
  },
}))

interface WriteSyncQueuedInHh2Props {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload
  result: integrationTypes.WriteSyncResultQueuedInHh2
  onClose: () => void
}

const i18nBase = 'integrations.queued_in_hh2'

/**
 * Screen shown when an invoice has been queued in hh2 but not yet processed by Sage.
 * In this case, we keep monitoring the export via cron jobs and we email the user when the invoice
 * is finally processed.
 */
export function WriteSyncQueuedInHh2({
  integration,
  projectId,
  payload,
  result,
  onClose,
}: WriteSyncQueuedInHh2Props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const text = useMemo((): WriteSyncResultScreenText => {
    return {
      actions: [],
      title: t(`${i18nBase}.title`),
      description: t(`${i18nBase}.description`, { entityDescription: result.entityDescription }),
      icon: (
        <img
          src={syncPendingImage}
          className={clsx(classes.icon, 'image')}
          alt={t(`${i18nBase}.title`)}
        />
      ),
      closeLabel: t('common.actions.ok'),
    }
  }, [classes.icon, result.entityDescription, t])

  return (
    <WriteSyncResultScreen
      text={text}
      integration={integration}
      payload={payload}
      projectId={projectId}
      onClose={onClose}
    />
  )
}
