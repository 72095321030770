import { Reference } from '@apollo/client'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Collapse, MenuItem, Select, TextField, Theme, Tooltip } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import {
  BillingType,
  TaxCalculationType,
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  roundCents,
  safeDivide,
} from 'siteline-common-all'
import {
  PayApp,
  SitelineText,
  colors,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from '../../../../common/components/DatePickerInput'
import { useSitelineConfirmation } from '../../../../common/components/SitelineConfirmation'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useProjectContext } from '../../../../common/contexts/ProjectContext'
import * as fragments from '../../../../common/graphql/Fragments'
import {
  ChangeOrderRequestsDocument,
  GetContractForPayAppDocument,
  GetSovLineItemGroupsDocument,
  Query,
  SovLineItem,
  SovLineItemGroupProperties,
  SovLineItemProgressProperties,
  TaxGroupProperties,
  useCreateChangeOrderMutation,
  useCreateSovLineItemGroupMutation,
  useDeleteChangeOrderMutation,
  useGetSovLineItemGroupsQuery,
  useUpdateChangeOrderMutation,
} from '../../../../common/graphql/apollo-operations'
import { getNewChangeOrderGroupId } from '../../../../common/util/ManageSov'
import { assertValidLineItemUnitPrice } from '../../../../common/util/ManageUnitPriceSovColumn'
import { isNewBilledInRange } from '../../../../common/util/PayApp'
import { usesStandardOrLineItemTracking } from '../../../../common/util/Retention'
import { getNextCode } from '../../../../common/util/Sov'
import { taxGroupPercentToDecimal } from '../../../../common/util/TaxGroup'
import { PayAppForBackup } from '../../backup/PayAppBackup'
import { formatTaxGroupLabel } from '../../settings/TaxCalculationOptions'
import { PayAppForProgress } from '../LumpSumPayAppInvoice'

const useStyles = makeStylesFast((theme: Theme) => ({
  inputs: {
    '& .flex': {
      display: 'flex',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(0.5, 0, 2, 0),
      width: '100%',
    },
    '& .code': {
      width: '20%',
    },
    '& .name': {
      marginLeft: theme.spacing(2),
      width: '60%',
    },
    '& .amount': {
      marginLeft: theme.spacing(2),
      width: '20%',
    },
    '& .retentionOrTaxGroup': {
      marginLeft: theme.spacing(2),
      width: '25%',
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
    '& .group': {
      width: '50%',
      '& .MuiInputBase-root': {
        margin: theme.spacing(0.5, 0, 2, 0),
      },
    },
    '& .date': {
      marginLeft: theme.spacing(2),
      width: '50%',
    },
    '& .groupCode': {
      width: '25%',
    },
    '& .groupName': {
      width: '75%',
      marginLeft: theme.spacing(2),
    },
    '&.unitPrice': {
      '& .name': {
        width: '80%',
      },
      '& .unitOfMeasure': {
        width: '20%',
      },
      '& .unitPrice': {
        width: '25%',
        marginLeft: theme.spacing(2),
      },
      '& .unitsContracted': {
        width: '25%',
        marginLeft: theme.spacing(2),
      },
    },
  },
  amountInput: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey30}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    height: theme.spacing(5),
    marginTop: theme.spacing(0.5),
    width: '100%',
    ...theme.typography.body1,
    '&:hover': {
      borderColor: colors.grey50,
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  delete: {
    color: colors.red50,
    // Override default secondary button border style
    borderColor: `${colors.red30} !important`,
    '& .MuiSvgIcon-root': {
      color: colors.red50,
    },
  },
  actions: {
    display: 'flex',
    '& .grow': {
      flex: 1,
    },
    '& .cancel': {
      borderColor: colors.grey30,
    },
  },
  noGroup: {
    color: colors.grey50,
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.invoice.add_change_order'

type AddOrEditChangeOrderDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  payApp: PayAppForBackup | PayAppForProgress
  lineItemProgress?: SovLineItemProgressProperties
  onChangeOrdersAdded?: () => void
  taxCalculationType: TaxCalculationType
  taxGroups: TaxGroupProperties[]
}

export function AddOrEditChangeOrderDialog({
  open,
  setOpen,
  payApp,
  lineItemProgress,
  onChangeOrdersAdded,
  taxCalculationType,
  taxGroups,
}: AddOrEditChangeOrderDialogProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { id: projectId } = useProjectContext()
  const { data: groupsData } = useGetSovLineItemGroupsQuery({
    variables: { contractId: payApp.contract.id },
  })

  const existingCodes = [...payApp.progress].map((progress) => progress.sovLineItem.code)
  const newCodeString = getNextCode(existingCodes)
  const { contract, timeZone } = useProjectContext()

  const existingGroups = useMemo(
    () => _.orderBy([...(groupsData?.sovLineItemGroups ?? [])], (group) => group.name),
    [groupsData]
  )
  const initialGroupId = useMemo(() => {
    let groupId = lineItemProgress?.sovLineItem.sovLineItemGroup?.id ?? null
    if (!lineItemProgress) {
      // By default, add to a group if every existing change order belongs to the same group
      groupId = getNewChangeOrderGroupId(
        payApp.progress.map((progress) => ({
          isChangeOrder: progress.sovLineItem.isChangeOrder,
          groupId: progress.sovLineItem.sovLineItemGroup?.id,
        }))
      )
    }
    return groupId
  }, [lineItemProgress, payApp.progress])

  const [name, setName] = useState<string>(lineItemProgress?.sovLineItem.name ?? '')
  const [code, setCode] = useState<string>(lineItemProgress?.sovLineItem.code ?? newCodeString)
  const [totalValue, setTotalValue] = useState<number | null>(lineItemProgress?.totalValue ?? null)
  const [unitOfMeasure, setUnitOfMeasure] = useState<string>('')
  const [unitPrice, setUnitPrice] = useState<number | null>(null)
  const [unitsContracted, setUnitsContracted] = useState<number | undefined>()
  const [groupId, setGroupId] = useState<string | 'new-group' | null>(initialGroupId)
  const [newGroupCode, setNewGroupCode] = useState<string>('')
  const [newGroupName, setNewGroupName] = useState<string>('')

  const shouldIncludeTaxGroups = taxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS
  const initialTaxGroupId = useMemo(
    () => (shouldIncludeTaxGroups ? (lineItemProgress?.sovLineItem.taxGroup?.id ?? null) : null),
    [lineItemProgress?.sovLineItem.taxGroup?.id, shouldIncludeTaxGroups]
  )
  const [taxGroupId, setTaxGroupId] = useState<string | null>(initialTaxGroupId)

  // When adding a change order, we should default to today's date, if it's in the range of the pay
  // app's billing period. If it's not, we should pick either the billingStart or billingEnd,
  // whichever is closer to today's date.
  const today = moment.tz(timeZone)
  const billingStart = moment.tz(payApp.billingStart, timeZone)
  const billingEnd = moment.tz(payApp.billingEnd, timeZone)
  let defaultDate = today
  if (today.isBetween(billingStart, billingEnd, 'date', '[]') === false) {
    const daysToStart = Math.abs(today.diff(billingStart, 'days'))
    const daysToEnd = Math.abs(today.diff(billingEnd, 'days'))
    if (daysToStart < daysToEnd) {
      defaultDate = billingStart
    } else {
      defaultDate = billingEnd
    }
  }

  const initialApprovedDate = useMemo(
    () =>
      makeDatePickerValue(
        lineItemProgress?.sovLineItem.changeOrderApprovedDate
          ? moment.tz(lineItemProgress.sovLineItem.changeOrderApprovedDate, timeZone)
          : defaultDate
      ),
    [lineItemProgress?.sovLineItem.changeOrderApprovedDate, defaultDate, timeZone]
  )
  const [approvedDateValue, setApprovedDateValue] = useState<DatePickerValue>(initialApprovedDate)

  // Only show the CO retention input for standard or line item retention tracking levels
  const showRetentionValue = contract?.retentionTrackingLevel
    ? usesStandardOrLineItemTracking(contract.retentionTrackingLevel)
    : false
  const defaultRetentionPercent = useMemo(() => {
    if (!contract || !showRetentionValue) {
      return ''
    }

    // If all the line items have the same retention %, use that as the default. This percent is
    // the current % for standard tracking and the total % for line item tracking, but both work
    // fine for this case.
    const lineItemPercents = _.chain(payApp.progress)
      .map((progress) => progress.retentionHeldPercent)
      .uniq()
      .value()
    if (lineItemPercents.length === 1) {
      return lineItemPercents[0].toString()
    }

    return ''
  }, [contract, payApp.progress, showRetentionValue])
  const [retentionPercent, setRetentionPercent] = useState<string>(defaultRetentionPercent)

  // Update the initial group ID when the groups load
  useEffect(() => {
    setGroupId(initialGroupId)
  }, [initialGroupId])

  // Update the initial tax group ID when the tax groups load
  useEffect(() => {
    setTaxGroupId(initialTaxGroupId)
  }, [initialTaxGroupId])

  const handleResetDialog = useCallback(() => {
    setRetentionPercent(defaultRetentionPercent)
    setGroupId(initialGroupId)
    setNewGroupCode('')
    setNewGroupName('')
    setTaxGroupId(initialTaxGroupId)
  }, [defaultRetentionPercent, initialGroupId, initialTaxGroupId])

  const [createChangeOrderMutation, { loading: isCreatingChangeOrder }] =
    useCreateChangeOrderMutation({
      update(cache, { data }) {
        if (!data) {
          return
        }
        const newRef = cache.writeFragment({
          data: data.createChangeOrder,
          fragment: fragments.sovLineItemProgress,
          fragmentName: 'SovLineItemProgressProperties',
        })
        cache.modify<WritableDeep<PayApp>>({
          id: cache.identify(payApp),
          fields: {
            progress(existing, { toReference }) {
              const refs = toReferences(existing, toReference)
              return _.compact([...refs, newRef])
            },
          },
        })

        // If added to a group, may need to update sort order of other line items
        const bumpLineItems = payApp.progress.filter(
          (progress) =>
            progress.sovLineItem.sortOrder >= data.createChangeOrder.sovLineItem.sortOrder
        )
        bumpLineItems.forEach((progress) => {
          cache.modify<WritableDeep<SovLineItem>>({
            id: cache.identify(progress.sovLineItem),
            fields: {
              sortOrder() {
                return progress.sovLineItem.sortOrder + 1
              },
            },
          })
        })
      },
      refetchQueries: [
        {
          query: GetContractForPayAppDocument,
          variables: { input: { projectId, companyId: payApp.contract.company.id } },
        },
      ],
    })
  const [updateChangeOrderMutation] = useUpdateChangeOrderMutation()
  const [deleteChangeOrderMutation] = useDeleteChangeOrderMutation({
    update(cache) {
      if (!lineItemProgress) {
        return
      }

      cache.modify<WritableDeep<PayApp>>({
        id: cache.identify(payApp),
        fields: {
          progress(existing, { readField, toReference }) {
            const refs = toReferences(existing, toReference)
            return refs.filter((progressItemRef) => {
              const sovLineItemRef = readField('sovLineItem', progressItemRef) as
                | Reference
                | undefined
              if (!sovLineItemRef) {
                return false
              }
              return lineItemProgress.sovLineItem.id !== readField('id', sovLineItemRef)
            })
          },
        },
      })
    },
    refetchQueries: [
      {
        query: ChangeOrderRequestsDocument,
        variables: { contractId: contract?.id ?? '' },
      },
      {
        query: GetSovLineItemGroupsDocument,
        variables: { contractId: payApp.contract.id },
      },
    ],
  })
  const [createSovLineItemGroupMutation, { loading: isCreatingGroup }] =
    useCreateSovLineItemGroupMutation({
      update(cache, { data }) {
        if (!data) {
          return
        }
        const newRef = cache.writeFragment({
          data: data.createSovLineItemGroup,
          fragment: fragments.sovLineItemGroup,
          fragmentName: 'SovLineItemGroupProperties',
        })
        cache.modify<WritableDeep<Query>>({
          fields: {
            sovLineItemGroups(existing, { toReference }) {
              const refs = toReferences(existing, toReference)
              return _.compact([...refs, newRef])
            },
          },
        })
      },
    })

  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()

  useEffect(() => {
    if (open) {
      setName(lineItemProgress?.sovLineItem.name ?? '')
      setCode(lineItemProgress?.sovLineItem.code ?? newCodeString)
      setTotalValue(lineItemProgress?.totalValue ?? null)
      setUnitOfMeasure(lineItemProgress?.sovLineItem.unitName ?? '')
      setUnitPrice(lineItemProgress?.sovLineItem.unitPrice ?? null)
      let initialUnitsContracted: number | undefined = undefined
      if (lineItemProgress?.totalValue && _.isNumber(lineItemProgress.sovLineItem.unitPrice)) {
        initialUnitsContracted = safeDivide(
          lineItemProgress.totalValue,
          lineItemProgress.sovLineItem.unitPrice,
          0
        )
      }
      setUnitsContracted(initialUnitsContracted)
      setApprovedDateValue(initialApprovedDate)
    } else {
      clearValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const clearValues = () => {
    setName('')
    setCode('')
    setTotalValue(null)
    setUnitOfMeasure('')
    setUnitPrice(null)
    setUnitsContracted(undefined)
    setApprovedDateValue(makeDatePickerValue(defaultDate))
  }

  const createSovLineItemGroup = async () => {
    if (groupId !== 'new-group' || newGroupName === '') {
      return null
    }

    try {
      const { data: newGroupData } = await createSovLineItemGroupMutation({
        variables: {
          input: {
            contractId: payApp.contract.id,
            groupName: newGroupName,
            groupCode: newGroupCode || null,
          },
        },
      })

      const newGroup = newGroupData?.createSovLineItemGroup ?? null
      if (!newGroup) {
        return null
      }
      setGroupId(newGroup.id)
      return newGroup
    } catch (err) {
      snackbar.showError(err.message)
      return null
    }
  }

  const billingType = contract?.billingType ?? BillingType.LUMP_SUM
  const isUnitPrice = billingType === BillingType.UNIT_PRICE
  const isLumpSum = billingType === BillingType.LUMP_SUM

  const submitCreate = async () => {
    const { date: approvedDate } = approvedDateValue
    if (approvedDate === null) {
      return
    }

    let sovLineItemGroup: SovLineItemGroupProperties | null | undefined = null
    if (groupId === 'new-group') {
      sovLineItemGroup = await createSovLineItemGroup()
      if (!sovLineItemGroup) {
        return
      }
    } else if (groupId) {
      sovLineItemGroup = existingGroups.find((group) => group.id === groupId)
    }

    const defaultRetentionPercent = retentionPercent === '' ? undefined : Number(retentionPercent)
    let createWithTotalValue = totalValue ?? 0
    if (isUnitPrice && _.isNumber(unitPrice)) {
      createWithTotalValue = roundCents(unitPrice * (unitsContracted ?? 0))
      const invalidUnitPriceError = assertValidLineItemUnitPrice(unitPrice, createWithTotalValue, t)
      if (invalidUnitPriceError) {
        snackbar.showError(invalidUnitPriceError)
        return
      }
    }

    // If the change order's approval date is outside the pay app's billing period, use the pay app
    // billing start as the effective date. Since the customer is adding the change order to this
    // pay app, we assume they want it to appear on this pay app.
    const effectiveDate = approvedDate.isBetween(billingStart, billingEnd, 'date', '[]')
      ? null
      : billingStart.clone().toISOString()

    try {
      await createChangeOrderMutation({
        variables: {
          input: {
            payAppId: payApp.id,
            name,
            code,
            totalValue: createWithTotalValue,
            unitPrice,
            unitName: unitOfMeasure || undefined,
            approvedDate: approvedDate.toISOString(),
            effectiveDate,
            defaultRetentionPercent,
            sovLineItemGroupId: sovLineItemGroup?.id ?? null,
            taxGroupId: shouldIncludeTaxGroups ? taxGroupId : undefined,
          },
        },
      })
      setOpen(false)
      if (onChangeOrdersAdded) {
        onChangeOrdersAdded()
      }
    } catch (err) {
      snackbar.showError(err.message)
    }
  }

  const submitUpdate = async () => {
    const { date: approvedDate } = approvedDateValue
    if (!lineItemProgress || approvedDate === null || totalValue === null) {
      return
    }

    const totalBilled =
      lineItemProgress.futureBilled +
      lineItemProgress.currentBilled +
      lineItemProgress.previousBilled

    if (isLumpSum && !isNewBilledInRange(totalBilled, totalValue)) {
      snackbar.showError(
        t(`${i18nBase}.total_too_high_error`, { totalBilled: centsToDollars(totalBilled) })
      )
      return
    }

    let sovLineItemGroup: SovLineItemGroupProperties | null | undefined = null
    if (groupId === 'new-group') {
      sovLineItemGroup = await createSovLineItemGroup()
      if (!sovLineItemGroup) {
        return
      }
    } else if (groupId) {
      sovLineItemGroup = existingGroups.find((group) => group.id === groupId)
    }
    const taxGroup = shouldIncludeTaxGroups
      ? (taxGroups.find((taxGroup) => taxGroup.id === taxGroupId) ?? null)
      : null
    let updatedTotalValue = totalValue
    if (isUnitPrice && _.isNumber(unitPrice)) {
      updatedTotalValue = roundCents(unitPrice * (unitsContracted ?? 0))
      const invalidUnitPriceError = assertValidLineItemUnitPrice(unitPrice, updatedTotalValue, t)
      if (invalidUnitPriceError) {
        snackbar.showError(invalidUnitPriceError)
        return
      }
    }
    updateChangeOrderMutation({
      variables: {
        input: {
          id: lineItemProgress.sovLineItem.id,
          payAppId: payApp.id,
          name,
          code,
          totalValue: updatedTotalValue,
          unitName: unitOfMeasure || undefined,
          unitPrice,
          approvedDate: approvedDate.toISOString(),
          sovLineItemGroupId: sovLineItemGroup?.id ?? null,
          taxGroupId: shouldIncludeTaxGroups ? taxGroupId : undefined,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateChangeOrder: {
          ...lineItemProgress,
          totalValue: updatedTotalValue,
          sovLineItem: {
            ...lineItemProgress.sovLineItem,
            name,
            code,
            latestTotalValue: updatedTotalValue,
            originalTotalValue: updatedTotalValue,
            sovLineItemGroup: sovLineItemGroup ?? null,
            changeOrderApprovedDate: approvedDate.toISOString(),
            unitName: unitOfMeasure || null,
            unitPrice,
            taxGroup,
          },
        },
      },
    })
    setOpen(false)
  }

  const deleteProgress = () => {
    if (!lineItemProgress) {
      return
    }

    // This is a safe cast because deleting change orders is only possible from the
    // invoice change orders dialog
    const payAppForProgress = payApp as PayAppForProgress
    deleteChangeOrderMutation({
      variables: {
        input: {
          id: lineItemProgress.sovLineItem.id,
          payAppId: payApp.id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteChangeOrder: payAppForProgress,
      },
    })
    setOpen(false)
  }

  const deleteProgressConfirm = () => {
    confirm({
      title: t(`${i18nBase}.add_dialog.delete_title`),
      details: t(`${i18nBase}.add_dialog.delete_details`),
      callback: (confirmed: boolean) => {
        if (!confirmed) {
          return
        }
        deleteProgress()
      },
    })
  }

  const canSubmit = useMemo(() => {
    if (showRetentionValue && (retentionPercent === '' || isNaN(Number(retentionPercent)))) {
      return false
    }
    if (groupId === 'new-group' && newGroupName === '') {
      return false
    }
    const allExist = name !== '' && code !== '' && !isMissingDate(approvedDateValue)
    if (!allExist) {
      return false
    }

    if (isLumpSum && totalValue === null) {
      return null
    }

    if (isUnitPrice) {
      // Unit name is optional
      const allUnitPriceExist = unitsContracted !== undefined && unitPrice !== null
      if (!allUnitPriceExist) {
        return false
      }
    }

    return true
  }, [
    approvedDateValue,
    showRetentionValue,
    retentionPercent,
    groupId,
    newGroupName,
    name,
    code,
    isLumpSum,
    totalValue,
    unitsContracted,
    unitPrice,
    isUnitPrice,
  ])

  return (
    <SitelineDialog
      title={
        lineItemProgress
          ? t(`${i18nBase}.add_dialog.edit_title`)
          : t(`${i18nBase}.add_dialog.add_title`)
      }
      open={open}
      onResetDialog={handleResetDialog}
      onClose={() => setOpen(false)}
      disableSubmit={!canSubmit}
      onSubmit={lineItemProgress ? submitUpdate : submitCreate}
      submitLabel={lineItemProgress ? t('common.actions.update') : t('common.actions.add')}
      submitting={isCreatingGroup || isCreatingChangeOrder}
      subscript={
        lineItemProgress ? (
          <Button variant="text" onClick={deleteProgressConfirm} className={classes.delete}>
            {t('common.actions.delete')}
          </Button>
        ) : undefined
      }
    >
      <div className={clsx(classes.inputs, { unitPrice: isUnitPrice })}>
        <div className="flex">
          <div className="code">
            <SitelineText variant="label">{t(`${i18nBase}.add_dialog.code`)}</SitelineText>
            <TextField
              variant="outlined"
              value={code}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
            />
          </div>
          <div className="name">
            <SitelineText variant="label">{t(`${i18nBase}.add_dialog.name`)}</SitelineText>
            <TextField
              variant="outlined"
              value={name}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            />
          </div>
          {isLumpSum && (
            <div className="amount">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.amount`)}</SitelineText>
              <NumericFormat
                className={classes.amountInput}
                value={totalValue !== null ? centsToDollars(totalValue) : ''}
                onValueChange={({ floatValue }) =>
                  setTotalValue(floatValue !== undefined ? dollarsToCents(floatValue) : null)
                }
                decimalScale={2}
                fixedDecimalScale
                displayType="input"
                thousandSeparator
                prefix="$"
                allowNegative
              />
            </div>
          )}
        </div>
        {isUnitPrice && (
          <div className="flex">
            <div className="unitOfMeasure">
              <SitelineText variant="label">
                {t(`${i18nBase}.add_dialog.unit_of_measure`)}
              </SitelineText>
              <TextField
                variant="outlined"
                value={unitOfMeasure}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUnitOfMeasure(event.target.value)
                }
              />
            </div>
            <div className="unitPrice">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.unit_price`)}</SitelineText>
              <NumericFormat
                className={classes.amountInput}
                value={unitPrice !== null ? centsToDollars(unitPrice) : ''}
                onValueChange={({ floatValue }) =>
                  setUnitPrice(floatValue !== undefined ? dollarsToCents(floatValue) : null)
                }
                decimalScale={2}
                fixedDecimalScale
                displayType="input"
                thousandSeparator
                prefix="$"
                allowNegative
              />
            </div>
            <div className="unitsContracted">
              <SitelineText variant="label">
                {t(`${i18nBase}.add_dialog.units_contracted`)}
              </SitelineText>
              <TextField
                variant="outlined"
                value={unitsContracted}
                type="number"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setUnitsContracted(event.target.value ? Number(event.target.value) : undefined)
                }}
              />
            </div>
          </div>
        )}
        <div className="flex">
          <div className="group">
            <SitelineText variant="label">{t(`${i18nBase}.add_dialog.group`)}</SitelineText>
            <Select
              variant="outlined"
              fullWidth
              value={groupId ?? ''}
              onChange={(event) => setGroupId(event.target.value as string)}
              displayEmpty
              renderValue={(groupId) => {
                if (!groupId) {
                  return (
                    <span className={classes.noGroup}>{t(`${i18nBase}.add_dialog.no_group`)}</span>
                  )
                }
                if (groupId === 'new-group') {
                  return t(`${i18nBase}.add_dialog.new_group`)
                }
                const group = existingGroups.find((group) => group.id === groupId)
                return group?.name
              }}
            >
              <MenuItem value="">{t(`${i18nBase}.add_dialog.no_group`)}</MenuItem>
              <MenuItem value="new-group">{t(`${i18nBase}.add_dialog.new_group`)}</MenuItem>
              {existingGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="date">
            <SitelineText variant="label">{t(`${i18nBase}.add_dialog.date`)}</SitelineText>
            <div>
              <DatePickerInput
                value={approvedDateValue}
                onChange={setApprovedDateValue}
                timeZone={timeZone}
                slots={{
                  leftArrowIcon: () => (
                    <Tooltip title={t(`${i18nBase}.arrow_tooltip`)} placement="top">
                      <ChevronLeftIcon />
                    </Tooltip>
                  ),
                  rightArrowIcon: () => (
                    <Tooltip title={t(`${i18nBase}.arrow_tooltip`)} placement="top">
                      <ChevronRightIcon />
                    </Tooltip>
                  ),
                }}
                slotProps={{
                  previousIconButton: { style: { pointerEvents: 'auto' } },
                  nextIconButton: { style: { pointerEvents: 'auto' } },
                }}
              />
            </div>
          </div>
          {showRetentionValue && !lineItemProgress && (
            <div className="retentionOrTaxGroup">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.retention`)}</SitelineText>
              <TextField
                variant="outlined"
                value={retentionPercent ? decimalToPercent(Number(retentionPercent), 2) : ''}
                type="number"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value
                  // Disallow the letter 'e', which is technically allowed by numeric inputs
                  if (value === 'e' || isNaN(Number(value))) {
                    return
                  }
                  const decimal = taxGroupPercentToDecimal(Number(value))
                  setRetentionPercent(decimal.toString())
                }}
              />
            </div>
          )}
          {shouldIncludeTaxGroups && (
            <div className="retentionOrTaxGroup">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.tax_group`)}</SitelineText>
              <Select
                value={taxGroupId ?? ''}
                onChange={(evt) => setTaxGroupId(evt.target.value)}
                displayEmpty
                renderValue={(value) => (
                  <SitelineText variant="body1" color={value ? 'grey90' : 'grey50'}>
                    {formatTaxGroupLabel(value, taxGroups, t)}
                  </SitelineText>
                )}
              >
                <MenuItem value="">{t('common.none')}</MenuItem>
                {taxGroups.map((taxGroup) => (
                  <MenuItem key={taxGroup.id} value={taxGroup.id}>
                    {formatTaxGroupLabel(taxGroup.id, taxGroups, t)}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        <Collapse in={groupId === 'new-group'}>
          <div className="flex">
            <div className="groupCode">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.group_code`)}</SitelineText>
              <TextField
                variant="outlined"
                value={newGroupCode}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setNewGroupCode(event.target.value)
                }
              />
            </div>
            <div className="groupName">
              <SitelineText variant="label">{t(`${i18nBase}.add_dialog.group_name`)}</SitelineText>
              <TextField
                variant="outlined"
                value={newGroupName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setNewGroupName(event.target.value)
                }
              />
            </div>
          </div>
        </Collapse>
      </div>
    </SitelineDialog>
  )
}
