export const CHANGE_ORDER_REQUEST_NAME_MAX_CHARS = 150

/**
 * All change order requests will have a rate table attached to them if a rate table has been
 * selected at the project level. This function determines whether or not the change order request
 * actually uses the pricing tool to calculate the COR amount (vs an amount being manually entered).
 */
export function doesChangeOrderRequestUsePricingTool(
  rateTableItems: readonly { currentPriced: number }[]
): boolean {
  return rateTableItems.some((item) => item.currentPriced !== 0)
}
