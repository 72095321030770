import { FormControlLabel, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormTemplateOnboardingSignatureType,
  MAX_ATTACHMENT_NOTES_LENGTH,
} from 'siteline-common-all'
import { ProjectOnboardingFormType, SitelineText, makeStylesFast } from 'siteline-common-web'
import { FormUploadBestPracticesHelperText } from '../../../common/components/FormUploadBestPracticesHelperText'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { Spacer } from '../../../common/components/Spacer'
import {
  ContractOnboardingFormType,
  OnboardingFormsInstructions,
} from '../../../common/util/ProjectOnboarding'
import { FileDragUpload, PendingFile } from '../backup/attachments/FileDragUpload'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'

const i18nBase = 'projects.onboarding.checklist.form_upload_dialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .examples': {
      whiteSpace: 'pre-line',
    },
    '& .inputHeading': {
      margin: theme.spacing(2.5, 0, 1),
    },
    '& .inputSubheading': {
      margin: theme.spacing(-0.5, 0, 1.5),
    },
    '& .signatureTypes': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        width: '50%',
        marginRight: 0,
        '& .MuiCheckbox-root': {
          padding: theme.spacing(1),
        },
      },
    },
  },
}))

const SLOT_PROPS = { htmlInput: { maxLength: MAX_ATTACHMENT_NOTES_LENGTH } }
const sortedSignatureTypes = [
  FormTemplateOnboardingSignatureType.DIGITAL_SIGNATURE,
  FormTemplateOnboardingSignatureType.WET_SIGNATURE,
  FormTemplateOnboardingSignatureType.NONE,
  FormTemplateOnboardingSignatureType.NOTARIZATION,
]

type FormInputInstructionSection =
  | 'formValues'
  | 'repeatingInputs'
  | 'specialConditions'
  | 'additionalInfo'

function instructionSectionsForFormsType(
  formsType: ContractOnboardingFormType
): FormInputInstructionSection[] {
  switch (formsType) {
    case ProjectOnboardingFormType.PAY_APP:
      return ['formValues', 'repeatingInputs', 'specialConditions', 'additionalInfo']
    case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
      return ['formValues', 'repeatingInputs', 'additionalInfo']
    case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
      return ['formValues', 'additionalInfo']
    case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
      return ['formValues', 'additionalInfo']
    case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
      return ['formValues', 'additionalInfo']
  }
}

interface OnboardingFormUploadDialogProps {
  formsType: ContractOnboardingFormType
  onUploadForms: (
    pendingFiles: PendingFile[],
    instructions: OnboardingFormsInstructions,
    includeChangeOrderLogOnPayApps?: boolean
  ) => Promise<void>
  open: boolean
  /** Exits the dialog. `fromButton` is true if triggered by tapping the Cancel button. */
  onClose: (fromButton: boolean) => void
  cancelLabel?: string
  submitting?: boolean
  helpCenterLinkScrollTo?: string
  /** Pass in undefined if this dialog is NOT for change order log forms */
  includeChangeOrderLogInPayAppPackage: boolean | undefined
}

/** Dialog box for uploading new form templates during project onboarding */
export function OnboardingFormUploadDialog({
  onUploadForms,
  open,
  onClose,
  cancelLabel,
  formsType,
  submitting,
  helpCenterLinkScrollTo,
  includeChangeOrderLogInPayAppPackage,
}: OnboardingFormUploadDialogProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [pendingFiles, setPendingFiles] = useState<PendingFile[]>([])
  const [repeatingValuesNote, setRepeatingValuesNote] = useState<string>('')
  const [formValuesNote, setFormValuesNote] = useState<string>('')
  const [specialFormsNote, setSpecialFormsNote] = useState<string>('')
  const [additionalInfoNote, setAdditionalInfoNote] = useState<string>('')
  const [selectedSignatureTypes, setSelectedSignatureTypes] = useState<
    FormTemplateOnboardingSignatureType[]
  >([])
  const [includeCOLogOnPayApps, setIncludeCOLogOnPayApps] = useState<boolean>(
    !!includeChangeOrderLogInPayAppPackage
  )

  const requiresSignatureType = formsType !== ProjectOnboardingFormType.CHANGE_ORDER_LOG

  const shouldDisplayIncludeCoLogToggle =
    formsType === ProjectOnboardingFormType.CHANGE_ORDER_LOG &&
    includeChangeOrderLogInPayAppPackage !== undefined

  const handleUpdatePendingFiles = useCallback(
    (update: PendingFile[]) => {
      if (pendingFiles.length === 0 && update.length > 0) {
        setIncludeCOLogOnPayApps(true)
      }
      if (update.length === 0) {
        setIncludeCOLogOnPayApps(false)
      }
      setPendingFiles(update)
    },
    [pendingFiles.length]
  )

  const handleRemovePendingFiles = useCallback(
    (index: number) => {
      const pendingFilesCopy = [...pendingFiles]
      pendingFilesCopy.splice(index, 1)
      handleUpdatePendingFiles(pendingFilesCopy)
    },
    [handleUpdatePendingFiles, pendingFiles]
  )

  const handleUploadSubmit = useCallback(() => {
    const instructions: OnboardingFormsInstructions = {
      signatureTypes: requiresSignatureType
        ? selectedSignatureTypes
        : [FormTemplateOnboardingSignatureType.NONE],
      // Pass undefined if any of the optional fields are empty
      repeatingValuesNote: repeatingValuesNote || undefined,
      formValuesNote: formValuesNote || undefined,
      specialFormsNote: specialFormsNote || undefined,
      note: additionalInfoNote || undefined,
    }
    const includeChangeOrderLogOnPayApps = shouldDisplayIncludeCoLogToggle
      ? includeCOLogOnPayApps
      : undefined
    onUploadForms(pendingFiles, instructions, includeChangeOrderLogOnPayApps).then(() => {
      handleUpdatePendingFiles([])
      onClose(false)
    })
  }, [
    additionalInfoNote,
    formValuesNote,
    handleUpdatePendingFiles,
    includeCOLogOnPayApps,
    onClose,
    onUploadForms,
    pendingFiles,
    repeatingValuesNote,
    requiresSignatureType,
    selectedSignatureTypes,
    shouldDisplayIncludeCoLogToggle,
    specialFormsNote,
  ])

  const handleToggleSignatureTypeSelected = useCallback(
    (signatureType: FormTemplateOnboardingSignatureType, selected: boolean) => {
      const otherSelectedSignatureTypes = selectedSignatureTypes.filter(
        (selectedSignatureType) => selectedSignatureType !== signatureType
      )
      if (selected) {
        setSelectedSignatureTypes([...otherSelectedSignatureTypes, signatureType])
      } else {
        setSelectedSignatureTypes(otherSelectedSignatureTypes)
      }
    },
    [selectedSignatureTypes]
  )

  const resetDialog = useCallback(() => {
    setPendingFiles([])
    setRepeatingValuesNote('')
    setFormValuesNote('')
    setSpecialFormsNote('')
    setAdditionalInfoNote('')
    setSelectedSignatureTypes([])
    setIncludeCOLogOnPayApps(!!includeChangeOrderLogInPayAppPackage)
  }, [includeChangeOrderLogInPayAppPackage])

  const { title, subtitle, subscript } = useMemo(() => {
    switch (formsType) {
      case ProjectOnboardingFormType.PAY_APP:
        return {
          title: t(`${i18nBase}.upload_pay_app_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.pay_app_upload_reminder`),
        }
      case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
        return {
          title: t(`${i18nBase}.upload_primary_lien_waiver_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.primary_lien_waiver_upload_reminder`),
        }
      case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
        return {
          title: t(`${i18nBase}.upload_vendor_lien_waiver_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.vendor_lien_waiver_upload_reminder`),
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
        return {
          title: t(`${i18nBase}.upload_cor_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.cor_upload_reminder`),
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
        return {
          title: t(`${i18nBase}.upload_cor_log_forms_title`),
          subtitle: t(`${i18nBase}.upload_cor_log_forms_subtitle`),
          subscript: shouldDisplayIncludeCoLogToggle ? (
            <IncludeChangeOrderLogInPayAppToggle
              shouldInclude={includeCOLogOnPayApps}
              onChange={setIncludeCOLogOnPayApps}
              disabled={false}
              shouldShowDisabledTooltip={false}
            />
          ) : undefined,
        }
    }
  }, [formsType, includeCOLogOnPayApps, shouldDisplayIncludeCoLogToggle, t])

  const instructionSections = instructionSectionsForFormsType(formsType)

  const hasUploadedFiles = pendingFiles.length > 0
  const hasSelectedSignatureType = selectedSignatureTypes.length > 0
  const isSubmitDisabled = !hasUploadedFiles || (!hasSelectedSignatureType && requiresSignatureType)

  return (
    <SitelineDialog
      open={open}
      onSubmit={handleUploadSubmit}
      onClose={onClose}
      cancelLabel={cancelLabel}
      title={title}
      subtitle={
        <>
          <Spacer minHeight={8} maxHeight={8} />
          <FormUploadBestPracticesHelperText
            helpCenterLinkScrollTo={helpCenterLinkScrollTo}
            prefix={subtitle}
          />
        </>
      }
      submitLabel={t(`${i18nBase}.submit_forms`)}
      size="email"
      disableSubmit={isSubmitDisabled}
      disableSubmitTooltip={
        hasUploadedFiles && !hasSelectedSignatureType && requiresSignatureType
          ? t(`${i18nBase}.disable_submit_tooltip`)
          : undefined
      }
      className={classes.root}
      onResetDialog={resetDialog}
      subscript={subscript}
      submitting={submitting}
    >
      <FileDragUpload
        pendingFiles={pendingFiles}
        setPendingFiles={handleUpdatePendingFiles}
        allowMultiple
        onRemovePendingFile={handleRemovePendingFiles}
        size={hasUploadedFiles ? 'small' : 'medium'}
        layout={hasUploadedFiles ? 'row' : 'column'}
      />
      {hasUploadedFiles && (
        <>
          <SitelineText color="grey50" variant="body2" className="examples">
            {t(`${i18nBase}.upload_instructions_examples`)}
          </SitelineText>
          {requiresSignatureType && (
            <>
              <SitelineText variant="h4" color="grey90" className="inputHeading">
                {t(`${i18nBase}.signature_type`)}
              </SitelineText>
              <div className="signatureTypes">
                {sortedSignatureTypes.map((signatureType) => {
                  const shouldDisableIfSelectedNone =
                    signatureType !== FormTemplateOnboardingSignatureType.NONE &&
                    selectedSignatureTypes.includes(FormTemplateOnboardingSignatureType.NONE)
                  const shouldDisableIfSelectedAny =
                    signatureType === FormTemplateOnboardingSignatureType.NONE &&
                    selectedSignatureTypes.length > 0 &&
                    selectedSignatureTypes[0] !== FormTemplateOnboardingSignatureType.NONE
                  const shouldDisable = shouldDisableIfSelectedNone || shouldDisableIfSelectedAny
                  return (
                    <FormControlLabel
                      key={signatureType}
                      control={
                        <SitelineCheckbox
                          name={signatureType}
                          value={selectedSignatureTypes.includes(signatureType)}
                          checked={selectedSignatureTypes.includes(signatureType)}
                          onChange={(ev, checked) => {
                            handleToggleSignatureTypeSelected(signatureType, checked)
                          }}
                          disabled={shouldDisable}
                        />
                      }
                      label={t(`${i18nBase}.signature_types.${signatureType}`)}
                      className="signatureType"
                    />
                  )
                })}
              </div>
            </>
          )}
          {instructionSections.includes('formValues') && (
            <>
              <SitelineText variant="h4" color="grey90" className="inputHeading">
                {t(`${i18nBase}.form_values`)}
              </SitelineText>
              <SitelineText variant="body1" color="grey50" className="inputSubheading">
                {t(`${i18nBase}.form_values_subtitle`)}
              </SitelineText>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                value={formValuesNote}
                placeholder={
                  formsType === ProjectOnboardingFormType.PAY_APP
                    ? t(`${i18nBase}.form_values_placeholder_pay_app`)
                    : t(`${i18nBase}.form_values_placeholder`)
                }
                onChange={(event) => setFormValuesNote(event.target.value)}
                slotProps={SLOT_PROPS}
              />
            </>
          )}
          {instructionSections.includes('repeatingInputs') && (
            <>
              <SitelineText variant="h4" color="grey90" className="inputHeading">
                {t(`${i18nBase}.repeating_inputs`)}
              </SitelineText>
              <SitelineText variant="body1" color="grey50" className="inputSubheading">
                {t(`${i18nBase}.repeating_inputs_subtitle`)}
              </SitelineText>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                placeholder={t(`${i18nBase}.repeating_inputs_placeholder`)}
                value={repeatingValuesNote}
                onChange={(event) => setRepeatingValuesNote(event.target.value)}
                slotProps={SLOT_PROPS}
              />
            </>
          )}
          {instructionSections.includes('specialConditions') && (
            <>
              <SitelineText variant="h4" color="grey90" className="inputHeading">
                {t(`${i18nBase}.special_conditions`)}
              </SitelineText>
              <SitelineText variant="body1" color="grey50" className="inputSubheading">
                {t(`${i18nBase}.special_conditions_subtitle`)}
              </SitelineText>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                value={specialFormsNote}
                placeholder={t(`${i18nBase}.special_conditions_placeholder`)}
                onChange={(event) => setSpecialFormsNote(event.target.value)}
                slotProps={SLOT_PROPS}
              />
            </>
          )}
          {instructionSections.includes('additionalInfo') && (
            <>
              <SitelineText variant="h4" color="grey90" className="inputHeading">
                {t(`${i18nBase}.additional_info`)}
              </SitelineText>
              <SitelineText variant="body1" color="grey50" className="inputSubheading">
                {t(`${i18nBase}.additional_info_subtitle`)}
              </SitelineText>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                value={additionalInfoNote}
                placeholder={t(`${i18nBase}.additional_info_placeholder`)}
                onChange={(event) => setAdditionalInfoNote(event.target.value)}
                slotProps={SLOT_PROPS}
              />
            </>
          )}
        </>
      )}
    </SitelineDialog>
  )
}
