import { NavigateOptions } from '@tanstack/react-router'
import { UserForAvatar } from '../../common/components/UserAvatarsTableCell'
import {
  ContractForBillingDashboardProperties,
  DashboardFiltersInput,
  OnboardingContractsQuery,
} from '../../common/graphql/apollo-operations'

export enum ReportingOverviewCard {
  PROJECTS_BILLED = 'projectsBilled',
  MONTHLY_BILLING_CHART = 'monthlyBillingChart',
  PAY_APPS_OUTSTANDING = 'payAppsOutstanding',
  LEIN_WAIVERS_OUTSTANDING = 'lienWaiversOutstanding',
}

export enum ReportingTab {
  OVERVIEW = 'overview',
  BILLING = 'billing',
  AGING = 'aging',
  FORECAST = 'forecast',
  CASH_FORECAST = 'cashForecast',
}

export const REPORTING_TABS = [
  ReportingTab.OVERVIEW,
  ReportingTab.BILLING,
  ReportingTab.AGING,
  ReportingTab.FORECAST,
  ReportingTab.CASH_FORECAST,
]

type GetReportingPathParams = {
  tab?: ReportingTab
  viewingYear?: number
  viewingMonth?: number
  viewingContractId?: string
}

export enum OverduePaymentsFilterType {
  ALL = 'ALL',
  OVERDUE = 'OVERDUE',
}

/**
 * Returns a reporting url based on tab & query params.
 * If none are provided, returns the home page `/reporting/overview`
 */
export function getReportingPath({
  tab,
  viewingMonth,
  viewingYear,
  viewingContractId,
}: GetReportingPathParams): NavigateOptions {
  // The forecast tabs do not support filtering by month
  const isMonthFilterApplied =
    viewingMonth &&
    viewingYear &&
    tab !== ReportingTab.FORECAST &&
    tab !== ReportingTab.CASH_FORECAST

  // The contract filter allows us to open comments on a specific contract,
  // which is only supported on aging & cash forecasting
  const isContractFilterApplied =
    viewingContractId && (tab === ReportingTab.AGING || tab === ReportingTab.CASH_FORECAST)

  const search: Record<string, unknown> = {}
  if (isMonthFilterApplied) {
    search.year = viewingYear
    // The month in the URL is non-zero-indexed; viewingMonth is zero-indexed
    search.month = viewingMonth + 1
  }
  if (isContractFilterApplied) {
    search.contractId = viewingContractId
  }

  return { to: '/reporting/$tab', params: { tab }, search }
}

type ContractWithLeadPMs = Pick<ContractForBillingDashboardProperties, 'leadPMs'>
type ProjectWithLeadPMs = { contract: ContractWithLeadPMs }

/**
 * Our aging and billing tables display lead PM info. This function formats the project object
 * to allow for 1) search by PM, and 2) easier lookup of PM when rendering the User Avatar PM cell
 */
function derivePMsFromProject<T extends ProjectWithLeadPMs>(project: T) {
  const leadPMs = project.contract.leadPMs
  const userAvatarPMs = leadPMs.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  }))
  const searchablePMs = userAvatarPMs.map((user) => `${user.firstName} ${user.lastName}`).join(', ')
  return { ...project, userAvatarPMs, searchablePMs }
}

export type ContractForOnboardingProjects =
  OnboardingContractsQuery['paginatedContracts']['contracts'][number]

/**
 * Our onboarding table displays lead PM info. This function formats the project object
 * to allow for 1) search by PM, and 2) easier lookup of PM when rendering the User Avatar PM cell
 */
function derivePMsFromContract(contract: ContractForOnboardingProjects) {
  const leadPMs = contract.leadPMs
  const userAvatarPMs = leadPMs.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  }))
  const searchablePMs = userAvatarPMs.map((user) => `${user.firstName} ${user.lastName}`).join(', ')
  return { ...contract, userAvatarPMs, searchablePMs }
}

export function buildProjectWithPMs<T extends ProjectWithLeadPMs>(
  project: T
): T & { userAvatarPMs: UserForAvatar[]; searchablePMs: string } {
  return { ...project, ...derivePMsFromProject(project) }
}

export function buildContractWithPMs<T extends ContractForOnboardingProjects>(
  contract: T
): T & { userAvatarPMs: UserForAvatar[]; searchablePMs: string } {
  return { ...contract, ...derivePMsFromContract(contract) }
}

export const EMPTY_QUERY_FILTERS: DashboardFiltersInput = {
  agingAmountType: undefined,
  billingType: undefined,
  generalContractorIds: undefined,
  leadPMIds: undefined,
  officeIds: undefined,
  payAppStatusFilter: undefined,
  projectStatus: undefined,
}

export enum BillingView {
  NET = 'NET',
  GROSS = 'GROSS',
}
