import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, Slide } from '@mui/material'
import { styled } from '@mui/material/styles'
import { memo } from 'react'
import { colors, SitelineText } from 'siteline-common-web'
import { Z_INDEX } from '../themes/Main'
import { Row } from './Row'

const StyledAgaveConnectorOfflinePopup = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),
  left: 0,
  right: 0,
  zIndex: Z_INDEX.popover,
  display: 'flex',
  justifyContent: 'center',
  pointerEvents: 'none',
  '& .container': {
    position: 'relative',
    backgroundColor: colors.red10,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 6, 2, 3),
    maxWidth: 700,
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'auto',
    '& .warningIcon': {
      color: colors.red70,
    },
    '& .dismiss': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      '& .MuiSvgIcon-root': {
        color: colors.grey70,
      },
    },
  },
}))

interface AgaveConnectorOfflinePopupProps {
  isOpen: boolean
  onClose: () => void
  error: string | null
}

/** Popup for notifying the user when the Agave Connector is offline */
export const AgaveConnectorOfflinePopup = memo(function AgaveConnectorOfflinePopup({
  isOpen,
  onClose,
  error,
}: AgaveConnectorOfflinePopupProps) {
  return (
    <Slide
      direction="down"
      in={isOpen && error !== null}
      style={{ transformOrigin: '75% 75%' }}
      easing="ease-out"
    >
      <StyledAgaveConnectorOfflinePopup>
        <div className="container">
          <Row alignItems="center" gap={16}>
            <WarningIcon className="warningIcon" />
            <SitelineText variant="body1">{error}</SitelineText>
          </Row>
          <IconButton className="dismiss" onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledAgaveConnectorOfflinePopup>
    </Slide>
  )
})
