import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText } from 'siteline-common-web'
import { VariantAndVersion } from '../../../common/util/Forms'
import { FormTemplatePreviewDialog } from './FormTemplatePreviewDialog'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'

interface PreviewDefaultFormsDialogProps {
  open: boolean
  /** Exits the dialog. `fromButton` is true if triggered by tapping the Cancel button. */
  onClose: (fromButton: boolean) => void
  onConfirm: (includeChangeOrderLogOnPayApps?: boolean) => void
  defaultFormTemplates: VariantAndVersion[]
  hasConditionalDefaultForms?: boolean
  submitting?: boolean
  /** Pass in undefined if this dialog is NOT for change order log forms */
  includeChangeOrderLogInPayAppPackage: boolean | undefined
}

const i18nBase = 'projects.subcontractors.settings.forms'

/** Dialog for previewing a company's default pay app or lien waiver forms */
export function PreviewDefaultFormsDialog({
  open,
  onClose,
  onConfirm,
  defaultFormTemplates,
  hasConditionalDefaultForms,
  submitting,
  includeChangeOrderLogInPayAppPackage,
}: PreviewDefaultFormsDialogProps) {
  const { t } = useTranslation()

  const [includeCOLogOnPayApps, setIncludeCOLogOnPayApps] = useState<boolean>(
    !!includeChangeOrderLogInPayAppPackage
  )

  const shouldDisplayIncludeCoLogToggle = includeChangeOrderLogInPayAppPackage !== undefined

  const handleConfirm = useCallback(() => {
    if (!open || submitting) {
      return
    }
    const includeChangeOrderLogOnPayApps = shouldDisplayIncludeCoLogToggle
      ? includeCOLogOnPayApps
      : undefined
    onConfirm(includeChangeOrderLogOnPayApps)
  }, [open, submitting, shouldDisplayIncludeCoLogToggle, includeCOLogOnPayApps, onConfirm])

  const handleClose = useCallback(
    (fromButton: boolean) => {
      setIncludeCOLogOnPayApps(!!includeChangeOrderLogInPayAppPackage)
      onClose(fromButton)
    },
    [includeChangeOrderLogInPayAppPackage, onClose]
  )

  return (
    <FormTemplatePreviewDialog
      open={open}
      onSubmit={handleConfirm}
      onClose={handleClose}
      title={t(`${i18nBase}.preview_default_forms`)}
      submitLabel={t('common.actions.confirm')}
      formTemplates={defaultFormTemplates}
      submitting={submitting}
      isProcessingForms={false}
      subscript={
        shouldDisplayIncludeCoLogToggle ? (
          <IncludeChangeOrderLogInPayAppToggle
            shouldInclude={includeCOLogOnPayApps}
            onChange={setIncludeCOLogOnPayApps}
            disabled={false}
            shouldShowDisabledTooltip={false}
          />
        ) : undefined
      }
    >
      {hasConditionalDefaultForms && (
        <SitelineText variant="body1" color="grey50">
          {t(`${i18nBase}.conditional_forms`)}
        </SitelineText>
      )}
    </FormTemplatePreviewDialog>
  )
}
