import { UserProps } from '../contexts/UserContext'
import { CompanyUserProperties, GetCompanyProjectsQuery } from '../graphql/apollo-operations'

export function getInitials({
  firstName,
  lastName,
}: {
  firstName: string
  lastName?: string
}): string {
  return `${firstName ? firstName.substring(0, 1) : ''}${lastName ? lastName.substring(0, 1) : ''}`
}

const sitelineEmailDomain = '@siteline.com'

/**
 * Filter out Siteline emails so customers don't see admin accounts as team
 * members. (Unless the current user is internal, then include all contacts.)
 */
export function filterOutSitelineEmails<T extends Pick<CompanyUserProperties, 'isSitelineAdmin'>>(
  currentUser: UserProps,
  contacts: T[]
): T[] {
  if (currentUser.email.endsWith(sitelineEmailDomain)) {
    return contacts
  }
  return contacts.filter((contact) => !contact.isSitelineAdmin)
}

/** Creates an object of project Ids, searchable by user id */
export function buildProjectIdsByUserIdMap(
  companyId: string,
  projectsData?: GetCompanyProjectsQuery
) {
  const map: Partial<Record<string, string[]>> = {}

  if (projectsData) {
    projectsData.companyProjects.forEach(({ contracts, id: projectId }) => {
      contracts.forEach(({ users, company }) => {
        if (company.id === companyId) {
          users.forEach(({ user }) => {
            map[user.id] ??= []
            map[user.id]?.push(projectId)
          })
        }
      })
    })
  }

  return map
}
