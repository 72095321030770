/* eslint-disable no-case-declarations */
import _ from 'lodash'
import { fuseSearch } from 'siteline-common-web'
import {
  RetentionTrackingLevel,
  SovLineItemGroupProperties,
  SovLineItemProgressProperties,
} from '../../../common/graphql/apollo-operations'
import { usesStandardOrLineItemTracking } from '../../../common/util/Retention'

type Filter = {
  search: string
  viewAll: boolean
}

export enum RetentionView {
  HELD_TO_DATE_AMOUNT = 'HELD_TO_DATE_AMOUNT',
  HELD_CURRENT_AMOUNT = 'HELD_CURRENT_AMOUNT',
  /** For standard retention, we show the current pay app retention percent */
  HELD_CURRENT_PERCENT = 'HELD_CURRENT_PERCENT',
  /** For line item retention, we show the total retention percent to date */
  HELD_TO_DATE_PERCENT = 'HELD_TO_DATE_PERCENT',
  RELEASED_CURRENT_AMOUNT = 'RELEASED_CURRENT_AMOUNT',
}

export enum TaxesView {
  TOTAL_POST_TAX = 'TOTAL_POST_TAX',
  TAX_AMOUNT = 'TAX_AMOUNT',
  TAX_RATE = 'TAX_RATE',
}

export enum StoredMaterialsView {
  AMOUNT = 'amount',
  UNIT = 'unit',
}

export type InvoiceReducerState = {
  payAppId: string
  /** All progress is a full list of valid progresses */
  allProgress: SovLineItemProgressProperties[]
  /** Visible progress is all the visible progresses */
  visibleProgress: SovLineItemProgressProperties[]
  /** All groups is a full list of groups on the SOV */
  allGroups: SovLineItemGroupProperties[]
  /** Visible groups is all the visible groups */
  visibleGroups: SovLineItemGroupProperties[]
  filter: Filter
  retentionTrackedPerProgress: boolean
  isRetentionOnly: boolean
  roundRetention: boolean
  /** How the retention values are viewed on the invoice page ($ or %) */
  retentionView?: RetentionView
  /** How the taxes are viewed on the invoice page */
  taxesView?: TaxesView
  /** Whether the stored materials are being viewed in dollars or units */
  storedMaterialsView: StoredMaterialsView
}

export type InvoiceAction =
  /**  Updates which items are currently visible on the screen */
  | {
      type: 'UPDATE_VISIBLE_PROGRESS'
      progress: SovLineItemProgressProperties[]
      groups: SovLineItemGroupProperties[]
      /**  Whether to initialize the full list of progresses as well */
      reinitializeAllProgress: boolean
      payAppId?: string
    }
  /**  The filter was changed */
  | { type: 'UPDATE_FILTER'; filter: Filter }
  /**  Changes how the pay app tracks retention */
  | { type: 'TRACK_RETENTION'; newTracked: RetentionTrackingLevel; isRetentionOnly: boolean }
  /**  Changes how the retention values are viewed ($ or %) */
  | { type: 'UPDATE_RETENTION_VIEW'; newView: RetentionView }
  /** Changes how stored materials are viewed (dollars or units) */
  | { type: 'UPDATE_STORED_MATERIALS_VIEW'; newView: StoredMaterialsView }
  /**  Changes how taxes are viewed (tax amount vs total with tax vs tax rate) */
  | { type: 'UPDATE_TAXES_VIEW'; taxesView: TaxesView }

const applyProgressFilter = (
  filter: Filter,
  allProgress: SovLineItemProgressProperties[],
  allGroups: SovLineItemGroupProperties[],
  retentionTrackedPerProgress: boolean
) => {
  let filteredProgress = [...allProgress]

  // View all or just this month
  if (filter.viewAll === false) {
    filteredProgress = filteredProgress.filter(
      (progress) =>
        progress.currentBilled !== 0 ||
        (retentionTrackedPerProgress && progress.previousRetentionBilled !== 0)
    )
  }

  // Find groups that match the search term
  const searchProgress = fuseSearch(
    filteredProgress,
    filter.search,
    ['sovLineItem.name', 'sovLineItem.code'],
    { ignoreLocation: true }
  )
  const searchGroups = fuseSearch(allGroups, filter.search, ['name', 'code'], {
    ignoreLocation: true,
  })

  // Include progress that either matches the search, or is under a group that matches the search
  const filteredGroupIds = searchGroups.map((group) => group.id)
  filteredProgress = _.uniqBy(
    [
      ...filteredProgress.filter((progress) =>
        filteredGroupIds.includes(progress.sovLineItem.sovLineItemGroup?.id ?? '')
      ),
      ...searchProgress,
    ],
    (progress) => progress.id
  )

  // Include all groups that have progress shown
  const filteredGroups =
    filter.search || !filter.viewAll
      ? allGroups.filter((group) =>
          filteredProgress.some(
            (progress) => progress.sovLineItem.sovLineItemGroup?.id === group.id
          )
        )
      : allGroups

  return {
    filteredProgress,
    filteredGroups,
  }
}

export function invoiceReducer(
  state: InvoiceReducerState,
  action: InvoiceAction
): InvoiceReducerState {
  switch (action.type) {
    case 'UPDATE_VISIBLE_PROGRESS':
      const { filteredGroups, filteredProgress } = applyProgressFilter(
        state.filter,
        action.progress,
        action.groups,
        state.retentionTrackedPerProgress
      )
      return {
        ...state,
        visibleProgress: filteredProgress,
        visibleGroups: filteredGroups,
        ...(action.reinitializeAllProgress && {
          allProgress: action.progress,
          allGroups: action.groups,
        }),
        ...(action.payAppId && {
          payAppId: action.payAppId,
        }),
      }
    case 'UPDATE_FILTER': {
      const { filteredProgress, filteredGroups } = applyProgressFilter(
        action.filter,
        state.allProgress,
        state.allGroups,
        state.retentionTrackedPerProgress
      )
      return {
        ...state,
        filter: action.filter,
        visibleProgress: filteredProgress,
        visibleGroups: filteredGroups,
      }
    }
    case 'TRACK_RETENTION': {
      // There are various ways to track retention on a project. If you are tracking by line item
      // progress, then we need to display more/different columns on the invoice. How a project is
      // being tracked comes off the Contract object, which is loaded
      // asynchronously alongside the pay app.
      const retentionTrackedPerProgress = usesStandardOrLineItemTracking(action.newTracked)
      return {
        ...state,
        retentionTrackedPerProgress,
        isRetentionOnly: action.isRetentionOnly,
      }
    }
    case 'UPDATE_RETENTION_VIEW': {
      const retentionView = state.retentionTrackedPerProgress ? action.newView : undefined
      return {
        ...state,
        retentionView,
      }
    }
    case 'UPDATE_STORED_MATERIALS_VIEW': {
      return {
        ...state,
        storedMaterialsView: action.newView,
      }
    }
    case 'UPDATE_TAXES_VIEW': {
      return {
        ...state,
        taxesView: action.taxesView,
      }
    }
  }
}
