import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import * as fragments from '../../../common/graphql/Fragments'
import { useClearChangeOrderLogFormsMutation } from '../../../common/graphql/apollo-operations'
import {
  CompanyWithForms,
  deriveChangeOrderLogTemplateFromContract,
  deriveDefaultChangeOrderLogTemplateFromContract,
} from '../../../common/util/Forms'
import {
  deriveFormSelectionStatusFromContract,
  getClearOnboardingFormsRefetchQuery,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import { OnboardingFormsSection } from './OnboardingFormsSection'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

gql`
  mutation clearChangeOrderLogForms($contractId: ID!) {
    clearChangeOrderLogForms(contractId: $contractId) {
      id
      changeOrderLogTemplate {
        id
      }
      includeChangeOrderLogInPayAppPackage
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${fragments.onboardedProjectContractStatus}
`

const i18nBase = 'projects.subcontractors.settings.forms'

const FORM_TYPE = ProjectOnboardingFormType.CHANGE_ORDER_LOG

interface ChangeOrderLogFormsTaskProps {
  contract: ContractForProjectOnboarding
  isEditing?: boolean
  company: CompanyWithForms
  shouldConfirmFormDeletion?: boolean
  shouldDisplayIncludeCoLogToggle: boolean
}

/** Under the onboarding checklist Forms task, section for selecting change order log forms */
export function ChangeOrderLogFormsSection({
  contract,
  isEditing = true,
  company,
  shouldConfirmFormDeletion,
  shouldDisplayIncludeCoLogToggle,
}: ChangeOrderLogFormsTaskProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const [clearForms] = useClearChangeOrderLogFormsMutation()

  const formSelectionStatus = useMemo(() => {
    return deriveFormSelectionStatusFromContract(FORM_TYPE, contract)
  }, [contract])

  const formTemplates = useMemo(() => {
    const template = deriveChangeOrderLogTemplateFromContract(contract)
    return template ? [template] : []
  }, [contract])

  const defaultFormTemplates = useMemo(() => {
    const template = deriveDefaultChangeOrderLogTemplateFromContract(company)
    return template ? [template] : []
  }, [company])

  const handleClearForms = useCallback(async () => {
    try {
      await clearForms({
        variables: { contractId: contract.id },
        update: (cache) => {
          // OnboardedStatus.selectedChangeOrderLogForms might change
          invalidateContractsAfterOnboardingStatusChange(cache)
        },
        ...getClearOnboardingFormsRefetchQuery(companyId),
      })
      snackbar.showSuccess(t(`${i18nBase}.cleared_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [clearForms, companyId, contract.id, snackbar, t])

  return (
    <OnboardingFormsSection
      onboardingFormType={FORM_TYPE}
      formTemplates={formTemplates}
      defaultFormTemplates={defaultFormTemplates}
      onClearForms={handleClearForms}
      isProcessingForms={formSelectionStatus.isProcessingForms}
      contract={contract}
      isEditing={isEditing}
      shouldConfirmFormDeletion={shouldConfirmFormDeletion}
      includeChangeOrderLogInPayAppPackage={
        shouldDisplayIncludeCoLogToggle
          ? !!contract.includeChangeOrderLogInPayAppPackage
          : undefined
      }
    />
  )
}
