import { Trans, useTranslation } from 'react-i18next'
import { SitelineText } from 'siteline-common-web'
import { Mailto } from '../../../common/components/Mailto'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { config } from '../../../common/config/constants'

const i18nBase = 'projects.subcontractors.settings.project_info.stored_materials'

interface DialogProps {
  open: boolean
  onClose: () => void
}

/**
 * Simple dialog displayed when the user attempts to enable manual stored materials tracking
 * on a project with forms that are not ready for manual stored materials tracking
 */
export function StoredMaterialsWarningDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation()

  return (
    <SitelineDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title={t(`${i18nBase}.warning_title`)}
    >
      <SitelineText variant="body1">
        <Trans
          i18nKey={`${i18nBase}.warning_body`}
          values={{ email: config.support.email }}
          components={{ emailLink: <Mailto email={config.support.email} /> }}
        />
      </SitelineText>
    </SitelineDialog>
  )
}
