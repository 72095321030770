import { Collapse, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { Row } from '../../../common/components/Row'
import { ContractPaymentTermsType } from '../../../common/graphql/apollo-operations'

const INPUT_WIDTH = 200
const PAYMENT_TERMS_GAP = 12
const useStyles = makeStylesFast((theme: Theme) => ({
  fixedWithInput: {
    width: INPUT_WIDTH,
  },
  paymentTermsRow: {
    '& .selectTerms': {
      width: INPUT_WIDTH,
      '&.isEmpty .MuiSelect-outlined': {
        color: colors.grey50,
      },
    },
    '& input': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      height: theme.spacing(5),
      width: 45,
      ...theme.typography.body1,
    },
    '&.missingTerms input': {
      borderColor: colors.red70,
    },
  },
  missingPaymentTerms: {
    marginTop: theme.spacing(0.5),
    marginLeft: INPUT_WIDTH + PAYMENT_TERMS_GAP,
  },
}))

const i18nBase = 'projects.onboarding.contract'
const menuProps: SelectProps['MenuProps'] = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  transformOrigin: {
    horizontal: 'left',
    vertical: 'top',
  },
}

type PaymentTerms = {
  paymentTermsType: ContractPaymentTermsType | null
  paymentTerms: number | null
}

interface EditablePaymentTermsProps {
  paymentTerms: PaymentTerms
  onPaymentTermsChange: (paymentTerms: Partial<PaymentTerms>) => void
  isMissingPaymentTerms: boolean
  required: boolean
}

/** Form for onboarding contract info for a new lump sum project */
export function EditablePaymentTerms({
  paymentTerms: { paymentTermsType, paymentTerms },
  onPaymentTermsChange,
  isMissingPaymentTerms,
  required,
}: EditablePaymentTermsProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handlePaymentTermsTypeChange = useCallback(
    (evt: SelectChangeEvent) => {
      const paymentTermsType = evt.target.value as ContractPaymentTermsType | ''
      // Wrapping the change in a timeout causes it to run one tick after the select component
      // closes and re-gains focus, so the days input can automatically take focus when it appears
      setTimeout(() =>
        onPaymentTermsChange({
          paymentTermsType: paymentTermsType || null,
          // If clearing the `paymentTermsType`, reset `paymentTerms` to null
          paymentTerms: paymentTermsType ? paymentTerms : null,
        })
      )
    },
    [onPaymentTermsChange, paymentTerms]
  )

  const paymentTermsTypeText = useMemo(() => {
    if (!paymentTermsType) {
      return ''
    }
    switch (paymentTermsType) {
      case ContractPaymentTermsType.NET_PAYMENT:
        return t(`${i18nBase}.days`)
      case ContractPaymentTermsType.PAY_WHEN_PAID:
      case ContractPaymentTermsType.PAY_IF_PAID:
        return required ? t(`${i18nBase}.days_expected`) : t(`${i18nBase}.days_expected_optional`)
    }
  }, [paymentTermsType, required, t])

  return (
    <>
      <Row
        gap={PAYMENT_TERMS_GAP}
        alignItems="center"
        className={clsx(classes.paymentTermsRow, {
          missingTerms: isMissingPaymentTerms,
        })}
      >
        <Select
          variant="outlined"
          value={paymentTermsType ?? ''}
          renderValue={paymentTermsType ? undefined : () => t(`${i18nBase}.none_selected`)}
          onChange={handlePaymentTermsTypeChange}
          className={clsx(classes.fixedWithInput, 'selectTerms', {
            isEmpty: !paymentTermsType,
          })}
          MenuProps={menuProps}
          displayEmpty
        >
          <MenuItem value="">{t('common.none')}</MenuItem>
          <MenuItem value={ContractPaymentTermsType.NET_PAYMENT}>
            {t(`${i18nBase}.net_payment`)}
          </MenuItem>
          <MenuItem value={ContractPaymentTermsType.PAY_WHEN_PAID}>
            {t(`${i18nBase}.pay_when_paid`)}
          </MenuItem>
          <MenuItem value={ContractPaymentTermsType.PAY_IF_PAID}>
            {t(`${i18nBase}.pay_if_paid`)}
          </MenuItem>
        </Select>
        {paymentTermsType && (
          <>
            <NumericFormat
              value={paymentTerms}
              onValueChange={({ floatValue }) =>
                onPaymentTermsChange({ paymentTerms: floatValue ?? null })
              }
              decimalScale={0}
              displayType="input"
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values
                return floatValue === undefined || floatValue >= 0
              }}
              // Auto-focus when expanding, but only if there is no initial value
              autoFocus={paymentTerms === null}
              min={1}
            />
            <SitelineText variant="body1">{paymentTermsTypeText}</SitelineText>
          </>
        )}
      </Row>
      <Collapse in={isMissingPaymentTerms}>
        <SitelineText variant="smallText" color="red70" className={classes.missingPaymentTerms}>
          {t('projects.onboarding.required_field')}
        </SitelineText>
      </Collapse>
    </>
  )
}
