import { useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/system'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyUserRole, dollarsToCents, roundCents } from 'siteline-common-all'
import { evictWithGc, fuseSearch, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { Spreadsheet, SpreadsheetHandle } from '../../../common/components/Spreadsheet/Spreadsheet'
import {
  SpreadsheetRow,
  SpreadsheetValue,
  makeDividerRow,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useUserRoleContext } from '../../../common/contexts/UserRoleContext'
import {
  BillingType,
  Permission,
  WorksheetLineItemProgressProperties,
  useUpdateWorksheetProgressBilledMutation,
} from '../../../common/graphql/apollo-operations'
import { HEADER_HEIGHT, TOP_HEADER_HEIGHT } from '../../../common/themes/Main'
import {
  BaseFieldGuestInvoiceColumn,
  EditingWorksheet,
  EditingWorksheetLineItem,
  FieldGuestInvoiceColumn,
  invoiceColumnToEditingWorksheetLineItemField,
  makeEmptyEditingWorksheetLineItem,
  reorderWorksheetLineItem,
  worksheetLineItemsFromWorksheet,
} from '../../../common/util/BillingWorksheet'
import { getAddLineItemRow, getEmptyInvoiceRow } from '../../../common/util/FieldGuestInvoiceRow'
import {
  FieldGuestLumpSumInvoiceColumn,
  getFieldGuestLumpSumInvoiceColumns,
  getFieldGuestLumpSumInvoiceLineItemRow,
  getFieldGuestLumpSumInvoiceWorksheetFooterRow,
  getFieldGuestLumpSumInvoiceWorksheetRow,
} from '../../../common/util/FieldGuestLumpSumInvoice'
import {
  FieldGuestUnitPriceInvoiceColumn,
  MOBILE_PERCENT_COMPLETE_ROW_ID_SUFFIX,
  MOBILE_UNITS_BILLED_ROW_ID_SUFFIX,
  getFieldGuestUnitPriceInvoiceColumns,
  getFieldGuestUnitPriceInvoiceLineItemRow,
  getFieldGuestUnitPriceInvoiceWorksheetFooterRow,
  getFieldGuestUnitPriceInvoiceWorksheetRow,
} from '../../../common/util/FieldGuestUnitPriceInvoice'
import { getWorksheetProgressBilledFromPercentComplete } from '../../../common/util/Invoice'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import { getNextCode } from '../../../common/util/Sov'
import { ContractForPayApps } from '../../billing/PayAppDetails'
import { PayAppForProgress } from '../../billing/invoice/LumpSumPayAppInvoice'
import {
  FIELD_GUEST_SMALL_SCREEN_WIDTH,
  FieldGuestBillingPathType,
  getFieldGuestBillingPath,
} from '../FieldGuest.lib'

const useStyles = makeStylesFast((theme: Theme) => ({
  invoice: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}))

interface FieldGuestInvoiceProps {
  spreadsheetId?: string
  /** The entire data set - an array of sov line items with worksheet line items nested inside */
  worksheet: EditingWorksheet
  /**
   * If provided, the user will be able to edit the worksheet itself (i.e. the worksheet line item
   * metadata, not just the progress billed). This should only be provided if the user has
   * adequate permission and the parent will handle edits to the worksheet metadata columns.
   */
  onWorksheetChange?: (worksheet: EditingWorksheet) => void
  /**
   * The `payApp` prop should be passed in from both the pay app invoice sidebar and the field guest
   * invoice. It will be null if this component was accessed from the sov sidebar (in which case the
   * worksheet itself is being built/edited, rather than progress)
   */
  payApp: PayAppForProgress | null
  contract: ContractForPayApps
  /**
   * The `sovLineItemId` is only passed in from the sidebar component (where the worksheet only displays
   * one sovLineItem at a time). This prop will be null on the field tool version of this app, which
   * displays the entire invoice with all worksheet & sov line items in a single view.
   */
  sovLineItemId: string | null
  searchQuery: string
  loading: boolean
  includePreSitelineBilling: boolean
  billingType: BillingType
  /**
   * We need to support two modes of editing:
   * 1. The user is a back-office user who is building the worksheet - e.g., adding new worksheet
   * line items, assigning total units contracted to each
   * 2. The user is a field guest or a back-office user who is filling out the progress (% complete)
   */
  editMode?: 'worksheet' | 'progress'
  /**
   * This is specific to the sidebar sov worksheet which has its own "edit mode". This callback
   * handles entering edit mode from the sov sidebar worksheet. Should be undefined if we're on
   * the field guest invoice or the back office invoice sidebar worksheet. Should also be
   * undefined if the user cannot edit the sov worksheet due to the sov item already being billed.
   */
  onEditSovWorksheet?: () => void
  focusSpreadsheetOnMount?: boolean
  /**
   * Field guests can only edit the invoice if the contract is active, the pay app is in draft,
   * the billing type is lump sum or unit price, the pay app is NOT retention only, etc. These
   * checks are handled on a parent component. Additional permission checking will be handled
   * here.
   */
  canEditInvoice?: boolean

  onClearSearch: () => void
}

/** The field guest-equivalent of the invoice page */
export function FieldGuestInvoice({
  spreadsheetId,
  worksheet,
  onWorksheetChange,
  payApp,
  sovLineItemId,
  searchQuery,
  loading,
  includePreSitelineBilling,
  billingType,
  contract,
  editMode = 'progress',
  focusSpreadsheetOnMount = true,
  canEditInvoice = true,
  onClearSearch,
  onEditSovWorksheet,
}: FieldGuestInvoiceProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const theme = useTheme()
  const navigate = useNavigate()
  const { id: projectId } = useProjectContext()
  const { permissions } = useCompanyContext()
  const { userRole } = useUserRoleContext()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(FIELD_GUEST_SMALL_SCREEN_WIDTH))

  const invoiceSpreadsheetRef = useRef<SpreadsheetHandle>(null)

  const [updateProgressBilled] = useUpdateWorksheetProgressBilledMutation()

  // If a pay app is provided, we include columns for progress billed
  const includeProgressColumns = payApp !== null
  const isSidebarWorksheet = sovLineItemId !== null
  const isSovWorksheet = onWorksheetChange !== undefined
  const isFieldGuest = userRole === CompanyUserRole.FIELD_GUEST
  const isMobileLayout = isSmallScreen && !isSidebarWorksheet
  const isEditingSovWorksheet = editMode === 'worksheet' && canEditInvoice && isSovWorksheet

  const worksheetLineItems = useMemo(() => worksheetLineItemsFromWorksheet(worksheet), [worksheet])

  const canEdit = useMemo(() => {
    if (!canEditInvoice) {
      return false
    }
    if (payApp && !isPayAppDraftOrSyncFailed(payApp.status)) {
      return false
    }
    return permissions.includes(Permission.EDIT_INVOICE)
  }, [canEditInvoice, payApp, permissions])

  const hasSignedPayApp = useMemo(
    () => contract.payApps.some((payApp) => !isPayAppDraftOrSyncFailed(payApp.status)),
    [contract.payApps]
  )

  // Map from worksheet line item ID to the corresponding progress, if a pay app is provided
  const worksheetProgressByLineItemId = useMemo(() => {
    const worksheetProgress =
      payApp?.progress.flatMap((progress) => progress.worksheetLineItemProgress) ?? []
    return _.keyBy(worksheetProgress, (progress) => progress.worksheetLineItem.id)
  }, [payApp?.progress])

  // Make a set of worksheet line items that match the search query so we can filter the line items
  // later
  const filteredWorksheetLineItemIds = useMemo(() => {
    // Worksheet line items that match the search result
    let filteredLineItems = fuseSearch(worksheetLineItems, searchQuery, ['name', 'code'], {
      ignoreLocation: true,
    })

    // The sidebar shows a worksheet corresponding to a single sov line item - applying the search time
    // to sov line items is not relevant to that case
    if (!isSidebarWorksheet) {
      // Worksheet line items that correspond to sov line items that match the search result
      const sovLineItemSearchResults = fuseSearch(worksheet, searchQuery, ['name', 'code'])
      const worksheetLineItemsFromSovLineItemSearchResults = sovLineItemSearchResults.flatMap(
        (sovLineItem) => sovLineItem.worksheetLineItems
      )
      filteredLineItems.push(...worksheetLineItemsFromSovLineItemSearchResults)
    }
    // The final filter applies to a case where our line items have progress (i.e. we're on a
    // pay app invoice). In this case we'll filter out line items that don't exist on the pay app.
    // This may happen if the worksheet line item corresponds to a change order whose approval
    // date is in the future.
    if (includeProgressColumns) {
      filteredLineItems = filteredLineItems.filter((lineItem) =>
        _.get(worksheetProgressByLineItemId, lineItem.id, false)
      )
    }
    return new Set(filteredLineItems.map((lineItem) => lineItem.id))
  }, [
    includeProgressColumns,
    isSidebarWorksheet,
    searchQuery,
    worksheet,
    worksheetLineItems,
    worksheetProgressByLineItemId,
  ])

  const filteredWorksheet = useMemo(() => {
    return _.chain(worksheet)
      .map((sovLineItem) => {
        const currentWorksheetLineItems = _.chain(sovLineItem.worksheetLineItems)
          .filter(({ id }) => filteredWorksheetLineItemIds.has(id))
          .value()

        if (currentWorksheetLineItems.length === 0) {
          return null
        }
        return {
          ...sovLineItem,
          worksheetLineItems: currentWorksheetLineItems,
        }
      })
      .compact()
      .value()
  }, [filteredWorksheetLineItemIds, worksheet])

  const handleDeleteLineItem = useCallback(
    async (lineItemId: string) => {
      if (!onWorksheetChange) {
        return
      }
      const sovLineItemIndex = worksheet.findIndex((lineItem) => lineItem.id === sovLineItemId)
      if (sovLineItemIndex === -1) {
        return
      }
      const sovLineItem = worksheet[sovLineItemIndex]
      const updatedSovLineItem = {
        ...sovLineItem,
        worksheetLineItems: sovLineItem.worksheetLineItems.filter(
          (worksheetLineItem) => worksheetLineItem.id !== lineItemId
        ),
      }
      const newLineItems = [...worksheet]
      newLineItems.splice(sovLineItemIndex, 1, updatedSovLineItem)
      onWorksheetChange(newLineItems)
    },
    [onWorksheetChange, sovLineItemId, worksheet]
  )

  const handleAddLineItem = useCallback(() => {
    // We only support adding a worksheet line item when viewing an SOV line item worksheet and
    // when the necessary callback is provided
    if (!sovLineItemId || !onWorksheetChange) {
      return
    }
    const code = getNextCode(worksheetLineItems.map((lineItem) => lineItem.code))
    const sovLineItemIndex = worksheet.findIndex((lineItem) => lineItem.id === sovLineItemId)
    if (sovLineItemIndex === -1) {
      return
    }
    const sovLineItem = worksheet[sovLineItemIndex]
    const newWorksheetLineItem: EditingWorksheetLineItem = {
      ...makeEmptyEditingWorksheetLineItem({
        includePreSitelineBilling,
        sovLineItem,
      }),
      code,
    }
    const updatedSovLineItem = {
      ...sovLineItem,
      worksheetLineItems: [...sovLineItem.worksheetLineItems, newWorksheetLineItem],
    }
    const newLineItems = [...worksheet]
    newLineItems.splice(sovLineItemIndex, 1, updatedSovLineItem)
    onWorksheetChange(newLineItems)
    // After the SOV updates, focus the newly-added line item (either the code cell or the
    // name cell if a default code was entered)
    setTimeout(() => {
      invoiceSpreadsheetRef.current?.focusCell(
        newWorksheetLineItem.id,
        code ? BaseFieldGuestInvoiceColumn.NAME : BaseFieldGuestInvoiceColumn.NUMBER
      )
    })
  }, [includePreSitelineBilling, onWorksheetChange, worksheetLineItems, sovLineItemId, worksheet])

  const invoiceColumns = useMemo(() => {
    const sovLineItemIndex = worksheet.findIndex((lineItem) => lineItem.id === sovLineItemId)
    const sovLineItem =
      sovLineItemId !== null && sovLineItemIndex !== -1 ? worksheet[sovLineItemIndex] : undefined

    return billingType === BillingType.LUMP_SUM
      ? getFieldGuestLumpSumInvoiceColumns(t, {
          isMobileLayout,
          editMode,
          canEdit,
          includeProgressColumns,
          includePreSitelineBillingColumn: includePreSitelineBilling,
          includeScheduledValueColumn: !isFieldGuest,
        })
      : getFieldGuestUnitPriceInvoiceColumns(t, {
          isMobileLayout,
          editMode,
          canEdit,
          includeProgressColumns,
          includePreSitelineBillingColumn: includePreSitelineBilling,
          isSidebar: isSidebarWorksheet,
          unitName: sovLineItem?.unitName,
        })
  }, [
    worksheet,
    sovLineItemId,
    billingType,
    t,
    isMobileLayout,
    editMode,
    canEdit,
    includeProgressColumns,
    includePreSitelineBilling,
    isFieldGuest,
    isSidebarWorksheet,
  ])

  const invoiceContent = useMemo(() => {
    if (filteredWorksheet.length === 0) {
      let emptyStateRow: SpreadsheetRow | undefined
      if (searchQuery.length > 0) {
        // This case gets hit if the invoice is empty due to no results matching the search query
        emptyStateRow = getEmptyInvoiceRow({
          numColumns: invoiceColumns.length,
          onClick: onClearSearch,
          buttonLabel: t('projects.subcontractors.pay_app.invoice.empty_state.show_all_items'),
          emptyStateMessage: t('projects.subcontractors.pay_app.invoice.empty_state.no_match'),
        })
      } else if (isSovWorksheet && editMode !== 'worksheet' && !onEditSovWorksheet) {
        // This case gets hit if the user is on the sov worksheet and no line items have yet been
        // added. We only return this empty state message if the sov worksheet can *not* be edited,
        // otherwise we'll return a row for adding a line item (see below).
        emptyStateRow = getEmptyInvoiceRow({
          numColumns: invoiceColumns.length,
          onClick: null,
          emptyStateMessage: t('projects.subcontractors.worksheet.empty_line_item_worksheet'),
          buttonLabel: '',
        })
      } else if (!isSidebarWorksheet) {
        // This case gets hit on the field tool if the invoice is empty. In this case we'll show
        // the "empty worksheet" message alongside a button encouraging the user to go back to
        // the pay app list.
        emptyStateRow = getEmptyInvoiceRow({
          numColumns: invoiceColumns.length,
          onClick: () =>
            navigate(
              getFieldGuestBillingPath({
                pathType: FieldGuestBillingPathType.ProjectHome,
                projectId,
              })
            ),
          emptyStateMessage: t('projects.subcontractors.worksheet.empty_worksheet'),
          buttonLabel: t('projects.subcontractors.worksheet.go_back'),
        })
      }
      if (emptyStateRow) {
        return {
          rows: [emptyStateRow],
          footerRows: [],
          enableReorderRows: false,
        }
      }
    }

    const rows = filteredWorksheet.flatMap((sovLineItem, index) => {
      const rowsToAdd: SpreadsheetRow[] = []

      // If we're viewing the worksheet for a specific SOV line item (i.e. in the sidebar), then
      // we don't show the "group" header row for the SOV line item because it's the only one.
      const includeSovLineItemHeaders = !isSidebarWorksheet

      if (index !== 0 && includeSovLineItemHeaders) {
        rowsToAdd.push(makeDividerRow(`${sovLineItem.id}-divider`))
      }

      let getInvoiceLineItemRowFn:
        | typeof getFieldGuestLumpSumInvoiceLineItemRow
        | typeof getFieldGuestUnitPriceInvoiceLineItemRow
        | undefined
      let getWorksheetRowFn:
        | typeof getFieldGuestLumpSumInvoiceWorksheetRow
        | typeof getFieldGuestUnitPriceInvoiceWorksheetRow
        | undefined
      let getFooterRowFn: typeof getFieldGuestLumpSumInvoiceWorksheetFooterRow | undefined
      switch (billingType) {
        case BillingType.LUMP_SUM:
          getInvoiceLineItemRowFn = getFieldGuestLumpSumInvoiceLineItemRow
          getWorksheetRowFn = getFieldGuestLumpSumInvoiceWorksheetRow
          getFooterRowFn = getFieldGuestLumpSumInvoiceWorksheetFooterRow
          break
        case BillingType.UNIT_PRICE:
          getInvoiceLineItemRowFn = getFieldGuestUnitPriceInvoiceLineItemRow
          getWorksheetRowFn = getFieldGuestUnitPriceInvoiceWorksheetRow
          getFooterRowFn = getFieldGuestUnitPriceInvoiceWorksheetFooterRow
          break
        case BillingType.QUICK:
        case BillingType.TIME_AND_MATERIALS:
          break
      }

      if (includeSovLineItemHeaders && getInvoiceLineItemRowFn) {
        rowsToAdd.push(
          getInvoiceLineItemRowFn({
            sovLineItem,
            numColumns: invoiceColumns.length,
          })
        )
      }

      sovLineItem.worksheetLineItems.forEach((worksheetLineItem) => {
        // Note: progress will be null if we're on the SOV view (no corresponding pay app with progress info)
        const progress = _.get(worksheetProgressByLineItemId, worksheetLineItem.id, null)
        // We allow the user to delete worksheet items only if they either have never been billed,
        // or there are no signed pay apps. If a worksheet item has been billed and pay apps have
        // been signed, it may have affected the SOV progress billed on a signed pay app and
        // deleting it would change the billing on signed forms.
        const canDeleteWorksheetItem = worksheetLineItem.billedToDate === 0 || !hasSignedPayApp
        if (getWorksheetRowFn) {
          const worksheetRows = getWorksheetRowFn({
            worksheetLineItem,
            progress,
            numColumns: invoiceColumns.length,
            isMobileLayout,
            includePreSitelineBillingColumn: includePreSitelineBilling,
            includeScheduledValueColumn: !isFieldGuest,
            isSidebar: isSidebarWorksheet,
            onDelete:
              isEditingSovWorksheet && canDeleteWorksheetItem ? handleDeleteLineItem : undefined,
            t,
            sovLineItem,
          })
          rowsToAdd.push(..._.compact(worksheetRows))
        }
      })

      const currentSovLineItemProgress = payApp?.progress.find(
        ({ sovLineItem: lineItem }) => lineItem.id === sovLineItem.id
      )

      if (getFooterRowFn && !isFieldGuest) {
        const footerRow = getFooterRowFn({
          progress: currentSovLineItemProgress ?? null,
          numColumns: invoiceColumns.length,
          sovLineItem,
          includePreSitelineBillingColumn: includePreSitelineBilling,
          includeScheduledValueColumn: !isFieldGuest,
          isMobileLayout,
        })
        if (footerRow) {
          rowsToAdd.push(footerRow)
        }
      }

      return rowsToAdd
    })

    // If in edit mode, we add a row with a button to add a new worksheet item. We only include
    // this button if viewing a single SOV line item's worksheet, so it's clear which line item
    // to create the item for.
    const canAddWorksheetLineItem = canEditInvoice && isSidebarWorksheet && isSovWorksheet
    const showAddWorksheetLineItem =
      canAddWorksheetLineItem && (editMode === 'worksheet' || worksheetLineItems.length === 0)
    if (showAddWorksheetLineItem) {
      const addItemRow = getAddLineItemRow({
        onAddLineItem: handleAddLineItem,
        numColumns: invoiceColumns.length,
        t,
      })
      rows.push(addItemRow)
    }

    return {
      rows,
      footerRows: [],
      enableReorderRows: isEditingSovWorksheet,
    }
  }, [
    filteredWorksheet,
    canEditInvoice,
    isSidebarWorksheet,
    isSovWorksheet,
    editMode,
    worksheetLineItems.length,
    isEditingSovWorksheet,
    searchQuery.length,
    onEditSovWorksheet,
    invoiceColumns.length,
    onClearSearch,
    t,
    navigate,
    projectId,
    billingType,
    payApp?.progress,
    worksheetProgressByLineItemId,
    hasSignedPayApp,
    isMobileLayout,
    includePreSitelineBilling,
    isFieldGuest,
    handleDeleteLineItem,
    handleAddLineItem,
  ])

  const handleReorder = useCallback(
    (rowId: string, toRowIndex: number) => {
      // Reordering is only possible when viewing the worksheet for a single SOV line item, so we
      // know the row ID will correspond to a worksheet line item ID
      const lineItemIndex = worksheetLineItems.findIndex((lineItem) => lineItem.id === rowId)
      const lineItem = lineItemIndex >= 0 ? worksheetLineItems[lineItemIndex] : undefined
      if (!lineItem || !onWorksheetChange) {
        return false
      }
      const fromRowIndex = invoiceContent.rows.findIndex((row) => row.id === rowId)
      const newWorksheetLineItems = reorderWorksheetLineItem({
        fromRowIndex,
        toRowIndex,
        lineItem,
        lineItemIndex,
        contentRows: invoiceContent.rows,
        lineItems: worksheetLineItems,
      })
      const sovLineItemIndex = worksheet.findIndex((sovLineItem) =>
        sovLineItem.worksheetLineItems.some(
          (worksheetLineItem) => worksheetLineItem.id === lineItem.id
        )
      )
      const sovLineItem = sovLineItemIndex >= 0 ? worksheet[sovLineItemIndex] : undefined
      if (!sovLineItem) {
        return false
      }
      const newWorksheet = [...worksheet]
      newWorksheet.splice(sovLineItemIndex, 1, {
        ...sovLineItem,
        worksheetLineItems: newWorksheetLineItems,
      })
      onWorksheetChange(newWorksheet)
      return true
    },
    [worksheetLineItems, onWorksheetChange, invoiceContent.rows, worksheet]
  )

  const handleProgressChange = useCallback(
    async (progress: WorksheetLineItemProgressProperties, progressBilled: number) => {
      try {
        await updateProgressBilled({
          variables: { input: { worksheetLineItemProgressId: progress.id, progressBilled } },
          optimisticResponse: {
            __typename: 'Mutation',
            updateWorksheetProgressBilled: {
              __typename: 'WorksheetLineItemProgress',
              id: progress.id,
              progressBilled,
              previousBilled: progress.previousBilled,
              totalValue: progress.totalValue,
              worksheetLineItem: progress.worksheetLineItem,
            },
          },
          update(cache) {
            // Refetch values on the SOV line item and SOV line item progress that are affected by
            // the progress change to the worksheet line item progress
            evictWithGc(cache, (evict) => {
              const sovLineItem = worksheetLineItems.find(
                (lineItem) => lineItem.id === progress.worksheetLineItem.sovLineItem.id
              )
              if (sovLineItem) {
                evict({ id: cache.identify(sovLineItem), fieldName: 'billedToDate' })
                evict({ id: cache.identify(sovLineItem), fieldName: 'totalBilled' })
              }
              const sovLineItemProgress = payApp?.progress.find(
                (lineItemProgress) =>
                  lineItemProgress.sovLineItem.id === progress.worksheetLineItem.sovLineItem.id
              )
              if (sovLineItemProgress) {
                evict({ id: cache.identify(sovLineItemProgress), fieldName: 'progressBilled' })
                evict({ id: cache.identify(sovLineItemProgress), fieldName: 'currentBilled' })
              }
            })
          },
        })
      } catch (err) {
        snackbar.showError(err.message)
      }
    },
    [payApp?.progress, snackbar, updateProgressBilled, worksheetLineItems]
  )

  // Handle updating the worksheet when a field is changed in the spreadsheet. For worksheet
  // columns, we update the worksheet itself. For progress columns, we call the update function
  // to directly update the progress.
  const handleUpdateWorksheetLineItem = useCallback(
    (worksheetLineItemId: string, column: FieldGuestInvoiceColumn, value: SpreadsheetValue) => {
      const sovLineItemIndex = worksheet.findIndex((lineItem) =>
        lineItem.worksheetLineItems.some(
          (worksheetLineItem) => worksheetLineItem.id === worksheetLineItemId
        )
      )
      if (sovLineItemIndex === -1) {
        return
      }
      const sovLineItem = worksheet[sovLineItemIndex]
      const worksheetLineItemIndex = sovLineItem.worksheetLineItems.findIndex(
        (item) => item.id === worksheetLineItemId
      )
      if (worksheetLineItemIndex === -1) {
        return
      }
      const worksheetLineItem = sovLineItem.worksheetLineItems[worksheetLineItemIndex]

      // If we're editing the progress on a worksheet via the two columns below, we defer the edit
      // to the progress handler
      const progress = _.get(worksheetProgressByLineItemId, worksheetLineItemId, null)
      switch (column) {
        case BaseFieldGuestInvoiceColumn.PERCENT_COMPLETE: {
          if (!progress) {
            return
          }
          const numberValue = Number(value)
          const newProgressBilled = getWorksheetProgressBilledFromPercentComplete(
            progress,
            numberValue
          )
          handleProgressChange(progress, newProgressBilled)
          return
        }
        case FieldGuestUnitPriceInvoiceColumn.CURRENT_UNITS_BILLED: {
          if (!progress || worksheetLineItem.unitPrice === undefined) {
            return
          }
          const unitsBilled = Number(value)
          const newProgressBilled = roundCents(unitsBilled * worksheetLineItem.unitPrice)
          handleProgressChange(progress, newProgressBilled)
          return
        }
        case BaseFieldGuestInvoiceColumn.NUMBER:
        case BaseFieldGuestInvoiceColumn.NAME:
        case BaseFieldGuestInvoiceColumn.COST_CODE:
        case BaseFieldGuestInvoiceColumn.PRE_SITELINE_BILLING:
        case FieldGuestUnitPriceInvoiceColumn.UNIT_NAME:
        case FieldGuestUnitPriceInvoiceColumn.TOTAL_UNITS:
        case FieldGuestLumpSumInvoiceColumn.SCHEDULED_VALUE:
          // Other column edits are handled below by the worksheet change handler
          break
      }

      // If another column was edited, we are editing the worksheet metadata itself and use the
      // worksheet change handler
      if (!onWorksheetChange) {
        return
      }

      const field = invoiceColumnToEditingWorksheetLineItemField(column)
      const unitPrice = sovLineItem.unitPrice ?? 0

      let lineItemUpdates: Partial<EditingWorksheetLineItem> = {}
      let isChanged = false
      switch (column) {
        case FieldGuestLumpSumInvoiceColumn.SCHEDULED_VALUE: {
          if (typeof value !== 'number') {
            break
          }
          const centsValue = dollarsToCents(value)
          lineItemUpdates = { [field]: centsValue }
          isChanged = centsValue !== worksheetLineItem[field]
          break
        }
        case BaseFieldGuestInvoiceColumn.PRE_SITELINE_BILLING: {
          if (typeof value !== 'number') {
            break
          }
          const centsValue = dollarsToCents(value)
          const oldPreSitelineBilled = worksheetLineItem.preSitelineBilled ?? 0
          const billedToDate = worksheetLineItem.billedToDate - oldPreSitelineBilled + centsValue
          lineItemUpdates = {
            [field]: centsValue,
            billedToDate,
          }
          isChanged = centsValue !== worksheetLineItem[field]
          break
        }
        case FieldGuestUnitPriceInvoiceColumn.TOTAL_UNITS: {
          if (typeof value !== 'number') {
            break
          }
          const totalValue = roundCents(value * unitPrice)
          lineItemUpdates = { [field]: totalValue }
          isChanged = totalValue !== worksheetLineItem[field]
          break
        }
        case BaseFieldGuestInvoiceColumn.NUMBER:
        case BaseFieldGuestInvoiceColumn.NAME:
        case BaseFieldGuestInvoiceColumn.COST_CODE:
          // For all other columns, we simply update the corresponding field with the given value
          lineItemUpdates = { [field]: value }
          isChanged = value !== worksheetLineItem[field]
          break
        case FieldGuestUnitPriceInvoiceColumn.UNIT_NAME:
          // This column should not be editable
          return
      }
      // If the value is unchanged, return without making any changes
      if (!isChanged) {
        return
      }
      const newWorksheetLineItems = [...sovLineItem.worksheetLineItems]
      newWorksheetLineItems.splice(worksheetLineItemIndex, 1, {
        ...worksheetLineItem,
        ...lineItemUpdates,
      })
      const updatedSovLineItem = {
        ...sovLineItem,
        worksheetLineItems: newWorksheetLineItems,
      }
      const newLineItems = [...worksheet]
      newLineItems.splice(sovLineItemIndex, 1, updatedSovLineItem)
      onWorksheetChange(newLineItems)
    },
    [worksheet, worksheetProgressByLineItemId, onWorksheetChange, handleProgressChange]
  )

  const handleChange = useCallback(
    async (rowId: string, columnId: string, toValue: SpreadsheetValue) => {
      // In the mobile layout view, multiple rows represent a single worksheet item and to distuinguish their IDs, we had
      // a suffix to them (e.g. "id-percentComplete"). If the change is triggered within a mobile layout, we must trim
      // the suffix off `rowId` to get the id that corresponds to a worksheet item. We also must identify the actual column id
      // based on this suffix, as the same column represents multiple fields.
      let trimmedRowId = rowId
      let compactColumnId = columnId as FieldGuestInvoiceColumn
      if (isMobileLayout) {
        if (rowId.endsWith(MOBILE_UNITS_BILLED_ROW_ID_SUFFIX)) {
          trimmedRowId = rowId.slice(0, -MOBILE_UNITS_BILLED_ROW_ID_SUFFIX.length)
          compactColumnId = FieldGuestUnitPriceInvoiceColumn.CURRENT_UNITS_BILLED
        } else if (rowId.endsWith(MOBILE_PERCENT_COMPLETE_ROW_ID_SUFFIX)) {
          trimmedRowId = rowId.slice(0, -MOBILE_PERCENT_COMPLETE_ROW_ID_SUFFIX.length)
          compactColumnId = BaseFieldGuestInvoiceColumn.PERCENT_COMPLETE
        } else {
          console.error(`Unable to format current rowId ${rowId} for search in worksheet`)
        }
      }

      const worksheetLineItemIndex = worksheetLineItems.findIndex(
        (lineItem) => lineItem.id === trimmedRowId
      )

      const worksheetLineItem =
        worksheetLineItemIndex >= 0 ? worksheetLineItems[worksheetLineItemIndex] : undefined
      // If a worksheet line item matches, update it appropriately
      if (worksheetLineItem) {
        handleUpdateWorksheetLineItem(trimmedRowId, compactColumnId, toValue)
        return
      }
      // If no worksheet line item matches the row ID, show an error toast
      snackbar.showError(t('common.errors.snackbar.generic'))
    },
    [handleUpdateWorksheetLineItem, isMobileLayout, snackbar, t, worksheetLineItems]
  )

  return (
    <div className={classes.invoice}>
      <Spreadsheet
        ref={invoiceSpreadsheetRef}
        spreadsheetFocusId={spreadsheetId}
        focusOnMount={focusSpreadsheetOnMount}
        columns={invoiceColumns}
        content={invoiceContent}
        onReorder={handleReorder}
        // The way sticky header position is calculated isn't compatible with spreadsheets that are
        //  contained within fixed-position components (which is the case for the sidebar worksheet)
        stickyHeaderTopOffset={
          isSidebarWorksheet ? undefined : TOP_HEADER_HEIGHT + HEADER_HEIGHT - 1
        }
        loading={loading}
        blurOnClickAway
        onChange={handleChange}
      />
    </div>
  )
}
