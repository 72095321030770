import { Checkbox, CheckboxProps, styled } from '@mui/material'
import { clsx } from 'clsx'
import CheckboxCheckedIcon from '../../assets/icons/checkbox-checked.svg'
import CheckboxIndeterminateIcon from '../../assets/icons/checkbox-indeterminate.svg'
import CheckboxIcon from '../../assets/icons/checkbox.svg'

const StyledSitelineCheckbox = styled(Checkbox)(() => ({
  '& .checkboxIcon': {
    width: 24,
    height: 24,
  },
  '& .disabledCheckboxIcon': {
    opacity: 0.5,
  },
}))

/**
 * Uses custom icon and colors for Checkbox. Custom icon has a white background, inside of the
 * checkbox itself, which is not available via normal color options.
 */
export function SitelineCheckbox(props: CheckboxProps) {
  return (
    <StyledSitelineCheckbox
      icon={
        <img
          src={CheckboxIcon}
          alt="Checkbox"
          className={clsx('checkboxIcon', { disabledCheckboxIcon: props.disabled })}
        />
      }
      checkedIcon={
        <img
          src={CheckboxCheckedIcon}
          alt="CheckboxChecked"
          className={clsx('checkboxIcon', { disabledCheckboxIcon: props.disabled })}
        />
      }
      indeterminateIcon={
        <img
          src={CheckboxIndeterminateIcon}
          alt="CheckboxIndeterminate"
          className={clsx('checkboxIcon', { disabledCheckboxIcon: props.disabled })}
        />
      }
      color="primary"
      {...props}
    />
  )
}
