import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import { Permission } from '../../common/graphql/apollo-operations'
import {
  orderedPermissions,
  shouldShowErpWritePermission,
} from './manage-users/PermissionChecklist'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .Siteline-h3': {
      marginBottom: theme.spacing(2),
    },
    '& .Siteline-body1': {
      margin: theme.spacing(2, 0),
    },
  },
  row: {
    borderBottom: `1px solid ${colors.grey20}`,
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))

export function Permissions() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { company, permissions } = useCompanyContext()

  const userHasPermission = useCallback(
    (permission: Permission) => permissions.includes(permission),
    [permissions]
  )

  const sortedPerms = useMemo(
    () =>
      _.chain(_.values(Permission))
        .orderBy((permission) => orderedPermissions.indexOf(permission), 'asc')
        // Filter out the edit users permission, as it won't be visible to non-permitted users
        .filter((permission) => permission !== Permission.EDIT_COMPANY_USERS)
        // Filter out the ERP permission if the company doesn't have one set up
        .filter((permission) => {
          const integrations = company?.companyIntegrations ?? []
          if (shouldShowErpWritePermission(integrations)) {
            return true
          } else {
            return permission !== Permission.ERP_SYNC
          }
        })
        .value(),
    [company]
  )

  return (
    <div className={classes.root}>
      <SitelineText variant="h3" bold color="grey90">
        {t('settings.navigation.permissions')}
      </SitelineText>
      {sortedPerms.map((permission) => {
        const hasPermission = userHasPermission(permission)
        return (
          <div key={permission} className={classes.row}>
            <SitelineText variant="body1" color={hasPermission ? 'grey70' : 'grey50'}>
              {t(`settings.permissions.description.${permission}`)}
            </SitelineText>
            <SitelineText variant="body1" color={hasPermission ? 'grey70' : 'grey50'}>
              {hasPermission
                ? t('settings.permissions.allowed')
                : t('settings.permissions.not_allowed')}
            </SitelineText>
          </div>
        )
      })}
    </div>
  )
}
