import { TextField } from '@mui/material'
import { SendEmailDialogRow, SendEmailDialogRowProps } from './SendEmailDialogRow'

interface SendEmailDialogTextFieldRowProps extends SendEmailDialogRowProps {
  type: string
  value: string
  onChange: (type: string, value: string | null) => void
  placeholder?: string
  autoFocus?: boolean
}

/** A row in a send email dialog with an editable text field */
export function SendEmailDialogTextFieldRow({
  type,
  value,
  onChange,
  placeholder,
  autoFocus,
  ...props
}: SendEmailDialogTextFieldRowProps) {
  return (
    <SendEmailDialogRow {...props}>
      <TextField
        fullWidth
        variant="outlined"
        value={value}
        onChange={(ev) => {
          const newValue = ev.target.value as string
          onChange(type, newValue)
        }}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
    </SendEmailDialogRow>
  )
}
