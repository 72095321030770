import { useApolloClient } from '@apollo/client'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { identifyUser, removeUserIdentification } from 'siteline-common-web'
import { Loader } from './Loader'

type AuthContextValue = {
  userId: string | null
  email: string | null
}

const AuthContext = createContext<AuthContextValue>({
  userId: null,
  email: null,
})

export function useSitelineAuthContext() {
  return useContext(AuthContext)
}

export function SitelineAuthProvider({ children }: PropsWithChildren) {
  const [userId, setUserId] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const client = useApolloClient()

  useEffect(() => {
    const auth = getAuth(getApp())
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoading(false)
      if (!user) {
        setUserId(null)
        setEmail(null)
        void removeUserIdentification()
        return
      }
      setUserId(user.uid)
      setEmail(user.email)
      void identifyUser({
        id: user.uid,
        email: user.email ?? undefined,
      })
    })
    return unsubscribe
  }, [])

  // Whenever the user ID changes, reset the apollo cache to invalidate root queries (like contract
  // list, or current user info)
  useEffect(() => {
    void client.resetStore()
  }, [userId, client])

  const contextValue = useMemo(() => ({ userId, email }), [userId, email])

  if (isLoading) {
    return <Loader />
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
