import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import CreateIcon from '@mui/icons-material/Create'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { Button, IconButton, Skeleton, Tooltip } from '@mui/material'
import { clsx } from 'clsx'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Dispatch, MouseEvent, ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StoredMaterialsCarryoverType,
  TAX_RATE_PERCENT_PRECISION,
  centsToDollars,
  decimalToPercent,
  safeDivide,
} from 'siteline-common-all'
import { SitelineText, SitelineTooltip, colors, makeStylesFast } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { BillingWorksheetLinesIcon } from '../../components/billing/BillingWorksheetLinesIcon'
import { ContractForPayApps } from '../../components/billing/PayAppDetails'
import { InvoiceHistoryButton } from '../../components/billing/invoice/InvoiceHistoryButton'
import {
  InvoiceAction,
  InvoiceReducerState,
  RetentionView,
  StoredMaterialsView,
  TaxesView,
} from '../../components/billing/invoice/InvoiceReducer'
import { SovLineItemProgressForEvents } from '../../components/billing/invoice/InvoiceSpreadsheet'
import {
  PayAppForProgress,
  SovLineItemGroupForProgress,
} from '../../components/billing/invoice/LumpSumPayAppInvoice'
import { AddChangeOrderButton } from '../../components/billing/invoice/change-order/AddChangeOrderButton'
import { AdjustRetentionView } from '../../components/billing/invoice/retention/AdjustRetentionDialog'
import { VISIBLE_ON_ROW_HOVER_CLASS } from '../components/SitelineTable'
import { Spacer } from '../components/Spacer'
import {
  SpreadsheetAuxiliaryRow,
  SpreadsheetCell,
  SpreadsheetColumn,
  SpreadsheetElement,
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeContentCell,
  makeDataCell,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  BillingType,
  PayApp,
  PayAppStatus,
  SovLineItemProgressProperties,
} from '../graphql/apollo-operations'
import { themeSpacing } from '../themes/Main'
import { calculateProgressFromEvent } from './Invoice'
import {
  getLumpSumInvoiceTotalsCells,
  getLumpSumProgressHistoryCells,
  getLumpSumProgressRowCells,
} from './LumpSumInvoiceRow'
import { currentPayAppBilled, isPayAppDraftOrSyncFailed, previousPayAppBilled } from './PayApp'
import { retentionToDate, usesStandardOrLineItemTracking } from './Retention'
import {
  getUnitPriceInvoiceTotalsCells,
  getUnitPriceProgressHistoryCells,
  getUnitPriceProgressRowCells,
} from './UnitPriceInvoiceRow'

export const DEFAULT_SKELETON_COLUMN_WIDTH = 100

export const SpreadsheetRowDeleteIcon = memo(function SpreadsheetRowDeleteIcon({
  onDelete,
}: {
  onDelete: () => void
}) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onDelete()
  }

  return (
    <div className={VISIBLE_ON_ROW_HOVER_CLASS}>
      <div className="delete">
        <IconButton color="secondary" onClick={handleClick} size="small">
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  )
})

const useStyles = makeStylesFast(() => ({
  edit: {
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: colors.grey50,
    },
    '&.isDisabled .MuiButtonBase-root': {
      cursor: 'default',
      color: colors.grey40,
    },
  },
}))

export const EditCellIcon = memo(function EditIcon({
  onEdit,
  type,
  isHoverOnly,
  className,
}: {
  onEdit: (() => void) | undefined
  type: 'text' | 'icon'
  isHoverOnly: boolean
  className?: string
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (onEdit) {
      onEdit()
    }
  }

  return (
    <div className={clsx({ [VISIBLE_ON_ROW_HOVER_CLASS]: isHoverOnly })}>
      <div className={clsx(classes.edit, className, { isDisabled: !onEdit })}>
        {type === 'icon' && (
          <IconButton color="secondary" onClick={handleClick} size="small">
            <CreateIcon fontSize="small" />
          </IconButton>
        )}
        {type === 'text' && (
          <Button variant="text" onClick={handleClick} size="small" className="editButton">
            <SitelineText variant="label" color="grey50">
              {t('common.actions.edit')}
            </SitelineText>
          </Button>
        )}
      </div>
    </div>
  )
})

/** Whether or not this progress item is a change order that was created on this pay app */
function isNewChangeOrder(
  progress: SovLineItemProgressProperties,
  payAppStatus: PayAppStatus,
  timeZone: string,
  payAppBillingEnd: string
) {
  if (!progress.sovLineItem.isChangeOrder || !progress.sovLineItem.changeOrderApprovedDate) {
    // Can only edit change orders
    return false
  }
  if (payAppStatus === PayAppStatus.PAID) {
    // Cannot edit if pay app already paid
    return false
  }
  const effectiveDate = moment.tz(
    progress.sovLineItem.changeOrderEffectiveDate ?? progress.sovLineItem.changeOrderApprovedDate,
    timeZone
  )
  return effectiveDate.isSame(moment.tz(payAppBillingEnd, timeZone), 'month')
}

/**
 * Returns a skeleton row with a specified number of columns. The width is either specified or
 * defaults to 100px.
 */
export function getSkeletonRow(columns: SpreadsheetColumn[]): SpreadsheetRow {
  const cells: SpreadsheetCell[] = []
  for (let i = 0; i < columns.length; i++) {
    cells.push(
      makeContentCell(
        <Skeleton
          variant="text"
          width={columns[i].skeletonWidth ?? DEFAULT_SKELETON_COLUMN_WIDTH}
        />,
        []
      )
    )
  }
  // The history column should not have a skeleton
  cells.push(makeContentCell(null, []))
  return {
    type: SpreadsheetRowType.DEFAULT,
    id: uuidv4(),
    cells,
    isNonEditableRow: true,
  }
}

const retentionHeldToDateViews = [
  RetentionView.HELD_TO_DATE_PERCENT,
  RetentionView.HELD_TO_DATE_AMOUNT,
]
const retentionHeldViews = [
  RetentionView.HELD_CURRENT_PERCENT,
  RetentionView.HELD_CURRENT_AMOUNT,
  ...retentionHeldToDateViews,
]

/**
 * There are a few icons we may show to the right of the line item name in a progress row, such as
 * an edit icon, delete icon, or worksheet items icon.
 *
 * @returns A tuple with the content to show, as well as a boolean indicating whether an edit or
 * delete icon is shown.
 */
function getProgressNameRightContent({
  progress,
  noEditPermissionTooltip,
  payAppStatus,
  onDelete,
  onEdit,
}: {
  progress: SovLineItemProgressProperties
  noEditPermissionTooltip?: string
  payAppStatus: PayAppStatus
  onDelete?: (progressId: string) => void
  onEdit?: (progressId: string) => void
}): [SpreadsheetElement | undefined, boolean] {
  const isPayAppDraft = isPayAppDraftOrSyncFailed(payAppStatus)
  const shouldShowDelete = !!onDelete && isPayAppDraft
  const shouldShowEdit = !!onEdit && !shouldShowDelete && isPayAppDraft
  const worksheetProgress = progress.worksheetLineItemProgress

  return [
    {
      content: (
        <div style={{ display: 'flex', alignItems: 'center', gap: themeSpacing(1) }}>
          {shouldShowDelete && <SpreadsheetRowDeleteIcon onDelete={() => onDelete(progress.id)} />}
          {shouldShowEdit && (
            <Tooltip title={noEditPermissionTooltip ?? ''}>
              <div>
                <EditCellIcon
                  type="text"
                  isHoverOnly
                  onEdit={noEditPermissionTooltip ? undefined : () => onEdit(progress.id)}
                />
              </div>
            </Tooltip>
          )}
          <BillingWorksheetLinesIcon
            sovLineItemId={progress.sovLineItem.id}
            numWorksheetItems={worksheetProgress.length}
            displayMode="withItems"
          />
        </div>
      ),
      dependencies: [
        onDelete,
        onEdit,
        noEditPermissionTooltip,
        worksheetProgress.length,
        progress.id,
        progress.sovLineItem.id,
      ],
    },
    shouldShowDelete || shouldShowEdit,
  ]
}

/**
 * Returns a `SpreadsheetRow` component to be displayed in the pay app invoice
 * table to represent a single progress line item
 */
export function getProgressRow(
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE,
  {
    progress,
    isExpanded,
    onToggleExpanded,
    onDelete,
    onEdit,
    noEditPermissionTooltip,
    retentionView,
    taxesView,
    payAppStatus,
    payAppBillingEnd,
    timeZone,
    isProgressFirstInGroup,
    showWarningIfEmpty,
    onEditRetention,
    onAdjustUnitPriceValue,
    retentionOnly,
    shouldIncludeCostCode,
    allowsOverbilling,
    t,
    onViewSovLineItemWorksheet,
    storedMaterialsCarryoverType,
    onEditStoredMaterialsManual,
    storedMaterialsView,
  }: {
    progress: SovLineItemProgressProperties
    isExpanded: boolean
    onToggleExpanded?: (progressId: string) => void
    onDelete?: (progressId: string) => void
    onEdit?: (progressId: string) => void
    noEditPermissionTooltip?: string
    retentionView?: RetentionView
    taxesView?: TaxesView
    payAppStatus: PayAppStatus
    payAppBillingEnd: string
    timeZone: string
    isProgressFirstInGroup: boolean
    showWarningIfEmpty?: boolean
    onEditRetention?: (progress: SovLineItemProgressProperties, view?: AdjustRetentionView) => void
    onAdjustUnitPriceValue?: (progress: SovLineItemProgressProperties) => void
    shouldIncludeCostCode: boolean
    allowsOverbilling: boolean
    retentionOnly: boolean
    t: TFunction
    onViewSovLineItemWorksheet: (sovLineItemId: string) => void
    storedMaterialsCarryoverType: StoredMaterialsCarryoverType
    onEditStoredMaterialsManual?: (progressId: string) => void
    storedMaterialsView: StoredMaterialsView
  }
): SpreadsheetRow {
  const cells: SpreadsheetCell[] = []

  const hasWorksheet = progress.worksheetLineItemProgress.length > 0

  const [nameRightContent, hasEditOrDeleteIcon] = getProgressNameRightContent({
    progress,
    payAppStatus,
    noEditPermissionTooltip,
    onDelete,
    onEdit,
  })

  switch (billingType) {
    case BillingType.LUMP_SUM:
      cells.push(
        ...getLumpSumProgressRowCells({
          progress,
          nameRightContent,
          showWarningIfEmpty,
          shouldIncludeCostCode,
          allowsOverbilling,
          t,
          onProgressClick: hasWorksheet ? onViewSovLineItemWorksheet : undefined,
          storedMaterialsCarryoverType,
          onEditStoredMaterialsManual,
        })
      )
      break
    case BillingType.UNIT_PRICE:
      cells.push(
        ...getUnitPriceProgressRowCells({
          progress,
          nameRightContent,
          showWarningIfEmpty,
          // Only show the icon for adjusting total value if the line item isn't already
          // an editable change order
          onAdjustUnitPriceValue: hasEditOrDeleteIcon ? undefined : onAdjustUnitPriceValue,
          shouldIncludeCostCode,
          t,
          onProgressClick: hasWorksheet ? onViewSovLineItemWorksheet : undefined,
          storedMaterialsView,
          storedMaterialsCarryoverType,
          onEditStoredMaterialsManual,
        })
      )
      break
  }

  // Check if there is a retention held override on this progress. Also check if it is an override
  // for $0 with $0 billed. If so, ignore it, since the override has no actual effect. (For some
  // reason, we have a number of old pay apps where this is the case with all unbilled progress.)
  const hasRetentionOverride =
    _.isNumber(progress.retentionHeldOverride) &&
    (progress.retentionHeldOverride !== 0 ||
      progress.currentBilled !== 0 ||
      progress.storedMaterialBilled !== 0)
  const showCurrentRetentionOverrideIcon =
    hasRetentionOverride && retentionView && retentionHeldViews.includes(retentionView)
  const lastPayAppWithRetentionHeldOverride = progress.lastProgressWithRetentionHeldOverride?.payApp
  const showPastRetentionOverrideIcon =
    lastPayAppWithRetentionHeldOverride !== undefined &&
    retentionView &&
    retentionHeldToDateViews.includes(retentionView)
  const showRetentionOverrideIcon =
    showCurrentRetentionOverrideIcon || showPastRetentionOverrideIcon
  const retentionOverrideIcon = showRetentionOverrideIcon ? (
    <SitelineTooltip
      title={
        hasRetentionOverride
          ? t('projects.subcontractors.pay_app.invoice.retention.progress_override')
          : t('projects.subcontractors.pay_app.invoice.retention.progress_past_override', {
              payAppNumber: lastPayAppWithRetentionHeldOverride?.payAppNumber,
            })
      }
    >
      {hasRetentionOverride && onEditRetention ? (
        <IconButton
          size="small"
          onClick={() => onEditRetention(progress, AdjustRetentionView.ADJUST_FUTURE)}
        >
          <LinkOffIcon />
        </IconButton>
      ) : (
        <LinkOffIcon />
      )}
    </SitelineTooltip>
  ) : null
  const hasRetentionBilling = progress.previousRetentionBilled !== 0
  const retentionBilledIcon = hasRetentionBilling ? (
    <SitelineTooltip
      title={t('projects.subcontractors.pay_app.invoice.retention.progress_released')}
    >
      {onEditRetention ? (
        <IconButton
          size="small"
          onClick={() => onEditRetention(progress, AdjustRetentionView.RELEASE_PAST)}
        >
          <AccountBalanceOutlinedIcon />
        </IconButton>
      ) : (
        <AccountBalanceOutlinedIcon />
      )}
    </SitelineTooltip>
  ) : null

  const leftContent = {
    content: onEditRetention ? (
      <Tooltip
        placement="top"
        title={t('projects.subcontractors.pay_app.invoice.retention.update_retention')}
        disableInteractive
      >
        <EditCellIcon
          isHoverOnly={!retentionOnly}
          type="icon"
          onEdit={() => {
            onEditRetention(progress)
          }}
          className="leftCellIcon"
        />
      </Tooltip>
    ) : null,
    dependencies: [onEditRetention],
    align: 'right' as const,
  }
  const rightContent = {
    content: (
      <div className={RETENTION_ICONS_CLASS}>
        {retentionOverrideIcon}
        {retentionBilledIcon}
      </div>
    ),
    dependencies: [
      progress.lastProgressWithRetentionHeldOverride,
      progress.retentionHeldOverride,
      progress.previousRetentionBilled,
    ],
  }

  // Retention view
  switch (retentionView) {
    case RetentionView.HELD_CURRENT_PERCENT:
    case RetentionView.HELD_TO_DATE_PERCENT: {
      cells.push(
        makeDataCell(decimalToPercent(progress.retentionHeldPercent, 1), {
          leftContent,
          rightContent,
        })
      )
      break
    }
    case RetentionView.HELD_TO_DATE_AMOUNT: {
      const retentionAmount = retentionToDate(progress)
      cells.push(
        makeDataCell(centsToDollars(retentionAmount), {
          leftContent,
          rightContent,
        })
      )
      break
    }
    case RetentionView.HELD_CURRENT_AMOUNT: {
      cells.push(
        makeDataCell(centsToDollars(progress.currentRetention), {
          leftContent,
          rightContent,
        })
      )
      break
    }
    case RetentionView.RELEASED_CURRENT_AMOUNT: {
      cells.push(
        makeDataCell(centsToDollars(progress.retentionReleased ?? 0), {
          leftContent,
          rightContent,
        })
      )
      break
    }
    case undefined:
      break
  }

  switch (taxesView) {
    case TaxesView.TAX_AMOUNT:
      cells.push(makeDataCell(centsToDollars(progress.amountDueTaxAmount)))
      break
    case TaxesView.TAX_RATE:
      cells.push(
        makeDataCell(
          decimalToPercent(
            progress.sovLineItem.taxGroup?.taxPercent ?? 0,
            TAX_RATE_PERCENT_PRECISION
          )
        )
      )
      break
    case TaxesView.TOTAL_POST_TAX:
      cells.push(makeDataCell(centsToDollars(progress.amountDuePostTax)))
      break
    case undefined:
      break
  }

  cells.push(
    // History toggle (appears on hover)
    makeContentCell(
      onToggleExpanded ? (
        <InvoiceHistoryButton
          isExpanded={isExpanded}
          progressId={progress.id}
          onToggleExpanded={onToggleExpanded}
        />
      ) : (
        <div />
      ),
      // We need to re-render the row whenever any of these change
      [progress.sovLineItem.id, isExpanded, onToggleExpanded]
    )
  )

  const isNew = isNewChangeOrder(progress, payAppStatus, timeZone, payAppBillingEnd)
  return {
    type: SpreadsheetRowType.DEFAULT,
    id: progress.sovLineItem.id,
    cells,
    backgroundColor: isNew ? 'blue' : 'default',
    isFirstInUngroupedBlock: isProgressFirstInGroup,
  }
}

/** Returns a row with a group header for a group of invoice line items */
export function getSovLineItemGroupHeader(
  group: SovLineItemGroupForProgress,
  numColumns: number
): SpreadsheetRow | null {
  if (!group) {
    return null
  }
  return {
    type: SpreadsheetRowType.DEFAULT,
    id: group.id,
    cells: [makeDataCell(group.code ?? ''), makeDataCell(group.name, { colSpan: numColumns - 1 })],
    isGroupHeaderRow: true,
    isNonEditableRow: true,
    isFirstInUngroupedBlock: true,
  }
}

/**
 * Returns a list of `SpreadsheetRow` components to be displayed in the pay app invoice
 * table for a given progress line item
 */
export function getProgressHistoryRows({
  billingType,
  progress,
  expandedProgress,
  numColumns,
  retentionView,
  taxesView,
  timeZone,
  t,
  shouldIncludeCostCode,
  storedMaterialsCarryoverType,
  storedMaterialsView,
}: {
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE
  progress: SovLineItemProgressProperties
  expandedProgress: SovLineItemProgressForEvents | undefined
  numColumns: number
  retentionView: RetentionView | undefined
  taxesView: TaxesView | undefined
  timeZone: string
  t: TFunction
  shouldIncludeCostCode: boolean
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  storedMaterialsView: StoredMaterialsView
}): SpreadsheetAuxiliaryRow[] {
  const historyRows: SpreadsheetAuxiliaryRow[] = []

  // Loaded UI
  if (expandedProgress && expandedProgress.id === progress.id) {
    const sortedEvents = _.orderBy(
      expandedProgress.events,
      (event) => moment.utc(event.createdAt).unix(),
      'desc'
    )
    const eventRows: SpreadsheetAuxiliaryRow[] = sortedEvents.map((event, index) => {
      const fakeProgress = calculateProgressFromEvent({
        progress,
        events: sortedEvents,
        index,
        storedMaterialsCarryoverType,
      })
      const cells: SpreadsheetCell[] = []
      switch (billingType) {
        case BillingType.LUMP_SUM:
          cells.push(
            ...getLumpSumProgressHistoryCells({
              event,
              progress,
              historyProgress: fakeProgress,
              retentionView,
              taxesView,
              timeZone,
              shouldIncludeCostCode,
              t,
              storedMaterialsCarryoverType,
            })
          )
          break
        case BillingType.UNIT_PRICE:
          cells.push(
            ...getUnitPriceProgressHistoryCells({
              event,
              progress,
              historyProgress: fakeProgress,
              retentionView,
              taxesView,
              timeZone,
              t,
              shouldIncludeCostCode,
              storedMaterialsCarryoverType,
              storedMaterialsView,
            })
          )
          break
      }
      return {
        type: SpreadsheetRowType.AUXILIARY,
        id: event.id,
        cells,
        isNonEditableRow: true,
      }
    })

    if (eventRows.length === 0) {
      eventRows.push({
        type: SpreadsheetRowType.AUXILIARY,
        id: `empty-row-${progress.id}`,
        cells: [
          makeContentCell(
            <SitelineText variant="secondary" color="grey70">
              {t('projects.subcontractors.pay_app.progress.events.empty')}
            </SitelineText>,
            [],
            { colSpan: numColumns, colOffset: 1 }
          ),
        ],
        isNonEditableRow: true,
      })
    }
    historyRows.push(...eventRows)
  } else {
    // Skeleton/loading UI
    historyRows.push({
      type: SpreadsheetRowType.AUXILIARY,
      id: `loading-history-${progress.sovLineItem.id}`,
      cells: [
        makeContentCell(
          <div className="historyDescription">
            <Skeleton variant="circular" className="avatar" />
            <Skeleton variant="rectangular" className="skeleton" width={200} />
          </div>,
          [],
          { colSpan: 4, colOffset: 1 }
        ),
        ..._.times(3, () => makeContentCell(<Skeleton variant="text" width="50%" />, [])),
        makeDataCell(''),
        ...(retentionView ? [makeContentCell(<Skeleton variant="text" width="50%" />, [])] : []),
        makeDataCell(''),
        makeContentCell(<Skeleton variant="text" width="50%" />, []),
      ],
      isNonEditableRow: true,
    })
  }
  return historyRows
}

export const RETENTION_PROGRESS_CELL_CLASS = 'retentionProgressCell'
export const HISTORICAL_PROGRESS_DATE_CELL_CLASS = 'historicalProgressDateCellClass'
export const RETENTION_ICONS_CLASS = 'retentionIcons'

type InvoiceTotalsRowProps = {
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE
  progress: SovLineItemProgressProperties[]
  payApp: Pick<PayApp, 'retentionReleased'>
  contract: ContractForPayApps
  currentRetention: number
  totalRetention: number
  hasRetentionOverride: boolean
  hasRetentionReleased: boolean
  lastPayAppWithRetentionHeldOverride: Pick<PayApp, 'id' | 'payAppNumber'> | null
  retentionView: RetentionView | undefined
  taxesView: TaxesView | undefined
  t: TFunction
  /** The totals row is first in its group if there are groups and the change orders row isn't shown */
  isFirstInUngroupedBlock: boolean
  onEditRetention?: (view?: AdjustRetentionView) => void
  shouldIncludeCostCode: boolean
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  storedMaterialsView: StoredMaterialsView
}

/**
 * Returns the totals row for the invoice, with total amounts for each column
 * to be displayed as a footer row in the invoice table
 */
export function getInvoiceTotalsRow({
  billingType,
  progress,
  payApp,
  currentRetention,
  totalRetention,
  hasRetentionOverride,
  lastPayAppWithRetentionHeldOverride,
  contract,
  hasRetentionReleased,
  retentionView,
  taxesView,
  t,
  isFirstInUngroupedBlock,
  shouldIncludeCostCode,
  onEditRetention,
  storedMaterialsCarryoverType,
  storedMaterialsView,
}: InvoiceTotalsRowProps): SpreadsheetFooterRow {
  const cells: SpreadsheetCell[] = []
  switch (billingType) {
    case BillingType.LUMP_SUM:
      cells.push(
        ...getLumpSumInvoiceTotalsCells({
          progress,
          t,
          shouldIncludeCostCode,
          totalsRowType: 'invoice',
          storedMaterialsCarryoverType,
        })
      )
      break
    case BillingType.UNIT_PRICE:
      cells.push(
        ...getUnitPriceInvoiceTotalsCells({
          progress,
          t,
          shouldIncludeCostCode,
          totalsRowType: 'invoice',
          storedMaterialsCarryoverType,
          storedMaterialsView,
        })
      )
      break
  }

  const showCurrentRetentionOverrideIcon =
    hasRetentionOverride && retentionView && retentionHeldViews.includes(retentionView)
  const showPastRetentionOverrideIcon =
    lastPayAppWithRetentionHeldOverride !== null &&
    retentionView &&
    retentionHeldToDateViews.includes(retentionView)
  const showPreSitelineRetentionOverrideIcon =
    usesStandardOrLineItemTracking(contract.retentionTrackingLevel) &&
    contract.preSitelineRetentionHeldOverride !== null

  // Determine whether to show a retention override icon
  let retentionOverrideIcon: ReactElement | null = null

  // Override on the current pay app
  if (showCurrentRetentionOverrideIcon) {
    retentionOverrideIcon = (
      <SitelineTooltip
        title={t('projects.subcontractors.pay_app.invoice.retention.pay_app_override')}
      >
        {onEditRetention ? (
          <IconButton
            size="small"
            onClick={() => onEditRetention(AdjustRetentionView.ADJUST_FUTURE)}
          >
            <LinkOffIcon />
          </IconButton>
        ) : (
          <LinkOffIcon />
        )}
      </SitelineTooltip>
    )

    // Override in a past pay app
  } else if (showPastRetentionOverrideIcon) {
    retentionOverrideIcon = (
      <SitelineTooltip
        title={t('projects.subcontractors.pay_app.invoice.retention.pay_app_past_override', {
          payAppNumber: lastPayAppWithRetentionHeldOverride.payAppNumber,
        })}
      >
        <LinkOffIcon />
      </SitelineTooltip>
    )

    // Pre-Siteline override on the SOV
  } else if (showPreSitelineRetentionOverrideIcon) {
    retentionOverrideIcon = (
      <SitelineTooltip
        title={t('projects.subcontractors.pay_app.invoice.retention.pay_app_pre_siteline_override')}
      >
        <LinkOffIcon />
      </SitelineTooltip>
    )
  }

  const retentionBilledIcon = hasRetentionReleased ? (
    <SitelineTooltip
      title={t('projects.subcontractors.pay_app.invoice.retention.pay_app_released')}
    >
      <AccountBalanceOutlinedIcon className="retentionTotalsIcon" />
    </SitelineTooltip>
  ) : null

  const rightContent = {
    content: (
      <div className={RETENTION_ICONS_CLASS}>
        {retentionOverrideIcon}
        {retentionBilledIcon}
      </div>
    ),
    dependencies: [
      lastPayAppWithRetentionHeldOverride,
      hasRetentionOverride,
      hasRetentionReleased,
      contract.preSitelineRetentionHeldOverride,
      contract.retentionTrackingLevel,
    ],
  }

  // Retention view
  switch (retentionView) {
    case RetentionView.HELD_CURRENT_PERCENT: {
      const currentRetention = _.sumBy(
        progress,
        (progressLineItem) => progressLineItem.currentRetention
      )
      const currentBilled = currentPayAppBilled([...progress])
      const retentionPercent = safeDivide(currentRetention, currentBilled, 0)
      cells.push(
        makeDataCell(decimalToPercent(retentionPercent, 1), {
          leftContent: { content: null, dependencies: [], align: 'right' },
          rightContent,
        })
      )
      break
    }
    case RetentionView.HELD_TO_DATE_PERCENT: {
      const currentBilled = currentPayAppBilled([...progress])
      const previousBilled = previousPayAppBilled([...progress])
      const totalBilled = currentBilled + previousBilled
      const retentionPercent = safeDivide(totalRetention, totalBilled, 0)
      cells.push(
        makeDataCell(decimalToPercent(retentionPercent, 1), {
          leftContent: { content: null, dependencies: [], align: 'right' },
          rightContent,
        })
      )
      break
    }
    case RetentionView.HELD_TO_DATE_AMOUNT: {
      cells.push(
        makeDataCell(centsToDollars(totalRetention), {
          leftContent: { content: null, dependencies: [], align: 'right' },
          rightContent,
        })
      )
      break
    }
    case RetentionView.HELD_CURRENT_AMOUNT: {
      cells.push(
        makeDataCell(centsToDollars(currentRetention), {
          leftContent: { content: null, dependencies: [], align: 'right' },
          rightContent,
        })
      )
      break
    }
    case RetentionView.RELEASED_CURRENT_AMOUNT: {
      let totalReleased = _.sumBy(
        progress,
        (progressLineItem) => progressLineItem.retentionReleased ?? 0
      )
      if (_.isNumber(payApp.retentionReleased)) {
        totalReleased = payApp.retentionReleased
      }
      cells.push(
        makeDataCell(centsToDollars(totalReleased), {
          leftContent: { content: null, dependencies: [], align: 'right' },
          rightContent,
        })
      )
      break
    }
    case undefined:
      break
  }

  if (taxesView) {
    cells.push(makeGroupTotalsTaxesCell({ groupProgress: progress, taxesView }))
  }

  // Empty column for history button
  cells.push(makeDataCell(''))

  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'totals',
    cells,
    isNonEditableRow: true,
    isFirstInUngroupedBlock,
    isFixed: true,
  }
}

function makeGroupTotalsRetentionCell({
  groupProgress,
  retentionView,
  t,
}: {
  groupProgress: SovLineItemProgressProperties[]
  retentionView: RetentionView | undefined
  t: TFunction
}) {
  const hasCurrentRetentionOverride = groupProgress.some(
    (progress) =>
      _.isNumber(progress.retentionHeldOverride) &&
      (progress.retentionHeldOverride !== 0 ||
        progress.currentBilled !== 0 ||
        progress.storedMaterialBilled !== 0)
  )
  const previousPayAppsWithRetentionOverrides = _.chain(groupProgress)
    .map((progress) => progress.lastProgressWithRetentionHeldOverride?.payApp)
    .compact()
    .value()
  const lastPayAppNumberWithRetentionOverride = _.maxBy(
    previousPayAppsWithRetentionOverrides,
    (payApp) => payApp.payAppNumber
  )?.payAppNumber
  const hasPastRetentionOverride = !!lastPayAppNumberWithRetentionOverride
  const hasRetentionBilling = groupProgress.some(
    (progress) => progress.previousRetentionBilled !== 0
  )

  const shouldShowCurrentOverrideIcon =
    hasCurrentRetentionOverride && retentionView && retentionHeldViews.includes(retentionView)
  const shouldShowPastRetentionOverrideIcon =
    hasPastRetentionOverride && retentionView && retentionHeldViews.includes(retentionView)
  const shouldShowRetentionOverrideIcon =
    shouldShowCurrentOverrideIcon || shouldShowPastRetentionOverrideIcon

  const retentionOverrideIcon = shouldShowRetentionOverrideIcon ? (
    <SitelineTooltip
      title={
        shouldShowCurrentOverrideIcon
          ? t('projects.subcontractors.pay_app.invoice.retention.progress_override_group')
          : t('projects.subcontractors.pay_app.invoice.retention.progress_past_override_group', {
              payAppNumber: lastPayAppNumberWithRetentionOverride,
            })
      }
    >
      <LinkOffIcon />
    </SitelineTooltip>
  ) : null
  const retentionBilledIcon = hasRetentionBilling ? (
    <SitelineTooltip
      title={t('projects.subcontractors.pay_app.invoice.retention.progress_released_group')}
    >
      <AccountBalanceOutlinedIcon />
    </SitelineTooltip>
  ) : null

  const leftContent = { content: null, dependencies: [], align: 'right' as const }

  const rightContent = {
    content: (
      <div className={RETENTION_ICONS_CLASS}>
        {/* This spacer aligns the footer icons with the line item retention icons */}
        <Spacer minWidth={1} maxWidth={1} />
        {retentionOverrideIcon}
        {retentionBilledIcon}
      </div>
    ),
    dependencies: [groupProgress, retentionView],
  }

  switch (retentionView) {
    case RetentionView.HELD_TO_DATE_PERCENT:
    case RetentionView.HELD_CURRENT_PERCENT: {
      const currentRetention = _.sumBy(groupProgress, (progress) => progress.currentRetention)
      const currentBilled = currentPayAppBilled([...groupProgress])
      const retentionPercent = safeDivide(currentRetention, currentBilled, 0)
      return makeDataCell(decimalToPercent(retentionPercent, 1), {
        leftContent,
        rightContent,
      })
    }
    case RetentionView.HELD_TO_DATE_AMOUNT: {
      const retentionAmount = _.sumBy(groupProgress, (progress) => retentionToDate(progress))
      return makeDataCell(centsToDollars(retentionAmount), {
        leftContent,
        rightContent,
      })
    }
    case RetentionView.HELD_CURRENT_AMOUNT: {
      const currentRetention = _.sumBy(groupProgress, (progress) => progress.currentRetention)
      return makeDataCell(centsToDollars(currentRetention), {
        leftContent,
        rightContent,
      })
    }
    case RetentionView.RELEASED_CURRENT_AMOUNT: {
      const totalReleased = _.sumBy(groupProgress, (progress) => progress.retentionReleased ?? 0)
      return makeDataCell(centsToDollars(totalReleased), {
        leftContent,
        rightContent,
      })
    }
    case undefined:
      return makeDataCell('')
  }
}

export function makeGroupTotalsTaxesCell({
  groupProgress,
  taxesView,
}: {
  groupProgress: SovLineItemProgressProperties[]
  taxesView: TaxesView
}) {
  switch (taxesView) {
    case TaxesView.TAX_AMOUNT: {
      const taxAmount = _.sumBy(groupProgress, (progress) => progress.amountDueTaxAmount)
      return makeDataCell(centsToDollars(taxAmount))
    }
    case TaxesView.TAX_RATE: {
      const taxRate = _.uniq(
        groupProgress.map((progress) => progress.sovLineItem.taxGroup?.taxPercent)
      )
      if (taxRate.length === 1) {
        return makeDataCell(decimalToPercent(taxRate[0] ?? 0, TAX_RATE_PERCENT_PRECISION))
      }
      return makeContentCell(null, [])
    }
    case TaxesView.TOTAL_POST_TAX: {
      const postTaxAmount = _.sumBy(groupProgress, (progress) => progress.amountDuePostTax)
      return makeDataCell(centsToDollars(postTaxAmount))
    }
  }
}

/** Returns a totals row for the invoice group */
export function getInvoiceGroupTotalsRow({
  group,
  t,
  billingType,
  allProgress,
  shouldIncludeCostCode,
  retentionView,
  taxesView,
  storedMaterialsView,
  storedMaterialsCarryoverType,
}: {
  group: SovLineItemGroupForProgress
  t: TFunction
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE
  allProgress: SovLineItemProgressProperties[]
  shouldIncludeCostCode: boolean
  retentionView: RetentionView | undefined
  taxesView: TaxesView | undefined
  storedMaterialsView: StoredMaterialsView
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}) {
  if (group === null) {
    return null
  }

  const groupProgress = allProgress.filter(
    ({ sovLineItem }) => sovLineItem.sovLineItemGroup?.id === group.id
  )

  const cells: SpreadsheetCell[] = []

  switch (billingType) {
    case BillingType.LUMP_SUM:
      cells.push(
        ...getLumpSumInvoiceTotalsCells({
          progress: groupProgress,
          t,
          shouldIncludeCostCode,
          totalsRowType: 'group',
          storedMaterialsCarryoverType,
        })
      )
      break
    case BillingType.UNIT_PRICE:
      cells.push(
        ...getUnitPriceInvoiceTotalsCells({
          progress: groupProgress,
          t,
          shouldIncludeCostCode,
          totalsRowType: 'group',
          storedMaterialsView,
          storedMaterialsCarryoverType,
        })
      )
      break
  }

  // Retention
  if (retentionView !== undefined) {
    cells.push(
      makeGroupTotalsRetentionCell({
        groupProgress,
        retentionView,
        t,
      })
    )
  }

  // Taxes
  if (taxesView !== undefined) {
    cells.push(makeGroupTotalsTaxesCell({ groupProgress, taxesView }))
  }

  // Empty column for history button column
  cells.push(makeDataCell(''))

  return {
    type: SpreadsheetRowType.FOOTER,
    id: `${group.id}-group-footer`,
    cells,
    isFixed: false,
    isNonEditableRow: true,
  }
}

/** A row in the invoice spreadsheet for creating a new change order */
export function getAddChangeOrderRow(
  payApp: PayAppForProgress,
  hasGroups: boolean,
  numColumns: number
): SpreadsheetFooterRow {
  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'add-change-order',
    cells: [
      makeContentCell(
        <div className="addChangeOrder">
          <AddChangeOrderButton payApp={payApp} variant="text" color="info" />
        </div>,
        [payApp],
        { colSpan: numColumns, colOffset: 1 }
      ),
    ],
    isNonEditableRow: true,
    // Add a border above this row if the spreadsheet has grouped rows
    isFirstInUngroupedBlock: hasGroups,
    isFixed: false,
  }
}

/** Empty state row when there are no progresses due to a search or filter. */
export function getClearFilterRow(
  state: InvoiceReducerState,
  dispatch: Dispatch<InvoiceAction>,
  numColumns: number,
  t: TFunction
): SpreadsheetRow | null {
  const showEmptyMonthMessage = !state.filter.viewAll && state.visibleProgress.length === 0
  const showEmptySearchMessage = !!state.filter.search && state.visibleProgress.length === 0

  let message = ''
  let buttonText = ''
  let onClick: () => void
  if (showEmptyMonthMessage) {
    message = t('projects.subcontractors.pay_app.invoice.empty_state.empty_month')
    buttonText = t('projects.subcontractors.pay_app.invoice.empty_state.show_all_items')
    onClick = () => {
      dispatch({
        type: 'UPDATE_FILTER',
        filter: {
          ...state.filter,
          viewAll: true,
        },
      })
    }
  } else if (showEmptySearchMessage) {
    message = t('projects.subcontractors.pay_app.invoice.empty_state.no_match')
    buttonText = t('projects.subcontractors.pay_app.invoice.empty_state.clear_search')
    onClick = () => {
      dispatch({
        type: 'UPDATE_FILTER',
        filter: {
          ...state.filter,
          search: '',
        },
      })
    }
  } else {
    return null
  }

  return {
    type: SpreadsheetRowType.DEFAULT,
    id: uuidv4(),
    cells: [
      makeContentCell(
        <div className="clear">
          <SitelineText variant="body1" className="message">
            {message}
          </SitelineText>
          <Button variant="outlined" color="secondary" onClick={onClick}>
            {buttonText}
          </Button>
        </div>,
        [],
        { colSpan: numColumns }
      ),
    ],
    backgroundColor: 'white',
  }
}
