import { Button, Menu, MenuItem, PopoverOrigin, styled } from '@mui/material'
import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText } from 'siteline-common-web'
import { AnimatedArrowDropdownIcon } from '../../../../common/components/AnimatedArrowDropdownIcon'
import { StoredMaterialsView } from '../InvoiceReducer'

const StyledStoredMaterialsColumnButton = styled(Button)(({ theme }) => ({
  height: 24,
  '& .endIcon': {
    // Use a smaller margin to save space
    marginLeft: `${theme.spacing(0.5)} !important`,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
}))

const StyledStoredMaterialsMenu = styled(Menu)(() => ({
  '& .MuiSvgIcon-root': {
    opacity: 0,
    '&.active': {
      opacity: 1,
    },
  },
}))

export const STORED_MATERIALS_VIEW_STORAGE_KEY = 'storedMaterialsColumnView'

const i18nBasePreSiteline = 'projects.subcontractors.sov.headers'
const i18nBase = 'projects.subcontractors.pay_app.invoice.headers'

const TRANSFORM_ORIGIN: PopoverOrigin = { horizontal: 'right', vertical: 'top' }
const ANCHOR_ORIGIN: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' }

interface StoredMaterialsColumnToggleProps {
  displayMetric: StoredMaterialsView
  onDisplayMetricChange: (metric: StoredMaterialsView) => void
  bold?: boolean
  /** Determines what we show on the dropdown label */
  isPreSiteline: boolean
}

/**
 * Dropdown toggle on the unit price invoice stored materials column header for switching display metric
 * between units and dollars
 */
export function StoredMaterialsColumnToggle({
  displayMetric,
  onDisplayMetricChange,
  bold,
  isPreSiteline,
}: StoredMaterialsColumnToggleProps) {
  const { t } = useTranslation()

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLSpanElement>(null)

  const handleOpen = useCallback((evt: MouseEvent<HTMLSpanElement>) => {
    setMenuAnchorEl(evt.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const handleUnitClick = useCallback(() => {
    onDisplayMetricChange(StoredMaterialsView.UNIT)
    handleClose()
  }, [handleClose, onDisplayMetricChange])

  const handleAmountClick = useCallback(() => {
    onDisplayMetricChange(StoredMaterialsView.AMOUNT)
    handleClose()
  }, [handleClose, onDisplayMetricChange])

  const isMenuOpen = Boolean(menuAnchorEl)

  const unitsLabel = isPreSiteline
    ? t(`${i18nBasePreSiteline}.materials_stored_unit`)
    : t(`${i18nBase}.stored_materials_unit`)
  const amountLabel = isPreSiteline
    ? t(`${i18nBasePreSiteline}.materials_stored_amount`)
    : t(`${i18nBase}.stored_materials_amount`)
  const label = displayMetric === StoredMaterialsView.UNIT ? unitsLabel : amountLabel

  return (
    <>
      <StyledStoredMaterialsColumnButton variant="text" onClick={handleOpen}>
        <SitelineText
          variant="smallText"
          color="grey50"
          endIcon={<AnimatedArrowDropdownIcon isMenuOpen={isMenuOpen} />}
          bold={bold}
        >
          {label}
        </SitelineText>
      </StyledStoredMaterialsColumnButton>
      <StyledStoredMaterialsMenu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        transformOrigin={TRANSFORM_ORIGIN}
        anchorOrigin={ANCHOR_ORIGIN}
        keepMounted
      >
        <MenuItem
          key="unit"
          onClick={handleUnitClick}
          selected={displayMetric === StoredMaterialsView.UNIT}
        >
          {unitsLabel}
        </MenuItem>
        <MenuItem
          key="amount"
          onClick={handleAmountClick}
          selected={displayMetric === StoredMaterialsView.AMOUNT}
        >
          {amountLabel}
        </MenuItem>
      </StyledStoredMaterialsMenu>
    </>
  )
}
