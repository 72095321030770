import { Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import {
  FEE_PERCENT_DECIMAL_PRECISION,
  FEE_PERCENT_PRECISION,
  decimalToPercent,
  percentToDecimal,
} from 'siteline-common-all'
import {
  SitelineText,
  colors,
  evictWithGc,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import * as fragments from '../../../common/graphql/Fragments'
import {
  BillingType,
  RateTableFeeProperties,
  RateTableGroupProperties,
  useCreateContractRateTableFeeMutation,
  useDeleteContractRateTableFeeMutation,
  useUpdateContractRateTableFeeMutation,
} from '../../../common/graphql/apollo-operations'
import { trackPricingFeeAddition, trackPricingFeeEdit } from '../../../common/util/MetricsTracking'
import { ContractForProjectHome } from '../home/ProjectHome'
import { SendEmailDialogRow } from '../submit-dialog/SendEmailDialogRow'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '&.isDialogHidden': {
      opacity: 0,
    },
    '& .rightContent': {
      paddingLeft: theme.spacing(3),
      width: '100%',
    },
    '& .numericFormatInput': {
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      height: theme.spacing(5),
      '&:hover': {
        borderColor: colors.grey50,
      },
      '&:focus-visible': {
        outline: 'none',
      },
    },
    '& .percentField': {
      width: 100,
      ...theme.typography.body1,
    },
    '& .deleteButton': {
      marginLeft: theme.spacing(-1),
    },
  },
}))

export type AddOrEditFee = Omit<RateTableFeeProperties, 'id' | '__typename' | 'percent'> & {
  id: null | string
  percent: RateTableFeeProperties['percent'] | undefined
  shouldOverride?: boolean
  /** Disable delete if the fee has already been deleted ("deleted" fees are overrides set to 0%) */
  isFeeDeleted?: boolean
}

export const createEmptyFee = (group: RateTableGroupProperties | null): AddOrEditFee => ({
  id: null,
  description: '',
  percent: 0,
  group,
  overridesFee: null,
})

const i18nBase = 'projects.onboarding.checklist'

interface AddOrEditFeeDialogProps {
  /**
   * We derive which dialog type to display based on the properties of addOrEditFee
   * - if addOrEditFee is null, the dialog is closed
   * - if addOrEditFee.id is null, addOrEditFee is empty and we're adding it for the first time
   * - if addOrEditFee.id is defined, we're editing an existing fee
   */
  addOrEditFee: AddOrEditFee | null
  onClose: () => void
  groups: RateTableGroupProperties[]
  contract: ContractForProjectOnboarding | ContractForProjectHome
  // Used for metrics
  location: 'projectOnboarding' | 'projectSettings'
}

/** Dialog that allows the user to add fees to their t&m contract  */
export function AddOrEditFeeDialog({
  addOrEditFee: initialAddOrEditFee,
  onClose,
  groups,
  contract,
  location,
}: AddOrEditFeeDialogProps) {
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()
  const snackbar = useSitelineSnackbar()
  const classes = useStyles()

  // Hide the dialog while presenting the confirmation modal, but don't close
  const [isDialogHidden, setIsDialogHidden] = useState<boolean>(false)
  const [addOrEditFee, setAddOrEditFee] = useState<AddOrEditFee | null>(initialAddOrEditFee)

  const [createRateTableFee] = useCreateContractRateTableFeeMutation()
  const [updateRateTableFee] = useUpdateContractRateTableFeeMutation()
  const [deleteRateTableFee] = useDeleteContractRateTableFeeMutation()

  // User clicked on a previously added fee (global or category). This dialog will allow them to make edits
  const isEditingExistingFee = addOrEditFee !== null && addOrEditFee.id !== null
  // User clicked "Add new fee" button (global or category)
  const isAddingNewFee = addOrEditFee !== null && !isEditingExistingFee
  // User is adding or editing a group fee
  const isGroupFee = addOrEditFee !== null && addOrEditFee.group !== null
  // User is adding or editing a global fee
  const isGlobalFee = addOrEditFee !== null && !isGroupFee
  // User clicked on a global fee from a rate table category. This dialog will allow them to override the fee.
  const shouldOverride = addOrEditFee !== null && addOrEditFee.shouldOverride === true
  // User clicked on an override from a rate table category (already overridden)
  const isOverride = addOrEditFee !== null && addOrEditFee.overridesFee !== null
  // Use this to determine if fees apply to the COR pricing tool or T&M pay app invoice
  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS

  // Update addOrEditFee held in state whenever initialAddOrEditFee changes (i.e., a new dialog is opened)
  useEffect(() => {
    setAddOrEditFee(initialAddOrEditFee)
  }, [initialAddOrEditFee])

  const groupsById = useMemo(() => _.keyBy(groups, (group) => group.id), [groups])

  const feePercent = useMemo(() => {
    if (addOrEditFee === null || addOrEditFee.percent === undefined) {
      return ''
    }
    return decimalToPercent(addOrEditFee.percent, FEE_PERCENT_PRECISION)
  }, [addOrEditFee])

  const hasEditedFee = useMemo(() => {
    if (addOrEditFee === null || initialAddOrEditFee === null) {
      return true
    }
    return (
      addOrEditFee.description !== initialAddOrEditFee.description ||
      addOrEditFee.group?.id !== initialAddOrEditFee.group?.id ||
      addOrEditFee.overridesFee?.id !== initialAddOrEditFee.overridesFee?.id ||
      addOrEditFee.percent !== initialAddOrEditFee.percent
    )
  }, [addOrEditFee, initialAddOrEditFee])

  const renderGroupSelectorValue = useCallback(
    (value: string) => {
      return groupsById[value].name
    },
    [groupsById]
  )

  const handleGroupChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (!isGroupFee) {
        return
      }
      const updatedGroup = groupsById[event.target.value]
      setAddOrEditFee({ ...addOrEditFee, group: updatedGroup })
    },
    [addOrEditFee, groupsById, isGroupFee]
  )

  const handleFeeTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (addOrEditFee === null) {
        return
      }
      setAddOrEditFee({ ...addOrEditFee, description: event.target.value })
    },
    [addOrEditFee]
  )

  const handleFeePercentChange = useCallback(
    (update: NumberFormatValues) => {
      if (addOrEditFee === null) {
        return
      }
      const floatValue = update.floatValue
      const asDecimal =
        floatValue === undefined
          ? undefined
          : percentToDecimal(floatValue, FEE_PERCENT_DECIMAL_PRECISION)
      setAddOrEditFee({ ...addOrEditFee, percent: asDecimal })
    },
    [addOrEditFee]
  )

  const handleSubmit = useCallback(
    async (isDeletingGlobalFeeFromCategory?: boolean) => {
      if (!addOrEditFee || addOrEditFee.percent === undefined) {
        return
      }
      if (isAddingNewFee || addOrEditFee.shouldOverride) {
        if (isAddingNewFee && !addOrEditFee.percent) {
          return
        }
        try {
          await createRateTableFee({
            variables: {
              input: {
                contractId: contract.id,
                description: addOrEditFee.description,
                percent: isDeletingGlobalFeeFromCategory ? 0 : addOrEditFee.percent,
                groupId: addOrEditFee.group?.id,
                overridesFeeId: addOrEditFee.id,
              },
            },
            update(cache, { data }) {
              if (!data) {
                return
              }
              cache.modify({
                id: cache.identify(contract),
                fields: {
                  rateTableFees(existingRefs, { toReference }) {
                    const fragment = cache.writeFragment({
                      data: data.createContractRateTableFee,
                      fragment: fragments.rateTable,
                      fragmentName: 'RateTableProperties',
                    })
                    const refs = toReferences(existingRefs, toReference)
                    return [...refs, fragment]
                  },
                },
              })
              evictWithGc(cache, (evict) => {
                evict({ id: cache.identify(contract), fieldName: 'payApps' })
                contract.changeOrderRequests.forEach((changeOrderRequest) => {
                  evict({
                    id: cache.identify(changeOrderRequest),
                    fieldName: 'haveContractFeesChanged',
                  })
                })
              })
            },
          })
          trackPricingFeeAddition({
            projectId: contract.project.id,
            projectName: contract.project.name,
            location,
            groupName: addOrEditFee.group ? groupsById[addOrEditFee.group.id].name : null,
            description: addOrEditFee.description,
            percent: decimalToPercent(addOrEditFee.percent, FEE_PERCENT_PRECISION),
            type: isTimeAndMaterials ? 'timeAndMaterials' : 'corPricingTool',
          })
          onClose()
        } catch (error) {
          snackbar.showError(error.message)
        }
      } else if (isEditingExistingFee && addOrEditFee.id !== null) {
        try {
          await updateRateTableFee({
            variables: {
              input: {
                feeId: addOrEditFee.id,
                description: isOverride ? undefined : addOrEditFee.description,
                percent: isDeletingGlobalFeeFromCategory ? 0 : addOrEditFee.percent,
              },
            },
            update(cache) {
              evictWithGc(cache, (evict) => {
                evict({ id: cache.identify(contract), fieldName: 'payApps' })
                contract.changeOrderRequests.forEach((changeOrderRequest) => {
                  evict({
                    id: cache.identify(changeOrderRequest),
                    fieldName: 'haveContractFeesChanged',
                  })
                })
              })
            },
          })
          trackPricingFeeEdit({
            projectId: contract.project.id,
            projectName: contract.project.name,
            location,
            groupName: addOrEditFee.group ? groupsById[addOrEditFee.group.id].name : null,
            description: addOrEditFee.description,
            percent: decimalToPercent(addOrEditFee.percent, FEE_PERCENT_PRECISION),
            type: isTimeAndMaterials ? 'timeAndMaterials' : 'corPricingTool',
          })
          onClose()
        } catch (error) {
          snackbar.showError(error.message)
        }
      }
    },
    [
      addOrEditFee,
      contract,
      createRateTableFee,
      groupsById,
      isAddingNewFee,
      isEditingExistingFee,
      isOverride,
      isTimeAndMaterials,
      location,
      onClose,
      snackbar,
      updateRateTableFee,
    ]
  )

  const handleDelete = useCallback(async () => {
    if (!addOrEditFee?.id) {
      return
    }
    try {
      await deleteRateTableFee({
        variables: {
          input: { feeId: addOrEditFee.id },
        },
        update(cache, { data }) {
          if (!data) {
            return
          }
          cache.modify({
            id: cache.identify(contract),
            fields: {
              rateTableFees(existingRefs, { readField, toReference }) {
                const refs = toReferences(existingRefs, toReference)
                return refs.filter(
                  (ref) => readField('id', ref) !== data.deleteContractRateTableFee.id
                )
              },
            },
          })
          evictWithGc(cache, (evict) => {
            evict({ id: cache.identify(contract), fieldName: 'payApps' })
            contract.changeOrderRequests.forEach((changeOrderRequest) => {
              evict({
                id: cache.identify(changeOrderRequest),
                fieldName: 'haveContractFeesChanged',
              })
            })
          })
        },
      })
      onClose()
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [addOrEditFee?.id, deleteRateTableFee, onClose, contract, snackbar])

  const handleConfirmAndDelete = useCallback(async () => {
    const isDeletingGlobalFeeFromCategory = shouldOverride || isOverride
    // Assume that we're deleting a fee from onboarding. Message should indicate that fees can be
    // adjusted in settings later
    let details = isDeletingGlobalFeeFromCategory
      ? // If they're deleting a global fee from a category, tell them that we'll be setting that fee to 0
        t(`${i18nBase}.delete_override_fee_confirmation_onboarding`)
      : // Otherwise they're deleting a global fee. Warn them that this will be deleted from all categories
        t(`${i18nBase}.delete_global_fee_confirmation_onboarding`)
    // If we're deleting a fee from settings, don't mention that the fees can be adjusted in settings later
    if (location === 'projectSettings') {
      details = isDeletingGlobalFeeFromCategory
        ? t(`${i18nBase}.delete_override_fee_confirmation_settings`)
        : t(`${i18nBase}.delete_global_fee_confirmation_settings`)
    }
    // If they are deleting a custom group fee, use a generic message
    if (!isGlobalFee && !isDeletingGlobalFeeFromCategory) {
      details = t(`${i18nBase}.delete_fee_confirmation_details`)
    }
    setIsDialogHidden(true)
    confirm({
      title: t(`${i18nBase}.delete_fee_confirmation_title`),
      details,
      confirmationType: 'delete',
      confirmLabel: t('common.actions.remove'),
      callback: async (confirmed) => {
        if (confirmed) {
          if (isDeletingGlobalFeeFromCategory) {
            // ShouldOverride: Special case where the user opts to delete a global fee from a category.
            // isOverride: User has already overridden this fee. In both cases, we'll set the fee to 0%,
            // rather than deleting
            await handleSubmit(isDeletingGlobalFeeFromCategory)
          } else {
            // If it's a category fee that is NOT tied to a global fee, or if it is a global fee, delete
            await handleDelete()
          }
        }
        setIsDialogHidden(false)
      },
    })
  }, [confirm, handleDelete, handleSubmit, isGlobalFee, isOverride, location, shouldOverride, t])

  const canEditDescription = !(shouldOverride || isOverride)

  const isSubmitDisabled =
    !addOrEditFee ||
    !addOrEditFee.description ||
    addOrEditFee.percent === undefined ||
    (!(shouldOverride || isOverride) && addOrEditFee.percent === 0) ||
    !hasEditedFee

  return (
    <SitelineDialog
      open={addOrEditFee !== null}
      onClose={onClose}
      title={isEditingExistingFee ? t(`${i18nBase}.edit_fee`) : t(`${i18nBase}.add_fee`)}
      subtitle={
        isAddingNewFee && isGlobalFee ? t(`${i18nBase}.add_fee_global_subtitle`) : undefined
      }
      subtitleVariant="body1"
      maxWidth={canEditDescription ? 'sm' : 'xs'}
      submitLabel={isAddingNewFee ? t('common.actions.add') : t('common.actions.save')}
      onSubmit={handleSubmit}
      disableSubmit={isSubmitDisabled}
      className={clsx(classes.root, { isDialogHidden })}
      subscript={
        isEditingExistingFee ? (
          <Button
            variant="text"
            onClick={handleConfirmAndDelete}
            color="error"
            className="deleteButton"
            disabled={addOrEditFee.isFeeDeleted}
          >
            {t('common.actions.delete')}
          </Button>
        ) : undefined
      }
    >
      {isGroupFee && (
        <SendEmailDialogRow label={t(`${i18nBase}.apply_fee_to`)}>
          {!isEditingExistingFee && (
            <div className="rightContent">
              <Select
                value={addOrEditFee.group.id}
                renderValue={renderGroupSelectorValue}
                onChange={handleGroupChange}
                fullWidth
              >
                {groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {isEditingExistingFee && (
            <div className="rightContent">
              <SitelineText variant="body1">
                {renderGroupSelectorValue(addOrEditFee.group.id)}
              </SitelineText>
            </div>
          )}
        </SendEmailDialogRow>
      )}
      <SendEmailDialogRow label={t(`${i18nBase}.fee_type`)}>
        {canEditDescription && (
          <div className="rightContent">
            <TextField
              value={addOrEditFee?.description ?? ''}
              onChange={handleFeeTypeChange}
              fullWidth
            />
          </div>
        )}
        {!canEditDescription && (
          <div className="rightContent">
            <SitelineText variant="body1">{addOrEditFee.description}</SitelineText>
          </div>
        )}
      </SendEmailDialogRow>
      <SendEmailDialogRow label={t(`${i18nBase}.percent`)}>
        <div className="rightContent">
          <NumericFormat
            value={feePercent}
            decimalScale={FEE_PERCENT_PRECISION}
            displayType="input"
            suffix="%"
            allowNegative={false}
            onValueChange={handleFeePercentChange}
            className="numericFormatInput percentField"
          />
        </div>
      </SendEmailDialogRow>
    </SitelineDialog>
  )
}
