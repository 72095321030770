import HelpIcon from '@mui/icons-material/Help'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button, IconButton, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Permission, centsToDollars, dollarsToCents, roundCents } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { SitelineAlert } from '../../../../common/components/SitelineAlert'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../../common/contexts/CompanyContext'
import { EditingSov } from '../../../../common/util/ManageSov'
import { usesStandardOrLineItemTracking } from '../../../../common/util/Retention'
import { ContractForEditingSov } from '../../onboarding/SovOnboarding'

const useStyles = makeStylesFast((theme: Theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  inputs: {
    '& .row': {
      display: 'flex',
      margin: theme.spacing(3, 0),
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        width: 248,
        '& .MuiSvgIcon-root': {
          marginLeft: theme.spacing(1),
          fontSize: 14,
          color: colors.grey50,
        },
      },
      '& .valueInput': {
        flex: 1,
        alignSelf: 'center',
        '& .MuiTypography-root': {
          textAlign: 'right',
          paddingRight: theme.spacing(1),
        },
        '& input': {
          textAlign: 'right',
          height: 40,
          padding: theme.spacing(0, 1),
          ...theme.typography.body1,
        },
      },
    },
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
}))

interface AdjustContractRetentionDialogProps {
  open: boolean
  onClose: () => void
  contract: ContractForEditingSov
  sov: EditingSov
  onSubmit: (preSitelineRetentionHeldOverride: number | null) => void
}

const i18nBase = 'projects.subcontractors.sov.adjust_pre_siteline_retention'
const RETENTION_HELP_CENTER_ARTICLE =
  'https://support.siteline.com/hc/en-us/articles/9981052773268-Make-retention-adjustments'

/** Dialog for updating the pre-siteline retention for a contract */
export function AdjustPreSitelineRetentionDialog({
  open,
  onClose,
  contract,
  sov,
  onSubmit,
}: AdjustContractRetentionDialogProps) {
  const classes = useStyles()
  const { permissions } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_INVOICE)
  const { t } = useTranslation()

  // When using STANDARD/LINE_ITEM retention, any adjustement at the contract level is an override
  // and we should warn the user.
  const wouldOverride = usesStandardOrLineItemTracking(contract.retentionTrackingLevel)

  const title = wouldOverride
    ? t(`${i18nBase}.dialog_title_override`)
    : t(`${i18nBase}.dialog_title_edit`)
  const subtitle = wouldOverride
    ? t(`${i18nBase}.dialog_subtitle_override`)
    : t(`${i18nBase}.dialog_subtitle_edit`)

  const initialRetentionHeldAmount = useMemo(
    () =>
      usesStandardOrLineItemTracking(contract.retentionTrackingLevel)
        ? sov.preSitelineRetentionHeldOverride
        : (sov.preSitelineRetentionHeldOverride ?? 0),
    [sov.preSitelineRetentionHeldOverride, contract.retentionTrackingLevel]
  )

  const [retentionHeldAmount, setRetentionHeldAmount] = useState<number | null>(
    initialRetentionHeldAmount
  )
  const [hasEditedHeldAmount, setHasEditedHeldAmount] = useState<boolean>(false)

  const handleSubmit = useCallback(() => {
    onSubmit(retentionHeldAmount)
    onClose()
  }, [onClose, onSubmit, retentionHeldAmount])

  const handleResetDialog = useCallback(() => {
    setRetentionHeldAmount(initialRetentionHeldAmount)
  }, [initialRetentionHeldAmount])

  const handleResetOverride = useCallback(() => {
    onSubmit(null)
    onClose()
  }, [onClose, onSubmit])

  const resetOverrideButton = wouldOverride && sov.preSitelineRetentionHeldOverride && (
    <Button variant="outlined" color="secondary" onClick={handleResetOverride}>
      {t(`${i18nBase}.reset_override`)}
    </Button>
  )

  const showUnroundedWarning = useMemo(() => {
    if (!_.isNumber(retentionHeldAmount)) {
      return false
    }
    return roundCents(retentionHeldAmount, contract.roundRetention) !== retentionHeldAmount
  }, [contract.roundRetention, retentionHeldAmount])

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      title={
        <div className={classes.title}>
          <SitelineText variant="h1" bold>
            {title}
          </SitelineText>
          <Tooltip
            title={
              <SitelineText variant="smallText" endIcon={<LaunchIcon style={{ fontSize: 14 }} />}>
                {t(`${i18nBase}.help_center`)}
              </SitelineText>
            }
            placement="top-start"
          >
            <IconButton
              color="secondary"
              onClick={() => {
                window.open(RETENTION_HELP_CENTER_ARTICLE, '_blank')
              }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      subtitle={
        <SitelineText variant="body1" color="grey50" className="subtitle">
          {subtitle}
        </SitelineText>
      }
      subtitleVariant="body1"
      submitLabel={t('common.actions.confirm')}
      additionalButton={resetOverrideButton}
      onSubmit={handleSubmit}
      disableSubmit={!hasEditedHeldAmount || !canEdit}
      maxWidth="sm"
      onResetDialog={handleResetDialog}
    >
      {wouldOverride && (
        <div className={classes.warning}>
          <SitelineAlert severity="warning">{t(`${i18nBase}.override_warning`)}</SitelineAlert>
        </div>
      )}
      {showUnroundedWarning && (
        <div className={classes.warning}>
          <SitelineAlert severity="warning">{t(`${i18nBase}.unrounded_warning`)}</SitelineAlert>
        </div>
      )}
      <div className={classes.inputs}>
        <div className="row">
          <div className="title">
            <SitelineText variant="body1" color="grey70">
              {t(`${i18nBase}.pre_siteline_retention_held`)}
            </SitelineText>
            <Tooltip title={t(`${i18nBase}.pre_siteline_retention_held_tooltip`)}>
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
          <div className="valueInput">
            <NumericFormat
              value={_.isNumber(retentionHeldAmount) ? centsToDollars(retentionHeldAmount) : null}
              onValueChange={({ floatValue }) => {
                const newHeldAmount = _.isNumber(floatValue) ? dollarsToCents(floatValue) : null
                setRetentionHeldAmount(newHeldAmount)
                setHasEditedHeldAmount(true)
              }}
              decimalScale={2}
              displayType="input"
              thousandSeparator
              prefix="$"
              allowNegative
            />
          </div>
        </div>
      </div>
    </SitelineDialog>
  )
}
