import { roundCents } from './currency.js'

/**
 * Whether the pre-siteline retention $ should be automatically updated when pre-siteline billing
 * or default retention percent is updated.
 * If any of the 3 columns is hidden, this is false.
 *
 * Example:
 *  - Billing $100, Default 10%, Retention $10 => Linked
 *  - Billing $100, Default 10%, Retention $9 => Not linked
 */
export function isPreSitelineRetentionLinked({
  defaultRetentionPercent,
  preSitelineBilling,
  preSitelineRetentionAmount,
  roundRetention,
}: {
  defaultRetentionPercent: number
  preSitelineBilling: number
  roundRetention: boolean
  preSitelineRetentionAmount: number
}): boolean {
  const expectedPreSitelineRetentionAmount = roundCents(
    defaultRetentionPercent * preSitelineBilling,
    roundRetention
  )
  return preSitelineRetentionAmount === expectedPreSitelineRetentionAmount
}

/**
 * To make it easier to enter retention for projects with past pay apps, we automatically
 * fill in the pre-siteline retention $ when the pre-siteline billing is updated OR
 * when updating the default retention %.
 * We do this only if pre-siteline billing and retention match the default percentage,
 * to avoid overriding pre-siteline retention that was manually entered.
 * For instance:
 *  - Default 10%, pre-siteline billing $100, pre-siteline retention $10 => Linked
 *  - Default 10%, pre-siteline billing $100, pre-siteline retention $9 => Not linked
 * When linked, we update the pre-siteline retention automatically to match the new default
 * percentage OR the new pre-siteline billing, whichever changed.
 *
 * This function checks if the numbers were previously linked and if so, returns the new retention
 * amount to include in the pre-Siteline billing update.
 */
export function updatedPreSitelineRetentionForPreSitelineBillingUpdate({
  newPreSitelineBilling,
  oldPreSitelineBilling,
  preSitelineRetentionAmount,
  defaultRetentionPercent,
  roundRetention,
}: {
  newPreSitelineBilling: number
  oldPreSitelineBilling: number
  preSitelineRetentionAmount: number
  defaultRetentionPercent: number
  roundRetention: boolean
}): number | undefined {
  const isLinked = isPreSitelineRetentionLinked({
    defaultRetentionPercent,
    preSitelineBilling: oldPreSitelineBilling,
    preSitelineRetentionAmount,
    roundRetention,
  })
  if (!isLinked) {
    return undefined
  }
  const newPreSitelineRetentionAmount = roundCents(
    defaultRetentionPercent * newPreSitelineBilling,
    roundRetention
  )
  return newPreSitelineRetentionAmount
}
