import { TextFieldProps } from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers'
import { clsx } from 'clsx'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 250,
    },
    '& .MuiPickersDay-root': {
      fontSize: 12,
      fontWeight: 'normal',
      '&.Mui-selected': {
        fontWeight: 'bold',
      },
    },
  },
  input: {
    width: '100%',
    '& input': {
      height: 40,
      boxSizing: 'border-box',
      fontFamily: '"InterVariable", sans-serif',
      fontSize: 16,
      paddingLeft: theme.spacing(1),
    },
    '& .MuiIconButton-root': {
      color: colors.grey50,
      '&:hover': {
        backgroundColor: colors.white,
        color: colors.grey90,
      },
    },
  },
  monthInput: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey30}`,
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    '& input': {
      textAlign: 'left',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      flexGrow: 1,
    },
    '& .MuiSvgIcon-root': {
      // Match icon in default date picker
      fontSize: 21,
    },
  },
  helperText: {
    marginLeft: theme.spacing(0.5),
    ...theme.typography.h6,
  },
}))

// Errors thrown by the DatePicker component if an invalid date is entered. Note, `invalidDate` is not
// always returned even if the input is invalid, so we do our own error handling if the `value` is null.
type ErrorReason = 'disablePast' | 'disableFuture' | 'minDate' | 'maxDate' | 'invalidDate' | string

export const HELPER_TEXT_DATE_FORMAT = 'MMM D, YYYY'

/**
 * This is the primary type used for managing dates that can be selected or edited with the date picker.
 * It is typed this way to distinguish between a valid input, which may be a complete date or null, and
 * invalid dates, such as an incomplete dates or dates outside a given valid range.
 */
export type DatePickerValue =
  | {
      type: 'valid'
      date: Moment | null
    }
  | {
      type: 'invalid'
      date: Moment | null
      error: ErrorReason
    }

/** Simple helper for creating a valid date picker value */
export function makeDatePickerValue(date: Moment | null): DatePickerValue {
  return { type: 'valid', date }
}

type ValidDatePickerValue = { type: 'valid'; date: Moment }

/** Whether a date value is "missing", either because the date is null or invalid */
export function isMissingDate(value: DatePickerValue): boolean {
  return value.type === 'invalid' || value.date === null
}

export function isValidDate(value: DatePickerValue): value is ValidDatePickerValue {
  return !isMissingDate(value)
}

interface DatePickerInputProps
  extends Omit<DatePickerProps<Moment>, 'format' | 'renderInput' | 'value' | 'onChange'> {
  value: DatePickerValue
  /** Called when a valid date is entered, either by input or from the date picker */
  onChange: (value: DatePickerValue) => void
  /**
   * Called when a date is selected from the picker. Note that when a date is selected from the picker,
   * `onChange` is also called; these functions are not mutually exclusive.
   */
  onSelectFromPicker?: (date: Moment) => void
  timeZone: string
  /** Custom error message rendered when the provided date is before minDate */
  minDateMessage?: string
  /** Custom error message rendered when the provided date is after maxDate */
  maxDateMessage?: string
  onKeyDown?: TextFieldProps['onKeyDown']
  format?: DatePickerProps<Moment>['format']

  /** If true, clicking anywhere on the input will open the selector */
  disableKeyboardInput?: boolean

  /** If true, will not show the red error border when value is `null` */
  isEmptyValueValid?: boolean
}

const DATE_PICKER_INPUT_FORMAT = 'MM/DD/YYYY'

// Converts a date to the project timezone. Note that we've tried doing this with
// `date.clone().tz(timeZone)` and it doesn't work on non-PT timezones. This seems to
// work across timezones.
export function localizeDate(date: Moment, timeZone: string) {
  return moment.tz(date.format(DATE_PICKER_INPUT_FORMAT), DATE_PICKER_INPUT_FORMAT, timeZone)
}

/** A text field input for selecting a date */
export function DatePickerInput({
  timeZone,
  value,
  onChange,
  onSelectFromPicker,
  className,
  minDateMessage,
  maxDateMessage,
  isEmptyValueValid = false,
  autoFocus,
  onKeyDown,
  disableKeyboardInput,
  ...props
}: DatePickerInputProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  let errorText = t('common.errors.dates.invalidDate')
  if (value.type === 'invalid') {
    const { error } = value
    switch (error) {
      case 'disablePast':
      case 'disableFuture':
      case 'invalidDate':
        errorText = t(`common.errors.dates.${error}`)
        break
      case 'minDate': {
        if (props.minDate && props.minDate.isValid()) {
          errorText =
            minDateMessage ??
            t('common.errors.dates.minDate', {
              minDate: props.minDate.format(HELPER_TEXT_DATE_FORMAT),
            })
        }
        break
      }
      case 'maxDate': {
        if (props.maxDate && props.maxDate.isValid()) {
          errorText =
            maxDateMessage ??
            t('common.errors.dates.maxDate', {
              maxDate: props.maxDate.format(HELPER_TEXT_DATE_FORMAT),
            })
        }
        break
      }

      // There are extra reasons that we don't want to surface
      // eslint-disable-next-line no-restricted-syntax
      default:
        break
    }
  }

  // Only show an error message if there's a real date shown, but it's an invalid date for some reason
  const showErrorMessage = value.date?.isValid() && value.type === 'invalid'
  // Falling back to `undefined` will allow the input to apply its own error logic
  const hasError = value.type === 'invalid' || (!value.date && !isEmptyValueValid) || undefined

  // MUI's `DatePicker` input handles device detection and enables/disables certain input features based on
  // whether the user is on mobile or desktop. Sometimes MUI is wrong about the device the user is on.
  // We should always use the same component, regardless of device. `DesktopDatePicker` works in all cases.
  return (
    <DesktopDatePicker
      open={open}
      format={DATE_PICKER_INPUT_FORMAT}
      value={value.date}
      onChange={(date) => {
        if (!date) {
          onChange({ type: 'valid', date: null })
          return
        }
        const localizedDate = localizeDate(date, timeZone)
        if (!localizedDate.isValid()) {
          onChange({ type: 'invalid', error: 'invalidDate', date: localizedDate })
          return
        }
        onChange({ type: 'valid', date: localizedDate })
      }}
      onAccept={(date) =>
        date && onSelectFromPicker && onSelectFromPicker(localizeDate(date, timeZone))
      }
      onError={(reason, value) =>
        reason && onChange({ type: 'invalid', error: reason, date: value })
      }
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      slotProps={{
        popper: { className: classes.root },
        openPickerButton: { tabIndex: -1 },
        textField: {
          autoFocus,
          onKeyDown,
          error: disableKeyboardInput ? false : hasError,
          className: clsx(classes.input, className),
          // Only show helper text for a valid date that has an error reason (e.g.
          // before min date, after max date, selected disabled date)
          helperText: showErrorMessage ? errorText : undefined,
          FormHelperTextProps: {
            className: classes.helperText,
          },
          onClick: disableKeyboardInput ? () => setOpen(true) : undefined,
          inputProps: disableKeyboardInput ? { readOnly: true } : undefined,
        },
      }}
      {...props}
    />
  )
}
