import { Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decimalToPercent, percentToDecimal } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { NumericFormatWithForwardRef } from '../../../../common/components/NumberFormat'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& input': {
      width: '100px',
      height: theme.spacing(5),
      margin: theme.spacing(0, 2, 0, 0),
      padding: theme.spacing(0, 1),
      ...theme.typography.body1,
      '&.dollarValue': {
        width: '150px',
      },
    },
  },
}))

interface RetentionPercentInputProps {
  percent: number | undefined
  onPercentChange: (percent: number) => void
  onInputChange?: (percent?: number) => void
  onBlur?: () => void
  amount?: number
  maxAllowedPercent: number
  disableInput?: boolean
  className?: string
}

const i18nBase = 'projects.subcontractors.pay_app.invoice.retention'

/** An input for editing a retention percent, with a tooltip for invalid amounts */
export function RetentionPercentInput({
  percent,
  onPercentChange,
  onInputChange,
  onBlur,
  maxAllowedPercent,
  disableInput,
  className,
}: RetentionPercentInputProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipText, setTooltipText] = useState('')
  const [percentShown, setPercentShown] = useState<number | undefined>(percent)

  // If the percent provided changes, update the percent shown
  useEffect(() => {
    setPercentShown(percent)
  }, [percent])

  const handleChange = (floatValue: number | undefined) => {
    setTooltipOpen(false)
    const decimalPercent = floatValue !== undefined ? percentToDecimal(floatValue) : undefined
    setPercentShown(decimalPercent)
    if (onInputChange && (percent === undefined || floatValue !== decimalToPercent(percent, 2))) {
      onInputChange(decimalPercent)
    }
  }

  const handleBlur = () => {
    setTooltipOpen(false)
    if (percentShown === undefined) {
      setPercentShown(percent)
    } else {
      onPercentChange(percentShown)
    }
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <div className={classes.root}>
      <Tooltip
        open={tooltipOpen}
        placement="top"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={tooltipText}
      >
        <NumericFormatWithForwardRef
          className={className}
          disabled={disableInput}
          value={percentShown !== undefined ? decimalToPercent(percentShown, 2) : ''}
          onValueChange={({ floatValue }) => handleChange(floatValue)}
          decimalScale={2}
          displayType="input"
          thousandSeparator={true}
          suffix="%"
          onBlur={handleBlur}
          allowNegative={false}
          isAllowed={(values) => {
            const { floatValue } = values
            // Undefined is ok since that's an empty input which defaults to 0
            if (_.isUndefined(floatValue)) {
              return true
            }
            const belowMax = floatValue <= decimalToPercent(maxAllowedPercent, 2)
            if (!belowMax) {
              setTooltipOpen(true)
              setTooltipText(
                t(`${i18nBase}.tooltip`, {
                  num: decimalToPercent(maxAllowedPercent, 2),
                })
              )
              return false
            }
            return true
          }}
        />
      </Tooltip>
    </div>
  )
}
