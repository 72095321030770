import _ from 'lodash'
import { useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { Document, Page as PdfPage } from '../../pdf'

const useStyles = makeStylesFast(() => ({
  pdfViewer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

interface RemotePdfViewerProps {
  file: string | Blob
  onPdfLoaded?: () => void
  height?: number
  width?: number
}

/**
 * PdfViewer that loads remote files (eg. files with a https://... path). It displays all pages
 * at once.
 */
export function RemotePdfViewer({ file, onPdfLoaded, height, width }: RemotePdfViewerProps) {
  const classes = useStyles()
  const [numPdfPages, setNumPdfPages] = useState(0)

  return (
    <Document
      file={file}
      // It's possible that this success callback is triggered with a null value
      onLoadSuccess={(document: { numPages: number } | null) => {
        if (document !== null) {
          setNumPdfPages(document.numPages)
        }
        if (onPdfLoaded) {
          onPdfLoaded()
        }
      }}
      loading=""
      className={classes.pdfViewer}
    >
      {_.range(numPdfPages).map((pageNumber) => (
        <PdfPage
          key={pageNumber}
          pageNumber={pageNumber + 1}
          renderAnnotationLayer={false}
          height={height}
          width={width}
        />
      ))}
    </Document>
  )
}
