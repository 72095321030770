import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { TFunction } from 'i18next'
import { SitelineText } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeContentCell,
} from '../components/Spreadsheet/Spreadsheet.lib'

/** A row in the edit worksheet spreadsheet for creating a new line item */
export function getAddLineItemRow({
  onAddLineItem,
  numColumns,
  t,
}: {
  onAddLineItem: () => void
  numColumns: number
  t: TFunction
}): SpreadsheetFooterRow {
  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'add-worksheet-item',
    cells: [
      makeContentCell(
        <div className="addLineItemOrGroup">
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={onAddLineItem}
            className="addLineItem"
          >
            <SitelineText
              variant="body2"
              bold
              color="grey70"
              startIcon={<AddIcon style={{ fontSize: 18 }} />}
            >
              {t('projects.subcontractors.worksheet.add_worksheet_item')}
            </SitelineText>
          </Button>
        </div>,
        [onAddLineItem],
        { colSpan: numColumns }
      ),
    ],
    isNonEditableRow: true,
    isFixed: false,
  }
}

/** Empty state row when there are no progresses due to a search or filter. */
export function getEmptyInvoiceRow({
  numColumns,
  emptyStateMessage,
  onClick,
  buttonLabel,
}: {
  numColumns: number
  emptyStateMessage: string
  /** CTA label linked to the onClick */
  buttonLabel: string
  /**
   * Callback that should handle getting out of this uninteractive state (either clear search or leave).
   * Pass null if the button should be hidden.
   **/
  onClick: (() => void) | null
}): SpreadsheetRow {
  return {
    type: SpreadsheetRowType.DEFAULT,
    id: uuidv4(),
    cells: [
      makeContentCell(
        <div className="clear">
          <SitelineText variant="body2" className="message">
            {emptyStateMessage}
          </SitelineText>
          {onClick !== null && (
            <Button variant="outlined" color="secondary" onClick={onClick}>
              {buttonLabel}
            </Button>
          )}
        </div>,
        [],
        { colSpan: numColumns }
      ),
    ],
    backgroundColor: 'white',
  }
}
