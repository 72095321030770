import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { TFunction } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationType, integrationTypes } from 'siteline-common-all'
import { colors, useSitelineSnackbar } from 'siteline-common-web'
import {
  ButtonVariant,
  DropdownButton,
  DropdownButtonAction,
} from '../../../common/components/DropdownButton'
import {
  MinimalIntegrationProperties,
  SovChangeSetProperties,
  useSovChangeSetFromIntegrationLazyQuery,
} from '../../../common/graphql/apollo-operations'
import { MINIMUM_SUPPORTED_SCREEN_WIDTH } from '../../../common/themes/Main'
import { PreviewSovChangeSetDialog } from '../home/PreviewSovChangeSetDialog'
import { SyncConfirmationDialog } from './SyncConfirmationDialog'
import { SyncFoundationDialog } from './SyncFoundationDialog'
import { SyncPayAppLineItemsDialog } from './SyncPayAppLineItemsDialog'
import { SyncSage100CDialog } from './SyncSage100CDialog'
import { WriteSyncDialog } from './WriteSyncDialog'

const iconStyle = { color: colors.grey50 }

// Some syncs (sage 100, pay app line items) need more information to construct a full payload,
// so we store the minimum amount of information to determine the next screen
export type PartialWriteSyncPayload =
  | Pick<integrationTypes.WriteSyncPayloadPayApp, 'type' | 'payAppId'>
  | Pick<integrationTypes.WriteSyncPayloadPayAppLineItems, 'type' | 'payAppId'>
  | integrationTypes.WriteSyncPayloadLegalRequirement
  | integrationTypes.WriteSyncPayloadLienWaivers

export interface WriteSyncButtonInput {
  payload: PartialWriteSyncPayload
  integration: MinimalIntegrationProperties
  variant: ButtonVariant
  label?: string
  names?: string[]
  disabled?: boolean
  tooltipTitle?: string
}

export interface ReadSyncButtonInput {
  integration: MinimalIntegrationProperties
  numExistingLineItems: number
  variant: ButtonVariant
  label?: string
  onlyChangeOrders: boolean
}

function labelForSyncType(
  syncType: integrationTypes.WriteSyncPayload['type'],
  integration: MinimalIntegrationProperties,
  t: TFunction
) {
  const integrationName = integration.shortName
  switch (syncType) {
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppSage100':
    case 'payAppFoundation':
    case 'payAppQuickbooks':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppComputerEase':
    case 'payAppManual':
    case 'payAppLineItemsSage300':
    case 'payAppLineItemsSpectrum':
    case 'payAppLineItemsVista':
    case 'payAppLineItemsAcumatica':
    case 'payAppLineItemsSageIntacct':
      return t('integrations.button.sync_invoice', { integrationName })
    case 'legalRequirement':
      return t('integrations.button.sync_to', { integrationName })
    case 'lienWaivers':
      return t('integrations.button.sync_lien', { integrationName })
  }
}

export const EMPTY_CHANGE_SET: SovChangeSetProperties = {
  __typename: 'SovChangeSet',
  additions: [],
  updates: [],
  deletions: [],
  groupAdditions: [],
  groupUpdates: [],
  groupDeletions: [],
}

const i18nBase = 'projects.subcontractors.pay_app.invoice'

type SyncButtonProps = {
  writeSyncInputs: WriteSyncButtonInput[]
  readSyncInputs: ReadSyncButtonInput[]
  dropdownLabel?: string
  shouldShrinkOnSmallScreen?: boolean
  smallScreenDropdownLabel?: ReactNode
  projectId: string
  payAppId?: string
  onStartSync?: () => void
  onClose?: (syncSuccess: boolean) => void
  closeOnError?: boolean
  className?: string
  additionalActions?: DropdownButtonAction[]
}

export function SyncButton({
  writeSyncInputs,
  readSyncInputs,
  dropdownLabel,
  projectId,
  payAppId,
  onStartSync,
  onClose,
  closeOnError = false,
  className,
  shouldShrinkOnSmallScreen = false,
  smallScreenDropdownLabel,
}: SyncButtonProps) {
  const { t } = useTranslation()

  const [getSovChangeSetFromIntegration, { data: changeSetData, loading: loadingChangeSet }] =
    useSovChangeSetFromIntegrationLazyQuery({
      fetchPolicy: 'cache-and-network',
    })

  const [syncDialogOpen, setSyncDialogOpen] = useState<boolean>(false)
  const [syncSage100CDialogOpen, setSyncSage100CDialogOpen] = useState<boolean>(false)
  const [syncFoundationDialogOpen, setSyncFoundationDialogOpen] = useState<boolean>(false)
  const [syncPayAppLineItemDialogOpen, setSyncPayAppLineItemDialogOpen] = useState<boolean>(false)

  const [syncConfirmationDialogOpen, setSyncConfirmationDialogOpen] = useState<boolean>(false)
  const [syncingWriteInput, setSyncingWriteInput] = useState<WriteSyncButtonInput | null>(null)
  const [syncingReadInput, setSyncingReadInput] = useState<ReadSyncButtonInput | null>(null)
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false)
  const snackbar = useSitelineSnackbar()

  const startSync = useCallback(() => {
    setSyncDialogOpen(true)
  }, [])

  const handleWriteClick = useCallback(
    (input: WriteSyncButtonInput) => {
      if (onStartSync) {
        onStartSync()
      }
      setSyncingWriteInput(input)

      switch (input.payload.type) {
        case 'payAppTextura':
        case 'payAppGcPay':
        case 'payAppProcore':
        case 'payAppManual':
        case 'legalRequirement':
          startSync()
          break
        case 'payAppSage100':
          setSyncSage100CDialogOpen(true)
          break
        case 'payAppFoundation':
          setSyncFoundationDialogOpen(true)
          break
        case 'payAppLineItemsSage300':
        case 'payAppLineItemsSpectrum':
        case 'payAppLineItemsVista':
        case 'payAppLineItemsAcumatica':
        case 'payAppLineItemsSageIntacct':
          setSyncPayAppLineItemDialogOpen(true)
          break
        case 'lienWaivers':
          setSyncConfirmationDialogOpen(true)
          break
        case 'payAppQuickbooks':
        case 'payAppFoundationFileGenie':
        case 'payAppFoundationFileFsi':
        case 'payAppComputerEase':
          throw new Error('File syncs are not supported')
      }
    },
    [onStartSync, startSync]
  )

  const handleReadClick = async (input: ReadSyncButtonInput) => {
    const translationKey = input.onlyChangeOrders
      ? (`${i18nBase}.add_change_order.sync` as const)
      : (`${i18nBase}.import_sov` as const)
    snackbar.showLoading(
      t(`${translationKey}.loading`, {
        integrationName: input.integration.shortName,
      })
    )
    try {
      const { data, error } = await getSovChangeSetFromIntegration({
        variables: {
          input: {
            integrationId: input.integration.id,
            onlyChangeOrders: input.onlyChangeOrders,
          },
        },
      })

      // If call succeeds, changeset is either null (no changes), or not null (changes exist)
      if (data) {
        if (data.sovChangeSetFromIntegration) {
          snackbar.closeAll()
          setSyncingReadInput(input)
          setPreviewDialogOpen(true)
        } else {
          const noChangesMessage = t(`${translationKey}.success_no_changes`, {
            integrationName: input.integration.shortName,
          })
          snackbar.showSuccess(noChangesMessage)
        }

        // If there's an error from the API, show it
      } else if (error) {
        snackbar.showError(error.message)

        // This should not happen but we handle it just in case
      } else {
        snackbar.showError(t('common.errors.snackbar.generic'))
      }
    } catch {
      snackbar.showError(t('common.errors.snackbar.generic'))
    }
  }

  const onDialogClose = (syncSuccess: boolean) => {
    if (onClose) {
      onClose(syncSuccess)
    }
    setSyncDialogOpen(false)
    setSyncingWriteInput(null)
  }

  const writeSyncActions = writeSyncInputs.map((input) => ({
    label: input.label ?? labelForSyncType(input.payload.type, input.integration, t),
    onClick: () => handleWriteClick(input),
    variant: input.variant,
    disabled: input.disabled,
    tooltipTitle: input.tooltipTitle,
    smallScreenLabel: shouldShrinkOnSmallScreen ? <SyncIcon style={iconStyle} /> : undefined,
  }))

  const readSyncActions = readSyncInputs.map((input) => {
    const translationKey = input.onlyChangeOrders
      ? (`${i18nBase}.add_change_order.sync.import_change_orders_from` as const)
      : (`${i18nBase}.import_sov.import_from` as const)
    return {
      label:
        input.label ??
        t(translationKey, {
          integrationName: input.integration.shortName,
        }),
      onClick: () => handleReadClick(input),
      variant: input.variant,
      smallScreenLabel: shouldShrinkOnSmallScreen ? (
        <ImportExportIcon style={iconStyle} />
      ) : undefined,
    }
  })

  const actions = [...readSyncActions, ...writeSyncActions]

  const changeSet = useMemo(
    () => changeSetData?.sovChangeSetFromIntegration ?? EMPTY_CHANGE_SET,
    [changeSetData?.sovChangeSetFromIntegration]
  )

  const smallScreenProps = useMemo(
    () =>
      shouldShrinkOnSmallScreen
        ? {
            minScreenSize: MINIMUM_SUPPORTED_SCREEN_WIDTH,
            dropdownLabel: smallScreenDropdownLabel ?? <SyncIcon style={iconStyle} />,
          }
        : undefined,
    [shouldShrinkOnSmallScreen, smallScreenDropdownLabel]
  )

  const syncingSage100WriteIntegrationType =
    syncingWriteInput &&
    (syncingWriteInput.integration.type === IntegrationType.SAGE_100_CONTRACTOR ||
      syncingWriteInput.integration.type === IntegrationType.SAGE_100_CONTRACTOR_AGAVE)
      ? syncingWriteInput.integration.type
      : null

  return (
    <>
      <DropdownButton
        actions={actions}
        className={className}
        dropdownLabel={dropdownLabel ?? t('integrations.button.sync')}
        allowPrimaryDropdown
        minDropdownActions={2}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
        }}
        smallScreenProps={smallScreenProps}
      />
      {syncingWriteInput && (
        <>
          <SyncConfirmationDialog
            open={syncConfirmationDialogOpen}
            onClose={() => {
              setSyncConfirmationDialogOpen(false)
              setSyncingWriteInput(null)
            }}
            integration={syncingWriteInput.integration}
            payload={syncingWriteInput.payload}
            names={syncingWriteInput.names ?? []}
            onClick={() => {
              setSyncConfirmationDialogOpen(false)
              startSync()
            }}
          />
          <WriteSyncDialog
            open={syncDialogOpen}
            onClose={onDialogClose}
            integration={syncingWriteInput.integration}
            payload={syncingWriteInput.payload as integrationTypes.WriteSyncPayload}
            projectId={projectId}
            closeOnError={closeOnError}
          />
          {payAppId && syncingSage100WriteIntegrationType && (
            <SyncSage100CDialog
              open={syncSage100CDialogOpen}
              onClose={() => {
                setSyncSage100CDialogOpen(false)
                setSyncingWriteInput(null)
              }}
              integration={syncingWriteInput.integration}
              integrationType={syncingSage100WriteIntegrationType}
              payAppId={payAppId}
            />
          )}
          {payAppId && (
            <SyncFoundationDialog
              open={syncFoundationDialogOpen}
              onClose={() => {
                setSyncFoundationDialogOpen(false)
                setSyncingWriteInput(null)
              }}
              integration={syncingWriteInput.integration}
              payAppId={payAppId}
            />
          )}
          {payAppId && (
            <SyncPayAppLineItemsDialog
              open={syncPayAppLineItemDialogOpen}
              onClose={() => {
                setSyncPayAppLineItemDialogOpen(false)
                setSyncingWriteInput(null)
              }}
              integration={syncingWriteInput.integration}
              payAppId={payAppId}
            />
          )}
        </>
      )}
      {syncingReadInput && (
        <PreviewSovChangeSetDialog
          open={previewDialogOpen}
          onClose={() => {
            setPreviewDialogOpen(false)
            setSyncingWriteInput(null)
          }}
          integration={syncingReadInput.integration}
          onlyChangeOrders={syncingReadInput.onlyChangeOrders}
          changeSet={changeSet}
          loading={loadingChangeSet}
        />
      )}
    </>
  )
}
