import { RetentionTrackingLevel, SovLineItemProgressProperties } from '../graphql/apollo-operations'

/**
 * The total retention to date for the given progress
 * @param progress Progress for which to calculate the retention
 */
export function retentionToDate(progress: SovLineItemProgressProperties) {
  return progress.previousRetention + progress.currentRetention
}

export function usesStandardOrLineItemTracking(level: RetentionTrackingLevel): boolean {
  return level === RetentionTrackingLevel.STANDARD || level === RetentionTrackingLevel.LINE_ITEM
}

export function usesPayAppOrProjectTracking(level: RetentionTrackingLevel): boolean {
  return level === RetentionTrackingLevel.PAY_APP || level === RetentionTrackingLevel.PROJECT
}
