import { Autocomplete } from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { areNormalizedStringsEqual } from 'siteline-common-all'
import { SitelineText, colors, fuseSearch, makeStylesFast } from 'siteline-common-web'
import { locationInputFromLocation } from '../../../common/components/LocationAutocomplete'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { formatLocationManyLines } from '../../../common/util/Location'
import { ProjectOnboardingCompanyInfo } from '../../../common/util/ProjectOnboarding'
import { OnboardingFormTextField } from './OnboardingFormTextField'
import { CompanyForProjectOnboarding } from './ProjectInfoForm'

const useStyles = makeStylesFast(() => ({
  autocomplete: {
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
}))

type MinimalCompany = Pick<CompanyForProjectOnboarding, 'id' | 'name' | 'locations'>

interface ProjectCompanyAutocompleteProps {
  companies: CompanyForProjectOnboarding[]
  company: ProjectOnboardingCompanyInfo | null
  onCompanyChange: (company: ProjectOnboardingCompanyInfo) => void
  error?: boolean
}

const i18nBase = 'projects.subcontractors.settings.project_info'

/** Autocomplete for selecting an existing company or adding a new one */
export function ProjectCompanyAutocomplete({
  companies,
  company: initialCompany,
  onCompanyChange,
  error,
}: ProjectCompanyAutocompleteProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()
  const [company, setCompany] = useState<ProjectOnboardingCompanyInfo | null>(initialCompany)
  const [existingCompany, setExistingCompany] = useState<MinimalCompany | null>(null)

  // Update the company when the prop changes
  useEffect(() => {
    setCompany(initialCompany)
  }, [initialCompany])

  const handleCompanyChange = useCallback(
    (company: MinimalCompany) => {
      onCompanyChange({
        id: company.id,
        name: company.name,
        locationId: company.locations.length ? company.locations[0].id : null,
        address: company.locations.length ? locationInputFromLocation(company.locations[0]) : null,
      })
    },
    [onCompanyChange]
  )

  const confirmCompanyChange = useMemo(
    () =>
      _.debounce((newCompany: MinimalCompany) => {
        const isNewCompany = !companies.some((company) => company.id === newCompany.id)
        if (isNewCompany) {
          // If the new company name is close to matching an existing company, confirm
          // that they are the same company
          const matches = fuseSearch(companies, newCompany.name, ['name'])
          if (matches.length > 0) {
            confirm({
              title: t(`${i18nBase}.same_company`),
              confirmLabel: t('common.yes'),
              cancelLabel: t('common.no'),
              details: (
                <div>
                  <SitelineText variant="h4">{matches[0].name}</SitelineText>
                  {matches[0].locations.length > 0 && (
                    <SitelineText variant="body1">
                      {formatLocationManyLines(matches[0].locations[0])}
                    </SitelineText>
                  )}
                </div>
              ),
              callback: (confirmed) => {
                if (confirmed) {
                  handleCompanyChange(matches[0])
                } else {
                  handleCompanyChange(newCompany)
                }
              },
            })
            return
          }
        }
        handleCompanyChange(newCompany)
      }),
    [handleCompanyChange, companies, confirm, t]
  )

  const options = useMemo(() => {
    const companyOptions = companies.map(({ id, name, locations }) => ({ id, name, locations }))
    if (company?.name && !companies.some((existingCompany) => existingCompany.id === company.id)) {
      companyOptions.push({
        id: '',
        name: company.name,
        locations: [],
      })
    }
    return companyOptions
  }, [companies, company])

  return (
    <Autocomplete
      freeSolo={false}
      options={options}
      autoHighlight
      inputValue={company?.name ?? ''}
      getOptionLabel={(company) => company.name}
      onInputChange={(ev, value, reason) => {
        if (reason === 'reset') {
          // Ignore this, since it can cause the autocomplete to reset for no reason when
          // an initial company name is provided
          return
        }
        const matchingCompany = companies.find((company) =>
          areNormalizedStringsEqual(company.name, value)
        )
        if (matchingCompany) {
          setExistingCompany(matchingCompany)
          confirmCompanyChange(matchingCompany)
        } else {
          let address = company?.address ?? null
          if (company?.locationId) {
            // If there was previously an existing company address, remove it since
            // the input no longer matches that company
            address = null
          }
          setCompany({
            id: null,
            name: value,
            locationId: null,
            address,
          })
        }
      }}
      onBlur={() => {
        if (areNormalizedStringsEqual(existingCompany?.name ?? '', company?.name ?? '')) {
          // Don't do anything in this case, it's already been chosen
          return
        } else if (company?.name) {
          confirmCompanyChange({
            id: '',
            name: company.name,
            locations: [],
          })
        } else {
          // If no new name, call the resulting function directly with empty company info
          onCompanyChange({
            id: null,
            name: null,
            locationId: null,
            address: null,
          })
        }
      }}
      onChange={(ev, option) => {
        if (!option) {
          return
        }
        setExistingCompany(option)
        confirmCompanyChange(option)
      }}
      className={classes.autocomplete}
      renderInput={(params) => <OnboardingFormTextField {...params} error={error} />}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.id
            ? option.name
            : t('projects.onboarding.use_company', { companyName: option.name })}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={t('projects.onboarding.no_options')}
    />
  )
}
