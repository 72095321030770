import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { SitelineDialog } from '../../common/components/SitelineDialog'
import { WrongCompanyMetadata } from '../../common/contexts/CompanyContext'
import { CompanyProperties } from '../../common/graphql/apollo-operations'
import { BillingPathType, getBillingPath } from '../../components/billing/Billing.lib'
import { ReportingTab, getReportingPath } from '../../components/reporting/Reporting.lib'
import { getCompliancePath } from '../legal-requirement/LegalRequirement.lib'
import { getVendorsPath } from '../vendors/Vendors.lib'

const useStyles = makeStylesFast((theme: Theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(1),
  },
}))

export interface SwitchCompanyDialogProps {
  open: boolean
  onClose: () => void
  currentCompany: Pick<CompanyProperties, 'id' | 'nickname' | 'name'> | null
  viewingCompany: Pick<CompanyProperties, 'id' | 'nickname' | 'name'> | null
  onSwitchCompanyId: (companyId: string | null) => void
  pageType: WrongCompanyMetadata['pageType']
}

const i18nBase = 'settings.company.switch_company_dialog'

/**
 * Dialog shown when the current page being viewed is not accessibly by the current company in view.
 * The dialog allows the user to either switch to the appropriate company or navigate somewhere
 * the current company has access.
 */
export function SwitchCompanyDialog({
  open,
  onClose,
  currentCompany,
  viewingCompany,
  onSwitchCompanyId,
  pageType,
}: SwitchCompanyDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const wrongCompanyName = currentCompany?.nickname ?? currentCompany?.name ?? ''
  const accessCompanyName = viewingCompany?.nickname ?? viewingCompany?.name ?? ''

  const { dialogTitle, switchLabel, exitLabel, exitPath } = useMemo(() => {
    const dialogTitle = currentCompany
      ? t(`${i18nBase}.cannot_view_${pageType}`)
      : t(`${i18nBase}.switch_to_view_${pageType}`)
    const switchLabel = viewingCompany
      ? t(`${i18nBase}.switch_to_company`, { accessCompanyName })
      : t(`${i18nBase}.switch_companies`)

    let exitLabel = t(`${i18nBase}.go_to_reporting`)
    let exitPath = getReportingPath({ tab: ReportingTab.OVERVIEW })
    if (currentCompany) {
      const isComplianceTab = location.pathname.match(/compliance/) !== null
      const isVendorsTab = location.pathname.match(/vendors/) !== null
      if (isComplianceTab) {
        exitPath = getCompliancePath()
        exitLabel = t(`${i18nBase}.go_to_compliance`)
      } else if (isVendorsTab) {
        exitPath = getVendorsPath()
        exitLabel = t(`${i18nBase}.go_to_vendors`)
      } else {
        exitPath = getBillingPath({ pathType: BillingPathType.Home })
        exitLabel = t(`${i18nBase}.go_to_billing`)
      }
    }

    return { dialogTitle, switchLabel, exitLabel, exitPath }
  }, [currentCompany, t, pageType, viewingCompany, accessCompanyName])

  const [dialogSubtitle, subtitleValues] = useMemo(() => {
    const subtitle = currentCompany
      ? (`${i18nBase}.company_no_access_${pageType}` as const)
      : (`${i18nBase}.all_companies_no_access_${pageType}` as const)
    return [
      subtitle,
      {
        wrongCompanyName,
        accessCompanyName,
      },
    ]
  }, [accessCompanyName, currentCompany, pageType, wrongCompanyName])

  return (
    <SitelineDialog
      disableBackdrop
      disableEscapeKey
      maxWidth="sm"
      actionsLayout="hideActions"
      open={open}
      title={dialogTitle}
      subtitle={
        <SitelineText variant="body1" color="grey50" className="subtitle">
          <Trans
            i18nKey={dialogSubtitle}
            values={subtitleValues}
            components={{ bold: <strong /> }}
          />
        </SitelineText>
      }
      subtitleVariant="body1"
    >
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            onSwitchCompanyId(viewingCompany?.id ?? null)
            onClose()
          }}
        >
          {switchLabel}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            navigate(exitPath)
            onClose()
          }}
        >
          {exitLabel}
        </Button>
      </div>
    </SitelineDialog>
  )
}
