import { Theme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { SitelineText, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
      '&.MuiCollapse-hidden': {
        marginBottom: theme.spacing(0),
      },
    },
    '& .noTopMargin': {
      marginTop: theme.spacing(-1),
    },
    '& .headerRow': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
  },
}))

/** A card containing a section of the project onboarding form */
export function ProjectOnboardingFormCard({
  title,
  button,
  children,
}: {
  title: ReactNode
  button?: ReactNode
  children: ReactNode
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className="headerRow">
        {typeof title === 'string' ? (
          <SitelineText variant="h3" bold>
            {title}
          </SitelineText>
        ) : (
          title
        )}
        {button}
      </div>
      {children}
    </div>
  )
}
