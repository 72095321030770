import { FormControlLabel } from '@mui/material'
import { Theme } from '@mui/system'
import _ from 'lodash'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationTypeFamily, supportsWriteSync } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { IntegrationType, Permission } from '../../../common/graphql/apollo-operations'
import { getIntegrationOfFamily } from '../../../common/util/Integration'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .permission': {
      width: '25%',
      marginRight: 0,
      [theme.breakpoints.down('lg')]: {
        width: '33%',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  },
}))

interface PermissionChecklistProps {
  selectedPermissions: Permission[]
  onTogglePermissionSelected: (permission: Permission, selected: boolean) => void
}

export const orderedPermissions: Permission[] = [
  Permission.EDIT_INVOICE,
  Permission.EDIT_BACKUP,
  Permission.MANAGE_VENDORS,
  Permission.EDIT_PROJECT_SETTINGS,
  Permission.DELETE_PROJECT,
  Permission.EDIT_CHANGE_ORDER,
  Permission.EDIT_LEGAL_REQUIREMENT,
  Permission.SIGNER,
  Permission.SUBMIT,
  Permission.SUBMIT_DRAFT,
  Permission.CREATE_QUICK_BILL_PROJECT,
  Permission.EDIT_COMPANY_USERS,
  Permission.ERP_SYNC,
]

export function shouldShowErpWritePermission(
  integrations: readonly { type: IntegrationType; archivedAt: string | null }[]
) {
  const active = integrations.filter((integration) => integration.archivedAt === null)
  const erpIntegration = getIntegrationOfFamily({ integrations: active }, IntegrationTypeFamily.ERP)
  const supportsPayApp = erpIntegration && supportsWriteSync(erpIntegration.type, 'payApp')
  const supportsPayAppLineItems =
    erpIntegration && supportsWriteSync(erpIntegration.type, 'payAppLineItems')
  const writeSupported = supportsPayApp || supportsPayAppLineItems

  return erpIntegration && writeSupported
}

/** A list of permissions with checkboxes */
export const PermissionChecklist = memo(function PermissionChecklist({
  selectedPermissions,
  onTogglePermissionSelected,
}: PermissionChecklistProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { company } = useCompanyContext()

  const sortedPermissions = useMemo(
    () =>
      _.chain(_.values(Permission))
        .orderBy((permission) => orderedPermissions.indexOf(permission), 'asc')
        // Filter out the ERP permission if the company doesn't have one set up
        .filter((permission) => {
          const integrations = company?.companyIntegrations ?? []
          if (shouldShowErpWritePermission(integrations)) {
            return true
          } else {
            return permission !== Permission.ERP_SYNC
          }
        })
        .value(),
    [company?.companyIntegrations]
  )

  return (
    <div className={classes.root}>
      {sortedPermissions.map((permission) => (
        <FormControlLabel
          key={permission}
          control={
            <SitelineCheckbox
              name={permission}
              value={selectedPermissions.includes(permission)}
              checked={selectedPermissions.includes(permission)}
              onChange={(ev, checked) => {
                onTogglePermissionSelected(permission, checked)
              }}
            />
          }
          label={t(`settings.permissions.description.${permission}`)}
          className="permission"
        />
      ))}
    </div>
  )
})
