import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../../../common/components/Loader'
import { RemotePdfViewer } from '../../../../common/components/RemotePdfViewer'
import { StoredFileProperties, StoredFileType } from '../../../../common/graphql/apollo-operations'
import { getAttachmentPdf } from '../../../../common/util/Attachment'
import { AttachmentThumbnail } from './AttachmentThumbnail'

const THUMBNAIL_CONTAINER_WIDTH = 225
const DIALOG_MIN_HEIGHT = 525
const MAX_PDF_SIZE = 1000

const useStyles = makeStylesFast((theme: Theme) => ({
  close: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
  },
  dialogContent: {
    padding: 0,
    minHeight: DIALOG_MIN_HEIGHT,
    backgroundColor: colors.grey20,
    display: 'flex',
  },
  thumbnailContainer: {
    width: THUMBNAIL_CONTAINER_WIDTH,
    padding: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
    },
    overflow: 'auto',
  },
  attachmentMetadata: {
    backgroundColor: colors.white,
    bottom: 0,
    padding: theme.spacing(2, 3),
    position: 'absolute',
    left: THUMBNAIL_CONTAINER_WIDTH,
    right: 0,
    '& .MuiTypography-body1': {
      paddingTop: theme.spacing(0.5),
    },
  },
  attachmentContainer: {
    textAlign: 'center',
    minHeight: DIALOG_MIN_HEIGHT,
    overflow: 'auto',
    width: `calc(100% - ${THUMBNAIL_CONTAINER_WIDTH}px)`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(6.5),
    background: '#d1d1d1', // Matches Google Drive Preview background
    '& > .image': {
      padding: theme.spacing(3),
      maxHeight: '99%',
      maxWidth: '99%',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& > .document': {
      width: '90%',
    },
  },
  attachmentThumb: {
    border: '2px solid',
    borderColor: colors.grey50,
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    margin: theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
  },
  selectedThumb: {
    borderColor: colors.green40,
  },
}))

export type AttachmentForViewer = {
  name: string
  description: string | null
  file: StoredFileProperties
}

type AttachmentViewerDialogProps = {
  open: boolean
  onClose: () => void
  attachments: AttachmentForViewer[]
  title?: string
}

type AttachmentSource = { type: 'image'; url: string } | { type: 'pdf'; file: string | Blob }

export function AttachmentViewerDialog({
  open,
  onClose,
  attachments,
  title,
}: AttachmentViewerDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedAttachment, setSelectedAttachment] = useState<AttachmentForViewer | null>(null)
  const [attachmentSource, setAttachmentSource] = useState<AttachmentSource | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [pdfWidth, setPdfWidth] = useState(MAX_PDF_SIZE)
  const attachmentThumbnailsRef = createRef<HTMLDivElement>()

  // When opening the dialog for the first time, select the first attachment
  useEffect(() => {
    if (!open || attachments.length === 0) {
      return
    }
    setSelectedAttachment(attachments[0])
  }, [open, attachments])

  // Whenever the selected attachment changes, load its pages using the attachments service
  useEffect(() => {
    if (!selectedAttachment) {
      return
    }

    // If file is an image, load its url directly
    if (
      selectedAttachment.file.type === StoredFileType.JPEG ||
      selectedAttachment.file.type === StoredFileType.PNG
    ) {
      setAttachmentSource({ type: 'image', url: selectedAttachment.file.url })
      return
    }

    // If file type is PDF, load PDF through native PDF viewer
    if (selectedAttachment.file.type === StoredFileType.PDF) {
      setIsLoading(true)
      setAttachmentSource({ type: 'pdf', file: selectedAttachment.file.url })
      return
    }

    setIsLoading(true)
    getAttachmentPdf({ storedFileId: selectedAttachment.file.id })
      .then((file) => {
        setAttachmentSource({ type: 'pdf', file })
      })
      .catch((err) => {
        console.error(`Could not load attachment PDF: ${err}`)
      })
  }, [selectedAttachment])

  // Because the PDFViewer requires specific widths, this effect looks at the window size any time
  // it changes and calculates the new width for the PDFViewer
  useEffect(() => {
    function handleResize() {
      // Account for thumbnails and 48px * 2 for padding
      const newWidth = window.innerWidth - THUMBNAIL_CONTAINER_WIDTH - 96
      setPdfWidth(Math.min(newWidth, MAX_PDF_SIZE))
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!selectedAttachment) {
    return <div />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="attachment-viewer-dialog-title"
    >
      <DialogTitle id="attachment-viewer-dialog-title">
        <SitelineText variant="h1" bold>
          {title ?? t('projects.subcontractors.pay_app.attachments.all')}
        </SitelineText>
        <IconButton
          color="secondary"
          onClick={onClose}
          className={classes.close}
          aria-label={t('common.actions.close')}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div ref={attachmentThumbnailsRef} className={classes.thumbnailContainer}>
          <div style={{ height: attachments.length * 150 }}>
            {attachments.map((attachment) => (
              <div
                key={attachment.file.id}
                id={attachment.file.id}
                onClick={() => setSelectedAttachment(attachment)}
                className={clsx(classes.attachmentThumb, {
                  [classes.selectedThumb]: selectedAttachment.file.id === attachment.file.id,
                })}
              >
                <AttachmentThumbnail attachment={attachment} />
              </div>
            ))}
          </div>
        </div>
        <div
          className={clsx(classes.attachmentContainer, {
            image: attachmentSource?.type === 'image',
          })}
        >
          {attachmentSource?.type === 'image' && (
            <img className="image" src={attachmentSource.url} alt={selectedAttachment.name} />
          )}
          {attachmentSource?.type === 'pdf' && (
            <RemotePdfViewer
              file={attachmentSource.file}
              onPdfLoaded={() => setIsLoading(false)}
              width={pdfWidth}
            />
          )}
          {isLoading && <Loader />}
        </div>
        <div className={classes.attachmentMetadata}>
          <SitelineText variant="label">{selectedAttachment.name}</SitelineText>
          {selectedAttachment.description && (
            <SitelineText variant="body1">{selectedAttachment.description}</SitelineText>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
