import ScheduleIcon from '@mui/icons-material/Schedule'
import { IconButton } from '@mui/material'
import { clsx } from 'clsx'
import { memo, useCallback } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import { VISIBLE_ON_ROW_HOVER_CLASS } from '../../../common/components/SitelineTable'
import {
  SPREADSHEET_BORDER_PADDING,
  SPREADSHEET_MIN_ROW_HEIGHT,
} from '../../../common/components/Spreadsheet/Spreadsheet'

const useStyles = makeStylesFast(() => ({
  root: {
    '& > button': {
      height: SPREADSHEET_MIN_ROW_HEIGHT - 2 * SPREADSHEET_BORDER_PADDING,
    },
  },
}))

interface InvoiceHistoryButtonProps {
  isExpanded: boolean
  progressId: string
  onToggleExpanded: (progressId: string) => void
}

/** A button on the invoice that appears on hover to toggle progress history */
export const InvoiceHistoryButton = memo(function InvoiceHistoryButton({
  isExpanded,
  progressId,
  onToggleExpanded,
}: InvoiceHistoryButtonProps) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, { [VISIBLE_ON_ROW_HOVER_CLASS]: !isExpanded })}>
      <IconButton
        color="secondary"
        onClick={useCallback(() => onToggleExpanded(progressId), [onToggleExpanded, progressId])}
      >
        <ScheduleIcon
          fontSize="small"
          style={{ color: isExpanded ? colors.grey70 : colors.grey50 }}
        />
      </IconButton>
    </div>
  )
})
