import { Block, RegisteredRouter, RouterState, useLocation } from '@tanstack/react-router'
import _ from 'lodash'
import { useCallback } from 'react'

type PromptProps = {
  when: boolean
  message: // A message to show in a window.confirm
  | string

    // A function to call with the current location, returning a boolean indicating whether
    // the navigation should continue.
    | ((location: RouterState<RegisteredRouter['routeTree']>['location']) => string | boolean)
}

/**
 * Reimplementation of the <Prompt> component of react-router-dom that was removed in v6.
 * This component blocks navigation when `when = true`, then calls `message` to determine whether
 * to continue the navigation.
 * If `message` is a string, it will show a confirmation dialog.
 * If `message` is a function, it will call it and use its result to determine whether to continue
 * the navigation.
 *
 *
 * **Important**: this does not block back navigation, as per
 * https://tanstack.com/router/latest/docs/framework/react/guide/navigation-blocking#what-about-the-back-button
 */
export function Prompt({ when, message }: PromptProps) {
  const location = useLocation()
  const blocker = useCallback(() => {
    const messageOrConfirmed = _.isFunction(message) ? message(location) : message
    if (_.isBoolean(messageOrConfirmed)) {
      return messageOrConfirmed
    }
    return window.confirm(messageOrConfirmed)
  }, [location, message])
  return <Block blockerFn={blocker} condition={when} />
}
