import { FormControlLabel, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import moment from 'moment-timezone'
import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OPT_OUT_REASON_MAX_CHARS, supportsBillingOptOut } from 'siteline-common-all'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { SitelineCheckbox } from '../../common/components/SitelineCheckbox'
import { SitelineDialog } from '../../common/components/SitelineDialog'
import { ActiveOrArchivedContractForProjectList } from '../project-home/ProjectList'
import { ContractForFullyBilledContracts } from '../project-home/ProjectListSwitcher'
import { BillingPathType, getBillingPath } from './Billing.lib'
import { ContractForProjectHome } from './home/ProjectHome'

const useStyles = makeStylesFast((theme: Theme) => ({
  unconditionalCheckbox: {
    marginTop: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& .reasonHeader': {
      margin: theme.spacing(3, 0, 1),
      display: 'flex',
      '& .optional': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.create_or_opt_out.opt_out_dialog'

interface OptOutOfBillingDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onOptedOut: (optOutOnIntegrationIds: string[], reason: string) => void
  hasPayApp: boolean
  month: moment.Moment
  contract:
    | ContractForProjectHome
    | ActiveOrArchivedContractForProjectList
    | ContractForFullyBilledContracts
  showCreateUnconditional: boolean
  submitting?: boolean
}

/** Dialog for opting out of billing and optionally creating an unconditional lien waiver. */
export function OptOutOfBillingDialog({
  open,
  setOpen,
  onOptedOut,
  hasPayApp,
  month,
  contract,
  showCreateUnconditional,
  submitting = false,
}: OptOutOfBillingDialogProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const [createUnconditional, setCreateUnconditional] = useState<boolean>(false)
  const [optOutOnIntegrationIds, setOptOutOnIntegrationIds] = useState<string[]>([])
  const [reason, setReason] = useState<string>('')

  const handleCancel = useCallback(() => {
    setOpen(false)
    setCreateUnconditional(false)
  }, [setOpen])

  const handleConfirm = useCallback(() => {
    // This is to avoid double-clicks on the submit button while the dialog is closing.
    if (!open) {
      return
    }
    setOpen(false)
    onOptedOut(optOutOnIntegrationIds, reason)
    if (createUnconditional) {
      navigate(
        getBillingPath({
          pathType: BillingPathType.OptedOutMonthUnconditional,
          projectId: contract.project.id,
          month,
        })
      )
    }
  }, [
    contract.project.id,
    createUnconditional,
    month,
    navigate,
    onOptedOut,
    optOutOnIntegrationIds,
    reason,
    setOpen,
    open,
  ])

  return (
    <SitelineDialog
      disableBackdrop
      disableEscapeKey
      onClick={(e: MouseEvent) => e.stopPropagation()}
      maxWidth="sm"
      open={open}
      title={t(`${i18nBase}.title`)}
      onClose={handleCancel}
      onSubmit={handleConfirm}
      submitLabel={t(`${i18nBase}.opt_out`)}
      submitButtonColor={hasPayApp ? 'destructive' : 'primary'}
      submitting={submitting}
    >
      <div className={classes.content}>
        <SitelineText variant="body1" color="grey50">
          {hasPayApp
            ? t(`${i18nBase}.body`)
            : t(`${i18nBase}.body_no_pay_app`, {
                month: month.format('MMMM'),
              })}
        </SitelineText>
        <div className="reasonHeader">
          <SitelineText variant="smallText" bold>
            {t(`${i18nBase}.reason`)}
          </SitelineText>
          <SitelineText variant="smallText" color="grey50" className="optional">
            {t(`${i18nBase}.optional`)}
          </SitelineText>
        </div>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          rows={2}
          value={reason}
          onChange={(event) => {
            if (event.target.value.length > OPT_OUT_REASON_MAX_CHARS) {
              // Stop allowing editing after a certain number of chars
              return
            }
            setReason(event.target.value)
          }}
          autoFocus
        />
        {showCreateUnconditional && (
          <FormControlLabel
            control={
              <SitelineCheckbox
                checked={createUnconditional}
                onChange={(ev, checked) => setCreateUnconditional(checked)}
              />
            }
            label={
              <SitelineText variant="secondary" color="grey50">
                {t(`${i18nBase}.generate_unconditional`)}
              </SitelineText>
            }
            className={classes.unconditionalCheckbox}
          />
        )}
        {contract.integrations.map((integration) => {
          if (!supportsBillingOptOut(integration.type)) {
            return null
          }
          const optedOut = optOutOnIntegrationIds.includes(integration.id)
          return (
            <FormControlLabel
              key={integration.id}
              control={
                <SitelineCheckbox
                  checked={optedOut}
                  onChange={(ev, checked) => {
                    if (checked) {
                      setOptOutOnIntegrationIds([...optOutOnIntegrationIds, integration.id])
                    } else {
                      setOptOutOnIntegrationIds(
                        optOutOnIntegrationIds.filter((id) => id !== integration.id)
                      )
                    }
                  }}
                />
              }
              label={
                <SitelineText variant="secondary" color="grey50">
                  {t(`${i18nBase}.opt_out_on_integration`, {
                    integrationName: integration.shortName,
                  })}
                </SitelineText>
              }
            />
          )
        })}
      </div>
    </SitelineDialog>
  )
}
