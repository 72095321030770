import { useLocation, useNavigate, useSearch } from '@tanstack/react-router'
import moment, { Moment } from 'moment-timezone'
import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react'
import { MIDDLE_OF_MONTH } from './Time'

/**
 * Returns the date corresponding to a URL's search params, or a default date if
 * non are included in the URL.
 */
function dateFromSearchParams(
  search: { year?: number; month?: number; date?: number },
  timeZone: string,
  defaultDate?: moment.Moment
) {
  const today = defaultDate?.clone() ?? moment.tz(timeZone)
  const defaultYear = search.year
  const defaultMonth = search.month

  if (defaultYear && defaultMonth) {
    const day = search.date
    // Subtract 1 from month since they range from 0 to 11 (zero-indexed)
    today
      .year(Number(defaultYear))
      .month(Number(defaultMonth) - 1)
      // Use the middle of the month instead of the start or end so that we avoid any timezone
      // issues. The date shouldn't matter since we are just parsing the URL's month + year combo
      // (unless a day is specifically provided in the search params)
      .date(day ? Number(day) : MIDDLE_OF_MONTH)
  }

  return today
}

type DateGranularity = 'day' | 'month'

/**
 * A query param in the form { key: value } corresponds to a ?key=value entry in
 * the URL (if the value is defined)
 */
type QueryParams = Record<string, string | undefined>

/** Whether a date is in the same period as the current date */
function isSamePeriod(date: moment.Moment, otherDate: moment.Moment, granularity: DateGranularity) {
  return date.isSame(otherDate, granularity)
}

/**
 * Creates query params with `month` and `year` params based on a given date, appended to
 * a base set of params if provided.
 */
function searchFromDate(
  date: moment.Moment,
  {
    timeZone,
    additionalParams,
    granularity,
    today,
  }: {
    timeZone: string
    additionalParams?: QueryParams
    granularity: DateGranularity
    today?: moment.Moment
  }
) {
  const defaultDate = today?.clone() ?? moment.tz(timeZone)
  const params: Record<string, unknown> = {}

  if (additionalParams) {
    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value) {
        params[key] = value
      }
    })
  }

  // Include the date params in the URL if not the current month (or day)
  if (!isSamePeriod(date, defaultDate, granularity)) {
    params.year = date.year()
    // Bump by one because months are zero-indexed
    params.month = date.month() + 1
    if (granularity === 'day') {
      params.date = date.date()
    }
  } else {
    delete params.month
    delete params.year
    delete params.date
  }

  return params
}

/**
 * Whether to allow changing the URL. Allowed by default, but may be disallowed if
 * `requirePathnameIncludes` is provided (e.g. to prevent adding query params after
 * navigating to a different subdomain).
 */
function allowUrlChange(pathname: string, requirePathnameIncludes?: string) {
  return requirePathnameIncludes === undefined || pathname.includes(requirePathnameIncludes)
}

/**
 * Utility hook for a page with navigation between months. The hook returns a date
 * corresponding to the current month and a setter function, which will both update the date
 * variable and update the URL to reflect the new date (with `month` and `year` query params).
 * It also accepts additional params to include in the URL, so that a component may use this
 * hook to fully manage changes to a URL based on multiple state variables.
 */
export function useDateWithUrlParams({
  timeZone,
  additionalParams,
  requirePathnameIncludes,
  granularity = 'month',
  defaultDate: today,
  resetDateDependencies,
  now,
}: {
  /** The time zone to use for the default date */
  timeZone: string
  /**
   * Params other than month & year that should be reflected in the URL. Changes to these
   * variables will update the URL, but not affect the history stack (i.e. the stack used
   * by the browser back button)
   */
  additionalParams?: QueryParams
  /** If provided, will only update the URL if the string is included in the current pathname */
  requirePathnameIncludes?: string
  /** Whether to include day as a parameter, in addition to month and year */
  granularity?: DateGranularity
  /** If provided, used as the default date if the URL contains no date params */
  defaultDate?: moment.Moment
  /** If the dependencies change, reset the date to the default */
  resetDateDependencies?: DependencyList
  /** Override the date with a specific date, used for testing */
  now?: moment.Moment
}): [moment.Moment, (date: moment.Moment) => unknown] {
  const search = useSearch({ strict: false })
  const location = useLocation()
  const navigate = useNavigate()
  const defaultDate = useMemo(
    () => now ?? dateFromSearchParams(search, timeZone, today),
    [now, search, timeZone, today]
  )

  const [date, setDate] = useState(defaultDate)

  // Update the URL when the `additionalParams` change. Replaces the URL instead of pushing
  // a new one, which doesn't affect the back button stack (this way, the back button won't
  // be clobbered when initial query params are set)
  useEffect(() => {
    if (allowUrlChange(location.pathname, requirePathnameIncludes)) {
      navigate({ to: '.', search: { ...search, ...additionalParams }, replace: true })
    }
    // Disabling exhaustive deps because we only want the effect to trigger when the params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalParams])

  // If the dependencies change, reset the date to today
  useEffect(() => {
    if (resetDateDependencies) {
      setDate(today?.clone() ?? moment.tz(timeZone))
    }
  }, [resetDateDependencies, timeZone, today])

  // Update the date state if the date in the URL has changed. The URL is viewed as the source
  // of truth, so the state should always reflect the current URL.
  useEffect(() => {
    const urlDate = dateFromSearchParams(search, timeZone, today)
    // Update the date if it doesn't match the current URL
    if (!isSamePeriod(date, urlDate, granularity)) {
      setDate(urlDate)
    }
  }, [date, timeZone, today, granularity, search])

  // If the search params are empty, reset the date to the default
  useEffect(() => {
    if (Object.keys(search).length === 0) {
      setDate(defaultDate)
    }
  }, [defaultDate, search])

  // The date setter returned by the hook doesn't directly update the state, but rather
  // creates corresponding query params and navigates to the appropriate URL. The state
  // will then be updated to reflect the URL, maintaining the URL as the primary source
  // of truth for the current date.
  const setDateInUrl = useCallback(
    (toDate: moment.Moment) => {
      // Only push a new URL if the date has changed
      if (
        !isSamePeriod(toDate, date, granularity) &&
        allowUrlChange(location.pathname, requirePathnameIncludes)
      ) {
        const toParams = searchFromDate(toDate, {
          additionalParams,
          timeZone,
          granularity,
          today,
        })
        navigate({ to: '.', search: toParams, replace: true })
      }
    },
    [
      date,
      granularity,
      location.pathname,
      requirePathnameIncludes,
      additionalParams,
      timeZone,
      today,
      navigate,
    ]
  )

  return useMemo(() => [date.clone(), setDateInUrl], [date, setDateInUrl])
}

/**
 * Given a date, return the date rounded to the end of its month if a given reference date is
 * at the end of its month.
 * matchEndOfMonth(<Aug 30>, <Sep 30>) => Aug 31
 * matchEndOfMonth(<Aug 30>, <Oct 30>) => Aug 30
 */
export function matchEndOfMonth(date: Moment, referenceDate: Moment): Moment {
  if (referenceDate.isSame(referenceDate.clone().endOf('month'), 'date')) {
    return date.clone().endOf('month')
  }
  return date.clone()
}

/**
 * Adjusts a date, corresponding to the number of months another date has changed.
 * For example, take a pay app with a start date of Sep 15 and end date of Oct 14 and say you
 * shift the end date back to Sep 14. To shift the start date back respectively, you would call
 * `adjustDateByMonths(<Sep 15>, <Oct 14>, <Sep 14>)`, which would return Aug 15.
 */
export function adjustDateByMonths(
  date: Moment,
  fromComparisonDate: Moment,
  toComparisonDate: Moment
): Moment {
  const diff = toComparisonDate
    .clone()
    .startOf('month')
    .diff(fromComparisonDate.clone().startOf('month'), 'months')
  const adjustedDate = date.clone().add(diff, 'months')
  return matchEndOfMonth(adjustedDate, date)
}

/**
 * Returns a formatted string representing a date range in its simplest form.
 * For example: September 2022; Sep 15 - Aug 14, 2022; Dec 15, 2022 - Jan 14, 2023
 */
export function formatDateRange(startDate: Moment, endDate: Moment): string {
  const isStartFirstOfMonth = startDate.isSame(startDate.clone().startOf('month'), 'date')
  const isEndLastOfMonth = endDate.isSame(endDate.clone().endOf('month'), 'date')
  const isExactMonth = isStartFirstOfMonth && isEndLastOfMonth && startDate.isSame(endDate, 'month')
  if (isExactMonth) {
    return `${endDate.format('MMMM YYYY')}`
  }
  const areSameYear = startDate.isSame(endDate, 'year')
  if (areSameYear) {
    return `${startDate.format('MMM D')} – ${endDate.format('MMM D, YYYY')}`
  }
  return `${startDate.format('MMM D, YYYY')} – ${endDate.format('MMM D, YYYY')}`
}
