import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { SitelineDialog } from './SitelineDialog'
import { Spacer } from './Spacer'

const useStyles = makeStylesFast((theme: Theme) => ({
  dialogContent: {
    '& .reasonContainer': {
      '& .reasonLabel': {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(1),
      },
    },
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.invoice.reset_to_draft'

interface DialogProps {
  open: boolean
  onClose: (success: boolean) => void
  retainAnnotations: boolean
  onRetainAnnotationsChange: (retainAnnotations: boolean) => void
  showRetainAnnotations?: boolean
  onResetToDraft: () => Promise<void>
  description?: string
  submitting?: boolean
  title?: string
  revertReason: string
  onRevertReasonChange: (update: string) => void
}

/** Dialog for resetting forms to draft and optionally preserving form values */
export function ConfirmResetToDraftDialog({
  open,
  onClose,
  retainAnnotations,
  onRetainAnnotationsChange,
  showRetainAnnotations = true,
  onResetToDraft,
  description,
  submitting,
  title,
  revertReason,
  onRevertReasonChange,
}: DialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const confirmReset = useCallback(async () => {
    await onResetToDraft()
    onClose(true)
  }, [onClose, onResetToDraft])

  const handleReasonChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const update = event.target.value
      onRevertReasonChange(update)
    },
    [onRevertReasonChange]
  )

  return (
    <SitelineDialog
      maxWidth="sm"
      open={open}
      onClose={() => onClose(false)}
      title={title ?? t(`${i18nBase}.title`)}
      onSubmit={confirmReset}
      submitLabel={t('common.actions.confirm')}
      subtitle={
        description ?? t('projects.subcontractors.pay_app.invoice.reset_to_draft.body_generic')
      }
      subtitleVariant="body1"
      submitting={submitting}
      subscript={
        showRetainAnnotations ? (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={retainAnnotations}
                  onChange={(event) => onRetainAnnotationsChange(event.target.checked)}
                />
              }
              label={t(
                'projects.subcontractors.pay_app.invoice.reset_to_draft.preserve_annotations'
              )}
            />
          </FormGroup>
        ) : undefined
      }
    >
      <div className={classes.dialogContent}>
        <div className="reasonContainer">
          <div className="reasonLabel">
            <SitelineText variant="smallText" bold>
              {t(`${i18nBase}.reason`)}
            </SitelineText>
            <Spacer maxWidth={8} />
            <SitelineText variant="smallText" color="grey50">
              {t(`${i18nBase}.optional`)}
            </SitelineText>
          </div>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={revertReason}
            onChange={handleReasonChange}
          />
        </div>
      </div>
    </SitelineDialog>
  )
}
