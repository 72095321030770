import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Grid2, TextField, Theme } from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import _ from 'lodash'
import throttle from 'lodash/throttle'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { config } from '../config/constants'
import {
  DraftLocationProperties,
  LocationInput,
  LocationProperties,
} from '../graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  highlight: {
    display: 'inline-block',
    whiteSpace: 'pre',
  },
}))

export function locationInputFromLocation(
  location: LocationProperties | DraftLocationProperties
): LocationInput {
  let nickname = ''
  if ('nickname' in location) {
    nickname = location.nickname || ''
  }
  return {
    nickname,
    street1: location.street1 || '',
    street2: location.street2 || '',
    city: location.city,
    county: location.county || '',
    state: location.state,
    postalCode: location.postalCode || '',
    country: location.country || 'USA',
    coordinates: {
      latitude: location.coordinates.latitude || 0,
      longitude: location.coordinates.longitude || 0,
    },
  }
}

const googleMapsLoader = new GoogleMapsLoader({
  apiKey: config.googleMapsApiKey,
  libraries: ['places'],
})

const placesLibrary = googleMapsLoader.importLibrary('places')
const autocompleteService = placesLibrary.then((places) => {
  return new places.AutocompleteService()
})

const fakeMap = document.createElement('div')
const placesService = placesLibrary.then((places) => {
  return new places.PlacesService(fakeMap)
})

export const geocodingService = googleMapsLoader.importLibrary('geocoding').then((geocoding) => {
  return new geocoding.Geocoder()
})

interface LocationAutocompleteProps {
  location: LocationInput | null
  setLocation: (location: LocationInput) => void
  placeholder?: string
  locationField?: keyof Omit<LocationInput, 'coordinates'>
}

/**
 * Searches a component list for a component with a type, returning the first match from
 * the given the list of types
 */
function getComponentForTypes(
  components: google.maps.GeocoderAddressComponent[],
  types: string[]
): google.maps.GeocoderAddressComponent | undefined {
  return _.chain(types)
    .map((type) => components.find((component) => component.types.includes(type)))
    .compact()
    .first()
    .value()
}

/* Taken from https://material-ui.com/components/autocomplete/#google-maps-place */
export function LocationAutocomplete({
  location,
  setLocation,
  placeholder,
  locationField = 'street1',
}: LocationAutocompleteProps) {
  const classes = useStyles()
  const [locationInputValue, setLocationInputValue] = useState('')
  const [locationOptions, setLocationOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const handleInputChange = useCallback(
    (_event: SyntheticEvent, option: google.maps.places.AutocompletePrediction | string) => {
      // If this is a keystroke change, update the input & return
      if (typeof option === 'string') {
        if (location) {
          setLocation({
            ...location,
            [locationField]: option,
          })
        }
        return
      }

      // If this change is the result of clicking an autocomplete option, derive necessary info & update location state
      placesService.then((service) => {
        const request = {
          placeId: option.place_id,
          fields: ['address_components', 'geometry'],
        }

        service.getDetails(request, (result) => {
          if (!result || !result.address_components || !result.geometry) {
            return
          }

          const { address_components: components, geometry } = result
          const streetNumber = getComponentForTypes(components, ['street_number'])
          const street = getComponentForTypes(components, ['route', 'premise', 'town_square'])
          const subpremise = getComponentForTypes(components, ['subpremise'])
          const city = getComponentForTypes(components, ['locality', 'sublocality'])
          const county = getComponentForTypes(components, ['administrative_area_level_2'])
          const state = getComponentForTypes(components, ['administrative_area_level_1'])
          const country = getComponentForTypes(components, ['country'])
          const postalCode = getComponentForTypes(components, ['postal_code'])
          const coordinates = geometry.location

          if (!coordinates) {
            console.error('Unable to derive coordinates from location input')
            return
          }

          const street1Number = streetNumber ? streetNumber.long_name + ' ' : ''

          setLocation({
            ...location,
            ...(subpremise && { street2: `#${subpremise.long_name}` }),
            street1: street1Number + (street?.long_name ?? ''),
            postalCode: postalCode?.long_name || '',
            county: county?.long_name || '',
            city: city?.long_name || '',
            state: state?.short_name || '',
            country: country?.long_name || '',
            coordinates: {
              latitude: coordinates.lat(),
              longitude: coordinates.lng(),
            },
          })
        })
      })
    },
    [location, locationField, setLocation]
  )

  const fetchLocationOptions = useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            result: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          autocompleteService.then((service) => {
            service.getPlacePredictions(
              {
                ...request,
                // If the autocomplete is for the city field, restrict the results to only cities
                // Note this does include tiny towns as well
                ...(locationField === 'city' && {
                  types: ['(cities)'],
                }),
              },
              callback
            )
          })
        },
        200
      ),
    [locationField]
  )

  useEffect(() => {
    let active = true

    if (locationInputValue === '') {
      setLocationOptions([])
      return
    }

    fetchLocationOptions({ input: locationInputValue }, (results) => {
      if (active) {
        setLocationOptions(results ?? [])
      }
    })

    return () => {
      active = false
    }
  }, [locationInputValue, fetchLocationOptions])

  const locationValue = location?.[locationField] || ''
  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={locationOptions}
      autoComplete
      includeInputInList
      onChange={handleInputChange}
      onInputChange={handleInputChange}
      value={locationValue}
      freeSolo={location !== null}
      disableClearable
      multiple={false}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          onChange={(ev) => setLocationInputValue(ev.target.value)}
        />
      )}
      renderOption={(props, option: google.maps.places.AutocompletePrediction) => {
        // Explicitly allow main_text_matched_substrings to be undefined because it can be (despite
        // what typecheck says). If it is undefined, assume the entire main_text is not highlighted
        const matches = option.structured_formatting.main_text_matched_substrings as
          | google.maps.places.PredictionSubstring[]
          | undefined
        let parts: { text: string; highlight: boolean }[] = []
        if (matches) {
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          )
        } else {
          parts = [{ text: option.structured_formatting.main_text, highlight: false }]
        }

        return (
          <li {...props} key={option.place_id}>
            <Grid2 container alignItems="center">
              <Grid2>
                <LocationOnIcon className={classes.icon} />
              </Grid2>
              <Grid2 size="grow">
                {parts.map((part, index) => (
                  <SitelineText
                    className={classes.highlight}
                    key={index}
                    variant="body1"
                    bold={part.highlight}
                  >
                    {part.text}
                  </SitelineText>
                ))}

                <SitelineText style={{ display: 'block' }} variant="secondary" color="grey50">
                  {option.structured_formatting.secondary_text}
                </SitelineText>
              </Grid2>
            </Grid2>
          </li>
        )
      }}
    />
  )
}
