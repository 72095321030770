import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  SubcontractorLienWaiverTemplateSetInput,
  useClearPrimaryLienWaiverFormsMutation,
  useUpdatePrimaryLienWaiverTemplatesMutation,
} from '../../../common/graphql/apollo-operations'
import {
  CompanyWithForms,
  deriveDefaultPrimaryLienWaiverTemplatesFromContract,
  derivePrimaryLienWaiverTemplatesFromContract,
} from '../../../common/util/Forms'
import {
  deriveFormSelectionStatusFromContract,
  getClearOnboardingFormsRefetchQuery,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import { OnboardingFormsSection } from './OnboardingFormsSection'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

gql`
  mutation clearPrimaryLienWaiverForms($contractId: ID!) {
    clearPrimaryLienWaiverForms(contractId: $contractId) {
      id
      lienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${fragments.minimalSubcontractorLienWaiverTemplateSet}
  ${fragments.onboardedProjectContractStatus}
`

const i18nBase = 'projects.subcontractors.settings.forms'

const FORM_TYPE = ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER

interface PrimaryLienWaiverFormsTaskProps {
  contract: ContractForProjectOnboarding
  company: CompanyWithForms
  isEditing?: boolean
  shouldConfirmFormDeletion?: boolean
}

/** Under the onboarding checklist Forms task, section for selecting primary lien waiver forms */
export function PrimaryLienWaiverFormsSection({
  contract,
  company,
  isEditing = true,
  shouldConfirmFormDeletion,
}: PrimaryLienWaiverFormsTaskProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const refetchQuery = useMemo(() => getClearOnboardingFormsRefetchQuery(companyId), [companyId])

  const [clearForms] = useClearPrimaryLienWaiverFormsMutation({
    ...refetchQuery,
    update: (cache) => {
      // OnboardedStatus.selectedPrimaryLienWaivers might change
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const [updatePrimaryLienWaiverFormTemplates] = useUpdatePrimaryLienWaiverTemplatesMutation()

  const defaultFormTemplates = useMemo(
    () => deriveDefaultPrimaryLienWaiverTemplatesFromContract(company),
    [company]
  )

  const formTemplates = useMemo(
    () => derivePrimaryLienWaiverTemplatesFromContract(contract),
    [contract]
  )

  const isProcessing = useMemo(() => {
    return deriveFormSelectionStatusFromContract(FORM_TYPE, contract).isProcessingForms
  }, [contract])

  const handleClearForms = useCallback(async () => {
    try {
      await clearForms({ variables: { contractId: contract.id } })
      snackbar.showSuccess(t(`${i18nBase}.cleared_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [clearForms, contract.id, snackbar, t])

  const handleRemoveForm = useCallback(
    async (formTemplateId: string) => {
      const { lienWaiverTemplates: templates } = contract

      const newSet: SubcontractorLienWaiverTemplateSetInput = {
        conditionalFinalVariantId:
          templates?.conditionalFinalVariant?.template.id === formTemplateId
            ? null
            : templates?.conditionalFinalVariant?.id,
        conditionalProgressVariantId:
          templates?.conditionalProgressVariant?.template.id === formTemplateId
            ? null
            : templates?.conditionalProgressVariant?.id,
        unconditionalFinalVariantId:
          templates?.unconditionalFinalVariant?.template.id === formTemplateId
            ? null
            : templates?.unconditionalFinalVariant?.id,
        unconditionalProgressVariantId:
          templates?.unconditionalProgressVariant?.template.id === formTemplateId
            ? null
            : templates?.unconditionalProgressVariant?.id,
      }

      try {
        await updatePrimaryLienWaiverFormTemplates({
          variables: {
            input: {
              contractId: contract.id,
              templates: newSet,
            },
          },
        })
        snackbar.showSuccess(t(`${i18nBase}.removed_form`))
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract, snackbar, t, updatePrimaryLienWaiverFormTemplates]
  )

  return (
    <OnboardingFormsSection
      onboardingFormType={FORM_TYPE}
      formTemplates={formTemplates}
      defaultFormTemplates={defaultFormTemplates}
      isProcessingForms={isProcessing}
      onClearForms={handleClearForms}
      onDeleteForm={isEditing ? handleRemoveForm : undefined}
      contract={contract}
      isEditing={isEditing}
      shouldConfirmFormDeletion={shouldConfirmFormDeletion}
    />
  )
}
