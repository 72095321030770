import { Theme } from '@mui/material/styles'
import { useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationType, makeStylesFast } from 'siteline-common-web'
import { ContainerWithSideTabs } from '../../common/components/ContainerWithSideTabs'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import { findCompanyIntegrationOfType } from '../../common/util/Integration'
import { useWaypoint } from '../../common/util/ReactWaypoint'
import { Company } from './Company'
import { FoundationCompanySettings } from './FoundationCompanySettings'
import { Permissions } from './Permissions'
import { QuickbooksCompanySettings } from './QuickbooksCompanySettings'

export enum CompanySettingsSection {
  COMPANY = 'company',
  QUICKBOOKS = 'quickbooks',
  FOUNDATION = 'foundation',
  PERMISSIONS = 'permissions',
}

const useStyles = makeStylesFast((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}))

const i18nBase = 'settings'

export function CompanySettings() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { company } = useCompanyContext()
  const sectionInSearch = useSearch({
    from: '/_a/settings/company/_c/',
    select: (search) => search.section,
  })

  const [selectedSection, setSelectedSection] = useState(CompanySettingsSection.COMPANY)
  const [handleScrollToSection, handleScrollSectionIntoView, isWaypointDisabled, disableWaypoint] =
    useWaypoint<CompanySettingsSection>({
      setSelectedSection,
      sectionToString: (section: CompanySettingsSection) => section,
    })

  const hasQuickbooksIntegration = useMemo(
    () =>
      findCompanyIntegrationOfType(company, IntegrationType.QUICKBOOKS_ENTERPRISE_FILE) !==
      undefined,
    [company]
  )

  const hasFoundationIntegration = useMemo(
    () => findCompanyIntegrationOfType(company, IntegrationType.FOUNDATION) !== undefined,
    [company]
  )

  const sidebarTabs = Object.values(CompanySettingsSection)
    // Only include the Integrations section if the company has a configurable integration
    .filter((tab) => {
      if (tab === CompanySettingsSection.QUICKBOOKS) {
        return hasQuickbooksIntegration
      }
      if (tab === CompanySettingsSection.FOUNDATION) {
        return hasFoundationIntegration
      }
      return true
    })
    .map((value) => {
      return {
        key: value,
        label: t(`${i18nBase}.navigation.${value}`),
        value,
      }
    })
  const sections = [
    { section: CompanySettingsSection.COMPANY, content: <Company /> },
    ...(hasQuickbooksIntegration
      ? [{ section: CompanySettingsSection.QUICKBOOKS, content: <QuickbooksCompanySettings /> }]
      : []),
    ...(hasFoundationIntegration
      ? [{ section: CompanySettingsSection.FOUNDATION, content: <FoundationCompanySettings /> }]
      : []),
    { section: CompanySettingsSection.PERMISSIONS, content: <Permissions /> },
  ]

  // If the page is loaded with a section name in the URL, scroll once to this section
  const scrollToSection = useCallback(() => {
    const selected = Object.values(CompanySettingsSection).find(
      (section) => section === sectionInSearch
    )
    if (selected) {
      handleScrollToSection(selected)
    }
  }, [handleScrollToSection, sectionInSearch])

  // If a hash is included in the URL, attempt once to scroll to the section
  useEffect(scrollToSection, [scrollToSection])

  return (
    <ContainerWithSideTabs
      className={classes.container}
      sidebarTabs={sidebarTabs}
      selectedSection={selectedSection}
      onSelectedSectionChange={setSelectedSection}
      handleScrollToSection={handleScrollToSection}
      handleScrollSectionIntoView={handleScrollSectionIntoView}
      isWaypointDisabled={isWaypointDisabled}
      disableWaypoint={disableWaypoint}
      sections={sections}
    />
  )
}
