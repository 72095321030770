import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Autocomplete, Button, Link, TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CompanyIntegrationMetadataFoundation } from 'siteline-common-all'
import {
  IntegrationType,
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import {
  useGetIntegrationGeneralLedgerAccountsQuery,
  useUpdateCompanyIntegrationMetadataMutation,
} from '../../common/graphql/apollo-operations'
import { findCompanyIntegrationOfType } from '../../common/util/Integration'
import { getLedgerAccountLabel } from '../billing/sync/SyncFoundationDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .flex': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .editButton': {
      color: colors.grey50,
      margin: theme.spacing(-1, -1, 0, 0),
    },
    '& .saveButton': {
      margin: theme.spacing(-1, 0, 0, 2),
    },
    '& .heading': {
      alignItems: 'center',
      '& .Siteline-h4': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .subtitle': {
      display: 'block',
      margin: theme.spacing(1, 0),
      whiteSpace: 'pre-wrap',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
      '&.editing': {
        padding: theme.spacing(0.5, 0),
      },
      '& .label': {
        minWidth: 300,
        marginRight: theme.spacing(3),
      },
      '& .input': {
        width: 300,
      },
      '& .tooltipIcon': {
        fontSize: 16,
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
}))

const i18nBase = 'settings.foundation'

const FOUNDATION_HELP_CENTER_ARTICLE =
  'https://support.siteline.com/hc/en-us/articles/25668398034196-Foundation-Integration-Details'

/** Company-wide integration settings for Foundation */
export function FoundationCompanySettings() {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { company } = useCompanyContext()
  const [updateCompanyIntegrationMetadata] = useUpdateCompanyIntegrationMetadataMutation()
  const foundationCompanyIntegration = useMemo(() => {
    return findCompanyIntegrationOfType(company, IntegrationType.FOUNDATION)
  }, [company])
  const { data: accountsData, loading: loadingAccounts } =
    useGetIntegrationGeneralLedgerAccountsQuery({
      variables: { companyIntegrationId: foundationCompanyIntegration?.id ?? '' },
      skip: foundationCompanyIntegration === undefined,
    })
  const ledgerAccounts = useMemo(() => {
    const accounts = accountsData?.integrationGeneralLedgerAccounts ?? []
    return _.orderBy(accounts, getLedgerAccountLabel)
  }, [accountsData])
  const initialMetadata: CompanyIntegrationMetadataFoundation = useMemo(() => {
    if (!foundationCompanyIntegration) {
      // This code should not be reachable, since this section is only mounted if a Foundation
      // company integration exists
      throw new Error('No Foundation company integration found')
    }
    const mappings = foundationCompanyIntegration.metadata as
      | CompanyIntegrationMetadataFoundation
      | undefined
    return mappings ?? {}
  }, [foundationCompanyIntegration])
  const [metadata, setMetadata] = useState<CompanyIntegrationMetadataFoundation>(initialMetadata)

  const ledgerAccount = useMemo(
    () =>
      ledgerAccounts.find(
        (account) => account.integrationAccountId === metadata.ledgerAccount?.agaveAccountId
      ) ?? null,
    [ledgerAccounts, metadata.ledgerAccount?.agaveAccountId]
  )
  const retentionLedgerAccount = useMemo(
    () =>
      ledgerAccounts.find(
        (account) => account.code === metadata.retentionLedgerAccount?.agaveSourceId
      ) ?? null,
    [ledgerAccounts, metadata.retentionLedgerAccount?.agaveSourceId]
  )

  const onEditOrCancel = useCallback(() => {
    // If we were editing already and we cancel, then we should reset the state back to default
    if (isEditing) {
      setMetadata(initialMetadata)
    }
    setIsEditing(!isEditing)
  }, [initialMetadata, isEditing])

  const handleSave = useCallback(async () => {
    if (!foundationCompanyIntegration) {
      // Should not be possible, as this card would not be rendered if there was no existing
      // Foundation company integration
      return
    }
    try {
      await updateCompanyIntegrationMetadata({
        variables: { input: { companyIntegrationId: foundationCompanyIntegration.id, metadata } },
      })
      setIsEditing(false)
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [foundationCompanyIntegration, metadata, snackbar, updateCompanyIntegrationMetadata])

  return (
    <div className={classes.root}>
      <div className="heading flex">
        <SitelineText variant="h3" bold color="grey90">
          {t('settings.navigation.foundation')}
        </SitelineText>
        <div>
          <Button variant="text" color="secondary" onClick={onEditOrCancel} className="editButton">
            {isEditing ? t('common.actions.cancel') : t('common.actions.edit')}
          </Button>
          {isEditing && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="saveButton"
              disabled={loadingAccounts}
            >
              {t('common.actions.save')}
            </Button>
          )}
        </div>
      </div>
      <SitelineText variant="body2" color="grey50" className="subtitle">
        <Trans
          i18nKey={`${i18nBase}.description`}
          components={{
            bold: <span className={classes.bold} />,
            helpLink: (
              <Link
                href={FOUNDATION_HELP_CENTER_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                color={colors.grey50}
              />
            ),
          }}
        />
      </SitelineText>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.gl_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.gl_account`)}
        </SitelineText>
        {isEditing ? (
          <Autocomplete
            value={ledgerAccount ?? undefined}
            disableClearable={false}
            fullWidth
            freeSolo={false}
            options={ledgerAccounts}
            autoHighlight
            openOnFocus
            onChange={(ev, option) =>
              setMetadata({
                ...metadata,
                ledgerAccount: option ? { agaveAccountId: option.integrationAccountId } : undefined,
              })
            }
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  placeholder={t(`${i18nBase}.search_accounts`)}
                  {...params}
                />
              )
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.integrationAccountId}>
                <SitelineText variant="secondary">{getLedgerAccountLabel(option)}</SitelineText>
              </li>
            )}
            getOptionLabel={getLedgerAccountLabel}
          />
        ) : (
          <SitelineText variant="body1" color={metadata.ledgerAccount ? 'grey90' : 'grey50'}>
            {ledgerAccount ? `${ledgerAccount.code} ${ledgerAccount.name}` : '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_gl_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_gl_account`)}
        </SitelineText>
        {isEditing ? (
          <Autocomplete
            value={retentionLedgerAccount ?? undefined}
            disableClearable={false}
            fullWidth
            freeSolo={false}
            options={ledgerAccounts}
            autoHighlight
            openOnFocus
            onChange={(ev, option) =>
              setMetadata({
                ...metadata,
                retentionLedgerAccount: option ? { agaveSourceId: option.code } : undefined,
              })
            }
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  placeholder={t(`${i18nBase}.search_accounts`)}
                  {...params}
                />
              )
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.integrationAccountId}>
                <SitelineText variant="secondary">{getLedgerAccountLabel(option)}</SitelineText>
              </li>
            )}
            getOptionLabel={getLedgerAccountLabel}
          />
        ) : (
          <SitelineText variant="body1" color={metadata.ledgerAccount ? 'grey90' : 'grey50'}>
            {retentionLedgerAccount
              ? `${retentionLedgerAccount.code} ${retentionLedgerAccount.name}`
              : '–'}
          </SitelineText>
        )}
      </div>
    </div>
  )
}
