import { TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, colorStyles, makeStylesFast, SitelineText } from 'siteline-common-web'
// We can't export scalars directly in common-web index.tsx because of the codegen and global conflicts (eg: URL)
import { TFunction } from 'i18next'
import { LatLng } from 'siteline-common-web/src/scalars'
import { LocationInput } from '../graphql/apollo-operations'
import { formatLocationOneLine } from '../util/Location'
import { geocodingService, LocationAutocomplete } from './LocationAutocomplete'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    margin: theme.spacing(-2, 0, 2, 0),
    '& .addressSection': {
      marginTop: theme.spacing(2),
      '& .label': {
        marginBottom: theme.spacing(0.5),
      },
    },
    '& .city-county': {
      display: 'flex',
      margin: theme.spacing(2, -1, 0, -1),
      '& > *': {
        flex: 1,
        margin: theme.spacing(0, 1),
      },
    },
    '& .state-postal-code': {
      display: 'flex',
      margin: theme.spacing(2, -1, 0, -1),
      '& > *': {
        flex: 1,
        margin: theme.spacing(0, 1),
      },
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
    },
  },
}))

export async function calculateLocationCoordinates(
  location: LocationInput,
  t: TFunction
): Promise<LatLng['input']> {
  // Recalculate coordinates based on manual input
  if (location.coordinates.latitude !== 0 || location.coordinates.longitude !== 0) {
    return location.coordinates
  }

  const address = formatLocationOneLine(location)
  const geocodePromise: Promise<google.maps.GeocoderResult[]> = new Promise((resolve, reject) => {
    geocodingService
      .then((service) => {
        service.geocode({ address }, (results) => {
          resolve(results ?? [])
        })
      })
      .catch((error) => {
        reject(error)
      })
  })

  const results = await geocodePromise
  if (results.length === 0) {
    throw new Error(t('projects.new_project.invalid_location'))
  }

  // Take the very first result, if one exists and update the coordinates state
  const result = results[0].geometry.location
  return {
    latitude: result.lat(),
    longitude: result.lng(),
  }
}

interface LocationAddressFormProps {
  location: LocationInput
  onLocationChange: (location: LocationInput) => void
  /** Whether to include an input for the location nickname */
  includeNickname: boolean
  /** @default true */
  markRequiredFields?: boolean
  labelColor: keyof typeof colorStyles
}

/** An autocomplete and set of inputs for entering a street address location */
export function LocationAddressForm({
  location,
  onLocationChange,
  includeNickname,
  labelColor,
  markRequiredFields = true,
}: LocationAddressFormProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    if (name === 'state') {
      // Uppercase the state, to signal to the user they should be using the state abbreviation
      value = value.toUpperCase()
    }
    onLocationChange({ ...location, [name]: value })
  }

  return (
    <div className={classes.root}>
      {includeNickname && (
        <div className="addressSection">
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {t('settings.company.labels.nickname')}
            </SitelineText>
          </div>
          <TextField
            variant="outlined"
            value={location.nickname ?? ''}
            onChange={handleChange('nickname')}
          />
        </div>
      )}
      <div className="addressSection">
        <div className="label">
          <SitelineText variant="label" color={labelColor}>
            {t('settings.company.labels.street1')}
          </SitelineText>
        </div>
        <LocationAutocomplete location={location} setLocation={onLocationChange} />
      </div>
      <div className="addressSection">
        <div className="label">
          <SitelineText variant="label" color={labelColor}>
            {t('settings.company.labels.street2')}
          </SitelineText>
        </div>
        <TextField
          variant="outlined"
          value={location.street2 ?? ''}
          onChange={handleChange('street2')}
        />
      </div>
      <div className="city-county addressSection">
        <div>
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {markRequiredFields
                ? t('settings.company.labels.city')
                : t('settings.company.labels.optional_city')}
            </SitelineText>
          </div>
          <LocationAutocomplete
            location={location}
            setLocation={onLocationChange}
            locationField="city"
          />
        </div>
        <div>
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {t('settings.company.labels.county')}
            </SitelineText>
          </div>
          <TextField
            variant="outlined"
            value={location.county ?? ''}
            onChange={handleChange('county')}
          />
        </div>
      </div>
      <div className="state-postal-code addressSection">
        <div>
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {markRequiredFields
                ? t('settings.company.labels.state')
                : t('settings.company.labels.optional_state')}
            </SitelineText>
          </div>
          <TextField
            variant="outlined"
            value={location.state}
            onChange={handleChange('state')}
            slotProps={{
              htmlInput: {
                maxLength: 3,
              },
            }}
          />
        </div>
        <div>
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {t('settings.company.labels.postalCode')}
            </SitelineText>
          </div>
          <TextField
            variant="outlined"
            value={location.postalCode ?? ''}
            onChange={handleChange('postalCode')}
          />
        </div>
        <div>
          <div className="label">
            <SitelineText variant="label" color={labelColor}>
              {markRequiredFields
                ? t('settings.company.labels.country')
                : t('settings.company.labels.optional_country')}
            </SitelineText>
          </div>
          <TextField
            variant="outlined"
            value={location.country}
            onChange={handleChange('country')}
          />
        </div>
      </div>
    </div>
  )
}
