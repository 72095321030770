import AutorenewIcon from '@mui/icons-material/Autorenew'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Theme } from '@mui/material/styles'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LienWaiverProgressType } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { OnboardingButton } from '../onboarding/OnboardingButton'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  lienWaiverButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const i18nBase = 'projects.subcontractors.pay_app_list.lien_waivers'

interface SelectLienWaiverProgressTypeDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (type: LienWaiverProgressType) => void
  /** If the unconditional progress lw template is being processed, we'll disable the option */
  isProcessingProgressTemplate: boolean
  /** If the unconditional final lw template is being processed, we'll disable the option */
  isProcessingFinalTemplate: boolean
}

/**
 * Dialog presented when the user has clicked "mark as paid" on a T&M pay app that has unconditional lien waivers.
 * The reason we present this dialog for T&Ms is that we don't have a way of deriving the lien waiver type based
 * on a remaining balance. The user must indicate which lien waiver they want to sign
 */
export function SelectLienWaiverProgressTypeDialog({
  open,
  onClose,
  onSubmit,
  isProcessingProgressTemplate,
  isProcessingFinalTemplate,
}: SelectLienWaiverProgressTypeDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSelectProgress = useCallback(() => {
    onSubmit(LienWaiverProgressType.PROGRESS)
  }, [onSubmit])

  const handleSelectFinal = useCallback(() => {
    onSubmit(LienWaiverProgressType.FINAL)
  }, [onSubmit])

  return (
    <SitelineDialog
      title={t(`${i18nBase}.select_progress_type_title`)}
      open={open}
      onClose={onClose}
      size="email"
      actionsLayout="closeIcon"
    >
      <div className={classes.root}>
        <OnboardingButton
          title={t(`${i18nBase}.select_progress`)}
          subtitle={t(`${i18nBase}.select_progress_subtitle`)}
          onClick={handleSelectProgress}
          disabled={isProcessingProgressTemplate}
          imageSrc={<AutorenewIcon fontSize="small" />}
          className={classes.lienWaiverButton}
        />
        <OnboardingButton
          title={t(`${i18nBase}.select_final`)}
          subtitle={t(`${i18nBase}.select_final_subtitle`)}
          onClick={handleSelectFinal}
          disabled={isProcessingFinalTemplate}
          imageSrc={<TaskAltIcon fontSize="small" />}
          className={classes.lienWaiverButton}
        />
      </div>
    </SitelineDialog>
  )
}
