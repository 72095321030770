import { Theme } from '@mui/material/styles'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import 'reset-css'
import { configurePdfService, initSentry, initializeLogRocket } from 'siteline-common-web'
import { Segment } from './Analytics'
import { App } from './App'
import { getCurrentUserAuthorization, initializeFirebaseAuth } from './client'
import {
  API_ENV,
  SENTRY_RELEASE,
  logRocketApiUrl,
  logRocketProject,
  pdfBaseUrl,
  sentryDsn,
  sentryTunnel,
} from './common/config/constants'

import './Fonts'
import './fonts.css'
import './i18n'
import './index.css'

// Polyfill for Promise.withResolvers, which is required by react-pdf
// See https://github.com/wojtekmaj/react-pdf/wiki/Upgrade-guide-from-version-8.x-to-9.x#dropped-support-for-older-browsers-and-nodejs-versions
import 'core-js/proposals/promise-with-resolvers'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

initSentry({
  dsn: sentryDsn,
  tunnel: sentryTunnel,
  release: SENTRY_RELEASE,
  environment: API_ENV,
})

Segment.initializeAnalytics()

// Initialize LogRocket session logging for prod and staging environments. Following install
// instructions using the app ID provided by LogRocket.
if (API_ENV === 'production' || API_ENV === 'staging') {
  void initializeLogRocket({
    project: logRocketProject,
    serverURL: logRocketApiUrl,
    release: SENTRY_RELEASE,
  })
}

initializeFirebaseAuth()
configurePdfService({
  baseUrl: pdfBaseUrl,
  getAuth: getCurrentUserAuthorization,
})

const container = document.getElementById('root')
if (!container) {
  throw new Error('Could not get root element')
}
const root = createRoot(container)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
