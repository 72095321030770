import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { colors, makeStylesFast, SitelineText } from 'siteline-common-web'
import ConeIcon from '../../assets/icons/cone.svg'
import SitelineLogo from '../../assets/images/logo/white-text.svg'
import { trackMaintenanceViewed } from '../../common/util/MetricsTracking'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: colors.blue90,
    '& .cones': {
      backgroundImage: `url(${ConeIcon})`,
      backgroundRepeat: 'repeat-y',
      position: 'fixed',
      left: 0,
      height: '100vh',
      width: 48,
      margin: theme.spacing(4),
      '&.second': {
        marginLeft: theme.spacing(12),
        top: theme.spacing(2),
      },
    },
    '& .logo': {
      marginBottom: theme.spacing(7),
      width: '155px',
    },
    '& .heading': {
      color: colors.white,
      fontSize: 36,
      lineHeight: '32px',
      marginBottom: theme.spacing(3),
    },
  },
}))

export function Maintenance() {
  const classes = useStyles()
  const { t } = useTranslation()

  trackMaintenanceViewed()

  return (
    <div className={classes.root}>
      <div className="cones first" />
      <div className="cones second" />
      <div>
        <img src={SitelineLogo} alt="Siteline" className="logo" />
        <SitelineText variant="h1" bold className="heading">
          {t('maintenance.title')}
        </SitelineText>
        <SitelineText variant="h3" color="white">
          {t('maintenance.description')}
        </SitelineText>
      </div>
    </div>
  )
}
