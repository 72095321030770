import { gql } from '@apollo/client'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Skeleton,
  TextField,
  Theme,
} from '@mui/material'
import { SyntheticEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TAX_RATE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import {
  CompanyIntegrationProperties,
  IntegrationTaxGroupProperties,
  useCompanyIntegrationTaxGroupsQuery,
} from '../../../../common/graphql/apollo-operations'

gql`
  query companyIntegrationTaxGroups($input: GetCompanyIntegrationTaxGroupsInput!) {
    companyIntegrationTaxGroups(input: $input) {
      integrationTaxGroupId
      name
      taxPercent
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
}))

interface SelectTaxGroupIntegrationProps {
  companyIntegration: CompanyIntegrationProperties
  selectedIntegrationTaxGroupId: string | null
  onSelectIntegrationTaxGroupId: (
    integrationTaxGroupId: string | null,
    companyIntegrationId: string
  ) => void
}

const i18nBase = 'projects.subcontractors.taxes'
const collator = new Intl.Collator('en', { numeric: true })

/** Label and autoselect for choosing a tax group from an integration */
export function SelectTaxGroupIntegration({
  companyIntegration,
  selectedIntegrationTaxGroupId,
  onSelectIntegrationTaxGroupId,
}: SelectTaxGroupIntegrationProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data, loading } = useCompanyIntegrationTaxGroupsQuery({
    variables: { input: { companyIntegrationId: companyIntegration.id } },
  })
  const integrationTaxGroups = useMemo(() => {
    const groups = [...(data?.companyIntegrationTaxGroups ?? [])]
    return groups.sort((a, b) => collator.compare(a.name, b.name))
  }, [data?.companyIntegrationTaxGroups])
  const selectedTaxGroup = useMemo(
    () =>
      integrationTaxGroups.find(
        (taxGroup) => taxGroup.integrationTaxGroupId === selectedIntegrationTaxGroupId
      ) ?? null,
    [integrationTaxGroups, selectedIntegrationTaxGroupId]
  )

  const handleChange = useCallback(
    (evt: SyntheticEvent<Element, Event>, option: IntegrationTaxGroupProperties | null) =>
      onSelectIntegrationTaxGroupId(option?.integrationTaxGroupId ?? null, companyIntegration.id),
    [companyIntegration.id, onSelectIntegrationTaxGroupId]
  )
  const getOptionLabel = useCallback(
    (option: IntegrationTaxGroupProperties) =>
      `${option.name} (${decimalToPercent(option.taxPercent, TAX_RATE_PERCENT_PRECISION)}%)`,
    []
  )
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField variant="outlined" type="label" placeholder={t('common.none')} {...params} />
    ),
    [t]
  )

  return (
    <div>
      <SitelineText variant="body1" color="grey50" className={classes.label}>
        {companyIntegration.longName}
      </SitelineText>
      {loading && <Skeleton variant="rectangular" height={40} />}
      {!loading && (
        <Autocomplete
          value={selectedTaxGroup}
          options={integrationTaxGroups}
          onChange={handleChange}
          className="select"
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
          noOptionsText={
            <SitelineText variant="secondary">
              {t(`${i18nBase}.no_integration_groups`)}
            </SitelineText>
          }
        />
      )}
    </div>
  )
}
