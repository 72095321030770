import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { Theme } from '@mui/material'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { TAX_RATE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { TaxGroupProperties } from '../../../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey10,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
    '& .bold': {
      fontWeight: 600,
      marginRight: theme.spacing(0.5),
    },
  },
}))

interface SingleTaxRateBannerProps {
  taxGroup: TaxGroupProperties
  className?: string
}

/** Banner for contracts with a single tax rate applied */
export function SingleTaxRateBanner({ taxGroup, className }: SingleTaxRateBannerProps) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <SitelineText variant="body1" startIcon={<ReceiptLongIcon />}>
        <Trans
          i18nKey="projects.subcontractors.taxes.single_tax_rate"
          values={{
            name: taxGroup.name,
            percent: decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION),
          }}
          components={{ bold: <span className="bold" /> }}
        />
      </SitelineText>
    </div>
  )
}
