import { TFunction } from 'i18next'
import _ from 'lodash'
import {
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  formatCentsToDollars,
  roundCents,
  safeDivide,
} from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import {
  SpreadsheetCell,
  SpreadsheetColumn,
  SpreadsheetDataType,
  SpreadsheetRow,
  SpreadsheetRowType,
  SpreadsheetValue,
  ValidationFunctionResult,
  makeDataCell,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  SovLineItemProgressProperties,
  WorksheetLineItemProgressProperties,
} from '../graphql/apollo-operations'
import {
  BaseFieldGuestInvoiceColumn,
  EditingWorksheetLineItem,
  EditingWorksheetSovLineItem,
  doesWorksheetExceedTotalValue,
  validatePercentComplete,
} from './BillingWorksheet'
import { SpreadsheetRowDeleteIcon } from './InvoiceRow'
import { NUM_FIXED_UNIT_DECIMALS } from './ManageUnitPriceSovColumn'
import {
  balanceToFinishForWorksheet,
  currentWorksheetProgressPercentComplete,
  isNewBilledInRange,
  roundPercentComplete,
} from './PayApp'

export enum FieldGuestUnitPriceInvoiceColumn {
  UNIT_NAME = 'unitName',
  TOTAL_UNITS = 'totalUnits',
  CURRENT_UNITS_BILLED = 'currentUnitsBilled',
}

/**
 * Checks if a cell value is overbilling, and if so confirms that the user intends to
 * bill that amount
 */
function validateUnitsBilled(
  newValue: SpreadsheetValue,
  progress: WorksheetLineItemProgressProperties,
  t: TFunction
): ValidationFunctionResult {
  const i18nBase = 'projects.subcontractors.pay_app.invoice'
  const value = Number(newValue)
  if (isNaN(value)) {
    return { type: 'error', message: t('common.spreadsheet.not_a_number') }
  }
  const unitPrice = progress.worksheetLineItem.unitPrice
  if (unitPrice === null) {
    return { type: 'error', message: t(`${i18nBase}.confirm_billing.no_unit_price`) }
  }

  const centsValue = roundCents(value * unitPrice)
  if (progress.totalValue === 0 && value !== 0) {
    // If billing any non-zero amount on a $0 line item, throw an error
    return {
      type: 'error',
      message: t('projects.subcontractors.pay_app.invoice.zero_scheduled_value'),
    }
  }
  if (!isNewBilledInRange(centsValue, progress.totalValue)) {
    const min = Math.min(progress.totalValue, 0)
    const max = Math.max(progress.totalValue, 0)
    if (centsValue < min) {
      return {
        type: 'error',
        message: t(`${i18nBase}.line_item_value_low_specific`, {
          // Important not to show dollar amounts here (and below), since we don't want to expose $s
          // to users with field guest access only
          amount: safeDivide(min, unitPrice, 0),
        }),
      }
    }
    if (centsValue > max) {
      return {
        type: 'error',
        message: t(`${i18nBase}.line_item_value_high_specific`, {
          amount: safeDivide(max, unitPrice, 0),
        }),
      }
    }
  } else {
    const clone = { ...progress, progressBilled: centsValue }
    const balance = balanceToFinishForWorksheet(clone)
    const totalValue = clone.totalValue - clone.previousBilled
    let tooLow = false
    let tooHigh = false
    if (totalValue < 0) {
      // If the total expected value for the line item is negative, then the remaining balance
      // should be <= 0 but above the negative total value (i.e. for a negative change order)
      tooLow = balance > 0
      tooHigh = balance < totalValue
    } else {
      tooLow = balance > totalValue
      tooHigh = balance < 0
    }
    if (tooLow) {
      return {
        type: 'error',
        message: t(`${i18nBase}.line_item_value_low`),
      }
    }
    if (tooHigh) {
      return {
        type: 'error',
        message: t(`${i18nBase}.line_item_value_high`),
      }
    }
  }

  return null
}

/** Returns the list of columns to show on the field tool pay app invoice table */
export function getFieldGuestUnitPriceInvoiceColumns(
  t: TFunction,
  {
    isMobileLayout,
    isSidebar,
    editMode,
    canEdit,
    unitName,
    includeProgressColumns,
    includePreSitelineBillingColumn,
  }: {
    isMobileLayout: boolean
    isSidebar: boolean
    editMode?: 'worksheet' | 'progress'
    canEdit: boolean
    /** Only applies to worksheets that represent a single line item (i.e. sidebar worksheets) */
    unitName: string | undefined
    includeProgressColumns: boolean
    includePreSitelineBillingColumn: boolean
  }
): SpreadsheetColumn[] {
  const headingI18nBase = 'projects.subcontractors.pay_app.invoice.headers'
  let totalUnitsTitle = t(`${headingI18nBase}.total_units`)

  if (isSidebar && unitName !== undefined && unitName.length > 0) {
    totalUnitsTitle = `${totalUnitsTitle} (${unitName})`
  }

  const columns: SpreadsheetColumn[] = [
    {
      id: BaseFieldGuestInvoiceColumn.NUMBER,
      heading: t(`${headingI18nBase}.number`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      minWidth: 80,
      maxWidth: 85,
      wordBreak: 'break-all',
    },
    {
      id: BaseFieldGuestInvoiceColumn.NAME,
      heading: t(`${headingI18nBase}.description`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 300,
      minWidth: isMobileLayout ? undefined : 100,
    },
  ]

  if (!isSidebar) {
    columns.push({
      id: FieldGuestUnitPriceInvoiceColumn.UNIT_NAME,
      heading: isMobileLayout ? '' : t(`${headingI18nBase}.unit_of_measure`),
      // Unit name must always match the units of the SOV line item, so it is only editable in
      // the SOV and not from the worksheet
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
    })
  }

  columns.push({
    id: FieldGuestUnitPriceInvoiceColumn.TOTAL_UNITS,
    heading: isMobileLayout ? '' : totalUnitsTitle,
    isEditable: canEdit && editMode === 'worksheet',
    dataType: SpreadsheetDataType.NUMBER,
    align: 'right',
    fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
  })

  if (includePreSitelineBillingColumn && !isMobileLayout) {
    columns.push({
      id: BaseFieldGuestInvoiceColumn.PRE_SITELINE_BILLING,
      heading: t(`${headingI18nBase}.pre_siteline_billing`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    })
  }

  if (includeProgressColumns) {
    columns.push(
      {
        id: BaseFieldGuestInvoiceColumn.PERCENT_COMPLETE,
        heading: isMobileLayout ? '' : t(`${headingI18nBase}.percent_complete`),
        isEditable: canEdit && editMode === 'progress',
        dataType: SpreadsheetDataType.PERCENT,
        align: 'right',
      },
      {
        id: FieldGuestUnitPriceInvoiceColumn.CURRENT_UNITS_BILLED,
        heading: isMobileLayout ? '' : t(`${headingI18nBase}.current_quantity`),
        isEditable: canEdit && editMode === 'progress',
        dataType: SpreadsheetDataType.NUMBER,
        align: 'right',
        fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      }
    )
  }

  return columns
}

/**
 * Returns a `SpreadsheetRow` header component for the invoice line item. For the field guest tool, this is styled
 * to look like a group header row because field tool line items have child "worksheet" line items.
 */
export function getFieldGuestUnitPriceInvoiceLineItemRow({
  sovLineItem,
  numColumns,
}: {
  sovLineItem: EditingWorksheetSovLineItem
  numColumns: number
}): SpreadsheetRow {
  const row: SpreadsheetRow = {
    id: sovLineItem.id,
    type: SpreadsheetRowType.DEFAULT,
    cells: [],
    isGroupHeaderRow: true,
    isNonEditableRow: true,
    isFirstInUngroupedBlock: true,
  }

  if (sovLineItem.unitPrice === undefined) {
    // Should not be possible, just helpful for type-checking
    return row
  }

  const cells = [makeDataCell(''), makeDataCell(sovLineItem.name, { colSpan: numColumns - 1 })]

  return {
    ...row,
    cells,
  }
}

export const MOBILE_DESCRIPTION_ROW_ID_SUFFIX = '-name'
export const MOBILE_PERCENT_COMPLETE_ROW_ID_SUFFIX = '-percentComplete'
export const MOBILE_UNITS_CONTRACTED_ROW_ID_SUFFIX = '-unitsContracted'
export const MOBILE_UNITS_BILLED_ROW_ID_SUFFIX = '-unitsBilled'

/**
 * Returns a `SpreadsheetRow` component for the invoice *worksheet* line item. For the field guest tool, this is styled
 * to look like a regular line item row because field tool line items have child "worksheet" line items. For mobile
 * screen sizes, we stack some cells so that they span an entire row.
 */
export function getFieldGuestUnitPriceInvoiceWorksheetRow({
  worksheetLineItem,
  progress,
  numColumns,
  isMobileLayout,
  includePreSitelineBillingColumn,
  onDelete,
  t,
  isSidebar,
  sovLineItem,
}: {
  worksheetLineItem: EditingWorksheetLineItem
  progress: WorksheetLineItemProgressProperties | null
  numColumns: number
  isMobileLayout: boolean
  includePreSitelineBillingColumn: boolean
  onDelete?: (lineItemId: string) => void
  t: TFunction
  isSidebar: boolean
  sovLineItem: EditingWorksheetSovLineItem
}): SpreadsheetRow[] | null {
  const i18nBase = 'projects.subcontractors.sov'
  if (worksheetLineItem.unitPrice === undefined) {
    // Should not be possible, just helpful for type-checking
    return null
  }
  const unitPrice = worksheetLineItem.unitPrice
  const includeProgressColumns = progress !== null

  const unitsContracted = safeDivide(worksheetLineItem.totalValue, unitPrice, 0)
  const currentQuantity = safeDivide(progress?.progressBilled ?? 0, unitPrice, 0)
  const percentComplete = progress
    ? roundPercentComplete(currentWorksheetProgressPercentComplete(progress))
    : 0

  const unitName = worksheetLineItem.unitName

  if (isMobileLayout) {
    // Note: We don't include the pre-Siteline billing column on small screens
    const rows = [
      // Description row
      {
        type: SpreadsheetRowType.DEFAULT,
        id: `${worksheetLineItem.id}${MOBILE_DESCRIPTION_ROW_ID_SUFFIX}`,
        cells: [
          makeDataCell(worksheetLineItem.code, {
            cellBorderOverrides: { right: `1px solid ${colors.grey30}`, bottom: 'none' },
            backgroundColor: 'none',
          }),
          makeDataCell(worksheetLineItem.name, {
            colSpan: numColumns - 1,
            ...(onDelete && {
              rightContent: {
                content: (
                  <SpreadsheetRowDeleteIcon onDelete={() => onDelete(worksheetLineItem.id)} />
                ),
                dependencies: [onDelete],
              },
            }),
          }),
        ],
      },
      // Units contracted row
      {
        type: SpreadsheetRowType.DEFAULT,
        id: `${worksheetLineItem.id}${MOBILE_UNITS_CONTRACTED_ROW_ID_SUFFIX}`,
        cells: [
          makeDataCell('', {
            cellBorderOverrides: {
              right: `1px solid ${colors.grey30}`,
              bottom: 'none',
            },
            backgroundColor: 'none',
          }),
          makeDataCell(
            unitName
              ? t('projects.subcontractors.pay_app.invoice.units_contracted_unit_name', {
                  unitName,
                })
              : t('projects.subcontractors.pay_app.invoice.headers.units_contracted'),
            {
              color: 'grey50',
              colSpan: numColumns - 2,
            }
          ),
          makeDataCell(unitsContracted, { isEditable: false }),
        ],
      },
    ]

    if (includeProgressColumns) {
      rows.push(
        // Percent complete row
        {
          type: SpreadsheetRowType.DEFAULT,
          id: `${worksheetLineItem.id}${MOBILE_PERCENT_COMPLETE_ROW_ID_SUFFIX}`,
          cells: [
            makeDataCell('', {
              cellBorderOverrides: {
                right: `1px solid ${colors.grey30}`,
                bottom: 'none',
              },
              backgroundColor: 'none',
            }),
            makeDataCell(t('projects.subcontractors.pay_app.invoice.headers.percent_complete'), {
              color: 'grey50',
              bold: true,
              colSpan: numColumns - 2,
            }),
            makeDataCell(percentComplete, {
              validate: (value: SpreadsheetValue) => validatePercentComplete(value, progress, t),
              // Stacking each of these cells in this way causes our percent cell to land in a number column,
              // so we must override it with a percent data type
              dataTypeOverride: { dataType: SpreadsheetDataType.PERCENT, fixedDecimals: 0 },
            }),
          ],
        },
        // Current units row
        {
          type: SpreadsheetRowType.DEFAULT,
          id: `${worksheetLineItem.id}${MOBILE_UNITS_BILLED_ROW_ID_SUFFIX}`,
          cells: [
            makeDataCell('', {
              cellBorderOverrides: {
                right: `1px solid ${colors.grey30}`,
                bottom: `2px solid ${colors.grey30}`,
              },
              backgroundColor: 'none',
            }),
            makeDataCell(t('projects.subcontractors.pay_app.invoice.headers.current_quantity'), {
              color: 'grey50',
              colSpan: numColumns - 2,
              bold: true,
              cellBorderOverrides: {
                bottom: `2px solid ${colors.grey30}`,
              },
            }),
            makeDataCell(currentQuantity, {
              cellBorderOverrides: {
                bottom: `2px solid ${colors.grey30}`,
              },
              validate: (value: SpreadsheetValue) => validateUnitsBilled(value, progress, t),
            }),
          ],
        }
      )

      return rows
    }
  }

  const cells = [
    // Number
    makeDataCell(worksheetLineItem.code),
    // Description
    makeDataCell(worksheetLineItem.name, {
      ...(onDelete && {
        rightContent: {
          content: <SpreadsheetRowDeleteIcon onDelete={() => onDelete(worksheetLineItem.id)} />,
          dependencies: [onDelete],
        },
      }),
    }),
  ]

  if (!isSidebar) {
    cells.push(
      // Unit name
      makeDataCell(worksheetLineItem.unitName ?? '')
    )
  }

  cells.push(
    // Number of units contracted
    makeDataCell(unitsContracted, {
      // Check that the total progress billed on this worksheet item does not exceed the new
      // total value
      validate: (value) => {
        const newTotalValue = Number(value) * unitPrice
        const doesProgressExceedLineItemTotalValue = !isNewBilledInRange(
          centsToDollars(worksheetLineItem.billedToDate),
          newTotalValue
        )
        if (doesProgressExceedLineItemTotalValue) {
          return { type: 'error', message: t(`${i18nBase}.billed_to_date_too_high`) }
        }
        const { exceedsTotalValue: previousWorksheetTotalValueExceededSovLineItem } =
          doesWorksheetExceedTotalValue({
            sovLineItem,
            currentWorksheetLineItem: null,
            toWorksheetLineItemValue: null,
            toSovLineItemValue: null,
          })
        const { exceedsTotalValue: doesWorksheetTotalValueExceedSovLineItemTotalValue } =
          doesWorksheetExceedTotalValue({
            sovLineItem,
            currentWorksheetLineItem: worksheetLineItem,
            toWorksheetLineItemValue: newTotalValue,
            toSovLineItemValue: null,
          })
        // Make the user confirm this update if the new sum of worksheet line items exceeds the sov
        // line item total value. If the sum of worksheet items was already greater that the sov line
        // item, don't warn.
        if (
          !previousWorksheetTotalValueExceededSovLineItem &&
          doesWorksheetTotalValueExceedSovLineItemTotalValue
        ) {
          const formattedTotalValue = formatCentsToDollars(sovLineItem.latestTotalValue, true)
          return {
            type: 'confirm',
            title: t(`${i18nBase}.worksheet_total_exceeds_confirm_title`),
            details: t(`${i18nBase}.worksheet_total_exceeds_confirm_details`, {
              totalValue: formattedTotalValue,
            }),
          }
        }
        return null
      },
    })
  )

  if (includePreSitelineBillingColumn) {
    cells.push(
      // Pre-Siteline billing
      makeDataCell(centsToDollars(worksheetLineItem.preSitelineBilled ?? 0), {
        // Check that the total progress billed on this worksheet item, with the new pre-Siteline
        // billing included, does not exceed the total value of the line item
        validate: (value) => {
          const oldPreSitelineBilling = worksheetLineItem.preSitelineBilled ?? 0
          const newBilledToDate =
            worksheetLineItem.billedToDate + (dollarsToCents(Number(value)) - oldPreSitelineBilling)
          if (
            !isNewBilledInRange(
              centsToDollars(newBilledToDate),
              centsToDollars(worksheetLineItem.totalValue)
            )
          ) {
            return { type: 'error', message: t(`${i18nBase}.billed_to_date_too_high`) }
          }
          return null
        },
      })
    )
  }
  if (includeProgressColumns) {
    cells.push(
      // Percent complete
      makeDataCell(percentComplete, {
        validate: (value: SpreadsheetValue) => validatePercentComplete(value, progress, t),
      }),
      // Number of units billed
      makeDataCell(currentQuantity, {
        validate: (value: SpreadsheetValue) => validateUnitsBilled(value, progress, t),
      })
    )
  }

  return [
    {
      type: SpreadsheetRowType.DEFAULT,
      id: worksheetLineItem.id,
      cells,
      // Dragging is allowed for all worksheet line items, as long as edit mode is enabled and
      // the user is in the right view for editing (both of which are handled by the field invoice
      // component)
      allowDragging: true,
    },
  ]
}

/**
 * Returns a `SpreadsheetRow` component for the invoice worksheet footer on both the field tool
 * and invoice sidesheet. Displays the total percent complete for an invoice line item. This
 * supports viewing the invoice sidesheet (with scheduled value, with or without pre-siteline billing).
 */
export function getFieldGuestUnitPriceInvoiceWorksheetFooterRow({
  progress,
  numColumns,
  includePreSitelineBillingColumn,
  sovLineItem,
  isMobileLayout,
}: {
  progress: SovLineItemProgressProperties | null
  numColumns: number
  includePreSitelineBillingColumn: boolean
  sovLineItem: EditingWorksheetSovLineItem
  isMobileLayout: boolean
}): SpreadsheetRow | null {
  const includeProgressColumns = progress !== null

  if (isMobileLayout) {
    return null
  }

  const cells: SpreadsheetCell[] = []

  // Push in total units contracted column
  const totalUnits = _.sumBy(sovLineItem.worksheetLineItems, (worksheetLineItem) =>
    safeDivide(worksheetLineItem.totalValue, worksheetLineItem.unitPrice ?? 0, 0)
  )
  let totalUnitsColSpan = numColumns
  if (includePreSitelineBillingColumn) {
    totalUnitsColSpan -= 1
  }
  if (includeProgressColumns) {
    totalUnitsColSpan -= 2
  }

  cells.push(
    makeDataCell('', {
      colSpan: totalUnitsColSpan,
      rightContent: {
        content: (
          <SitelineText variant="body2" bold>
            {totalUnits.toFixed(NUM_FIXED_UNIT_DECIMALS)}
          </SitelineText>
        ),
        dependencies: [totalUnits],
      },
    })
  )

  // Push in pre siteline billing column (if the project has pre siteline billing + not field guest)
  if (includePreSitelineBillingColumn) {
    const sumOfWorksheetPreSitelineBillingTotals = _.sumBy(
      sovLineItem.worksheetLineItems,
      (worksheetLineItem) => worksheetLineItem.preSitelineBilled ?? 0
    )
    const preSitelineBilledDollars = centsToDollars(sumOfWorksheetPreSitelineBillingTotals)
    const colSpan = 1
    cells.push(
      makeDataCell(preSitelineBilledDollars, {
        colSpan,
        bold: true,
      })
    )
  }

  // Push in percent complete column
  if (includeProgressColumns) {
    const sumOfWorksheetLineItemTotals = _.sumBy(
      sovLineItem.worksheetLineItems,
      (worksheetLineItem) => worksheetLineItem.totalValue
    )
    const totalBilled = progress.progressBilled + progress.previousBilled
    const percentComplete =
      sumOfWorksheetLineItemTotals === 0
        ? 0
        : decimalToPercent(safeDivide(totalBilled, sumOfWorksheetLineItemTotals, 0), 0)
    const roundedPercentComplete = roundPercentComplete(percentComplete)
    cells.push(
      makeDataCell(roundedPercentComplete, {
        colSpan: 1,
        bold: true,
      })
    )

    // Push in number of units billed column
    const currentQuantity = safeDivide(progress.progressBilled, sovLineItem.unitPrice ?? 0, 0)
    cells.push(
      makeDataCell(currentQuantity, {
        colSpan: 1,
        bold: true,
      })
    )
  }

  return {
    type: SpreadsheetRowType.FOOTER,
    id: `${sovLineItem.id}-worksheet-footer`,
    cells,
    allowDragging: false,
    isNonEditableRow: true,
  }
}
