import _ from 'lodash'
import { useEffect, useState } from 'react'
import { pdfTypes } from 'siteline-common-all'
import { generatePdfWithMetadata } from 'siteline-common-web'
import {
  PdfPreviewDialog,
  PdfPreviewDialogProps,
} from '../../../common/components/Pdf/PdfPreviewDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { VariantAndVersion } from '../../../common/util/Forms'

interface FormTemplatePreviewDialogProps
  extends Omit<PdfPreviewDialogProps, 'file' | 'metadata' | 'loading' | 'emptyState'> {
  formTemplates: VariantAndVersion[]
}

/** Dialog for previewing form templates */
export function FormTemplatePreviewDialog({
  formTemplates,
  ...props
}: FormTemplatePreviewDialogProps) {
  const [pdf, setPdf] = useState<Blob | null>(null)
  const [metadata, setMetadata] = useState<pdfTypes.PageMetadata[]>()
  const { contract } = useProjectContext()

  // Generate the PDF to preview all the default forms
  useEffect(() => {
    if (!props.open) {
      return
    }
    async function generateFormsPdf() {
      if (formTemplates.length === 0) {
        return
      }
      const formsPdf = await generatePdfWithMetadata({
        type: 'templatesPreview',
        formTemplateVersionIds: _.uniq(formTemplates.map((template) => template.versionId)),
        formTemplateVariantIds: _.uniq(formTemplates.map((template) => template.variantId)),
        contractId: contract?.id,
      })
      setPdf(formsPdf.blob)
      setMetadata(formsPdf.metadata)
    }

    generateFormsPdf()
  }, [formTemplates, contract?.id, props.open])

  return <PdfPreviewDialog {...props} file={pdf} metadata={metadata} loading={!pdf} />
}
