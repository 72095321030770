import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import { useLocation } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { segmentApiHost, segmentCdnUrl, segmentKey } from './common/config/constants'
import { useGetCurrentUserQuery } from './common/graphql/apollo-operations'

export class Segment {
  static analytics: Analytics | undefined

  /**
   * Initializes Segment with the correct environment ID. By default, development won't send analytics
   * events to Segment. In order to turn this on and test locally, copy the segmentKey from
   * config.staging into config.development.
   */
  public static initializeAnalytics() {
    if (!segmentKey.length) {
      return
    }

    AnalyticsBrowser.load(
      {
        writeKey: segmentKey,
        cdnURL: segmentCdnUrl,
      },
      {
        obfuscate: true,
        integrations: {
          'Segment.io': {
            apiHost: segmentApiHost,
            protocol: 'https',
          },
        },
      }
    ).then(([response]) => {
      Segment.analytics = response
    })
  }
}

/**
 * Taps into the React Router to log a page view anytime the location changes.
 */
export function TrackPageViews() {
  const pathname = useLocation({ select: (location) => location.pathname })

  useEffect(() => {
    Segment.analytics?.page(pathname)
  }, [pathname])

  return <></>
}

export type IdentifyUser = {
  name: string
  email: string
  jobTitle: string | null
  companyName?: string
  company?: {
    id: string
    name: string
  }
}

/**
 * Identifies the current user and assigns to company group.
 */
export function IdentifyUser() {
  const { data } = useGetCurrentUserQuery()
  const [identified, setIdentified] = useState(false)

  useEffect(() => {
    const user = data?.currentUser
    if (!user || identified) {
      return
    }

    const traits: IdentifyUser = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      jobTitle: user.jobTitle,
    }

    if (user.companies.length === 1) {
      const company = user.companies[0]
      traits.companyName = company.name
      traits.company = {
        id: company.id,
        name: company.name,
      }
    }

    Segment.analytics?.identify(user.id, traits)

    for (const company of user.companies) {
      Segment.analytics?.group(company.id, {
        name: company.name,
        type: company.type,
        phoneNumber: company.phoneNumber,
      })
    }

    setIdentified(true)
  }, [data, identified])

  return <></>
}
