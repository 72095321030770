import { useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast(() => ({
  root: {
    backgroundColor: 'transparent',
  },
}))

interface SpacerProps {
  maxHeight?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  minWidth?: number | string
}

/**
 * Basic styled component used to space out to other components.
 * When maxHeight & maxWidth props are omitted, defaults to filling all remaining space.
 * When minHeight & minWidth props are omitted, will shrink to 0px before content overflows,
 * wraps, or scrolls.
 *
 * @example
 * <div style={{display: 'flex', flexDirection: 'row'}}>
 *    <div>This should hug the left</div>
 *      <Spacer />
 *    <div>This should hug the right</div>
 * </div>
 */
export function Spacer({ maxHeight, maxWidth, minHeight, minWidth }: SpacerProps) {
  const classes = useStyles()

  const styles = useMemo(
    () => ({
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      flex: 1,
    }),
    [maxHeight, maxWidth, minHeight, minWidth]
  )
  return <div className={classes.root} style={styles} />
}
