import { gql } from '@apollo/client'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { Loader } from '../components/Loader'
import {
  GetContractForProjectContextQuery,
  useGetContractForProjectContextQuery,
} from '../graphql/apollo-operations'
import * as fragments from '../graphql/Fragments'
import { isContractActive } from '../util/Project'
import { useMultiCompanyContext } from './CompanyContext'

gql`
  query getContractForProjectContext($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      percentComplete
      timeZone
      company {
        ...CompanyProperties
        metadata {
          monthlyCreatePayAppReminderDate
        }
      }
      lienWaiverTemplates {
        id
      }
      lowerTierLienWaiverTemplates {
        id
      }
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      project {
        id
        name
        projectNumber
      }
      payApps {
        id
      }
      defaultTaxGroup {
        ...TaxGroupProperties
      }
    }
  }
  ${fragments.minimalContract}
  ${fragments.companyContact}
  ${fragments.onboardedProjectContractStatus}
  ${fragments.taxGroup}
`

export type ContractForProjectContext = GetContractForProjectContextQuery['contractByProjectId']

interface ProjectContextData {
  id: string
  projectNumber: string
  name: string
  timeZone: string
  isContractActive: boolean
  contract?: ContractForProjectContext
}

const ProjectContext = createContext<ProjectContextData>({
  id: '',
  projectNumber: '',
  timeZone: '',
  name: '',
  isContractActive: false,
})

interface ProjectProviderProps {
  projectId: string
  children: ReactNode
}

export function ProjectProvider({ projectId, children }: ProjectProviderProps) {
  const { companyId, onViewingWrongCompany } = useMultiCompanyContext()
  const { data: contractData } = useGetContractForProjectContextQuery({
    // Do NOT include `companyId` in this query. We may be in the context of the wrong company
    // and need to switch company contexts, so we intentionally fetch the contract without the
    // company param – the API will find the right contract based on all the user's companies.
    variables: { input: { projectId } },
  })
  const contract = contractData?.contractByProjectId

  const isViewingProjectWithoutAccess = contract?.company.id && contract.company.id !== companyId

  // Notify the company context that we are within the context of a contract that belongs to a
  // certain company. If the contract belongs to a different company than the current company
  // view, we'll ask the user whether to switch companies or navigate away.
  useEffect(() => {
    if (isViewingProjectWithoutAccess) {
      onViewingWrongCompany({ companyId: contract.company.id, pageType: 'project' })
    }
  }, [contract?.company.id, isViewingProjectWithoutAccess, onViewingWrongCompany])

  if (!contract || isViewingProjectWithoutAccess) {
    return <Loader />
  }

  const projectContextData: ProjectContextData = {
    id: projectId,
    projectNumber: contract.project.projectNumber,
    name: contract.project.name,
    timeZone: contract.timeZone,
    isContractActive: isContractActive(contract.status),
    contract,
  }

  return <ProjectContext.Provider value={projectContextData}>{children}</ProjectContext.Provider>
}

/**
 * The Project Context is used to access a TimeZone based on a specified project
 */
export function useProjectContext() {
  return useContext(ProjectContext)
}
