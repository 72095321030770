import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingForecastMonth,
  DueToType,
  OPT_OUT_FORMAT,
  PayAppForSorting,
  sortPayAppsByBillingEnd,
} from 'siteline-common-all'
import { evictWithGc, getPayAppMonth, useSitelineSnackbar } from 'siteline-common-web'
import { apolloClient } from '../../client'
import { ContractForPayApps, PayAppForPayApps } from '../../components/billing/PayAppDetails'
import {
  ContractForProjectHome,
  PayAppForProjectHome,
} from '../../components/billing/home/ProjectHome'
import { ContractForMarkAsPaid } from '../../components/billing/paid/MarkAsPaidButton'
import { ActiveOrArchivedContractForProjectList } from '../../components/project-home/ProjectList'
import { ContractForFullyBilledContracts } from '../../components/project-home/ProjectListSwitcher'
import { ContractForOnboardingProjects } from '../../components/reporting/Reporting.lib'
import { useSitelineConfirmation } from '../components/SitelineConfirmation'
import { ConfirmationType } from '../components/SitelineConfirmationDialog'
import { useMultiCompanyContext } from '../contexts/CompanyContext'
import { useUserContext } from '../contexts/UserContext'
import * as fragments from '../graphql/Fragments'
import {
  BillingType,
  CompanyProjectMetadata,
  CompanyProperties,
  ContractStatus,
  GetContractForProjectContextDocument,
  MinimalContractProperties,
  MinimalProjectProperties,
  useArchiveContractMutation,
  useUnarchiveContractMutation,
} from '../graphql/apollo-operations'
import { requiresFinalLienWaiver } from './LienWaiver'
import { safeLocalStorage } from './SafeLocalStorage'

/**
 * Gets the most recent pay-app by billing period, as in the pay-app that has the newest billingStart.
 * This does not take current time into account.
 *
 * @param project The project we want to get the latest pay app for.
 */
export const getMostRecentPayApp = <T extends PayAppForSorting>(contract: {
  timeZone: string
  payApps: readonly T[]
}): T | undefined => {
  const sorted = sortPayAppsByBillingEnd([...contract.payApps], 'desc', contract.timeZone)
  return _.first(sorted)
}

/**
 * Gets the pay app for a project given a month.
 * @param project The project we want to get a pay app for.
 * @param month The month of the pay app that we're looking for. This is represented as a generic
 * Moment object and we will compare the payApp month to this value to find the right pay app.
 * This is the same logic as used by our email sending service.
 */
export const getPayAppForMonth = <T extends PayAppForSorting>(
  contract: {
    payApps: readonly T[]
    timeZone: string
  },
  month: Moment
): T | undefined => {
  const timeZone = contract.timeZone
  return contract.payApps.find((payApp) => {
    const billingMonth = getPayAppMonth(payApp, timeZone)
    return billingMonth.isSame(month, 'month')
  })
}

export function getPayAppName(
  t: TFunction,
  // Only the relevant pay app properties
  payApp?: { id: string; retentionOnly: boolean; payAppNumber: number; billingType: BillingType }
): string {
  if (!payApp) {
    // If the pay app hasn't loaded yet, don't show a title
    return ''
  }
  if (payApp.billingType === BillingType.QUICK) {
    return t('projects.subcontractors.pay_app.header.quick_bill')
  }
  return payApp.retentionOnly
    ? t('projects.subcontractors.pay_app.header.retention_only_title', {
        num: payApp.payAppNumber,
      })
    : t('projects.subcontractors.pay_app.header.title', {
        num: payApp.payAppNumber,
      })
}

/**
 * Gets the due date for a project.
 * @param project The project we want to get the due date for
 * @param now A reference of time (only used for tests)
 */
export const getPayAppDueToDate = (
  project: Pick<MinimalProjectProperties, 'timeZone' | 'metadata'>,
  now?: Moment
) => {
  const timeZone = project.timeZone
  const nowDate = now ?? moment.tz(timeZone)
  const referenceDate = nowDate.clone()
  const payAppDueOnDayOfMonth = project.metadata.payAppDueOnDayOfMonth
  return referenceDate.date(payAppDueOnDayOfMonth)
}

/** Adjusts the due date for a project based on the user's company role  */
export function adjustDueToDate(
  dueDate: Moment,
  dueTo: DueToType,
  contract?: Pick<MinimalContractProperties, 'daysBeforePayAppDue'>
) {
  const DEFAULT_DAYS_BEFORE_PAY_APP_DUE = 3
  if (dueTo === DueToType.ACCOUNTING) {
    return dueDate.subtract(
      contract?.daysBeforePayAppDue ?? DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
      'days'
    )
  }
  return dueDate
}

/**
 * Get a project by ID from the apollo cache.
 * @param projectId ID of the project to retrieve
 */
export function getProjectFromCache(
  projectId: string | undefined
): MinimalProjectProperties | undefined {
  if (!projectId) {
    return
  }
  try {
    const project = apolloClient.readFragment({
      id: `Project:${projectId}`,
      fragment: fragments.minimalProject,
      fragmentName: 'MinimalProjectProperties',
    })
    return project ?? undefined
  } catch {
    return
  }
}

/**
 * Get a list of all pay apps after the current pay app. Treat concurrent retention-only pay
 * apps as after concurrent progress pay apps.
 *
 * This is meant to mirror the functionality of the same named function in siteline-common.
 */
export function getLaterPayApps(
  contract: ContractForPayApps,
  payAppId: string
): PayAppForPayApps[] {
  const timeZone = contract.timeZone
  const payApps = [...contract.payApps]
  const currentPayApp = payApps.find((payApp) => payApp.id === payAppId)
  if (!currentPayApp) {
    return []
  }
  const sorted = sortPayAppsByBillingEnd(payApps, 'asc', timeZone)
  return _.takeRightWhile(sorted, (otherPayApp) => otherPayApp.id !== payAppId)
}

/**
 * Returns whether or not a project has been opted out of billing for that month. Returns null in
 * the event that we couldn't fetch data from the cache (also to satisfy the TypeScript compiler).
 */
export function isOptedOutForMonth(
  contract: Pick<MinimalContractProperties, 'skippedPayAppMonths'>,
  month: moment.Moment
): boolean | null {
  const formattedMonth = month.format(OPT_OUT_FORMAT)
  return contract.skippedPayAppMonths.includes(formattedMonth)
}

/**
 * If this project only has one pay app and it is a quick bill, returns the pay app.
 * Otherwise returns null.
 */
export function getSingleQuickBillPayApp(contract: {
  payApps: readonly { id: string; billingType: BillingType }[]
}) {
  if (contract.payApps.length !== 1) {
    return null
  }
  const singlePayApp = contract.payApps[0]
  if (singlePayApp.billingType === BillingType.QUICK) {
    return singlePayApp
  }
  return null
}

/** Returns true if a project has an active status */
export function isContractActive(contractStatus: ContractStatus) {
  return contractStatus === ContractStatus.ACTIVE
}

/** Returns the mutation to archive a project, handling all associated cache updates */
export function useArchiveContractMutationWithCache(projectId?: string) {
  return useArchiveContractMutation({
    // Re-fetch the project context as Apollo doesn't seem to properly update the cache
    refetchQueries: projectId
      ? [{ query: GetContractForProjectContextDocument, variables: { input: { projectId } } }]
      : [],
    update(cache, { data }) {
      if (!data) {
        return
      }
      evictWithGc(cache, (evict) => {
        evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'fullyBilledContracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
        evict({ id: 'ROOT_QUERY', fieldName: 'paginatedContracts' })
      })
    },
  })
}

/** Returns the mutation to unarchive a project, handling all associated cache updates */
export function useUnarchiveContractMutationWithCache(projectId?: string) {
  return useUnarchiveContractMutation({
    // Re-fetch the project context as Apollo doesn't seem to properly update the cache
    refetchQueries: projectId
      ? [{ query: GetContractForProjectContextDocument, variables: { input: { projectId } } }]
      : [],
    update(cache, { data }) {
      if (!data) {
        return
      }
      evictWithGc(cache, (evict) => {
        evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'fullyBilledContracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
        evict({ id: 'ROOT_QUERY', fieldName: 'paginatedContracts' })
      })
    },
  })
}

type CompanyProjectMetadataForName = Pick<CompanyProjectMetadata, 'companyName'> & {
  company: Pick<CompanyProperties, 'name'>
}

/** Returns the company name for a project's general contractor, owner, architect, or bond provider */
export function getCompanyName(metadata: CompanyProjectMetadataForName) {
  // Use the name on the project's company metadata if it exists; otherwise, default to the standard
  // company name
  return metadata.companyName ?? metadata.company.name
}

export type SitelineModule = 'billing' | 'compliance' | 'vendors'

type GetRecentProjectsLocalStorageKeyParams = {
  userId: string
  companyId: string
  module: SitelineModule
}

/**
 * Storage key for recent projects in local storage.
 * Projects are stored separately for each module, user, and company.
 */
function getRecentProjectsLocalStorageKey({
  userId,
  companyId,
  module,
}: GetRecentProjectsLocalStorageKeyParams) {
  return `recentProjects-${module}-${userId}-${companyId}`
}

/**
 * Old key for recent projects in local storage. Used for backward compatibility.
 */
function getRecentProjectsOldLocalStorageKey({
  userId,
  module,
}: GetRecentProjectsLocalStorageKeyParams) {
  return `recentProjects-${module}-${userId}`
}

/** Returns the list of projects most recently viewed by the current user */
export function useRecentProjectIds(module: SitelineModule): string[] {
  const { id: userId } = useUserContext()
  const { companyId } = useMultiCompanyContext()
  if (!companyId) {
    return []
  }
  const newKey = getRecentProjectsLocalStorageKey({ userId, companyId, module })
  const oldKey = getRecentProjectsOldLocalStorageKey({ userId, companyId, module })
  const idsStr = safeLocalStorage.getItem(newKey) ?? safeLocalStorage.getItem(oldKey)
  return idsStr ? JSON.parse(idsStr) : []
}

type AddRecentlyViewedProjectIdParams = {
  userId: string
  companyId: string
  projectId: string
  module: SitelineModule
}

/** Adds a project to the list of projects recently viewed in browser storage */
export function addRecentlyViewedProjectId({
  userId,
  companyId,
  projectId,
  module,
}: AddRecentlyViewedProjectIdParams) {
  const newKey = getRecentProjectsLocalStorageKey({ userId, companyId, module })
  const oldKey = getRecentProjectsOldLocalStorageKey({ userId, companyId, module })
  const idsStr = safeLocalStorage.getItem(newKey) ?? safeLocalStorage.getItem(oldKey)
  const prevIds: string[] = idsStr ? JSON.parse(idsStr) : []
  // Only keep the 10 most recent projects so the list doesn't get unmanageably long
  const newIds = [projectId, ...prevIds.filter((id) => id !== projectId)].slice(0, 10)
  safeLocalStorage.setItem(newKey, JSON.stringify(newIds))
}

/**
 * Converts the month viewed on the homepage in the user's local time zone to the time zone
 * of a given contract
 */
export function viewingMonthInTimeZone(monthNoTimeZone: Moment, toTimeZone: string) {
  return moment.tz(toTimeZone).year(monthNoTimeZone.year()).month(monthNoTimeZone.month())
}

/** Returns the name of the general contractor to use for a project */
export function getGeneralContractorName(
  project: Pick<ContractForOnboardingProjects['project'], 'generalContractor'>
): string {
  if (!project.generalContractor) {
    return ''
  }
  return project.generalContractor.companyName ?? project.generalContractor.company.name
}

/** Hook for archiving or unarchiving a contract */
export function useToggleContractArchived() {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [archiveContract] = useArchiveContractMutationWithCache()
  const [unarchiveContract] = useUnarchiveContractMutationWithCache()
  const { confirm } = useSitelineConfirmation()

  const i18nBase = 'project_home'

  return useCallback(
    (
      contract:
        | ActiveOrArchivedContractForProjectList
        | ContractForProjectHome
        | ContractForFullyBilledContracts,
      skipConfirmation?: boolean
    ) => {
      const isActive = isContractActive(contract.status)

      const handleToggleArchived = async () => {
        const status = isActive ? ContractStatus.ARCHIVED : ContractStatus.ACTIVE
        const updatedContract = { ...contract, status }
        try {
          if (isActive) {
            await archiveContract({
              variables: {
                id: contract.id,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                archiveContract: {
                  ...contract.project,
                  contracts: [updatedContract],
                },
              },
            })
          } else {
            await unarchiveContract({
              variables: {
                id: contract.id,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                unarchiveContract: {
                  ...contract.project,
                  contracts: [updatedContract],
                },
              },
            })
          }
          snackbar.showSuccess(
            isActive
              ? t(`${i18nBase}.confirm_archive.archived_project`)
              : t(`${i18nBase}.confirm_unarchive.restored_project`)
          )
        } catch (err) {
          snackbar.showError(err.message)
        }
      }

      if (skipConfirmation) {
        handleToggleArchived()
        return
      }

      const title = isActive
        ? t(`${i18nBase}.confirm_archive.title`, { projectName: contract.project.name })
        : t(`${i18nBase}.confirm_unarchive.title`, { projectName: contract.project.name })
      let details = isActive
        ? t(`${i18nBase}.confirm_archive.body`)
        : t(`${i18nBase}.confirm_unarchive.body`)
      let confirmationType: ConfirmationType = 'confirm'

      // Indicate that the project is not fully billed if applicable
      const isProgressComplete = contract.percentComplete === 1
      const isRetentionComplete = contract.sov?.totalRetention === 0
      const isContractFullyBilled = isProgressComplete && isRetentionComplete

      if (!isContractFullyBilled && isActive) {
        details = t(`${i18nBase}.confirm_archive.outstanding_progress_body`)
        confirmationType = 'delete'
      }

      confirm({
        title,
        details,
        confirmationType,
        confirmLabel: t('common.actions.confirm'),
        callback: async (confirmed: boolean) => {
          if (confirmed) {
            handleToggleArchived()
          }
        },
      })
    },
    [archiveContract, confirm, snackbar, t, unarchiveContract]
  )
}

/**
 * Returns a list of months to include on a project's monthly billing chart. The months shown should
 * be the union of months the project has billed and months on the contract's billing forecast.
 */
export function getMonthlyBillingSummaryMonths(
  forecastMonths: BillingForecastMonth[],
  sortedPayApps: Pick<PayAppForProjectHome, 'billingEnd'>[],
  timeZone: string
) {
  // Find the range of months to include from the billing forecast
  const projectedStart = _.first(forecastMonths)?.month
  const projectedEnd = _.last(forecastMonths)?.month

  // Find the range of months to include from the contract's pay apps
  const firstPayApp = _.first(sortedPayApps)
  const payAppStart = firstPayApp ? moment.tz(firstPayApp.billingEnd, timeZone) : null
  const lastPayApp = _.last(sortedPayApps)
  const payAppEnd = lastPayApp ? moment.tz(lastPayApp.billingEnd, timeZone) : null

  // Use the earliest and latest month from the ranges above
  const firstMonth = _.min(_.compact([projectedStart, payAppStart]))
  const lastMonth = _.max(_.compact([projectedEnd, payAppEnd]))
  if (!firstMonth || !lastMonth) {
    return []
  }

  // Create a consecutive list of months from the starting month to the end month
  const months: Moment[] = []
  const month = firstMonth.clone()
  while (month.isSameOrBefore(lastMonth, 'month')) {
    months.push(month.clone().startOf('month'))
    month.add(1, 'month')
  }

  return months
}

export function doesContractHaveUnconditionalProgress(contract: ContractForMarkAsPaid) {
  return {
    hasUnconditionalTemplate: !_.isNil(contract.lienWaiverTemplates?.unconditionalProgressVariant),
    isProcessingFormTemplate:
      contract.lienWaiverTemplates?.unconditionalProgressVariant?.template.isCustomerReady ===
      false,
  }
}

export function doesContractHaveUnconditionalFinal(contract: ContractForMarkAsPaid) {
  return {
    hasUnconditionalTemplate: !_.isNil(contract.lienWaiverTemplates?.unconditionalFinalVariant),
    isProcessingFormTemplate:
      contract.lienWaiverTemplates?.unconditionalFinalVariant?.template.isCustomerReady === false,
  }
}

/**
 * Checks that the contract has both progress and final unconditionals, and that neither is
 * being processed.
 */
export function doesContractHaveBothUnconditionalTemplates(contract: ContractForMarkAsPaid) {
  const {
    hasUnconditionalTemplate: hasUnconditionalProgressTemplate,
    isProcessingFormTemplate: isProcessingUnconditionalProgressTemplate,
  } = doesContractHaveUnconditionalProgress(contract)
  const {
    hasUnconditionalTemplate: hasUnconditionalFinalTemplate,
    isProcessingFormTemplate: isProcessingUnconditionalFinalTemplate,
  } = doesContractHaveUnconditionalFinal(contract)
  return (
    hasUnconditionalProgressTemplate &&
    hasUnconditionalFinalTemplate &&
    !isProcessingUnconditionalProgressTemplate &&
    !isProcessingUnconditionalFinalTemplate
  )
}

export function requiresUnconditionalLienWaiver(
  payApp: Pick<PayAppForProjectHome, 'balanceToFinish' | 'totalRetention'>,
  contract: ContractForMarkAsPaid
) {
  const requiresFinalPayApp = requiresFinalLienWaiver(payApp, contract.sov)
  return requiresFinalPayApp
    ? doesContractHaveUnconditionalFinal(contract)
    : doesContractHaveUnconditionalProgress(contract)
}

// Px width breakpoint that defines styling on the project list header;
// smaller screen sizes will wrap to 2 lines
export const PROJECT_LIST_LINE_WRAP_WIDTH = 1000
