import { FormControlLabel } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidEmail, parsePhoneNumber, serializePhoneNumber } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar, useToggle } from 'siteline-common-web'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { CompanyProperties } from '../../../common/graphql/apollo-operations'
import { makeEmptyContact } from '../../../common/util/Contact'
import { findCloselyMatchingEmail } from '../../../common/util/Email'
import { formatPhoneNumber } from '../../../common/util/PhoneNumber'
import { Contact, NewContactForm } from '../submit-dialog/NewContactForm'

const i18nBase = 'projects.subcontractors.settings.new_contact_dialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  contactForm: {
    margin: theme.spacing(1, 0, 3),
  },
}))

type NewContactDialogProps = {
  onAddOrEditContact: (contact: Contact) => Promise<void>
  availableContacts: Contact[]
  initialContact?: Contact
  company?: Pick<CompanyProperties, 'id' | 'name'>
  open: boolean
  onClose: () => void
  subtitle: string
  existingDomains?: string[]
}

/** Dialog that wraps NewContactForm to add contacts that can be persisted on a contract */
export function NewContactDialog({
  onAddOrEditContact,
  availableContacts,
  initialContact,
  company,
  open,
  onClose,
  subtitle,
  existingDomains,
}: NewContactDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  // This is a callback because every time its referenced, a new id should be generated
  // for the emptyContact
  const generateDefaultContact = useCallback(() => {
    if (!initialContact) {
      return makeEmptyContact(company)
    }
    const phoneNumber = initialContact.phoneNumber && formatPhoneNumber(initialContact.phoneNumber)
    return {
      ...initialContact,
      phoneNumber,
    }
  }, [company, initialContact])

  const [editingContact, setEditingContact] = useState<Contact>(generateDefaultContact())
  const [emailSuggestion, setEmailSuggestion] = useState<string | null>(null)
  const [isSubmittingContact, handleStartSubmitting, handleFinishSubmitting] = useToggle()
  const [shouldShowInvalidEmailWarning, setShouldShowInvalidEmailWarning] = useState<boolean>(false)
  const [shouldShowInvalidPhoneNumberWarning, setShouldShowInvalidPhoneNumberWarning] =
    useState<boolean>(false)
  const [addAnotherRecipient, setAddAnotherRecipient] = useState<boolean>(false)

  // Clear the invalid email warning if the email changes
  useEffect(() => {
    setShouldShowInvalidEmailWarning(false)
  }, [editingContact.email])

  // Clear the invalid phone number warning if the phone number changes
  useEffect(() => {
    setShouldShowInvalidPhoneNumberWarning(false)
  }, [editingContact.phoneNumber])

  // If the initial contact changes, update the contact being edited in the dialog
  useEffect(() => {
    setEditingContact(generateDefaultContact())
  }, [generateDefaultContact])

  // Reset the email suggestion banner when the dialog is closed
  useEffect(() => {
    if (!open) {
      setEmailSuggestion(null)
    }
  }, [open])

  const isSubmitDisabled =
    editingContact.email === '' ||
    editingContact.fullName === '' ||
    editingContact.companyName === ''

  const resetDialog = useCallback(() => {
    setEditingContact(generateDefaultContact())
    setShouldShowInvalidEmailWarning(false)
    setShouldShowInvalidPhoneNumberWarning(false)
  }, [generateDefaultContact])

  const dialogTransitionProps = useMemo(
    () => ({
      onEnter: resetDialog,
      onExited: resetDialog,
    }),
    [resetDialog]
  )

  const handleAddOrEditRecipient = useCallback(async () => {
    const isEmailValid = isValidEmail(editingContact.email)
    const parsedPhoneNumber =
      !!editingContact.phoneNumber && parsePhoneNumber(editingContact.phoneNumber)
    const isPhoneNumberValid = !editingContact.phoneNumber || !!parsedPhoneNumber
    if (!isEmailValid) {
      setShouldShowInvalidEmailWarning(true)
    }
    if (!isPhoneNumberValid) {
      setShouldShowInvalidPhoneNumberWarning(true)
    }
    if (isEmailValid && isPhoneNumberValid) {
      handleStartSubmitting()
      try {
        await onAddOrEditContact({
          ...editingContact,
          phoneNumber: parsedPhoneNumber ? serializePhoneNumber(parsedPhoneNumber) : null,
        })
        setEditingContact(makeEmptyContact(company))
        if (!addAnotherRecipient) {
          onClose()
        }
      } catch (error) {
        snackbar.showError(error.message)
      }
      handleFinishSubmitting()
    }
  }, [
    editingContact,
    handleStartSubmitting,
    handleFinishSubmitting,
    onAddOrEditContact,
    company,
    addAnotherRecipient,
    onClose,
    snackbar,
  ])

  const handleEmailSuggestion = useCallback(() => {
    if (existingDomains === undefined) {
      return
    }
    const emailMatch = findCloselyMatchingEmail({
      email: editingContact.email,
      validDomains: existingDomains,
    })
    if (emailMatch !== null) {
      setEmailSuggestion(emailMatch)
    }
  }, [editingContact.email, existingDomains])

  const emailSuggestionProps = useMemo(
    () => ({
      suggestedEmail: emailSuggestion,
      onSuggestEmail: handleEmailSuggestion,
      onClearEmailSuggestion: () => setEmailSuggestion(null),
      onAcceptEmailSuggestion: () => {
        if (emailSuggestion === null) {
          return
        }
        setEditingContact({ ...editingContact, email: emailSuggestion })
        setEmailSuggestion(null)
      },
    }),
    [editingContact, emailSuggestion, handleEmailSuggestion]
  )

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      title={t(`${i18nBase}.add_contact`)}
      subtitle={subtitle}
      subtitleVariant="body1"
      onSubmit={handleAddOrEditRecipient}
      submitLabel={initialContact ? t('common.actions.save') : t('common.actions.add')}
      TransitionProps={dialogTransitionProps}
      disableSubmit={isSubmitDisabled}
      submitting={isSubmittingContact}
      maxWidth="sm"
      subscript={
        <FormControlLabel
          control={
            <SitelineCheckbox
              name={t(`${i18nBase}.add_another_contact`)}
              value={addAnotherRecipient}
              checked={addAnotherRecipient}
              onChange={(ev, checked) => setAddAnotherRecipient(checked)}
            />
          }
          label={t(`${i18nBase}.add_another_contact`)}
        />
      }
    >
      <div className={classes.contactForm}>
        <NewContactForm
          contact={editingContact}
          onContactChange={setEditingContact}
          companyContacts={availableContacts}
          allowNewCompanies
          showContactOptionalFields
          showInvalidEmailWarning={shouldShowInvalidEmailWarning}
          showInvalidPhoneNumberWarning={shouldShowInvalidPhoneNumberWarning}
          emailSuggestionProps={existingDomains ? emailSuggestionProps : undefined}
        />
      </div>
    </SitelineDialog>
  )
}
