import { TFunction } from 'i18next'
import _ from 'lodash'
import {
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  formatCentsToDollars,
  safeDivide,
} from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import {
  SpreadsheetCell,
  SpreadsheetColumn,
  SpreadsheetDataType,
  SpreadsheetRow,
  SpreadsheetRowType,
  SpreadsheetValue,
  makeDataCell,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  SovLineItemProgressProperties,
  WorksheetLineItemProgressProperties,
} from '../graphql/apollo-operations'
import {
  BaseFieldGuestInvoiceColumn,
  EditingWorksheetLineItem,
  EditingWorksheetSovLineItem,
  doesWorksheetExceedTotalValue,
  validatePercentComplete,
} from './BillingWorksheet'
import { SpreadsheetRowDeleteIcon } from './InvoiceRow'
import {
  currentWorksheetProgressPercentComplete,
  isNewBilledInRange,
  roundPercentComplete,
} from './PayApp'

export enum FieldGuestLumpSumInvoiceColumn {
  SCHEDULED_VALUE = 'scheduledValue',
}

/** Returns the list of columns to show on the field tool pay app invoice table */
export function getFieldGuestLumpSumInvoiceColumns(
  t: TFunction,
  {
    isMobileLayout,
    editMode,
    canEdit,
    includeProgressColumns,
    includePreSitelineBillingColumn,
    includeScheduledValueColumn,
  }: {
    isMobileLayout: boolean
    editMode?: 'worksheet' | 'progress'
    canEdit: boolean
    includeProgressColumns: boolean
    includePreSitelineBillingColumn: boolean
    includeScheduledValueColumn: boolean
  }
): SpreadsheetColumn[] {
  const headingI18nBase = 'projects.subcontractors.pay_app.invoice.headers'

  const columns: SpreadsheetColumn[] = [
    {
      id: BaseFieldGuestInvoiceColumn.NUMBER,
      heading: t(`${headingI18nBase}.number`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      minWidth: 80,
      maxWidth: 85,
      wordBreak: 'break-all',
    },
    {
      id: BaseFieldGuestInvoiceColumn.NAME,
      heading: t(`${headingI18nBase}.description`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 300,
      minWidth: 300,
    },
  ]

  if (includeScheduledValueColumn && !isMobileLayout) {
    columns.push({
      id: FieldGuestLumpSumInvoiceColumn.SCHEDULED_VALUE,
      heading: t(`${headingI18nBase}.scheduled_value`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    })
  }

  if (includePreSitelineBillingColumn && !isMobileLayout) {
    columns.push({
      id: BaseFieldGuestInvoiceColumn.PRE_SITELINE_BILLING,
      heading: t(`${headingI18nBase}.pre_siteline_billing`),
      isEditable: canEdit && editMode === 'worksheet',
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    })
  }

  if (includeProgressColumns) {
    columns.push({
      id: BaseFieldGuestInvoiceColumn.PERCENT_COMPLETE,
      heading: isMobileLayout ? '' : t(`${headingI18nBase}.percent_complete`),
      isEditable: canEdit && editMode === 'progress',
      dataType: SpreadsheetDataType.PERCENT,
      align: 'right',
    })
  }

  return columns
}

/**
 * Returns a `SpreadsheetRow` component for the invoice line item. For the field guest tool, this is styled
 * to look like a group header row because field tool line items have child "worksheet" line items.
 */
export function getFieldGuestLumpSumInvoiceLineItemRow({
  sovLineItem,
  numColumns,
}: {
  sovLineItem: EditingWorksheetSovLineItem
  numColumns: number
}): SpreadsheetRow {
  const cells = [
    // Number
    makeDataCell(''),
    // Name
    makeDataCell(sovLineItem.name, { colSpan: numColumns - 1 }),
  ]

  return {
    id: sovLineItem.id,
    type: SpreadsheetRowType.DEFAULT,
    cells,
    isGroupHeaderRow: true,
    isNonEditableRow: true,
    isFirstInUngroupedBlock: true,
  }
}

/**
 * Returns a `SpreadsheetRow` component for the invoice *worksheet* line item. For the field guest tool, this is styled
 * to look like a regular line item row because field tool line items have child "worksheet" line items. For mobile
 * screen sizes, we stack cells so that they span an entire row.
 */
export function getFieldGuestLumpSumInvoiceWorksheetRow({
  worksheetLineItem,
  progress,
  numColumns,
  isMobileLayout,
  includePreSitelineBillingColumn,
  includeScheduledValueColumn,
  onDelete,
  t,
  sovLineItem,
}: {
  worksheetLineItem: EditingWorksheetLineItem
  progress: WorksheetLineItemProgressProperties | null
  numColumns: number
  isMobileLayout: boolean
  includePreSitelineBillingColumn: boolean
  includeScheduledValueColumn: boolean
  onDelete?: (lineItemId: string) => void
  t: TFunction
  sovLineItem: EditingWorksheetSovLineItem
}): SpreadsheetRow[] | null {
  const i18nBase = 'projects.subcontractors.sov'
  const includeProgressColumns = progress !== null

  if (isMobileLayout) {
    // Note: We never include the scheduled value or pre-Siteline billing columns on small screens

    const rows = [
      // Description row
      {
        type: SpreadsheetRowType.DEFAULT,
        id: `${worksheetLineItem.id}-name`,
        cells: [
          makeDataCell(worksheetLineItem.code, {
            cellBorderOverrides: { right: `1px solid ${colors.grey30}`, bottom: 'none' },
            backgroundColor: 'none',
          }),
          makeDataCell(worksheetLineItem.name, {
            ...(onDelete && {
              rightContent: {
                content: (
                  <SpreadsheetRowDeleteIcon onDelete={() => onDelete(worksheetLineItem.id)} />
                ),
                dependencies: [onDelete],
              },
            }),
            colSpan: numColumns - 1,
          }),
        ],
      },
    ]

    if (includeProgressColumns) {
      rows.push(
        // % complete row
        {
          type: SpreadsheetRowType.DEFAULT,
          id: `${worksheetLineItem.id}-percentComplete`,
          cells: [
            makeDataCell('', {
              cellBorderOverrides: {
                right: `1px solid ${colors.grey30}`,
                bottom: `2px solid ${colors.grey30}`,
              },
              backgroundColor: 'none',
            }),
            makeDataCell(t('projects.subcontractors.pay_app.invoice.headers.percent_complete'), {
              bold: true,
              color: 'grey50',
              cellBorderOverrides: {
                bottom: `2px solid ${colors.grey30}`,
              },
            }),
            makeDataCell(roundPercentComplete(currentWorksheetProgressPercentComplete(progress)), {
              cellBorderOverrides: {
                bottom: `2px solid ${colors.grey30}`,
              },
              validate: (value: SpreadsheetValue) => validatePercentComplete(value, progress, t),
            }),
          ],
        }
      )
    }

    return rows
  }

  const cells = [
    // Number
    makeDataCell(worksheetLineItem.code),
    // Description
    makeDataCell(worksheetLineItem.name, {
      ...(onDelete && {
        rightContent: {
          content: <SpreadsheetRowDeleteIcon onDelete={() => onDelete(worksheetLineItem.id)} />,
          dependencies: [onDelete],
        },
      }),
    }),
  ]
  if (includeScheduledValueColumn) {
    // Scheduled value
    cells.push(
      makeDataCell(centsToDollars(worksheetLineItem.totalValue), {
        validate: (value) => {
          // Check that the total progress billed on this worksheet item does not exceed the new
          // total value
          const doesProgressExceedLineItemTotalValue = !isNewBilledInRange(
            centsToDollars(worksheetLineItem.billedToDate),
            Number(value)
          )
          if (doesProgressExceedLineItemTotalValue) {
            return { type: 'error' as const, message: t(`${i18nBase}.billed_to_date_too_high`) }
          }
          // Check that the new total value, summed together with the rest of the worksheet total values
          // does not exceed the sov line item total value
          const { exceedsTotalValue: doesWorksheetTotalValueExceedSovLineItemTotalValue } =
            doesWorksheetExceedTotalValue({
              sovLineItem,
              currentWorksheetLineItem: worksheetLineItem,
              toWorksheetLineItemValue: dollarsToCents(Number(value)),
              toSovLineItemValue: null,
            })
          if (doesWorksheetTotalValueExceedSovLineItemTotalValue) {
            const formattedTotalValue = formatCentsToDollars(sovLineItem.latestTotalValue, true)
            return {
              type: 'error' as const,
              message: t(`${i18nBase}.worksheet_total_exceeds`, {
                totalValue: formattedTotalValue,
              }),
            }
          }
          return null
        },
      })
    )
  }
  if (includePreSitelineBillingColumn) {
    cells.push(
      makeDataCell(centsToDollars(worksheetLineItem.preSitelineBilled ?? 0), {
        // Check that the total progress billed on this worksheet item, with the new pre-Siteline
        // billing included, does not exceed the total value of the line item
        validate: (value) => {
          const oldPreSitelineBilling = worksheetLineItem.preSitelineBilled ?? 0
          const newBilledToDate =
            worksheetLineItem.billedToDate + (dollarsToCents(Number(value)) - oldPreSitelineBilling)
          if (
            !isNewBilledInRange(
              centsToDollars(newBilledToDate),
              centsToDollars(worksheetLineItem.totalValue)
            )
          ) {
            return { type: 'error', message: t(`${i18nBase}.billed_to_date_too_high`) }
          }
          return null
        },
      })
    )
  }
  if (includeProgressColumns) {
    cells.push(
      makeDataCell(roundPercentComplete(currentWorksheetProgressPercentComplete(progress)), {
        validate: (value: SpreadsheetValue) => validatePercentComplete(value, progress, t),
      })
    )
  }

  return [
    {
      type: SpreadsheetRowType.DEFAULT,
      id: worksheetLineItem.id,
      cells,
      // The field invoice component handles enabling/disabling reordering based on the current
      // edit mode and user permissions. This field enables dragging for all worksheet line
      // items as long as reordering is enabled for the worksheet as a whole.
      allowDragging: true,
    },
  ]
}

/**
 * Returns a `SpreadsheetRow` component for the invoice worksheet footer on both the field tool
 * and invoice sidesheet. Displays the total percent complete for an invoice line item. This
 * supports viewing the invoice or sov sidesheet (with scheduled value, with or without pre-siteline billing).
 */
export function getFieldGuestLumpSumInvoiceWorksheetFooterRow({
  progress,
  numColumns,
  includePreSitelineBillingColumn,
  includeScheduledValueColumn,
  sovLineItem,
  isMobileLayout,
}: {
  progress: SovLineItemProgressProperties | null
  numColumns: number
  includePreSitelineBillingColumn: boolean
  includeScheduledValueColumn: boolean
  sovLineItem: EditingWorksheetSovLineItem
  isMobileLayout: boolean
}): SpreadsheetRow | null {
  const includeProgressColumns = progress !== null

  if (isMobileLayout) {
    return null
  }

  const cells: SpreadsheetCell[] = []

  const sumOfWorksheetLineItemTotals = _.sumBy(
    sovLineItem.worksheetLineItems,
    (worksheetLineItem) => worksheetLineItem.totalValue
  )

  // Push in scheduled value (if not field guest)
  if (includeScheduledValueColumn) {
    let scheduledValueColSpan = numColumns
    if (includePreSitelineBillingColumn) {
      scheduledValueColSpan -= 1
    }
    if (includeProgressColumns) {
      scheduledValueColSpan -= 1
    }
    const formattedSumOfTotals = formatCentsToDollars(sumOfWorksheetLineItemTotals, true)

    cells.push(
      makeDataCell('', {
        colSpan: scheduledValueColSpan,
        rightContent: {
          content: (
            <SitelineText variant="body2" bold>
              {formattedSumOfTotals}
            </SitelineText>
          ),
          dependencies: [sumOfWorksheetLineItemTotals],
        },
      })
    )
  }

  // Push in pre siteline billing column (if the project has pre siteline billing + not field guest)
  if (includePreSitelineBillingColumn) {
    const sumOfWorksheetPreSitelineBillingTotals = _.sumBy(
      sovLineItem.worksheetLineItems,
      (worksheetLineItem) => worksheetLineItem.preSitelineBilled ?? 0
    )
    const preSitelineBilledDollars = centsToDollars(sumOfWorksheetPreSitelineBillingTotals)
    cells.push(
      makeDataCell(preSitelineBilledDollars, {
        colSpan: 1,
        bold: true,
      })
    )
  }

  // Push in progress columns (if not SOV)
  if (includeProgressColumns) {
    const totalBilled = progress.progressBilled + progress.previousBilled
    const percentComplete =
      sumOfWorksheetLineItemTotals === 0
        ? 0
        : decimalToPercent(safeDivide(totalBilled, sumOfWorksheetLineItemTotals, 0), 0)
    const roundedPercentComplete = roundPercentComplete(percentComplete)
    const formattedPercentComplete = `${roundedPercentComplete}%`
    const shouldMergeFooterCells = !(includePreSitelineBillingColumn || includeScheduledValueColumn)

    // `shouldMergeFooterCells` is true if there is no pre siteline billing column and no scheduled
    // value column. In that case, we're only displaying percent complete and all footer cells
    // can be merged together
    if (shouldMergeFooterCells) {
      cells.push(
        makeDataCell('', {
          colSpan: numColumns,
          rightContent: {
            content: (
              <SitelineText variant="body2" bold>
                {formattedPercentComplete}
              </SitelineText>
            ),
            dependencies: [roundedPercentComplete],
          },
        })
      )
    } else {
      cells.push(
        makeDataCell(roundedPercentComplete, {
          colSpan: 1,
          bold: true,
        })
      )
    }
  }

  return {
    type: SpreadsheetRowType.FOOTER,
    id: `${sovLineItem.id}-worksheet-footer`,
    cells,
    allowDragging: false,
    isNonEditableRow: true,
  }
}
