import { Theme } from '@mui/material/styles'
import { HTMLAttributes, useMemo } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import { ContractForVendorsCopyDialog } from '../../components/vendors/add-vendors/CopyProjectVendorsDialog'
import { ContractWithGc } from '../util/Contact'
import { ComplianceContract } from '../util/LegalRequirement'
import { getGeneralContractorName } from '../util/Project'
import { ContractForForms } from '../util/ProjectOnboarding'
import { ArchivedChip } from './ArchivedChip'
import { SameGcChip } from './SameGcChip'

const useStyles = makeStylesFast((theme: Theme) => ({
  menuOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .projectInfo': {
      color: colors.grey50,
    },
    '& .chips': {
      display: 'flex',
      gap: theme.spacing(1),
    },
  },
}))

interface CopyProjectDropdownOptionProps {
  inputProps: HTMLAttributes<HTMLLIElement>
  currentContract?: ContractWithGc
  contractOption: ComplianceContract | ContractForForms | ContractForVendorsCopyDialog
  includeSameGcChip?: boolean
}

/**
 * List item displayed on dropdown menus of projects to copy from. Used on compliance
 * and billing "copy project" dialogs.
 **/
export function CopyProjectDropdownOption({
  inputProps,
  currentContract,
  contractOption,
  includeSameGcChip = true,
}: CopyProjectDropdownOptionProps) {
  const classes = useStyles()

  const generalContractorName = useMemo(
    () => getGeneralContractorName(contractOption.project),
    [contractOption.project]
  )

  return (
    <li {...inputProps}>
      <div className={classes.menuOption}>
        <div>
          <span>{contractOption.project.name}</span>
          <span className="projectInfo">
            {' '}
            {contractOption.internalProjectNumber ?? contractOption.project.projectNumber}
          </span>
          {generalContractorName && <span className="projectInfo"> {generalContractorName}</span>}
        </div>
        <div className="chips">
          <ArchivedChip contract={contractOption} />
          {includeSameGcChip && (
            <SameGcChip currentContract={currentContract} otherContract={contractOption} />
          )}
        </div>
      </div>
    </li>
  )
}
