import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button, Menu, MenuItem, Popover, PopoverOrigin, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DateView, StaticDatePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import { Moment } from 'moment-timezone'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { localizeDate } from '../../../common/components/DatePickerInput'
import {
  SPREADSHEET_BORDER_PADDING,
  SPREADSHEET_CELL_SIDE_PADDING,
} from '../../../common/components/Spreadsheet/Spreadsheet'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { ChangeOrderDateField, EditChangeOrderDateFn } from './ManageSovRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .verticalAligned': {
      // The date cell text appears to be vertically off-center by just a smidge
      paddingTop: theme.spacing(0.5),
    },
  },
  empty: {
    width: 38,
  },
  button: {
    justifyContent: 'flex-start',
    // Adjust margins and sizing to fill entire cell
    marginLeft: -SPREADSHEET_BORDER_PADDING,
    marginTop: -SPREADSHEET_BORDER_PADDING,
    width: `calc(100% + ${SPREADSHEET_BORDER_PADDING * 2}px)`,
    height: `calc(100% + ${SPREADSHEET_BORDER_PADDING * 2}px)`,
    // Since the column has no padding, match the default cell padding to align the label with
    // the column header
    paddingLeft: SPREADSHEET_CELL_SIDE_PADDING - SPREADSHEET_BORDER_PADDING + 2,
    borderRadius: 0,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: colors.grey50,
    },
  },
  tooltip: {
    padding: theme.spacing(0.5),
    '& .tooltipRow': {
      display: 'flex',
      gap: theme.spacing(1),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
      },
      '& .label': {
        minWidth: 60,
      },
    },
  },
  calendarActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 2),
    '& .removeButton': {
      marginTop: theme.spacing(-4),
    },
  },
  fullSize: {
    width: '100%',
    height: '100%',
    display: 'flex',
    '& .fullWidth': {
      width: '100%',
    },
  },
  effectiveDateMenuItem: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: colors.grey50,
      },
    },
  },
}))

const i18nBase = 'projects.subcontractors.sov.change_order_date'

const DATE_VIEWS: DateView[] = ['day', 'month', 'year']

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }

interface ChangeOrderDatesCellProps {
  approvedAt: Moment | null
  effectiveAt: Moment | null
  onEditDate: EditChangeOrderDateFn | undefined
  isApprovalDateEditable?: boolean
}

/** SOV cell for viewing and editing approval and effective dates for a change order */
export function ChangeOrderDatesCell({
  approvedAt,
  effectiveAt,
  onEditDate,
  isApprovalDateEditable = true,
}: ChangeOrderDatesCellProps) {
  const { t } = useTranslation()
  const { timeZone } = useProjectContext()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [editingField, setEditingField] = useState<ChangeOrderDateField | null>(null)

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setEditingField(null)
  }, [])

  const handleChangeDate = useCallback(
    (date: Moment | null) => {
      if (date === null || !date.isValid() || !editingField || !onEditDate) {
        return
      }
      const dateWithTimezone = localizeDate(date, timeZone)
      onEditDate(editingField, dateWithTimezone)
      handleCloseMenu()
    },
    [editingField, onEditDate, timeZone, handleCloseMenu]
  )

  const handleEditApprovalDate = useCallback(() => setEditingField('changeOrderApprovedAt'), [])
  const handleEditEffectiveDate = useCallback(() => setEditingField('changeOrderEffectiveAt'), [])

  const handleRemoveEffectiveDate = useCallback(() => {
    if (!onEditDate) {
      return
    }
    onEditDate('changeOrderEffectiveAt', null)
    handleCloseMenu()
  }, [handleCloseMenu, onEditDate])

  if (!approvedAt) {
    // If there is no approval date, this is not a change order and we won't show anything
    return null
  }

  const isEditable = onEditDate !== undefined

  return (
    // Make the content full width if the button is clickable so the tap target spans the full cell
    <div className={clsx(classes.root, { [classes.fullSize]: isEditable })}>
      <Tooltip
        arrow
        title={
          <div className={classes.tooltip}>
            <div className="tooltipRow">
              <SitelineText variant="smallText" bold className="label">
                {t(`${i18nBase}.approved`)}
              </SitelineText>
              <SitelineText variant="smallText">{approvedAt.format('MMM D, YYYY')}</SitelineText>
            </div>
            {effectiveAt && (
              <div className="tooltipRow">
                <SitelineText variant="smallText" bold className="label">
                  {t(`${i18nBase}.effective`)}
                </SitelineText>
                <SitelineText variant="smallText">{effectiveAt.format('MMM D, YYYY')}</SitelineText>
              </div>
            )}
          </div>
        }
        placement="top-start"
        disableInteractive
      >
        <div className="fullWidth verticalAligned">
          <Button
            onClick={(evt) => setAnchorEl(evt.currentTarget)}
            disabled={!isEditable}
            className={classes.button}
          >
            <SitelineText variant="body2" color="grey70">
              {approvedAt.format('MMM D')}
            </SitelineText>
            {effectiveAt && (
              <SitelineText variant="body2" color="grey50" style={{ marginLeft: 8 }}>
                / {effectiveAt.format('MMM D')}
              </SitelineText>
            )}
          </Button>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && editingField === null}
        onClose={handleCloseMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {isApprovalDateEditable && (
          <MenuItem onClick={handleEditApprovalDate}>
            {t(`${i18nBase}.edit_approval_date`)}
          </MenuItem>
        )}
        <MenuItem onClick={handleEditEffectiveDate} className={classes.effectiveDateMenuItem}>
          {effectiveAt ? t(`${i18nBase}.edit_effective_date`) : t(`${i18nBase}.add_effective_date`)}
          <Tooltip title={t(`${i18nBase}.effective_date_tooltip`)} placement="bottom">
            <InfoOutlinedIcon />
          </Tooltip>
        </MenuItem>
      </Menu>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && editingField !== null}
        onClose={handleCloseMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <StaticDatePicker
          value={editingField === 'changeOrderApprovedAt' ? approvedAt : effectiveAt}
          views={DATE_VIEWS}
          onChange={handleChangeDate}
          openTo="day"
          slotProps={{ toolbar: { hidden: true } }}
          slots={{
            actionBar: () =>
              editingField === 'changeOrderEffectiveAt' && effectiveAt !== null ? (
                <div className={classes.calendarActions}>
                  <Button
                    variant="text"
                    className="removeButton"
                    onClick={handleRemoveEffectiveDate}
                  >
                    <SitelineText variant="body2" bold color="blue50">
                      {t(`${i18nBase}.remove`)}
                    </SitelineText>
                  </Button>
                </div>
              ) : null,
          }}
        />
      </Popover>
    </div>
  )
}
