import _ from 'lodash'
import { centsToDollars } from './numbers.js'

/**
 * There should be no decimal value of cents so round to 0 decimals
 * i.e. Given 1234.56 cents, return 1235 cents
 *
 * If we want to round to have 0 cents, then we do the following:
 * 1. Given 1234.56 cents, round to 1235 cents
 * 2. convert 1235 cents to 12.35 dollars
 * 3. round 12.35 dollars to 12 dollars
 * 4. multiply 12 dollars to make 1200 cents
 * 5. return 1200 cents
 */
export function roundCents(cents: number, roundToZeroCents = false): number {
  let roundedCents = _.round(cents)
  if (roundToZeroCents) {
    const dollars = centsToDollars(roundedCents)
    const roundedDollars = _.round(dollars)
    roundedCents = _.round(roundedDollars * 100)
  }
  return roundedCents
}
