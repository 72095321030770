import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactNode, useMemo } from 'react'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import ClipboardIcon from '../../assets/images/clipboard.png'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    '& > .title': {
      marginTop: theme.spacing(4),
    },
    '& > .subtitle': {
      maxWidth: 500,
      whiteSpace: 'pre-wrap',
      marginTop: theme.spacing(3),
      '& > a': {
        textDecoration: 'none',
        color: colors.blue50,
      },
    },
    '& .button': {
      marginTop: theme.spacing(4),
    },
  },
}))

interface ButtonProps {
  onClick: () => void
  label: string
  startIcon?: ReactNode
}

interface EmptyResultsViewProps {
  title?: string
  subtitle: ReactNode
  image?: string
  /**
   * Pixel width & height applied to the image
   * @default 120
   */
  size?: number
  buttonProps?: ButtonProps
}

/** Empty results page used across surfaces that need to support an empty state (list view of 0 results) */
export function EmptyResultsView({
  title,
  subtitle,
  image,
  size = 120,
  buttonProps,
}: EmptyResultsViewProps) {
  const classes = useStyles()
  const imageStyles = useMemo(() => ({ height: size, width: size }), [size])

  return (
    <div className={classes.root}>
      <img src={image ?? ClipboardIcon} alt={title ?? ''} style={imageStyles} />
      {title && (
        <SitelineText variant="h1" bold className="title">
          {title}
        </SitelineText>
      )}
      <SitelineText variant="body1" color="grey50" className="subtitle">
        {subtitle}
      </SitelineText>
      {buttonProps !== undefined && (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={buttonProps.startIcon}
          onClick={buttonProps.onClick}
          className="button"
        >
          {buttonProps.label}
        </Button>
      )}
    </div>
  )
}
