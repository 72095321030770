import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import {
  StoredMaterialsCarryoverType,
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  formatCentsToDollars,
  percentToDecimal,
  safeDivide,
} from 'siteline-common-all'
import { SitelineText } from 'siteline-common-web'
import { RetentionView, TaxesView } from '../../components/billing/invoice/InvoiceReducer'
import { SovLineItemProgressForEvents } from '../../components/billing/invoice/InvoiceSpreadsheet'
import { Avatar } from '../components/Avatar'
import {
  SpreadsheetCell,
  SpreadsheetElement,
  SpreadsheetValue,
  ValidationFunctionResult,
  makeContentCell,
  makeDataCell,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  SovLineItemProgressEventType,
  SovLineItemProgressProperties,
} from '../graphql/apollo-operations'
import {
  BaseInvoiceColumn,
  HistoryProgressProperties,
  getProgressEventDescription,
} from './Invoice'
import {
  EditCellIcon,
  HISTORICAL_PROGRESS_DATE_CELL_CLASS,
  RETENTION_PROGRESS_CELL_CLASS,
} from './InvoiceRow'
import { LumpSumInvoiceColumn } from './LumpSumInvoice'
import {
  balanceToFinish,
  balanceToFinishForFullPayApp,
  currentPayAppBilled,
  currentPayAppMaterialsStored,
  currentPayAppProgress,
  currentPercentComplete,
  isNewBilledInRange,
  previousPayAppBilled,
  previousPayAppWorkCompleted,
  roundPercentComplete,
} from './PayApp'

const i18nBase = 'projects.subcontractors.pay_app.invoice'

function checkForOverbilling({
  newProgressBilled,
  newStoredMaterialBilled,
  progress,
  t,
}: {
  newProgressBilled?: number
  newStoredMaterialBilled?: number
  progress: SovLineItemProgressProperties
  t: TFunction
}): ValidationFunctionResult {
  if (newProgressBilled === undefined && newStoredMaterialBilled === undefined) {
    throw new Error('Must provide either newProgressBilled or newStoredMaterialsBilled')
  }
  if (newProgressBilled !== undefined && newStoredMaterialBilled !== undefined) {
    throw new Error('Must provide only one of newProgressBilled or newStoredMaterialBilled')
  }
  const progressBilled = newProgressBilled ? newProgressBilled : progress.progressBilled
  const storedMaterialBilled = newStoredMaterialBilled
    ? newStoredMaterialBilled
    : progress.storedMaterialBilled
  const progressWithBilling = { ...progress, progressBilled, storedMaterialBilled }
  const balanceToFinishWithBilling = balanceToFinish(progressWithBilling)
  const updatedValue = newProgressBilled ?? newStoredMaterialBilled ?? 0

  const isBillingNegativeOnPositive = progress.totalValue >= 0 && updatedValue < 0
  const isBillingPositiveOnNegative = progress.totalValue < 0 && updatedValue > 0
  if (isBillingNegativeOnPositive || isBillingPositiveOnNegative) {
    return {
      type: 'confirm',
      title: t(`${i18nBase}.confirm_billing.confirm_billing`),
      details: isBillingNegativeOnPositive
        ? t(`${i18nBase}.confirm_billing.billing_positive`, { number: progress.sovLineItem.code })
        : t(`${i18nBase}.confirm_billing.billing_negative`, {
            number: progress.sovLineItem.code,
          }),
      cancelLabel: t(`${i18nBase}.confirm_billing.revise`),
    }
  }

  const isPositiveValueOverbilled = progress.totalValue >= 0 && balanceToFinishWithBilling < 0
  const isNegativeValueOverbilled = progress.totalValue < 0 && balanceToFinishWithBilling > 0
  if (isPositiveValueOverbilled || isNegativeValueOverbilled) {
    return {
      type: 'confirm',
      title: t(`${i18nBase}.confirm_billing.line_item_overbilled`),
      details: t(`${i18nBase}.confirm_billing.bill_over_amount_lump_sum`, {
        number: progress.sovLineItem.code,
        amount: formatCentsToDollars(progress.totalValue, true),
      }),
      cancelLabel: t(`${i18nBase}.confirm_billing.revise`),
    }
  }
  return null
}

/**
 * Checks that a cell value is valid and if not, returns a message to display to the user
 * that describes the problem
 */
function validateProgressValue(
  column:
    | LumpSumInvoiceColumn.PERCENT_COMPLETE
    | BaseInvoiceColumn.PROGRESS_BILLED
    | BaseInvoiceColumn.STORED_MATERIALS,
  newValue: SpreadsheetValue,
  progress: SovLineItemProgressProperties,
  allowsOverbilling: boolean,
  t: TFunction
): ValidationFunctionResult {
  const value = Number(newValue)
  if (isNaN(value)) {
    return { type: 'error', message: t('common.spreadsheet.not_a_number') }
  }

  switch (column) {
    case LumpSumInvoiceColumn.PERCENT_COMPLETE: {
      if (progress.totalValue === 0 && value !== 0) {
        return {
          type: 'error',
          message: t('projects.subcontractors.pay_app.invoice.zero_scheduled_value'),
        }
      }
      if (allowsOverbilling) {
        return checkForOverbilling({
          // Calculate the new progress billed value for this month, which is the new total percent
          // complete, minus the amount previously
          newProgressBilled:
            progress.sovLineItem.latestTotalValue * percentToDecimal(value) -
            progress.previousBilled,
          progress,
          t,
        })
      }
      if (value > 100) {
        return {
          type: 'error',
          message: t(`${i18nBase}.percent_complete_maximum`, {
            num: `100%`,
          }),
        }
      } else {
        const progressWithoutProgressBilled = { ...progress, progressBilled: 0 }
        const minPercentComplete = currentPercentComplete(progressWithoutProgressBilled)
        if (value < minPercentComplete) {
          return {
            type: 'error',
            message: t(`${i18nBase}.percent_complete_minimum`, {
              num: `${minPercentComplete}%`,
            }),
          }
        }
      }
      break
    }
    case BaseInvoiceColumn.PROGRESS_BILLED:
    case BaseInvoiceColumn.STORED_MATERIALS: {
      const centsValue = dollarsToCents(value)
      if (allowsOverbilling) {
        const newProgressBilled =
          column === BaseInvoiceColumn.PROGRESS_BILLED ? centsValue : undefined
        const newStoredMaterialBilled =
          column === BaseInvoiceColumn.STORED_MATERIALS ? centsValue : undefined
        return checkForOverbilling({ newProgressBilled, newStoredMaterialBilled, progress, t })
      }
      if (!isNewBilledInRange(centsValue, progress.totalValue)) {
        const min = progress.totalValue >= 0 ? 0 : progress.totalValue
        const max = progress.totalValue >= 0 ? progress.totalValue : 0
        if (centsValue < min) {
          return {
            type: 'error',
            message: t(`${i18nBase}.line_item_value_low_specific`, {
              amount: formatCentsToDollars(min, true),
            }),
          }
        }
        if (centsValue > max) {
          return {
            type: 'error',
            message: t(`${i18nBase}.line_item_value_high_specific`, {
              amount: formatCentsToDollars(max, true),
            }),
          }
        }
      } else {
        const clone = { ...progress, [column]: centsValue }
        const balance = balanceToFinish(clone)
        const totalValue = clone.totalValue - clone.previousBilled
        let tooLow = false
        let tooHigh = false
        if (totalValue < 0) {
          // If the total expected value for the line item is negative, then the remaining balance
          // should be <= 0 but above the negative total value (i.e. for a negative change order)
          tooLow = balance > 0
          tooHigh = balance < totalValue
        } else {
          tooLow = balance > totalValue
          tooHigh = balance < 0
        }
        if (tooLow) {
          return {
            type: 'error',
            message: t(`${i18nBase}.line_item_value_low`),
          }
        }
        if (tooHigh) {
          return {
            type: 'error',
            message: t(`${i18nBase}.line_item_value_high`),
          }
        }
      }
    }
  }
  return null
}

/**
 * Returns a `SpreadsheetRow` component to be displayed in the pay app invoice
 * table to represent a single unit price progress line item
 */
export function getLumpSumProgressRowCells({
  progress,
  nameRightContent,
  showWarningIfEmpty,
  shouldIncludeCostCode,
  allowsOverbilling,
  t,
  onProgressClick,
  storedMaterialsCarryoverType,
  onEditStoredMaterialsManual,
}: {
  progress: SovLineItemProgressProperties
  nameRightContent?: SpreadsheetElement
  showWarningIfEmpty?: boolean
  shouldIncludeCostCode: boolean
  allowsOverbilling: boolean
  t: TFunction
  /**
   * Only applicable to invoices that support field work. This is a scenario where we want to
   * prevent back office users from directly editing the invoice when the line item has a worksheet.
   * Instead of allowing them to edit the progress cell, we open the sidebar worksheet. Undefined
   * should be passed in if this is unapplicable.
   */
  onProgressClick?: (sovLineItemId: string) => void
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  /**
   * Only applicable when the project is set up with manual stored materials carryover type. In this
   * case, the stored materials cell is clickable. It opens a dialog for entering stored materials
   * values.
   */
  onEditStoredMaterialsManual?: (progressId: string) => void
}): SpreadsheetCell[] {
  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL

  const lineItemCells = [
    // Number
    makeDataCell(progress.sovLineItem.code, {
      borderColor: showWarningIfEmpty && !progress.sovLineItem.code ? 'error' : 'default',
    }),
    // Name
    makeDataCell(progress.sovLineItem.name, {
      rightContent: nameRightContent,
      borderColor: showWarningIfEmpty && !progress.sovLineItem.name ? 'error' : 'default',
    }),
    // Cost code
    ...(shouldIncludeCostCode ? [makeDataCell(progress.sovLineItem.costCode ?? '')] : []),
    // Scheduled value
    makeDataCell(centsToDollars(progress.totalValue)),
    // Previous billed/work completed
    makeDataCell(
      centsToDollars(
        isManualStoredMaterials ? progress.previousWorkCompleted : progress.previousBilled
      )
    ),
  ]

  const totalBalanceToFinish = balanceToFinish(progress)

  const handleEditStoredMaterialsManual = onEditStoredMaterialsManual
    ? () => onEditStoredMaterialsManual(progress.id)
    : undefined

  const handleProgressClick = onProgressClick
    ? () => onProgressClick(progress.sovLineItem.id)
    : undefined

  // This is a very specific edge case where the line item has worksheet billing AND is set up
  // with stored materials manual tracking. In that case, clicking the pencil icon should open the
  // billing worksheet sidebar rather than opening the stored materials dialog.
  const handleEditStoredMaterialsClick = onProgressClick
    ? handleProgressClick
    : handleEditStoredMaterialsManual

  const storedMaterialsCell = isManualStoredMaterials
    ? makeDataCell(centsToDollars(progress.materialsInStorageThroughCurrentPayApp), {
        leftContent: {
          content: onEditStoredMaterialsManual ? (
            <EditCellIcon
              isHoverOnly
              type="icon"
              onEdit={handleEditStoredMaterialsClick}
              className="leftCellIcon"
            />
          ) : null,
          dependencies: [onEditStoredMaterialsManual, onProgressClick],
          align: 'right' as const,
        },
      })
    : makeDataCell(centsToDollars(progress.storedMaterialBilled), {
        onBeforeEdit: handleProgressClick,
        validate: (value: SpreadsheetValue) =>
          validateProgressValue(
            BaseInvoiceColumn.STORED_MATERIALS,
            value,
            progress,
            allowsOverbilling,
            t
          ),
      })
  const progressCells = [
    // % Complete
    makeDataCell(roundPercentComplete(currentPercentComplete(progress)), {
      validate: (value: SpreadsheetValue) =>
        validateProgressValue(
          LumpSumInvoiceColumn.PERCENT_COMPLETE,
          value,
          progress,
          allowsOverbilling,
          t
        ),
      onBeforeEdit: handleProgressClick,
    }),
    // Completed this month
    makeDataCell(centsToDollars(progress.progressBilled), {
      validate: (value: SpreadsheetValue) =>
        validateProgressValue(
          BaseInvoiceColumn.PROGRESS_BILLED,
          value,
          progress,
          allowsOverbilling,
          t
        ),
      onBeforeEdit: handleProgressClick,
    }),
    // Stored materials
    storedMaterialsCell,
    // Total completed and stored to date
    makeDataCell(centsToDollars(progress.totalValue - totalBalanceToFinish)),
    // Balance to finish
    makeDataCell(centsToDollars(totalBalanceToFinish)),
  ]

  return [...lineItemCells, ...progressCells]
}

/**
 * Returns the totals row for a lump sum invoice, with total amounts for each column
 * to be displayed as a footer row below each group and at the end of the invoice table
 */
export function getLumpSumInvoiceTotalsCells({
  progress,
  t,
  shouldIncludeCostCode,
  totalsRowType,
  storedMaterialsCarryoverType,
}: {
  progress: SovLineItemProgressProperties[]
  t: TFunction
  shouldIncludeCostCode: boolean
  totalsRowType: 'group' | 'invoice'
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}): SpreadsheetCell[] {
  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
  const scheduledValue = progress.reduce((a, b) => a + b.totalValue, 0)
  const previouslyBilled = previousPayAppBilled([...progress])
  const previousWorkCompleted = previousPayAppWorkCompleted([...progress])
  const previousAmount = isManualStoredMaterials ? previousWorkCompleted : previouslyBilled
  const currentBilled = currentPayAppBilled([...progress])
  const progressBilled = currentPayAppProgress([...progress])
  const storedMaterialBilled = currentPayAppMaterialsStored(
    [...progress],
    storedMaterialsCarryoverType
  )
  const totalBalanceToFinish = balanceToFinishForFullPayApp([...progress])
  const totalBilledToDate = previouslyBilled + currentBilled
  const itemCount = t('projects.subcontractors.pay_app.invoice.total_items', {
    count: progress.length,
  })

  const lineItemCells = [
    makeContentCell(
      <SitelineText variant="secondary" bold>
        {totalsRowType === 'group'
          ? t('projects.subcontractors.pay_app.invoice.subtotal')
          : t('projects.subcontractors.pay_app.invoice.total')}
      </SitelineText>,
      []
    ),
    makeDataCell(itemCount, { colSpan: shouldIncludeCostCode ? 2 : 1 }),
    makeDataCell(centsToDollars(scheduledValue)),
    makeDataCell(centsToDollars(previousAmount)),
  ]

  const progressCells = [
    makeDataCell(decimalToPercent(safeDivide(totalBilledToDate, scheduledValue, 0), 2)),
    makeDataCell(centsToDollars(progressBilled)),
    makeDataCell(centsToDollars(storedMaterialBilled)),
    makeDataCell(centsToDollars(scheduledValue - totalBalanceToFinish)),
    makeDataCell(centsToDollars(totalBalanceToFinish)),
  ]

  return [...lineItemCells, ...progressCells]
}

/**
 * Returns a list of `SpreadsheetRow` event cells to be displayed in a lump sum pay app invoice
 * history row
 */
export function getLumpSumProgressHistoryCells({
  retentionView,
  taxesView,
  timeZone,
  t,
  event,
  progress,
  historyProgress,
  shouldIncludeCostCode,
  storedMaterialsCarryoverType,
}: {
  retentionView?: RetentionView
  taxesView?: TaxesView
  timeZone: string
  t: TFunction
  event: SovLineItemProgressForEvents['events'][number]
  progress: SovLineItemProgressProperties
  historyProgress: HistoryProgressProperties
  shouldIncludeCostCode: boolean
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}): SpreadsheetCell[] {
  const formattedDate = moment.tz(event.createdAt, timeZone).format('MMM D, h:mma')
  const totalRetention = (historyProgress.retentionHeld ?? 0) + progress.previousRetention
  const currentBilled = historyProgress.progressBilled + historyProgress.storedMaterialBilled

  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
  const storedMaterials = isManualStoredMaterials
    ? historyProgress.materialsStored
    : historyProgress.storedMaterialBilled

  return [
    makeDataCell(formattedDate, { className: HISTORICAL_PROGRESS_DATE_CELL_CLASS }),
    makeContentCell(
      <div className="historyDescription">
        <Avatar
          user={event.createdBy}
          isAdmin={event.isAdmin}
          color="grey30"
          textColor="grey90"
          size="sm"
        />
        <SitelineText variant="secondary">
          {getProgressEventDescription(progress.sovLineItem.isChangeOrder, event, t)}
        </SitelineText>
      </div>,
      [],
      { colSpan: shouldIncludeCostCode ? 2 : 1 }
    ),
    makeDataCell(centsToDollars(historyProgress.scheduledValue), {
      bold: event.type === SovLineItemProgressEventType.SET_LINE_ITEM_TOTAL_VALUE,
    }),
    makeDataCell(centsToDollars(historyProgress.previousBilled), {
      bold: event.type === SovLineItemProgressEventType.SET_LINE_ITEM_PREVIOUS_BILLED,
    }),
    makeDataCell(historyProgress.percentComplete),
    makeDataCell(centsToDollars(historyProgress.progressBilled), {
      bold:
        event.type === SovLineItemProgressEventType.SET_PROGRESS_BILLED ||
        event.type === SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP,
    }),
    makeDataCell(centsToDollars(storedMaterials ?? historyProgress.storedMaterialBilled), {
      bold:
        event.type === SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED ||
        event.type === SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED_AND_INSTALLED ||
        event.type === SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP,
    }),
    makeDataCell(centsToDollars(historyProgress.scheduledValue - historyProgress.balanceToFinish)),
    makeDataCell(centsToDollars(historyProgress.balanceToFinish)),
    ...(retentionView === RetentionView.HELD_CURRENT_PERCENT ||
    retentionView === RetentionView.HELD_TO_DATE_PERCENT
      ? [
          makeDataCell(
            historyProgress.retentionHeld !== undefined
              ? decimalToPercent(safeDivide(historyProgress.retentionHeld, currentBilled, 0), 0)
              : '',
            {
              bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
              className: RETENTION_PROGRESS_CELL_CLASS,
            }
          ),
        ]
      : []),
    ...(retentionView === RetentionView.HELD_TO_DATE_AMOUNT
      ? [
          makeDataCell(totalRetention !== 0 ? centsToDollars(totalRetention) : '', {
            bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
            className: RETENTION_PROGRESS_CELL_CLASS,
          }),
        ]
      : []),
    ...(retentionView === RetentionView.HELD_CURRENT_AMOUNT
      ? [
          makeDataCell(
            historyProgress.retentionHeld ? centsToDollars(historyProgress.retentionHeld) : '',
            {
              bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
              className: RETENTION_PROGRESS_CELL_CLASS,
            }
          ),
        ]
      : []),
    ...(retentionView === RetentionView.RELEASED_CURRENT_AMOUNT
      ? [
          makeDataCell('', {
            className: RETENTION_PROGRESS_CELL_CLASS,
          }),
        ]
      : []),
    ...(taxesView ? [makeDataCell('')] : []),
    makeDataCell(''),
  ]
}
