import { gql } from '@apollo/client'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Button, Link, TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  CompanyIntegrationMetadataQuickbooks,
  QUICKBOOKS_ITEM_MAX_CHAR_COUNT,
} from 'siteline-common-all'
import {
  IntegrationType,
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import { useUpdateCompanyIntegrationMetadataMutation } from '../../common/graphql/apollo-operations'
import { findCompanyIntegrationOfType } from '../../common/util/Integration'

gql`
  mutation updateCompanyIntegrationMetadata($input: UpdateCompanyIntegrationMetadataInput!) {
    updateCompanyIntegrationMetadata(input: $input) {
      id
      metadata
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .flex': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .editButton': {
      color: colors.grey50,
      margin: theme.spacing(-1, -1, 0, 0),
    },
    '& .saveButton': {
      margin: theme.spacing(-1, 0, 0, 2),
    },
    '& .heading': {
      alignItems: 'center',
      '& .Siteline-h4': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .subtitle': {
      display: 'block',
      margin: theme.spacing(1, 0),
      whiteSpace: 'pre-wrap',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
      '&.editing': {
        padding: theme.spacing(0.5, 0),
      },
      '& .label': {
        minWidth: 300,
        marginRight: theme.spacing(3),
      },
      '& .input': {
        width: 300,
      },
      '& .tooltipIcon': {
        fontSize: 16,
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
}))

const EMPTY_QUICKBOOKS_METADATA = {
  accountsReceivableAccount: '',
  progressItemName: '',
  progressItemIncomeAccount: '',
  retentionItemName: '',
  retentionItemIncomeAccount: '',
}

const i18nBase = 'settings.quickbooks'

const QUICKBOOKS_HELP_CENTER_ARTICLE =
  'https://support.siteline.com/hc/en-us/articles/14697493769876-Export-an-IIF-file-to-QuickBooks-Enterprise'

/** Company-wide integration settings, currently for Quickbooks only */
export function QuickbooksCompanySettings() {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { company } = useCompanyContext()
  const [updateCompanyIntegrationMetadata] = useUpdateCompanyIntegrationMetadataMutation()
  const quickbooksCompanyIntegration = useMemo(() => {
    return findCompanyIntegrationOfType(company, IntegrationType.QUICKBOOKS_ENTERPRISE_FILE)
  }, [company])
  const initialMetadata: CompanyIntegrationMetadataQuickbooks = useMemo(() => {
    if (!quickbooksCompanyIntegration) {
      // This code should not be reachable, since this section is only mounted if a Quickbooks
      // company integration exists
      throw new Error('No Quickbooks company integration found')
    }
    const mappings = quickbooksCompanyIntegration.metadata as
      | CompanyIntegrationMetadataQuickbooks
      | undefined
    return mappings ?? EMPTY_QUICKBOOKS_METADATA
  }, [quickbooksCompanyIntegration])
  const [metadata, setMetadata] = useState<CompanyIntegrationMetadataQuickbooks>(initialMetadata)

  const onEditOrCancel = useCallback(() => {
    // If we were editing already and we cancel, then we should reset the state back to default
    if (isEditing) {
      setMetadata(initialMetadata)
    }
    setIsEditing(!isEditing)
  }, [initialMetadata, isEditing])

  const handleSave = useCallback(async () => {
    if (!quickbooksCompanyIntegration) {
      // Should not be possible, as this card would not be rendered if there was no existing
      // Quickbooks company integration
      return
    }
    try {
      await updateCompanyIntegrationMetadata({
        variables: { input: { companyIntegrationId: quickbooksCompanyIntegration.id, metadata } },
      })
      setIsEditing(false)
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [metadata, quickbooksCompanyIntegration, snackbar, updateCompanyIntegrationMetadata])

  const disableSave = useMemo(
    () =>
      !metadata.progressItemName ||
      !metadata.progressItemIncomeAccount ||
      !metadata.retentionItemName ||
      !metadata.retentionItemIncomeAccount,
    [metadata]
  )

  return (
    <div className={classes.root}>
      <div className="heading flex">
        <SitelineText variant="h3" bold color="grey90">
          {t('settings.navigation.quickbooks')}
        </SitelineText>
        <div>
          <Button variant="text" color="secondary" onClick={onEditOrCancel} className="editButton">
            {isEditing ? t('common.actions.cancel') : t('common.actions.edit')}
          </Button>
          {isEditing && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={disableSave}
              className="saveButton"
            >
              {t('common.actions.save')}
            </Button>
          )}
        </div>
      </div>
      <SitelineText variant="body2" color="grey50" className="subtitle">
        <Trans
          i18nKey={`${i18nBase}.description`}
          components={{
            bold: <span className={classes.bold} />,
            helpLink: (
              <Link
                href={QUICKBOOKS_HELP_CENTER_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                color={colors.grey50}
              />
            ),
          }}
        />
      </SitelineText>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.ar_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.ar_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.accountsReceivableAccount}
            onChange={(event) =>
              setMetadata({ ...metadata, accountsReceivableAccount: event.target.value })
            }
            className="input"
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.accountsReceivableAccount ? 'grey90' : 'grey50'}
          >
            {metadata.accountsReceivableAccount || '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_ar_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_ar_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionAccountsReceivableAccount}
            onChange={(event) =>
              setMetadata({ ...metadata, retentionAccountsReceivableAccount: event.target.value })
            }
            className="input"
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.retentionAccountsReceivableAccount ? 'grey90' : 'grey50'}
          >
            {metadata.retentionAccountsReceivableAccount || '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.invoice_item_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.invoice_item`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.progressItemName}
            onChange={(event) => setMetadata({ ...metadata, progressItemName: event.target.value })}
            slotProps={{ htmlInput: { maxLength: QUICKBOOKS_ITEM_MAX_CHAR_COUNT } }}
            className="input"
          />
        ) : (
          <SitelineText variant="body1" color={metadata.progressItemName ? 'grey90' : 'grey50'}>
            {metadata.progressItemName || '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.income_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.income_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.progressItemIncomeAccount}
            onChange={(event) =>
              setMetadata({ ...metadata, progressItemIncomeAccount: event.target.value })
            }
            className="input"
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.progressItemIncomeAccount ? 'grey90' : 'grey50'}
          >
            {metadata.progressItemIncomeAccount || '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_item_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_item`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionItemName}
            onChange={(event) =>
              setMetadata({ ...metadata, retentionItemName: event.target.value })
            }
            slotProps={{ htmlInput: { maxLength: QUICKBOOKS_ITEM_MAX_CHAR_COUNT } }}
            className="input"
          />
        ) : (
          <SitelineText variant="body1" color={metadata.retentionItemName ? 'grey90' : 'grey50'}>
            {metadata.retentionItemName || '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionItemIncomeAccount}
            onChange={(event) =>
              setMetadata({ ...metadata, retentionItemIncomeAccount: event.target.value })
            }
            className="input"
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.retentionItemIncomeAccount ? 'grey90' : 'grey50'}
          >
            {metadata.retentionItemIncomeAccount || '–'}
          </SitelineText>
        )}
      </div>
    </div>
  )
}
