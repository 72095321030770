import { Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { memo, useCallback } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import { Page as PdfPage } from '../../../pdf'

const useStyles = makeStylesFast((theme: Theme) => ({
  thumbnail: {
    overflow: 'hidden',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${colors.grey20}`,
    height: 160,
    '&.clickable': {
      cursor: 'pointer',
    },
  },
}))

function ThumbnailSkeleton() {
  const classes = useStyles()
  return <Skeleton variant="rectangular" width="100%" className={classes.thumbnail} />
}

interface PdfPageMemoizedProps {
  thumbnailWidth: number
  pageNumber: number
  onRenderSuccess: () => void
}

/** Returns the PdfPage component wrapped in a memo to reduce re-renders */
export const PdfPageMemoized = memo(function PdfPageMemoized({
  thumbnailWidth,
  pageNumber,
  onRenderSuccess,
}: PdfPageMemoizedProps) {
  // Use setTimeout to add a small buffer after render before launching
  // the onRenderSuccess callback
  const handleRenderSuccess = useCallback(() => {
    setTimeout(onRenderSuccess, 500)
  }, [onRenderSuccess])

  return (
    <PdfPage
      pageNumber={pageNumber}
      width={thumbnailWidth}
      loading={<ThumbnailSkeleton />}
      renderAnnotationLayer={false}
      renderTextLayer={false}
      onRenderSuccess={handleRenderSuccess}
    />
  )
})
