import { DocumentNode } from '@apollo/client'
import {
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons'
import { useMemo } from 'react'
import {
  GetPayAppForBackupDocument,
  StoredFileType,
  useGetContractForPayAppQuery,
} from '../../common/graphql/apollo-operations'

/**
 * Returns an icon for a specific attachment type.
 * @param attachmentType Type of the attachment to generate an icon for
 */
export function getIcon(attachmentType: StoredFileType): IconDefinition {
  switch (attachmentType) {
    case StoredFileType.DOC:
      return faFileWord
    case StoredFileType.DOCX:
      return faFileWord
    case StoredFileType.JPEG:
      return faFileImage
    case StoredFileType.PDF:
      return faFilePdf
    case StoredFileType.PNG:
      return faFileImage
    case StoredFileType.XLS:
      return faFileExcel
    case StoredFileType.XLSX:
      return faFileExcel
  }
}

export function isImage(attachmentType: StoredFileType): boolean {
  switch (attachmentType) {
    case StoredFileType.JPEG:
    case StoredFileType.PNG:
      return true
    case StoredFileType.DOC:
    case StoredFileType.DOCX:
    case StoredFileType.PDF:
    case StoredFileType.XLS:
    case StoredFileType.XLSX:
      return false
  }
}

export type RefetchQueriesForRecurringBackup = {
  query: DocumentNode
  variables: {
    payAppId: string
  }
}[]

type UseBuildRefetchQueriesForRecurringBackupParams = {
  projectId: string
  companyId: string
}

/**
 * We apply refetch queries to create/update/delete attachment requests when the user has marked the attachment
 * as recurring or updates this preference. In this case, we want to update the cache of backup associated with
 * future pay apps.
 */
export function useBuildRefetchQueriesForRecurringBackup({
  projectId,
  companyId,
}: UseBuildRefetchQueriesForRecurringBackupParams): RefetchQueriesForRecurringBackup {
  const { data } = useGetContractForPayAppQuery({
    variables: {
      input: { projectId, companyId },
    },
  })

  return useMemo(() => {
    return (data?.contractByProjectId.payApps ?? []).map((futurePayApp) => ({
      query: GetPayAppForBackupDocument,
      variables: { payAppId: futurePayApp.id },
    }))
  }, [data?.contractByProjectId.payApps])
}
