import { TFunction } from 'i18next'
import { BillingType, StoredMaterialsCarryoverType } from 'siteline-common-all'
import { RetentionView, TaxesView } from '../../components/billing/invoice/InvoiceReducer'
import { RetentionViewToggle } from '../../components/billing/invoice/retention/RetentionViewToggle'
import { TaxesViewToggle } from '../../components/billing/invoice/taxes/TaxesViewToggle'
import { SpreadsheetColumn, SpreadsheetDataType } from '../components/Spreadsheet/Spreadsheet.lib'
import { BaseInvoiceColumn, InvoiceColumnsProps } from './Invoice'

export enum LumpSumInvoiceColumn {
  SCHEDULED_VALUE = 'totalValue',
  PREVIOUS_BILLED = 'previousBilled',
  PERCENT_COMPLETE = 'percentComplete',
  TOTAL_PROGRESS_AND_MATERIALS = 'totalProgressAndMaterials',
  BALANCE = 'balance',
}

/** Returns the list of columns to show on the pay app invoice table */
export function getLumpSumInvoiceColumns(
  t: TFunction,
  {
    disableEditing,
    isRetentionOnly,
    retentionView,
    trackingType,
    onRetentionViewChange,
    taxesView,
    onTaxesViewChange,
    shouldIncludeCostCode,
    storedMaterialsCarryoverType,
  }: InvoiceColumnsProps
): SpreadsheetColumn[] {
  const headingI18nBase = 'projects.subcontractors.pay_app.invoice.headers'

  const lineItemColumns: SpreadsheetColumn[] = [
    {
      id: BaseInvoiceColumn.CODE,
      heading: t(`${headingI18nBase}.number`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      // This column holds the date in expanded event rows
      minWidth: 165,
      maxWidth: 200,
      wordBreak: 'break-all',
    },
    {
      id: BaseInvoiceColumn.NAME,
      heading: t(`${headingI18nBase}.name`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 150,
      minWidth: 150,
    },
    ...(shouldIncludeCostCode
      ? [
          {
            id: BaseInvoiceColumn.COST_CODE,
            heading: t(`${headingI18nBase}.cost_code`),
            isEditable: false,
            dataType: SpreadsheetDataType.OTHER as const,
            align: 'left' as const,
          },
        ]
      : []),
    {
      id: LumpSumInvoiceColumn.SCHEDULED_VALUE,
      heading: t(`${headingI18nBase}.scheduled_value`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
    {
      id: LumpSumInvoiceColumn.PREVIOUS_BILLED,
      heading:
        storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
          ? t(`${headingI18nBase}.work_completed`)
          : t(`${headingI18nBase}.previously_billed`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
  ]
  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
  const isStoredMaterialsEditable = isManualStoredMaterials
    ? false
    : !disableEditing && !isRetentionOnly

  const progressColumns: SpreadsheetColumn[] = [
    {
      id: LumpSumInvoiceColumn.PERCENT_COMPLETE,
      heading: t(`${headingI18nBase}.percent_complete`),
      isEditable: !disableEditing && !isRetentionOnly,
      dataType: SpreadsheetDataType.PERCENT,
      align: 'right',
    },
    {
      id: BaseInvoiceColumn.PROGRESS_BILLED,
      heading:
        storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
          ? t(`${headingI18nBase}.progress_this_month`)
          : t(`${headingI18nBase}.completed_this_month`),
      isEditable: !disableEditing && !isRetentionOnly,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
    {
      id: BaseInvoiceColumn.STORED_MATERIALS,
      heading: t(`${headingI18nBase}.stored_materials`),
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      isEditable: isStoredMaterialsEditable,
      // Add padding to fit edit icon on hover
      extraWidth: isManualStoredMaterials ? 30 : 0,
    },
    {
      id: LumpSumInvoiceColumn.TOTAL_PROGRESS_AND_MATERIALS,
      heading:
        storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
          ? t(`${headingI18nBase}.total_billed`)
          : t(`${headingI18nBase}.total_completed`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR as const,
      align: 'right' as const,
    },
    {
      id: LumpSumInvoiceColumn.BALANCE,
      heading: t(`${headingI18nBase}.balance`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
  ]

  if (retentionView) {
    const dataType =
      retentionView === RetentionView.HELD_CURRENT_PERCENT ||
      retentionView === RetentionView.HELD_TO_DATE_PERCENT
        ? SpreadsheetDataType.PERCENT
        : SpreadsheetDataType.DOLLAR
    progressColumns.push({
      id: BaseInvoiceColumn.RETENTION_PERCENT,
      heading: (
        <RetentionViewToggle
          retentionView={retentionView}
          onViewChange={(newView: RetentionView) => {
            if (onRetentionViewChange) {
              onRetentionViewChange(newView)
            }
          }}
          trackingType={trackingType}
        />
      ),
      isEditable: false,
      dataType,
      align: 'right',
      // Add padding to fit edit icon on hover
      extraWidth: 90,
      minWidth: 100,
    })
  }

  if (taxesView) {
    const dataType =
      taxesView === TaxesView.TAX_RATE ? SpreadsheetDataType.PERCENT : SpreadsheetDataType.DOLLAR
    progressColumns.push({
      id: BaseInvoiceColumn.TAXES,
      heading: (
        <TaxesViewToggle
          taxesView={taxesView}
          onViewChange={(newView: TaxesView) => {
            if (onTaxesViewChange) {
              onTaxesViewChange(newView)
            }
          }}
          billingType={BillingType.LUMP_SUM}
        />
      ),
      isEditable: false,
      dataType,
      align: 'right',
    })
  }

  const columns: SpreadsheetColumn[] = [
    ...lineItemColumns,
    ...progressColumns,
    {
      id: BaseInvoiceColumn.HISTORY,
      heading: '',
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'right',
      minWidth: 55,
      maxWidth: 55,
      skeletonWidth: 0,
      grow: true,
    },
  ]

  return columns
}
