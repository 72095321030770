import { parsePhoneNumber } from 'siteline-common-all'

export function phoneNumberHasExtension(phoneNumber: string): boolean {
  // The x is not optional
  const REGEX = /^\+[1-9]\d{1,14}x\d{1,4}$/
  return REGEX.test(phoneNumber)
}

export function getExtension(phoneNumber: string): string {
  if (!phoneNumberHasExtension(phoneNumber)) {
    return ''
  }

  const parts = phoneNumber.split('x')
  return parts[1]
}

/** Returns a more readable formatted phone number, for standard US phone numbers */
export function formatPhoneNumber(phoneNumber: string): string {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  if (!parsedPhoneNumber) {
    return ''
  }
  return parsedPhoneNumber.formatNational()
}
