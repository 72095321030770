import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'

// Need to use a fixed width to keep labels aligned and be able to truncate
// text in the content
const labelWidth = 124
const labelWidthMedium = 145

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2, 0),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '& .textContainer': {
      display: 'flex',
      '&.alignCenter': {
        alignSelf: 'center',
      },
    },
    // The label by default is not centered using flex-box, because the the contents might have multiple lines;
    // instead, it has top padding to approximate horizontal alignment with the first line of the content.
    // If the content will always be a single line, use the `alignLabel` prop to center the label.
    '& .label': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '&.alignCenter': {
        paddingTop: 0,
        alignSelf: 'center',
      },
      '&.smallWidth': {
        minWidth: labelWidth,
        width: labelWidth,
      },
      '&.mediumWidth': {
        minWidth: labelWidthMedium,
        width: labelWidthMedium,
      },
      '& .tooltipIcon': {
        marginLeft: theme.spacing(1),
        fontSize: theme.typography.body1.fontSize,
      },
    },
    '& .contents': {
      minHeight: 40,
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      overflow: 'hidden',
      '&.noLabel': {
        '&.smallWidth': {
          paddingLeft: labelWidth,
        },
        '&.mediumWidth': {
          paddingLeft: labelWidthMedium,
        },
      },
    },
    '& input::placeholder': {
      color: colors.grey50,
      opacity: 1,
    },
  },
  infoTooltip: {
    maxWidth: 'unset',
  },
}))

export interface SendEmailDialogRowProps {
  label?: string
  tooltip?: string
  endIcon?: ReactNode

  /** Defaults to start; use center if content is guaranteed to be one line */
  alignLabel?: 'start' | 'center'

  /**
   * Default to smaller width. Using fixed widths so labels for all rows are aligned.
   */
  width?: 'small' | 'medium' | number

  className?: string
}

/** A row in the submit dialog with a label and content to its right */
export function SendEmailDialogRow({
  label,
  tooltip,
  endIcon,
  alignLabel,
  width = 'small',
  className,
  children,
}: React.PropsWithChildren<SendEmailDialogRowProps>) {
  const classes = useStyles()
  let showEndIcon = endIcon
  if (tooltip) {
    showEndIcon = (
      <Tooltip title={tooltip} placement="bottom-start" classes={{ tooltip: classes.infoTooltip }}>
        <HelpOutlineOutlinedIcon fontSize="small" />
      </Tooltip>
    )
  }

  const usingNumericWidth = width !== 'small' && width !== 'medium'

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx('textContainer', { alignCenter: alignLabel === 'center' })}>
        {label !== undefined && (
          <div
            className={clsx('label', {
              mediumWidth: width === 'medium',
              smallWidth: width === 'small',
            })}
            style={usingNumericWidth ? { width, minWidth: width } : undefined}
          >
            <SitelineText
              variant="body1"
              color="grey50"
              endIcon={showEndIcon}
              className={clsx({ alignCenter: alignLabel === 'center' })}
            >
              {label}
            </SitelineText>
          </div>
        )}
      </div>
      <div
        className={clsx('contents', { noLabel: label === undefined })}
        style={label === undefined && usingNumericWidth ? { paddingLeft: width } : undefined}
      >
        {children}
      </div>
    </div>
  )
}
