import clsx from 'clsx'
import { forwardRef, HTMLProps, PropsWithChildren, useMemo } from 'react'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

interface RowProps extends HTMLProps<HTMLDivElement> {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  gap?: string | number
  className?: string
}

/** Wrapper around a list of child components that enables easier setting of flex properties */
export const Row = forwardRef<HTMLDivElement, PropsWithChildren<RowProps>>(
  function Row(props, ref) {
    const { justifyContent, alignItems, gap, className, children, ...restProps } = props
    const classes = useStyles()

    const styles = useMemo(
      () => ({
        justifyContent,
        alignItems,
        gap,
      }),
      [alignItems, gap, justifyContent]
    )

    return (
      <div ref={ref} {...restProps} className={clsx(className, classes.row)} style={styles}>
        {children}
      </div>
    )
  }
)
