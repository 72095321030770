import copy from 'copy-to-clipboard'
import _ from 'lodash'
import { isDesktop } from 'react-device-detect'
import { BaseInvoiceColumn } from '../../util/Invoice'
import { LumpSumInvoiceColumn } from '../../util/LumpSumInvoice'
import {
  CellPosition,
  columnIndexForCell,
  emptyValueForColumn,
  isCellEditable,
  moveToEditableCell,
  nextCell,
  nextRow,
  pasteContentToSpreadsheet,
  preserveCellPosition,
  previousRow,
  reorderSpreadsheet,
  SpreadsheetColumn,
  SpreadsheetContent,
  SpreadsheetValue,
} from './Spreadsheet.lib'

export type SpreadsheetReducerState = {
  columns: SpreadsheetColumn[]
  content: SpreadsheetContent
  onChange?: (rowId: string, columnId: string, toValue: SpreadsheetValue) => void
  onReorder?: (rowId: string, toRowIndex: number) => boolean
  selectedCell?: CellPosition
  editingCell?: {
    position: CellPosition
    initialValue?: string
  }
  isFocused: boolean
  isFocusPaused: boolean
}

export type SpreadsheetAction =
  // Selects a cell but does NOT edit it. Adds a heavy border around the cell.
  | { type: 'SELECT_CELL'; cell: CellPosition }
  // Edit the cell that's currently selected. This puts the cursor into the <input> element.
  | { type: 'EDIT_CELL'; event?: KeyboardEvent }
  // Select and begin editing a cell in one action.
  | { type: 'SELECT_AND_EDIT_CELL'; cell: CellPosition }
  // Start editing the currently selected cell and enter the event key as an initial value.
  | { type: 'DIRECTLY_EDIT_CELL'; event?: KeyboardEvent }
  // Paste content into cell from clipboard
  | { type: 'PASTE_CELL_CONTENT'; event?: KeyboardEvent }
  // Copy content from cell into clipboard
  | { type: 'COPY_CELL_CONTENT'; event?: KeyboardEvent }
  // Stop editing the current cell, but leave it selected.
  | { type: 'STOP_EDITING'; event?: KeyboardEvent }
  // Move to next cell, which is to the right or beginning of next row
  | { type: 'NEXT_CELL'; event?: KeyboardEvent }
  // Move to previous cell, which is to the left or end of previous row
  | { type: 'PREVIOUS_CELL'; event?: KeyboardEvent }
  // Move to previous row, if not on the first row
  | { type: 'PREVIOUS_ROW'; event?: KeyboardEvent }
  // Move to next row, if not on last row
  | { type: 'NEXT_ROW'; event?: KeyboardEvent }
  // User clicked outside of the table
  | { type: 'CLICKED_AWAY' }
  // User edited the value of a cell
  | { type: 'EDITED_CELL'; toValue: SpreadsheetValue }
  // Delete the current content of the selected cell
  | { type: 'DELETE' }
  // Completely clear editing and selected state
  | { type: 'RESET' }
  // Reorder a row or group in the spreadsheet
  | { type: 'REORDER'; rowId: string; toRowIndex: number }
  // Update the spreadsheet content stored in the reducer state
  | { type: 'CONTENT_CHANGE'; content: SpreadsheetContent }
  // Update the spreadsheet columns stored in the reducer state
  | { type: 'COLUMNS_CHANGE'; columns: SpreadsheetColumn[] }
  // Update the change handler
  | { type: 'CHANGE_HANDLER_CHANGED'; onChange: SpreadsheetReducerState['onChange'] }
  // Update the reorder handler
  | { type: 'REORDER_HANDLER_CHANGED'; onReorder: SpreadsheetReducerState['onReorder'] }
  // If the spreadsheet focus changes
  | { type: 'FOCUS_CHANGED'; isFocused: boolean; isFocusPaused: boolean }

/** Returns the current selected cell, or the first editable cell in the spreadsheet */
function initialEditingCell(state: SpreadsheetReducerState): CellPosition | undefined {
  if (state.selectedCell) {
    return state.selectedCell
  }
  const initialCell = { rowIndex: 0, cellIndex: 0 }
  let currentCell = initialCell
  while (!isCellEditable(currentCell, state.columns, state.content)) {
    const potentialNextCell = nextCell(currentCell, state.content)
    if (_.isEqual(potentialNextCell, initialCell) || _.isEqual(potentialNextCell, currentCell)) {
      return undefined
    }
    currentCell = potentialNextCell
  }
  return currentCell
}

function moveSelectedCell(
  state: SpreadsheetReducerState,
  direction: 'next' | 'previous'
): CellPosition | undefined {
  if (!state.selectedCell) {
    return initialEditingCell(state)
  }
  return moveToEditableCell(state.selectedCell, direction, state.content, state.columns)
}

function moveSelectedRow(
  state: SpreadsheetReducerState,
  direction: 'next' | 'previous'
): CellPosition | undefined {
  if (!state.selectedCell) {
    return initialEditingCell(state)
  }
  const initialCell = state.selectedCell
  let currentCell = initialCell
  let lastCell = initialCell

  do {
    lastCell = currentCell
    currentCell =
      direction === 'next'
        ? nextRow(currentCell, state.content)
        : previousRow(currentCell, state.content)
  } while (
    !isCellEditable(currentCell, state.columns, state.content) &&
    currentCell.rowIndex !== lastCell.rowIndex
  )

  // If we got stuck in a loop, there's no row to move to; return the initial cell
  if (currentCell.rowIndex === lastCell.rowIndex) {
    return initialCell
  }

  return currentCell
}

export function spreadsheetReducer(
  state: SpreadsheetReducerState,
  action: SpreadsheetAction
): SpreadsheetReducerState {
  switch (action.type) {
    case 'SELECT_CELL': {
      if (!state.isFocused || state.isFocusPaused) {
        return state
      }
      return {
        ...state,
        selectedCell: action.cell,
        editingCell: undefined,
      }
    }
    case 'EDIT_CELL': {
      action.event?.preventDefault()
      return {
        ...state,
        editingCell: state.selectedCell && { position: state.selectedCell },
      }
    }
    case 'SELECT_AND_EDIT_CELL': {
      if (!state.isFocused || state.isFocusPaused) {
        return state
      }
      return {
        ...state,
        selectedCell: action.cell,
        editingCell: { position: action.cell },
      }
    }
    case 'DIRECTLY_EDIT_CELL': {
      if (!state.isFocused || state.isFocusPaused) {
        return state
      }
      action.event?.preventDefault()
      return {
        ...state,
        editingCell: state.selectedCell && {
          position: state.selectedCell,
          initialValue: action.event?.key,
        },
      }
    }
    case 'COPY_CELL_CONTENT': {
      // Only handle cases where the cell is selected and not being edited.
      // If directly editing the cell, we can rely on default copy behavior.
      if (!state.editingCell && !!state.selectedCell) {
        action.event?.preventDefault()
        const currentRow = state.content.rows[state.selectedCell.rowIndex]
        const currentCell = currentRow.cells[state.selectedCell.cellIndex]
        if (currentCell.type === 'DATA') {
          copy(currentCell.value.toString())
        }
      }
      return state
    }
    case 'PASTE_CELL_CONTENT': {
      action.event?.preventDefault()
      // Only handle cases where the cell is selected and not being edited.
      // If directly editing the cell, we can rely on default paste behavior.
      if (!state.editingCell && !!state.selectedCell && state.onChange) {
        pasteContentToSpreadsheet({
          onChange: state.onChange,
          selectedCell: state.selectedCell,
          spreadsheetContent: state.content,
          columns: state.columns,
        })
      }
      return state
    }
    case 'STOP_EDITING': {
      action.event?.preventDefault()
      return {
        ...state,
        editingCell: undefined,
      }
    }
    case 'PREVIOUS_CELL': {
      action.event?.preventDefault()
      const newPosition = moveSelectedCell(state, 'previous')
      const shouldEdit = !isDesktop && newPosition !== undefined
      return {
        ...state,
        selectedCell: newPosition,
        editingCell: shouldEdit ? { position: newPosition } : undefined,
      }
    }
    case 'NEXT_CELL': {
      action.event?.preventDefault()
      const newPosition = moveSelectedCell(state, 'next')
      const shouldEdit = !isDesktop && newPosition !== undefined
      return {
        ...state,
        selectedCell: newPosition,
        editingCell: shouldEdit ? { position: newPosition } : undefined,
      }
    }
    case 'PREVIOUS_ROW':
      action.event?.preventDefault()
      return {
        ...state,
        selectedCell: moveSelectedRow(state, 'previous'),
        editingCell: undefined,
      }
    case 'NEXT_ROW':
      action.event?.preventDefault()
      return {
        ...state,
        selectedCell: moveSelectedRow(state, 'next'),
        editingCell: undefined,
      }
    case 'CLICKED_AWAY': {
      // If we are actively editing a cell, save the state but keep the cell selected
      if (state.editingCell) {
        return { ...state, editingCell: undefined }
        // If we aren't editing a cell, deselect the cell entirely
      } else {
        return {
          ...state,
          selectedCell: undefined,
          editingCell: undefined,
        }
      }
    }
    case 'EDITED_CELL': {
      if (!state.onChange) {
        throw new Error('Unable to edit cell, no edit handler provided')
      }
      const { selectedCell } = state
      if (!selectedCell) {
        throw new Error('Cannot edit cell if none is selected')
      }
      const { rowIndex } = selectedCell
      const row = state.content.rows[rowIndex]
      const columnIndex = columnIndexForCell(selectedCell, state.content)
      const column = state.columns[columnIndex]
      state.onChange(row.id, column.id, action.toValue)
      return {
        ...state,
        editingCell: undefined,
      }
    }
    case 'DELETE': {
      if (!state.onChange) {
        throw new Error('Unable to delete cell, no edit handler provided')
      }
      const { selectedCell } = state
      if (!selectedCell) {
        return state
      }
      const { rowIndex } = selectedCell
      const rowId = state.content.rows[rowIndex].id
      const columnIndex = columnIndexForCell(selectedCell, state.content)
      const column = state.columns[columnIndex]

      // Handle deleting % complete by removing the progress billed for this month.
      if (column.id === LumpSumInvoiceColumn.PERCENT_COMPLETE) {
        state.onChange(rowId, BaseInvoiceColumn.PROGRESS_BILLED, 0)
        const progressCell = moveSelectedCell(state, 'next')
        if (!progressCell) {
          // If unable to proceed to the next cell, something is wrong; log an error and do nothing
          console.error('Unable to find editable progress billed cell')
        }
        return state
      }

      const cell = state.content.rows[rowIndex].cells[columnIndex]
      if (cell.type === 'DATA') {
        const emptyValue = emptyValueForColumn(column)
        state.onChange(rowId, column.id, emptyValue)
      }

      return state
    }
    case 'RESET': {
      return {
        ...state,
        selectedCell: undefined,
        editingCell: undefined,
      }
    }
    case 'REORDER': {
      if (!state.onReorder) {
        throw new Error('Unable to reorder spreadsheet, no reorder handler provided')
      }
      const toContent = reorderSpreadsheet(action.rowId, action.toRowIndex, state.content)
      const editingCellPosition = preserveCellPosition(
        state.editingCell?.position,
        state.content,
        toContent,
        state.columns
      )
      const isValidReorder = state.onReorder(action.rowId, action.toRowIndex)
      if (!isValidReorder) {
        return state
      }
      return {
        ...state,
        content: toContent,
        selectedCell: preserveCellPosition(
          state.selectedCell,
          state.content,
          toContent,
          state.columns
        ),
        editingCell: editingCellPosition && {
          position: editingCellPosition,
        },
      }
    }
    case 'CONTENT_CHANGE': {
      const editingCellPosition = preserveCellPosition(
        state.editingCell?.position,
        state.content,
        action.content,
        state.columns
      )
      return {
        ...state,
        content: action.content,
        selectedCell: preserveCellPosition(
          state.selectedCell,
          state.content,
          action.content,
          state.columns
        ),
        editingCell: editingCellPosition
          ? {
              ...state.editingCell,
              position: editingCellPosition,
            }
          : undefined,
      }
    }
    case 'COLUMNS_CHANGE': {
      return {
        ...state,
        columns: action.columns,
      }
    }
    case 'CHANGE_HANDLER_CHANGED': {
      return {
        ...state,
        onChange: action.onChange,
      }
    }
    case 'REORDER_HANDLER_CHANGED': {
      return {
        ...state,
        onReorder: action.onReorder,
      }
    }
    case 'FOCUS_CHANGED': {
      return {
        ...state,
        isFocused: action.isFocused,
        isFocusPaused: action.isFocusPaused,
        // If the spreadsheet loses focus, de-select any selected cell. We don't de-select when
        // focus is paused.
        ...(!action.isFocused && { selectedCell: undefined, editingCell: undefined }),
      }
    }
  }
}
