import { ClickAwayListener, InputBase } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import moment from 'moment-timezone'
import { Dispatch, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { replaceAllWhitespaces } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { DatePickerInput, DatePickerValue, makeDatePickerValue } from '../DatePickerInput'
import { cellTextColor } from '../SitelineTable.lib'
import { SpreadsheetDataType, numericDataTypes } from './Spreadsheet.lib'
import { SpreadsheetAction } from './SpreadsheetReducer'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    width: '100%',
    ...theme.typography.body2,
    '& .MuiInputBase-input': {
      padding: theme.spacing(0),
      height: 'initial',
    },
    '&.bold .MuiInputBase-input': {
      fontWeight: 600,
    },
    '&.center .MuiInputBase-input': {
      textAlign: 'center',
    },
    '&.right .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  datePicker: {
    '& input': {
      ...theme.typography.body2,
      // Override default input padding to match spreadsheet cell layout
      paddingLeft: `${theme.spacing(2)} !important`,
      // Default height of 40px causes row to shift slightly when cell is selected;
      // subtract 4px for the  2px border on top and bottom
      height: '36px !important',
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(0),
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
}))

interface BaseSpreadsheetCellInputProps {
  value: string
  onValueChange: (value: string) => void
  onSave: (value?: string, onAfterSave?: () => void) => void
  onReset: () => void
  dataType: SpreadsheetDataType
  dispatch: Dispatch<SpreadsheetAction>
  bold?: boolean
  color?: 'grey90' | 'grey50' | 'grey70' | 'red50'
  textAlign?: 'left' | 'center' | 'right'
  characterLimit?: number
  maxDecimals?: number
}

interface SpreadsheetDateCellInputProps extends BaseSpreadsheetCellInputProps {
  dataType: SpreadsheetDataType.DATE
  timeZone: string
}

interface SpreadsheetNonDateCellInputProps extends BaseSpreadsheetCellInputProps {
  dataType: Exclude<SpreadsheetDataType, SpreadsheetDataType.DATE>
}

type SpreadsheetCellInputProps = SpreadsheetDateCellInputProps | SpreadsheetNonDateCellInputProps

/** A cell in a spreadsheet that is currently being edited */
export const SpreadsheetCellInput = memo(function SpreadsheetCellInput(
  props: SpreadsheetCellInputProps
) {
  const {
    value,
    onValueChange,
    onSave,
    onReset,
    dataType,
    dispatch,
    bold,
    color,
    textAlign,
    maxDecimals = 2,
  } = props
  const classes = useStyles()
  const initialDatePickerValue = useMemo(
    () =>
      makeDatePickerValue(
        props.dataType === SpreadsheetDataType.DATE ? moment.tz(props.value, props.timeZone) : null
      ),
    [props]
  )
  const [datePickerValue, setDatePickerValue] = useState<DatePickerValue>(initialDatePickerValue)

  // Update the date picker value if the parent value changes
  useEffect(() => {
    setDatePickerValue(initialDatePickerValue)
  }, [initialDatePickerValue])

  const handleValueChange = (unsanitized: string) => {
    const value = replaceAllWhitespaces(unsanitized)

    // Ignore input that isn't numeric if the data type expects a number
    const isNumeric = numericDataTypes.includes(dataType)
    if (isNumeric && isNaN(Number(value)) && !['-', '.'].includes(value)) {
      return
    }
    // Ignore input after two decimal places for dollar inputs
    if (dataType === SpreadsheetDataType.DOLLAR) {
      const decimalPlace = value.indexOf('.')
      if (decimalPlace !== -1) {
        const decimals = value.substring(decimalPlace + 1)
        if (decimals.length > maxDecimals) {
          return
        }
      }
    }
    onValueChange(value)
  }

  // These are all events that we're responding to INSIDE the <input> element. By default,
  // react-hotkeys does not respond to these events and we want to control them ourselves.
  // This is why some of the logic around moving is duplicated here.
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // If using the date picker, directly save the current picker value
      const saveValue =
        props.dataType === SpreadsheetDataType.DATE
          ? datePickerValue.date?.toISOString()
          : undefined
      switch (event.key) {
        case 'Enter': {
          onSave(saveValue, () => dispatch({ type: 'NEXT_ROW' }))
          break
        }
        case 'Escape':
          onReset()
          dispatch({ type: 'STOP_EDITING' })
          break
        case 'Tab':
          event.preventDefault()
          onSave(saveValue, () =>
            dispatch({ type: event.shiftKey ? 'PREVIOUS_CELL' : 'NEXT_CELL' })
          )
          break
      }
    },
    [dispatch, onSave, onReset, props.dataType, datePickerValue.date]
  )

  const inputProps = useMemo(() => {
    if (!props.characterLimit) {
      return undefined
    }
    return { maxLength: props.characterLimit }
  }, [props.characterLimit])

  if (props.dataType === SpreadsheetDataType.DATE) {
    const { timeZone } = props
    return (
      <ClickAwayListener
        // Use leading click event instead of default trailing click, since there is already
        // a click away listener on the spreadsheet that would take precedence for the same event
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        // Save when clicking away from the cell. This is the equivalent to the `onBlur` listener
        // on the non-date input element below, but `onBlur` won't work here because the input blurs when
        // opening the date picker calendar.
        onClickAway={() => onSave(datePickerValue.date?.toISOString())}
      >
        {
          // Prevent screen readers from considering this div clickable
          // https://mui.com/base/react-click-away-listener/
        }
        <div role="presentation">
          <DatePickerInput
            value={datePickerValue}
            onChange={(datePickerValue) => {
              setDatePickerValue(datePickerValue)
            }}
            onSelectFromPicker={(date) => onSave(date.toISOString())}
            timeZone={timeZone}
            className={classes.datePicker}
            autoFocus
            onKeyDown={onKeyDown}
          />
        </div>
      </ClickAwayListener>
    )
  }

  return (
    <InputBase
      autoFocus
      className={clsx(classes.root, {
        bold,
        ...(textAlign ? { [textAlign]: true } : {}),
      })}
      style={{ color: cellTextColor(color) }}
      value={value}
      onKeyDown={onKeyDown}
      onChange={(ev) => handleValueChange(ev.target.value)}
      onBlur={(ev) => onSave(ev.target.value)}
      inputProps={inputProps}
    />
  )
})
