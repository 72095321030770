import { gql } from '@apollo/client'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useLocation, useSearch } from '@tanstack/react-router'
import { getApp } from 'firebase/app'
import { getAuth, sendSignInLinkToEmail, updatePassword } from 'firebase/auth'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { privacyPolicyUrl, termsOfServiceUrl } from '../../common/config/constants'
import { useCompanyContext, useMultiCompanyContext } from '../../common/contexts/CompanyContext'
import * as fragments from '../../common/graphql/Fragments'
import {
  AuthProviderType,
  GetCurrentCompanyUserQuery,
  useAuthProviderLazyQuery,
  useGetCurrentCompanyUserQuery,
  useGetCurrentUserQuery,
  useUpdateUserAfterInviteMutation,
  useUpdateUserOfficeLocationMutation,
} from '../../common/graphql/apollo-operations'
import { LOCAL_STORAGE_EMAIL_KEY } from '../../common/util/Auth'
import { buildFormattedCompanyLocationsMap } from '../../common/util/Location'
import { trackAccountCreated } from '../../common/util/MetricsTracking'
import { safeLocalStorage } from '../../common/util/SafeLocalStorage'
import { getTimeNoTimeZone } from '../../common/util/Time'

gql`
  mutation updateUserAfterInvite($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      ...UserProperties
    }
  }
  ${fragments.user}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  signinContainer: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 0),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  addressMenuItem: {
    // Override default max width set in `Main.ts`
    '&.MuiMenuItem-root': {
      maxWidth: 'unset',
    },
  },
  loading: {
    textAlign: 'center',
  },
}))

export enum UpdateUserStep {
  INTRO = 'intro',
  JOB_TITLE = 'jobTitle',
  OFFICE = 'office',
  PASSWORD = 'password',
  TERMS_OF_SERVICE = 'termsOfService',
}

// Using instead of generic loader component so progress indicator is centered
// correctly in the card
function Loading() {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  )
}

/** First step in new user sign up: "Welcome" message */
function Intro({ onComplete }: { onComplete: () => void }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const { data } = useGetCurrentUserQuery()
  const user = data?.currentUser
  if (!user) {
    return <Loading />
  }

  const isPostInvite = location.pathname.includes('updateUserAfterInvite')
  const descriptionKey = isPostInvite
    ? 'auth.invite.update_user.intro.details'
    : 'auth.invite.update_user.intro.modal_details'
  const buttonKey = isPostInvite
    ? 'auth.invite.update_user.intro.submit'
    : 'common.actions.continue'

  return (
    <>
      <SitelineText variant="h1" bold className={classes.title}>
        {t('auth.invite.update_user.intro.title', { firstName: user.firstName })}
      </SitelineText>
      <SitelineText variant="body1" color="grey50">
        <Trans i18nKey={descriptionKey} />
      </SitelineText>
      <div className={classes.signinContainer}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          variant="contained"
          onClick={onComplete}
        >
          {t(buttonKey)}
        </Button>
      </div>
    </>
  )
}

/** Allow user to update their job title, prefilled with data collected when the user was invited */
function JobTitle({ onComplete }: { onComplete: () => void }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data } = useGetCurrentUserQuery()
  const user = data?.currentUser

  const [jobTitle, setJobTitle] = useState(user?.jobTitle ?? '')
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [updateUser] = useUpdateUserAfterInviteMutation()

  useEffect(() => {
    if (user) {
      setJobTitle(user.jobTitle ?? '')
    }
  }, [user])

  if (!user) {
    return <Loading />
  }

  const validateAndSubmit = async () => {
    await updateUser({
      variables: {
        id: user.id,
        user: {
          jobTitle,
        },
      },
    })
    onComplete()
  }

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    setError(null)
    setIsSaving(true)
    validateAndSubmit()
      .then(() => {
        setIsSaving(false)
      })
      .catch((err) => {
        setError(err.message)
        setIsSaving(false)
      })
  }

  return (
    <>
      <SitelineText variant="h1" bold className={classes.title}>
        {t('auth.invite.update_user.job_title.title')}
      </SitelineText>
      <form onSubmit={onSubmit} className={classes.form}>
        <TextField
          fullWidth
          variant="outlined"
          className={classes.field}
          helperText={t('auth.invite.update_user.job_title.helper')}
          value={jobTitle}
          onChange={(ev) => setJobTitle(ev.target.value)}
        />
        <div className={classes.signinContainer}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={isSaving || !jobTitle}
          >
            {t('auth.invite.update_user.job_title.submit')}
          </Button>
        </div>
        <div className={classes.errorMessage}>{error}</div>
      </form>
    </>
  )
}

type CurrentCompanyUser = GetCurrentCompanyUserQuery['currentCompanyUser']

const officeIdFromCompanyUser = (companyUser?: CurrentCompanyUser) =>
  companyUser?.companyLocation.id

/** Allow user to update their primary office: prefilled with data collected when the user was invited */
function PrimaryOffice({
  companyUser,
  onComplete,
}: {
  companyUser?: CurrentCompanyUser
  onComplete: () => void
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { company, companyId } = useCompanyContext()
  const [updateUserOfficeLocation] = useUpdateUserOfficeLocationMutation()

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedOfficeId, setSelectedOfficeId] = useState<string | undefined>(
    officeIdFromCompanyUser(companyUser)
  )

  const companyLocationsMap = useMemo(() => buildFormattedCompanyLocationsMap(company), [company])

  const locationIds = useMemo(() => Object.keys(companyLocationsMap), [companyLocationsMap])

  useEffect(() => {
    // Skip this step if there aren't multiple offices to choose from
    if (locationIds.length <= 1) {
      onComplete()
      return
    }

    // Reset selected office id if there is a change to companyUser/ it loads after initially setting office id
    if (companyUser) {
      setSelectedOfficeId(officeIdFromCompanyUser(companyUser))
    }
  }, [companyUser, locationIds.length, onComplete])

  const handleUpdateSelectedOfficeId = useCallback((event: SelectChangeEvent<string>) => {
    event.preventDefault()
    setSelectedOfficeId(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    async (ev: FormEvent) => {
      if (selectedOfficeId === undefined) {
        return
      }
      ev.preventDefault()
      setError(null)
      setIsSaving(true)
      try {
        await updateUserOfficeLocation({
          variables: {
            input: {
              locationId: selectedOfficeId,
              companyId,
            },
          },
        })
        onComplete()
        setIsSaving(false)
      } catch (err) {
        setError(err.message)
        setIsSaving(false)
      }
    },
    [onComplete, selectedOfficeId, updateUserOfficeLocation, companyId]
  )

  if (!selectedOfficeId) {
    return <Loading />
  }

  return (
    <>
      <SitelineText variant="h1" bold className={classes.title}>
        {t('auth.invite.update_user.office.title')}
      </SitelineText>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Select
          variant="outlined"
          value={selectedOfficeId}
          onChange={handleUpdateSelectedOfficeId}
          className={classes.field}
          fullWidth
        >
          {locationIds.map((locationId) => (
            <MenuItem key={locationId} value={locationId} className={classes.addressMenuItem}>
              {companyLocationsMap[locationId]}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.signinContainer}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={isSaving || !selectedOfficeId}
          >
            {t('auth.invite.update_user.job_title.submit')}
          </Button>
        </div>
        <div className={classes.errorMessage}>{error}</div>
      </form>
    </>
  )
}

/** Password creation step in new user sign up flow */
function Password({ onComplete }: { onComplete: () => void }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const { data } = useGetCurrentUserQuery()
  const user = data?.currentUser
  if (!user) {
    return <Loading />
  }

  const validateAndSubmit = async () => {
    if (password.length < 8) {
      throw new Error(t('auth.invite.update_user.password.invalid'))
    }

    if (password !== passwordConfirmation) {
      throw new Error(t('auth.invite.update_user.password.not_equal'))
    }

    const auth = getAuth(getApp())
    const currentUser = auth.currentUser
    if (!currentUser) {
      throw new Error(t('auth.invite.update_user.password.no_current_user'))
    }

    // Update password using Firebase.
    // Note that Firebase requires a fresh session to change a password, so if we detect that the session
    // is too old, we can send a sign-in link and start again from this step.
    await updatePassword(currentUser, password).catch(async (err) => {
      if (err.code === 'auth/requires-recent-login') {
        safeLocalStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, user.email)
        const continueUrl = new URL(window.location.origin)
        continueUrl.pathname = '/signinWithEmailLink'
        continueUrl.searchParams.set('redirect', '/updateUserAfterInvite?step=password')

        await sendSignInLinkToEmail(auth, user.email, {
          handleCodeInApp: true,
          url: continueUrl.toString(),
        })
        throw new Error(t('auth.invite.update_user.password.signin_again'))
      }

      throw err
    })

    onComplete()
  }

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    setError(null)
    setIsSaving(true)
    validateAndSubmit()
      .then(() => {
        setIsSaving(false)
      })
      .catch((err) => {
        setError(err.message)
        setIsSaving(false)
      })
  }

  return (
    <>
      <SitelineText variant="h1" bold className={classes.title}>
        {t('auth.invite.update_user.password.title')}
      </SitelineText>
      <form onSubmit={onSubmit} className={classes.form}>
        <TextField
          required
          fullWidth
          variant="outlined"
          size="small"
          className={classes.field}
          type="password"
          label={t('auth.invite.update_user.password.password_label')}
          helperText={t('auth.invite.update_user.password.password_helper')}
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <TextField
          required
          fullWidth
          variant="outlined"
          size="small"
          className={classes.field}
          type="password"
          label={t('auth.invite.update_user.password.confirmation_label')}
          value={passwordConfirmation}
          onChange={(ev) => setPasswordConfirmation(ev.target.value)}
        />
        <div className={classes.signinContainer}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={isSaving}
          >
            {t('auth.invite.update_user.password.submit')}
          </Button>
        </div>
        <div className={classes.errorMessage}>{error}</div>
      </form>
    </>
  )
}

/** Terms of service step in new user sign up flow */
function TermsOfService({ onComplete }: { onComplete: () => void }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [didAcceptPolicies, setDidAcceptPolicies] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [updateUser] = useUpdateUserAfterInviteMutation()
  const { companyId } = useMultiCompanyContext()

  const { data } = useGetCurrentUserQuery()
  const user = data?.currentUser
  if (!user) {
    return <Loading />
  }

  const validateAndSubmit = async () => {
    // Update User.policiesAcceptedAt to keep record of when policies were accepted
    const timestamp = getTimeNoTimeZone().toISOString()
    const result = await updateUser({
      variables: {
        id: user.id,
        user: {
          policiesAcceptedAt: timestamp,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUser: {
          ...data.currentUser,
          policiesAcceptedAt: timestamp,
        },
      },
    })

    if (result.data) {
      const resultUser = result.data.updateUser
      trackAccountCreated({
        signUpChannel: 'siteline', // We don't allow you to invite anyone right now
        companyId: companyId ?? undefined,
        title: resultUser.jobTitle,
      })
    }

    onComplete()
  }

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    setError(null)
    setIsSaving(true)
    validateAndSubmit()
      .then(() => {
        setIsSaving(false)
      })
      .catch((err) => {
        setError(err.message)
        setIsSaving(false)
      })
  }

  return (
    <>
      <SitelineText variant="h1" bold className={classes.title}>
        {t('auth.invite.update_user.terms.title')}
      </SitelineText>
      <form onSubmit={onSubmit} className={classes.form}>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              size="small"
              color="secondary"
              checked={didAcceptPolicies}
              onChange={() => setDidAcceptPolicies(!didAcceptPolicies)}
            />
          }
          label={
            <SitelineText variant="body1" color="grey50">
              <Trans
                i18nKey="auth.invite.update_user.terms.terms"
                components={{
                  termsOfServiceLink: (
                    <Link
                      href={termsOfServiceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="secondary"
                      underline="hover"
                    />
                  ),

                  privacyPolicyLink: (
                    <Link
                      href={privacyPolicyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="secondary"
                      underline="hover"
                    />
                  ),
                }}
              />
            </SitelineText>
          }
          labelPlacement="end"
        />
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={isSaving || !didAcceptPolicies}
        >
          {t('auth.invite.update_user.terms.submit')}
        </Button>
        <div className={classes.errorMessage}>{error}</div>
      </form>
    </>
  )
}

/** Additional signup steps, including setting a password, job title, and accepting policies */
export function UpdateUser({ onComplete }: { onComplete: () => void }) {
  const stepFromQueryParams = useSearch({
    strict: false,
    select: (search) => search.step as UpdateUserStep | undefined,
  })
  const { companyId } = useCompanyContext()
  const [step, setStep] = useState<UpdateUserStep>(stepFromQueryParams ?? UpdateUserStep.INTRO)
  // Fetch company user data for the office step so the data is pre-loaded
  const { data: companyUserData } = useGetCurrentCompanyUserQuery({
    variables: { companyId },
  })
  const [getAuthProvider] = useAuthProviderLazyQuery()
  const snackbar = useSitelineSnackbar()

  const handlePrimaryOfficeComplete = useCallback(() => {
    const user = getAuth().currentUser
    if (!user || !user.email) {
      return
    }
    getAuthProvider({
      variables: {
        input: {
          email: user.email,
        },
      },
    })
      .then((result) => {
        const needsPassword = result.data?.authProvider.type === AuthProviderType.EMAIL
        setStep(needsPassword ? UpdateUserStep.PASSWORD : UpdateUserStep.TERMS_OF_SERVICE)
      })
      .catch((err) => snackbar.showError(err.message))
  }, [getAuthProvider, snackbar])

  switch (step) {
    case UpdateUserStep.INTRO:
      return <Intro onComplete={() => setStep(UpdateUserStep.JOB_TITLE)} />
    case UpdateUserStep.JOB_TITLE:
      return <JobTitle onComplete={() => setStep(UpdateUserStep.OFFICE)} />
    case UpdateUserStep.OFFICE:
      return (
        <PrimaryOffice
          companyUser={companyUserData?.currentCompanyUser}
          onComplete={handlePrimaryOfficeComplete}
        />
      )
    case UpdateUserStep.PASSWORD:
      return <Password onComplete={() => setStep(UpdateUserStep.TERMS_OF_SERVICE)} />
    case UpdateUserStep.TERMS_OF_SERVICE:
      return <TermsOfService onComplete={onComplete} />
  }
}
