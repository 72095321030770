import { Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  STANDARD_FIXED_DIALOG_TOP,
  SitelineDialog,
} from '../../../common/components/SitelineDialog'
import { ContractForManagingUsers } from '../../../common/components/UserAvatarsTableCell'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  CompanyUsersQuery,
  ContractUserRole,
  Permission,
  useUpdateContractUsersMutation,
} from '../../../common/graphql/apollo-operations'
import { invalidateContractsAfterOnboardingStatusChange } from '../../../common/util/ProjectOnboarding'
import { ContractForProjectHome } from '../home/ProjectHome'
import { NoLeadPmInfo } from '../onboarding/NoLeadPmInfo'
import { ManageProjectTeam, UserWithContractRole } from './ManageProjectTeam'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .subtitle': {
      marginTop: theme.spacing(1),
    },
  },
  noLeadPm: {
    marginTop: theme.spacing(1),
  },
}))

export type CompanyUserForManagingUsers = CompanyUsersQuery['companyUsers'][number]
interface ManageUsersDialogProps {
  open: boolean
  onClose: () => void
  contract: ContractForProjectHome | ContractForManagingUsers
  companyUsers: CompanyUserForManagingUsers[]
}

const i18nBase = 'projects.subcontractors.settings.users.dialog'

/** Dialog for managing users on a single project. */
export function ManageUsersDialog({
  open,
  onClose,
  contract,
  companyUsers,
}: ManageUsersDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { permissions } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS)
  const initialTeamMembers = useMemo(() => {
    return contract.users
      .filter((contractUser) => {
        const foundUser = companyUsers.find(
          (companyUser) => companyUser.user.id === contractUser.user.id
        )
        return foundUser !== undefined
      })
      .map((contractUser) => ({
        userId: contractUser.user.id,
        contractRole: contractUser.role,
      }))
  }, [contract, companyUsers])
  const [teamMembers, setTeamMembers] = useState<UserWithContractRole[]>(initialTeamMembers)
  const teamMemberByUserId = useMemo(
    () => _.keyBy(teamMembers, (teamMember) => teamMember.userId),
    [teamMembers]
  )
  const [updateContractUsers, { loading: saving }] = useUpdateContractUsersMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      const newUserRefs = data.updateContractUsers.users.map((user) => {
        return cache.writeFragment({
          data: user,
          fragment: fragments.minimalContractUser,
          fragmentName: 'MinimalContractUserProperties',
        })
      })

      const leadPmRefs = data.updateContractUsers.users
        .filter((user) => user.role === ContractUserRole.LEAD_PM)
        .map((user) => {
          return cache.writeFragment({
            data: user,
            fragment: fragments.minimalContractUser,
            fragmentName: 'MinimalContractUserProperties',
          })
        })

      cache.modify({
        id: cache.identify(data.updateContractUsers),
        fields: {
          users() {
            // We're returning ALL users, so we may have overlap. Only include the new userRefs
            return newUserRefs
          },
          leadPMs() {
            return leadPmRefs
          },
        },
      })

      // OnboardedStatus.addedTeammates
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  // Whenever the project changes, recompute the default team members shown
  useEffect(() => {
    setTeamMembers(initialTeamMembers)
  }, [initialTeamMembers])

  const handleToggleUserAdded = useCallback(
    (userId: string, added: boolean) => {
      if (added) {
        // New team members by default are added as members (not lead PMs)
        setTeamMembers([...teamMembers, { userId, contractRole: ContractUserRole.MEMBER }])
      } else {
        setTeamMembers(teamMembers.filter((teamMember) => teamMember.userId !== userId))
      }
    },
    [teamMembers]
  )

  const handleChangeContractUserRole = useCallback(
    (userId: string, contractRole: ContractUserRole) => {
      const newTeamMembers = [...teamMembers]
      const teamMemberIndex = newTeamMembers.findIndex((teamMember) => teamMember.userId === userId)
      newTeamMembers.splice(teamMemberIndex, 1, { userId, contractRole })
      setTeamMembers(newTeamMembers)
    },
    [teamMembers]
  )

  const onSubmit = useCallback(() => {
    const newUsers = companyUsers
      .filter(({ user }) => user.id in teamMemberByUserId)
      .map(({ user }) => {
        const role =
          user.id in teamMemberByUserId
            ? teamMemberByUserId[user.id].contractRole
            : ContractUserRole.MEMBER
        return {
          __typename: 'ContractUser' as const,
          id: uuidv4(),
          user,
          role,
        }
      })
    const newLeadPMs = companyUsers
      .filter((companyUser) => companyUser.user.id in teamMemberByUserId)
      .filter((companyUser) => {
        const teamMember = teamMemberByUserId[companyUser.user.id]
        return teamMember.contractRole === ContractUserRole.LEAD_PM
      })
      .map(({ user }) => newUsers.find((newUser) => newUser.id === user.id))

    updateContractUsers({
      variables: {
        input: {
          id: contract.id,
          users: teamMembers,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateContractUsers: {
          ...contract,
          users: newUsers,
          leadPMs: _.compact(newLeadPMs).map((contractUser) => contractUser.user),
        },
      },
    })
      .then(() => {
        snackbar.showSuccess(t(`${i18nBase}.save_success`))
        onClose()
      })
      .catch((error) => {
        snackbar.showError(error.message)
      })
  }, [
    companyUsers,
    contract,
    onClose,
    snackbar,
    t,
    teamMembers,
    teamMemberByUserId,
    updateContractUsers,
  ])

  const hasLeadPM = useMemo(
    () => teamMembers.some((teamMember) => teamMember.contractRole === ContractUserRole.LEAD_PM),
    [teamMembers]
  )

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      title={t(`${i18nBase}.title`)}
      subtitle={
        <SitelineText variant="body1" color="grey50" className="subtitle">
          {t(`${i18nBase}.subtitle`)}
        </SitelineText>
      }
      maxWidth="md"
      submitLabel={t('common.actions.save')}
      onSubmit={onSubmit}
      disableSubmit={!canEdit}
      submitting={saving}
      subscript={t(`${i18nBase}.num_team_members`, { count: teamMembers.length })}
      className={classes.root}
      fixedTopPosition={STANDARD_FIXED_DIALOG_TOP}
    >
      <ManageProjectTeam
        companyUsers={companyUsers}
        teamMembers={teamMembers}
        onToggleTeamMemberAdded={handleToggleUserAdded}
        onChangeContractUserRole={handleChangeContractUserRole}
        size="lg"
      />
      <Collapse in={!hasLeadPM} className={classes.noLeadPm}>
        <NoLeadPmInfo />
      </Collapse>
    </SitelineDialog>
  )
}
