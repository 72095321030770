import { Decimal } from 'decimal.js'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { safeDivideDecimal } from 'siteline-common-all'
import { StoredMaterialsView } from '../../components/billing/invoice/InvoiceReducer'
import { SpreadsheetColumn, SpreadsheetDataType } from '../components/Spreadsheet/Spreadsheet.lib'
import {
  BaseManageSovColumn,
  EditingSovLineItem,
  GetSovColumnsForViewOrEditSovProps,
} from './ManageSov'
import {
  makeChangeOrderApprovalColumn,
  makeChangeOrderColumn,
  makeDefaultRetentionPercentColumn,
  makePreSitelineBillingColumn,
  makePreSitelineRetentionAmountColumn,
  makeStoredMaterialsUnitPriceColumn,
  makeTaxGroupColumn,
  makeTotalRetainageColumn,
} from './ManageSovColumn'
import { isOnboardingSov } from './ProjectOnboarding'

export enum ManageUnitPriceSovColumn {
  UNIT_NAME = 'unitName',
  UNIT_PRICE = 'unitPrice',
  BID_QUANTITY = 'bidQuantity',
  BID_AMOUNT = 'bidAmount',
  REVISED_QUANTITY = 'revisedQuantity',
  REVISED_AMOUNT = 'revisedAmount',
  QUANTITY_TO_DATE = 'quantityToDate',
  AMOUNT_TO_DATE = 'amountToDate',
  UNITS_TO_FINISH = 'unitsToFinish',
}

export const NUM_FIXED_UNIT_DECIMALS = 2

/** Columns that may be shown when viewing or editing an SOV */
export function getUnitPriceSovColumns(
  t: TFunction,
  {
    includeMaterialsInStorageColumn,
    includePreSitelineBillingColumn,
    includePreSitelineRetentionColumns,
    includeRetentionPercentColumn,
    includeTotalRetainageColumn,
    includeTotalBillingColumns,
    includeChangeOrderColumn,
    includeChangeOrderApprovalColumn,
    includeRevisedContractColumns,
    taxGroups,
    onAddTaxGroupForLineItemId,
    isEditable,
    storedMaterialsView = StoredMaterialsView.AMOUNT,
    onStoredMaterialsViewChange = _.noop,
  }: GetSovColumnsForViewOrEditSovProps
): SpreadsheetColumn[] {
  const i18nBase = 'projects.subcontractors.sov'
  const isOnboarding = isOnboardingSov()
  const columns: SpreadsheetColumn[] = [
    {
      id: BaseManageSovColumn.CODE,
      heading: t(`${i18nBase}.headers.number`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      maxWidth: 200,
    },
    {
      id: BaseManageSovColumn.NAME,
      heading: t(`${i18nBase}.headers.description`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 200,
      minWidth: 250,
    },
    {
      id: BaseManageSovColumn.COST_CODE,
      heading: t(`${i18nBase}.headers.cost_code`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      skeletonWidth: 100,
      minWidth: 100,
    },
    {
      id: ManageUnitPriceSovColumn.UNIT_NAME,
      // Once the SOV exists, show an abbreviated name for this column
      heading: isOnboarding
        ? t(`${i18nBase}.headers.unit_of_measure`)
        : t(`${i18nBase}.headers.units`),
      isEditable,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
    },
    {
      id: ManageUnitPriceSovColumn.UNIT_PRICE,
      heading: t(`${i18nBase}.headers.unit_price`),
      isEditable,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 108,
      maxDecimals: 4,
    },
    {
      id: ManageUnitPriceSovColumn.BID_QUANTITY,
      heading: t(`${i18nBase}.headers.bid_quantity`),
      isEditable,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      minWidth: 100,
    },
  ]

  // Show only one contract amount column; either the revised amount if showing revisions,
  // or else the original bid amount
  if (includeRevisedContractColumns) {
    columns.push(
      {
        id: ManageUnitPriceSovColumn.REVISED_QUANTITY,
        heading: t(`${i18nBase}.headers.revised_quantity`),
        isEditable: isEditable && isOnboarding,
        dataType: SpreadsheetDataType.NUMBER,
        align: 'right',
        fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      },
      {
        id: ManageUnitPriceSovColumn.REVISED_AMOUNT,
        heading: t(`${i18nBase}.headers.contract_amount`),
        isEditable: false,
        dataType: SpreadsheetDataType.DOLLAR,
        align: 'right',
        minWidth: 140,
      }
    )
  } else {
    columns.push({
      id: ManageUnitPriceSovColumn.BID_AMOUNT,
      heading: t(`${i18nBase}.headers.bid_amount`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 140,
    })
  }

  if (includePreSitelineBillingColumn) {
    columns.push(makePreSitelineBillingColumn({ t, isEditable }))
  }
  if (includeMaterialsInStorageColumn) {
    columns.push(
      makeStoredMaterialsUnitPriceColumn({
        isEditable,
        storedMaterialsView,
        onStoredMaterialsViewChange,
        t,
      })
    )
  }
  if (includeRetentionPercentColumn) {
    columns.push(makeDefaultRetentionPercentColumn({ t, isEditable }))
  }
  if (includePreSitelineRetentionColumns) {
    columns.push(makePreSitelineRetentionAmountColumn({ t, isEditable }))
  }
  if (taxGroups !== null) {
    columns.push(makeTaxGroupColumn({ t, isEditable, taxGroups, onAddTaxGroupForLineItemId }))
  }
  if (includeChangeOrderColumn) {
    columns.push(makeChangeOrderColumn({ t, isEditable }))
  }
  if (includeChangeOrderApprovalColumn) {
    columns.push(makeChangeOrderApprovalColumn({ t, isEditable }))
  }
  if (includeTotalBillingColumns) {
    columns.push(
      {
        id: ManageUnitPriceSovColumn.QUANTITY_TO_DATE,
        heading: t(`${i18nBase}.headers.quantity_to_date`),
        isEditable: false,
        dataType: SpreadsheetDataType.NUMBER as const,
        align: 'right' as const,
        fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      },
      {
        id: ManageUnitPriceSovColumn.AMOUNT_TO_DATE,
        heading: t(`${i18nBase}.headers.amount_to_date`),
        isEditable: false,
        dataType: SpreadsheetDataType.DOLLAR as const,
        align: 'right' as const,
        minWidth: 116,
      },
      {
        id: ManageUnitPriceSovColumn.UNITS_TO_FINISH,
        heading: t(`${i18nBase}.headers.units_to_finish`),
        isEditable: false,
        dataType: SpreadsheetDataType.NUMBER as const,
        align: 'right' as const,
        fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      }
    )
  }
  if (includeTotalRetainageColumn) {
    columns.push(makeTotalRetainageColumn({ t }))
  }

  return columns
}

/**
 * Returns updated unit price amount fields for a line item, based on changes to the unit price.
 */
export function getUpdatedUnitPriceFields(
  oldLineItem: EditingSovLineItem,
  newLineItem: EditingSovLineItem
): Partial<EditingSovLineItem> {
  const oldUnitPrice = new Decimal(oldLineItem.unitPrice ?? 1)
  const newUnitPrice = new Decimal(newLineItem.unitPrice ?? 1)
  const oldOriginalContractQuantity = safeDivideDecimal(
    oldLineItem.originalTotalValue,
    oldUnitPrice,
    0
  )
  const originalTotalValue = oldOriginalContractQuantity.times(newUnitPrice).round()
  const oldLatestContractQuantity = safeDivideDecimal(oldLineItem.latestTotalValue, oldUnitPrice, 0)
  const latestTotalValue = oldLatestContractQuantity.times(newUnitPrice).round()
  // Progress will be calculated based on the prior quantity billed and new unit price
  const oldBilledToDateQuantity = safeDivideDecimal(oldLineItem.billedToDate, oldUnitPrice, 0)
  const billedToDate = oldBilledToDateQuantity.times(newUnitPrice).round()
  return {
    originalTotalValue: originalTotalValue.toNumber(),
    latestTotalValue: latestTotalValue.toNumber(),
    billedToDate: billedToDate.toNumber(),
  }
}

/** Returns an error if a line item's unit price does not correspond to its total value */
export function assertValidLineItemUnitPrice(unitPrice: number, totalValue: number, t: TFunction) {
  if (unitPrice === 0 && totalValue !== 0) {
    return t('projects.subcontractors.sov.zero_unit_price_error')
  }
  return undefined
}

/** Returns true if an SOV has line items with revised total values */
export function hasRevisedSovLineItems(
  lineItems: readonly { latestTotalValue: number; originalTotalValue: number }[]
) {
  return lineItems.some((lineItem) => lineItem.latestTotalValue !== lineItem.originalTotalValue)
}
