import { Theme } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import {
  BillingType,
  Permission,
  RateTableProperties,
} from '../../../common/graphql/apollo-operations'
import { ContractForProjectHome } from '../home/ProjectHome'
import { FeeTables } from '../onboarding/FeeTables'
import { SettingsHeader } from './SettingsHeader'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    '& .feeTableWrapper': {
      marginTop: theme.spacing(-2),
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings'

interface FeesProps {
  contract: ContractForProjectHome
  rateTable: RateTableProperties
}

/** Fees section in billing settings that allows you to view & edit fees for t&m projects */
export function Fees({ contract, rateTable }: FeesProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useCompanyContext()

  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const canEdit = useMemo(
    () => permissions.includes(Permission.EDIT_PROJECT_SETTINGS),
    [permissions]
  )

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={isTimeAndMaterials ? t(`${i18nBase}.tabs.FEES`) : t(`${i18nBase}.tabs.COR_FEES`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={null}
      />
      <div className="feeTableWrapper">
        <FeeTables
          contract={contract}
          rateTable={rateTable}
          isEditing={isEditing}
          location="projectSettings"
        />
      </div>
    </div>
  )
}
