import LaunchIcon from '@mui/icons-material/Launch'
import { Button, MenuItem, Select } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MAX_DAYS_BEFORE_PAY_APP_DUE } from 'siteline-common-all'
import { Permission, SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { RouterLink } from '../../../common/components/RouterLink'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  BillingType,
  useUpdateContractMutation,
  useUpdateProjectMutation,
} from '../../../common/graphql/apollo-operations'
import { ContractForProjectHome } from '../home/ProjectHome'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  editing: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(-1, 0),
    '& .select': {
      marginRight: theme.spacing(2),
    },
    '& .editInSettings': {
      marginLeft: theme.spacing(1),
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.reminders'

interface RemindersProps {
  contract?: ContractForProjectHome
}

/** Shows a project's reminders and allows you to edit some parts of it. */
export function Reminders({ contract }: RemindersProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isContractActive } = useProjectContext()
  const { permissions } = useCompanyContext()
  const snackbar = useSitelineSnackbar()

  const startBillingReminder = contract?.company.metadata.monthlyCreatePayAppReminderDate
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  const [updateProjectMutation] = useUpdateProjectMutation()
  const [updateContractMutation] = useUpdateContractMutation()
  const [payAppDueOnDayOfMonth, setPayAppDueOnDayOfMonth] = useState<number>(1)
  const [daysBeforePayAppDue, setDaysBeforePayAppDue] = useState<number>(1)

  const resetState = useCallback(() => {
    if (!contract) {
      return
    }

    setPayAppDueOnDayOfMonth(contract.project.metadata.payAppDueOnDayOfMonth)
    setDaysBeforePayAppDue(contract.daysBeforePayAppDue)
    setHasEdited(false)
  }, [contract])

  const handleSave = useCallback(async () => {
    if (!contract) {
      return
    }

    let success = false

    // Only update project metadata if different
    if (payAppDueOnDayOfMonth !== contract.project.metadata.payAppDueOnDayOfMonth) {
      try {
        await updateProjectMutation({
          variables: {
            input: {
              companyId: contract.company.id,
              id: contract.project.id,
              payAppDueOnDayOfMonth,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateProject: {
              ...contract.project,
              metadata: {
                ...contract.project.metadata,
                payAppDueOnDayOfMonth,
              },
            },
          },
        })
        success = true
      } catch (error) {
        snackbar.showError(error.message)
      }
    }

    // Only update contract if different
    if (daysBeforePayAppDue !== contract.daysBeforePayAppDue) {
      try {
        await updateContractMutation({
          variables: {
            input: {
              id: contract.id,
              daysBeforePayAppDue,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateContract: {
              ...contract,
              daysBeforePayAppDue,
            },
          },
        })
        success = true
      } catch (error) {
        snackbar.showError(error.message)
      }
    }

    // Display the success only if something was successful
    if (success) {
      snackbar.showSuccess(t(`${i18nBase}.updated`))
      setHasEdited(false)
    }
  }, [
    contract,
    daysBeforePayAppDue,
    payAppDueOnDayOfMonth,
    snackbar,
    t,
    updateContractMutation,
    updateProjectMutation,
  ])

  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
      confirmSaveTitle: t('projects.subcontractors.settings.confirm.reminders_title'),
      confirmSaveMessage: t('projects.subcontractors.settings.confirm.reminders_details'),
    }),
    [handleSave, hasEdited, resetState, t]
  )

  // Update the state when the project and contract load in
  useEffect(() => {
    if (!contract) {
      return
    }
    resetState()
  }, [contract, resetState])

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.title`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={bulkSaveProps}
      />
      <SettingsRow
        label={t(`${i18nBase}.start_billing`)}
        isLoading={!contract}
        value={
          contract && startBillingReminder
            ? moment.localeData().ordinal(startBillingReminder)
            : t(`${i18nBase}.second_monday`)
        }
        editingValue={
          <div className={classes.editing}>
            <SitelineText variant="body1" color="grey90">
              {startBillingReminder
                ? moment.localeData().ordinal(startBillingReminder)
                : t(`${i18nBase}.second_monday`)}
            </SitelineText>
            <RouterLink underline="none" to="/settings/company">
              <Button
                variant="text"
                color="secondary"
                endIcon={<LaunchIcon fontSize="small" />}
                className="editInSettings"
              >
                <SitelineText variant="button" color="grey50">
                  {t(`${i18nBase}.edit_in_settings`)}
                </SitelineText>
              </Button>
            </RouterLink>
          </div>
        }
        isEditing={isEditing}
      />
      {contract && contract.billingType !== BillingType.TIME_AND_MATERIALS && (
        <SettingsRow
          label={t(`${i18nBase}.pay_app_due_date`)}
          isLoading={!contract}
          value={
            payAppDueOnDayOfMonth > 0
              ? t(`${i18nBase}.day_of_the_month`, {
                  day: moment.localeData().ordinal(payAppDueOnDayOfMonth),
                })
              : null
          }
          editingValue={
            <div className={classes.editing}>
              <Select
                variant="outlined"
                value={payAppDueOnDayOfMonth}
                onChange={(event) => {
                  setPayAppDueOnDayOfMonth(event.target.value as number)
                  setHasEdited(true)
                }}
                className="select"
              >
                {_.range(1, 29).map((dayOfMonth) => (
                  <MenuItem key={dayOfMonth} value={dayOfMonth}>
                    {moment.localeData().ordinal(dayOfMonth)}
                  </MenuItem>
                ))}
              </Select>
              <SitelineText variant="body1" color="grey90">
                {t(`${i18nBase}.of_the_month`)}
              </SitelineText>
            </div>
          }
          isEditing={isEditing}
        />
      )}
      <SettingsRow
        label={t(`${i18nBase}.pay_app_due_reminder`)}
        isLoading={!contract}
        value={
          contract
            ? t(`${i18nBase}.num_days_before_due_date`, { count: daysBeforePayAppDue })
            : null
        }
        editingValue={
          <div className={classes.editing}>
            <Select
              variant="outlined"
              value={daysBeforePayAppDue}
              onChange={(event) => {
                setDaysBeforePayAppDue(event.target.value as number)
                setHasEdited(true)
              }}
              className="select"
            >
              {_.range(1, MAX_DAYS_BEFORE_PAY_APP_DUE).map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
            <SitelineText variant="body1" color="grey90">
              {t(`${i18nBase}.days_before_due_date`, { count: daysBeforePayAppDue })}
            </SitelineText>
          </div>
        }
        isEditing={isEditing}
      />
    </div>
  )
}
